import { useToggle, Loader } from '@met/react-components';
import { Card, Col, Row, Typography } from 'antd';
import React, { Fragment, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ProductIncludedMappingModal from './ProductIncludedMappingModal';
import { useProductIncludedMappingContext } from './ProductIncludedMappingContext';
import { productsActions } from '../../../../store';
import { DeleteTextWrapper, IncludedMappingsTable, MappingsButtonWrapper, StyledSpan } from '../styled';
import { InboxOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';

const Text = { Typography };

const ProductIncludedMappings = ({ sku }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    addMode,
    deleteMode,
    setMappingMode,
    setCurrentMapping,
    productInclusionMappings,
    refreshingProductInclusions,
  } = useProductIncludedMappingContext();

  const [showModal, toggleModal] = useToggle(false);

  const handleAddClick = () => {
    setMappingMode(addMode);
    setCurrentMapping({ sku: sku });
    toggleModal();
  };

  const handleRefreshClick = () => {
    dispatch(productsActions.refreshProductInclusions(sku))
      .then(payload => {
        toast.success(intl.formatMessage({ id: 'admin.included.includedUpdated' }), { autoClose: 5000 });
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.included.includedError' }), { autoClose: 5000 });
      });
  };

  const handleDeleteClick = mappingToDelete => {
    setMappingMode(deleteMode);
    setCurrentMapping(mappingToDelete);
    toggleModal();
  };

  const productInclusionMappingMarkup = !refreshingProductInclusions && productInclusionMappings && (
    <Col md={12}>
      <div>
        <IncludedMappingsTable
          data-test-id='includedMappingTable'
          rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
          bordered={false}
          showHeader={false}
          pagination={false}
          columns={[
            {
              key: 'boxIcon',
              render: () => <InboxOutlined />,
            },
            {
              key: 'description',
              dataIndex: 'description',
            },
            {
              key: 'delete',
              render: inclusionMapping => (
                <DeleteTextWrapper
                  data-test-id='inclusionDelete'
                  onClick={() => {
                    handleDeleteClick(inclusionMapping);
                  }}
                >
                  Delete
                </DeleteTextWrapper>
              ),
            },
          ]}
          dataSource={productInclusionMappings}
          rowKey={item => item.includedId}
        />
      </div>
    </Col>
  );

  return (
    <Fragment>
      <Card>
        <Row>
          <Col xs={24} md={12}>
            <h3>
              <FormattedMessage id='admin.product.productInclusionMappings.heading' />
            </h3>
          </Col>
          <Col sm={24} md={10}>
            <MappingsButtonWrapper
              data-test-id='refreshProductInclusions'
              type='primary'
              icon={<RedoOutlined />}
              disabled={refreshingProductInclusions}
              loading={refreshingProductInclusions}
              onClick={() => handleRefreshClick()}
            >
              <StyledSpan>
                <FormattedMessage id='admin.included.refresh' />
              </StyledSpan>
            </MappingsButtonWrapper>
          </Col>
          <Col sm={24} md={2}>
            <MappingsButtonWrapper
              data-test-id='productInclusionAdd'
              type='primary'
              icon={<PlusOutlined />}
              disabled={refreshingProductInclusions}
              loading={refreshingProductInclusions}
              onClick={() => handleAddClick()}
            >
              <StyledSpan>
                <FormattedMessage id='general.add' />
              </StyledSpan>
            </MappingsButtonWrapper>
          </Col>
        </Row>
        <Row>{productInclusionMappingMarkup}</Row>
      </Card>
      <ProductIncludedMappingModal hidden={!showModal} toggle={toggleModal} />
    </Fragment>
  );
};

export default ProductIncludedMappings;
