const stripNonNumeric = val => {
  if (val) {
    return val.replace(/[^0-9]+/g, '');
  }

  return val;
};

const formatPhoneNumber = phoneNumber => {
  let areaCode = phoneNumber.substring(0, 3);
  let prefix = phoneNumber.substring(3, 6);
  let lineNumber = phoneNumber.substring(6, 10);

  if (phoneNumber.length === 0) {
    return null;
  }

  return `(${areaCode}) ${prefix}-${lineNumber}`;
};

const getFileExtension = fileName => {
  let fileNameParts = fileName.split('.');

  return fileNameParts[fileNameParts.length - 1];
};

const shortenEmail = email => {
  if (email) {
    let emailParts = email && email.split('@');

    return emailParts && emailParts[0];
  }

  return null;
};

const formatPostalCode = (country, postalCode) => {
  let postal = '';
  let re;

  // Canada
  if (postalCode && (country == 'CAN' || country == 'CA')) {
    re = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

    postal = postalCode.replace('-', '').toUpperCase();

    if (re.test(postal)) {
      // postal code with no space
      if (postal.length === 6) {
        // Add space
        postal = postal.replace(/^(.{3})(.*)$/, '$1 $2');

        return postal;
      }

      return postal;
    }

    return postalCode;
  }

  return postalCode;
};

export { stripNonNumeric, formatPhoneNumber, getFileExtension, shortenEmail, formatPostalCode };
