import { Button, Card, Col, message, Popconfirm, Row, Space, Typography } from 'antd';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import React, { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InlineLoadingDots } from '../../styled';
import { DeleteTextWrapper, LmrMappingsTable, StyledUpdatedCol } from '../styled';
import { ProductLmrMappingModal } from '../index';
import { useToggle } from '../../../../hooks';
import { useDispatch } from 'react-redux';
import { productsActions } from '../../../../store';

const { Text } = Typography;

const ProductLmrMappings = ({ product, lastUpdatedFormatted, refreshTriggered, handleReloadLmrClick }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const lmrRegex = /^(:?LMR|C)_.*$/i; // Only allow LMR_ or C_ prefix

  const [isAdd, setIsAdd] = useState(true);
  const [selectedLmrMapping, setSelectedLmrMapping] = useState(null);
  const [showLmrMappingModal, toggleLmrMappingModal] = useToggle(false);

  const handleLmrMappingAddClick = () => {
    setSelectedLmrMapping(null);
    setIsAdd(true);
    toggleLmrMappingModal();
  };

  const handleLmrMappingEditClick = mapping => {
    setSelectedLmrMapping(mapping);
    setIsAdd(false);
    toggleLmrMappingModal();
  };

  const handleLmrValidation = mapping => {
    let isValid = true;
    if (isAdd) {
      if (product?.productLmrMappings.find(x => x.lmrId === mapping.lmrId)) {
        isValid = false;
        message.error(intl.formatMessage({ id: 'admin.product.lmrMappingAlreadyExists' }));
      }

      if (!lmrRegex.test(mapping.lmrId)) {
        isValid = false;
        message.error(intl.formatMessage({ id: 'admin.product.lmrMappingFormatInvalid' }));
      }
    }

    return isValid;
  };

  const handleSubmitClick = mapping => {
    mapping.lmrId = mapping.lmrId.toUpperCase();
    if (handleLmrValidation(mapping)) {
      dispatch(productsActions.upsertLmrMapping(product.sku, mapping))
        .then(() => {
          getLmrMappingsBySku();
          toggleLmrMappingModal();
          message.success('Success');
        })
        .catch(() => {
          message.error('Error');
        });
    }
  };

  const handleDeleteClick = mapping => {
    dispatch(productsActions.deleteLmrMapping(product.sku, mapping.lmrId))
      .then(() => {
        getLmrMappingsBySku();
        if (showLmrMappingModal) {
          toggleLmrMappingModal();
        }
        message.success(intl.formatMessage({ id: 'admin.product.lmrMappingDeleteSuccess' }));
      })
      .catch(() => {
        message.error(intl.formatMessage({ id: 'admin.product.lmrMappingDeleteError' }));
      });
  };

  const getLmrMappingsBySku = () => {
    dispatch(productsActions.getLmrMappingsBySku(product.sku));
  };

  const lastUpdatedMarkup = refreshTriggered ? (
    <div>
      <FormattedMessage id='admin.product.lastUpdated' />
      <InlineLoadingDots />
    </div>
  ) : (
    <div>
      <FormattedMessage id='admin.product.lastUpdated' />
      {lastUpdatedFormatted || <FormattedMessage id='general.na' />}
    </div>
  );

  const getLmrPriceMarkup = lmrPrice => {
    return lmrPrice != null ? <Text>${lmrPrice}</Text> : <FormattedMessage id='general.na' />;
  };
  const getLmrPriceCaMarkup = lmrPriceCa => {
    return lmrPriceCa != null ? <Text>${lmrPriceCa}</Text> : <FormattedMessage id='general.na' />;
  };
  const getWarrantyPeriodMarkup = warrantyPeriod => {
    return warrantyPeriod != null ? (
      <Text>
        {warrantyPeriod}
        <FormattedMessage id='admin.product.years' />
      </Text>
    ) : (
      <FormattedMessage id='general.na' />
    );
  };

  const getServiceEligibleMarkup = serviceEligible => {
    return serviceEligible == null ? (
      <FormattedMessage id='general.na' />
    ) : serviceEligible ? (
      <FormattedMessage id='general.yes' />
    ) : (
      <FormattedMessage id='general.no' />
    );
  };

  const lmrTableMarkup = (
    <LmrMappingsTable
      data-test-id='lmrMappingsTable'
      rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
      bordered={false}
      pagination={false}
      columns={[
        {
          title: 'LMR ID',
          dataIndex: 'lmrId',
          key: 'lmrId',
        },
        {
          title: 'Category',
          dataIndex: 'lmrCategoryDescription',
          key: 'lmrCategoryDescription',
        },
        {
          title: 'Label',
          dataIndex: 'lmrLabel',
          key: 'lmrLabel',
        },
        {
          title: 'Price (USD)',
          dataIndex: 'lmrPrice',
          key: 'lmrPrice',
          render: (item, index) => {
            return getLmrPriceMarkup(item);
          },
        },
        {
          title: 'Price (CAD)',
          dataIndex: 'lmrPriceCa',
          key: 'lmrPriceCa',
          render: (item, index) => {
            return getLmrPriceCaMarkup(item);
          },
        },
        {
          title: 'Warranty Period',
          dataIndex: 'warrantyPeriod',
          key: 'warrantyPeriod',
          render: (item, index) => {
            return getWarrantyPeriodMarkup(item);
          },
        },
        {
          title: 'Service Eligible',
          dataIndex: 'serviceEligible',
          key: 'serviceEligible',
          render: (item, index) => {
            return getServiceEligibleMarkup(item);
          },
        },
        {
          title: 'Actions',
          key: 'delete',
          render: mapping => (
            <Space size={'middle'}>
              <DeleteTextWrapper data-test-id='adminProductLMRMappingDeleteText' type='primary' onClick={() => handleLmrMappingEditClick(mapping)}>
                Edit
              </DeleteTextWrapper>
              <Popconfirm
                title={intl.formatMessage({ id: 'general.areYouSure' })}
                onConfirm={() => handleDeleteClick(mapping)}
                okText={intl.formatMessage({ id: 'general.yes' })}
                cancelText={intl.formatMessage({ id: 'general.no' })}
                cancelButtonProps={{ 'data-test-id': 'cancelBtn', style: { height: 'auto' } }}
                okButtonProps={{ 'data-test-id': 'yesBtn', style: { height: 'auto' } }}
              >
                <DeleteTextWrapper type='primary'>Delete</DeleteTextWrapper>
              </Popconfirm>
            </Space>
          ),
        },
      ]}
      dataSource={product.productLmrMappings}
      rowKey={item => item.lmrId}
    />
  );

  return (
    <Fragment>
      <Card>
        <Row style={{ paddingBottom: '2rem' }}>
          <StyledUpdatedCol xs={24} md={4}>
            <h3>
              <FormattedMessage id='admin.product.productLmrMappings' />
            </h3>
          </StyledUpdatedCol>
          <StyledUpdatedCol xs={24} md={8} offset={6}>
            <span>{lastUpdatedMarkup}</span>
          </StyledUpdatedCol>
          <Col xs={24} md={6}>
            <Row justify={'end'} gutter={[8, 8]}>
              <Col>
                <Button
                  data-test-id='lmrRefresh'
                  icon={<RedoOutlined />}
                  onClick={() => handleReloadLmrClick()}
                  loading={refreshTriggered}
                  type='primary'
                >
                  <span>
                    <FormattedMessage id='admin.product.reloadLmr' />
                  </span>
                </Button>
              </Col>
              <Col>
                <Button
                  data-test-id='lmrAdd'
                  icon={<PlusOutlined />}
                  onClick={() => handleLmrMappingAddClick()}
                  type='primary'
                >
                  <span>
                    <FormattedMessage id='general.add' />
                  </span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {lmrTableMarkup}
      </Card>
      <ProductLmrMappingModal
        hidden={showLmrMappingModal}
        toggle={toggleLmrMappingModal}
        isAdd={isAdd}
        currentLmrMapping={selectedLmrMapping}
        handleSubmit={mapping => handleSubmitClick(mapping)}
        handleDelete={mapping => handleDeleteClick(mapping)}
      />
    </Fragment>
  );
};

export default ProductLmrMappings;
