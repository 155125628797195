import { Collapsible, Icon, ListGroup, ListGroupItem, Loader } from '@met/react-components';
import Enumerable from 'linq';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useServiceOrder, useToggle, useUser } from '../../../../hooks';
import { serviceOrdersActions } from '../../../../store';
import ServiceOrderLine from './ServiceOrderLine';
import ResubmitBulkServiceOrderModal from '../ResubmitBulkServiceOrderModal';
import { CollapsibleHeaderRow, Divider, NoServiceOrderFound, Pager, ServiceOrderLinesWrapper } from '../styled';
import { Button, Checkbox, Col, DatePicker, Form, Input, PageHeader, Row, Select, Space, Typography } from 'antd';
import { ReloadOutlined, FileSyncOutlined } from '@ant-design/icons';
import ResyncOrderIndexModal from '../ResyncOrderIndexModal';
import { Permissions } from '../../../../enums';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
const { Text } = Typography;
const ServiceOrdersTab = () => {
  const { permissions } = useUser();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { generateIssuesList, generateWarningsList } = useServiceOrder();
  const formRef = useRef();
  const searchTermRef = useRef(null);

  const displayDateFormat = 'MM/DD/YYYY';
  const calendarDateFormat = 'YYYY-MM-DD';
  let defaultStartDate = moment().add(-30, 'd').format(calendarDateFormat);
  let defaultEndDate = moment().format(calendarDateFormat);
  const defaultPage = 1;
  const defaultPageSize = 15;
  const defaultShowOnlyFailed = '';

  const [query, setQuery] = useQueryParams({
    q: withDefault(StringParam, ''),
  });

  // eslint-disable-next-line no-unused-vars
  const [statusQuery, setStatusQuery] = useQueryParams({
    st: withDefault(NumberParam, undefined),
  });

  const [datesQuery, setDatesQuery] = useQueryParams({
    s: withDefault(StringParam, defaultStartDate),
    e: withDefault(StringParam, defaultEndDate),
  });

  const [paginationQuery, setPaginationQuery] = useQueryParams({
    p: withDefault(NumberParam, defaultPage),
    ps: withDefault(NumberParam, defaultPageSize),
  });

  const [, setFailedShippingOrdersQuery] = useQueryParams({
    shipstatus: withDefault(StringParam, defaultShowOnlyFailed),
  });

  const searchingServiceOrders = useSelector((state) => state.serviceOrders.searchServiceOrdersAdmin.isLoading);
  const serviceOrders = useSelector((state) => state.serviceOrders.searchServiceOrdersAdmin.data);
  const searchServiceOrdersAdmin = useSelector((state) => state.serviceOrders.searchServiceOrdersAdmin);
  const loadingServiceOrderStatuses = useSelector((state) => state.serviceOrders.getServiceOrderStatuses.isLoading);
  const serviceOrderStatuses = useSelector((state) => state.serviceOrders.getServiceOrderStatuses.serviceOrderStatuses);

  const [serviceOrderStatusOptions, setServiceOrderStatusOptions] = useState([]);
  const [, setSelectedServiceOrderStatus] = useState(null);
  const [issuesMap, setIssuesMap] = useState([]);
  const [warningsMap, setWarningsMap] = useState([]);
  const [isFailed, setIsFailed] = useState(false);
  const [showResubmitModal, toggleResubmitModal] = useToggle(false);
  const [showResyncModal, toggleResyncModal] = useToggle(false);

  useEffect(() => {
    if (datesQuery.s || datesQuery.e) {
      let momentStartDate = moment(datesQuery.s, displayDateFormat, true);
      let momentEndDate = moment(datesQuery.e, displayDateFormat, true);

      if (momentStartDate.isValid()) {
        setDatesQuery({ s: momentStartDate.format(displayDateFormat) });
      }

      if (momentEndDate.isValid()) {
        setDatesQuery({ e: momentEndDate.format(displayDateFormat) });
      }

      if (formRef.current && momentStartDate.isValid() && momentEndDate.isValid()) {
        formRef.current.setFieldsValue({
          date: [moment(momentStartDate, displayDateFormat, true), moment(momentEndDate, displayDateFormat, true)],
        });
      } else if (formRef.current && !momentStartDate.isValid() && momentEndDate.isValid()) {
        formRef.current.setFieldsValue({
          date: [moment(defaultStartDate, calendarDateFormat, true), moment(momentEndDate, displayDateFormat, true)],
        });
      } else if (formRef.current && momentStartDate.isValid() && !momentEndDate.isValid()) {
        formRef.current.setFieldsValue({
          date: [moment(momentStartDate, displayDateFormat, true), moment(defaultEndDate, calendarDateFormat, true)],
        });
      }
    }
  }, [datesQuery]);

  useEffect(() => {
    clearSearchServiceOrders();

    searchTermRef.current.focus();
  }, []);

  useEffect(() => {
    dispatch(serviceOrdersActions.getServiceOrderStatuses()).catch(() => {
      toast.error(intl.formatMessage({ id: 'admin.serviceOrders.loadingServiceOrderStatusesErrorMessage' }), {
        autoClose: false,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (serviceOrderStatuses && serviceOrderStatuses.length > 0) {
      setServiceOrderStatusOptions(createServiceOrderStatusOptions());
    }
  }, [serviceOrderStatuses]);

  useEffect(() => {
    if (serviceOrderStatusOptions && serviceOrderStatusOptions.length > 0) {
      let option = Enumerable.from(serviceOrderStatusOptions).firstOrDefault((x) => x.value === statusQuery.st);

      if (option) {
        setSelectedServiceOrderStatus(option);
      }
    }
  }, [serviceOrderStatusOptions]);

  useEffect(() => {
    if (serviceOrders.length > 0) {
      setIssuesMap(
        serviceOrders.map((serviceOrder, index) => {
          let issuesList = generateIssuesList(serviceOrder);

          return (
            issuesList.length > 0 && { key: serviceOrder.hashedServiceOrderId, value: generateIssuesList(serviceOrder) }
          );
        })
      );

      setWarningsMap(
        serviceOrders.map((serviceOrder, index) => {
          let warningsList = generateWarningsList(serviceOrder);

          return (
            warningsList.length > 0 && {
              key: serviceOrder.hashedServiceOrderId,
              value: generateWarningsList(serviceOrder),
            }
          );
        })
      );
    }
  }, [serviceOrders]);

  useEffect(() => {
    if (window.location.search) {
      searchServiceOrders(window.location.search);
    }
  }, [window.location.search]);

  const handleSearch = (formVal) => {
    if (formVal.query) {
      setQuery({ q: formVal.query });
    } else {
      setQuery({ q: '' });
    }

    setPaginationQuery({ p: defaultPage });

    searchServiceOrders(window.location.search);
  };

  const clearSearchServiceOrders = () => {
    dispatch(serviceOrdersActions.clearServiceOrdersAdmin());
  };

  const searchServiceOrders = (searchParameters) => {
    clearSearchServiceOrders();

    dispatch(serviceOrdersActions.searchServiceOrdersAdmin(1, searchParameters)).catch(() => {
      toast.error(intl.formatMessage({ id: 'admin.serviceOrders.searchError' }));
    });
  };

  const handleManageClick = (serviceOrder) => {
    history.push(`/admin/serviceorders/${serviceOrder.hashedServiceOrderId}`);
  };

  const handleServiceOrderStatusSelect = (selectedServiceOrderStatus) => {
    setSelectedServiceOrderStatus(selectedServiceOrderStatus);

    setStatusQuery({ st: selectedServiceOrderStatus });
    setPaginationQuery({ p: defaultPage });
  };

  const handleDateChange = (dates) => {
    // Start date
    if (dates && dates[0] && dates[0].isValid()) {
      setDatesQuery({ s: dates[0].format(displayDateFormat) });
    } else {
      setDatesQuery({});
    }
    // End Date
    if (dates && dates[1] && dates[1].isValid()) {
      setDatesQuery({ e: dates[1].format(displayDateFormat) });
    } else {
      setDatesQuery({});
    }

    setPaginationQuery({ p: defaultPage });
  };

  const createServiceOrderStatusOptions = () => {
    let serviceOrderStatusOptions = [];

    if (!loadingServiceOrderStatuses && serviceOrderStatuses) {
      serviceOrderStatusOptions = serviceOrderStatuses.map((status, index) => {
        return (
          <Option key={'statuses-' + index} value={status.value}>
            {status.key}
          </Option>
        );
      });
    }
    serviceOrderStatusOptions.unshift(
      <Option key={'defaultStatus'} value={0}>
        <FormattedMessage id='general.any' />
      </Option>
    );

    return serviceOrderStatusOptions;
  };

  const handleShowOnlyFailedOrdersClick = (isChecked) => {
    if (isChecked) {
      setIsFailed(true);
      setFailedShippingOrdersQuery({ shippostatus: 'failed' });
    } else {
      setIsFailed(false);
      setFailedShippingOrdersQuery({ shippostatus: '' });
    }

    setPaginationQuery({ p: defaultPage });
  };

  const loadingSearchResultsMarkup = searchingServiceOrders && (
    <Row justify={'center'}>
      <Col>
        <Loader type='tiles' />
      </Col>
    </Row>
  );

  const searchResultsList =
    serviceOrders &&
    serviceOrders.map((serviceOrder, soi) => {
      let formattedSubmittedDate = () => {
        return moment(serviceOrder.serviceOrderDate).local().format('MM/DD/YYYY');
      };

      let hasIssues =
        Enumerable.from(issuesMap).firstOrDefault((x) => x.key === serviceOrder.hashedServiceOrderId) != null;
      let hasWarnings =
        Enumerable.from(warningsMap).firstOrDefault((x) => x.key === serviceOrder.hashedServiceOrderId) != null;

      let collapsibleHeaderMarkup = (
        <CollapsibleHeaderRow gutter={[6, 6]} align={'middle'}>
          <Col xs={12} lg={3}>
            <Space direction={'vertical'}>
              <Text strong>
                <FormattedMessage id='admin.serviceOrders.submittedLabel' />
              </Text>
              <Text>{formattedSubmittedDate()}</Text>
            </Space>
          </Col>
          <Col xs={12} lg={3}>
            <Space direction={'vertical'}>
              <Text strong>
                <FormattedMessage id='admin.serviceOrders.serviceOrderStatusLabel' />
              </Text>
              <Text>
                {serviceOrder.status
                  ? intl.formatMessage({ id: 'admin.serviceOrders.statuses.' + serviceOrder.status })
                  : intl.formatMessage({ id: 'general.na' })}
              </Text>
            </Space>
          </Col>
          <Col xs={12} lg={3}>
            <Space direction={'vertical'}>
              <Text strong>
                <FormattedMessage id='admin.serviceOrders.itemsLabel' />
              </Text>
              <Text>{serviceOrder.serviceOrderLines.length}</Text>
            </Space>
          </Col>
          <Col xs={12} lg={3}>
            <Space direction={'vertical'}>
              <Text strong>
                <FormattedMessage id='admin.serviceOrders.serviceOrderNumberLabel' />
              </Text>
              <Text>{serviceOrder.serviceOrderNumber || <FormattedMessage id='general.na' />}</Text>
            </Space>
          </Col>
          <Col xs={12} lg={6}>
            <Space direction={'vertical'}>
              <Text strong>
                <FormattedMessage id='admin.serviceOrders.submittedByLabel' />
              </Text>
              <Text>{serviceOrder.emailAddress || <FormattedMessage id='general.na' />}</Text>
            </Space>
          </Col>
          <Col xs={12} lg={3}>
            <div>
              {hasIssues && <Icon type='times-circle' danger sm className='mr-1' />}
              {hasWarnings && <Icon type='exclamation-circle' warning sm />}
            </div>
          </Col>
          <Col xs={24} lg={3} style={{ textAlign: 'center' }}>
            <Button data-test-id='serviceOrderManage' type={'primary'} onClick={() => handleManageClick(serviceOrder)}>
              <FormattedMessage id='general.manage' />
            </Button>
          </Col>
        </CollapsibleHeaderRow>
      );

      let serviceOrderLinesMarkup =
        serviceOrder.serviceOrderLines &&
        serviceOrder.serviceOrderLines.map((serviceOrderLine, soli) => {
          return (
            <ServiceOrderLine
              key={`search-result-line-${soli}`}
              sku={serviceOrderLine.sku}
              description={serviceOrderLine.description}
              serialNumber={serviceOrderLine.serialNumber}
              isDeniedWarranty={serviceOrderLine.isDeniedWarranty}
              className='p-2 mt-0'
            />
          );
        });

      return (
        <ListGroupItem key={`search-result-${soi}`}>
          <Collapsible trigger={collapsibleHeaderMarkup}>
            <ServiceOrderLinesWrapper>
              <Divider />
              {serviceOrderLinesMarkup}
            </ServiceOrderLinesWrapper>
          </Collapsible>
        </ListGroupItem>
      );
    });

  const searchResultsMarkup = !searchingServiceOrders && serviceOrders && serviceOrders.length > 0 && (
    <Row>
      <Col xs={24}>
        <ListGroup>{searchResultsList}</ListGroup>
      </Col>
    </Row>
  );

  const pagination = searchServiceOrdersAdmin?.totalCount > 0 && (
    <Pager
      data-test-id='serviceOrderPager'
      current={paginationQuery.p}
      pageSize={paginationQuery.ps}
      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
      total={searchServiceOrdersAdmin.totalCount}
      showSizeChanger={true}
      onChange={(res) => setPaginationQuery({ p: res })}
      onShowSizeChange={(current, to) => setPaginationQuery({ ps: to })}
      pageSizeOptions={[15, 25, 50, 100]}
      responsive={true}
    />
  );

  const searchMarkup = (
    <Row gutter={[12, 12]}>
      <Form
        name='SearchForm'
        onFinish={handleSearch}
        autoComplete='off'
        hideRequiredMark={false}
        layout={'inline'}
        ref={formRef}
      >
        <Col xs={24} lg={6}>
          <Form.Item name={'date'}>
            <RangePicker
              data-test-id='datePicker'
              allowClear={true}
              style={{ width: '100%' }}
              onChange={handleDateChange}
              defaultPickervalue={[
                moment(defaultStartDate, calendarDateFormat),
                moment(defaultEndDate, calendarDateFormat),
              ]}
              defaultValue={[moment(defaultStartDate, calendarDateFormat), moment(defaultEndDate, calendarDateFormat)]}
              format={displayDateFormat}
              allowEmpty={[true, true]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item name={'status'} initialValue={0}>
            <Select
              data-test-id='statusChangeDropdown'
              onChange={handleServiceOrderStatusSelect}
              style={{ minWidth: '100%' }}
            >
              {serviceOrderStatusOptions}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item name={'query'}>
            <Search
              data-test-id='searchOrder'
              ref={searchTermRef}
              allowClear
              onSearch={() => formRef?.current?.submit()}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={3}>
          <Form.Item>
            <Button
              data-test-id='reprocessButton'
              type='primary'
              icon={<ReloadOutlined />}
              disabled={!isFailed}
              onClick={() => toggleResubmitModal()}
            >
              <span style={{ paddingLeft: '0.25rem' }}>
                <FormattedMessage id='admin.serviceOrders.reprocessModal.reprocess' />
              </span>
            </Button>
          </Form.Item>
        </Col>
        <Col xs={24} lg={3}>
          {permissions()[Permissions.SERVICEORDERS_RESYNC_INDEX] && (
            <Button
              data-test-id='Refresh Index'
              type='secondary'
              icon={<FileSyncOutlined />}
              onClick={() => toggleResyncModal()}
            >
              <span style={{ paddingLeft: '0.25rem' }}>Sync Index</span>
            </Button>
          )}
        </Col>
        <Col xs={24}>
          <Form.Item name={'showOnlyFailedOrders'}>
            <Checkbox
              data-test-id='failedOrdersCheckbox'
              onChange={(val) => handleShowOnlyFailedOrdersClick(val.target.checked)}
            >
              <FormattedMessage id='admin.serviceOrders.showOnlyFailedOrders' />
            </Checkbox>
          </Form.Item>
        </Col>
      </Form>
    </Row>
  );

  const noSearchResultsMarkup = query.q && !searchingServiceOrders && serviceOrders && serviceOrders.length === 0 && (
    <NoServiceOrderFound justify={'center'}>
      <Col>
        <FormattedMessage id='admin.serviceOrders.noSearchResults' />
      </Col>
    </NoServiceOrderFound>
  );

  return (
    <Fragment>
      <PageHeader title={intl.formatMessage({ id: 'admin.serviceOrders.heading' })}>
        {searchMarkup}
        {loadingSearchResultsMarkup}
        {searchResultsMarkup}
        {noSearchResultsMarkup}
        {pagination}
      </PageHeader>
      <ResubmitBulkServiceOrderModal
        data-test-id='resubmitBulkServiceOrderModal'
        hidden={!showResubmitModal}
        toggle={() => toggleResubmitModal()}
        serviceOrders={searchServiceOrdersAdmin}
        datesQuery={datesQuery}
        handleSearch={() => formRef?.current?.submit()}
      />
      <ResyncOrderIndexModal
        data-test-id='resyncOrderModal'
        hidden={!showResyncModal}
        toggle={() => toggleResyncModal()}
      />
    </Fragment>
  );
};

export default ServiceOrdersTab;
