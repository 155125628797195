import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PageLoader } from '../../../components';
import { WarrantyInformation, ProductDetail } from './components';
import { RepairItemsProvider } from './RepairItemsContext';
import WarrantyEvaluation from './components/WarrantyInformation/components/WarrantyEvaluation';

const RepairItems = () => {
  const location = useLocation();
  const history = useHistory();

  const [showWarrantyInformation, setShowWarrantyInformation] = useState(false);
  const [showProductDetail, setShowProductDetail] = useState(false);

  useEffect(() => {
    let pathParts = location.pathname.split('/');
    pathParts = pathParts.filter(part => {
      if (part && part !== '') {
        return part;
      }
    });

    // If SKU is not specified in URL
    // Ex. /repairprocess/warrantyinformation/285720
    if (pathParts.length < 3) {
      history.replace('/repairprocess/addanother');
    }

    let repairItemsStep = pathParts[1].toLowerCase();

    if (repairItemsStep === 'warrantyinformation') {
      setShowWarrantyInformation(true);
      setShowProductDetail(false);
    } else if (repairItemsStep === 'productdetail') {
      setShowProductDetail(true);
      setShowWarrantyInformation(false);
    } else {
      history.replace('/repairprocess/addanother');
    }
  }, [location.pathname]);

  const warrantyInformationMarkup = showWarrantyInformation && <WarrantyInformation />;

  const productDetailMarkup = showProductDetail && <ProductDetail />;

  return (
    <RepairItemsProvider>
      {warrantyInformationMarkup}
      {productDetailMarkup}
    </RepairItemsProvider>
  );
};

export default RepairItems;
