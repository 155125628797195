import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { NotFound, PageLoader } from '../../../../../components';
import { useToggle } from '../../../../../hooks';
import { productsActions } from '../../../../../store';
import { useRepairItemsContext } from '../../RepairItemsContext';
import BatteryEvaluationModal from '../BatteryEveluationModal/BatteryEvaluationModal';
import { ProofOfPurchase } from './components';
import SerialNumber from './components/SerialNumber';
import ToolInfoHeader from './components/ToolInfoHeader';
import WarrantyEvaluation from './components/WarrantyEvaluation';

const WarrantyInformation = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();

  const {
    product,
    loadingProduct,
    sku,
    setSku,
    manufactureDate,
    setManufactureDate,
    serialNumber,
    setSerialNumber,
    serialNumberUnreadable,
    setSerialNumberUnreadable,
    setIsRecycled,
    productBatteryDetails,
  } = useRepairItemsContext();

  const [notFound, setNotFound] = useState(false);
  const [showSerialNumberMarkup, setShowSerialNumberMarkup] = useState(true);
  const [showProofOfPurchaseMarkup, setShowProofOfPurchaseMarkup] =
    useState(false);
  const [showWarrantyStatusMarkup, setShowWarrantyStatusMarkup] =
    useState(false);
  const [showBatteryModal, toggleBatteryModal] = useToggle(false);
  const [batterySafetyLink, setBatterySafetyLink] = useState(null);

  useEffect(() => {
    if (productBatteryDetails) {
      if (productBatteryDetails.safetyLink) {
        setBatterySafetyLink(productBatteryDetails.safetyLink);
      } else {
        setBatterySafetyLink(null);
      }
    }
  }, [productBatteryDetails]);

  useEffect(() => {
    if (sku !== '' && product && product.isBattery && !showBatteryModal) {
      toggleBatteryModal();
    }
  }, [product]);

  useEffect(() => {
    let pathParts = location.pathname.split('/');
    pathParts = pathParts.filter((part) => {
      if (part && part !== '') {
        return part;
      }
    });

    if (pathParts[1] === 'warrantyinformation') {
      setSku(pathParts[2]);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (sku !== '') {
      dispatch(productsActions.getProduct(sku)).catch((payload) => {
        if (
          (payload.response && payload.response.status === 400) ||
          payload.response.status === 404
        ) {
          setNotFound(true);
        }

        toast.error(
          intl.formatMessage({ id: 'productDetail.getProductError' })
        );
      });
    }
  }, [sku]);

  if (notFound) {
    return <NotFound />;
  }

  if (loadingProduct || !product) {
    return <PageLoader />;
  }

  const onSerialNumberUnreadableClick = () => {
    setManufactureDate(null);
    setSerialNumber(null);
    setSerialNumberUnreadable(true);
    setShowSerialNumberMarkup(false);
    setShowProofOfPurchaseMarkup(true);
  };

  const onSerialNumberContinueClick = () => {
    setShowSerialNumberMarkup(false);
    setShowProofOfPurchaseMarkup(true);
  };

  const handleProofOfPurchaseBackClick = () => {
    setShowProofOfPurchaseMarkup(false);
    setShowSerialNumberMarkup(true);
  };

  const handleWarrantyStatusBackClick = () => {
    setShowWarrantyStatusMarkup(false);
    setShowProofOfPurchaseMarkup(true);
  };

  const handleEnterRepairDetailsClick = () => {
    history.push(`/repairprocess/productdetail/${sku}`);
  };

  const handleCheckWarrantyStatusClick = () => {
    setShowProofOfPurchaseMarkup(false);
    setShowWarrantyStatusMarkup(true);
  };

  const handleGoToSerialNumber = () => {
    setShowWarrantyStatusMarkup(false);
    setShowSerialNumberMarkup(true);
  };

  const serialNumberMarkup = showSerialNumberMarkup && (
    <SerialNumber
      onSerialNumberUnreadableClick={() => onSerialNumberUnreadableClick()}
      onContinueClick={() => onSerialNumberContinueClick()}
    />
  );

  const proofOfPurchaseMarkup = showProofOfPurchaseMarkup && (
    <ProofOfPurchase
      onBack={() => handleProofOfPurchaseBackClick()}
      onCheckWarrantyStatus={() => handleCheckWarrantyStatusClick()}
    />
  );

  const warrantyStatusMarkup = showWarrantyStatusMarkup && (
    <WarrantyEvaluation
      onBack={() => handleWarrantyStatusBackClick()}
      onEnterRepairDetails={() => handleEnterRepairDetailsClick()}
      onGoToSerialNumber={() => handleGoToSerialNumber()}
    />
  );

  return (
    <Content style={{ padding: '0 2rem' }}>
      <ToolInfoHeader
        data-test-id='toolInfoHeader'
        productInfo={product}
        serialNumber={serialNumber}
        isSerialNumberUnreadable={serialNumberUnreadable}
        manufactureDate={manufactureDate}
        purchaseDate={null}
      />
      {serialNumberMarkup}
      {proofOfPurchaseMarkup}
      {warrantyStatusMarkup}
      <BatteryEvaluationModal
        data-test-id='batteryInfoHeader'
        selectedProduct={product}
        showBatteryModal={showBatteryModal}
        toggleBatteryModal={toggleBatteryModal}
        setIsRecycled={(isRecycled) => setIsRecycled(isRecycled)}
        batterySafetyLink={batterySafetyLink}
      />
    </Content>
  );
};

export default WarrantyInformation;
