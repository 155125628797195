import { Icon } from '@met/react-components';
import { Space, Typography } from 'antd';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { ServiceCard } from '../../../../../../components';
import { useToggle } from '../../../../../../hooks';
import { useRepairItemsContext } from '../../../RepairItemsContext';
import LmrDefinitionModal from './LmrDefinitionModal';

const { Paragraph, Title } = Typography;

const ToolNoWarranty = ({ warrantyLine }) => {
  const { loadingProduct, product } = useRepairItemsContext();

  const [showLmrModal, toggleLmrModal] = useToggle(false);

  const underWarrantyMarkup = product && !loadingProduct && (
    <Fragment>
      <ServiceCard>
        <Title strong>
          <FormattedMessage id='repairProcess.warrantyInformation.outWarranty.title' />
        </Title>
        <Space direction='vertical' size='large'>
          <div>
            <Paragraph>
              <FormattedMessage
                id='repairProcess.warrantyInformation.outWarranty.description'
                values={{ warranty: product?.warrantyPeriod }}
              />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id='repairProcess.warrantyInformation.outWarranty.description2' />
            </Paragraph>
          </div>
          <div>
            <Title level={3} strong style={{ marginBottom: '0px' }}>
              <FormattedMessage id='productDetail.toolInfo.lmrTitle' />
              <Icon data-test-id='outOfWarrantyLMRQuestionCircle' className='ml-2' type='question-circle' sm onClick={toggleLmrModal} />
            </Title>
            <Title level={2} strong style={{ marginTop: '0px', color: '#db011c' }}>
              {warrantyLine}
            </Title>
          </div>
          <div>
            <Title level={3} strong>
              <FormattedMessage id='repairProcess.warrantyInformation.warrantyCoverage.title' />
            </Title>
            <Paragraph>
              <ul style={{ paddingLeft: '15px' }}>
                <li>
                  <FormattedMessage id='repairProcess.warrantyInformation.warrantyCoverage.factor1' />
                </li>
                <li>
                  <FormattedMessage id='repairProcess.warrantyInformation.warrantyCoverage.factor2' />
                </li>
                <li>
                  <FormattedMessage id='repairProcess.warrantyInformation.warrantyCoverage.factor3' />
                </li>
              </ul>
            </Paragraph>
          </div>
        </Space>
      </ServiceCard>
    </Fragment>
  );

  return (
    <Fragment>
      {underWarrantyMarkup}
      <LmrDefinitionModal isVisible={showLmrModal} toggle={() => toggleLmrModal()} />
    </Fragment>
  );
};

export default ToolNoWarranty;
