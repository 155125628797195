import { Button, CardBody, Col, Link, Row, Text } from '@met/react-components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPhoneNumber } from '../../../helpers/formatter';

import { ShippingInfoSummaryCard } from './styled';

const ShippingInfoSummary = ({
  shippingAddress,
  name,
  email,
  accountNumber,
  phoneNumber,
  preferredContactMethod,
  toggleUserMetadataModal,
  editable,
}) => {
  const shippingInfoButtons = editable && (
    <Fragment>
      <Link to='/repairprocess/shipping' className='hidden-xs visible-sm mt-2'>
        <FormattedMessage id='general.edit' />
      </Link>
      <Link button primary block className='hidden-sm mt-3' to='/repairprocess/shipping'>
        <FormattedMessage id='general.edit' />
      </Link>
    </Fragment>
  );

  const accountInfoButtons = editable && (
    <Fragment>
      <Text data-test-id='shippingPageEditAccountInfoText' primary clickable onClick={() => toggleUserMetadataModal()} className='hidden-xs visible-sm mt-2'>
        <FormattedMessage id='general.edit' />
      </Text>
      <Button data-test-id='shippingPageEditAccountInfoButton' primary block onClick={() => toggleUserMetadataModal()} className='hidden-sm mt-3'>
        <FormattedMessage id='general.edit' />
      </Button>
    </Fragment>
  );

  return (
    <ShippingInfoSummaryCard>
      <CardBody>
        <Row>
          <Col xs={12} sm={6} className='mb-3 mb-sm-0'>
            <h3>
              <FormattedMessage id='repairProcess.shippingInfoSummary.shippingInfoLabel' />
            </h3>
            <Text block bold className='mt-1' data-test-id='shipping-name'>
              {shippingAddress.name}
            </Text>
            <Text block data-test-id='shipping-line-1'>
              {shippingAddress.addressLine1}
            </Text>
            <Text block data-test-id='shipping-other'>
              {shippingAddress.city}, {shippingAddress.state} {shippingAddress.postalCode}
            </Text>
            {shippingInfoButtons}
          </Col>
          <Col xs={12} sm={6}>
            <h3>
              <FormattedMessage id='repairProcess.shippingInfoSummary.accountInfoLabel' />
            </h3>
            <Text block>
              <FormattedMessage id='repairProcess.shippingInfoSummary.emailLabel' /> {email}
            </Text>
            {accountNumber && (
              <Text block>
                <FormattedMessage id='repairProcess.shippingInfoSummary.accountNumberLabel' /> {accountNumber}
              </Text>
            )}
            <Text block>
              <FormattedMessage id='repairProcess.shippingInfoSummary.phoneNumberLabel' />{' '}
              {formatPhoneNumber(phoneNumber)}
            </Text>
            <Text block>
              <FormattedMessage id='repairProcess.shippingInfoSummary.preferredContactMethodLabel' />{' '}
              {preferredContactMethod}
            </Text>
            <Text block>
              <FormattedMessage id='repairProcess.shippingInfoSummary.nameLabel' /> {name}
            </Text>
            {accountInfoButtons}
          </Col>
        </Row>
      </CardBody>
    </ShippingInfoSummaryCard>
  );
};

ShippingInfoSummary.propTypes = {
  shippingAddress: PropTypes.object,
  name: PropTypes.string,
  phoneNumber: PropTypes.string,
  accountNumber: PropTypes.string,
  email: PropTypes.string,
  preferredContactMethod: PropTypes.string,
  toggleUserMetadataModal: PropTypes.func,
  editable: PropTypes.bool,
};

export default ShippingInfoSummary;
