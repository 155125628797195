import createAxiosAction from '../createAxiosAction';
import { REGISTER_TRACKING_NUMBER_HUB, GET_SIGNALR_ENDPOINT } from '../types';

export default {
  addToTrackingNumberGroup: serviceOrderId => {
    return createAxiosAction({
      url: `/api/v1/signalr/addtogroup`,
      method: 'POST',
      body: {
        targetGroup: serviceOrderId,
      },
      startAction: REGISTER_TRACKING_NUMBER_HUB,
    });
  },
  getSignalREndpoint: () => {
    return createAxiosAction({
      url: `/api/v1/signalr/config`,
      method: 'GET',
      startAction: GET_SIGNALR_ENDPOINT,
    });
  },
};
