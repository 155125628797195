import { GET_COUNTRIES } from '../types';
import { error, success } from '../utils';

export let initialState = {
  getCountries: {
    isLoading: false,
    isError: false,
    countries: [],
  },
};

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_COUNTRIES:
      return {
        ...state,
        getCountries: {
          isLoading: true,
          isError: false,
          countries: [],
        },
      };
    case success(GET_COUNTRIES):
      return {
        ...state,
        getCountries: {
          isLoading: false,
          isError: false,
          countries: action.payload.data,
        },
      };
    case error(GET_COUNTRIES):
      return {
        ...state,
        getCountries: {
          isLoading: false,
          isError: true,
          countries: [],
        },
      };
    default:
      return state;
  }
};

export default reducer;
