import { Icon, ModalBody } from '@met/react-components';
import { Breadcrumb, Button, Col, Collapse, Descriptions, Form, Pagination, Row, Table, Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;
const { Panel } = Collapse;

export const ProductsContainer = styled.div`
  margin-top: 1rem;
`;

export const ProductImage = styled.img`
  max-width: 100%;
  max-height: 150px;
  padding: 0.5em;
`;

export const CollapsibleHeaderRow = styled(Row)`
  width: 100%;
`;

export const ServiceOrderLinesWrapper = styled.div`
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ServiceOrderLineRow = styled(Row)`
  &:nth-child(odd) {
    background-color: ${props => props.theme.colors.lightGray};
  }
`;

export const Divider = styled.hr`
  height: 1px;
  margin-top: 1em;
  margin-bottom: 1em;
  border: none;
  background-color: ${props => props.theme.colors.lightGray};
`;

export const ServiceOrderLine = styled(Row)`
  &:nth-child(even) {
    background-color: ${props => props.theme.colors.lightGray};
  }
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 130px;
`;

export const LmrMappingsTable = styled(Table)`
  tbody {
    td:first-child,
    td:last-child {
      width: 1px;
    }
`;

export const ServiceMappingsTable = styled(Table)`
  thead {
    display: none;
  }

  tbody {
    td:first-child,
    td:last-child {
      width: 1px;
    }
  }
`;

export const ProblemMappingsTable = styled(Table)`
  tbody {
    td:first-child,
    td:last-child {
      width: 1px;
    }
  }
`;

export const IncludedMappingsTable = styled(Table)`
  thead {
    display: none;
  }

  tbody {
    td:first-child,
    td:last-child {
      width: 1px;
    }
  }
`;

export const ServiceCenterMappingModalBody = styled(ModalBody)`
  min-height: 15em;
`;

export const ProductProblemMappingModalBody = styled(ModalBody)`
  min-height: 30em;
`;
export const ProductIncludedMappingModalBody = styled(ModalBody)`
  min-height: 30em;
`;

export const ButtonWrapper = styled.div`
  margin-top: 1rem;

  @media (max-width: ${props => props.theme.screenSize.md}) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const PostalCodeCol = styled(Col)`
  @media (max-width: ${props => props.theme.screenSize.md}) {
    margin-top: 1rem;
  }
`;

export const StyledButton = styled(Button)`
  margin-left: 1rem;
`;

export const SuggestionText = styled(Text)`
  margin-top: 0.25rem;
  color: rgb(23, 162, 184);
  cursor: pointer;

  :hover {
    color: rgb(17, 122, 139);
  }
`;

export const SuggestionRow = styled(Row)`
  margin-top: -25px;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 0.5rem;
`;

export const Pager = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

export const NoServiceOrderFound = styled(Row)`
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
`;

export const MaxRow = styled(Row)`
  width: 100%;
`;
export const ButtonIconWrapper = styled.div`
  padding-right: 5px;
  visibility: visible;
  height: 100%;
  @media (max-width: ${props => props.theme.responsive.xl}) {
    visibility: hidden;
    height: 0;
  }
`;
export const IncludedResultsDiv = styled.div`
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;
export const RowIconWrapper = styled.div`
  font-size: 150%;
`;
export const FilterBarRow = styled(Row)`
  padding-bottom: 16px;
`;
export const IncludedFilterLabelWrapper = styled.div`
  padding-top: 5px;
`;

export const IncludedButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
  margin-bottom: 1rem;
  padding: 10px;

  background-color: ${props => props.theme.colors.lightGray};
`;

export const StyledCol = styled(Col)`
  padding-left: 2rem;
  align-self: center;
`;

export const StyledUpdatedCol = styled(Col)`
  align-self: center;
`;

export const DeleteTextWrapper = styled(Typography)`
  cursor: pointer;
  color: rgb(220, 1, 28);
  text-decoration: underline;
`;

export const MappingsButtonWrapper = styled(Button)`
  float: right;
  background-color: rgb(220, 1, 28);
  color: white;
  font-size: 18px;
`;

export const StyledSpan = styled.span`
  padding-left: 0.0625rem;
  }
`;

export const ProductProblemForm = styled(Form)`
  #insertProductProblem_addProblemItem {
    box-shadow: none;
  }
`;

export const StyledDescription = styled(Descriptions)`
  .ant-descriptions-title {
    font-weight: normal;
  }

  .ant-descriptions-view {
    border: none;
  }

  .ant-descriptions-item-label {
    width: 30%;
    font-weight: bold;
    white-space: normal;
  }

  table tr.ant-descriptions-row {
    border-bottom: none;
  }

  table th.ant-descriptions-item-label {
    background-color: #ffffff;
  }

  table th.ant-descriptions-item-label {
    padding: 5px 24px;
    border-right: none;
  }

  table td.ant-descriptions-item-content {
    padding: 5px 24px;
  }

  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 10px;
  }
`;

export const AnnouncementsAddButtonWrapper = styled(Button)`
  float: right;
  background-color: rgb(220, 1, 28);
  color: white;
  font-size: 18px;
`;

export const AnnouncementsButtonWrapper = styled(Button)`
  background-color: rgb(220, 1, 28);
  color: white;
  font-size: 18px;
`;

export const CenteredPanel = styled(Panel)`
  .ant-collapse-header {
    padding: 1em !important;

    > div {
      align-self: center;
    }
  }
`;

export const StyledDescriptions = styled(Descriptions) `
  .ant-descriptions-title {
    font-weight: 500;
  }
  .ant-descriptions-item-label {
    font-weight: bold;
  }
`;
