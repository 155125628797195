import { Button, Col, Modal, ModalBody, ModalContent, Row, Text } from '@met/react-components';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const ConfirmDeleteModal = ({ hidden, toggle, deleteRepairItem }) => {
  return (
    <Modal lg centered hidden={hidden} toggle={toggle}>
      <ModalContent>
        <ModalBody>
          <Text bold>
            <p>
              <FormattedMessage id='confirmation.areYouSure' />
            </p>
          </Text>
          <Row className='mt-3'>
            <Col end='xs' flexDirection='row'>
              <Button data-test-id='confirmDeleteModalCancelButton' secondary onClick={() => toggle()} className='mr-2'>
                <FormattedMessage id='general.cancel' />
              </Button>
              <Button data-test-id='confirmDeleteModalOkButton' primary onClick={() => deleteRepairItem()}>
                <FormattedMessage id='general.ok' />
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

ConfirmDeleteModal.propTypes = {
  hidden: PropTypes.bool,
  toggle: PropTypes.func,
  handleSubmitClick: PropTypes.func,
};

export default ConfirmDeleteModal;
