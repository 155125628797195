import createAxiosAction from '../createAxiosAction';
import {
  GET_SERVICE_ORDER_RECYCLING,
  GET_SERVICE_ORDER_RECYCLING_GROUPS,
  EXPORT_SERVICE_ORDER_RECYCLING,
  UPDATE_SERVICE_ORDER_RECYCLING_REPLACEMENT,
} from '../types';

export default {
  getServiceOrderRecycling: id => {
    return createAxiosAction({
      url: `/api/v1/recycling/${id}`,
      method: 'GET',
      startAction: GET_SERVICE_ORDER_RECYCLING,
    });
  },
  getServiceOrderRecyclingSearch: (searchParams, startDate, endDate, selectedColumn, status) => {
    //Change in future to take from URL
    var params = `?searchparams=${searchParams}&startDate=${startDate}&endDate=${endDate}&selectedColumn=${selectedColumn}&status=${status}`;
    return createAxiosAction({
      url: `/api/v1/recycling/search${params}`,
      method: 'GET',
      startAction: GET_SERVICE_ORDER_RECYCLING_GROUPS,
    });
  },
  getExportRecyclingToExcel: (searchParams, startDate, endDate, selectedColumn, status) => {
    //Change in future to take from URL
    var params = `?searchparams=${searchParams}&startDate=${startDate}&endDate=${endDate}&selectedColumn=${selectedColumn}&status=${status}`;
    return createAxiosAction({
      url: `/api/v1/recycling/export${params}`,
      responseType: 'blob',
      startAction: EXPORT_SERVICE_ORDER_RECYCLING,
    });
  },
  updateReplacement: (id, replacementAxOrderId, replacementTrackingNumber) => {
    return createAxiosAction({
      url: `/api/v1/recycling/updateReplacement`,
      method: 'POST',
      startAction: UPDATE_SERVICE_ORDER_RECYCLING_REPLACEMENT,
      body: {
        id: id,
        replacementAxOrderId: replacementAxOrderId,
        replacementTrackingNumber: replacementTrackingNumber,
      },
    });
  },
};
