import { Button, DatePicker, Input, Row, Upload } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { ServiceCard } from '../../../../../../components';

const { Search } = Input;
const { Dragger } = Upload;

export const StyledDatePicker = styled(DatePicker)`
  margin-top: 0.5em;
  border: 2px solid #db011c;
`;

export const ButtonRow = styled(Row)`
  padding-top: 10px;
  justify-content: flex-end;
`;

export const RepairDetailsButton = styled(Button)`
  background-color: rgb(220, 1, 28);
  color: white;
`;

export const ProductImage = styled.img`
  max-width: 100%;
  max-height: 150px;
  padding: 0.5em;
`;

export const FocusedServiceCard = styled(ServiceCard)`
  border: 2px solid #db011c;
`;

export const SerialNumberSearch = styled(Search)`
  input {
    border: 1px solid #5c5c5c;
  }
`;

export const StyledDragger = styled(Dragger)`
  padding: 1em;
  border: 2px solid #db011c !important;
`;

export const WideButton = styled(Button)`
  height: auto;
  white-space: normal;
`;
