import {
  faBox,
  faBoxOpen,
  faPaperPlane,
  faRecycle,
  faShippingFast,
  faSpinner,
  faTools,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PizzaTracker from '../../../../components/PizzaTracker';
import { useServiceOrder } from '../../../../hooks';

const OrderProgress = ({ trackingNumber }) => {
  const { serviceOrder } = useServiceOrder();

  const [serviceOrderStatus, setServiceOrderStatus] = useState(serviceOrder.status);

  useEffect(() => {
    if (serviceOrder.status === 1) {
      if (!trackingNumber) {
        setServiceOrderStatus(0); // When order is processing, use "Processing" status
      } else {
        setServiceOrderStatus(1); // When order is done processing, use "Submitted" status
      }
    } else if (serviceOrder.status === 9) {
      setServiceOrderStatus(4); // If an order needs additional info in repair, use "In Repair" status
    } else if (serviceOrder.status === 10) {
      setServiceOrderStatus(4); // If an order is denied warranty, use "In Repair" status
    }
  }, [trackingNumber]);

  const detailsMarkup = (
    <FormattedMessage id={`repairDetails.orderInfo.orderStatus.status${serviceOrderStatus}Details`} />
  );

  return (
    <Space direction='vertical' size='large'>
      <PizzaTracker
        steps={[
          {
            id: 0,
            icon: <FontAwesomeIcon icon={faSpinner} />,
            label: <FormattedMessage id='repairDetails.orderInfo.orderStatus.status0' />,
            isVisible: !trackingNumber,
          },
          {
            id: 1,
            icon: <FontAwesomeIcon icon={faPaperPlane} />,
            label: <FormattedMessage id='repairDetails.orderInfo.orderStatus.status1' />,
          },
          {
            id: 2,
            icon: <FontAwesomeIcon icon={faShippingFast} />,
            label: <FormattedMessage id='repairDetails.orderInfo.orderStatus.status2' />,
          },
          {
            id: 3,
            icon: <FontAwesomeIcon icon={faBoxOpen} />,
            label: <FormattedMessage id='repairDetails.orderInfo.orderStatus.status3' />,
          },
          {
            id: 4,
            icon: <FontAwesomeIcon icon={faTools} />,
            label: <FormattedMessage id='repairDetails.orderInfo.orderStatus.status4' />,
          },
          {
            id: 5,
            icon: <FontAwesomeIcon icon={faShippingFast} flip='horizontal' />,
            label: <FormattedMessage id='repairDetails.orderInfo.orderStatus.status5' />,
            isVisible: ![8, 9].includes(serviceOrderStatus),
          },
          {
            id: 6,
            icon: <FontAwesomeIcon icon={faBox} />,
            label: <FormattedMessage id='repairDetails.orderInfo.orderStatus.status6' />,
            isVisible: ![7, 8].includes(serviceOrderStatus),
          },
          {
            id: 7,
            icon: <FontAwesomeIcon icon={faBox} />,
            label: <FormattedMessage id='repairDetails.orderInfo.orderStatus.status7' />,
            isVisible: serviceOrderStatus === 7,
          },
          {
            id: 8,
            icon: <FontAwesomeIcon icon={faRecycle} />,
            label: <FormattedMessage id='repairDetails.orderInfo.orderStatus.status8' />,
            isVisible: serviceOrderStatus === 8,
          },
        ]}
        currentId={serviceOrderStatus}
      />
      {detailsMarkup}
    </Space>
  );
};

export default OrderProgress;
