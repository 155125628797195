import { Form } from 'antd';
import styled from 'styled-components';

export const ShippingInstructionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const ShippingInstructionAcknowledgementForm = styled(Form)`
  display: flex;
  justify-content: center;
`
