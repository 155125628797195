import {
  Button,
  Col,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Placeholder,
  PlaceholderContent,
  PlaceholderInput,
  PlaceholderParagraph,
  Row,
  Text,
  useToggle,
} from '@met/react-components';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import LmrOptions from '../../../../../../components/LmrOptions';
import { useLocale } from '../../../../../../hooks';
import { useRepairItemsContext } from '../../../RepairItemsContext';
import LmrDefinitionModal from '../../WarrantyInformation/components/LmrDefinitionModal';
import {
  PlaceholderGapRow,
  PlaceholderSquareContent,
  SerialNumberImg,
  ToolInfoImage,
  ToolInfoImageWrapper,
} from '../styled';

const ToolInfo = () => {
  const {
    loadingProduct,
    manufactureDate,
    product,
    purchaseDate,
    serialNumber,
    serialNumberUnreadable,
    proofOfPurchaseIncluded,
    proofOfPurchaseNotIncluded,
    warrantyEvaluation,
  } = useRepairItemsContext();
  const { isCaLocale, isEnLocale } = useLocale();
  const [showWarrantyModal, toggleWarrantyModal] = useToggle(false);
  const [showLmrModal, toggleLmrModal] = useToggle(false);
  const [showProofOfPurchaseModal, toggleProofOfPurchaseModal] = useToggle(false);
  const [showSerialNumberModal, toggleSerialNumberModal] = useToggle(false);
  const [toolManufactureDate, setToolManufactureDate] = useState(null);
  const [toolPurchaseDate, setToolPurchaseDate] = useState(null);

  useEffect(() => {
    if (manufactureDate) {
      setToolManufactureDate(moment(manufactureDate).format('MM/DD/YYYY'));
    }
  }, [manufactureDate]);

  useEffect(() => {
    if (purchaseDate) {
      setToolPurchaseDate(moment(purchaseDate).format('MM/DD/YYYY'));
    }
  }, [manufactureDate]);

  const toolInfoTitle = (
    <Row>
      <Col>
        <h3>
          <FormattedMessage id='productDetail.toolInfo.toolInfoTitle' />
        </h3>
      </Col>
    </Row>
  );

  const canadaLmrLines = product && (
    <LmrOptions lmrMappings={product.productLmrMappings} country='ca' style={{ fontWeight: '600' }} />
  );

  const usLmrLines = product && (
    <LmrOptions lmrMappings={product.productLmrMappings} country='us' style={{ fontWeight: '600' }} />
  );

  const warrantyLines = isCaLocale() ? canadaLmrLines : usLmrLines;

  const serialNumberLine =
    serialNumber || !serialNumberUnreadable ? (
      <Text bold>{serialNumber}</Text>
    ) : (
      serialNumberUnreadable && (
        <Text bold>
          <FormattedMessage id='productDetail.toolInfo.serialNumberUnreadableLabel' />
        </Text>
      )
    );

  const manufactureLine = manufactureDate ? (
    <Text bold>{toolManufactureDate}</Text>
  ) : (
    <Text bold>
      <FormattedMessage id='general.pending' />
    </Text>
  );

  const purchaseDateLine = purchaseDate ? (
    <Text bold>{toolPurchaseDate}</Text>
  ) : (!warrantyEvaluation && serialNumber && !purchaseDate && proofOfPurchaseIncluded) ||
    (warrantyEvaluation === null && serialNumberUnreadable && proofOfPurchaseIncluded) ||
    (warrantyEvaluation && serialNumber && !purchaseDate && proofOfPurchaseIncluded) ? (
    <Text bold>
      <FormattedMessage id='productDetail.toolInfo.proofOfPurchaseIncludedInShipment' />
    </Text>
  ) : (
    <Text bold>
      <FormattedMessage id='productDetail.toolInfo.proofOfPurchaseNotIncluded' />
    </Text>
  );

  const warrantyEligibility = warrantyEvaluation ? (
    <Text bold>
      <FormattedMessage id='productDetail.toolInfo.meetsRequirements' />
    </Text>
  ) : (!warrantyEvaluation && serialNumber && !purchaseDate && proofOfPurchaseIncluded) ||
    (warrantyEvaluation === null && serialNumberUnreadable && proofOfPurchaseIncluded) ? (
    <Text bold>
      <FormattedMessage id='productDetail.toolInfo.warrantyPending' />
    </Text>
  ) : warrantyEvaluation === null &&
    ((serialNumberUnreadable && proofOfPurchaseNotIncluded) ||
      (serialNumber && manufactureDate && purchaseDate) ||
      (serialNumber && !manufactureDate && !purchaseDate && proofOfPurchaseNotIncluded)) ? (
    <Text bold>
      <FormattedMessage id='productDetail.toolInfo.moreInfoNeeded' />
    </Text>
  ) : (
    <Text bold>
      <FormattedMessage id='productDetail.toolInfo.outOfWarranty' />
    </Text>
  );

  const toolInfoPlaceholderMarkup = loadingProduct && (
    <Placeholder>
      {toolInfoTitle}
      <Row>
        <Col xs={8} md={7}>
          <Row>
            <Col xs={6}>
              <PlaceholderContent />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <PlaceholderContent big />
            </Col>
          </Row>
          <PlaceholderGapRow>
            <Col xs={8}>
              <PlaceholderInput labeled />
            </Col>
          </PlaceholderGapRow>
          <Row>
            <Col xs={8}>
              <PlaceholderInput labeled />
            </Col>
          </Row>
          <PlaceholderGapRow>
            <Col xs={10}>
              <PlaceholderParagraph lines={5} />
            </Col>
          </PlaceholderGapRow>
          <PlaceholderGapRow>
            <Col xs={6}>
              <PlaceholderContent />
            </Col>
          </PlaceholderGapRow>
          <Row>
            <Col xs={7}>
              <PlaceholderContent />
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <PlaceholderContent />
            </Col>
          </Row>
          <PlaceholderGapRow>
            <Col xs={7}>
              <PlaceholderContent />
            </Col>
          </PlaceholderGapRow>
          <Row>
            <Col xs={7}>
              <PlaceholderContent />
            </Col>
          </Row>
        </Col>
        <Col xs={4} center='xs'>
          <PlaceholderSquareContent picture />
        </Col>
      </Row>
    </Placeholder>
  );

  const toolInfoMarkup = !loadingProduct && product && (
    <Fragment>
      <Row>
        <Col>{toolInfoTitle}</Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Row>
            <Col xs={12}>
              <Text md>{product.skuAlias}</Text>
            </Col>
            <Col xs={12}>
              <Text lg bold>
                {product.description}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text>
                <FormattedMessage id='productDetail.toolInfo.serialNumberTitle' />
              </Text>
            </Col>
          </Row>
          <Row style={{ marginTop: 0 }}>
            <Col>{serialNumberLine}</Col>
          </Row>
          <Row>
            <Col>
              <Text>
                <FormattedMessage id='productDetail.toolInfo.manufactureDate' />
              </Text>
            </Col>
          </Row>
          <Row style={{ marginTop: 0 }}>
            <Col>{manufactureLine}</Col>
          </Row>
          <Row>
            <Col>
              <Text>
                <FormattedMessage id='productDetail.toolInfo.purchaseDate' />
              </Text>
            </Col>
          </Row>
          <Row style={{ marginTop: 0 }}>
            <Col>{purchaseDateLine}</Col>
          </Row>
          <Row className='mb-2 mb-md-0'>
            <Col>
              <Text>
                <FormattedMessage id='productDetail.toolInfo.warrantyTitle' />
                <Text clickable>
                  <Icon data-test-id='productDetailToolInfoWarrantyTitle' className='ml-2' type='question-circle' xs onClick={toggleWarrantyModal} />
                </Text>
              </Text>
              <Text bold>
                <FormattedMessage
                  id='productDetail.toolInfo.warrantyLabel'
                  values={{ warrantyPeriod: product.warrantyPeriod }}
                />
              </Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text>
                <FormattedMessage id='productDetail.toolInfo.warrantyStatus' />
              </Text>
              {warrantyEligibility}
            </Col>
          </Row>
          <Row style={{ paddingBottom: '5px' }}>
            <Col>
              <Text>
                <FormattedMessage id='productDetail.toolInfo.warrantyInfo' />
              </Text>
              <Text style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <FormattedMessage id='productDetail.toolInfo.lmrTitle' />
                <Text clickable>
                  <Icon data-test-id='productDetailToolInfoLMRTitle' className='ml-2' type='question-circle' xs onClick={toggleLmrModal} />
                </Text>
              </Text>
              <Text style={{ color: '#db011c' }}>{warrantyLines}</Text>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} middle='xs' center='xs'>
          <ToolInfoImageWrapper className='mt-3 mt-md-0'>
            <ToolInfoImage src={product.image} />
          </ToolInfoImageWrapper>
        </Col>
      </Row>
    </Fragment>
  );

  const underWarrantyModalMarkup = (
    <Modal lg centered hidden={!showWarrantyModal} toggle={toggleWarrantyModal}>
      <ModalContent>
        <ModalHeader toggle={toggleWarrantyModal}>
          <FormattedMessage id='productDetail.toolInfo.warrantyModalHeader' />
        </ModalHeader>
        <ModalBody>
          <p>
            <FormattedMessage id='productDetail.toolInfo.warrantyModalLimitedWarranty1' />
          </p>
          <p>
            <FormattedMessage id='productDetail.toolInfo.warrantyModalLimitedWarranty2' />
          </p>
        </ModalBody>
        <ModalFooter>
          <Button data-test-id='productDetailToggleWarrantyModal' onClick={toggleWarrantyModal}>
            <FormattedMessage id='general.close' />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const proofOfPurchaseModalMarkup = (
    <Modal lg centered hidden={!showProofOfPurchaseModal} toggle={toggleProofOfPurchaseModal}>
      <ModalContent>
        <ModalHeader toggle={toggleProofOfPurchaseModal}>
          <FormattedMessage id='productDetail.toolInfo.proofOfPurchaseModalHeader' />
        </ModalHeader>
        <ModalBody>
          <p>
            <FormattedMessage id='productDetail.toolInfo.proofOfPurchaseModalBody1' />
          </p>
          <p>
            <FormattedMessage id='productDetail.toolInfo.proofOfPurchaseModalBody2' />
          </p>
        </ModalBody>
        <ModalFooter>
          <Button data-test-id='productDetailGeneralOKButton' onClick={toggleProofOfPurchaseModal}>
            <FormattedMessage id='general.ok' />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const serialNumberModalMarkup = (
    <Modal lg centered hidden={!showSerialNumberModal} toggle={toggleSerialNumberModal}>
      <ModalContent>
        <ModalHeader toggle={toggleSerialNumberModal}>
          <FormattedMessage id='productDetail.toolInfo.serialNumberModalHeader' />
        </ModalHeader>
        <ModalBody>
          <p>
            <FormattedMessage id='productDetail.toolInfo.serialNumberModalBody1' />
          </p>
          <Row>
            <Col center='xs'>
              <SerialNumberImg src={window.location.origin + '/images/battery_serial_number.jpg'} />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col center='xs'>
              <SerialNumberImg src={window.location.origin + '/images/tool_serial_number.jpg'} />
            </Col>
          </Row>
          <p>
            <FormattedMessage id='productDetail.toolInfo.serialNumberModalBody2' />
          </p>
        </ModalBody>
        <ModalFooter>
          <Button data-test-id='productDetailSerialNumberModalGeneralOKButton' onClick={toggleSerialNumberModal}>
            <FormattedMessage id='general.ok' />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  return (
    <Fragment>
      {toolInfoPlaceholderMarkup}
      {toolInfoMarkup}
      {underWarrantyModalMarkup}
      <LmrDefinitionModal isVisible={showLmrModal} toggle={() => toggleLmrModal()} />
      {proofOfPurchaseModalMarkup}
      {serialNumberModalMarkup}
    </Fragment>
  );
};

export default ToolInfo;
