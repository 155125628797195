import { shallowEqual, useSelector } from 'react-redux';
import { shortenEmail } from '../helpers/formatter';
import { decodeMetSession, getUserMetadata } from '../helpers/sessionStorage';

const useUser = () => {
  const { firstName, lastName, phoneNumber } = getUserMetadata();

  const user = useSelector(state => state.users.user, shallowEqual);
  const loadingUser = useSelector(state => state.users.getCurrentUser.isLoading);
  const errorLoadingUser = useSelector(state => state.users.getCurrentUser.isError);
  const email = useSelector(state => state.users.user.email);
  const accountNumber = useSelector(state => state.users.user && state.users.user.accountNumber);
  const requestedAccountNumber = useSelector(state => state.users.user && state.users.user.requestedAccountNumber);
  const acceptedTermsAndConditions = useSelector(
    state => state.users.user && state.users.user.acceptedTermsAndConditions
  );
  const preferredContactMethod = useSelector(state => state.users.user && state.users.user.preferredContactMethod);
  const preferredLanguage = useSelector(state => state.users.user && state.users.user.preferredLanguage);
  const userEmails = useSelector(state => state.users.userEmails);
  const loadingUserEmails = useSelector(state => state.users.getUserEmails.isLoading);
  const updatingUserMetadata = useSelector(state => state.userMetadata.updateUserMetadata.isLoading);
  const updatingUserNotifications = useSelector(state => state.users.upsertUserNotifications.isLoading);
  const updatingPreferredContactMethod = useSelector(state => state.users.updatePreferredContactMethod.isLoading);

  const userMetadataIncomplete = () => {
    return !firstName || !lastName || !phoneNumber;
  };

  const isSuperAdmin = () => {
    let metSession = decodeMetSession();

    if (metSession && metSession.role) {
      return metSession.role.includes('SuperAdmin');
    }

    return false;
  };

  const isLmrAdmin = () => {
    let metSession = decodeMetSession();

    if (metSession && metSession.role) {
      return metSession.role.includes('LmrAdmin');
    }

    return false;
  };

  const isServiceMappingsAdmin = () => {
    let metSession = decodeMetSession();

    if (metSession && metSession.role) {
      return metSession.role.includes('ServiceMappingsAdmin');
    }

    return false;
  };

  const isServiceOrdersAdmin = () => {
    let metSession = decodeMetSession();

    if (metSession && metSession.role) {
      return metSession.role.includes('ServiceOrdersAdmin');
    }

    return false;
  };

  const permissions = () => {
    let metSession = decodeMetSession();

    if (metSession && metSession.permission) {
      let permissions = {};
      if (metSession.permission instanceof Array) {
        for (let i = 0; i < metSession.permission.length; i++) {
          permissions[metSession.permission[i]] = true;
        }
      } else {
        permissions[metSession.permission] = true;
      }
      return permissions;
    }
    return {};
  };

  const hasPermission = permissionRequired => {
    if (isSuperAdmin()) return true;

    switch (permissionRequired) {
      case 'products':
        if (isLmrAdmin() || isServiceMappingsAdmin()) return true;
        break;
      case 'products.lmr':
        if (isLmrAdmin()) return true;
        break;
      case 'products.inclusion':
        if (isLmrAdmin()) return true;
        break;
      case 'products.problems':
        if (isLmrAdmin()) return true;
        break;
      case 'products.state':
        if (isServiceMappingsAdmin()) return true;
        break;
      case 'serviceCenters':
        if (isServiceMappingsAdmin()) return true;
        break;
      case 'serviceCenters.state':
        if (isServiceMappingsAdmin()) return true;
        break;
      case 'serviceCenters.serviceCenters':
        if (isSuperAdmin()) return true;
        break;
      case 'productProblems':
        if (isLmrAdmin()) return true;
        break;
      case 'productIncluded':
        if (isSuperAdmin()) return true;
        break;
      case 'serviceOrders':
        if (isServiceOrdersAdmin()) return true;
        break;
      case 'admin':
      default:
        if (isLmrAdmin() || isServiceMappingsAdmin() || isServiceOrdersAdmin())
          return true;
        break;
    }
    return false;
  };

  return {
    user,
    loadingUser,
    errorLoadingUser,
    userEmails,
    loadingUserEmails,
    acceptedTermsAndConditions,
    preferredContactMethod,
    preferredLanguage,
    isAdmin:
      isSuperAdmin() ||
      isLmrAdmin() ||
      isServiceMappingsAdmin() ||
      isServiceOrdersAdmin(),
    isSuperAdmin: isSuperAdmin,
    isServiceMappingsAdmin: isServiceMappingsAdmin,
    isServiceOrdersAdmin: isServiceOrdersAdmin,
    isLmrAdmin: isLmrAdmin,
    hasPermission: hasPermission,
    permissions: permissions,
    email,
    shortEmail: shortenEmail(email),
    accountNumber,
    requestedAccountNumber,
    firstName: firstName || '',
    lastName: lastName || '',
    phoneNumber: phoneNumber || '',
    userMetadataIncomplete,
    name: firstName && lastName ? firstName + ' ' + lastName : '',
    updatingUserMetadata,
    updatingUserNotifications,
    updatingPreferredContactMethod,
  };
};

export default useUser;
