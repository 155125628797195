import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout, Menu } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { clearSession } from '../../helpers/sessionStorage';
import { useUser, useWindowSize } from '../../hooks';
import { useBaseContext } from '../Layout/BaseLayout/BaseContext';
import { CaretDownOutlined, MenuOutlined } from '@ant-design/icons';
import {
  DesktopAccountWrapper,
  DesktopNav,
  HeaderContainer,
  Label,
  LabelWrapper,
  LogoWrapper,
  MobileDrawerMenu,
  MobileLabelWrapper,
  MobileNav,
  StyledDrawer,
  StyledHeader,
  TopNavMenu,
  TopSubMenu,
  UppercaseText,
} from './styled';
import { Hamburger } from '../Hamburger';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

export const generateLeftItems = (authenticationComplete) => {
  return [{ label: 'Dashboard', to: '/dashboard', hidden: !authenticationComplete }];
};

export const generateRightItems = (
  isAuthenticated,
  authenticationComplete,
  isAdmin,
  loginWithRedirect,
  handleLogout
) => {
  return [
    {
      label: 'Admin',
      to: '/admin',
      hidden: !authenticationComplete || !isAdmin,
    },
    {
      label: 'Preferences',
      to: '/preferences',
      hidden: !authenticationComplete,
    },
    {
      divider: true,
      hidden: !authenticationComplete,
    },
    {
      label: 'Log In / Sign Up',
      onClick: () => loginWithRedirect(),
      hidden: isAuthenticated,
    },
    {
      label: 'Sign Out',
      onClick: () => handleLogout(),
      hidden: !isAuthenticated,
    },
  ];
};

export const NavMenu = () => {
  const history = useHistory();

  const { email, shortEmail, isAdmin, name } = useUser();
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();
  const { authenticationError } = useBaseContext();
  const [authenticationComplete, setAuthenticationComplete] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const windowSize = useWindowSize();

  useEffect(() => {
    let newAuthenticationState = isAuthenticated && email && !authenticationError;
    setAuthenticationComplete(newAuthenticationState);
  }, [isAuthenticated, email, authenticationError]);

  const handleLogout = () => {
    clearSession();
    logout({ returnTo: window.location.origin });
  };

  const handleNavBarClick = (e) => {
    if (e) {
      switch (e.key) {
        case 'dashboard':
          history.push('/dashboard');
          return;
        case 'admin':
          history.push('/admin');
          return;
        case 'preferences':
          history.push('/preferences');
          return;
        case 'login':
          loginWithRedirect();
          return;
        case 'logout':
          handleLogout();
          return;
        case 'home':
        default:
          history.push('/');
          return;
      }
    }
  };

  const dashboardButton = authenticationComplete && (
    <Menu.Item data-test-id='navOptionDashboard' key='dashboard'>
      <UppercaseText>
        <FormattedMessage id='navigation.dashboard' />
      </UppercaseText>
    </Menu.Item>
  );

  const subMenulabel = authenticationComplete ? (
    <LabelWrapper data-test-id='navOptionUser' className='subMenu'>
      <FontAwesomeIcon icon={faUserCircle} style={{ height: '25px' }} className='mr-2' />
      <Label style={{ marginRight: '5px' }}>{name ? name : shortEmail}</Label>
      <CaretDownOutlined />
    </LabelWrapper>
  ) : (
    <LabelWrapper data-test-id='navOptionUser' className='subMenu'>
      <FontAwesomeIcon icon={faUserCircle} style={{ height: '25px' }} className='mr-2' />
      <CaretDownOutlined />
    </LabelWrapper>
  );

  const mobileDashboardButton = authenticationComplete && (
    <Menu.Item data-test-id='navOptionDashboard' key='dashboard'>
      <UppercaseText>
        <FormattedMessage id='navigation.dashboard' />
      </UppercaseText>
    </Menu.Item>
  );

  const subMenulabelMobile = authenticationComplete ? (
    <MobileLabelWrapper data-test-id='navOptionUser'>
      <FontAwesomeIcon icon={faUserCircle} style={{ height: '25px' }} className='mr-2' />
      <Label>{name ? name : shortEmail}</Label>
    </MobileLabelWrapper>
  ) : (
    <MobileLabelWrapper data-test-id='navOptionUser'>
      <FontAwesomeIcon icon={faUserCircle} style={{ height: '25px' }} className='mr-2' />
    </MobileLabelWrapper>
  );

  const adminMenuItem = isAdmin && (
    <Menu.Item data-test-id='navOptionAdmin' key='admin'>
      <UppercaseText>
        <FormattedMessage id='navigation.admin' />
      </UppercaseText>
    </Menu.Item>
  );

  const preferencesMenuItem = authenticationComplete && (
    <Menu.Item data-test-id='navOptionPreferences' key='preferences'>
      <UppercaseText>
        <FormattedMessage id='navigation.preferences' />
      </UppercaseText>
    </Menu.Item>
  );

  const signInMenuItem = !isAuthenticated && (
    <Menu.Item data-test-id='navOptionSignIn' key='login'>
      <UppercaseText>
        <FormattedMessage id='navigation.loginSignup' />
      </UppercaseText>
    </Menu.Item>
  );

  const logoutMenuItem = isAuthenticated && (
    <Menu.Item data-test-id='navOptionLogOut' key='logout'>
      <UppercaseText>
        <FormattedMessage id='navigation.signOut' />
      </UppercaseText>
    </Menu.Item>
  );

  const mobileNavTitle = (
    <MobileDrawerMenu
      data-test-id='navbarMenuMobileDrawer'
      onClick={handleNavBarClick}
      mode='vertical'
      overflowedIndicator={<MenuOutlined />}
      expandIcon={<CaretDownOutlined />}
    >
      <Menu.ItemGroup>{mobileDashboardButton}</Menu.ItemGroup>
    </MobileDrawerMenu>
  );

  const desktopNav = (
    <DesktopNav>
      <Layout.Header
        style={{
          zIndex: 1,
          width: '100%',
          background: '#db011c',
          boxShadow: '0 0 15px 0 #6c757d',
          height: '84px',
          padding: '10px 25px',
        }}
      >
        <HeaderContainer>
          <LogoWrapper
            data-test-id='navbarLogoWrapper'
            onClick={() => {
              handleNavBarClick('home');
            }}
          >
            <img src={'/static/media/logo-milwaukeetool.f5a3dda2.svg'} height='67' width='124' alt='logo' />
          </LogoWrapper>
          <TopNavMenu
            data-test-id='navbarTopNavMenuDashboardText'
            mode='horizontal'
            theme='light'
            onClick={handleNavBarClick}
            disabledOverflow={true}
          >
            {dashboardButton}
          </TopNavMenu>
          <DesktopAccountWrapper>
            <TopNavMenu
              data-test-id='navbarTopNavMenuUserMenu'
              mode='horizontal'
              subMenuCloseDelay={0.2}
              theme='light'
              onClick={handleNavBarClick}
              id='userMENU'
              disabledOverflow={true}
            >
              <TopSubMenu title={subMenulabel} key='accountSubMenu'>
                {adminMenuItem}
                {preferencesMenuItem}
                <Menu.Divider />
                {signInMenuItem}
                {logoutMenuItem}
              </TopSubMenu>
            </TopNavMenu>
          </DesktopAccountWrapper>
        </HeaderContainer>
      </Layout.Header>
    </DesktopNav>
  );

  const mobileNav = (
    <MobileNav>
      <Layout.Header
        style={{
          zIndex: 1,
          width: '100%',
          background: '#db011c',
          boxShadow: '0 0 15px 0 #6c757d',
          height: '84px',
          padding: '10px 25px',
        }}
      >
        <HeaderContainer>
          <LogoWrapper
            data-test-id='navbarMenuHome'
            onClick={() => {
              handleNavBarClick('home');
            }}
          >
            <img src={'/static/media/logo-milwaukeetool.f5a3dda2.svg'} height='67' width='124' alt='logo' />
          </LogoWrapper>

          <Hamburger
            data-test-id='navbarHamburgerOpenDrawer'
            onClick={() => setShowDrawer(!showDrawer)}
            open={showDrawer}
          />

          <StyledDrawer
            placement='right'
            closable={false}
            onClose={() => setShowDrawer(false)}
            open={showDrawer}
            title={mobileNavTitle}
            bodyStyle={{ paddingTop: '0px' }}
          >
            <MobileDrawerMenu
              data-test-id='navbarMenuOutlinedCarot'
              onClick={handleNavBarClick}
              mode='vertical'
              overflowedIndicator={<MenuOutlined />}
              expandIcon={<CaretDownOutlined />}
            >
              <Menu.ItemGroup>
                {subMenulabelMobile}
                {adminMenuItem}
                {preferencesMenuItem}
                <Menu.Divider />
                {signInMenuItem}
                {logoutMenuItem}
              </Menu.ItemGroup>
            </MobileDrawerMenu>
          </StyledDrawer>
        </HeaderContainer>
      </Layout.Header>
    </MobileNav>
  );
  return (
    <Fragment>
      {mobileNav}
      {desktopNav}
    </Fragment>
  );
};
