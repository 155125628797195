import { getLocale, setLocale } from '../../helpers/sessionStorage';
import translation from '../../locales/messages';
import { DEFAULT_LOCALE, flattenMessages } from '../../locales/utils';
import { UPDATE_LOCALE, NAVIGATION } from '../types';

export const generateInitialState = () => {
  const browserLanguage = window.navigator.language.split('-')[0];
  const localeFromStorage = getLocale();
  let locale;
  let messages;
  let languages;

  if (window.translations) {
    languages = Object.keys(window.translations);
    // a language stored in local storage has priority
    if (localeFromStorage && window.translations[localeFromStorage]) {
      locale = localeFromStorage;
      messages = window.translations[localeFromStorage];
    }
    // then let's check for the shortened language (ex. en, es, fr instead of en-US, es-MX)
    else if (window.translations[browserLanguage]) {
      locale = browserLanguage;
      messages = window.translations[browserLanguage];
    }
    // if we still don't have a match, check for the whole navigator language (ex. en-US, es-MX, zh-CN)
    else if (window.translations[navigator.language]) {
      locale = navigator.language;
      messages = window.translations[navigator.language];
    }
    // if no matches, fallback to our default locale
    else {
      locale = DEFAULT_LOCALE;
      messages = flattenMessages(translation[DEFAULT_LOCALE]);
    }
  } else {
    languages = ['en'];
    locale = DEFAULT_LOCALE;
    messages = flattenMessages(translation[DEFAULT_LOCALE]);
  }

  return {
    locale,
    messages,
    languages,
  };
};

//breaking the generation of the initial state into it's own method so we can unit test it better.
export const initialState = generateInitialState();

export default (state, action) => {
  state = state || initialState;
  if (action.type === UPDATE_LOCALE) {
    setLocale(action.locale);
    return {
      ...state,
      locale: window.translations && window.translations[action.locale] ? action.locale : DEFAULT_LOCALE,
      messages: window.translations
        ? window.translations[action.locale] || window.translations[DEFAULT_LOCALE]
        : flattenMessages(translation[DEFAULT_LOCALE]),
    };
  } else {
    return state;
  }
};
