import React, {useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {squareActions} from '../../store';
import {ActionLinks, CreditCardContainer, DetailsContainer, DisclaimerText, FullHeightRow} from './styled';
import {Col, message, Popconfirm, Row, Space, Typography} from 'antd';
import {CreditCardOutlined} from '@ant-design/icons';
import {PageLoaderJr} from '../../components';
import {NewCreditCard, StyledFontAwesomeIcon} from '../../components/SquarePayments/styled';
import AddCreditCardModal from '../../components/SquarePayments/Add/AddCreditCardModal';
import {useToggle} from '../../hooks';
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import PageError from "../../components/PageError/PageError";

const { Text } = Typography;

const PaymentMethod = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [showAddCreditCard, toggleShowAddCreditCard] = useToggle(false);

  const squareApplicationId = useSelector(state => state.square.squareApplicationId);
  const userCreditCards = useSelector(state => state.square.creditCards);
  const creditCardsIsLoading = useSelector(state => state.square.getCreditCards.isLoading);
  const squareApplicationIsLoading = useSelector(state => state.square.getSquareConfig.isLoading);
  const squareApplicationIsError = useSelector(state => state.square.getSquareConfig.isError);
  
  useEffect(() => {
    if (!squareApplicationId) {
      dispatch(squareActions.getSquareConfig()).catch(e => {
        message.error(intl.formatMessage({ id: 'general.genericError' }));
      });
    } else {
      getUserCreditCards();
    }
  }, [squareApplicationId]);

  const getUserCreditCards = () => {
    dispatch(squareActions.getCreditCards())
      .then(() => {})
      .catch(e => {
        message.error(intl.formatMessage({ id: 'square.getPaymentMethodError' }));
      });
  };

  const deleteCard = cardId => {
    dispatch(squareActions.deleteCreditCard(cardId)).then(() => {
      getUserCreditCards();
      message.success(intl.formatMessage({ id: 'square.deletePaymentMethodSuccess' }));
    }).catch(() => {
      message.error(intl.formatMessage({ id: 'square.deletePaymentMethodError' }))
    });
  };

  const handleCreditCardAdded = (cardData, nonce, cardholderNameValue, saveCard) => {
    dispatch(squareActions.addCreditCard(cardData.billing_postal_code, cardholderNameValue, nonce))
      .then(r => {
        toggleShowAddCreditCard();
        getUserCreditCards();
        message.success(intl.formatMessage({ id: 'square.paymentMethodAddedSuccess' }));
      })
      .catch(e => {
        message.error(intl.formatMessage({ id: 'square.addCreditCardError' }));
      });
  };

  const creditCardList = userCreditCards.map((item, index) => {
    return (
      <Col xs={24} md={8} key={'creditCardContainer_' + index}>
        <CreditCardContainer>
          <Space direction={'vertical'} size={[1, 1]}>
            <CreditCardOutlined style={{ fontSize: '24px' }} />
            <Text strong>{item?.cardholderName}</Text>
            <Text>****{item?.cardLast4}</Text>
            <Text>
              {item?.cardExpMonth}/{item?.cardExpYear}
            </Text>
            <Popconfirm title={intl.formatMessage({ id: 'general.areYouSure' })} onConfirm={() => deleteCard(item?.id)}>
              <ActionLinks direction={'horizontal'}>
                <Text>
                  <FormattedMessage id='general.delete' />
                </Text>
              </ActionLinks>
            </Popconfirm>
          </Space>
        </CreditCardContainer>
      </Col>
    );
  });
  
  const newCardMarkup = (!squareApplicationIsLoading && !squareApplicationIsError) && (
    <Col xs={24} md={8}>
      <NewCreditCard
        data-test-id='addNewCreditCardOption'
        onClick={() => toggleShowAddCreditCard()}
      >
        <FullHeightRow>
          <Col>
            <Space direction={'vertical'} size={[0, 0]}>
              <StyledFontAwesomeIcon icon={faPlus} size='lg'/>
              <Text type='secondary' strong>
                <FormattedMessage id='square.addCard'/>
              </Text>
            </Space>
          </Col>
        </FullHeightRow>
      </NewCreditCard>
    </Col>
  );
  
  const disclaimerText = !squareApplicationIsError && (
    <>
      <Text>
        <FormattedMessage id='profile.paymentMethodTab.paymentMethodIntro' />
      </Text>
      <DisclaimerText>
        <FormattedMessage id='profile.paymentMethodTab.usCustomerDisclaimer' />
      </DisclaimerText>
    </>
  );
  
  const creditCardListMarkup = !squareApplicationIsError && (
    <DetailsContainer>
      <Row gutter={[16, 16]}>
        {newCardMarkup}
        <AddCreditCardModal
          isVisible={showAddCreditCard}
          onCreditCardAdded={handleCreditCardAdded}
          toggleIsVisible={() => toggleShowAddCreditCard()}
        />
        {creditCardList}
      </Row>
    </DetailsContainer>
  );
  
  const errorLoadingSquare = squareApplicationIsError && (
    <PageError
      title={intl.formatMessage({ id: 'general.genericError' })}
    />
  );

  if (creditCardsIsLoading || squareApplicationIsLoading) {
    return (
      <PageLoaderJr />
    );
  }

  return (
    <>
      {disclaimerText}
      {creditCardListMarkup}
      {errorLoadingSquare}
    </>
  );
};

export default PaymentMethod;
