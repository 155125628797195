import styled from 'styled-components';
import { CloseCircleFilled } from '@ant-design/icons';
export const Wrapper = styled.div`
  min-height: 100%;
  height: 100%;
`;

export const StyledIcon = styled(CloseCircleFilled)`
  font-size: 50px;
  color: gray;
  margin-bottom: 12px;
`;
