import { Button, Col, Grid, Row, useToggle } from '@met/react-components';
import Enumerable from 'linq';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { ProductSearchModal } from '../../../components/ProductSearchModal';
import { handleNavigateAway } from '../../../helpers/navigation';
import { useUser } from '../../../hooks';
import { serviceOrdersActions } from '../../../store';
import ConfirmDeleteModal from '../components/ConfirmDeleteModal';
import PackagesSummary from '../components/PackagesSummary';
import RepairItemsSummary from '../components/RepairItemsSummary';
import ShippingInfoSummary from '../components/ShippingInfoSummary';
import { useRepairProcessContext } from '../RepairProcessContext';
import BatteryInstructionsModal from './components/BatteryInstructionsModal';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { UserMetadataModal } from '../../../components';

const Confirmation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const appInsights = useAppInsightsContext();

  const { user, email, name, phoneNumber, preferredContactMethod, accountNumber } = useUser();

  const { submittingServiceOrder, repairItems, setRepairItems, getServiceOrder, shipping } = useRepairProcessContext();

  const [showSearchModal, toggleSearchModal] = useToggle(false);
  const [showBatteryInstructionsModal, toggleBatteryInstructionsModal] = useToggle(false);
  const [showConfirmDeleteModal, toggleConfirmDeleteModal] = useToggle(false);
  const [showUserMetadataModal, toggleUserMetadataModal] = useToggle(false);

  const [deleteGuid, setDeleteGuid] = useState(null);

  const handleEditRepairItemClick = guid => {
    history.push(`/repairprocess/productdetail/?g=${guid}`);
  };

  const deleteRepairItem = () => {
    toggleConfirmDeleteModal();
    setRepairItems(repairItems.filter((r, i) => r.guid !== deleteGuid));
    setDeleteGuid(null);
  };

  const handleOpenDeleteModal = guid => {
    toggleConfirmDeleteModal();
    setDeleteGuid(guid);
  };

  useEffect(() => {
    if (repairItems.length === 0) {
      history.replace('/repairprocess/addanother');
    }
  }, []);

  const handleSubmitClick = () => {
    let batteryIncluded =
      Enumerable.from(repairItems).firstOrDefault(x => x.batteryIncluded !== '') ||
      Enumerable.from(repairItems).firstOrDefault(x => x.isBattery);

    if (batteryIncluded) {
      toggleBatteryInstructionsModal();
    } else {
      submit();
    }
  };

  const submit = () => {
    let serviceOrderData = getServiceOrder();

    appInsights.trackEvent({
      name: 'createServiceOrder',
      properties: {
        serviceOrderData,
        user,
      },
    });

    dispatch(serviceOrdersActions.submitServiceOrder(serviceOrderData))
      .then(response => {
        toast.success(intl.formatMessage({ id: 'confirmation.submitted' }));

        history.push(`/repairprocess/ordersummary/${response.data.data}`);
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'confirmation.submittedError' }));
      });
  };

  const shippingSummaryMarkup = shipping && shipping.shippingAddress && (
    <Col>
      <ShippingInfoSummary
        shippingAddress={shipping.shippingAddress}
        name={name}
        email={email}
        phoneNumber={phoneNumber}
        accountNumber={accountNumber}
        preferredContactMethod={intl.formatMessage({
          id: `profile.preferredContactMethods.label${preferredContactMethod}`,
        })}
        toggleUserMetadataModal={toggleUserMetadataModal}
        editable
      />
    </Col>
  );

  const repairItemsMarkup = repairItems && (
    <Fragment>
      <Row>
        <Col middle='xs'>
          <h3 className='m-0'>
            <FormattedMessage id='confirmation.repairItemsLabel' />
          </h3>
        </Col>
        <Col xs='auto' middle='xs'>
          <Button data-test-id='confirmationAddToolButton' icon='plus' primary onClick={() => toggleSearchModal()}>
            <FormattedMessage id='general.addTool' />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <RepairItemsSummary
            repairItems={repairItems}
            handleEditClick={handleEditRepairItemClick}
            handleOpenDeleteModal={handleOpenDeleteModal}
          />
        </Col>
      </Row>
    </Fragment>
  );

  return (
    <Fragment>
      <Grid>
        <Row>
          <Col>
            <h1>
              <FormattedMessage id='confirmation.confirmationTitle' />
            </h1>
          </Col>
        </Row>
        <Row>{shippingSummaryMarkup}</Row>
        {repairItemsMarkup}
        <Row>
          <Col end='xs'>
            <div className='mt-2'>
              <Button
                data-test-id='confirmationPageBackButton'
                secondary
                onClick={() => history.push('/repairprocess/shipping')}
                loading={submittingServiceOrder}
                className='mr-2'
              >
                <FormattedMessage id='general.back' />
              </Button>
              <Button
                primary
                onClick={() => handleSubmitClick()}
                loading={submittingServiceOrder}
                data-test-id='confirmationPageSubmitButton'
                disabled={repairItems.length < 1}
              >
                <FormattedMessage id='general.submit' />
              </Button>
            </div>
          </Col>
        </Row>
      </Grid>
      <ProductSearchModal hidden={!showSearchModal} toggle={toggleSearchModal} />
      <BatteryInstructionsModal
        hidden={!showBatteryInstructionsModal}
        toggle={toggleBatteryInstructionsModal}
        handleSubmitClick={submit}
        loading={submittingServiceOrder}
      />
      <Prompt
        message={location =>
          handleNavigateAway(
            location,
            repairItems.length > 0,
            intl.formatMessage({ id: 'general.unsavedChangesWarning' })
          )
        }
      />
      <ConfirmDeleteModal
        hidden={!showConfirmDeleteModal}
        toggle={toggleConfirmDeleteModal}
        deleteRepairItem={deleteRepairItem}
      />
      <UserMetadataModal hidden={!showUserMetadataModal} toggle={toggleUserMetadataModal} />
    </Fragment>
  );
};

export default Confirmation;
