import { Button, Input, Radio, Space, Typography } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocale, useServiceOrder, useUser, useToggle } from '../../../../hooks';
import LmrOptions from '../../../../components/LmrOptions';
import { Icon } from '@met/react-components';
import LmrDefinitionModal from '../../../RepairProcess/RepairItems/components/WarrantyInformation/components/LmrDefinitionModal';

const { Paragraph, Text } = Typography;

const DeniedWarrantyActions = ({ onSubmit }) => {
  const intl = useIntl();
  const { serviceOrder } = useServiceOrder();
  const { isCaLocale } = useLocale();
  const { email, phoneNumber, accountNumber, preferredContactMethod } = useUser();
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [poNumber, setPoNumber] = useState(null);
  const [formIsValid, setFormIsValid] = useState(false);
  const [showLmrModal, toggleLmrModal] = useToggle(false);

  const canadaLmrLines = serviceOrder && (
    <LmrOptions
      lmrMappings={serviceOrder.productLmrMappings}
      country='ca'
      style={{ fontWeight: '600', color: '#db011c' }}
    />
  );

  const usLmrLines = serviceOrder && (
    <LmrOptions
      lmrMappings={serviceOrder.productLmrMappings}
      country='us'
      style={{ fontWeight: '600', color: '#db011c' }}
    />
  );

  const warrantyLines = isCaLocale() ? canadaLmrLines : usLmrLines;

  useEffect(() => {
    setSelectedPaymentMethod(null);
  }, [selectedAction]);

  useEffect(() => {
    setPoNumber(null);
  }, [selectedPaymentMethod]);

  useEffect(() => {
    setFormIsValid(false);

    if ([2, 3].includes(selectedAction)) {
      // Return or Recycle are selected
      setFormIsValid(true);
    } else {
      // Repair is selected
      if (selectedPaymentMethod === 1) {
        // Credit Card is selected
        setFormIsValid(true);
      } else {
        // PO Number is selected
        if (poNumber) {
          setFormIsValid(true);
        }
      }
    }
  }, [selectedAction, selectedPaymentMethod, poNumber]);

  const handleSubmit = () => {
    let deniedWarrantyState = {
      action: selectedAction,
      paymentMethod: selectedPaymentMethod,
      poNumber: poNumber,
    };

    onSubmit(deniedWarrantyState);
  };

  const contactMethodDescription =
    preferredContactMethod === 1 ? (
      <FormattedMessage id='repairDetails.deniedWarranty.contactViaEmail' values={{ email: email }} />
    ) : (
      <FormattedMessage id='repairDetails.deniedWarranty.contactViaPhone' values={{ phoneNumber: phoneNumber }} />
    );

  const ccPaymentDescription = selectedAction === 1 && selectedPaymentMethod === 1 && (
    <Space direction='vertical'>
      <Text>{contactMethodDescription}</Text>
      <a data-test-id='deniedWarrantyChangePreferencesLink' href='/preferences' target='_self'>
        <FormattedMessage id='repairDetails.deniedWarranty.changePreferences' />
      </a>
    </Space>
  );

  const poPaymentDescription = selectedAction === 1 && selectedPaymentMethod === 2 && (
    <Space direction='vertical'>
      <Input
        data-test-id='repairDetailsDeniedWarrantyPOPlaceholderInput'
        onChange={e => setPoNumber(e.target.value)}
        placeholder={intl.formatMessage({ id: 'repairDetails.deniedWarranty.poNumberPlaceholder' })}
      />
      <Paragraph>
        <FormattedMessage id='repairDetails.deniedWarranty.poNumberNotDescription' />
      </Paragraph>
    </Space>
  );

  // Hide PO payment option if user has no account number
  const poPaymentOptionMarkup = accountNumber && (
    <Radio value={2}>
      <Space direction='vertical'>
        <FormattedMessage id='repairDetails.deniedWarranty.poLabel' />
        {poPaymentDescription}
      </Space>
    </Radio>
  );

  const paymentOptionsMarkup = selectedAction === 1 && (
    <Radio.Group data-test-id='deniedWarrantyRadioButtonCCGroup' onChange={e => setSelectedPaymentMethod(e.target.value)}>
      <Space direction='vertical'>
        <Radio value={1}>
          <Space direction='vertical'>
            <FormattedMessage id='repairDetails.deniedWarranty.ccLabel' />
            {ccPaymentDescription}
          </Space>
        </Radio>
        {poPaymentOptionMarkup}
      </Space>
    </Radio.Group>
  );

  const repairDescription = <FormattedMessage id='repairDetails.deniedWarranty.lmrDescription' />;

  const lmrMarkup = selectedAction === 1 && warrantyLines && (
    <Fragment>
      <Text>
        <FormattedMessage id='productDetail.toolInfo.lmrTitle' />
        <Icon data-test-id='deniedWarrantyLMRToolTitleIcon' className='ml-2' type='question-circle' xs onClick={toggleLmrModal} />
      </Text>
      {warrantyLines}
    </Fragment>
  );

  return (
    <Space direction='vertical'>
      <Radio.Group data-test-id='deniedWarrantyRepairLabelRadioGroup' onChange={e => setSelectedAction(e.target.value)}>
        <Space direction='vertical'>
          <Radio value={1}>
            <Space direction='vertical'>
              <Text>
                <FormattedMessage id='repairDetails.deniedWarranty.repairLabel' />
              </Text>
              <Text type={selectedAction === 1 ? 'primary' : 'secondary'}>{repairDescription}</Text>
              {lmrMarkup}
              <LmrDefinitionModal isVisible={showLmrModal} toggle={() => toggleLmrModal()} />
              {paymentOptionsMarkup}
            </Space>
          </Radio>
          <Radio value={2}>
            <Space direction='vertical'>
              <Text>
                <FormattedMessage id='repairDetails.deniedWarranty.returnLabel' />
              </Text>
              <Text type={selectedAction === 2 ? 'primary' : 'secondary'}>
                <FormattedMessage id='repairDetails.deniedWarranty.returnDescription' />
              </Text>
            </Space>
          </Radio>
          <Radio value={3}>
            <Space direction='vertical'>
              <Text>
                <FormattedMessage id='repairDetails.deniedWarranty.recycleLabel' />
              </Text>
              <Text type={selectedAction === 3 ? 'primary' : 'secondary'}>
                <FormattedMessage id='repairDetails.deniedWarranty.recycleDescription' />
              </Text>
            </Space>
          </Radio>
        </Space>
      </Radio.Group>
      <Button data-test-id='deniedWarrantySubmitOptionButton' type='primary' disabled={!formIsValid} onClick={() => handleSubmit()}>
        <FormattedMessage id='general.submit' />
      </Button>
    </Space>
  );
};

export default DeniedWarrantyActions;
