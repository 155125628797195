import { LOADING_CHATSCHEDULE, SYNC_PRODUCT_INCLUSIONS_TO_INTL, SYNC_PRODUCT_PROBLEMS_TO_INTL } from '../types';
import { error, success } from '../utils';

export let initialState = {
  syncProductProblemsToIntl: {
    isLoading: false,
    isError: false,
  },
  syncProductInclusionsToIntl: {
    isLoading: false,
    isError: false,
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case SYNC_PRODUCT_PROBLEMS_TO_INTL:
      return {
        ...state,
        syncProductProblemsToIntl: {
          isLoading: true,
          isError: false,
        },
      };
    case success(SYNC_PRODUCT_PROBLEMS_TO_INTL):
      return {
        ...state,
        syncProductProblemsToIntl: {
          isLoading: false,
          isError: false,
        },
      };
    case error(SYNC_PRODUCT_PROBLEMS_TO_INTL):
      return {
        ...state,
        syncProductProblemsToIntl: {
          isLoading: false,
          isError: true,
        },
      };

    case SYNC_PRODUCT_INCLUSIONS_TO_INTL:
      return {
        ...state,
        syncProductInclusionsToIntl: {
          isLoading: true,
          isError: false,
        },
      };
    case success(SYNC_PRODUCT_INCLUSIONS_TO_INTL):
      return {
        ...state,
        syncProductInclusionsToIntl: {
          isLoading: false,
          isError: false,
        },
      };
    case error(SYNC_PRODUCT_INCLUSIONS_TO_INTL):
      return {
        ...state,
        syncProductInclusionsToIntl: {
          isLoading: false,
          isError: true,
        },
      };
    default:
      return state;
  }
};

export default reducer;
