import { Button, Card, Col, InputNumber, Row, Typography, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useShippingContext } from '../ShippingContext';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import MaxPackagesAlertModal from './MaxPackagesAlertModal';

const isAddressCanadian = address => {
  if (address.countryCode) {
    return address.countryCode.toLowerCase() === 'can' || address.countryCode.toLowerCase() === 'ca';
  } else {
    return false;
  }
};

const Packages = () => {
  const minPackages = 1;
  const { Text } = Typography;

  const {
    packagesCount,
    setPackagesCount,
    packagesCountInvalid,
    setPackagesCountInvalid,
    shippingAddress,
  } = useShippingContext();

  const [isEnabled, setIsEnabled] = useState(true);
  const [maxPackages, setMaxPackages] = useState(8);
  const [shouldShowMaxPackagesModal, setShouldShowMaxPackagesModal] = useState(false);

  useEffect(() => {
    setPackagesCountInvalid(false);
  }, [packagesCount]);

  useEffect(() => {
    if (shippingAddress && isAddressCanadian(shippingAddress)) {
      setIsEnabled(false);
      setMaxPackages(1);
    } else {
      setMaxPackages(8);
      setIsEnabled(true);
    }
  }, [shippingAddress]);

  const handleMaxPackagesModalClick = e => {
    setShouldShowMaxPackagesModal(false);
  };

  const handlePackagesCountChange = updatedPackagesCount => {
    if (!updatedPackagesCount) return;
    if (packagesCount !== updatedPackagesCount) {
      if (updatedPackagesCount > maxPackages) {
        setShouldShowMaxPackagesModal(true);
        setPackagesCount(maxPackages);
      } else {
        setPackagesCount(updatedPackagesCount);
      }
    }
  };

  const maxPackagesAlert = shouldShowMaxPackagesModal && (
    <MaxPackagesAlertModal showModal={shouldShowMaxPackagesModal} handleConfirmClick={handleMaxPackagesModalClick} />
  );

  const warningMarkup = packagesCountInvalid && (
    <Row>
      <Text>
        <FormattedMessage id='shipping.packagesCountRequired' />
      </Text>
    </Row>
  );

  return (
    <Card>
      <div>
        <Row>
          <Col>
            <h3>
              <FormattedMessage id='shipping.numberOfPackages' />
            </h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md='auto' middle='xs'>
            <div className='mr-3'>
              <InputNumber
                data-test-id='packages-count-input'
                size='large'
                min={1}
                defaultValue={1}
                onChange={e => handlePackagesCountChange(e)}
                disabled={!isEnabled}
                value={packagesCount}
              />
            </div>
          </Col>
          <Col xs={12} md middle='xs'>
            <div className='mt-xs-2 mt-md-0'>
              <Text>
                <FormattedMessage id='shipping.maximumBatteries1' />
              </Text>
              <br />
              <Text>
                <FormattedMessage id='shipping.maximumBatteries2' />
              </Text>
            </div>
          </Col>
        </Row>
        {warningMarkup}
      </div>
      {maxPackagesAlert}
    </Card>
  );
};

export default Packages;
