import { CheckCircleTwoTone, MinusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Layout, List, PageHeader, Row, Select, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import { useAddresses } from '../../../../hooks';
import { productsActions, serviceOrdersActions, statesActions } from '../../../../store';
import RoutingResult from './RoutingResult';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const RoutingRulesTab = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { loadingStates, states, userAddresses, defaultUserAddress } = useAddresses();
  const [stateOrigin, setStateOrigin] = useState('');
  const [selectedOption, setSelectedOption] = useState('STATE');
  const [addressOrigin, setAddressOrigin] = useState({});
  const [statesOptions, setStatesOptions] = useState([]);
  const [modeOptions, setModeOptions] = useState([]);
  const [addressOptions, setAddressOptions] = useState([]);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const [showRoutingResult, setShowRoutingResult] = useState(false);

  let searchingProducts = useSelector(state => state.products.searchProducts.isLoading);
  let productSearchResults = useSelector(state => state.products.searchProducts.products);
  let verifyingRouting = useSelector(state => state.serviceOrders.getServiceOrderRouting.isLoading);
  let routingResult = useSelector(state => state.serviceOrders.getServiceOrderRouting.routingResult);

  useEffect(() => {
    let options = [
      { label: 'States', value: 'STATE' },
      { label: 'Geolocation', value: 'GEOLOCATION' },
    ];
    setModeOptions(options);
    dispatch(statesActions.getStates()).catch(() => {
      toast.error(intl.formatMessage({ id: 'address.loadingStatesErrorMessage' }), { autoClose: false });
    });
  }, []);

  useEffect(() => {
    if (states && states.length > 0) {
      setStatesOptions(
        states.map((state, index) => {
          return { label: state.name, value: state.abbreviation };
        })
      );
    }
  }, [states]);

  useEffect(() => {
    let addresses = [];
    if (userAddresses && userAddresses.length > 0) {
      addresses = userAddresses.map((address, index) => {
        return {
          label: `${address.name} - ${address.addressLine1} ${address.city} ${address.state} ${address.postalCode}`,
          value: address.id,
        };
      });
    }
    if (defaultUserAddress) {
      addresses.push({
        label: `${defaultUserAddress.name} - ${defaultUserAddress.addressLine1} ${defaultUserAddress.city} ${defaultUserAddress.state} ${defaultUserAddress.postalCode} (Default Address)`,
        value: defaultUserAddress.id,
      });
    }
    setAddressOptions(addresses);
  }, [userAddresses, defaultUserAddress]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      handleProductSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setShowRoutingResult(false);
  }, [products]);

  const handleStateOriginChange = state => {
    setShowRoutingResult(false);
    setStateOrigin(state);
  };
  const handleModeChange = mode => {
    setShowRoutingResult(false);
    setSelectedOption(mode);
  };

  const handleAddressOriginChange = address => {
    setShowRoutingResult(false);
    if (userAddresses && userAddresses.length > 0) {
      for (let i = 0; i < userAddresses.length; i++) {
        if (userAddresses[i].id == address) {
          setAddressOrigin(userAddresses[i]);
          return;
        }
      }
    }
  };

  const handleSubmit = () => {
    const skus = products.map((product, index) => {
      return product.sku;
    });
    switch (selectedOption) {
      case 'STATE':
        let countryCode;
        for (let state in states) {
          if (state.abbreviation == stateOrigin) {
            countryCode = state.countryCode;
          }
        }
        dispatch(serviceOrdersActions.getServiceOrderRouting(stateOrigin, skus, countryCode))
          .then(() => {
            setShowRoutingResult(true);
          })
          .catch(() => {
            toast.error(intl.formatMessage({ id: 'admin.routingRules.verifyRoutingError' }));
          });
        break;
      case 'GEOLOCATION':
        dispatch(serviceOrdersActions.getServiceOrderRoutingGeolocation(addressOrigin, skus))
          .then(() => {
            setShowRoutingResult(true);
          })
          .catch(() => {
            toast.error(intl.formatMessage({ id: 'admin.routingRules.verifyRoutingError' }));
          });
        break;
      default:
        break;
    }
  };

  const handleProductSearch = searchTerm => {
    dispatch(productsActions.searchProducts(searchTerm)).catch(() => {
      toast.error(intl.formatMessage({ id: 'productSearch.productSearchError' }));
    });
  };

  const handleProductSelect = sku => {
    const product = productSearchResults.filter(productSearchResult => {
      return productSearchResult.sku === sku;
    })[0];

    setProducts([...products, product]);
  };

  const handleRemoveProductClick = productIndex => {
    setProducts(
      products.filter((product, index) => {
        return index !== productIndex;
      })
    );
  };

  const searchResultsSearchingMarkup = searchingProducts && (
    <Select.Option key='search-result-searching' value={null} disabled>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Spin />
      </div>
    </Select.Option>
  );

  const searchResultsOptionsMarkup =
    !searchingProducts &&
    productSearchResults &&
    productSearchResults.length > 0 &&
    productSearchResults.map((searchResult, index) => {
      return (
        <Select.Option key={`search-result-${index}`} value={searchResult.sku} disabled={!searchResult.serviceEligible}>
          {searchResult.sku} - {searchResult.description}
        </Select.Option>
      );
    });

  const searchResultsNoResultsMarkup = !searchingProducts &&
    debouncedSearchTerm &&
    debouncedSearchTerm.length > 0 &&
    (!productSearchResults || productSearchResults.length < 1) && (
      <Select.Option key='search-result-na' value={null} disabled>
        <FormattedMessage id='admin.routingRules.searchProductNoResults' />
      </Select.Option>
    );

  const productsList =
    products &&
    products.map((product, index) => {
      return (
        <List.Item key={`product-${index}`}>
          <div>
            {product.sku} - {product.description}
          </div>
          <MinusCircleOutlined data-test-id='removeSKUFromRoutingCircle' onClick={() => handleRemoveProductClick(index)} />
        </List.Item>
      );
    });

  const productsMarkup = productsList && productsList.length > 0 && <List bordered>{productsList}</List>;

  const routingResultMarkup = !verifyingRouting && showRoutingResult && routingResult && (
    <RoutingResult result={routingResult} />
  );

  const stateFormMarkup = selectedOption == 'STATE' && (
    <Form.Item
      name='stateOrigin'
      label={intl.formatMessage({ id: 'admin.routingRules.stateOriginLabel' })}
      rules={[{ required: true, message: intl.formatMessage({ id: 'admin.routingRules.pleaseSelectState' }) }]}
    >
      <Select
        data-test-id='adminRoutingRulesStateSelect' 
        loading={loadingStates || !statesOptions}
        onChange={handleStateOriginChange}
        options={statesOptions}
        showSearch
        style={{ maxWidth: '200px' }}
      />
    </Form.Item>
  );
  const addressFormMarkup = selectedOption == 'GEOLOCATION' && (
    <Form.Item
      name='addressOrigin'
      label={intl.formatMessage({ id: 'admin.routingRules.addressOriginLabel' })}
      rules={[{ required: true, message: intl.formatMessage({ id: 'admin.routingRules.pleaseSelectAddress' }) }]}
    >
      <Select
        data-test-id='adminRoutingRulesAddressSelect' 
        loading={!addressOptions}
        onChange={handleAddressOriginChange}
        options={addressOptions}
        showSearch
        style={{ minWidth: '350px', maxWidth: '500px' }}
      />
    </Form.Item>
  );
  const instructionsMarkup = () => {
    switch (selectedOption) {
      case 'STATE':
        return <FormattedMessage id='admin.routingRules.preamble1' />;
      case 'GEOLOCATION':
        return <FormattedMessage id='admin.routingRules.geoLocationInstructions' />;
      default:
        return;
    }
  };

  return (
    <PageHeader title={intl.formatMessage({ id: 'admin.routingRules.title' })}>
      <Paragraph>
        <FormattedMessage id='admin.routingRules.preamble2' />
      </Paragraph>
      <Row>
        <Col>
          <Title level={5}>
            <FormattedMessage id='admin.routingRules.modeLabel' />
          </Title>
          <Select
            data-test-id='adminRoutingRulesProductSelect' 
            loading={!modeOptions}
            defaultActiveFirstOption={true}
            onChange={handleModeChange}
            options={modeOptions}
            defaultValue={selectedOption}
            style={{ width: '200px', paddingBottom: '10px' }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Paragraph>{instructionsMarkup()}</Paragraph>
          <Form name='routingRulesForm' onFinish={handleSubmit} layout='vertical'>
            {stateFormMarkup}
            {addressFormMarkup}
            <Form.Item label={<FormattedMessage id='admin.routingRules.productsLabel' />}>
              <Select
                data-test-id='adminRoutingRulesSearchProductPlaceholderSelect' 
                showArrow={false}
                showSearch
                value={null}
                placeholder={intl.formatMessage({ id: 'admin.routingRules.searchProductPlaceholder' })}
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={s => setSearchTerm(s)}
                onChange={handleProductSelect}
                style={{ maxWidth: '400px', marginBottom: '1em' }}
                loading={searchingProducts}
                notFoundContent={null}
              >
                {searchResultsSearchingMarkup}
                {searchResultsOptionsMarkup}
                {searchResultsNoResultsMarkup}
              </Select>
              {productsMarkup}
            </Form.Item>
            <Form.Item>
              <Button data-test-id='adminRoutingRulesVerifyButton' type='primary' htmlType='submit' loading={verifyingRouting}>
                <FormattedMessage id='admin.routingRules.verifyRoutingLabel' />
              </Button>
            </Form.Item>
          </Form>
          <Row>
            <Col>{routingResultMarkup}</Col>
          </Row>
        </Col>
      </Row>
    </PageHeader>
  );
};

export default RoutingRulesTab;
