import { Button, Col, Modal, ModalBody, ModalContent, ModalHeader, Row, Text } from '@met/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { StyledImg } from '../../components/styled';

const BatteryInstructionsModal = ({ hidden, toggle, handleSubmitClick, loading }) => {
  return (
    <Modal lg centered hidden={hidden} toggle={toggle}>
      <ModalContent>
        <ModalHeader toggle={toggle}>
          <FormattedMessage id='confirmation.batteryInstructions.title' />
        </ModalHeader>
        <ModalBody>
          <p>
            <FormattedMessage id='confirmation.batteryInstructions.line1' />
          </p>
          <p>
            <FormattedMessage id='confirmation.batteryInstructions.line2' />
          </p>
          <Text bold>
            <p>
              <FormattedMessage id='confirmation.batteryInstructions.line3' />
            </p>
          </Text>
          <Text bold primary block>
            <p>
              <FormattedMessage id='confirmation.batteryInstructions.line4' />
            </p>
          </Text>
          <Row className='mb-3'>
            <Col xs='auto' middle='xs'>
              <StyledImg src={window.location.origin + '/images/dot-logo.png'} />
            </Col>
            <Col middle='xs'>
              <FormattedMessage id='confirmation.batteryInstructions.regulationsHeading' />
            </Col>
          </Row>
          <ul>
            <li>
              <FormattedMessage id='confirmation.batteryInstructions.regulations1' />
            </li>
            <li>
              <FormattedMessage id='confirmation.batteryInstructions.regulations2' />
            </li>
          </ul>
          <Row className='mb-3'>
            <Col xs='auto' middle='xs'>
              <StyledImg src={window.location.origin + '/images/dot-logo.png'} />
            </Col>
            <Col middle='xs'>
              <FormattedMessage id='confirmation.batteryInstructions.enforcementHeading' />
            </Col>
          </Row>
          <ul>
            <li>
              <FormattedMessage id='confirmation.batteryInstructions.enforcement1' />
            </li>
            <li>
              <FormattedMessage id='confirmation.batteryInstructions.enforcement2' />
            </li>
            <li>
              <FormattedMessage id='confirmation.batteryInstructions.enforcement3' />
            </li>
          </ul>
          <Row className='mt-3'>
            <Col end='xs' flexDirection='row'>
              <Button data-test-id='batteryInstructionsModalCancelButton' secondary loading={loading} onClick={() => toggle()} className='mr-2'>
                <FormattedMessage id='general.cancel' />
              </Button>
              <Button data-test-id='batteryInstructionsModalSubmitButton' primary loading={loading} onClick={() => handleSubmitClick()}>
                <FormattedMessage id='general.submit' />
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

BatteryInstructionsModal.propTypes = {
  hidden: PropTypes.bool,
  toggle: PropTypes.func,
  handleSubmitClick: PropTypes.func,
};

export default BatteryInstructionsModal;
