import {FormattedMessage} from "react-intl";
import React from "react";
import {Card, Space, Typography} from "antd";

const { Text } = Typography;

const ContactInfo = ({ name, email, phone}) => {
  
  return (
    <Card>
      <Space direction={'vertical'}>
        <Text style={{fontWeight: 500}}>
          <FormattedMessage id='admin.serviceOrders.contact.contactTitle' />
        </Text>
        <Text>{name}</Text>
        <Text>{email}</Text>
        <Text>{phone}</Text>
      </Space>
    </Card>
  );
};

export default ContactInfo;