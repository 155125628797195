import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { serviceOrdersActions } from '../store';

const useServiceOrder = () => {
  const intl = useIntl();

  const serviceOrder = useSelector(state => state.serviceOrders.getServiceOrder.data);
  const loadingServiceOrder = useSelector(state => state.serviceOrders.getServiceOrder.isLoading);
  const upsertingWarrantyOption = useSelector(state => state.serviceOrders.upsertWarrantyOption.isLoading);
  const warrantyOption = useSelector(
    state => state.serviceOrders.getServiceOrder.data && state.serviceOrders.getServiceOrder.data.warrantyOption
  );
  const paymentMethodId = useSelector(state => state.serviceOrders.getServiceOrder.data && state.serviceOrders.getServiceOrder.data.paymentMethodId);

  const generateIssuesList = serviceOrder => {
    let issues = [];

    if (serviceOrder) {
      if (!serviceOrder.trackingNumber) {
        issues.push(intl.formatMessage({ id: 'admin.serviceOrder.issues.noTrackingNumber' }));
      }
      if (!serviceOrder.status) {
        issues.push(intl.formatMessage({ id: 'admin.serviceOrder.issues.noStatus' }));
      }
      if (serviceOrder.numberOfPackages > 8) {
        issues.push(intl.formatMessage({ id: 'admin.serviceOrder.issues.tooManyPackages' }));
      }
      if (serviceOrder.shippoStatus === 0) {
        issues.push(intl.formatMessage({ id: 'admin.serviceOrder.issues.shippoStatusUnknown' }));
      }
      if (serviceOrder.shippoStatus === 4) {
        issues.push(intl.formatMessage({ id: 'admin.serviceOrder.issues.shippoStatusDocumentsFailed' }));
      }
      if (serviceOrder.shippoStatus === 5) {
        issues.push(intl.formatMessage({ id: 'admin.serviceOrder.issues.shippoStatusFailed' }));
      }
      if (serviceOrder.shippoStatus === 6) {
        issues.push(intl.formatMessage({ id: 'admin.serviceOrder.issues.shippoStatusTrackingFailed' }));
      }
    }

    return issues;
  };

  const generateWarningsList = serviceOrder => {
    let warnings = [];

    if (serviceOrder) {
      if (!serviceOrder.serviceOrderNumber) {
        warnings.push(intl.formatMessage({ id: 'admin.serviceOrder.issues.noServiceOrderNumber' }));
      }
      if (!serviceOrder.addressName) {
        warnings.push(intl.formatMessage({ id: 'admin.serviceOrder.warnings.noAddressName' }));
      }
      if (serviceOrder.shippoStatus === 1) {
        warnings.push(intl.formatMessage({ id: 'admin.serviceOrder.warnings.shippoStatusRequested' }));
      }
    }

    return warnings;
  };

  const getServiceOrder = id => {
    return serviceOrdersActions.getServiceOrder(id);
  };

  const upsertWarrantyOption = (serviceOrderNumber, purchaseOrderNumber, warrantyOption, paymentMethodId) => {
    return serviceOrdersActions.upsertWarrantyOption(serviceOrderNumber, purchaseOrderNumber, warrantyOption, paymentMethodId);
  };

  const getServiceOrderEstimatedScrapDate = (serviceOrderId, notificationTypeId) => {
    return serviceOrdersActions.getServiceOrderEstimatedScrapDate(serviceOrderId, notificationTypeId);
  }

  return {
    serviceOrder,
    loadingServiceOrder,
    upsertingWarrantyOption,
    warrantyOption,
    paymentMethodId,
    generateIssuesList,
    generateWarningsList,
    getServiceOrder,
    upsertWarrantyOption,
    getServiceOrderEstimatedScrapDate
  };
};

export default useServiceOrder;
