import { useAuth0 } from '@auth0/auth0-react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseUserIdFromClaims } from '../helpers/user';
import { signalRActions } from '../store';

const useSignalR = () => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const [hub, setHub] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  const isConfigured = useSelector(state => state.signalR.signalRConfig.isConfigured);
  const endpoint = useSelector(state => state.signalR.signalRConfig.endpoint);
  const isRegistered = useSelector(state => state.signalR.signalRConnection.isRegistered);

  // Create the hub on initial mount
  useEffect(() => {
    init();
  }, []);

  // After the hub is created, start it.
  // When the component unmounts, stop the hub so we don't keep sockets open forever.
  useEffect(() => {
    if (hub) {
      hub
        .start()
        .then(() => setIsConnected(true))
        .catch(() => console.log('Error while connecting to hub!'));
    }

    return () => {
      if (hub) {
        setIsConnected(false);
        hub.stop();
      }
    };
  }, [hub]);

  useEffect(() => {
    if (isConfigured && endpoint) {
      initializeHub();
    }
  }, [isConfigured, endpoint]);

  const initializeHub = () => {
    if (user) {
      let userId = parseUserIdFromClaims(user);
      const hubConnection = new HubConnectionBuilder()
        .withUrl(endpoint, {
          httpClient: {
            getCookieString: url => '',
            post: (url, httpOptions) => {
              return fetch(url, {
                method: 'POST',
                headers: {
                  ...httpOptions.headers,
                  UserId: userId,
                  'Content-Type': 'application/json',
                },
              }).then(response => {
                return response.text().then(txt => {
                  return {
                    statusCode: response.status,
                    statusText: response.statusText,
                    content: txt,
                  };
                });
              });
            },
          },
        })
        .build();
      setHub(hubConnection);
    }
  };

  const init = () => {
    if (!isConfigured) {
      dispatch(signalRActions.getSignalREndpoint());
    } else {
      initializeHub();
    }
  };

  return { hub, isConnected, isRegistered };
};

export default useSignalR;
