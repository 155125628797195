export default {
  PRODUCTS: 'ProductsAdmin',
  SERVICECENTERS: 'ServiceCenterAdmin',
  PRODUCTPROBLEMS: 'ProductProblemsAdmin',
  PRODUCTINCLUSIONS: 'ProductIncludedAdmin',
  SERVICEORDERS: 'ServiceOrdersAdmin',
  PRODUCTS_LMR: 'ProductsAdmin.Lmr',
  PRODUCTS_INCLUSIONS: 'ProductsAdmin.Included',
  PRODUCTS_PROBLEMS: 'ProductsAdmin.Problems',
  PRODUCTS_STATES: 'ProductsAdmin.States',
  SERVICECENTERS_SERVICECENTERS: 'ServiceCenterAdmin.ServiceCenters',
  SERVICECENTERS_STATES: 'ServiceCenterAdmin.States',
  ANNOUNCEMENTS: 'AnnouncementsAdmin',
  SERVICEORDERS_RESYNC_INDEX: 'ServiceOrdersAdmin.ResyncIndex',
};
