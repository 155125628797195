import { Alert, Button, Card, CardBody, Col, Grid, Icon, Row, Tab, Tabs, useToggle } from '@met/react-components';
import React, { Fragment, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { ProductSearchModal } from '../../components';
import { useUser } from '../../hooks';
import { serviceOrdersActions } from '../../store';
import DashboardDeniedContentList from './components/DashboardDeniedContentList';
import DashboardListGroup from './components/DashboardListGroup';
import DashboardTab from './components/DashboardTab';
import { NewRepairTitle } from './styled';

const Dashboard = () => {
  const { user } = useUser();
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [query, setQuery] = useQueryParams({
    t: withDefault(StringParam, 'current'),
  });

  const [showSearchModal, toggleSearchModal] = useToggle(false);

  const purchaseOrders = useSelector(state => state.serviceOrders.searchServiceOrders.data);
  const { deniedServiceOrders } = useSelector(state => state.serviceOrders.getDeniedServiceOrders);

  useEffect(() => {
    getDeniedServiceOrders();
  }, [dispatch]);

  const getDeniedServiceOrders = () => {
    dispatch(serviceOrdersActions.getDeniedServiceOrders()).catch(() => {
      toast.error(intl.formatMessage({ id: 'dashboard.loadingErrorMessage' }), { autoClose: false });
    });
  };

  const handleAddToolClick = () => {
    if (user.acceptedTermsAndConditions) {
      toggleSearchModal();
    } else {
      history.push('/');
      toast.warn(intl.formatMessage({ id: 'home.authenticatedContent.termsAndConditionsRequired' }));
    }
  };

  const handleChangeTab = tab => {
    setQuery({ t: tab }, 'push');
  };

  const searchResultsMarkup = purchaseOrders && <DashboardListGroup purchaseOrders={purchaseOrders} />;

  const deniedServiceOrdersMarkup = deniedServiceOrders && deniedServiceOrders.length > 0 && (
    <Row>
      <Col>
        <Alert warning>
          <Row className='mt-2 mb-2'>
            <Col xs='auto' middle='xs'>
              <Icon type='exclamation-triangle' />
            </Col>
            <Col middle='xs'>
              <FormattedMessage id='dashboard.deniedWarning' />
            </Col>
          </Row>
          <Row>
            <Col>
              <DashboardDeniedContentList purchaseOrderLines={deniedServiceOrders} />
            </Col>
          </Row>
        </Alert>
      </Col>
    </Row>
  );

  const currentTabMarkup = query.t === 'current' && <DashboardTab searchResultsMarkup={searchResultsMarkup} />;

  const completedTabMarkup = query.t === 'completed' && <DashboardTab searchResultsMarkup={searchResultsMarkup} />;

  return (
    <Fragment>
      <Grid fluid>
        <Row>
          <Col xs={12} md='auto' middle='xs'>
            <h1>
              <FormattedMessage id='dashboard.dashboardTitle' />
            </h1>
          </Col>
          <Col end='xs' middle='xs'>
            <NewRepairTitle>
              <FormattedMessage id='dashboard.startRepair' />
            </NewRepairTitle>
          </Col>
          <Col xs='auto' end='xs' middle='xs'>
            <Button data-test-id='dashboardAddToolButton' primary outline onClick={handleAddToolClick} icon='plus'>
              <FormattedMessage id='general.addTool' />
            </Button>
          </Col>
        </Row>
        {deniedServiceOrdersMarkup}
        <Row>
          <Col>
            <Card style={{ marginTop: 0 }}>
              <CardBody>
                <Tabs data-test-id='dashboardTabSelectionGroup' defaultTab={query.t} onChange={handleChangeTab} isFullWidthMobile>
                  <Tab data-test-id='currentDashboardTab' id='current' title='Current'>
                    {currentTabMarkup}
                  </Tab>
                  <Tab data-test-id='completedDashboardTab' id='completed' title='Completed'>
                    {completedTabMarkup}
                  </Tab>
                </Tabs>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Grid>
      <ProductSearchModal hidden={!showSearchModal} toggle={toggleSearchModal} isAnonymous={false} />
    </Fragment>
  );
};

export default Dashboard;
