import { Button, Col, Icon, Row, Text, useToggle } from '@met/react-components';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { useRepairProcessContext } from '../RepairProcessContext';
import {
  Image,
  ImageWrapper,
  RepairItemAction,
  RepairItemActions,
  RepairItemImage,
} from './styled';

const RepairItemsList = ({ showButtons, handleContinueClick }) => {
  const history = useHistory();

  const { repairItems, setRepairItems } = useRepairProcessContext();

  const [showConfirmDeleteModal, toggleConfirmDeleteModal] = useToggle(false);
  const [deleteGuid, setDeleteGuid] = useState(null);

  const handleEditClick = (sku, guid) => {
    history.push(`/repairprocess/productdetail/${sku}?g=${guid}`);
  };

  const deleteRepairItem = () => {
    toggleConfirmDeleteModal();

    setRepairItems(repairItems.filter((r, i) => r.guid !== deleteGuid));

    setDeleteGuid(null);
  };

  const handleOpenDeleteModal = (guid) => {
    toggleConfirmDeleteModal();

    setDeleteGuid(guid);
  };

  const repairItemsTitle = (
    <Row>
      <Col>
        <h3>
          <FormattedMessage id='repairItems.repairItemsTitle' />
        </h3>
      </Col>
    </Row>
  );

  const repairItemsList =
    repairItems &&
    repairItems.map((repairItem, index) => {
      return (
        <Row key={`repair-item-${index}`}>
          <RepairItemImage xs={3} middle='xs'>
            <ImageWrapper>
              <Image src={repairItem.image} />
            </ImageWrapper>
          </RepairItemImage>
          <Col>
            <Text data-test-id='repairItemSku'>{repairItem.sku}</Text>
            <Text bold>{repairItem.description}</Text>
          </Col>
          <RepairItemActions xs='auto' middle='xs' flexDirection='row'>
            <RepairItemAction clickable>
              <Icon
                data-test-id='repairItemSKUIcon'
                sm
                type='pencil'
                onClick={() => handleEditClick(repairItem.sku, repairItem.guid)}
              />
            </RepairItemAction>
            <RepairItemAction clickable>
              <Icon
                data-test-id='repairItemGUIDIcon'
                sm
                type='trash'
                onClick={() => handleOpenDeleteModal(repairItem.guid)}
              />
            </RepairItemAction>
          </RepairItemActions>
        </Row>
      );
    });

  const noRepairItemsMarkup =
    !repairItems ||
    (repairItems.length === 0 && (
      <Row>
        <Col center='xs'>
          <Text md>
            <FormattedMessage id='repairItems.noRepairItems' />
          </Text>
        </Col>
      </Row>
    ));

  const buttonsMarkup = showButtons && (
    <Row>
      <Col center='xs'>
        <Button
          data-test-id='repairItemsContinueLabelButton'
          primary
          disabled={!repairItems || repairItems.length === 0}
          onClick={() => handleContinueClick()}
        >
          <FormattedMessage id='repairItems.continueLabel' />
        </Button>
      </Col>
    </Row>
  );

  const repairItemsMarkup = (
    <Fragment>
      {repairItemsTitle}
      {repairItemsList}
      {noRepairItemsMarkup}
      {buttonsMarkup}
      <ConfirmDeleteModal
        hidden={!showConfirmDeleteModal}
        toggle={toggleConfirmDeleteModal}
        deleteRepairItem={deleteRepairItem}
      />
    </Fragment>
  );

  return <Fragment>{repairItemsMarkup}</Fragment>;
};

RepairItemsList.propTypes = {
  handleContinueClick: PropTypes.func,
  showButtons: PropTypes.bool,
};

export default RepairItemsList;
