import { SearchOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useToggle } from '@met/react-components';
import { Button, Col, Layout, Row, Space, Typography } from 'antd';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ProductSearchModal } from '../../../../components';
import IneligibleToolModal from '../../../../components/IneligibleToolModal/IneligibleToolModal';
import { Image } from './styled';
import { useLocale } from '../../../../hooks';
import { getCountryIntlValues } from '../../../../helpers/intl';

const { Title } = Typography;
const { Content } = Layout;

const AnonymousContent = () => {
  const intl = useIntl();
  const { isCaLocale } = useLocale();

  const [showSearchModal, toggleSearchModal] = useToggle(false);
  const [showIneligibleToolModal, toggleIneligibleToolModal] = useToggle(false);

  const isCa = isCaLocale();

  const dropOffMarkup = isCa ? (
    <Image
      src={window.location.origin + '/images/drop-off-ca.png'}
      alt={intl.formatMessage({ id: 'home.anonymousContent.dropOffAltCa' })}
    />
  ) : (
    <Image
      src={window.location.origin + '/images/drop-off.png'}
      alt={intl.formatMessage({ id: 'home.anonymousContent.dropOffAlt' })}
    />
  );
  return (
    <Fragment>
      <Content style={{ maxWidth: '1200px', padding: '0 2rem', margin: '2rem auto' }}>
        <Row align='middle'>
          <Col xs={24} lg={12}>
            <Space direction='vertical' size='large' style={{ width: '100%' }}>
              <div>
                <Title level={4}>
                  <FormattedMessage id='home.anonymousContent.howDoIKnow' />
                </Title>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12}>
                    <Button
                      data-test-id='searchForEligibleToolsButton'
                      id='searchForEligibleToolsButton'
                      type='primary'
                      icon={<SearchOutlined />}
                      onClick={() => toggleSearchModal()}
                      style={{ width: '100%' }}
                    >
                      <span>
                        <FormattedMessage id='home.anonymousContent.searchPrompt' />
                      </span>
                    </Button>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Button
                      data-test-id='listIneligibleToolsButton'
                      id='listIneligibleToolsButton'
                      type='primary'
                      icon={<UnorderedListOutlined />}
                      onClick={() => toggleIneligibleToolModal()}
                      style={{ width: '100%' }}
                    >
                      <span>
                        <FormattedMessage id='home.anonymousContent.checkIneligibleProducts' />
                      </span>
                    </Button>
                  </Col>
                </Row>
              </div>
              <div>
                <Title level={4}>
                  <FormattedMessage id='home.anonymousContent.solutionHeader' />
                </Title>
                <ul>
                  <li>
                    <FormattedMessage id='home.anonymousContent.solutionLine1' />
                  </li>
                  <li>
                    <FormattedMessage id='home.anonymousContent.solutionLine2' />
                  </li>
                  <li>
                    <FormattedMessage
                      id='home.anonymousContent.solutionLine3'
                      values={{ carrier: getCountryIntlValues().carrier }}
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id='home.anonymousContent.solutionLine4'
                      values={{
                        carrier: getCountryIntlValues().carrier,
                        carrierLocationAmount: getCountryIntlValues().carrierLocationAmount,
                      }}
                    />
                  </li>
                  <li>
                    <FormattedMessage id='home.anonymousContent.solutionLine5' />
                  </li>
                  <li>
                    <FormattedMessage id='home.anonymousContent.solutionLine6' />
                  </li>
                </ul>
              </div>
              <div>
                <Title level={4}>
                  <FormattedMessage id='home.anonymousContent.benefitsHeader' />
                </Title>
                <ul>
                  <li>
                    <FormattedMessage id='home.anonymousContent.benefitsLine1' />
                  </li>
                  <li>
                    <FormattedMessage id='home.anonymousContent.benefitsLine2' />
                  </li>
                  <li>
                    <FormattedMessage id='home.anonymousContent.benefitsLine3' />
                  </li>
                </ul>
              </div>
            </Space>
          </Col>
          <Col xs={0} lg={12} style={{ textAlign: 'center' }}>
            <Space direction='vertical' size='large' style={{ maxWidth: '300px', margin: '0 auto' }}>
              <Image
                src={window.location.origin + '/images/7-10.png'}
                alt={intl.formatMessage({ id: 'home.anonymousContent.repairsCompletedAlt' })}
              />
              {dropOffMarkup}
            </Space>
          </Col>
        </Row>
      </Content>
      <ProductSearchModal hidden={!showSearchModal} toggle={toggleSearchModal} />
      <IneligibleToolModal hidden={showIneligibleToolModal} toggle={toggleIneligibleToolModal} />
    </Fragment>
  );
};

export default AnonymousContent;
