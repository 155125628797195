import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { signalRActions } from '../store';
import { useRecycling, useServiceOrder, useSignalR } from './index';

const useOrderStatus = () => {
  const defaultTimeoutDuration = 60000;

  const dispatch = useDispatch();
  const { serviceOrder } = useServiceOrder();
  const { serviceOrderRecycling } = useRecycling();
  const { isConnected, hub, isRegistered } = useSignalR();

  const [shippoTrackingProps, setShippoTrackingProps] = useState(null);
  const [shippoTrackingFailure, setShippoTrackingFailure] = useState(false);
  const [callToRecycleFailure, setCallToRecycleFailure] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState(null);

  const shippoTrackingTimeout = useRef(null);

  useEffect(() => {
    if (serviceOrder) {
      setShippoTrackingFailure(serviceOrder.shippoStatus === 5);
    }
  }, [serviceOrder]);

  useEffect(() => {
    if (serviceOrderRecycling) {
      setCallToRecycleFailure(serviceOrderRecycling.status === 9);
    }
  }, [serviceOrderRecycling]);

  useEffect(() => {
    if (isConnected && hub) {
      if (shippoTrackingProps) {
        const { groupId, callback, timeout } = shippoTrackingProps;

        shippoTrackingTimeout.current = setTimeout(() => {
          // If listening times out, mark as failure
          setShippoTrackingFailure(true);
          stopListeningForShippoTracking();
        }, timeout);

        hub.on('TrackingNumber', message => {
          if (message && message.hash && message.hash === groupId) {
            setTrackingNumber(message.trackingNumber);
            callback(message.trackingNumber);
          }
        });

        if (!isRegistered) {
          dispatch(signalRActions.addToTrackingNumberGroup(groupId));
        }
      } else {
        if (isConnected && hub) {
          hub.stop();
        }

        if (shippoTrackingTimeout.current) {
          clearTimeout(shippoTrackingTimeout.current);
        }
      }
    }
  }, [shippoTrackingProps, isConnected, hub]);

  const startListeningForShippoTracking = (groupId, callback, timeout = defaultTimeoutDuration) => {
    setShippoTrackingProps({ groupId, callback, timeout });
  };

  const stopListeningForShippoTracking = () => {
    setShippoTrackingProps(null);
  };

  return {
    shippoTrackingFailure,
    callToRecycleFailure,
    startListeningForShippoTracking,
    stopListeningForShippoTracking,
    trackingNumber,
    setTrackingNumber,
  };
};

export default useOrderStatus;
