import Enumerable from 'linq';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useAddresses, useLocale } from '../../hooks';
import { usersActions } from '../../store';

import { SuggestionRow, SuggestionText } from './styled';
import { formatPostalCode } from '../../helpers/formatter';
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space, Typography } from 'antd';

const { isCaLocale } = useLocale();
const { Option } = Select;
const { Text } = Typography;

const AddressModal = ({
  hidden,
  toggle,
  address,
  showDeleteAddressView,
  showDefaultAddressView,
  onCancelClick,
  onDeleteClick,
  onSetDefaultClick,
  onSaveChangesClick,
}) => {
  //Refs
  const formRef = useRef();
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    defaultUserAddress,
    countries,
    states,
    validatingAddress,
    savingAddress,
    sanitizedAddress,
    addressValidationWarnings,
    addressValidationErrors,
  } = useAddresses();

  const [countriesOptions, setCountriesOptions] = useState([]);
  const [statesOptions, setStatesOptions] = useState([]);
  const [defaultShippingAddress, setDefaultShippingAddress] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine1Suggestion, setAddressLine1Suggestion] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressLine2Suggestion, setAddressLine2Suggestion] = useState(''); // eslint-disable-line no-unused-vars
  const [citySuggestion, setCitySuggestion] = useState('');
  const [selectedState, setSelectedState] = useState(null);
  const [stateSuggestion, setStateSuggestion] = useState(null);
  const [postalCode, setPostalCode] = useState('');
  const [postalCodeSuggestion, setPostalCodeSuggestion] = useState('');
  const [addressLatitude, setAddressLatitude] = useState();
  const [addressLongitude, setAddressLongitude] = useState();
  const [addressIsResidential, setAddressIsResidential] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isInitiallyDefault, setIsInitiallyDefault] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [, setPostalCodeInvalid] = useState(false);

  useEffect(() => {
    setIsDirty(true);
    if (address) {
      if (defaultUserAddress) {
        let isDefault = address.id === defaultUserAddress.id;
        setDefaultShippingAddress(isDefault);
        setIsInitiallyDefault(isDefault);
      } else {
        setDefaultShippingAddress(false);
      }
      setSelectedState(address.state || '');
      setAddressIsResidential(address.isResidential || false);
      setPostalCodeInvalid(false);
    }
  }, [address]);

  useEffect(() => {
    if (countries && countries.length > 0) {
      setCountriesOptions(createCountriesOptions());
    }
  }, [countryList, countries]);

  useEffect(() => {
    if (countryList.length === 0) {
      setCountryList(
        Enumerable.from(countries)
          .where((x) => x.iso3 === 'USA' || x.iso3 == 'CAN')
          .toArray()
      ); // SVC-372: Only allow USA
    }
  }, [countries]);

  useEffect(() => {
    if (countryList.length > 0) {
      if (address && address.countryCode) {
        setSelectedCountry(address.countryCode);
      } else {
        let defaultVal = 'USA';
        if (isCaLocale()) {
          defaultVal = 'CAN';
        }

        let defaultOption = countryList.find((x) => x.iso3 === defaultVal).iso3;

        setSelectedCountry(defaultOption);
      }
    }
  }, [address, countryList]);

  useEffect(() => {
    if (states && states.length > 0) {
      setStatesOptions(createStatesOptions());
    }
  }, [states]);

  useEffect(() => {
    if (stateList && stateList.length > 0 && address && address.state) {
      let option = stateList.find((x) => x.abbreviation === address.state)?.abbreviation;

      if (option) {
        setSelectedState(option);
      } else {
        setSelectedState('');
      }
    }
  }, [address, stateList]);

  useEffect(() => {
    let list = [];
    if (states && states.length > 0) {
      list = states.filter((state) => {
        if (selectedCountry) {
          return state.countryCode?.toLowerCase() == selectedCountry.toLowerCase();
        }
        return true;
      });

      setStateList(list);
    }
  }, [states, selectedCountry]);

  useEffect(() => {
    if (selectedCountry !== null) {
      setStatesOptions(createStatesOptions());
    }

    setIsDirty(true);
  }, [stateList]);

  useEffect(() => {
    if (addressLine1.length > 0) {
      // Handle clearing AddressLine2 if suggestion for AddressLine1 includes it
      if (
        addressLine1 === addressLine1Suggestion &&
        addressLine1Suggestion.toLowerCase().includes(addressLine2.toLowerCase())
      ) {
        setAddressLine2('');
      }
    }
    setAddressLine1Suggestion('');
    setIsDirty(true);
  }, [addressLine1]);

  useEffect(() => {
    setAddressLine2Suggestion('');
    setIsDirty(true);
  }, [addressLine2]);

  useEffect(() => {
    if (selectedState !== null) {
      setStateSuggestion(null);

      if (formRef.current) {
        formRef.current.setFieldsValue({
          state: selectedState,
        });
      }
    }

    setIsDirty(true);
  }, [selectedState]);

  useEffect(() => {
    setPostalCodeSuggestion('');
    setIsDirty(true);
  }, [postalCode]);

  useEffect(() => {
    if (sanitizedAddress) {
      const { addressLine1, city, state, postalCode, latitude, longitude } = sanitizedAddress;
      if (latitude) {
        setAddressLatitude(latitude);
      }
      if (longitude) {
        setAddressLongitude(longitude);
      }
      if (addressLine1) {
        setAddressLine1Suggestion(addressLine1);
      }
      if (city) {
        setCitySuggestion(city);
      }
      if (state && statesOptions.length > 0) {
        let option = stateList.find((x) => x.abbreviation === state).abbreviation;
        if (option) {
          setStateSuggestion(option);
        }
      }
      if (postalCode) {
        setPostalCodeSuggestion(postalCode);
      }
    }
  }, [sanitizedAddress]);

  const createCountriesOptions = () => {
    let countriesOptions = [];

    if (countryList.length > 0) {
      countriesOptions = countryList.map((country, index) => {
        return (
          <Option key={'country-' + index} value={country.iso3}>
            {country.name}
          </Option>
        );
      });
    }

    return countriesOptions;
  };

  const createStatesOptions = () => {
    let statesOptions = [];

    if (stateList && stateList.length > 0) {
      statesOptions = stateList.map((state, index) => {
        return (
          <Option key={'state-' + index} value={state.abbreviation}>
            {state.name}
          </Option>
        );
      });
    }

    return statesOptions;
  };

  const validate = (formVal) => {
    let isValid = true;

    if (formVal.postalCode) {
      if (selectedCountry) {
        isValid = validatePostalCode(formVal.postalCode);
      }
    }

    if (formVal.addressLine1) {
      isValid = validatePoBox(formVal.addressLine1);
    }

    return isValid;
  };

  const validatePostalCode = (postalCode) => {
    let isValid = true;

    let isUS = selectedCountry == 'USA' || selectedCountry == 'US';
    let isCA = selectedCountry == 'CAN' || selectedCountry == 'CA';
    let re = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/;
    if (isCA) {
      re = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    }
    if (!re.test(postalCode)) {
      setPostalCodeInvalid(true);
      isValid = false;
    }

    return isValid;
  };

  const validatePoBox = (address) => {
    let isValid = true;

    let re = /\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/i;

    if (re.test(address)) {
      isValid = false;
    }

    return isValid;
  };

  const onBlurPostalCode = (e) => {
    let formatted = formatPostalCode(selectedCountry, e);
    setPostalCode(formatted);

    if (formRef.current) {
      formRef.current.setFieldsValue({
        postalCode: formatted,
      });
    }
  };

  const onCountryChange = (country) => {
    setSelectedState('');
    formRef.current.setFieldsValue({
      state: '',
    });
    setSelectedCountry(country);
  };

  const clearAddressModal = () => {
    dispatch(usersActions.clearAddressModal());
    setSelectedCountry(null);
    setAddressLine1('');
    setAddressLine1Suggestion('');
    setAddressLine2('');
    setAddressLine2Suggestion('');
    setCitySuggestion('');
    setSelectedState(null);
    setStateSuggestion(null);
    setPostalCode('');
    setPostalCodeSuggestion('');
    setAddressLatitude(null);
    setAddressLongitude(null);
    setAddressIsResidential(false);
    setIsDirty(false);
    setIsInitiallyDefault(false);
    setPostalCodeInvalid(false);
    setPostalCodeSuggestion('');
    setStateSuggestion('');
    setCitySuggestion('');
    setAddressLine1Suggestion('');
    setAddressLine2Suggestion('');
  };

  const handleSaveChangesClick = (formVal) => {
    let isValid = validate(formVal);

    if (!isValid) {
      toast.error(intl.formatMessage({ id: 'address.pleaseCorrectLabel' }), { autoClose: 5000 });
    } else if (!isDirty) {
      onSaveChangesClick(
        address.id,
        defaultShippingAddress,
        formVal.addressLine1,
        addressLine2,
        formVal.city,
        formVal.state,
        formVal.country,
        formVal.postalCode,
        formVal.addressName,
        addressIsResidential,
        addressLatitude,
        addressLongitude
      );
    } else {
      dispatch(
        usersActions.validateUserAddress(
          formVal.addressLine1,
          addressLine2,
          formVal.city,
          formVal.state,
          formVal.country,
          formVal.postalCode
        )
      )
        .then((payload) => {
          if (payload.data.data.isValid) {
            setIsDirty(false);
            toast.success(intl.formatMessage({ id: 'address.addressIsValid' }), { autoClose: 5000 });
          } else if (payload.data.data.errors) {
            setIsDirty(true);
            toast.error(intl.formatMessage({ id: 'address.addressIsInvalid' }), { autoClose: 5000 });
          } else if (payload.data.data.warnings) {
            setIsDirty(true);
            toast.error(intl.formatMessage({ id: 'address.addressNotFound' }), { autoClose: 5000 });
          }
        })
        .catch(() => {
          toast.error(intl.formatMessage({ id: 'address.sanitizationError' }), { autoClose: 5000 });
          setIsDirty(true);
        });
    }
  };

  const deleteAddressMarkup = showDeleteAddressView && address && (
    <Fragment>
      <Space direction='vertical' size={1}>
        <FormattedMessage id='address.confirmDeleteAddress' />

        <Text strong>{address.name}</Text>
        <Text strong>{address.addressLine1}</Text>
        <Text strong>
          {address.city}, {address.state} {address.postalCode}
        </Text>
        <Text strong>{address.countryCode}</Text>
      </Space>
    </Fragment>
  );

  const defaultAddressMarkup = showDefaultAddressView && address && (
    <Fragment>
      <Space direction='vertical' size={1}>
        <FormattedMessage id='address.confirmDefaultAddress' />

        <Text strong>{address.name}</Text>
        <Text strong>{address.addressLine1}</Text>
        <Text strong>
          {address.city}, {address.state} {address.postalCode}
        </Text>
        <Text strong>{address.countryCode}</Text>
      </Space>
    </Fragment>
  );

  const addressValidationWarningList =
    !isDirty &&
    !addressValidationErrors &&
    addressValidationWarnings &&
    addressValidationWarnings.map((warning, index) => {
      return (
        <Row key={`validation-warning-${index}`}>
          <Col>
            <Text strong type={'danger'}>
              {warning.text}
            </Text>
          </Col>
        </Row>
      );
    });

  const addressValidationWarningMarkup = addressValidationWarningList && (
    <Fragment>{addressValidationWarningList}</Fragment>
  );

  const addressValidationErrorList =
    isDirty &&
    addressValidationErrors &&
    addressValidationErrors.map((error, index) => {
      return (
        <Row key={`validation-error-${index}`}>
          <Col>
            <Text strong type={'danger'}>
              {error.text}
            </Text>
          </Col>
        </Row>
      );
    });

  const addressValidationErrorMarkup = addressValidationErrorList && <Fragment>{addressValidationErrorList}</Fragment>;

  const addressLine1SuggestionMarkup = addressLine1Suggestion && (
    <SuggestionRow>
      <Col xs='auto'>
        <SuggestionText
          data-test-id='addressLine1SuggestionText'
          strong
          italic
          onClick={() => setFieldValue('addressLine1', addressLine1Suggestion)}
        >
          <FormattedMessage id='address.didYouMean' values={{ suggestion: addressLine1Suggestion }} />
        </SuggestionText>
      </Col>
    </SuggestionRow>
  );

  const citySuggestionMarkup = citySuggestion && (
    <SuggestionRow>
      <Col xs='auto'>
        <SuggestionText
          data-test-id='citySuggestion'
          strong
          italic
          onClick={() => setFieldValue('city', citySuggestion)}
        >
          <FormattedMessage id='address.didYouMean' values={{ suggestion: citySuggestion }} />
        </SuggestionText>
      </Col>
    </SuggestionRow>
  );

  const stateSuggestionMarkup = stateSuggestion && (
    <SuggestionRow>
      <Col xs='auto'>
        <SuggestionText
          data-test-id='stateSuggestion'
          strong
          italic
          onClick={() => setFieldValue('state', stateSuggestion)}
        >
          <FormattedMessage
            id='address.didYouMean'
            values={{ suggestion: stateList.find((x) => x.abbreviation === stateSuggestion).name }}
          />
        </SuggestionText>
      </Col>
    </SuggestionRow>
  );

  const postalCodeSuggestionMarkup = postalCodeSuggestion && (
    <SuggestionRow>
      <Col xs='auto'>
        <SuggestionText
          data-test-id='postalCodeSuggestion'
          strong
          italic
          onClick={() => setFieldValue('postalCode', postalCodeSuggestion)}
        >
          <FormattedMessage id='address.didYouMean' values={{ suggestion: postalCodeSuggestion }} />
        </SuggestionText>
      </Col>
    </SuggestionRow>
  );

  const defaultAddressCheckbox = !isInitiallyDefault && (
    <Checkbox
      data-test-id='defaultAddressCheckbox'
      defaultChecked={defaultShippingAddress}
      onChange={(e) => setDefaultShippingAddress(e.target.checked)}
      value={true}
    >
      {intl.formatMessage({ id: 'address.defaultShippingAddressLabel' })}
    </Checkbox>
  );

  const setFieldValue = (field, value) => {
    if (field) {
      setIsDirty(true);

      switch (field) {
        case 'addressLine1':
          formRef.current.setFieldsValue({
            addressLine1: value,
          });
          setAddressLine1Suggestion('');
          return;
        case 'city':
          formRef.current.setFieldsValue({
            city: value,
          });
          setCitySuggestion('');
          return;
        case 'state':
          formRef.current.setFieldsValue({
            state: value,
          });
          setStateSuggestion('');
          return;
        case 'postalCode':
          formRef.current.setFieldsValue({
            postalCode: value,
          });
          setPostalCodeSuggestion('');
          setPostalCodeInvalid(false);
          return;
      }
    }
  };

  const editAddressMarkup = !hidden && !showDeleteAddressView && !showDefaultAddressView && address && (
    <Form
      ref={formRef}
      name='addressForm'
      layout='vertical'
      onFinish={(formVal) => {
        handleSaveChangesClick(formVal);
      }}
      scrollToFirstError={true}
      onValuesChange={(field, allValues) => {
        let key = Object.keys(field)[0];
        let value = field[key];

        setFieldValue(key, value);
      }}
      initialValues={{
        addressName: address.name,
        country: selectedCountry,
        addressLine1: address.addressLine1,
        city: address.city,
        state: selectedState,
        postalCode: address.postalCode,
      }}
    >
      <Form.Item
        label={intl.formatMessage({ id: 'address.addressNameLabel' })}
        name='addressName'
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: 'address.addressNameRequired' }),
          },
          {
            max: 30,
            message: intl.formatMessage({ id: 'address.addressNameCharLimit' }),
          },
          {
            pattern: new RegExp('^[a-zA-Z0-9 ]*$'),
            message: intl.formatMessage({ id: 'address.addressNameInvalidChar' }),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'address.countryLabel' })}
        name='country'
        rules={[
          {
            required: true,
            message: 'Country is Required',
          },
        ]}
      >
        <Select
          data-test-id='onCountryChange'
          showSearch
          onChange={(e) => onCountryChange(e)}
          optionFilterProp='children'
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {countriesOptions}
        </Select>
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'address.addressLine1Label' })}
        name='addressLine1'
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: 'address.addressLine1Required' }),
          },
          {
            pattern: new RegExp('^[a-zA-Z0-9 ]*$'),
            message: intl.formatMessage({ id: 'address.addressLineInvalidChar' }),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (value && validatePoBox(value)) {
                return Promise.resolve();
              }

              if (!value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(intl.formatMessage({ id: 'address.poBoxesAreNotAllowed' })));
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
      {addressLine1SuggestionMarkup}
      <Form.Item
        label={intl.formatMessage({ id: 'address.cityLabel' })}
        name='city'
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: 'address.cityRequired' }),
          },
        ]}
      >
        <Input />
      </Form.Item>
      {citySuggestionMarkup}
      <Row gutter={[6, 6]}>
        <Col xs={12}>
          <Form.Item
            label={intl.formatMessage({ id: 'address.stateLabel' })}
            name='state'
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'address.stateLabel' }),
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {statesOptions}
            </Select>
          </Form.Item>
          {stateSuggestionMarkup}
        </Col>
        <Col xs={12}>
          <Form.Item
            label={intl.formatMessage({ id: 'address.postalCodeLabel' })}
            name='postalCode'
            required={true}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && validatePostalCode(value)) {
                    return Promise.resolve();
                  }

                  if (!value) {
                    return Promise.reject(new Error(intl.formatMessage({ id: 'address.postalCodeRequired' })));
                  }
                  return Promise.reject(new Error(intl.formatMessage({ id: 'address.postalCodeInvalid' })));
                },
              }),
            ]}
          >
            <Input onBlur={(e) => onBlurPostalCode(e.target.value)} />
          </Form.Item>
          {postalCodeSuggestionMarkup}
        </Col>
      </Row>
      <Space direction='vertical'>
        <Checkbox
          data-test-id='residentialAddressLabel'
          defaultChecked={addressIsResidential}
          onChange={(e) => setAddressIsResidential(e.target.checked)}
        >
          {intl.formatMessage({ id: 'address.residentialAddressLabel' })}
        </Checkbox>
        {defaultAddressCheckbox}
      </Space>
      {addressValidationWarningMarkup}
      {addressValidationErrorMarkup}
    </Form>
  );

  return (
    <Modal
      title={
        showDeleteAddressView ? (
          <FormattedMessage id='address.deleteAddressModalTitle' />
        ) : showDefaultAddressView ? (
          <FormattedMessage id='address.defaultAddressModalTitle' />
        ) : address.id ? (
          <FormattedMessage id='address.editAddressModalTitle' />
        ) : (
          <FormattedMessage id='address.newAddressModalTitle' />
        )
      }
      open={!hidden}
      footer={[
        <Button
          data-test-id='addressModalCancelButton'
          key={'cancelBtn'}
          type='default'
          onClick={() => onCancelClick()}
        >
          Cancel
        </Button>,
        !showDeleteAddressView && !showDefaultAddressView && (
          <Button
            data-test-id='save-changes-button'
            key={'submitBtn'}
            type='primary'
            onClick={() => formRef?.current?.submit()}
            loading={validatingAddress || savingAddress}
          >
            {isDirty ? (
              <FormattedMessage id='address.validateAddress' />
            ) : address.id ? (
              <FormattedMessage id='general.saveChanges' />
            ) : (
              <FormattedMessage id='general.submit' />
            )}
          </Button>
        ),
        showDeleteAddressView && (
          <Button
            data-test-id='delete-button'
            key={'deleteBtn'}
            type='primary'
            onClick={() => onDeleteClick(address.id, defaultShippingAddress)}
          >
            <FormattedMessage id='general.delete' />
          </Button>
        ),
        showDefaultAddressView && (
          <Button
            data-test-id='set-default-button'
            key={'setDefaultBtn'}
            type='primary'
            onClick={() => onSetDefaultClick(address.id)}
          >
            <FormattedMessage id='address.setDefaultButton' />
          </Button>
        ),
      ]}
      onCancel={toggle}
      destroyOnClose={true}
      afterClose={clearAddressModal}
    >
      {deleteAddressMarkup}
      {defaultAddressMarkup}
      {editAddressMarkup}
    </Modal>
  );
};

AddressModal.propTypes = {
  hidden: PropTypes.bool,
  toggle: PropTypes.func,
  address: PropTypes.object,
  showDeleteAddressView: PropTypes.bool,
  showDefaultAddressView: PropTypes.bool,
  onCancelClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onSetDefaultClick: PropTypes.func,
  onSaveChangesClick: PropTypes.func,
};

AddressModal.defaultProps = {
  showDeleteAddressView: false,
  showDefaultAddressView: false,
};

export default AddressModal;
