const isDevelopment = process.env.NODE_ENV === 'development';
const baseDevUrl = 'https://service-dev.milwaukeetool.com';

const supportCenterRedirectMap = {
  '/': { redirectPath: '/support', param: null },
  '/dashboard': { redirectPath: '/support/service-orders', param: null },
  '/repairprocess/warrantyinformation': { redirectPath: '/support', param: null },
  '/repairprocess/warrantyinformation/:id': { redirectPath: '/support', param: null },
  '/repairprocess/productdetail/:id': { redirectPath: '/support', param: null },
  '/repairprocess/productdetail': { redirectPath: '/support', param: null },
  '/repairprocess/addanother': { redirectPath: '/support', param: null },
  '/repairprocess/shipping': { redirectPath: '/support', param: null },
  '/repairprocess/confirmation': { redirectPath: '/support', param: null },
  '/repairprocess/ordersummary/:hash': { redirectPath: '/support', param: 'hash' },
  '/preferences': { redirectPath: '/support/account-settings', param: null },
  '/repairdetail/:id': { redirectPath: '/support/service-order-details/:id', param: 'id' },
  '/recoveremail': { redirectPath: '/support/account-settings', param: null },
  '/verifyemail/:token': { redirectPath: '/support/verifyemail/:token', param: 'token' },
};

export function getSupportCenterRedirectRoute(path, params) {
  const pathConfig = supportCenterRedirectMap[path];
  if (!pathConfig) return null;

  const { redirectPath, param } = pathConfig;

  const formattedPath = !!param
    ? redirectPath.replace(`:${param}`, params[param])
    : redirectPath;

  // isDevelopment logic really just needed for local development
  // as it runs on service-local.milwaukeetool.com
  return isDevelopment ? `${baseDevUrl}${formattedPath}` : formattedPath;
}