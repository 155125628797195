import styled from 'styled-components';
import { CreditCardContainer } from '../../containers/Preferences/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const NewCreditCard = styled(CreditCardContainer)`
  text-align: center;
  cursor: pointer;
`;

export const DisclaimerContainer = styled.div`
  text-align: center;
  font-style: italic;
  color: ${props => props.theme.colors.darkGray};
`

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: rgb(204, 204, 204);
    stroke-width: 40;
    stroke: rgb(204, 204, 204);
    margin-bottom: .5rem;
`;
