import {
  Button,
  Col,
  DateRange,
  Icon,
  InputGroup,
  InputGroupAppend,
  Loader,
  LoadingDots,
  Row,
  Text,
  TextInput,
  useToggle,
} from '@met/react-components';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { SearchHelpModal } from '../../../components/SearchHelpModal';
import { downloadBlob } from '../../../helpers/downloadBlob';
import { serviceOrdersActions } from '../../../store';
import { NoResultsCol, StyledInfiniteScroll } from './styled';

const DashboardTab = ({ searchResultsMarkup }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const searchRef = useRef(null);

  const pageSize = 15;
  const displayDateFormat = 'MM/DD/YYYY';
  const calendarDateFormat = 'YYYY-MM-DD';
  const maxResults = 500;
  const defaultStartDate = moment()
    .add(-180, 'd')
    .format(calendarDateFormat);
  const defaultEndDate = moment().format(calendarDateFormat);

  const [query, setQuery] = useQueryParams({
    q: withDefault(StringParam, ''),
    p: withDefault(NumberParam, 1),
    ps: withDefault(NumberParam, pageSize),
  });

  const [datesQuery, setDatesQuery] = useQueryParams({
    s: withDefault(StringParam, defaultStartDate),
    e: withDefault(StringParam, defaultEndDate),
  });

  const [searchTerm, setSearchTerm] = useState(query.q);
  const [startDateInvalid, setStartDateInvalid] = useState(false);
  const [endDateInvalid, setEndDateInvalid] = useState(false);
  const [dateRangeInvalid, setDateRangeInvalid] = useState(false);

  const { isLoading, totalCount, count } = useSelector(state => state.serviceOrders.searchServiceOrders);
  const purchaseOrders = useSelector(state => state.serviceOrders.searchServiceOrders.data || []);
  const exportIsLoading = useSelector(state => state.serviceOrders.exportServiceOrders.isLoading);

  const [showSearchHelpModal, toggleSearchHelpModal] = useToggle(false);

  useEffect(() => {
    searchRef.current.focus();

    setQuery({ p: 1 });
  }, []);

  useEffect(() => {
    if (datesQuery.s || datesQuery.e) {
      let momentStartDate = moment(datesQuery.s, calendarDateFormat, true);
      let momentEndDate = moment(datesQuery.e, calendarDateFormat, true);

      let dateRangeValid = momentEndDate.diff(momentStartDate) >= 0;
      setDateRangeInvalid(!dateRangeValid);

      if (dateRangeValid) {
        clearServiceOrders();
        searchServiceOrders(1, window.location.search);
      }
    }
  }, [datesQuery]);

  const searchServiceOrders = (page, searchParameters) => {
    dispatch(serviceOrdersActions.searchServiceOrders(page, searchParameters)).catch(() => {
      toast.error(intl.formatMessage({ id: 'dashboard.loadingErrorMessage' }), { autoClose: false });
    });
  };

  const clearServiceOrders = () => {
    dispatch(serviceOrdersActions.clearServiceOrders());
  };

  const exportServiceOrders = () => {
    let searchParameters = window.location.search;
    dispatch(serviceOrdersActions.exportServiceOrders(searchParameters))
      .then(d => {
        downloadBlob(d, d.data, true);
      })
      .catch(err => {
        alert(err.message);
        toast.error(intl.formatMessage({ id: 'dashboard.exportErrorMessage' }), {
          autoClose: false,
        });
      });
  };

  const handleStartDateChange = startDate => {
    let momentStartDate = moment(startDate, calendarDateFormat, true);

    setStartDateInvalid(!momentStartDate.isValid());

    if (momentStartDate.isValid()) {
      setDatesQuery({ s: momentStartDate.format(calendarDateFormat) });
    }
  };

  const handleEndDateChange = endDate => {
    let momentEndDate = moment(endDate, calendarDateFormat, true);

    setEndDateInvalid(!momentEndDate.isValid());

    if (momentEndDate.isValid()) {
      setDatesQuery({ e: momentEndDate.format(calendarDateFormat) });
    }
  };

  const handleSearchClick = () => {
    setQuery({ q: searchTerm, p: 1 });

    clearServiceOrders();
    searchServiceOrders(1, window.location.search);
  };

  const handleLoadNextPage = () => {
    let nextPage = query.p + 1;

    setQuery({ p: nextPage });

    searchServiceOrders(nextPage, window.location.search);
  };

  const dateRangeMarkup = (
    <DateRange
      selectedStartDate={datesQuery.s}
      selectedEndDate={datesQuery.e}
      onStartChange={date => handleStartDateChange(date)}
      onEndChange={date => handleEndDateChange(date)}
      dateFormat={displayDateFormat}
    />
  );

  const startDateErrorMarkup = startDateInvalid && (
    <Text error>
      <FormattedMessage id='dashboard.startDateInvalid' />
    </Text>
  );

  const endDateErrorMarkup = endDateInvalid && (
    <Text error>
      <FormattedMessage id='dashboard.endDateInvalid' />
    </Text>
  );

  const dateRangeErrorMarkup = dateRangeInvalid && (
    <Text error>
      <FormattedMessage id='dashboard.dateRangeInvalid' />
    </Text>
  );

  const resultsLabelMarkup = isLoading ? (
    <Row>
      <Col>
        <LoadingDots />
      </Col>
    </Row>
  ) : (
    <Row>
      <Col>
        <FormattedMessage
          id='dashboard.showingLabel'
          values={{ resultsCount: count * query.p, totalCount: totalCount }}
        />
      </Col>
    </Row>
  );

  const loadingResultsMarkup = (
    <Row center='xs' middle='xs'>
      <Col center='xs' middle='xs' className='mt-3 mb-3'>
        <Loader type='tiles' />
      </Col>
    </Row>
  );

  const noResultsMarkup = (
    <Row>
      <NoResultsCol>
        <Text bold center lg>
          <FormattedMessage id='dashboard.noOrdersFound' />
        </Text>
      </NoResultsCol>
    </Row>
  );

  const resultsListMarkup = (
    <StyledInfiniteScroll
      dataLength={purchaseOrders.length}
      next={handleLoadNextPage}
      hasMore={purchaseOrders.length < totalCount && purchaseOrders.length <= maxResults}
      loader={loadingResultsMarkup}
      threshold={300}
    >
      {searchResultsMarkup}
    </StyledInfiniteScroll>
  );

  const resultsMarkup =
    isLoading && query.p === 1 ? loadingResultsMarkup : purchaseOrders.length > 0 ? resultsListMarkup : noResultsMarkup;

  return (
    <Fragment>
      <Row>
        <Col xs={12} sm className='mt-2 mb-2'>
          <Text bold>
            <FormattedMessage id='dashboard.waitingPeriodWarning' />
          </Text>
        </Col>
        <Col xs={12} sm='auto' end='xs'>
          <Button data-test-id='resultsToExcelButton' onClick={exportServiceOrders} loading={exportIsLoading} icon='file-download'>
            <FormattedMessage id='dashboard.resultsToExcel' />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg={7} xs={12}>
          <Row>
            <Col xs='auto'>
              {dateRangeMarkup}
              <div className='mt-2'>
                {startDateErrorMarkup}
                {endDateErrorMarkup}
                {dateRangeErrorMarkup}
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <InputGroup>
            <TextInput
              data-test-id='searchBarTextInput'
              placeholder={intl.formatMessage({ id: 'general.search' })}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              loading={isLoading}
              inputRef={searchRef}
              onSubmit={handleSearchClick}
              data-hj-allow
            />
            <InputGroupAppend>
              <Button data-test-id='searchInputQuestionCircle' onClick={() => toggleSearchHelpModal()}>
                <Icon type='question-circle' />
              </Button>
            </InputGroupAppend>
            <InputGroupAppend>
              <Button data-test-id='searchInputSearchIcon' onClick={() => handleSearchClick()} loading={isLoading}>
                <Icon type='search' />
              </Button>
            </InputGroupAppend>
          </InputGroup>
        </Col>
      </Row>
      {resultsLabelMarkup}
      {resultsMarkup}
      <SearchHelpModal hidden={!showSearchHelpModal} toggle={toggleSearchHelpModal} />
    </Fragment>
  );
};

export default DashboardTab;
