import {Button, Col, Input, message, Radio, Row, Skeleton, Space, Typography} from 'antd';
import React, {createRef, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useServiceOrder, useToggle, useUser} from '../../../../hooks';
import LmrOptions from '../../../../components/LmrOptions';
import {Icon} from '@met/react-components';
import LmrDefinitionModal
  from '../../../RepairProcess/RepairItems/components/WarrantyInformation/components/LmrDefinitionModal';
import {CreditCardSpace, RadioContainer} from '../../styled';
import {squareActions} from '../../../../store';
import {useDispatch, useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCreditCard} from '@fortawesome/free-solid-svg-icons';
import {DeniedWarrantyTypes, PaymentMethodTypes, PreferredContactMethodTypes} from '../../../../enums';
import SquarePaymentForm from '../../../../components/SquarePayments/Add/SquarePaymentForm';
import {toast} from 'react-toastify';

const { Paragraph, Text } = Typography;

const DeniedWarrantyActionsWithCreditCards = ({ onSubmit }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const ref = createRef();

  const { serviceOrder } = useServiceOrder();
  const { email, phoneNumber, accountNumber, preferredContactMethod } = useUser();
  
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [selectedCreditCard, setSelectedCreditCard] = useState(null);
  const [poNumber, setPoNumber] = useState(null);
  const [formIsValid, setFormIsValid] = useState(false);
  const [showLmrModal, toggleLmrModal] = useToggle(false);
  const [showAddCreditCard, toggleShowAddCreditCard] = useToggle(false);
  
  const userCreditCards = useSelector(state => state.square.creditCards);
  const squareApplicationId = useSelector(state => state.square.squareApplicationId);
  const userCreditCardsIsLoading = useSelector(state => state.square.getCreditCards.isLoading);
  const useCreditCardIsSaving = useSelector(state => state.square.addCreditCard.isLoading);
  const squareConfigIsLoading = useSelector(state => state.square.getSquareConfig.isLoading);

  const warrantyLines = serviceOrder && (
    <LmrOptions
      lmrMappings={serviceOrder.productLmrMappings}
      country='us'
      style={{ fontWeight: '600', color: '#db011c' }}
    />
  );

  useEffect(() => {
    if (!squareApplicationId) {
      dispatch(squareActions.getSquareConfig()).then(() => {
        getUserCreditCards();
      });
    }
  }, [squareApplicationId]);

  useEffect(() => {
    setSelectedPaymentMethod(null);
  }, [selectedAction]);

  useEffect(() => {
    setPoNumber(null);
  }, [selectedPaymentMethod]);

  useEffect(() => {
    setFormIsValid(false);

    if ([DeniedWarrantyTypes.RETURN, DeniedWarrantyTypes.RECYCLE].includes(selectedAction)) {
      setFormIsValid(true);
    } else {
      switch (selectedPaymentMethod) {
        case PaymentMethodTypes.PAYLATER:
          setFormIsValid(true);
          break;
        case PaymentMethodTypes.PURCHASEORDER:
          if (poNumber) {
            setFormIsValid(true);
          }
          break;
        case PaymentMethodTypes.CREDITCARD:
          if (selectedCreditCard) {
            setFormIsValid(true);
          }
          break;
      }
    }
  }, [selectedAction, selectedPaymentMethod, poNumber, selectedCreditCard]);

  const getUserCreditCards = () => {
    dispatch(squareActions.getCreditCards()).catch(e => {
      message.error(intl.formatMessage({ id: 'square.getPaymentMethodError' }));
    });
  };

  const handleSelectedActionChange = e => {
    setSelectedAction(e.target.value);
    setSelectedCreditCard(null);
  };

  const handleSubmit = () => {
    let deniedWarrantyState = {
      action: selectedAction,
      paymentMethod: selectedPaymentMethod,
      poNumber: poNumber,
      creditCard: selectedCreditCard,
    };

    onSubmit(deniedWarrantyState);
  };

  const addCardClick = () => {
    setSelectedCreditCard(null);
    setSelectedPaymentMethod(null);
    toggleShowAddCreditCard();
  };

  const handleCreditCardAdded = (cardData, nonce, cardholderNameValue, saveCard) => {
    dispatch(squareActions.addCreditCard(cardData.billing_postal_code, cardholderNameValue, nonce))
      .then(r => {
        getUserCreditCards();
        toast.success(intl.formatMessage({ id: 'square.paymentMethodAddedSuccess' }));
        toggleShowAddCreditCard();
      })
      .catch(e => {
        message.error(intl.formatMessage({ id: 'square.addCreditCardError' }));
      });
  };

  const contactMethodDescription =
    preferredContactMethod === PreferredContactMethodTypes.EMAIL ? (
      <FormattedMessage id='repairDetails.deniedWarranty.contactViaEmail' values={{ email: email }} />
    ) : (
      <FormattedMessage id='repairDetails.deniedWarranty.contactViaPhone' values={{ phoneNumber: phoneNumber }} />
    );

  const payLaterPaymentDescription = selectedAction === DeniedWarrantyTypes.REPAIR &&
    selectedPaymentMethod === PaymentMethodTypes.PAYLATER && (
      <Space direction='vertical'>
        <Text>{contactMethodDescription}</Text>
        <a data-test-id='preferencesLink' href='/preferences' target='_self'>
          <FormattedMessage id='repairDetails.deniedWarranty.changePreferences' />
        </a>
      </Space>
    );

  const plPaymentOptionMarkup = !serviceOrder.isNationalAccount && (
    <Radio data-test-id='payLaterRadio' value={PaymentMethodTypes.PAYLATER}>
      <Space direction='vertical'>
        <Text>
          <FormattedMessage id='repairDetails.deniedWarranty.payLater' />
        </Text>
        {payLaterPaymentDescription}
      </Space>
    </Radio>
  );

  const poPaymentDescription = selectedAction === DeniedWarrantyTypes.REPAIR &&
    selectedPaymentMethod === PaymentMethodTypes.PURCHASEORDER && (
      <Space direction='vertical'>
        <Input
          data-test-id='purchaseOrderInput'
          onChange={e => setPoNumber(e.target.value)}
          placeholder={intl.formatMessage({ id: 'repairDetails.deniedWarranty.poNumberPlaceholder' })}
        />
        <Paragraph>
          <FormattedMessage id='repairDetails.deniedWarranty.poNumberNotDescription' />
        </Paragraph>
      </Space>
    );

  // Hide PO payment option if user has no account number
  const poPaymentOptionMarkup = accountNumber && (
    <Radio data-test-id='purchaseOrderRadio' value={PaymentMethodTypes.PURCHASEORDER}>
      <Space direction='vertical'>
        <FormattedMessage id='repairDetails.deniedWarranty.poLabel' />
        {poPaymentDescription}
      </Space>
    </Radio>
  );

  const creditCardListMarkup =
    !showAddCreditCard &&
    userCreditCards &&
    userCreditCards.map((item, index) => {
      return (
        <RadioContainer key={'cc-' + index} value={item}>
          <Row gutter={[16, 16]}>
            <Col>
              <Space direction='vertical' style={{ width: '100%' }}>
                <Text strong>Card Ending in {item.cardLast4}</Text>
                <Text>{item.cardholderName}</Text>
              </Space>
            </Col>
            <Col>
              <FontAwesomeIcon icon={faCreditCard} size='xl' />
            </Col>
          </Row>
        </RadioContainer>
      );
    });

  // pay with existing credit card
  const existingCCPaymentOptionMarkup = !showAddCreditCard && (
    <Skeleton
      loading={squareConfigIsLoading || userCreditCardsIsLoading}
      paragraph={{ rows: 4, width: '100%' }}
      title={false}
      active
    >
      <Radio.Group
        onChange={e => setSelectedCreditCard(e.target.value)}
        disabled={selectedPaymentMethod !== PaymentMethodTypes.CREDITCARD}
      >
        {creditCardListMarkup}
      </Radio.Group>
    </Skeleton>
  );

  const addCreditCardMarkup = showAddCreditCard && (
    <Space direction='vertical' style={{ width: '350px' }}>
      <Text strong>
        <FormattedMessage id='square.addNewCard' />
      </Text>
      <SquarePaymentForm ref={ref} onCardNonceSuccess={handleCreditCardAdded} showSaveCard={true} />
      <Space direction='horizontal'>
        <Button
          data-test-id='cancelBtn'
          onClick={toggleShowAddCreditCard}
        >
          <FormattedMessage id='general.cancel'/>
        </Button>
        <Button 
          data-test-id='saveBtn'
          type='primary' 
          onClick={() => ref?.current?.handleFormSubmit()} 
          loading={useCreditCardIsSaving || squareConfigIsLoading}
        >
          <FormattedMessage id='general.save'/>
        </Button>
      </Space>
    </Space>
  );
  
  const addCreditCardButton = !showAddCreditCard && (
    <Button
      type='primary'
      onClick={() => addCardClick()}
      disabled={selectedPaymentMethod !== PaymentMethodTypes.CREDITCARD}
    >
      <FormattedMessage id='square.addCard'/>
    </Button>
  );

  const ccPaymentOptionMarkup = !serviceOrder.isNationalAccount && (
    <Col xs={24} lg={12}>
      <Radio data-test-id='creditCardRadio' value={PaymentMethodTypes.CREDITCARD}>
        <CreditCardSpace direction='vertical'>
          <Text strong>
            <FormattedMessage id='repairDetails.deniedWarranty.creditCard' />
          </Text>
          {existingCCPaymentOptionMarkup}
          {addCreditCardButton}
        </CreditCardSpace>
      </Radio>
    </Col>
  );
  
  const paymentOptionsMarkup = selectedAction === DeniedWarrantyTypes.REPAIR && (
    <>
      {
        !showAddCreditCard && (
          <>
            <Text strong>
              <FormattedMessage id='repairDetails.deniedWarranty.selectPaymentMethod' />
            </Text>
            <Radio.Group
              data-test-id='deniedWarrantyPaymentMethodRadioGroup'
              onChange={e => setSelectedPaymentMethod(e.target.value)}
              style={{ width: '100%' }}
            >
              <Row style={{ width: '100%' }}>
                {ccPaymentOptionMarkup}
                <Col xs={24} lg={12}>
                  <Space direction={'vertical'}>
                    {poPaymentOptionMarkup}
                    {plPaymentOptionMarkup}
                  </Space>
                </Col>
              </Row>
            </Radio.Group>
          </>
        )
      }
      {addCreditCardMarkup}
    </>
  );

  const repairDescription = <FormattedMessage id='repairDetails.deniedWarranty.lmrDescription' />;

  const lmrMarkup = selectedAction === DeniedWarrantyTypes.REPAIR && warrantyLines && (
    <>
      <Text>
        <FormattedMessage id='productDetail.toolInfo.lmrTitle' />
        <Icon
          data-test-id='deniedWarrantyLMRToolTitleIcon'
          className='ml-2'
          type='question-circle'
          xs
          onClick={toggleLmrModal}
        />
      </Text>
      {warrantyLines}
    </>
  );

  const deniedWarranty = (
    <Space direction='vertical'>
      <Radio.Group
        data-test-id='deniedWarrantyRepairLabelRadioGroup'
        onChange={e => handleSelectedActionChange(e)}
        size='large'
        buttonStyle='solid'
      >
        <Space direction='vertical'>
          <RadioContainer value={DeniedWarrantyTypes.REPAIR}>
            <Space direction='vertical'>
              <Text>
                <FormattedMessage id='repairDetails.deniedWarranty.repairLabel' />
              </Text>
              <Text type={selectedAction === DeniedWarrantyTypes.REPAIR ? 'primary' : 'secondary'}>
                {repairDescription}
              </Text>
              {lmrMarkup}
              <LmrDefinitionModal isVisible={showLmrModal} toggle={() => toggleLmrModal()} />
              {paymentOptionsMarkup}
            </Space>
          </RadioContainer>
          <RadioContainer value={DeniedWarrantyTypes.RETURN}>
            <Space direction='vertical'>
              <Text>
                <FormattedMessage id='repairDetails.deniedWarranty.returnLabel' />
              </Text>
              <Text type={selectedAction === DeniedWarrantyTypes.RETURN ? 'primary' : 'secondary'}>
                <FormattedMessage id='repairDetails.deniedWarranty.returnDescription' />
              </Text>
            </Space>
          </RadioContainer>
          <RadioContainer value={DeniedWarrantyTypes.RECYCLE}>
            <Space direction='vertical'>
              <Text>
                <FormattedMessage id='repairDetails.deniedWarranty.recycleLabel' />
              </Text>
              <Text type={selectedAction === DeniedWarrantyTypes.RECYCLE ? 'primary' : 'secondary'}>
                <FormattedMessage id='repairDetails.deniedWarranty.recycleDescription' />
              </Text>
            </Space>
          </RadioContainer>
        </Space>
      </Radio.Group>
      <Row justify={'end'} gutter={[16, 16]}>
        <Col>
          <Button data-test-id='nextBtn' type='primary' disabled={!formIsValid} onClick={() => handleSubmit()}>
            <FormattedMessage id='general.next' />
          </Button>
        </Col>
      </Row>
    </Space>
  );

  return <>{deniedWarranty}</>;
};

export default DeniedWarrantyActionsWithCreditCards;
