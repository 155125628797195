import { List, Row, Col, Typography, Button } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Icon, { EditOutlined } from '@ant-design/icons';
import { ReactComponent as MapMarker } from '../../../../assets/map-marker.svg';
import { ReactComponent as Pencil } from '../../../../assets/pencil-alt-solid.svg';

const { Text } = Typography;

const DefaultServiceCenterLine = ({ defaultServiceCenter, editDefaultServiceCenterClick, index }) => {
  return (
    <List.Item key={'defaultServiceCenter-' + index} data-test-id={'defaultServiceCenter-' + index}>
      <Row style={{ width: '100%' }} align={'vertical'}>
        <Col xs={2} md={1} style={{ textAlign: 'center', margin: 'auto' }}>
          <Icon component={MapMarker} style={{ color: '#17A2B8', fontSize: '20px' }} />
        </Col>
        <Col xs={8} style={{ margin: 'auto' }}>
          <Text>{defaultServiceCenter.countryCode}</Text>
        </Col>
        <Col xs={8}>
          <Text strong style={{ display: 'block', fontSize: '12px' }}>
            Service Center
          </Text>
          <Text>{defaultServiceCenter.serviceCenterDescription}</Text>
        </Col>
        <Col xs={6} md={7} style={{ textAlign: 'right' }}>
          <Button
            type={'primary'}
            icon={<Icon component={Pencil} />}
            onClick={() => editDefaultServiceCenterClick()}
            data-test-id={'editDefaultServiceCenterBtn-' + index}
          >
            <Text style={{ color: 'white' }}>
              <FormattedMessage id='general.edit' />
            </Text>
          </Button>
        </Col>
      </Row>
    </List.Item>
  );
};

export default DefaultServiceCenterLine;
