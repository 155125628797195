import { Alert, Button, notification, Space, Typography } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { InfoGrid, InfoGridItem } from '../../../../components';
import { downloadBlob } from '../../../../helpers/downloadBlob';
import {
  useContact,
  useFeatureToggles,
  useLocale,
  useServiceOrder,
  useServiceOrders,
  useShipping,
  useToggle,
} from '../../../../hooks';
import DeniedWarrantyModal from './DeniedWarrantyModal';
import DeniedWarrantyActions from './DeniedWarrantyActions';
import DeniedWarrantyActionsWithCreditCards from './DeniedWarrantyActionsWithCreditCards';
import RepairOrderSummary from './RepairOrderSummary';
import LmrDefinitionModal from '../../../RepairProcess/RepairItems/components/WarrantyInformation/components/LmrDefinitionModal';
import { squareActions } from '../../../../store';
import { DeniedWarrantyTypes, PaymentMethodTypes } from '../../../../enums';

const { Paragraph, Text } = Typography;

const OrderStatus = ({ trackingNumber, shippoTrackingFailure }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { creditCardPayments } = useFeatureToggles();
  const { serviceOrder, upsertWarrantyOption, warrantyOption, paymentMethodId } = useServiceOrder();
  const { getEmailLink, getPhoneLink, getServiceCenterLink } = useContact();
  const { getTrackingMarkup, getProviderLocationMarkup } = useShipping();
  const { getServiceOrdersPdf, generatingPaperwork } = useServiceOrders();
  const { isCaLocale } = useLocale();

  const [serviceOrderStatus, setServiceOrderStatus] = useState(serviceOrder.status);
  const [deniedWarrantyState, setDeniedWarrantyState] = useState(null);
  const [showDeniedWarrantyModal, setShowDeniedWarrantyModal] = useState(false);
  const [showLmrModal, toggleLmrModal] = useToggle(false);
  const [lmrPrice, setLmrPrice] = useState(null);
  const [showRepairOrderSummary, setShowRepairOrderSummary] = useState(false);
  const [usePaymentMethodV2, setUsePaymentMethodV2] = useState(false);

  useEffect(() => {
    if (creditCardPayments && serviceOrder && serviceOrder.countryCode.toLowerCase() === 'usa') {
      setUsePaymentMethodV2(true);
    }
  }, [serviceOrder]);

  useEffect(() => {
    if (serviceOrder.status === 1) {
      if (!trackingNumber) {
        setServiceOrderStatus(0); // When order is processing, use pseudo-status of 0 (Processing)
      } else {
        setServiceOrderStatus(1); // When order is done processing, use real status of 1 (Submitted)
      }
    }
  }, [trackingNumber]);

  useEffect(() => {
    if (serviceOrder && serviceOrder.serviceOrderLines && serviceOrder.serviceOrderLines.length > 0) {
      let deniedWarrantyLines = serviceOrder.serviceOrderLines.filter(x => x.isDeniedWarranty);

      if (deniedWarrantyLines.length > 0) {
        if (isCaLocale()) {
          setLmrPrice(deniedWarrantyLines[0].lmrPriceCa);
        } else {
          setLmrPrice(deniedWarrantyLines[0].lmrPrice);
        }
      }
    }
  }, [serviceOrder]);

  const handlePrintPaperworkClick = () => {
    notification.success({
      message: intl.formatMessage({ id: 'orderDetails.pdfDownloading' }),
      description: intl.formatMessage({ id: 'orderDetails.pdfDownloadingDetail' }),
    });

    dispatch(getServiceOrdersPdf(serviceOrder.serviceOrderGroupId))
      .then(response => {
        downloadBlob(`${serviceOrder.serviceOrderGroupId}.pdf`, response.data);
      })
      .catch(() => {
        notification.error({
          message: intl.formatMessage({ id: 'orderDetails.pdfDownloadError' }),
          description: intl.formatMessage({ id: 'orderDetails.pdfDownloadErrorDetail' }),
        });
      })
      .finally(() => {
        notification.destroy();
      });
  };

  const handleUpsertWarrantyOption = () => {
    dispatch(
      upsertWarrantyOption(serviceOrder.serviceOrderNumber, deniedWarrantyState.poNumber, deniedWarrantyState.action, deniedWarrantyState.paymentMethod)
    )
      .then(() => {
        notification.success({
          message: intl.formatMessage({ id: 'repairDetails.orderInfo.warrantyUpserted' }),
          description: intl.formatMessage({ id: 'repairDetails.orderInfo.warrantyUpsertedDetail' }),
        });
        setShowRepairOrderSummary(false);
      })
      .catch(() => {
        notification.error({
          message: intl.formatMessage({ id: 'repairDetails.orderInfo.warrantyUpsertError' }),
          description: intl.formatMessage({ id: 'repairDetails.orderInfo.warrantyUpsertErrorDetail' }),
        });
      });
  };

  const handleDeniedWarrantyClick = state => {
    setDeniedWarrantyState(state);

    if (usePaymentMethodV2) {
      setShowRepairOrderSummary(true);
    } else {
      // Original method
      setShowDeniedWarrantyModal(true);
    }
  };

  const handleDeniedWarrantyConfirm = () => {
    setShowDeniedWarrantyModal(false);
    handleUpsertWarrantyOption();
  };

  const handleDeniedWarrantyConfirmV2 = () => {
    if (
      deniedWarrantyState.action === DeniedWarrantyTypes.REPAIR &&
      deniedWarrantyState.paymentMethod === PaymentMethodTypes.CREDITCARD
    ) {
      dispatch(squareActions.authorizeCreditCard(deniedWarrantyState?.creditCard?.id, serviceOrder?.serviceOrderNumber))
        .then(() => {
          handleUpsertWarrantyOption();
        })
        .catch(() => {
          notification.error({
            message: intl.formatMessage({ id: 'general.error' }),
            description: intl.formatMessage({ id: 'repairDetails.deniedWarranty.authorizationError' })
          });
        });
    } else {
      handleUpsertWarrantyOption();
    }
  };

  const messageType = shippoTrackingFailure ? 'error' : 'info';

  let message;
  if (shippoTrackingFailure) {
    message = <FormattedMessage id='orderDetails.trackingInfoError' />;
  } else {
    let warrantyOptionMarkup = warrantyOption && (
      <Text>
        <span> - </span>
        <FormattedMessage id={`repairDetails.actionPending.${warrantyOption.toLowerCase()}Label`} />
      </Text>
    );

    message = (
      <Fragment>
        <FormattedMessage id={`repairDetails.orderInfo.orderStatus.status${serviceOrderStatus}`} />
        {warrantyOptionMarkup}
      </Fragment>
    );
  }

  // If status is In Transit
  const inboundTrackingMarkup =
    serviceOrder &&
    serviceOrder.trackingNumber &&
    serviceOrder.status === 2 &&
    getTrackingMarkup(0, serviceOrder.countryCode, serviceOrder.trackingNumber);

  // If status is Shipped -> Delivered
  let returnTrackingMarkup =
    serviceOrder &&
    serviceOrder.serviceOrderShipments &&
    serviceOrder.serviceOrderShipments.length > 0 &&
    [5, 6].includes(serviceOrder.status) &&
    getTrackingMarkup(0, serviceOrder.countryCode, serviceOrder.serviceOrderShipments[0].trackingNumber);

  let trackingMarkup = (inboundTrackingMarkup || returnTrackingMarkup) && (
    <InfoGridItem label={intl.formatMessage({ id: 'repairDetails.orderInfo.trackingInfo' })}>
      <Space direction='vertical' size={2}>
        {inboundTrackingMarkup}
        {returnTrackingMarkup}
      </Space>
    </InfoGridItem>
  );
  
  const repairDescription = (
    <div>
      <Paragraph>
        <FormattedMessage
          id={`repairDetails.actionPending.repair${!lmrPrice ? 'NoCost' : ''}Description`}
          values={{ cost: lmrPrice }}
        />
      </Paragraph>
      <Paragraph>
        <FormattedMessage id='repairDetails.actionPending.repairPaymentDescription' />
      </Paragraph>
      <Paragraph>
        <FormattedMessage id='repairDetails.actionPending.repairProcessDescription' />
      </Paragraph>
      <Paragraph>
        <FormattedMessage id='repairDetails.actionPending.repairContactDescription' />
      </Paragraph>
    </div>
  )
  
  const repairWithCreditCardDescription = (
    paymentMethodId === PaymentMethodTypes.PAYLATER ? (
      <div style={{ paddingBottom: '25px' }}>
        <div>
          <FormattedMessage id='repairDetails.actionPending.repairDescriptionPayLater' />
          <Text strong>
            <FormattedMessage id='repairDetails.actionPending.repairDescriptionPayLaterBold' />
          </Text>
          <FormattedMessage id='repairDetails.actionPending.repairDescriptionPayLater2' />
        </div>
        <div style={{ paddingTop: '20px'}}>
          <Text strong italic>
            <FormattedMessage id='repairDetails.actionPending.repairDescriptionPayLaterBold2' />
          </Text>
          <FormattedMessage id='repairDetails.actionPending.repairDescriptionPayLater3' />
        </div>
      </div>
    ) : paymentMethodId === PaymentMethodTypes.PURCHASEORDER ? (
      repairDescription
    ) : paymentMethodId === PaymentMethodTypes.CREDITCARD && (
      <div style={{ paddingBottom: '20px' }}>
        <FormattedMessage id='repairDetails.actionPending.repairDescriptionPayByCreditCard' />
        <Text strong>
          <FormattedMessage id='repairDetails.actionPending.repairDescriptionPayByCreditCardBold' />
        </Text>
      </div>
    )
  )

  let actions;
  if (shippoTrackingFailure) {
    actions = (
      <Space size='large' wrap>
        {getPhoneLink()}
        {getEmailLink()}
      </Space>
    );
  } else if (serviceOrderStatus === 1 && trackingNumber) {
    // Shipment generated but not yet in transit
    actions = (
      <Space size='large' wrap>
        <Button
          data-test-id='orderStatusPrintPaperworkButton'
          type='primary'
          onClick={() => handlePrintPaperworkClick()}
          loading={generatingPaperwork}
        >
          <FormattedMessage id='orderDetails.printPaperwork' />
        </Button>
        {getProviderLocationMarkup(serviceOrder.countryCode)}
      </Space>
    );
  } else if ([2, 5, 6].includes(serviceOrderStatus)) {
    // Shipment in transit to service center or customer
    actions = <InfoGrid>{trackingMarkup}</InfoGrid>;
  } else if (serviceOrderStatus === 7) {
    // Order is ready for pickup at service center
    actions = getServiceCenterLink();
  } else if (serviceOrderStatus === 9) {
    // Order requires customer input
    actions = (
      <Space size='large' wrap>
        {getPhoneLink()}
        {getEmailLink()}
      </Space>
    );
  } else if (serviceOrderStatus === 10 && ['Repair', 'Recycle'].includes(warrantyOption)) {
    // Order needs payment
    actions = (
      <Space size='large' wrap>
        {getPhoneLink()}
        {getEmailLink()}
      </Space>
    );
  } else if (serviceOrderStatus === 10 && !warrantyOption) {
    // Order is denied warranty but no action selected
    actions = usePaymentMethodV2 ? (
      <DeniedWarrantyActionsWithCreditCards onSubmit={handleDeniedWarrantyClick} />
    ) : (
      <DeniedWarrantyActions onSubmit={handleDeniedWarrantyClick} />
    );
  }

  let description;
  if (shippoTrackingFailure) {
    description = <FormattedMessage id='orderDetails.trackingInfoErrorDetail' />;
  } else if (serviceOrderStatus === 0) {
    description = (
      <div>
        <Paragraph>
          <FormattedMessage id='repairDetails.orderInfo.orderStatus.status0Details' />
        </Paragraph>
        <Paragraph>
          <FormattedMessage id='repairDetails.orderInfo.orderStatus.status0DetailsExt1' />
        </Paragraph>
      </div>
    );
  } else if (serviceOrderStatus === 10) {
    // Order is denied warranty
    if (warrantyOption === 'Repair') {
      // Customer wants tool repaired but needs to provide payment(
      if (usePaymentMethodV2) {
        description = repairWithCreditCardDescription;
      } else {
        description = repairDescription;
      }
    } else if (warrantyOption === 'Return') {
      // Customer wants tool returned
      description = (
        <div>
          <Paragraph>
            <FormattedMessage id='repairDetails.actionPending.returnDescription' />
          </Paragraph>
          <Paragraph>
            <FormattedMessage id='repairDetails.actionPending.returnChargesDescription' />
          </Paragraph>
        </div>
      );
    } else if (warrantyOption === 'Recycle') {
      // Customer wants tool recycled
      description = (
        <div>
          <Paragraph>
            <FormattedMessage id='repairDetails.actionPending.recycleDescription' />
          </Paragraph>
          <Paragraph>
            <FormattedMessage id='repairDetails.actionPending.recycleMistakeDescription' />
          </Paragraph>
        </div>
      );
    } else {
      // Tool was denied warranty, customer needs to select preference
      let diagnosesMarkup =
        serviceOrder &&
        serviceOrder.serviceOrderRepairLines &&
        serviceOrder.serviceOrderRepairLines.map((line, index) => {
          return (
            <InfoGrid column={1} key={`diagnosis-${index}`}>
              <InfoGridItem label={intl.formatMessage({ id: 'repairDetails.deniedWarranty.symptomCode' })}>
                <span>{line.symptomCode}</span>
              </InfoGridItem>
              <InfoGridItem label={intl.formatMessage({ id: 'repairDetails.deniedWarranty.reason' })}>
                <span>{line.diagnosisAreaName}</span>
              </InfoGridItem>
            </InfoGrid>
          );
        });

      description = (
        <Space direction='vertical'>
          {diagnosesMarkup}
          <div>
            <Paragraph>
              <FormattedMessage id='repairDetails.orderInfo.orderStatus.status10DetailsExt1' />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id='repairDetails.orderInfo.orderStatus.status10DetailsExt2' />
            </Paragraph>
            <Paragraph style={{ marginBottom: 0 }}>
              <FormattedMessage id='repairDetails.orderInfo.orderStatus.status10DetailsExt3' />
            </Paragraph>
          </div>
        </Space>
      );
    }
  } else {
    // If user selected denied warranty action, tell them what they selected
    let warrantyOptionMarkup = serviceOrderStatus > 3 && warrantyOption && (
      <Paragraph>
        <FormattedMessage
          id={`repairDetails.actionPending.${warrantyOption.toLowerCase()}Description`}
          values={{ cost: lmrPrice }}
        />
      </Paragraph>
    );

    description = (
      <div>
        {warrantyOptionMarkup}
        <Paragraph style={{ marginBottom: 0 }}>
          <FormattedMessage id={`repairDetails.orderInfo.orderStatus.status${serviceOrderStatus}Details`} />
        </Paragraph>
      </div>
    );
  }

  const descriptionMarkup = (
    <Space direction='vertical' size='middle'>
      {description}
      {actions}
    </Space>
  );

  const deniedWarrantyModalMarkup = deniedWarrantyState && (
    <DeniedWarrantyModal
      visible={showDeniedWarrantyModal}
      onOk={handleDeniedWarrantyConfirm}
      onCancel={() => setShowDeniedWarrantyModal(false)}
      state={deniedWarrantyState}
    />
  );

  const orderStatus = !showRepairOrderSummary && (
    <Alert type={messageType} message={message} description={descriptionMarkup} />
  );

  const repairOrderSummary = usePaymentMethodV2 && showRepairOrderSummary && (
    <RepairOrderSummary
      deniedWarrantyState={deniedWarrantyState}
      onBackClick={() => setShowRepairOrderSummary(false)}
      onSubmitClick={() => handleDeniedWarrantyConfirmV2()}
      toggleLmrModal={toggleLmrModal}
    />
  );

  return (
    <Fragment>
      {orderStatus}
      {repairOrderSummary}
      {deniedWarrantyModalMarkup}
      <LmrDefinitionModal isVisible={showLmrModal} toggle={() => toggleLmrModal()} />
    </Fragment>
  );
};

export default OrderStatus;
