import React from 'react';
import { Modal, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

const MaxPackagesAlertModal = ({ showModal, handleConfirmClick }) => {
  return (
    <Modal
      closeIcon={<CloseOutlined data-test-id='maxPackagesModalXButton' />}
      visible={showModal}
      title={<FormattedMessage id='repairProcess.caRestrictionTitle' />}
      footer={
        <Button data-test-id='maxPackagesModalButton' type='primary' onClick={handleConfirmClick}>
          <FormattedMessage id='general.ok' />
        </Button>
      }
      onCancel={handleConfirmClick}
      destroyOnClose={true}
    >
      <span data-test-id='maxPackagesModalText'>
        <FormattedMessage id='repairProcess.usRestrictionText' />
      </span>
    </Modal>
  );
};

export default MaxPackagesAlertModal;
