import {
  Button,
  Col,
  Icon,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Loader,
  Row,
  Text,
  TextInput,
  useToggle,
} from '@met/react-components';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { intlSyncActions, productProblemActions } from '../../../../store';
import ProductProblemModal from './ProductProblemModal';
import { Button as AntButton, PageHeader } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const ProductProblemTab = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const loadingProblems = useSelector(state => state.productProblems.getProductProblems.isLoading);
  const problems = useSelector(state => state.productProblems.getProductProblems.productProblems);
  const upsertingProblem = useSelector(state => state.productProblems.upsertProductProblem.isLoading);
  const isSyncingProblems = useSelector(state => state.intlSync.syncProductProblemsToIntl.isLoading);
  const [filter, setFilter] = useState('');
  const [filteredProblems, setFilteredProblems] = useState([]);
  const [selectedProblem, setSelectedProblem] = useState({});
  const [showModal, setShowModal] = useToggle(false);

  useEffect(() => {
    dispatch(productProblemActions.getProductProblems()).catch(() => {
      toast.error(intl.formatMessage({ id: 'admin.serviceOrders.loadingServiceOrderStatusesErrorMessage' }), {
        autoClose: false,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    let probs = problems.filter(problem => {
      return (
        !filter || 0 === filter.length || problem.description?.toLocaleLowerCase()?.includes(filter.toLocaleLowerCase())
      );
    });
    setFilteredProblems([...probs]);
  }, [filter]);

  useEffect(() => {
    setFilteredProblems([...problems]);
  }, [problems]);

  const handleNewProblemClick = () => {
    setSelectedProblem({});
    setShowModal();
  };

  const handleEditProblemClick = problem => {
    setSelectedProblem(problem);
    setShowModal();
  };

  const handleCancelClick = () => {
    setShowModal();
  };
  const handleSaveChangesClick = (id, description) => {
    dispatch(productProblemActions.upsertProductProblem(id, description))
      .then(payload => {
        setShowModal();
        toast.success(intl.formatMessage({ id: 'admin.problems.problemUpdated' }), { autoClose: 5000 });
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.problems.problemError' }), { autoClose: 5000 });
      });
  };

  const handleSyncProblems = () => {
    dispatch(intlSyncActions.syncProductProblemsToIntl()).then(res => {
      toast.success('Successfully Synced Problems to Intl', { autoClose: 5000 });
    });
  };

  const searchResultsList =
    filteredProblems &&
    filteredProblems.map((problem, index) => {
      return (
        <ListGroupItem key={`search-result-${index}`}>
          <Row>
            <Col xs='auto' center='xs' middle='xs' className='mb-2 mb-md-0'>
              <Icon type='tools' sm />
            </Col>
            <Col middle='xs'>
              <Text sm bold>
                {problem.description}
              </Text>
            </Col>
            <Col xs='auto' right='xs' middle='xs'>
              <Button data-test-id='adminProductProblemsEditButton' primary icon='pencil' onClick={() => handleEditProblemClick(problem)}>
                <FormattedMessage id='general.edit' />
              </Button>
            </Col>
          </Row>
        </ListGroupItem>
      );
    });

  const loadingSearchResultsMarkup = loadingProblems && (
    <Row className='mt-3'>
      <Col center='xs'>
        <Loader type='tiles' />
      </Col>
    </Row>
  );

  const searchResultsMarkup = problems && problems.length > 0 && (
    <Fragment>
      <Row>
        <Col xs={8}>
          <ListGroup>{searchResultsList}</ListGroup>
        </Col>
      </Row>
    </Fragment>
  );

  const noSearchResultsMarkup = !loadingProblems && problems && problems.length === 0 && (
    <Row>
      <Col>
        <ListGroup>
          <ListGroupItem>
            <Row>
              <Col middle='xs' center='xs'>
                <FormattedMessage id='admin.problems.noSearchResults' />
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Col>
    </Row>
  );
  const productProblemModal = (
    <ProductProblemModal
      hidden={!showModal}
      toggle={setShowModal}
      problem={selectedProblem}
      onCancelClick={handleCancelClick}
      onSaveChangesClick={handleSaveChangesClick}
      problems={problems}
      upsertingProblem={upsertingProblem}
    />
  );
  const filterBarMarkup = (
    <Row>
      <Col middle='xs' xs={2}>
        <Text className='mb-2'>
          <FormattedMessage id='admin.problems.filterLabel' />
        </Text>
      </Col>
      <Col middle='xs' sm={6}>
        <InputGroup>
          <TextInput data-test-id='adminProductsTabFilterProblemsTextInput' onChange={e => setFilter(e.target.value)} value={filter} />
        </InputGroup>
      </Col>
    </Row>
  );
  return (
    <Fragment>
      <PageHeader
        title={intl.formatMessage({ id: 'admin.problems.header' })}
        extra={[
          <AntButton data-test-id='adminProductsTabSyncProblemsToIntlButton' loading={isSyncingProblems} key='sync' type='primary' onClick={() => handleSyncProblems()}>
            <span>Sync Problems to Intl</span>
          </AntButton>,
          <AntButton data-test-id='adminProductsTabAddProductProblemButton' key='add' type='primary' onClick={() => handleNewProblemClick()} icon={<PlusOutlined />}>
            <span>
              <FormattedMessage id='general.add' />
            </span>
          </AntButton>,
        ]}
      >
        {filterBarMarkup}
        {loadingSearchResultsMarkup}
        {searchResultsMarkup}
        {noSearchResultsMarkup}
      </PageHeader>
      {productProblemModal}
    </Fragment>
  );
};

export default ProductProblemTab;
