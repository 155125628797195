import createAxiosAction from '../createAxiosAction';
import {
  CLEAR_SEARCH_PRODUCTS,
  DELETE_SERVICE_SKU_MAPPING,
  GET_PRODUCT,
  GET_PRODUCT_IMAGE,
  SEARCH_PRODUCTS,
  UPSERT_SERVICE_SKU_MAPPING,
  UPSERT_PRODUCT_PROBLEM_MAPPING,
  DELETE_PRODUCT_PROBLEM_MAPPING,
  UPSERT_PRODUCT_INCLUDED_MAPPING,
  DELETE_PRODUCT_INCLUDED_MAPPING,
  REFRESH_PRODUCT_INCLUDED_MAPPING,
  INSERT_PRODUCT_PROBLEM_MAPPING_BULK,
  ADD_ALL_SERVICE_MAPPINGS,
  DELETE_LMR_MAPPING,
  UPSERT_LMR_MAPPING,
  LOAD_ALL_LMR_MAPPINGS,
  LOAD_SKU_LMR_MAPPINGS,
  GET_LMR_MAPPINGS_BY_SKU,
} from '../types';

export default {
  searchProducts: searchTerm => {
    return createAxiosAction({
      url: `/api/v1/products/?q=${searchTerm}`,
      startAction: SEARCH_PRODUCTS,
    });
  },
  clearSearchProducts: () => {
    return {
      type: CLEAR_SEARCH_PRODUCTS,
    };
  },
  getProduct: sku => {
    return createAxiosAction({
      url: `/api/v1/products/${sku}`,
      startAction: GET_PRODUCT,
    });
  },
  getProductImage: sku => {
    return createAxiosAction({
      url: `/api/v1/products/${sku}/image`,
      startAction: GET_PRODUCT_IMAGE,
      actionPayload: {
        sku,
      },
    });
  },
  getProductForAdmin: sku => {
    return createAxiosAction({
      url: `/api/v1/products/${sku}/?a=true`,
      startAction: GET_PRODUCT,
    });
  },
  upsertServiceSkuMapping: (serviceCenterId, sku) => {
    return createAxiosAction({
      url: `/api/v1/products/${sku}/servicecenter`,
      method: 'POST',
      body: {
        serviceCenterId,
        sku,
      },
      startAction: UPSERT_SERVICE_SKU_MAPPING,
    });
  },
  upsertAllServiceMappingsForSku: sku => {
    return createAxiosAction({
      url: `/api/v1/products/${sku}/servicecenterall`,
      method: 'POST',
      body: {
        sku,
      },
      startAction: ADD_ALL_SERVICE_MAPPINGS,
    });
  },
  deleteServiceSkuMapping: (serviceCenterId, sku) => {
    return createAxiosAction({
      url: `/api/v1/products/${sku}/servicecenter`,
      method: 'DELETE',
      body: {
        serviceCenterId,
        sku,
      },
      startAction: DELETE_SERVICE_SKU_MAPPING,
    });
  },
  upsertProductProblemMapping: (problemId, sku) => {
    return createAxiosAction({
      url: `/api/v1/products/${sku}/problem`,
      method: 'POST',
      body: {
        problemId,
        sku,
      },
      startAction: UPSERT_PRODUCT_PROBLEM_MAPPING,
    });
  },
  deleteProductProblemMapping: (problemId, sku) => {
    return createAxiosAction({
      url: `/api/v1/products/${sku}/problem`,
      method: 'DELETE',
      body: {
        problemId,
        sku,
      },
      startAction: DELETE_PRODUCT_PROBLEM_MAPPING,
    });
  },
  insertBulkProductProblems: (problemIds, sku) => {
    return createAxiosAction({
      method: 'POST',
      url: `/api/v1/products/${sku}/problem/bulk`,
      body: {
        problemIds,
        sku,
      },
      startAction: INSERT_PRODUCT_PROBLEM_MAPPING_BULK,
    });
  },
  deleteBulkProductProblems: (problemIds, sku) => {
    return createAxiosAction({
      method: 'DELETE',
      url: `/api/v1/products/${sku}/problem/bulk`,
      body: {
        problemIds,
        sku,
      },
      startAction: INSERT_PRODUCT_PROBLEM_MAPPING_BULK,
    });
  },
  upsertProductIncludedMapping: (includedId, sku) => {
    return createAxiosAction({
      url: `/api/v1/products/${sku}/included`,
      method: 'POST',
      body: {
        includedId,
        sku,
      },
      startAction: UPSERT_PRODUCT_INCLUDED_MAPPING,
    });
  },
  deleteProductIncludedMapping: (includedId, sku) => {
    return createAxiosAction({
      url: `/api/v1/products/${sku}/included`,
      method: 'DELETE',
      body: {
        includedId,
        sku,
      },
      startAction: DELETE_PRODUCT_INCLUDED_MAPPING,
    });
  },
  loadAllLmrMappings: () => {
    return createAxiosAction({
      url: `/api/v1/products/lmr/load`,
      startAction: LOAD_ALL_LMR_MAPPINGS,
    });
  },
  loadSkuLmrMappings: sku => {
    return createAxiosAction({
      url: `/api/v1/products/${sku}/lmr/load`,
      startAction: LOAD_SKU_LMR_MAPPINGS,
    });
  },
  refreshProductInclusions: sku => {
    return createAxiosAction({
      url: `api/v1/productIncluded/refresh/${sku}`,
      startAction: REFRESH_PRODUCT_INCLUDED_MAPPING,
    });
  },
  upsertLmrMapping: (sku, mapping) => {
    let body = {
      sku: sku,
      lmrId: mapping.lmrId,
      lmrCategoryTypeId: mapping.lmrCategoryTypeId,
      lmrLabel: mapping.lmrLabel,
    };
    return createAxiosAction({
      url: `/api/v1/products/lmrMapping/upsert`,
      method: 'POST',
      body: body,
      startAction: UPSERT_LMR_MAPPING,
    });
  },
  deleteLmrMapping: (sku, lmrId) => {
    return createAxiosAction({
      url: `/api/v1/products/lmrMapping/delete`,
      startAction: DELETE_LMR_MAPPING,
      method: 'DELETE',
      body: {
        lmrId,
        sku,
      },
    });
  },
  getLmrMappingsBySku: sku => {
    return createAxiosAction({
      url: `/api/v1/products/${sku}/lmrMapping`,
      startAction: GET_LMR_MAPPINGS_BY_SKU,
      method: 'GET',
    });
  },
};
