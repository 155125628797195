import { useAuth0 } from '@auth0/auth0-react';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useBaseContext } from '../../components/Layout/BaseLayout/BaseContext';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import {
  setAiInstrumentationKey,
  setMetSessionExpiry,
  setShowWhatsNew,
  setUserHasOrders,
  setUserMetadata,
  updateMetSession,
} from '../../helpers/sessionStorage';
import {
  getMetToken,
  parseUserIdFromClaims,
  parseUserMetadataFromClaims,
  upsertUser,
  upsertUserEmail,
} from '../../helpers/user';
import { serviceOrdersActions } from '../../store';
import {PageLoader} from "../../components";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isAuthenticated, isLoading, user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { authenticationError, setAuthenticationError } = useBaseContext();
  const appInsights = useAppInsightsContext();

  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useQueryParams({
    r: withDefault(StringParam, '/'),
  });

  const [session, setSession] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [metSession, setMetSession] = useState(null);

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      initUserSession().catch(() => {
        setAuthenticationError(true);
      });
    }
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    if (session) {
      initUserState().catch(() => {
        setAuthenticationError(true);
      });
    }
  }, [session]);

  useEffect(() => {
    if (authenticationError) {
      history.replace(query.r);
    }
  }, [authenticationError]);

  const initUserSession = () => {
    appInsights.trackEvent({
      name: 'Login',
      properties: {
        user,
      },
    });

    // Check if user is missing legacy user ID in Auth0
    let userId = parseUserIdFromClaims(user);
    if (userId === '') {
      return Promise.reject();
    }

    let userMetadata = parseUserMetadataFromClaims(user);
    setUserMetadata(userMetadata);

    return getAccessTokenSilently().then(token => {
      setSession({ token });
    });
  };

  const initMetSession = () => {
    return dispatch(getMetToken()).then(response => {
      const { token, expires } = response.data.data;

      updateMetSession({ token });
      setMetSessionExpiry(Math.floor(new Date(expires) / 1000));
      setMetSession({ token });
    });
  };

  const initUserState = () => {
    return dispatch(upsertUser()).then(user => {
      initMetSession().then(() => {
        const { loginCount } = user.data.data;
        setShowWhatsNew(loginCount <= 1);

        dispatch(upsertUserEmail(true)).then(() => {
          dispatch(serviceOrdersActions.searchServiceOrders(1, '')).then(response => {
            setUserHasOrders(response.data.count > 0);
            // Set Ai Instrumentation Key on successful authentication
            setAiKey();
            history.replace(query.r); // Redirect user after successful authentication
          });
        });
      });
    });
  };

  const getConfiguration = () => {
    return fetch('/api/v1/configuration').then(response => {
      return response.json();
    });
  };

  const setAiKey = () => {
    getConfiguration().then(config => {
      const { appInsights } = config.data;
      if (appInsights.instrumentationKey) {
        setAiInstrumentationKey(appInsights.instrumentationKey);
      }
    });
  };

  return (
    <Row style={{ height: '100%' }}>
      <Col>
        <PageLoader />
      </Col>
    </Row>
  );
};

export default Login;
