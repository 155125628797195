import React from 'react';
import {Button, Col, Collapse, DatePicker, Form, PageHeader, Row, Space, Typography} from 'antd';
import {FormattedMessage, useIntl} from 'react-intl';
import {FileExcelFilled} from '@ant-design/icons';
import {CenteredPanel} from '../styled';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBoxesStacked,
  faMapLocation,
  faScrewdriverWrench,
  faToolbox,
  faWrench
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {reportActions} from '../../../../store';
import {downloadBlob} from '../../../../helpers/downloadBlob';
import {toast} from 'react-toastify';

const { Text } = Typography;
const { RangePicker } = DatePicker;

const ReportsTab = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const dateFormat = 'MM/DD/YYYY';

  const isSkuHubVolumeReportLoading = useSelector(state => state.reports.getSkuHubVolumeReport.isLoading);
  const isSkuStateVolumeReportLoading = useSelector(state => state.reports.getSkuStateVolumeReport.isLoading);
  const isSkuHubMappingsReportLoading = useSelector(state => state.reports.getSkuHubMappingsReport.isLoading);
  const isWillCallServiceOrdersLoading = useSelector(state => state.reports.getWillCallServiceOrdersReport.isLoading);
  const isDeniedWarrantyServiceOrdersLoading = useSelector(state => state.reports.getDeniedWarrantyServiceOrdersReport.isLoading);

  const extractDatesFromRange = dateRange => {
    return {
      startDate: dateRange[0].format(dateFormat),
      endDate: dateRange[1].format(dateFormat),
    };
  };

  const downloadSkuHubVolumeReport = dateRange => {
    const { startDate, endDate } = extractDatesFromRange(dateRange);

    dispatch(reportActions.getSkuHubVolumeReport(startDate, endDate))
      .then(d => {
        downloadBlob(d, d.data, true);
      })
      .catch(err => {
        toast.error(intl.formatMessage({ id: 'dashboard.exportErrorMessage' }), {
          autoClose: false,
        });
      });
  };

  const downloadSkuStateVolumeReport = dateRange => {
    const { startDate, endDate } = extractDatesFromRange(dateRange);

    dispatch(reportActions.getSkuStateVolumeReport(startDate, endDate))
      .then(d => {
        downloadBlob(d, d.data, true);
      })
      .catch(err => {
        toast.error(intl.formatMessage({ id: 'dashboard.exportErrorMessage ' }), {
          autoClose: false,
        });
      });
  };

  const downloadSkuHubMappingReport = () => {
    dispatch(reportActions.getSkuHubMappingsReport())
      .then(d => {
        downloadBlob(d, d.data, true);
      })
      .catch(err => {
        toast.error(intl.formatMessage({ id: 'dashboard.exportErrorMessage' }), {
          autoClose: false,
        });
      });
  };

  const downloadWillCallServiceOrdersReport = (dateRange) => {
    const { startDate, endDate } = extractDatesFromRange(dateRange);
    
    dispatch(reportActions.getWillCallServiceOrdersReport(startDate, endDate))
      .then(d => {
        downloadBlob(d, d.data, true);
      })
      .catch(err => {
        toast.error(intl.formatMessage({ id: 'dashboard.exportErrorMessage' }), {
          autoClose: false,
        });
      });
  };

  const downloadDeniedWarrantyServiceOrdersReport = (dateRange) => {
    const { startDate, endDate } = extractDatesFromRange(dateRange);

    dispatch(reportActions.getDeniedWarrantyServiceOrdersReport(startDate, endDate))
      .then(d => {
        downloadBlob(d, d.data, true);
      })
      .catch(err => {
        toast.error(intl.formatMessage({ id: 'dashboard.exportErrorMessage' }), {
          autoClose: false,
        });
      });
  };
  
  const deniedWarrantyReport = (
    <CenteredPanel
      key='deniedWarrantyServiceOrdersReport'
      header={
        <Row align='middle' gutter={18}>
          <Col>
            <FontAwesomeIcon icon={faWrench} size='xl' />
          </Col>
          <Col>
            <Space direction='vertical' size={3}>
              <Text>
                <FormattedMessage id='admin.reports.deniedWarrantyServiceOrdersTitle' />
              </Text>
              <Text type='secondary' style={{ fontSize: '.95em' }}>
                <FormattedMessage id='admin.reports.deniedWarrantyServiceOrdersSubtitle' />
              </Text>
            </Space>
          </Col>
        </Row>
      }
    >
      <Form
        layout='vertical'
        initialValues={{
          dateRange: [moment().subtract(30, 'days'), moment()],
        }}
        onFinish={values => downloadDeniedWarrantyServiceOrdersReport(values.dateRange)}
      >
        <Space direction='horizontal' align='end' size='large'>
          <Form.Item label={intl.formatMessage({ id: 'admin.reports.serviceOrderCreatedDateRangeTitle' })} name='dateRange'>
            <RangePicker format={dateFormat} size='large' />
          </Form.Item>
          <Form.Item>
            <Button
              data-test-id='adminReportingTabDeniedWarrantyServiceOrders'
              type='primary'
              icon={<FileExcelFilled />}
              htmlType='submit'
              loading={isDeniedWarrantyServiceOrdersLoading}
            >
                  <span>
                    <FormattedMessage id='general.download' />
                  </span>
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </CenteredPanel>
  );

  return (
    <PageHeader title={intl.formatMessage({ id: 'admin.reports.title' })}>
      <Collapse>
        <CenteredPanel
          key='skuHubVolumes'
          header={
            <Row align='middle' gutter={18}>
              <Col>
                <FontAwesomeIcon icon={faBoxesStacked} size='xl' />
              </Col>
              <Col>
                <Space direction='vertical' size={3}>
                  <Text>
                    <FormattedMessage id='admin.reports.skuHubVolumesTitle' />
                  </Text>
                  <Text type='secondary' style={{ fontSize: '.95em' }}>
                    <FormattedMessage id='admin.reports.skuHubVolumesSubtitle' />
                  </Text>
                </Space>
              </Col>
            </Row>
          }
        >
          <Form
            layout='vertical'
            initialValues={{
              dateRange: [moment().subtract(30, 'days'), moment()],
            }}
            onFinish={values => downloadSkuHubVolumeReport(values.dateRange)}
          >
            <Space direction='horizontal' align='end' size='large'>
              <Form.Item label={intl.formatMessage({ id: 'admin.reports.dateRange' })} name='dateRange'>
                <RangePicker format={dateFormat} size='large' />
              </Form.Item>
              <Form.Item>
                <Button
                  data-test-id='adminReportingTabSKUVolumeReportButton' 
                  type='primary'
                  icon={<FileExcelFilled />}
                  htmlType='submit'
                  loading={isSkuHubVolumeReportLoading}
                >
                  <span>
                    <FormattedMessage id='general.download' />
                  </span>
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </CenteredPanel>
        <CenteredPanel
          key='skuState'
          header={
            <Row align='middle' gutter={18}>
              <Col>
                <FontAwesomeIcon icon={faMapLocation} size='xl' />
              </Col>
              <Col>
                <Space direction='vertical' size={3}>
                  <Text>
                    <FormattedMessage id='admin.reports.skuStateFrequencyTitle' />
                  </Text>
                  <Text type='secondary' style={{ fontSize: '.95em' }}>
                    <FormattedMessage id='admin.reports.skuStateFrequencySubtitle' />
                  </Text>
                </Space>
              </Col>
            </Row>
          }
        >
          <Form
            layout='vertical'
            initialValues={{
              dateRange: [moment().subtract(30, 'days'), moment()],
            }}
            onFinish={values => downloadSkuStateVolumeReport(values.dateRange)}
          >
            <Space direction='horizontal' align='end' size='large'>
              <Form.Item label={intl.formatMessage({ id: 'admin.reports.dateRange' })} name='dateRange'>
                <RangePicker format={dateFormat} size='large' />
              </Form.Item>
              <Form.Item>
                <Button
                  data-test-id='adminReportingTabStateVolumeReportingButton' 
                  type='primary'
                  icon={<FileExcelFilled />}
                  htmlType='submit'
                  loading={isSkuStateVolumeReportLoading}
                >
                  <span>
                    <FormattedMessage id='general.download' />
                  </span>
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </CenteredPanel>
        <CenteredPanel
          key='skuHubMappings'
          header={
            <Row align='middle' gutter={18}>
              <Col>
                <FontAwesomeIcon icon={faScrewdriverWrench} size='xl' />
              </Col>
              <Col>
                <Space direction='vertical' size={3}>
                  <Text>
                    <FormattedMessage id='admin.reports.skuHubMappingsTitle' />
                  </Text>
                  <Text type='secondary' style={{ fontSize: '.95em' }}>
                    <FormattedMessage id='admin.reports.skuHubMappingsSubtitle' />
                  </Text>
                </Space>
              </Col>
            </Row>
          }
        >
          <Form layout='vertical'>
            <Space direction='horizontal' align='end' size='large'>
              <Form.Item>
                <Button
                  data-test-id='adminReportingTabSKUHubReportsButton' 
                  type='primary'
                  icon={<FileExcelFilled />}
                  htmlType='submit'
                  loading={isSkuHubMappingsReportLoading}
                  onClick={() => {
                    downloadSkuHubMappingReport();
                  }}
                >
                  <span>
                    <FormattedMessage id='general.download' />
                  </span>
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </CenteredPanel>
        <CenteredPanel
          key='willCallServiceOrdersReport'
          header={
            <Row align='middle' gutter={18}>
              <Col>
                <FontAwesomeIcon icon={faToolbox} size='xl' />
              </Col>
              <Col>
                <Space direction='vertical' size={3}>
                  <Text>
                    <FormattedMessage id='admin.reports.willCallServiceOrdersTitle' />
                  </Text>
                  <Text type='secondary' style={{ fontSize: '.95em' }}>
                    <FormattedMessage id='admin.reports.willCallServiceOrdersSubtitle' />
                  </Text>
                </Space>
              </Col>
            </Row>
          }
        >
          <Form
            layout='vertical'
            initialValues={{
              dateRange: [moment().subtract(30, 'days'), moment()],
            }}
            onFinish={values => downloadWillCallServiceOrdersReport(values.dateRange)}
          >
            <Space direction='horizontal' align='end' size='large'>
              <Form.Item label={intl.formatMessage({ id: 'admin.reports.serviceOrderCreatedDateRangeTitle' })} name='dateRange'>
                <RangePicker format={dateFormat} size='large' />
              </Form.Item>
              <Form.Item>
                <Button
                  data-test-id='adminReportingTabWillCallServiceOrders'
                  type='primary'
                  icon={<FileExcelFilled />}
                  htmlType='submit'
                  loading={isWillCallServiceOrdersLoading}
                >
                  <span>
                    <FormattedMessage id='general.download' />
                  </span>
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </CenteredPanel>
        {deniedWarrantyReport}
      </Collapse>
    </PageHeader>
  );
};

export default ReportsTab;
