import { useSelector } from 'react-redux';
import { recyclingActions } from '../store';

const useRecycling = () => {
  const serviceOrderRecycling = useSelector(state => state.recycling.getServiceOrderRecycling.serviceOrderRecycling);
  const loadingServiceOrderRecycling = useSelector(state => state.recycling.getServiceOrderRecycling.isLoading);

  const serviceOrderRecyclingList = useSelector(
    state => state.recycling.getServiceOrderRecyclingGroups.serviceOrderRecyclingGroups
  );
  const loadingServiceOrderRecyclingGroups = useSelector(
    state => state.recycling.getServiceOrderRecyclingGroups.isLoading
  );

  const getServiceOrderRecycling = recyclingId => {
    return recyclingActions.getServiceOrderRecycling(recyclingId);
  };

  const getServiceOrderRecyclingSearch = (searchParams, startDate, endDate, selectedColumn, status) => {
    return recyclingActions.getServiceOrderRecyclingSearch(searchParams, startDate, endDate, selectedColumn, status);
  };

  return {
    serviceOrderRecycling,
    loadingServiceOrderRecycling,
    serviceOrderRecyclingList,
    getServiceOrderRecycling,
    loadingServiceOrderRecyclingGroups,
    getServiceOrderRecyclingSearch,
  };
};

export default useRecycling;
