import React, { createContext, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const ServiceCenterMappingContext = createContext({
  addMode: '',
  deleteMode: '',
  mappingMode: '',
  setMappingMode: () => {},
  currentMapping: null,
  setCurrentMapping: () => {},
  serviceCenters: [],
  loadingServiceCenters: false,
  serviceSkuMappings: [],
  addingServiceSkuMapping: false,
  deletingServiceSkuMapping: false,
});

const useServiceCenterMappingContext = () => useContext(ServiceCenterMappingContext);

const ServiceCenterMappingProvider = ({ children }) => {
  const intl = useIntl();

  const addMode = intl.formatMessage({ id: 'admin.product.addMapping' });
  const deleteMode = intl.formatMessage({ id: 'admin.product.deleteMapping' });

  const [mappingMode, setMappingMode] = useState('');
  const [currentMapping, setCurrentMapping] = useState(null);

  const serviceCenters = useSelector(state => state.serviceCenters.getServiceCenters.serviceCenters);
  const loadingServiceCenters = useSelector(state => state.serviceCenters.getServiceCenters.isLoading);
  const serviceSkuMappings = useSelector(
    state =>
      state.products.getProduct &&
      state.products.getProduct.product &&
      state.products.getProduct.product.serviceSkuMappings
  );
  const addingServiceSkuMapping = useSelector(state => state.products.upsertServiceSkuMapping.isLoading);
  const deletingServiceSkuMapping = useSelector(state => state.products.deleteServiceSkuMapping.isLoading);

  return (
    <ServiceCenterMappingContext.Provider
      value={{
        addMode,
        deleteMode,
        mappingMode,
        setMappingMode,
        currentMapping,
        setCurrentMapping,
        serviceCenters,
        loadingServiceCenters,
        serviceSkuMappings,
        addingServiceSkuMapping,
        deletingServiceSkuMapping,
      }}
    >
      {children}
    </ServiceCenterMappingContext.Provider>
  );
};

export { ServiceCenterMappingContext, useServiceCenterMappingContext, ServiceCenterMappingProvider };
