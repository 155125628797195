import { Button, Col, Modal, Row } from 'antd';
import { TextInput } from '@met/react-components';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRepairItemsContext } from '../../../RepairItemsContext';
import { Icon } from '@met/react-components';
import { useToggle } from '../../../../../../hooks';

const TagReference = () => {
  const intl = useIntl();
  const { product, isServiceEligible, tagNumber, tagNumberInvalid, setTagNumber, setUnsavedChanges } =
    useRepairItemsContext();

  const [showReferenceModal, toggleReferenceModal] = useToggle(false);

  const handleTagNumberChange = (updatedTagNumber) => {
    if (tagNumber !== updatedTagNumber) {
      setUnsavedChanges(true);
      setTagNumber(updatedTagNumber);
    }
  };

  const tagNumberTitle = (
    <Row>
      <Col>
        <h3>
          <FormattedMessage id='productDetail.toolInfo.tagNumberLabel' />
        </h3>
      </Col>
      <div>
        <Icon
          data-test-id='tagReferenceQuestionCircle'
          className='ml-2'
          type='question-circle'
          xs
          onClick={toggleReferenceModal}
          style={{ marginTop: '5px' }}
        />
      </div>
    </Row>
  );

  const tagNumberRef = isServiceEligible && product && (
    <Fragment>
      {tagNumberTitle}
      <Row style={{ marginTop: '1rem' }}>
        <Col xs={12}>
          <TextInput
            placeholder='1234567890'
            value={tagNumber}
            onChange={(e) => handleTagNumberChange(e.target.value)}
            invalid={tagNumberInvalid}
            data-test-id='tagNumberInput'
            data-hj-allow
          />
        </Col>
      </Row>
    </Fragment>
  );

  const referenceModalMarkup = (
    <Modal
      width={800}
      centered
      onCancel={toggleReferenceModal}
      open={showReferenceModal}
      title={intl.formatMessage({
        id: 'productDetail.toolInfo.tagNumberLabel',
      })}
      footer={[
        <Button
          data-test-id='closeTagReferenceModalButton'
          key='close'
          onClick={toggleReferenceModal}
          style={{ backgroundColor: 'rgb(219,1,28)', color: 'white' }}
        >
          <FormattedMessage id='general.close' />
        </Button>,
      ]}
    >
      <FormattedMessage id='productDetail.toolInfo.tagNumberBlurb' />
    </Modal>
  );

  return (
    <Fragment>
      {tagNumberRef}
      {referenceModalMarkup}
    </Fragment>
  );
};

export default TagReference;
