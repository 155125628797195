import React, { forwardRef } from "react";
import InputMask from "react-input-mask";
import { Input } from "antd";
import PropTypes from 'prop-types';

export const MaskedInput = forwardRef((props, ref) => {
  return (
    <InputMask {...props}>
      {inputProps => (
        <Input
          {...inputProps}
          ref={ref}
          disabled={props.disabled ? props.disabled : null}
        />
      )}
    </InputMask>
  );
});
  
MaskedInput.propTypes = {
  mask: PropTypes.string,
};