import React, {createRef} from 'react';
import {Modal} from 'antd';
import {useIntl} from 'react-intl';
import SquarePaymentForm from './SquarePaymentForm';
import {useSelector} from 'react-redux';

const AddCreditCardModal = ({ isVisible, onCreditCardAdded, toggleIsVisible }) => {
  const intl = useIntl();
  const ref = createRef();

  const isLoading = useSelector(state => state.square.addCreditCard.isLoading);

  return (
    <Modal
      open={isVisible}
      title={intl.formatMessage({ id: 'square.addNewCard' })}
      cancelText={intl.formatMessage({ id: 'general.cancel' })}
      okText={intl.formatMessage({ id: 'square.addCard' })}
      onOk={() => ref?.current?.handleFormSubmit()}
      onCancel={() => toggleIsVisible()}
      okButtonProps={{
        loading: isLoading,
      }}
      destroyOnClose
    >
      <SquarePaymentForm ref={ref} onCardNonceSuccess={onCreditCardAdded} showSaveCard={false} showDisclaimer={true} />
    </Modal>
  );
};

export default AddCreditCardModal;
