import { GET_ACTIVE_FEATURES } from '../types';
import { error, success } from '../utils';
import {
  getRedirectToSupportCenterEnabled,
  setRedirectToSupportCenterEnabled,
  getUserId,
} from '../../helpers/sessionStorage';

export let initialState = {
  getActiveFeatures: {
    isLoading: false,
    isError: false,
    activeFeatures: [],
  },
};

export function mergeWithRedirectToSc(activeFeatures) {
  const userId = getUserId();
  if(!userId) return activeFeatures;

  const redirectToSupportCenterEnabled = getRedirectToSupportCenterEnabled(userId);
  const activeFeaturesContainsRedirectToSupportCenter = activeFeatures.includes('RedirectToSupportCenter');

  // If user already has the feature enabled, just return active features
  // ensuring that RedirectToSupportCenter is in the list
  if (redirectToSupportCenterEnabled) {
    return activeFeaturesContainsRedirectToSupportCenter ? activeFeatures
      : [...activeFeatures, 'RedirectToSupportCenter'];
  }

  // If user doesn't have the feature enabled, and it is not in the active features list,
  // just return active features
  if(!activeFeaturesContainsRedirectToSupportCenter) {
    return activeFeatures;
  }

  // If we reach this line, it means the user has just been selected to have the feature enabled
  setRedirectToSupportCenterEnabled(true, userId);

  // And we can just return active features as it already contains RedirectToSupportCenter
  return activeFeatures;
}

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_ACTIVE_FEATURES:
      return {
        ...state,
        getActiveFeatures: {
          isLoading: true,
          isError: false,
          activeFeatures: [],
        },
      };
    case success(GET_ACTIVE_FEATURES):
      const mergedActiveFeatures = mergeWithRedirectToSc(action.payload.data);
      return {
        ...state,
        getActiveFeatures: {
          isLoading: false,
          isError: false,
          activeFeatures: mergedActiveFeatures,
        },
      };
    case error(GET_ACTIVE_FEATURES):
      return {
        ...state,
        getActiveFeatures: {
          isLoading: false,
          isError: true,
          activeFeatures: [],
        },
      };
    default:
      return state;
  }
};

export default reducer;
