import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocale } from '../../hooks';
import { StyledButton } from './styled.js';

const { getCurrentLocale } = useLocale();

const IneligibleToolModal = (props) => {
  const handleFindServiceCenter = () => {
    const currentLocale = getCurrentLocale();

    var url = '';

    switch (currentLocale) {
      case 'CA':
        url = 'https://www.milwaukeetool.ca/Support/Find-A-Service-Centre';

        break;

      case 'US':
      default:
        url = 'https://www.milwaukeetool.com/Support/Find-A-Service-Center';

        break;
    }
    const newWindow = window.open(url, '_blank', 'noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Modal
      data-test-id='ineligibleToolModal'
      open={props.hidden}
      centered
      closable={true}
      onCancel={() => props.toggle()}
      title={<FormattedMessage id='ineligibleTools.ineligibleToolsTitle' />}
      footer={[
        <StyledButton data-test-id='findAServiceCenterButton' primary onClick={() => handleFindServiceCenter()}>
          <FormattedMessage id='ineligibleTools.ineligibleToolsFooter' />
        </StyledButton>,
      ]}
    >
      <ul>
        <li>
          <FormattedMessage id='ineligibleTools.ineligibleTools.tool01' />
        </li>
        <li>
          <FormattedMessage id='ineligibleTools.ineligibleTools.tool03' />
        </li>
        <li>
          <FormattedMessage id='ineligibleTools.ineligibleTools.tool04' />
        </li>
        <li>
          <FormattedMessage id='ineligibleTools.ineligibleTools.tool05' />
        </li>
        <li>
          <FormattedMessage id='ineligibleTools.ineligibleTools.tool06' />
        </li>
        <li>
          <FormattedMessage id='ineligibleTools.ineligibleTools.tool07' />
        </li>
        <li>
          <FormattedMessage id='ineligibleTools.ineligibleTools.tool08' />
        </li>
        <li>
          <FormattedMessage id='ineligibleTools.ineligibleTools.tool09' />
        </li>
        <li>
          <FormattedMessage id='ineligibleTools.ineligibleTools.tool10' />
        </li>
        <li>
          <FormattedMessage id='ineligibleTools.ineligibleTools.tool11' />
        </li>
        <li>
          <FormattedMessage id='ineligibleTools.ineligibleTools.tool12' />
        </li>
        <li>
          <FormattedMessage id='ineligibleTools.ineligibleTools.tool13' />
        </li>
        <li>
          <FormattedMessage id='ineligibleTools.ineligibleTools.tool14' />
        </li>
        <li>
          <FormattedMessage id='ineligibleTools.ineligibleTools.tool15' />
        </li>
        <li>
          <FormattedMessage id='ineligibleTools.ineligibleTools.tool16' />
        </li>
        <li>
          <FormattedMessage id='ineligibleTools.ineligibleTools.tool17' />
        </li>
        <li>
          <FormattedMessage id='ineligibleTools.ineligibleTools.tool18' />
        </li>
        <li>
          <FormattedMessage id='ineligibleTools.ineligibleTools.tool19' />
        </li>
      </ul>
    </Modal>
  );
};

IneligibleToolModal.propTypes = {
  hidden: PropTypes.bool,
  toggle: PropTypes.func,
};

export default IneligibleToolModal;
