import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { AddressModal } from '../../components';
import { PageLoaderJr } from '../../components';
import { useUser, useAddresses, useToggle } from '../../hooks';
import { countriesActions, statesActions, usersActions } from '../../store';
import {
  ActionLinks,
  AddressCard,
  DefaultAddressLabel,
  DetailsContainer,
  FullHeightRow,
  NewAddressCard,
} from './styled';
import { StyledFontAwesomeIcon } from '../../components/SquarePayments/styled'
import { Col, Row, Space, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckFast, faPlus } from '@fortawesome/free-solid-svg-icons';

const { Text } = Typography;

const Addresses = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { loadingUser } = useUser();
  const { defaultUserAddress, userAddresses } = useAddresses();

  const [currentAddress, setCurrentAddress] = useState({});
  const [showDeleteAddressView, setShowDeleteAddressView] = useState(false);
  const [showDefaultAddressView, setShowDefaultAddressView] = useState(false);
  const [showAddressModal, toggleAddressModal] = useToggle(false);

  useEffect(() => {
    dispatch(countriesActions.getCountries()).catch(() => {
      toast.error(intl.formatMessage({ id: 'address.loadingCountriesErrorMessage' }), { autoClose: false });
    });
    dispatch(statesActions.getStates()).catch(() => {
      toast.error(intl.formatMessage({ id: 'address.loadingStatesErrorMessage' }), { autoClose: false });
    });
    getUserAddresses();
  }, [dispatch]);

  const getUserAddresses = () => {
    dispatch(usersActions.getUserAddressesWithDefault()).catch(() => {
      toast.error(intl.formatMessage({ id: 'profile.loadingUserErrorMessage' }), { autoClose: false });
    });
  };

  const handleNewAddressClick = () => {
    handleClearAddressValidation();
    setCurrentAddress({});
    setShowDeleteAddressView(false);
    setShowDefaultAddressView(false);
    toggleAddressModal();
  };

  const handleEditAddressClick = address => {
    handleClearAddressValidation();
    setCurrentAddress(address);
    setShowDeleteAddressView(false);
    setShowDefaultAddressView(false);
    toggleAddressModal();
  };

  const handleDeleteAddressClick = address => {
    setCurrentAddress(address);
    setShowDeleteAddressView(true);
    setShowDefaultAddressView(false);
    toggleAddressModal();
  };

  const handleSetDefaultClick = address => {
    setCurrentAddress(address);
    setShowDeleteAddressView(false);
    setShowDefaultAddressView(true);
    toggleAddressModal();
  };

  const handleCancelClick = () => {
    handleClearAddressValidation();
    toggleAddressModal();
  };

  const handleClearAddressValidation = () => {
    dispatch(usersActions.clearAddressValidation());
  };

  const handleConfirmDeleteClick = (id, defaultShippingAddress) => {
    dispatch(usersActions.deleteUserAddress(id, defaultShippingAddress))
      .then(() => {
        getUserAddresses();
        toggleAddressModal();
        toast.success(intl.formatMessage({ id: 'address.addressDeleted' }), { autoClose: 5000 });
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'address.deleteError' }), { autoClose: 5000 });
      });
  };

  const handleConfirmDefaultClick = id => {
    dispatch(usersActions.updateUserDefaultAddress(id))
      .then(() => {
        getUserAddresses();
        toggleAddressModal();
        toast.success(intl.formatMessage({ id: 'address.addressUpdated' }), { autoClose: 5000 });
      })
      .catch(error => {
        toast.error(intl.formatMessage({ id: 'address.saveChangesError' }), { autoClose: 5000 });
      });
  };

  const handleConfirmEditClick = (
    id,
    defaultShippingAddress,
    addressLine1,
    addressLine2,
    city,
    state,
    countryCode,
    postalCode,
    name,
    isResidential,
    latitude,
    longitude
  ) => {
    dispatch(
      usersActions.upsertUserAddress(
        id,
        defaultShippingAddress,
        addressLine1,
        addressLine2,
        city,
        state,
        countryCode,
        postalCode,
        name,
        isResidential,
        latitude,
        longitude
      )
    )
      .then(() => {
        getUserAddresses();
        toggleAddressModal();
        toast.success(intl.formatMessage({ id: 'address.addressUpdated' }), { autoClose: 5000 });
      })
      .catch(error => {
        toast.error(intl.formatMessage({ id: 'address.saveChangesError' }), { autoClose: 5000 });
      });
  };

  const newAddressMarkup = (
    <Col xs={24} md={8}>
      <NewAddressCard data-test-id='newAddressCardOnClick' onClick={() => handleNewAddressClick()}>
        <FullHeightRow>
          <Col>
            <Space direction={'vertical'} size={[0, 0]}>
              <StyledFontAwesomeIcon icon={faPlus} size='lg' />
              <Text type={'secondary'} strong style={{ color: 'rgb(204, 204, 204)' }}>
                <FormattedMessage id='profile.newAddressLabel' />
              </Text>
            </Space>
          </Col>
        </FullHeightRow>
      </NewAddressCard>
    </Col>
  );

  const defaultAddressMarkup = defaultUserAddress && defaultUserAddress.id && (
    <Col xs={24} md={8}>
      <AddressCard
        title={
          <Space direction={'horizontal'} size={[5, 0]}>
            <FontAwesomeIcon icon={faTruckFast} size='xs' style={{ color: '#db011c', height: '.75rem' }} />
            <DefaultAddressLabel strong>
              <FormattedMessage id='profile.defaultAddressLabel' />
            </DefaultAddressLabel>
          </Space>
        }
      >
        <Space direction={'vertical'} size={[1, 1]}>
          <Text strong>{defaultUserAddress.name}</Text>
          <Text>{defaultUserAddress.addressLine1}</Text>
          <Text>{defaultUserAddress.addressLine2}</Text>
          <Text>
            {defaultUserAddress.city}, {defaultUserAddress.state} {defaultUserAddress.postalCode}
          </Text>
          <Text>{defaultUserAddress.countryCode}</Text>
          <ActionLinks direction={'horizontal'}>
            <Text data-test-id='editDefaultUserAddressText' onClick={() => handleEditAddressClick(defaultUserAddress)}>
              <FormattedMessage id='general.edit' />
            </Text>
            <Text data-test-id='deleteDefaultUserAddressText' onClick={() => handleDeleteAddressClick(defaultUserAddress)}>
              <FormattedMessage id='general.delete' />
            </Text>
          </ActionLinks>
        </Space>
      </AddressCard>
    </Col>
  );

  const addressesMarkup =
    userAddresses &&
    userAddresses.map((userAddress, index) => {
      let setDefaultMarkup = !userAddress.defaultShippingAddress && (
        <Text data-test-id='setDefaultAddressText' onClick={() => handleSetDefaultClick(userAddresses[index])}>
          <FormattedMessage id='profile.setDefaultAddress' />
        </Text>
      );

      return (
        <Col xs={24} md={8} key={`user-address-${index}`}>
          <AddressCard>
            <Space direction={'vertical'} size={[1, 1]}>
              <Text strong>{userAddress.name}</Text>
              <Text>{userAddress.addressLine1}</Text>
              <Text>{userAddress.addressLine2}</Text>
              <Text>
                {userAddress.city}, {userAddress.state} {userAddress.postalCode}
              </Text>
              <Text>{userAddress.countryCode}</Text>
              <ActionLinks direction={'horizontal'}>
                <Text data-test-id='preferencesEditAddressText' onClick={() => handleEditAddressClick(userAddress)}>
                  <FormattedMessage id='general.edit' />
                </Text>
                <Text data-test-id='preferencesDeleteAddressText' onClick={() => handleDeleteAddressClick(userAddress)}>
                  <FormattedMessage id='general.delete' />
                </Text>
                {setDefaultMarkup}
              </ActionLinks>
            </Space>
          </AddressCard>
        </Col>
      );
    });

  if (loadingUser) {
    return (
      <PageLoaderJr />
    );
  }

  return (
    <Fragment>
      <DetailsContainer>
        <Row gutter={[16, 16]}>
          {newAddressMarkup}
          {defaultAddressMarkup}
          {addressesMarkup}
        </Row>
      </DetailsContainer>
      <AddressModal
        hidden={!showAddressModal}
        toggle={toggleAddressModal}
        address={currentAddress}
        showDeleteAddressView={showDeleteAddressView}
        showDefaultAddressView={showDefaultAddressView}
        onCancelClick={handleCancelClick}
        onDeleteClick={handleConfirmDeleteClick}
        onSetDefaultClick={handleConfirmDefaultClick}
        onSaveChangesClick={handleConfirmEditClick}
      />
    </Fragment>
  );
};

export default Addresses;
