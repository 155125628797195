import { Button, Card } from 'antd';
import styled from 'styled-components';

export const StyledContainer = styled(Card)`
  padding: '1.25rem',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  box-shadow: 'rgb(0 0 0 / 3%) 0px 0px 3px 0px',
`;

export const OkButton = styled(Button)`
  border: none;
  box-shadow: none;
  text-shadow: none;
  background: #db011c;
`;