import usersActions from '../store/Users/actions';
import { stripNonNumeric } from './formatter';

export const upsertUser = () => {
  return usersActions.upsertUser();
};

export const upsertUserTermsAndConditions = accepted => {
  return usersActions.upsertUserTermsAndConditions(accepted);
};

export const upsertUserEmail = isVerified => {
  return usersActions.upsertUserEmail(isVerified);
};

export const getUserEmails = () => {
  return usersActions.getUserEmails();
};

export const getCurrentUser = () => {
  return usersActions.getCurrentUser();
};

export const parseUserMetadataFromClaims = data => {
  const oidNamespace = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/';

  let auth0Profile = {};

  if (data) {
    auth0Profile = {
      firstName: data[oidNamespace + 'givenname'],
      lastName: data[oidNamespace + 'surname'],
      phoneNumber: stripNonNumeric(data[oidNamespace + 'homephone']),
    };
  }

  return auth0Profile;
};

export const parseUserIdFromClaims = data => {
  const mtNamespace = 'https://milwaukeetool.com:milwaukeetool:';

  let userId = '';

  if (data) {
    userId = data[mtNamespace + 'user_id'];
  }

  return userId;
};

export const getMetToken = () => {
  return usersActions.getMetUserToken();
};
