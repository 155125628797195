import { 
  GET_SKU_HUB_VOLUME_REPORT,
  GET_SKU_STATE_VOLUME_REPORT,
  GET_SKU_HUB_MAPPINGS_REPORT,
  GET_WILL_CALL_SERVICE_ORDERS_REPORT,
  GET_DENIED_WARRANTY_SERVICE_ORDERS_REPORT
} from '../types';
import { error, success } from '../utils';

export let initialState = {
  getSkuHubVolumeReport: {
    isLoading: false,
    isError: false,
  },
  getSkuStateVolumeReport: {
    isLoading: false,
    isError: false,
  },
  getSkuHubMappingsReport: {
    isLoading: false,
    isError: false,
  },
  getWillCallServiceOrdersReport: {
    isLoading: false,
    isError: false,
  },
  getDeniedWarrantyServiceOrdersReport: {
    isLoading: false,
    isError: false,
  }
};

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_SKU_HUB_VOLUME_REPORT:
      return {
        ...state,
        getSkuHubVolumeReport: {
          isLoading: true,
          isError: false,
        },
      };
    case success(GET_SKU_HUB_VOLUME_REPORT):
      return {
        ...state,
        getSkuHubVolumeReport: {
          isLoading: false,
          isError: false,
        },
      };
    case error(GET_SKU_HUB_VOLUME_REPORT):
      return {
        ...state,
        getSkuHubVolumeReport: {
          isLoading: false,
          isError: true,
        },
      };
    case GET_SKU_STATE_VOLUME_REPORT:
      return {
        ...state,
        getSkuStateVolumeReport: {
          isLoading: true,
          isError: false,
        },
      };
    case success(GET_SKU_STATE_VOLUME_REPORT):
      return {
        ...state,
        getSkuStateVolumeReport: {
          isLoading: false,
          isError: false,
        },
      };
    case error(GET_SKU_STATE_VOLUME_REPORT):
      return {
        ...state,
        getSkuStateVolumeReport: {
          isLoading: false,
          isError: true,
        },
      };
    case GET_SKU_HUB_MAPPINGS_REPORT:
      return {
        ...state,
        getSkuHubMappingsReport: {
          isLoading: true,
          isError: false,
        },
      };
    case success(GET_SKU_HUB_MAPPINGS_REPORT):
      return {
        ...state,
        getSkuHubMappingsReport: {
          isLoading: false,
          isError: false,
        },
      };
    case error(GET_SKU_HUB_MAPPINGS_REPORT):
      return {
        ...state,
        getSkuHubMappingsReport: {
          isLoading: false,
          isError: true,
        },
      };
    case GET_WILL_CALL_SERVICE_ORDERS_REPORT:
      return {
        ...state,
        getWillCallServiceOrdersReport: {
          isLoading: true,
          isError: false,
        },
      };
    case success(GET_WILL_CALL_SERVICE_ORDERS_REPORT):
      return {
        ...state,
        getWillCallServiceOrdersReport: {
          isLoading: false,
          isError: false,
        },
      };
    case error(GET_WILL_CALL_SERVICE_ORDERS_REPORT):
      return {
        ...state,
        getWillCallServiceOrdersReport: {
          isLoading: false,
          isError: true,
        },
      };
    case GET_DENIED_WARRANTY_SERVICE_ORDERS_REPORT:
      return {
        ...state,
        getDeniedWarrantyServiceOrdersReport: {
          isLoading: true,
          isError: false,
        },
      };
    case success(GET_DENIED_WARRANTY_SERVICE_ORDERS_REPORT):
      return {
        ...state,
        getDeniedWarrantyServiceOrdersReport: {
          isLoading: false,
          isError: false,
        },
      };
    case error(GET_DENIED_WARRANTY_SERVICE_ORDERS_REPORT):
      return {
        ...state,
        getDeniedWarrantyServiceOrdersReport: {
          isLoading: false,
          isError: true,
        },
      };
    default:
      return state;
  }
};

export default reducer;
