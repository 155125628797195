import { TranslationOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useToggle } from '../../../hooks';
import { Col, Popover, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { WhatsNewModal } from '../../../containers/Home/components/WhatsNewModal';
import { withAppInsights } from '../../../helpers/appInsights';
import { getShowWhatsNew, hasMetSession, setShowWhatsNew } from '../../../helpers/sessionStorage';
import { useFeatureToggles, useLocale, useMetAuth, useUser } from '../../../hooks';
import { announcementsActions, configurationActions, localesActions, usersActions } from '../../../store';
import { NavMenu } from '../../NavMenu';
import { AuthError } from '../../PageError';
import { useBaseContext } from './BaseContext';
import { PageLoader } from '../../PageLoader';
import {
  BannerAlert,
  CenteredFooterCol,
  Content,
  FooterExternalLink,
  FooterImage,
  FooterMenu,
  InnerContent,
  LinkDivider,
  Main,
  RightContent,
  StyledRow,
} from './styled';
import { parseUserIdFromClaims } from '../../../helpers/user';
import { setUserId } from '../../../helpers/sessionStorage';

const BaseLayout = withAppInsights((props) => {
  const { Link } = Typography;
  const { isAuthenticated, isLoading, getAccessTokenSilently, user: auth0user } = useAuth0();
  const { user, email, loadingUser, errorLoadingUser, preferredLanguage, accountNumber } = useUser();
  const { languages, storedLocale, isCaLocale } = useLocale();
  const { loadingMetUserToken } = useMetAuth();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { localesSupport } = useFeatureToggles();
  const { Text } = Typography;
  const { authenticationError } = useBaseContext();
  const [showWhatsNewModal, toggleWhatsNewModal] = useToggle(false);
  const [languagePopover, setLanguagePopover] = useState(false);
  const { locale } = useSelector((state) => state.locales);
  const { announcements } = useSelector((state) => state.announcements.getActiveAnnouncements);
  const [siteLanguage, setSiteLanguage] = useState('English');
  const [isInitializing, setIsInitializing] = useState(true);

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    if (locale && languages) {
      for (let i = 0; i < languages.length; i++) {
        if (locale.toLowerCase() == languages[i].value.toLowerCase()) {
          setSiteLanguage(intl.formatMessage({ id: languages[i].id }));
          return;
        }
      }
      setSiteLanguage(intl.formatMessage({ id: 'profile.preferredLanguage.english' }));
    } else {
      setSiteLanguage(intl.formatMessage({ id: 'profile.preferredLanguage.english' }));
    }
  }, [locale, languages]);

  useEffect(() => {
    // fetch stored language preference from footer button
    let language = storedLocale();

    // check if user is authenticated before checking language
    if (isAuthenticated && hasMetSession()) {
      const userId = parseUserIdFromClaims(auth0user);
      setUserId(userId);

      if (user && language) {
        // if users preferred language does not match the one in the UI, updates the app to match the preferred language
        if (preferredLanguage != language) {
          dispatch(localesActions.updateLocale(preferredLanguage));
        }
      } else if (user && locale) {
        // if the browser has a selected language that is not english set the users preference to that language.
        if (preferredLanguage != locale && locale.toLowerCase().indexOf('en') < 0) {
          dispatch(usersActions.updateUserPreferredLanguage(locale));
        }
        // locale is english and no stored language so if there is a language preference use that
        else if (preferredLanguage) {
          dispatch(localesActions.updateLocale(preferredLanguage));
        }
      }
    }
  }, [locale, user, preferredLanguage]);

  useEffect(() => {
    if (!isAuthenticated) {
      // Enable seamless SSO w/ Auth0 by asynchronously requesting access token if user is not logged in
      // If request succeeds, it will change the isAuthenticated state and the site will refresh itself to show the user as logged in!
      getAccessTokenSilently()
        .catch((error) => {
          if (error.error !== 'login_required') {
            appInsights.trackEvent({
              name: 'GetAccessTokenSilently_Error',
              properties: {
                error,
              },
            });
            throw error;
          }
        })
        .finally(() => {
          setIsInitializing(false);
        });
    } else {
      setIsInitializing(false);
    }

    if (isAuthenticated && !hasMetSession() && !authenticationError) {
      // User has active auth cookie but is missing session data
      history.replace(`/login/?r=${window.location.pathname}`);
    }

    if (getShowWhatsNew()) {
      toggleWhatsNewModal();
    }

    if (isAuthenticated && hasMetSession() && !email && !loadingUser) {
      // Populate Redux with user object
      dispatch(usersActions.getCurrentUser()).catch(() => {
        // Upsert User because the user is authenticated via auth0 but does not exist in our eService database
        dispatch(usersActions.upsertUser())
          .then(() => {
            history.push('/');
          })
          .catch(() => {
            toast.error(intl.formatMessage({ id: 'profile.loadingUserErrorMessage' }), { autoClose: false });
          });
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (announcements.length < 1) {
      // Announcements
      let userCountryCode = 'US';

      if (isCaLocale()) {
        userCountryCode = 'CA';
      }

      dispatch(announcementsActions.getActiveAnnouncements(userCountryCode));
    }

    dispatch(configurationActions.getActiveFeatures());
  }, []);

  const handleToggleWhatsNewModal = () => {
    setShowWhatsNew(false);

    toggleWhatsNewModal();
  };

  const handleLocaleChange = (newLocale) => {
    dispatch(localesActions.updateLocale(newLocale));
    dispatch(usersActions.updateUserPreferredLanguage(newLocale));
    if (languagePopover) {
      setLanguagePopover(false);
    }
  };

  const handleVisibleChange = (visibility) => {
    setLanguagePopover(visibility);
  };

  const getLanguageBody = (index) => {
    let body = '';
    if (announcements.length > 0) {
      let lang = preferredLanguage?.toLowerCase() ?? '';
      switch (lang) {
        case 'fr-ca':
          body = announcements[index]?.body.filter((x) => x.lang.toLowerCase() === 'fr-ca')[0];

          if (!body) {
            body = announcements[index]?.body.filter((x) => x.lang.toLowerCase() === 'en-us')[0];
          }
          break;
        case 'en-us':
        default:
          body = announcements[index]?.body.filter((x) => x.lang.toLowerCase() === 'en-us')[0];
          break;
      }
    }
    return body.text;
  };

  // If processing Auth0, MET session, or loading user into Redux
  if (
    isLoading ||
    loadingMetUserToken ||
    isInitializing ||
    (isAuthenticated && !email && !authenticationError && !errorLoadingUser)
  ) {
    return <PageLoader />;
  }

  const announcementsList =
    announcements &&
    announcements.length > 0 &&
    announcements.map((element, index) => {
      return <BannerAlert type='info' showIcon message={getLanguageBody(index)} />;
    });

  const localePopoverContent = (
    <Col>
      <Row>
        <div>
          <FooterImage
            data-test-id='localeUSA'
            src={window.location.origin + '/images/en.svg'}
            alt={intl.formatMessage({ id: 'footer.en' })}
            className='mr-3'
            onClick={() => handleLocaleChange('en-US')}
          />
        </div>
        <div>
          <Link data-test-id='changeLocaleValue' onClick={() => handleLocaleChange('en-US')}>
            {intl.formatMessage({ id: 'profile.preferredLanguage.english' })}
          </Link>
        </div>
      </Row>
      <Row>
        <div>
          <FooterImage
            data-test-id='localeCA'
            src={window.location.origin + '/images/fr-CA.svg'}
            alt={intl.formatMessage({ id: 'footer.frCa' })}
            onClick={() => handleLocaleChange('fr-CA')}
            className='mr-3'
          />
        </div>
        <div>
          <Link data-test-id='localeChangeCA' onClick={() => handleLocaleChange('fr-CA')}>
            {intl.formatMessage({ id: 'profile.preferredLanguage.french' })}
          </Link>
        </div>
      </Row>
    </Col>
  );

  const localesMarkup = localesSupport && (
    <CenteredFooterCol xs={24} lg={4} style={{ marginLeft: '15px' }}>
      <Popover
        title={intl.formatMessage({ id: 'footer.language' })}
        content={localePopoverContent}
        open={languagePopover}
        onOpenChange={handleVisibleChange}
        trigger='click'
      >
        <TranslationOutlined
          data-test-id='languagePopover'
          className='mr-1'
          onClick={() => handleVisibleChange(!languagePopover)}
        />
        <FooterExternalLink data-test-id='languageSiteFooter' onClick={() => handleVisibleChange(!languagePopover)}>
          {siteLanguage}
        </FooterExternalLink>
      </Popover>
    </CenteredFooterCol>
  );

  const content = authenticationError ? <AuthError /> : props.children;
  //const showChat = isAuthenticated && accountNumber && !isCaLocale();

  return (
    <Content>
      <NavMenu />
      <Main>
        <RightContent>
          <InnerContent>
            {announcementsList}
            {content}
          </InnerContent>
          <FooterMenu>
            <StyledRow justify={'end'}>
              <CenteredFooterCol xs={24} lg={4} offset={2}>
                <Text style={{ color: '#FFFFFF' }}>© {new Date().getFullYear()} - Milwaukee Tool</Text>
              </CenteredFooterCol>
              <CenteredFooterCol xs={24} lg={12} style={{ textAlign: 'right' }}>
                <div>
                  <FooterExternalLink
                    data-test-id='legalFooterLink'
                    href='https://www.milwaukeetool.com/Footer/Legal'
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <FormattedMessage id='footer.legal' />
                  </FooterExternalLink>
                  <LinkDivider> | </LinkDivider>
                  <FooterExternalLink
                    data-test-id='privacyFooterLink'
                    href='https://www.milwaukeetool.com/Footer/Privacy'
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <FormattedMessage id='footer.privacy' />
                  </FooterExternalLink>
                  <LinkDivider> | </LinkDivider>
                  <FooterExternalLink
                    data-test-id='privacyFooterOneTrustLink'
                    href='https://privacyportal-cdn.onetrust.com/dsarwebform/0bcd3d0d-df9d-4291-9bd5-f276c7dae8aa/c5ed10b1-7ea6-4af4-839e-b31008e11e5c.html'
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {intl.formatMessage({ id: 'footer.oneTrust' })}
                  </FooterExternalLink>
                </div>
              </CenteredFooterCol>
              {localesMarkup}
            </StyledRow>
          </FooterMenu>
        </RightContent>
      </Main>
      <WhatsNewModal hidden={!showWhatsNewModal} toggle={handleToggleWhatsNewModal} />
    </Content>
  );
});

export default BaseLayout;
