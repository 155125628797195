import moment from 'moment';

// NULL = Unknown
// true = Is covered based on information provided
// false = Is not covered based on information provided
const calculateWarrantyEligibility = (manufactureDate, purchaseDate, warrantyPeriod) => {
  const today = new moment().format('MM/DD/YYYY');

  // If no warranty period given we can't do anything
  if (!warrantyPeriod) {
    return null;
  }

  // If given manufacture date, try using that first
  if (manufactureDate && !purchaseDate) {
    let manufactureDateMoment = moment(manufactureDate).format('MM/DD/YYYY');
    let lastDayOfWarranty = moment(manufactureDateMoment).add(warrantyPeriod, 'years');

    return moment(today).isBefore(lastDayOfWarranty);
  }
  // Otherwise, try to use purchase date
  else if (purchaseDate && !manufactureDate) {
    let purchaseDateMoment = moment(purchaseDate).format('MM/DD/YYYY');
    let lastDayOfWarranty = moment(purchaseDateMoment).add(warrantyPeriod, 'years');

    return moment(today).isBefore(lastDayOfWarranty);
  }
  //If both exist and purchaseDate was earlier than manufactured date use purchaseDate
  else if (manufactureDate && purchaseDate) {
    let manufactureDateMoment = moment(manufactureDate).format('MM/DD/YYYY');
    let lastDayOfManufactureWarranty = moment(manufactureDateMoment).add(warrantyPeriod, 'years');

    let purchaseDateMoment = moment(purchaseDate).format('MM/DD/YYYY');
    let lastDayOfPurchaseWarranty = moment(purchaseDateMoment).add(warrantyPeriod, 'years');

    if (lastDayOfManufactureWarranty <= lastDayOfPurchaseWarranty) {
      return moment(today).isBefore(lastDayOfPurchaseWarranty);
    } else {
      return moment(today).isBefore(lastDayOfManufactureWarranty);
    }
  }
  // Without manufacture or purchase date, we can't make determination
  else {
    return null;
  }
};

export { calculateWarrantyEligibility };
