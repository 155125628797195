import {
  GET_SERVICE_ORDER_RECYCLING,
  GET_SERVICE_ORDER_RECYCLING_GROUPS,
  EXPORT_SERVICE_ORDER_RECYCLING,
  UPDATE_SERVICE_ORDER_RECYCLING_REPLACEMENT,
} from '../types';
import { error, success } from '../utils';

export let initialState = {
  getServiceOrderRecycling: {
    isLoading: false,
    isError: false,
    serviceOrderRecycling: null,
  },
  updateServiceOrderRecyclingReplacement: {
    isLoading: false,
    isError: false,
  },
  getServiceOrderRecyclingGroups: {
    isLoading: false,
    isError: false,
    serviceOrderRecyclingGroups: null,
  },
  exportServiceOrderRecycling: {
    isLoading: false,
    isError: false,
  },
};

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_SERVICE_ORDER_RECYCLING:
      return {
        ...state,
        getServiceOrderRecycling: {
          isLoading: true,
          isError: false,
          serviceOrderRecycling: null,
        },
      };
    case success(GET_SERVICE_ORDER_RECYCLING):
      return {
        ...state,
        getServiceOrderRecycling: {
          isLoading: false,
          isError: false,
          serviceOrderRecycling: action.payload.data,
        },
      };
    case error(GET_SERVICE_ORDER_RECYCLING):
      return {
        ...state,
        getServiceOrderRecycling: {
          isLoading: false,
          isError: true,
          serviceOrderRecycling: null,
        },
      };
    case GET_SERVICE_ORDER_RECYCLING_GROUPS:
      return {
        ...state,
        getServiceOrderRecyclingGroups: {
          isLoading: true,
          isError: false,
          serviceOrderRecyclingGroups: null,
        },
      };
    case success(GET_SERVICE_ORDER_RECYCLING_GROUPS):
      return {
        ...state,
        getServiceOrderRecyclingGroups: {
          isLoading: false,
          isError: false,
          serviceOrderRecyclingGroups: action.payload.data,
        },
      };
    case error(GET_SERVICE_ORDER_RECYCLING_GROUPS):
      return {
        ...state,
        getServiceOrderRecyclingGroups: {
          isLoading: false,
          isError: true,
          serviceOrderRecyclingGroups: null,
        },
      };
    case UPDATE_SERVICE_ORDER_RECYCLING_REPLACEMENT:
      return {
        ...state,
        updateServiceOrderRecyclingReplacement: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPDATE_SERVICE_ORDER_RECYCLING_REPLACEMENT):
      return {
        ...state,
        updateServiceOrderRecyclingReplacement: {
          isLoading: false,
          isError: false,
        },
      };
    case error(UPDATE_SERVICE_ORDER_RECYCLING_REPLACEMENT):
      return {
        ...state,
        updateServiceOrderRecyclingReplacement: {
          isLoading: false,
          isError: true,
        },
      };
    case EXPORT_SERVICE_ORDER_RECYCLING:
      return {
        ...state,
        exportServiceOrderRecycling: {
          isLoading: true,
          isError: false,
        },
      };
    case success(EXPORT_SERVICE_ORDER_RECYCLING):
      return {
        ...state,
        exportServiceOrderRecycling: {
          isLoading: false,
          isError: false,
        },
      };
    case error(EXPORT_SERVICE_ORDER_RECYCLING):
      return {
        ...state,
        exportServiceOrderRecycling: {
          isLoading: false,
          isError: true,
        },
      };
    default:
      return state;
  }
};

export default reducer;
