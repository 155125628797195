import { Alert, Button, Space } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

const RecycleOrderStatus = ({ serviceOrderId, orderProcessed, callToRecycleFailure }) => {
  const history = useHistory();

  let messageType = callToRecycleFailure ? 'error' : 'info';

  let message;
  if (callToRecycleFailure) {
    message = <FormattedMessage id='orderDetails.trackingInfoError' />;
  } else if (orderProcessed) {
    message = <FormattedMessage id='general.processed' />;
  } else {
    message = <FormattedMessage id='general.processing' />;
  }

  let description;
  if (callToRecycleFailure) {
    description = <FormattedMessage id='orderDetails.trackingInfoErrorDetail' />;
  } else if (orderProcessed) {
    description = <FormattedMessage id='orderSummary.otherItemsProcessed' />;
  } else {
    description = <FormattedMessage id='orderSummary.otherItemsProcessing' />;
  }

  let actions;
  if (!callToRecycleFailure) {
    actions = (
      <Button data-test-id='C2RViewOrderButton' type='primary' onClick={() => history.push(`/repairdetail/${serviceOrderId}`)}>
        <FormattedMessage id='orderSummary.viewDetails' />
      </Button>
    );
  }

  let descriptionMarkup = (
    <Space direction='vertical' size='middle'>
      {description}
      {actions}
    </Space>
  );

  return <Alert type={messageType} message={message} description={descriptionMarkup} />;
};

export default RecycleOrderStatus;
