import { useAuth0 } from '@auth0/auth0-react';
import { Button, Col, Row, Text } from '@met/react-components';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ActionText, ActionWrapper, SearchResult, StyledImg, StyledText } from './styled';
import { useFeatureToggles } from '../../hooks';

const ProductSearchResult = ({ sku, skuAlias, description, imageUrl, serviceEligible, handleAddClick }) => {
  const { isAuthenticated } = useAuth0();
  const { largeProductSkuProgram } = useFeatureToggles();

  const [largeProductSku, setLargeProductSku] = useState(false);

  //Due to the rebuild of eService/ServiceApps, the decision was made to 
  //hard-code these values rather than building a scalable solution. 
  //These values will NOT be maintained/updated over time
  const largeProductSkus = [
    'MXF5122XC',
    'MXF2202HD',
    'MXF3363HD',
    'MXF3242HD',
    'MXF5011CP',
    'MXF5001CP',
    'MXF3022HD',
    'MXF0411XC',
    'MXF0410',
    'MXF3162XC',
    'MXF3681XC',
    '3302',
    'MXF3012CXS',
    '3300R']

  useEffect(() => {
    if (largeProductSkuProgram && largeProductSkus.includes(sku)) {
      setLargeProductSku(true);
    }
  })

  const largeProductSkuMarkup = (
    <ActionWrapper>
      <ActionText style={{ color: '#db011c', fontWeight: 'bold' }}>
        <FormattedMessage id='productSearch.largeProductCall' />
      </ActionText>
      <br />
      <Text>
        <FormattedMessage id='productSearch.largeProductServiceSupport' />
      </Text>
    </ActionWrapper>
  )

  const actionMarkup = serviceEligible ? (
    <ActionWrapper>
      <Button data-test-id='productSearchResultLoginAndAddToolButton' primary onClick={() => handleAddClick()}>
        {isAuthenticated ? <FormattedMessage id='general.add' /> : <FormattedMessage id='general.loginAndAdd' />}
      </Button>
    </ActionWrapper>
  ) : (
    <ActionWrapper>
      <Text>
        <FormattedMessage id='productSearch.notEligible' />
      </Text>
      <br />
      <a data-test-id='unauthenticatedUserFindAServiceCenterLink' href='https://www.milwaukeetool.com/Support/Find-A-Service-Center' target='_blank' rel='noopener noreferrer'>
        <FormattedMessage id='productSearch.findServiceCenter' />
      </a>
    </ActionWrapper>
  );

  return (
    <SearchResult>
      <Row>
        <Col xs={3} middle='xs' center='xs'>
          <div>
            <StyledImg src={imageUrl} />
          </div>
        </Col>
        <Col middle='xs'>
          <StyledText sm data-hj-allow>
            {skuAlias}
          </StyledText>
          <StyledText sm bold>
            {description}
          </StyledText>
        </Col>
        <Col xs={12} sm={4} middle='xs' center='xs'>
          {largeProductSku ? largeProductSkuMarkup : actionMarkup}
        </Col>
      </Row>
    </SearchResult>
  );
};

export default ProductSearchResult;
