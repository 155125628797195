import { Modal, Typography } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const { Title, Paragraph } = Typography;

const LmrDefinitionModal = ({ isVisible, toggle }) => {
  return (
    <Modal centered width={800} visible={isVisible} footer={null} onCancel={toggle}>
      <Title strong>
        <FormattedMessage id='productDetail.toolInfo.lmrModalHeader' />
      </Title>
      <Paragraph>
        <FormattedMessage id='productDetail.toolInfo.lmrModalLightning1' />
      </Paragraph>
      <Paragraph>
        <FormattedMessage id='productDetail.toolInfo.lmrModalLightning2' />
      </Paragraph>
      <Paragraph>
        <ul>
          <li>
            <FormattedMessage id='productDetail.toolInfo.lmrModalLightning3.speed' />
          </li>
          <li>
            <FormattedMessage id='productDetail.toolInfo.lmrModalLightning3.payLess' />
          </li>
          <li>
            <FormattedMessage id='productDetail.toolInfo.lmrModalLightning3.reliability' />
          </li>
          <li>
            <FormattedMessage id='productDetail.toolInfo.lmrModalLightning3.quality' />
          </li>
        </ul>
      </Paragraph>
    </Modal>
  );
};

export default LmrDefinitionModal;
