import { Checkbox, Col, Placeholder, PlaceholderContent, Row, Text, TextInput } from '@met/react-components';
import Enumerable from 'linq';
import React, { Fragment, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useRepairItemsContext } from '../../../RepairItemsContext';
import { CheckboxWrapper, OtherInputWrapper } from '../styled';

const Problem = ({ problemRef }) => {
  const intl = useIntl();
  const {
    loadingProduct,
    product,
    problems,
    setProblems,
    hasOtherProblem,
    setHasOtherProblem,
    otherProblem,
    setOtherProblem,
    otherProblemInvalid,
    problemsInvalid,
    setUnsavedChanges,
  } = useRepairItemsContext();

  useEffect(() => {
    if (!hasOtherProblem) setOtherProblem('');
  }, [hasOtherProblem]);

  useEffect(() => {
    setHasOtherProblem(otherProblem.length > 0);
  }, [otherProblem]);

  const handleCheckProblem = problem => {
    let existingProblemIndex = Enumerable.from(problems).indexOf(x => x.id === problem.id);

    if (existingProblemIndex > -1) {
      let updatedProblems = problems.filter((value, index) => {
        return index !== existingProblemIndex;
      });

      if (problems.length !== updatedProblems.length) {
        setUnsavedChanges(true);
      }

      setProblems(updatedProblems);
    } else {
      let newProblem = {
        id: problem.id,
        value: problem.description,
      };

      setUnsavedChanges(true);

      setProblems([...problems, newProblem]);
    }
  };

  const problemTitle = (
    <Row>
      <Col>
        <h3 ref={problemRef}>
          <FormattedMessage id='productDetail.problem.problemTitle' />
        </h3>
      </Col>
    </Row>
  );

  const problemsErrorMarkup = problemsInvalid && (
    <Text error>
      <FormattedMessage id='productDetail.problem.problemInvalid' />
    </Text>
  );

  const problemPlaceholderMarkup = loadingProduct && (
    <Placeholder>
      {problemTitle}
      <Row>
        <Col>
          <Row>
            <Col xs={5}>
              <PlaceholderContent />
            </Col>
          </Row>
          <Row>
            <Col xs={5}>
              <PlaceholderContent />
            </Col>
          </Row>
          <Row>
            <Col xs={5}>
              <PlaceholderContent />
            </Col>
          </Row>
          <Row>
            <Col xs={5}>
              <PlaceholderContent />
            </Col>
          </Row>
          <Row>
            <Col xs={5}>
              <PlaceholderContent />
            </Col>
          </Row>
          <Row>
            <Col xs={5}>
              <PlaceholderContent />
            </Col>
          </Row>
        </Col>
      </Row>
    </Placeholder>
  );

  const problemList =
    product &&
    product.productProblems.map((problem, index) => {
      let existingProblem = Enumerable.from(problems).firstOrDefault(x => x.id === problem.id);

      return (
        <Col xs={12} md={6} key={`problem-${index}`}>
          <CheckboxWrapper>
            <Checkbox
              onCheck={() => handleCheckProblem(problem)}
              label={intl.formatMessage({ id: 'productProblems.' + problem.id, defaultMessage: problem.description })}
              checked={existingProblem}
              data-test-id={`problem-${index}`}
            />
          </CheckboxWrapper>
        </Col>
      );
    });

  const problemOther = (
    <Col xs={12} md={6}>
      <CheckboxWrapper>
        <Checkbox
          onCheck={() => setHasOtherProblem(!hasOtherProblem)}
          label={intl.formatMessage({
            id: 'productDetail.problem.problemOtherLabel',
          })}
          checked={hasOtherProblem}
          data-test-id='problem-other'
        />
        <OtherInputWrapper>
          <TextInput
            data-test-id='productDetailOtherProblem'
            multiline
            rows={5}
            onChange={e => setOtherProblem(e.target.value)}
            disabled={!hasOtherProblem}
            value={otherProblem}
            invalid={otherProblemInvalid}
            errorMessage={intl.formatMessage({ id: 'productDetail.problem.problemOtherInvalid' })}
            data-hj-allow
          />
        </OtherInputWrapper>
      </CheckboxWrapper>
    </Col>
  );

  const problemMarkup = !loadingProduct && product && (
    <Fragment>
      {problemTitle}
      {problemsErrorMarkup}
      <Row>
        {problemList}
        {problemOther}
      </Row>
    </Fragment>
  );

  return (
    <Fragment>
      {problemPlaceholderMarkup}
      {problemMarkup}
    </Fragment>
  );
};

export default Problem;
