import React from 'react';
import { useSelector } from 'react-redux';
import { proofOfPurchaseActions } from '../store';

const useProofOfPurchase = () => {
  const downloadingProofOfPurchase = useSelector(state => state.proofOfPurchase.downloadProofOfPurchase.isLoading);

  const downloadProofOfPurchase = serviceOrderId => {
    return proofOfPurchaseActions.downloadProofOfPurchase(serviceOrderId);
  };

  return {
    downloadingProofOfPurchase,
    downloadProofOfPurchase,
  };
};

export default useProofOfPurchase;
