import styled from 'styled-components';

export const PageLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SpinContainer = styled.div`
   text-align: center;
   margin-top: 15%;
`;

export const InnerSpinner = styled.div`
  border: 3px solid #DB011C;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
  opacity: 50%;
  
  @keyframes rotate {
    0% {
      transform: rotateZ(-360deg)
    }
    100% {
      transform: rotateZ(0deg)
    }
  }
  @keyframes rotate2 {
    0% {
      transform: rotateZ(360deg)
    }
    100% {
      transform: rotateZ(0deg)
    }
`;

export const OuterSpinner = styled.div`
  margin: 0 auto;
  border-radius: 50%;
  
  border: 3px solid #DB011C;
  width: 220px;
  height: 220px;
  position: relative;
  top: -210px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
  
    @keyframes rotate {
    0% {
      transform: rotateZ(-360deg)
    }
    100% {
      transform: rotateZ(0deg)
    }
  }
  @keyframes rotate2 {
    0% {
      transform: rotateZ(360deg)
    }
    100% {
      transform: rotateZ(0deg)
    }
`;

export const LogoWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  top: -345px;
`;

// Small Scale Spinner 

export const InnerSpinnerJr = styled.div`
  border: 2px solid #DB011C;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
  opacity: 50%;
  
  @keyframes rotate {
    0% {
      transform: rotateZ(-360deg)
    }
    100% {
      transform: rotateZ(0deg)
    }
  }
  @keyframes rotate2 {
    0% {
      transform: rotateZ(360deg)
    }
    100% {
      transform: rotateZ(0deg)
    }
`;

export const OuterSpinnerJr = styled.div`
  margin: 0 auto;
  border-radius: 50%;
  
  border: 2px solid #DB011C;
  width: 110px;
  height: 110px;
  position: relative;
  top: -105px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
  
    @keyframes rotate {
    0% {
      transform: rotateZ(-360deg)
    }
    100% {
      transform: rotateZ(0deg)
    }
  }
  @keyframes rotate2 {
    0% {
      transform: rotateZ(360deg)
    }
    100% {
      transform: rotateZ(0deg)
    }
`;

export const SpinContainerJr = styled.div`
   text-align: center;
   margin-top: 10%;
`;

export const LogoWrapperJr = styled.div`
  margin: 0 auto;
  position: relative;
  top: -172px;
`;