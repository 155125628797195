import { faBox, faBoxOpen, faShippingFast, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PizzaTracker from '../../../../components/PizzaTracker';
import { useRecycling } from '../../../../hooks';

const RecycleOrderProgress = () => {
  const { serviceOrderRecycling } = useRecycling();

  const [recyclingStatus, setRecyclingStatus] = useState(serviceOrderRecycling.status);

  if (recyclingStatus === 9) {
    return null; // If tracking failed, don't show any progress
  }

  if (recyclingStatus === 0) {
    setRecyclingStatus(1); // If status is NULL or 0, default to 1 (Processing)
  }

  let detailsMarkup = <FormattedMessage id={`repairDetails.orderInfo.recycleStatus.status${recyclingStatus}Details`} />;

  return (
    <Space direction='vertical' size='large'>
      <PizzaTracker
        steps={[
          {
            id: 1,
            icon: <FontAwesomeIcon icon={faSpinner} />,
            label: <FormattedMessage id='repairDetails.orderInfo.recycleStatus.status1' />,
          },
          {
            id: 2,
            icon: <FontAwesomeIcon icon={faShippingFast} flip='horizontal' />,
            label: <FormattedMessage id='repairDetails.orderInfo.recycleStatus.status2' />,
          },
          {
            id: 3,
            icon: <FontAwesomeIcon icon={faBox} />,
            label: <FormattedMessage id='repairDetails.orderInfo.recycleStatus.status3' />,
          },
          {
            id: 4,
            icon: <FontAwesomeIcon icon={faShippingFast} />,
            label: <FormattedMessage id='repairDetails.orderInfo.recycleStatus.status4' />,
          },
          {
            id: 5,
            icon: <FontAwesomeIcon icon={faBoxOpen} />,
            label: <FormattedMessage id='repairDetails.orderInfo.recycleStatus.status5' />,
          },
          {
            id: 6,
            icon: <FontAwesomeIcon icon={faShippingFast} flip='horizontal' />,
            label: <FormattedMessage id='repairDetails.orderInfo.recycleStatus.status6' />,
            isVisible: recyclingStatus > 1, // Only show "Replacements" steps if order processed
          },
          {
            id: 7,
            icon: <FontAwesomeIcon icon={faBox} />,
            label: <FormattedMessage id='repairDetails.orderInfo.recycleStatus.status7' />,
            isVisible: recyclingStatus > 1, // Only show "Replacements" steps if order processed
          },
        ]}
        currentId={recyclingStatus}
      />
      {detailsMarkup}
    </Space>
  );
};

export default RecycleOrderProgress;
