import createAxiosAction from '../createAxiosAction';
import { DELETE_ANNOUNCEMENT, GET_ANNOUNCEMENTS, GET_ACTIVE_ANNOUNCEMENTS, UPSERT_ANNOUNCEMENT } from '../types';

export default {
  getAnnouncements: () => {
    return createAxiosAction({
      url: `/api/v1/announcements`,
      method: 'GET',
      startAction: GET_ANNOUNCEMENTS,
    });
  },
  getActiveAnnouncements: countryCode => {
    return createAxiosAction({
      url: `/api/v1/announcements/active/${countryCode}`,
      startAction: GET_ACTIVE_ANNOUNCEMENTS,
    });
  },
  upsertAnnouncement: (Id, Body, ActiveFrom, ActiveTo, CountryCode) => {
    return createAxiosAction({
      url: '/api/v1/announcements',
      method: 'POST',
      body: {
        Id,
        Body,
        ActiveFrom,
        ActiveTo,
        CountryCode,
      },
      startAction: UPSERT_ANNOUNCEMENT,
    });
  },
  deleteAnnouncement: id => {
    return createAxiosAction({
      url: `/api/v1/announcements`,
      method: 'DELETE',
      body: {
        id: id,
      },
      startAction: DELETE_ANNOUNCEMENT,
    });
  },
};
