import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useUser } from '../../hooks';

const PermissionGatedComponent = ({ permissionRequired, children }) => {
  const { permissions } = useUser();
  const shouldRender = permissions()[permissionRequired];

  return shouldRender ? <Fragment> {children} </Fragment> : <Fragment />;
};

PermissionGatedComponent.propTypes = {
  permissionRequired: PropTypes.string,
};

export default PermissionGatedComponent;
