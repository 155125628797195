import {Button, Form, Input, Select, Typography} from 'antd';
import styled from 'styled-components';

const { Title } = Typography;

export const DisabledInput = styled(Input)`
  cursor: default !important;
  color: #4a4a4a !important;
  background-color: #f2f2f2 !important;
  padding: 0.375rem 0.75rem;
`;

export const UserModalDataFormItem = styled(Form.Item)`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  margin-bottom: 0;

  label.ant-form-item-required::before {
    display: none !important;
  }

  .ant-form-item-explain-error {
    font-size: 0.9rem;
    color: #db011c
  }
`;

export const UserModalInput = styled(Input)`
  padding: 0.375rem 0.75rem;
  width: 100%;
`;

export const UserModalSelect = styled(Select)`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  width: 100% !important;
  font-size: 16px !important;
`;

export const SelectTitle = styled(Title)`
  font-size: 1rem !important;
  font-weight: 400;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
`;

export const SubmitButton = styled(Button)`
  background-color: #db011c; 
  color: #fff;
`;

export const CancelButton = styled(Button)`
  background-color: #cccccc
`;