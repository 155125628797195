import React from 'react';
import { StyledDescriptions, StyledDescriptionsItem } from './styled';

const InfoGrid = ({ children, ...rest }) => {
  return (
    <StyledDescriptions bordered colon={false} {...rest}>
      {children}
    </StyledDescriptions>
  );
};

const InfoGridItem = ({ children, ...rest }) => {
  return <StyledDescriptionsItem {...rest}>{children}</StyledDescriptionsItem>;
};

export { InfoGrid, InfoGridItem };
