import {
  Col,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Row,
  Text,
  TextInput,
} from '@met/react-components';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ButtonWrapper, StyledButton } from '../styled';

const ProductProblemModal = ({
  hidden,
  toggle,
  problem,
  onCancelClick,
  onSaveChangesClick,
  problems,
  upsertingProblem,
}) => {
  const intl = useIntl();
  const [description, setDescription] = useState('');
  const [descriptionInvalid, setDescriptionInvalid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [problemValidationErrors, setProblemValidationErrors] = useState([]);

  const isValid = () => {
    let isValid = true;
    const regex = /([-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/])/gm; // eslint-disable-line no-useless-escape
    setIsDirty(false);
    setDescriptionInvalid(false);
    setProblemValidationErrors([]);
    if (!description || description.length <= 0) {
      setDescriptionInvalid(true);
      isValid = false;
    }
    if (checkForDuplicate(problems, description)) {
      isValid = false;
      setProblemValidationErrors([intl.formatMessage({ id: 'admin.problems.noDuplicates' })]);
    }
    if (regex.test(description)) {
      isValid = false;
      setProblemValidationErrors([intl.formatMessage({ id: 'admin.problems.noSpecialCharacters' })]);
    }
    return isValid;
  };

  const checkForDuplicate = (allProblems, currentDescription) => {
    let result = false;
    let foundProblem = allProblems.find(x => x.description?.toLowerCase() === currentDescription?.toLowerCase());
    if (foundProblem) {
      result = true;
    } else {
      result = false;
    }
    return result;
  };

  const handleSaveChangesClick = () => {
    if (isValid()) {
      onSaveChangesClick(problem.id, description);
    }
  };

  useEffect(() => {
    if (problem && problem.description && problem.description.length > 0) setDescription(problem.description);
    else setDescription('');
    setIsDirty(false);
  }, [problem]);

  useEffect(() => {
    setDescriptionInvalid(false);
    setIsDirty(true);
  }, [description]);

  const problemValidationErrorList =
    !isDirty &&
    problemValidationErrors &&
    problemValidationErrors.length > 0 &&
    problemValidationErrors.map((error, index) => {
      return (
        <Row key={`validation-error-${index}`}>
          <Col>
            <Text bold error>
              {error}
            </Text>
          </Col>
        </Row>
      );
    });

  const problemValidationErrorMarkup = problemValidationErrorList && <Fragment>{problemValidationErrorList}</Fragment>;

  const editProductProblemModal = problem && (
    <Fragment>
      <ModalHeader toggle={toggle}>
        {problem.id ? (
          <FormattedMessage id='admin.problems.editProblemModalTitle' />
        ) : (
          <FormattedMessage id='admin.problems.addProblemModalTitle' />
        )}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <TextInput
              data-test-id='adminProductProblemsDescriptionLabel' 
              label={intl.formatMessage({ id: 'admin.problems.descriptionLabel' })}
              value={description}
              onChange={e => setDescription(e.target.value)}
              invalid={descriptionInvalid}
              errorMessage={intl.formatMessage({ id: 'admin.problems.descriptionRequired' })}
            />
          </Col>
        </Row>
        {problemValidationErrorMarkup}
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col end='xs'>
            {/* eslint-disable-next-line test-selectors/button */}
            <ButtonWrapper>
              <StyledButton data-test-id='adminProductProblemsModalCancelButton' type='secondary' onClick={() => onCancelClick()} loading={upsertingProblem}>
                <FormattedMessage id='general.cancel' />
              </StyledButton>
              <StyledButton
                type='primary'
                onClick={handleSaveChangesClick}
                data-test-id='save-changes-button'
                loading={upsertingProblem}
              >
                <FormattedMessage id='general.submit' />
              </StyledButton>
            </ButtonWrapper>
          </Col>
        </Row>
      </ModalFooter>
    </Fragment>
  );

  return (
    <Modal md centered hidden={hidden} toggle={toggle}>
      <ModalContent data-test-id='productProblem-modal-content'>{editProductProblemModal}</ModalContent>
    </Modal>
  );
};
ProductProblemModal.propTypes = {
  hidden: PropTypes.bool,
  toggle: PropTypes.func,
  problem: PropTypes.object,
  onCancelClick: PropTypes.func,
  onSaveChangesClick: PropTypes.func,
};
export default ProductProblemModal;
