import { css } from 'styled-components';
import { darken } from 'polished';
import { colors } from '../../styled-config/colors';

// Bootstrap 4's reboot.css for normalizing cross-browser styles (override by passing object with properties listed below)
// The defaultOptions will be merged with any properties passed to component
const defaultOptions = {
  fontFamilyBase:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  fontFamilyMonospace:
    'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
  fontSizeBase: '1rem',
  fontWeightBase: 400,
  lineHeightBase: 1.5,
  placeholderColor: '#B5BBC1',
  bodyBg: '#fafafa',
  bodyColor: '#212529',
  textMutedColor: colors.muted,
  headingsMarginBottom: '0.5rem',
  paragraphMarginBottom: '1rem',
  labelMarginBottom: '0.5rem',
  dtFontWeight: 700,
  tableCellPadding: '0.75rem',
  linkColor: colors.red,
  linkHoverColor: darken(0.1, colors.red),
  linkDecoration: 'none',
  linkHoverDecoration: 'underline',
};

export const rebootCss = props => {
  const {
    fontFamilyBase,
    fontFamilyMonospace,
    fontSizeBase,
    fontWeightBase,
    lineHeightBase,
    placeholderColor,
    bodyBg,
    bodyColor,
    textMutedColor,
    headingsMarginBottom,
    paragraphMarginBottom,
    labelMarginBottom,
    dtFontWeight,
    tableCellPadding,
    linkColor,
    linkDecoration,
    linkHoverColor,
    linkHoverDecoration,
  } = {
    ...defaultOptions,
    ...props,
  };

  return css`
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }
    html {
      font-family: sans-serif;
      line-height: 1.15;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
      -ms-overflow-style: scrollbar;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    @-ms-viewport {
      width: device-width;
    }
    article,
    aside,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    nav,
    section {
      display: block;
    }
    body {
      margin: 0;
      font-family: ${fontFamilyBase};
      font-size: ${fontSizeBase};
      font-weight: ${fontWeightBase};
      line-height: ${lineHeightBase};
      color: ${bodyColor};
      text-align: left;
      background-color: ${bodyBg};
    }
    [tabindex='-1']:focus {
      outline: 0 !important;
    }
    hr {
      box-sizing: content-box;
      height: 0;
      overflow: visible;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
      margin-bottom: ${headingsMarginBottom};
    }
    p {
      margin-top: 0;
      margin-bottom: ${paragraphMarginBottom};
    }
    abbr[title],
    abbr[data-original-title] {
      text-decoration: underline;
      text-decoration: underline dotted;
      cursor: help;
      border-bottom: 0;
    }
    address {
      margin-bottom: 1rem;
      font-style: normal;
      line-height: inherit;
    }
    ol,
    ul,
    dl {
      margin-top: 0;
      margin-bottom: 1rem;
    }
    ol ol,
    ul ul,
    ol ul,
    ul ol {
      margin-bottom: 0;
    }
    dt {
      font-weight: ${dtFontWeight};
    }
    dd {
      margin-bottom: 0.5rem;
      margin-left: 0;
    }
    blockquote {
      margin: 0 0 1rem;
    }
    dfn {
      font-style: italic;
    }
    b,
    strong {
      font-weight: bolder;
    }
    small {
      font-size: 80%;
    }
    sub,
    sup {
      position: relative;
      font-size: 75%;
      line-height: 0;
      vertical-align: baseline;
    }
    sub {
      bottom: -0.25em;
    }
    sup {
      top: -0.5em;
    }
    a {
      color: ${linkColor};
      text-decoration: ${linkDecoration};
      background-color: transparent;
      -webkit-text-decoration-skip: objects;
      &:hover {
        color: ${linkHoverColor};
        text-decoration: ${linkHoverDecoration};
      }
    }
    a:not([href]):not([tabindex]) {
      color: inherit;
      text-decoration: none;
      &:focus,
      &:hover {
        color: inherit;
        text-decoration: none;
      }
      &:focus {
        outline: 0;
      }
    }
    pre,
    code,
    kbd,
    samp {
      font-family: ${fontFamilyMonospace};
      font-size: 1em;
    }
    pre {
      margin-top: 0;
      margin-bottom: 1rem;
      overflow: auto;
      -ms-overflow-style: scrollbar;
    }
    figure {
      margin: 0 0 1rem;
    }
    img {
      vertical-align: middle;
      border-style: none;
    }
    svg {
      overflow: hidden;
      vertical-align: middle;
    }
    table {
      border-collapse: collapse;
    }
    caption {
      padding-top: ${tableCellPadding};
      padding-bottom: ${tableCellPadding};
      color: ${textMutedColor};
      text-align: left;
      caption-side: bottom;
    }
    th {
      text-align: inherit;
    }
    label {
      display: inline-block;
      margin-bottom: ${labelMarginBottom};
    }
    button {
      border-radius: 0;
    }
    button:focus {
      outline: 1px dotted;
      outline: 5px auto -webkit-focus-ring-color;
    }
    input,
    button,
    select,
    optgroup,
    textarea {
      margin: 0;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
    }
    button,
    input {
      overflow: visible;
    }
    input::placeholder {
      color: ${placeholderColor};
    }
    button,
    select {
      text-transform: none;
    }
    button,
    html [type='button'],
    [type='reset'],
    [type='submit'] {
      -webkit-appearance: button;
    }
    button::-moz-focus-inner,
    [type='button']::-moz-focus-inner,
    [type='reset']::-moz-focus-inner,
    [type='submit']::-moz-focus-inner {
      padding: 0;
      border-style: none;
    }
    input[type='radio'],
    input[type='checkbox'] {
      box-sizing: border-box;
      padding: 0;
    }
    input[type='date'],
    input[type='time'],
    input[type='datetime-local'],
    input[type='month'] {
      -webkit-appearance: listbox;
    }
    textarea {
      overflow: auto;
      resize: vertical;
    }
    fieldset {
      min-width: 0;
      padding: 0;
      margin: 0;
      border: 0;
    }
    legend {
      display: block;
      width: 100%;
      max-width: 100%;
      padding: 0;
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
      line-height: inherit;
      color: inherit;
      white-space: normal;
    }
    progress {
      vertical-align: baseline;
    }
    [type='number']::-webkit-inner-spin-button,
    [type='number']::-webkit-outer-spin-button {
      height: auto;
    }
    [type='search'] {
      outline-offset: -2px;
      -webkit-appearance: none;
    }
    [type='search']::-webkit-search-cancel-button,
    [type='search']::-webkit-search-decoration {
      -webkit-appearance: none;
    }
    ::-webkit-file-upload-button {
      font: inherit;
      -webkit-appearance: button;
    }
    output {
      display: inline-block;
    }
    summary {
      display: list-item;
      cursor: pointer;
    }
    template {
      display: none;
    }
    [hidden] {
      display: none !important;
    }
  `;
};

export default rebootCss;