const generateGuid = () => {
  let baseString = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  let parsedString = baseString.replace(/[xy]/g, c => {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

  return parsedString;
};

export { generateGuid };
