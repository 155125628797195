import createAxiosAction from '../createAxiosAction';
import { CLEAR_SELECTED_BATTERY, GET_BATTERIES, GET_BATTERY_BY_SKU, UPSERT_BATTERY } from '../types';

export default {
  getBatteries: () => {
    return createAxiosAction({
      url: `/api/v1/batteries`,
      startAction: GET_BATTERIES,
    });
  },
  getBatteryBySku: sku => {
    return createAxiosAction({
      url: `/api/v1/batteries/` + sku,
      startAction: GET_BATTERY_BY_SKU,
    });
  },
  upsertBattery: battery => {
    return createAxiosAction({
      url: `/api/v1/batteries`,
      method: 'POST',
      body: battery,
      startAction: UPSERT_BATTERY,
    });
  },
  clearSelectedBattery: () => {
    return {
      type: CLEAR_SELECTED_BATTERY,
    };
  },
};
