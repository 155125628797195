import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Collapse, Descriptions, Image, List, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useProofOfPurchase } from '../../hooks';
import { CollapsiblePanel, UncollapsibleItem } from './styled';

const { Text } = Typography;

const CollapsibleOrderList = ({ orders, showViewButton }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { downloadProofOfPurchase, downloadingProofOfPurchase } = useProofOfPurchase();

  const [isRecycleOrder, setIsRecycleOrder] = useState(false);

  useEffect(() => {
    if (orders && orders.length > 0) {
      setIsRecycleOrder(orders[0].serviceOrderRecyclingId ? true : false);
    }
  }, [orders]);

  const handleProofOfPurchaseClick = serviceOrderId => {
    dispatch(downloadProofOfPurchase(serviceOrderId)).then(response => {
      window.open(response.data.data.url);
    });
  };

  const ordersMarkup =
    orders &&
    orders.map(order => {
      let primaryTool = order.serviceOrderLines.find(x => x.isPrimaryTool === true);

      if (!primaryTool) {
        return null;
      }

      let serialNumber =
        primaryTool.serialNumber && primaryTool.serialNumber.length > 0
          ? primaryTool.serialNumber
          : intl.formatMessage({ id: 'general.notProvided' });
      let tagNumber =
        order.mcaCustomerRef && order.mcaCustomerRef.length > 0
          ? order.mcaCustomerRef
          : intl.formatMessage({ id: 'general.notProvided' });

      let viewButtonMarkup = showViewButton && (
        <Button
          type='primary'
          ghost
          onClick={() => history.push(`/repairdetail/${order.id}`)}
          data-test-id='viewOrderDetailsButton'
        >
          <FormattedMessage id='collapsibleOrderList.viewDetails' />
        </Button>
      );

      let headerMarkup = (
        <Row gutter={[24, 24]} align='middle' justify='center' style={{ width: '100%' }}>
          <Col xs={12} md={2}>
            <Image preview={false} src={`/api/v1/products/${primaryTool.sku}/image`} alt={primaryTool.sku} />
          </Col>
          <Col xs={24} md={10}>
            <Text>{primaryTool.sku}</Text>
            <br />
            <Text strong>{primaryTool.description}</Text>
          </Col>
          <Col xs={24} md={4}>
            <Text>
              <FormattedMessage id='collapsibleOrderList.serialNumber' />
            </Text>
            <br />
            <Text>{serialNumber}</Text>
          </Col>
          <Col xs={{ flex: 0 }} md={8} style={{ textAlign: 'right' }}>
            {viewButtonMarkup}
          </Col>
        </Row>
      );

      let proofOfPurchaseMarkup = order.proofOfPurchaseIncluded ? (
        <FormattedMessage id='collapsibleOrderList.included' />
      ) : order.proofOfPurchaseBlob ? (
        <Button
          type='primary'
          onClick={() => handleProofOfPurchaseClick(order.id)}
          icon={<FontAwesomeIcon icon={faFileDownload} />}
          loading={downloadingProofOfPurchase}
          data-test-id='downloadProofOfPurchaseButton'
        >
          <span>
            <FormattedMessage id='general.download' />
          </span>
        </Button>
      ) : (
        <FormattedMessage id='collapsibleOrderList.notIncluded' />
      );

      let serviceOrderIncluded = order?.serviceOrderIncluded
        ?.map((id, index) => {
          return intl.formatMessage({ id: `productInclusions.${id}` });
        })
        .join(', ');

      let serviceOrderProblems = order?.serviceOrderProblems
        ?.map((id, index) => {
          return intl.formatMessage({ id: `productProblems.${id}` });
        })
        .join(', ');

      return isRecycleOrder ? (
        <UncollapsibleItem key={`order-${order.id}`}>{headerMarkup}</UncollapsibleItem>
      ) : (
        <CollapsiblePanel header={headerMarkup} key={`order-${order.id}`}>
          <Descriptions bordered column={1} style={{ maxWidth: '30em' }}>
            <Descriptions.Item label={intl.formatMessage({ id: 'collapsibleOrderList.serialNumber' })}>
              {serialNumber}
            </Descriptions.Item>
            <Descriptions.Item label={intl.formatMessage({ id: 'collapsibleOrderList.tagNumber' })}>
              {tagNumber}
            </Descriptions.Item>
            <Descriptions.Item label={intl.formatMessage({ id: 'collapsibleOrderList.proofOfPurchase' })}>
              {proofOfPurchaseMarkup}
            </Descriptions.Item>
            <Descriptions.Item label={intl.formatMessage({ id: 'collapsibleOrderList.problem' })}>
              {serviceOrderProblems ? serviceOrderProblems : order.problemDescription}
              {serviceOrderProblems && order.problemDescriptionOther ? ', ' + order.problemDescriptionOther : ''}
            </Descriptions.Item>
            <Descriptions.Item label={intl.formatMessage({ id: 'collapsibleOrderList.included' })}>
              {serviceOrderIncluded ? serviceOrderIncluded : order.included}
              {serviceOrderIncluded && order.includedOther ? ', ' + order.includedOther : ''}
            </Descriptions.Item>
          </Descriptions>
        </CollapsiblePanel>
      );
    });

  return isRecycleOrder ? <List bordered>{ordersMarkup}</List> : <Collapse>{ordersMarkup}</Collapse>;
};

export default CollapsibleOrderList;
