import {
  ADD_CREDIT_CARD,
  AUTHORIZE_CREDIT_CARD,
  DELETE_CREDIT_CARD,
  GET_CREDIT_CARDS,
  GET_INVITE_TO_PAY,
  GET_SQUARE_CONFIG,
  SET_SELECTED_CARD,
} from '../types';
import { error, success } from '../utils';

export const initialState = {
  squareApplicationId: '',
  allowTestCards: false,
  creditCards: [],
  selectedCard: null,
  inviteToPay: {
    transaction: null,
    isLoading: false,
    isError: false,
  },
  getCreditCards: {
    isLoading: false,
    isError: false,
  },
  addCreditCard: {
    isLoading: false,
    isError: false,
  },
  getSquareConfig: {
    isLoading: false,
    isError: false,
  },
  deleteCreditCard: {
    isProcessing: false,
    isError: false,
  },
  submitInviteToPay: {
    isProcessing: false,
    isError: false,
  },
  authorizeCreditCard: {
    isLoading: false,
    isError: false,
  },
};

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_SQUARE_CONFIG:
      return {
        ...state,
        getSquareConfig: {
          isLoading: true,
          isError: false,
        },
      };
    case success(GET_SQUARE_CONFIG):
      return {
        ...state,
        squareApplicationId: action.payload.data.squareApplicationId,
        allowTestCards: action.payload.data.allowTestCards,
        getSquareConfig: {
          isLoading: false,
          isError: false,
        },
      };
    case error(GET_SQUARE_CONFIG):
      return {
        ...state,
        getSquareConfig: {
          isLoading: false,
          isError: true,
        },
      };

    case GET_INVITE_TO_PAY:
      return {
        ...state,
        inviteToPay: {
          ...state.inviteToPay,
          isLoading: true,
          isError: false,
        },
      };
    case success(GET_INVITE_TO_PAY):
      return {
        ...state,
        inviteToPay: {
          transaction: action.payload.data,
          isLoading: false,
          isError: false,
        },
      };
    case error(GET_INVITE_TO_PAY):
      return {
        ...state,
        inviteToPay: {
          ...state.inviteToPay,
          isLoading: false,
          isError: true,
        },
      };

    case GET_CREDIT_CARDS:
      return {
        ...state,
        creditCards: [],
        getCreditCards: {
          isLoading: true,
          isError: false,
        },
      };
    case success(GET_CREDIT_CARDS):
      return {
        ...state,
        creditCards: action.payload.data,
        selectedCard: action?.payload?.data?.length > 0 ? action.payload.data[0] : null,
        getCreditCards: {
          isLoading: false,
          isError: false,
        },
      };
    case error(GET_CREDIT_CARDS):
      return {
        ...state,
        getCreditCards: {
          isLoading: false,
          isError: true,
        },
      };

    case ADD_CREDIT_CARD:
      return {
        ...state,
        addCreditCard: {
          isLoading: true,
          isError: false,
        },
      };
    case success(ADD_CREDIT_CARD):
      return {
        ...state,
        addCreditCard: {
          isLoading: false,
          isError: false,
        },
      };
    case error(ADD_CREDIT_CARD):
      return {
        ...state,
        addCreditCard: {
          isLoading: false,
          isError: true,
        },
      };

    case DELETE_CREDIT_CARD:
      return {
        ...state,
        deleteCreditCard: {
          isProcessing: true,
          isError: false,
        },
      };
    case success(DELETE_CREDIT_CARD): {
      return {
        ...state,
        deleteCreditCard: {
          isProcessing: false,
          isError: false,
        },
      };
    }
    case error(DELETE_CREDIT_CARD):
      return {
        ...state,
        deleteCreditCard: {
          isProcessing: false,
          isError: true,
        },
      };
    case SET_SELECTED_CARD:
      return {
        ...state,
        selectedCard: action.payload,
      };
    case AUTHORIZE_CREDIT_CARD:
      return {
        ...state,
        authorizeCreditCard: {
          isLoading: true,
          isError: false,
        },
      };
    case success(AUTHORIZE_CREDIT_CARD):
      return {
        ...state,
        authorizeCreditCard: {
          isLoading: false,
          isError: false,
        },
      };
    case error(AUTHORIZE_CREDIT_CARD): {
      return {
        ...state,
        authorizeCreditCard: {
          isLoading: false,
          isError: true,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
