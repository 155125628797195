import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Modal, Row, Select } from 'antd';
import Enumerable from 'linq';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { productsActions } from '../../../../store';
import { useProductProblemMappingContext } from './ProductProblemMappingContext';
import { productProblemActions } from '../../../../store';
import { ProductProblemForm } from '../styled.js';

const ProductProblemMappingModal = ({ hidden, toggle }) => {
  const formRef = useRef();
  const formRef2 = useRef();
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    addMode,
    deleteMode,
    mappingMode,
    currentMapping,
    productProblems,
    loadingProductProblems,
    productProblemMappings,
    addingProductProblemMapping,
  } = useProductProblemMappingContext();

  const [productProblemsOptions, setProductProblemsOptions] = useState([]);
  const [mappedIds, setMappedIds] = useState([]);

  useEffect(() => {
    if (productProblems && productProblems.length > 0) {
      setProductProblemsOptions(createProductProblemsOptions());
    }
  }, [productProblems, mappedIds, productProblemMappings]);

  useEffect(() => {
    if (productProblemMappings && productProblemMappings.length > 0) {
      let problemIds = Enumerable.from(productProblemMappings)
        .select(x => x.id || x.problemId)
        .toArray();

      setMappedIds(problemIds);
    } else {
      setMappedIds([]);
    }
  }, [productProblemMappings]);

  const createProductProblemsOptions = () => {
    let productProblemsOptions = [];

    if (productProblems && productProblems.length > 0) {
      productProblemsOptions = productProblems.map((productProblems, index) => {
        return {
          key: 'productProblem-' + index,
          label: productProblems.description,
          value: productProblems.id,
          disabled: mappedIds.includes(productProblems.id),
        };
      });
    }

    return productProblemsOptions;
  };

  const checkForSpecialCharacters = formVal => {
    let isValid = true;
    const regex = /([-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/])/gm; // eslint-disable-line no-useless-escape
    if (regex.test(formVal)) {
      isValid = false;
    }
    return isValid;
  };

  const checkForDuplicate = formVal => {
    let duplicate = false;
    let foundProblem = productProblemsOptions.find(x => x.label?.toLowerCase() === formVal?.toLowerCase());
    if (foundProblem) {
      duplicate = true;
    } else {
      duplicate = false;
    }
    return duplicate;
  };

  const handleAddClick = formVal => {
    if (formVal.addProblemItem && formVal.addProblemItem.length > 0) {
      dispatch(productProblemActions.upsertProductProblem(formVal.id, formVal.addProblemItem))
        .then(payload => {
          toast.success(intl.formatMessage({ id: 'admin.problems.problemUpdated' }), { autoClose: 5000 });
        })
        .catch(() => {
          toast.error(intl.formatMessage({ id: 'admin.problems.problemError' }), { autoClose: 5000 });
        });
    }
  };

  const handleSubmit = formVal => {
    if (formVal.productProblems && formVal.productProblems.length > 0 && currentMapping.sku) {
      dispatch(productsActions.insertBulkProductProblems(formVal.productProblems, currentMapping.sku))
        .then(() => {
          toggle();
          toast.success(
            intl.formatMessage({ id: 'admin.product.productProblemMappings.productProblemModal.createSuccess' })
          );
        })
        .catch(() => {
          toast.error(
            intl.formatMessage({ id: 'admin.product.productProblemMappings.productProblemModal.createError' })
          );
        });
    }
  };

  const addMarkup = mappingMode === addMode && (
    <Form
      layout='vertical'
      onFinish={formVal => {
        handleSubmit(formVal);
      }}
      ref={formRef}
    >
      <Form.Item
        name='productProblems'
        label={intl.formatMessage({
          id: 'admin.product.productProblemMappings.productProblemModal.productProblemLabel',
        })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: 'admin.product.productProblemMappings.productProblemModal.oneOrMore' }),
          },
        ]}
      >
        <Select
          options={productProblemsOptions}
          loading={loadingProductProblems}
          mode={'multiple'}
          filterOption={true}
          optionFilterProp={'label'}
          allowClear
          dropdownRender={menu => (
            <div>
              {menu}
              <Row style={{ paddingTop: '20px' }}>
                <ProductProblemForm
                  name='insertProductProblem'
                  layout='horizontal'
                  onFinish={formVal => {
                    handleAddClick(formVal);
                  }}
                  ref={formRef2}
                >
                  <Divider />
                  <Row>
                    <Col span={17} offset={1}>
                      <Form.Item
                        name='addProblemItem'
                        label={intl.formatMessage({
                          id: 'admin.product.productProblemMappings.productProblemModal.productProblemLabel',
                        })}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: 'admin.problems.descriptionRequired' }),
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value && checkForDuplicate(value)) {
                                return Promise.reject(
                                  new Error(intl.formatMessage({ id: 'admin.problems.noDuplicates' }))
                                );
                              }
                              if (value && !checkForSpecialCharacters(value)) {
                                return Promise.reject(
                                  new Error(intl.formatMessage({ id: 'admin.problems.noSpecialCharacters' }))
                                );
                              }

                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input data-test-id='addProblemInput' allowClear />
                      </Form.Item>
                    </Col>
                    <Col span={3} offset={1}>
                      <Form.Item>
                        <Button
                          data-test-id='addProblemButton'
                          style={{ backgroundColor: 'rgb(219, 1, 28)', color: 'white' }}
                          icon={<PlusOutlined />}
                          onClick={() => {
                            formRef2.current.submit();
                          }}
                        >
                          Add
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </ProductProblemForm>
              </Row>
            </div>
          )}
        ></Select>
      </Form.Item>
    </Form>
  );

  return (
    <Modal
      title={
        mappingMode + intl.formatMessage({ id: 'admin.product.productProblemMappings.productProblemModal.heading' })
      }
      visible={!hidden}
      onCancel={toggle}
      footer={[
        <Button data-test-id='adminProductProblemsMappingCancelButton' key='cancelBtn' secondary onClick={() => toggle()} className='mr-2'>
          <FormattedMessage id='general.cancel' />
        </Button>,
        <Button
          data-test-id='adminProductProblemsMappingAddButton' 
          key='addBtn'
          type={'primary'}
          onClick={() => formRef.current.submit()}
          loading={addingProductProblemMapping}
        >
          {mappingMode}
        </Button>,
      ]}
      destroyOnClose
    >
      {addMarkup}
    </Modal>
  );
};

export default ProductProblemMappingModal;
