import { Col, Text } from '@met/react-components';
import moment from 'moment';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const OrderDetails = ({ submittedAt, serviceOrderNumber, accountNumber, status }) => {
  const intl = useIntl();

  let formattedSubmittedAt = () => {
    return moment(submittedAt)
      .local()
      .format('MM/DD/YYYY h:mm:ss A');
  };

  return (
    <Fragment>
      <Col xs={6} sm md={6}>
        <Text bold>
          <FormattedMessage id='admin.serviceOrder.submittedAtLabel' />
        </Text>
        <Text>{formattedSubmittedAt()}</Text>
      </Col>
      <Col xs={6} sm md={6}>
        <Text bold>
          <FormattedMessage id='admin.serviceOrder.serviceOrderNumberLabel' />
        </Text>
        <Text>{serviceOrderNumber || intl.formatMessage({ id: 'general.na' })}</Text>
      </Col>
      <Col xs={6} sm md={6} className='mt-2 mt-sm-0 mt-md-2'>
        <Text bold>
          <FormattedMessage id='admin.serviceOrder.accountNumberLabel' />
        </Text>
        <Text>{accountNumber || intl.formatMessage({ id: 'general.na' })}</Text>
      </Col>
      <Col xs={6} sm md={6} className='mt-2 mt-sm-0 mt-md-2'>
        <Text bold>
          <FormattedMessage id='admin.serviceOrder.statusLabel' />
        </Text>
        <Text>
          {status
            ? intl.formatMessage({ id: 'admin.serviceOrders.statuses.' + status })
            : intl.formatMessage({ id: 'general.na' })}
        </Text>
      </Col>
    </Fragment>
  );
};

export default OrderDetails;
