import { Card, Radio, Space } from 'antd';
import styled from 'styled-components';

export const RadioContainer = styled(Radio)`
  padding: 15px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #abacad;
  border-radius: 2px;
  width: 100%;

  .ant-radio-wrapper {
    width: 100%;
  }
`;

export const CreditCardSpace = styled(Space)`
  width: 90%;
  .ant-radio-wrapper-checked {
    border: 3px solid #076ae6;
  }

  .ant-radio-wrapper-disabled {
    border: 1px solid #abacad;
  }
`;

export const InfoCardContainer = styled(Card)`
  background: #e6f7ff;
  border: 1px solid #91d5ff;
`;

export const InnerInfoCardContainer = styled(Card)`
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #abacad;
`;

export const Space100 = styled(Space)`
  width: 100%;
`;
