import { Button, Col, Collapsible, Icon, ListGroup, ListGroupItem, Row, Text } from '@met/react-components';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { proofOfPurchaseActions } from '../../../store';
import { Image, ImageWrapper, RepairItemDetail, RepairItemsRow } from './styled';

const RepairItemsSummary = ({ repairItems, handleEditClick, handleOpenDeleteModal, handleViewDetailsClick }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const downloadProofOfPurchase = hashedServiceOrderId => {
    dispatch(proofOfPurchaseActions.downloadProofOfPurchase(hashedServiceOrderId))
      .then(d => {
        window.open(d.data.data.url);
      })
      .catch(err => {
        alert(err.message);
        toast.error(intl.formatMessage({ id: 'dashboard.exportErrorMessage' }), {
          autoClose: false,
        });
      });
  };

  let getProofOfPurchaseIncludedLine = repairItem => {
    if (repairItem.proofOfPurchase) return <Text>{repairItem.proofOfPurchase.name}</Text>;
    if (repairItem.id)
      return (
        <Button data-test-id='repairItemDownloadProofOfPurchaseButton' onClick={() => downloadProofOfPurchase(repairItem.id)} icon='file-download'>
          <FormattedMessage id='general.uploaded' />
        </Button>
      );
    return <FormattedMessage id='repairProcess.repairItemsSummary.proofOfPurchaseNotIncluded' />;
  };

  const repairItemsList =
    repairItems &&
    repairItems.map((repairItem, index) => {
      let editMarkup = handleEditClick && (
        <div>
          <Icon
            data-test-id='repairItemListIcon'
            sm
            type='pencil'
            onClick={() => handleEditClick(repairItem.guid)}
            className='mr-3 hidden-xs visible-sm'
          />
          <Button data-test-id='repairItemGeneralEditButton' primary outline block onClick={() => handleEditClick(repairItem.guid)} className='mr-3 hidden-sm'>
            <FormattedMessage id='general.edit' />
          </Button>
        </div>
      );

      let deleteMarkup = handleOpenDeleteModal && (
        <div>
          <Icon
            data-test-id='repairItemGeneralDeleteIcon'
            sm
            type='trash'
            onClick={() => handleOpenDeleteModal(repairItem.guid)}
            className='hidden-xs visible-sm'
          />
          <Button data-test-id='repairItemGeneralDeleteButton' primary outline block onClick={() => handleOpenDeleteModal(repairItem.guid)} className='hidden-sm'>
            <FormattedMessage id='general.delete' />
          </Button>
        </div>
      );

      let detailsMarkup = handleViewDetailsClick && (
        <div>
          <Button data-test-id='repairItemViewDetailsButton' primary outline block onClick={() => handleViewDetailsClick(repairItem.id)}>
            <FormattedMessage id='orderSummary.viewDetails' />
          </Button>
        </div>
      );

      let buttonMarkup = (
        <Col xs={12} sm='auto' middle='xs' center='xs' flexDirection='row' className='mt-xs-2 mt-sm-0'>
          {editMarkup}
          {deleteMarkup}
          {detailsMarkup}
        </Col>
      );

      let collapsibleLabel = (
        <RepairItemsRow>
          <Col xs={12} sm={2} md={1} middle='xs' center='xs'>
            <ImageWrapper>
              <Image src={`/api/v1/products/${repairItem.sku}/image`} alt={repairItem.sku} />
            </ImageWrapper>
          </Col>
          <Col xs={12} sm middle='xs' className='mb-1 mb-md-0'>
            <Text block sm>
              {repairItem.sku}
            </Text>
            <Text block bold>
              {repairItem.description}
            </Text>
          </Col>
          <Col middle='xs' className='hidden-xs visible-md'>
            <Text block sm>
              <FormattedMessage id='repairProcess.repairItemsSummary.serialNumberLabel1' />
            </Text>
            <Text block>
              {repairItem.serialNumber
                ? repairItem.serialNumber
                : intl.formatMessage({ id: 'repairProcess.repairItemsSummary.serialNumberNotProvided' })}
            </Text>
          </Col>
          {buttonMarkup}
        </RepairItemsRow>
      );

      let batteryIncludedMarkup = repairItem.batteryIncluded !== '' && (
        <RepairItemDetail className='ml-3'>
          <Text bold>
            <FormattedMessage id='repairProcess.repairItemsSummary.batteryIncludedLabel' />
          </Text>
          <Text data-test-id='battery-included'>{repairItem.batteryIncluded}</Text>
        </RepairItemDetail>
      );

      let proofOfPurchaseMarkup = repairItem.proofOfPurchaseIncluded ? (
        <Text>
          <FormattedMessage id='repairProcess.repairItemsSummary.proofOfPurchaseIncluded' />
        </Text>
      ) : repairItem.proofOfPurchaseBlob ? (
        getProofOfPurchaseIncludedLine(repairItem)
      ) : (
        <Text>
          <FormattedMessage id='repairProcess.repairItemsSummary.proofOfPurchaseNotIncluded' />
        </Text>
      );

      let problemsMarkup =
        repairItem.selectedProblemIds &&
        repairItem.selectedProblemIds
          .map((id, index) => {
            return intl.formatMessage({ id: `productProblems.${id}` });
          })
          .join(', ');

      let inclusionsMarkup =
        repairItem.selectedIncludedIds &&
        repairItem.selectedIncludedIds
          .map((id, index) => {
            return intl.formatMessage({ id: `productInclusions.${id}` });
          })
          .join(', ');

      return (
        <ListGroupItem key={`repair-item-${index}`} data-test-id={`repair-item-${index}`}>
          <Collapsible trigger={collapsibleLabel} open={index === 0}>
            <RepairItemDetail className='hidden-md'>
              <Text bold>
                <FormattedMessage id='repairProcess.repairItemsSummary.serialNumberLabel2' />
              </Text>
              <Text>
                {repairItem.serialNumber
                  ? repairItem.serialNumber
                  : intl.formatMessage({ id: 'confirmation.serialNumberNotProvided' })}
              </Text>
            </RepairItemDetail>
            <RepairItemDetail>
              <Text bold>
                <FormattedMessage id='repairProcess.repairItemsSummary.tagNumberLabel' />
              </Text>
              <Text>{repairItem.tagNumber}</Text>
            </RepairItemDetail>
            <RepairItemDetail>
              <Text bold>
                <FormattedMessage id='repairProcess.repairItemsSummary.proofOfPurchaseLabel' />
              </Text>
              {proofOfPurchaseMarkup}
            </RepairItemDetail>
            <RepairItemDetail>
              <Text bold>
                <FormattedMessage id='repairProcess.repairItemsSummary.problemLabel' />
              </Text>
              <Text data-test-id='problems'>{problemsMarkup}</Text>
            </RepairItemDetail>
            <RepairItemDetail>
              <Text bold>
                <FormattedMessage id='repairProcess.repairItemsSummary.includedLabel' />
              </Text>
              <Text data-test-id='included'>{inclusionsMarkup}</Text>
            </RepairItemDetail>
            {batteryIncludedMarkup}
          </Collapsible>
        </ListGroupItem>
      );
    });

  const noRepairItemsMarkup =
    !repairItems ||
    (repairItems.length === 0 && (
      <ListGroupItem>
        <Row>
          <Col center='xs' className='mt-2 mb-2'>
            <Text md>
              <FormattedMessage id='repairItems.noRepairItems' />
            </Text>
          </Col>
        </Row>
      </ListGroupItem>
    ));

  return (
    <ListGroup>
      {repairItemsList}
      {noRepairItemsMarkup}
    </ListGroup>
  );
};

RepairItemsSummary.propTypes = {
  repairItems: PropTypes.array,
  handleEditClick: PropTypes.func,
  handleOpenDeleteModal: PropTypes.func,
  handleViewDetailsClick: PropTypes.func,
};

export default RepairItemsSummary;
