import { Col, Row, Typography } from 'antd';
import React from 'react';
import { StyledIcon, Wrapper } from './styled';

const PageError = ({ title, subtitle, children }) => {
  const { Text, Title } = Typography;
  return (
    <Wrapper>
      <Row justify={'center'}>
        <Col style={{ textAlign: 'center' }}>
          <StyledIcon />
          <Title level={3}>{title}</Title>
          <Text style={{ fontSize: '20px' }}>{subtitle}</Text>
          {children}
        </Col>
      </Row>
    </Wrapper>
  );
};

export default PageError;
