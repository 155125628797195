import { GET_PRODUCT_PROBLEMS, UPSERT_PRODUCT_PROBLEM } from '../types';

import { error, success } from '../utils';

export let initialState = {
  getProductProblems: {
    isLoading: false,
    isError: false,
    productProblems: [],
  },
  upsertProductProblem: {
    isLoading: false,
    isError: false,
  },
};

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_PRODUCT_PROBLEMS:
      return {
        ...state,
        getProductProblems: {
          isLoading: true,
          isError: false,
          productProblems: [],
        },
      };
    case success(GET_PRODUCT_PROBLEMS):
      return {
        ...state,
        getProductProblems: {
          isLoading: false,
          isError: false,
          productProblems: action.payload.data || [],
        },
      };
    case error(GET_PRODUCT_PROBLEMS):
      return {
        ...state,
        getProductProblems: {
          isLoading: false,
          isError: true,
          productProblems: [],
        },
      };
    case UPSERT_PRODUCT_PROBLEM:
      return {
        ...state,
        upsertProductProblem: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPSERT_PRODUCT_PROBLEM):
      let productProblems = state.getProductProblems.productProblems.filter(x => x.id !== action.payload.data.id);
      return {
        ...state,
        upsertProductProblem: {
          isLoading: false,
          isError: false,
        },
        getProductProblems: {
          ...state.getProductProblems,
          productProblems: [...productProblems, action.payload.data],
        },
      };
    case error(UPSERT_PRODUCT_PROBLEM):
      return {
        ...state,
        upsertProductProblem: {
          isLoading: false,
          isError: true,
        },
      };
    default:
      return state;
  }
};

export default reducer;
