import { PARSE_SERIAL_NUMBER } from '../types';
import { error, success } from '../utils';

export let initialState = {
  parseSerialNumber: {
    isLoading: false,
    isError: false,
  },
};

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case PARSE_SERIAL_NUMBER:
      return {
        ...state,
        parseSerialNumber: {
          isLoading: true,
          isError: false,
        },
      };
    case success(PARSE_SERIAL_NUMBER):
      return {
        ...state,
        parseSerialNumber: {
          isLoading: false,
          isError: false,
        },
      };
    case error(PARSE_SERIAL_NUMBER):
      return {
        ...state,
        parseSerialNumber: {
          isLoading: false,
          isError: true,
        },
      };
    default:
      return state;
  }
};

export default reducer;
