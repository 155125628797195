import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Layout, Row, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MoreInformation } from '../../../../components';

const { Content } = Layout;
const { Title } = Typography;

const AuthenticatedContent = ({
  termsConditions,
  handleAddNewClick,
  handleTermsConditionsCheck,
  handleDownloadTermsConditions,
}) => {
  return (
    <Content style={{ maxWidth: '1200px', padding: '0 2rem', margin: '2rem auto' }}>
      <Row justify='center'>
        <Col xs={24} md={18} lg={12}>
          <Space size='large' direction='vertical'>
            <Row justify='center'>
              <Col style={{ textAlign: 'center' }}>
                <Title level={3}>
                  <FormattedMessage id='home.authenticatedContent.startOrderHeading' />
                </Title>
                <Checkbox
                  data-test-id='termsAndConditionsCheckbox'
                  id='termsConditionsCheckbox'
                  checked={termsConditions}
                  disabled={termsConditions}
                  onChange={() => handleTermsConditionsCheck(true)}
                >
                  <FormattedMessage id='home.authenticatedContent.acceptThe' />
                  <Button
                    data-test-id='downloadTermsAndConditionsButton'
                    id='downloadTermsConditionsButton'
                    type='link'
                    onClick={() => handleDownloadTermsConditions()}
                    style={{ padding: '0', margin: '0' }}
                  >
                    <FormattedMessage id='home.authenticatedContent.termsAndConditionsLabel' />
                  </Button>
                </Checkbox>
              </Col>
            </Row>
            <Row justify='center'>
              <Col>
                <Button
                  data-test-id='newAuthenticatedLandingPageAddToolButton'
                  id='addNewToolButton'
                  type='primary'
                  size='large'
                  icon={<PlusOutlined />}
                  onClick={() => handleAddNewClick()}
                >
                  <span>
                    <FormattedMessage id='home.authenticatedContent.addToolLabel' />
                  </span>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <MoreInformation handleDownloadTermsConditions={handleDownloadTermsConditions} />
              </Col>
            </Row>
          </Space>
        </Col>
      </Row>
    </Content>
  );
};

AuthenticatedContent.propTypes = {
  termsConditions: PropTypes.bool,
  handleAddNewClick: PropTypes.func,
  handleTermsConditionsCheck: PropTypes.func,
  handleDownloadTermsConditions: PropTypes.func,
};

export default AuthenticatedContent;
