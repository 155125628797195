import {
  Button,
  Card,
  CardBody,
  Col,
  Grid,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Row,
  useToggle,
} from '@met/react-components';
import Enumerable from 'linq';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { AddressModal } from '../../../components/AddressModal';
import { UserMetadataModal } from '../../../components/UserMetadataModal';
import { handleNavigateAway } from '../../../helpers/navigation';
import { useAddresses, useLocale, useFeatureToggles } from '../../../hooks';
import { countriesActions, statesActions, usersActions } from '../../../store';
import CaShippingRestrictionWarning from '../components/CaShippingRestrictionWarning';
import UsShippingRestrictionWarning from '../components/UsShippingRestrictionWarning';
import RepairItemsList from '../components/RepairItemsList';
import { useRepairProcessContext } from '../RepairProcessContext';
import Packages from './components/Packages';
import ShippingInfo from './components/ShippingInfo';
import { ShippingProvider, useShippingContext } from './ShippingContext';

const Shipping = props => {
  return (
    <ShippingProvider>
      <ShippingContent {...props} />
    </ShippingProvider>
  );
};

const isAddressCanadian = address => {
  if (address.countryCode) {
    return address.countryCode.toLowerCase() === 'can' || address.countryCode.toLowerCase() === 'ca';
  } else {
    return false;
  }
};

export const ShippingContent = () => {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { defaultUserAddress } = useAddresses();
  const { batteryRecycle } = useFeatureToggles();

  const {
    validate,
    setShippingAddress,
    shippingAddress,
    getShippingInfo,
    setShippingInfo,
    shippingAddressIsRestricted,
    shippingAddressInvalid,
    setPackagesCount,
    packagesCount,
  } = useShippingContext();

  const { isEnLocale } = useLocale();

  const { repairItems, shipping, setShipping } = useRepairProcessContext();

  const [currentAddress, setCurrentAddress] = useState({});

  const [isOnlyRecycle, setIsOnlyRecycle] = useState(false);

  const [showAddressModal, toggleAddressModal] = useToggle(false);
  const [showUserMetadataModal, toggleUserMetadataModal] = useToggle(false);
  const [showShippingRestrictedModal, toggleShippingRestrictedModal] = useToggle(false);

  useEffect(() => {
    if (shipping.shippingAddress) {
      setShippingInfo(shipping);
    }
  }, []);
  useEffect(() => {
    if (repairItems.length === 0) {
      history.replace('/repairprocess/addanother');
    }
    if (batteryRecycle) {
      if (repairItems.every(x => x.isRecycled)) {
        setIsOnlyRecycle(true);
        setPackagesCount(0);
      } else {
        setIsOnlyRecycle(false);
      }
    }
  }, [repairItems]);

  useEffect(() => {
    getUser();

    dispatch(countriesActions.getCountries()).catch(() => {
      toast.error(intl.formatMessage({ id: 'address.loadingCountriesErrorMessage' }), { autoClose: false });
    });
    dispatch(statesActions.getStates()).catch(() => {
      toast.error(intl.formatMessage({ id: 'address.loadingStatesErrorMessage' }), { autoClose: false });
    });
  }, [dispatch]);

  useEffect(() => {
    if (defaultUserAddress && !currentAddress.id && !shipping.shippingAddress && !shippingAddress.id) {
      setShippingAddress(defaultUserAddress);
      setCurrentAddress(defaultUserAddress);
    }
  }, [defaultUserAddress]);

  useEffect(() => {
    if (shippingAddress && isAddressCanadian(shippingAddress)) {
      if (packagesCount > 1) {
        setPackagesCount(1);
      }

      if (isEnLocale()) {
        toast.error(intl.formatMessage({ id: 'repairDetails.caShippingRestrictionToast' }));
      }
    }
  }, [shippingAddress]);

  const getUser = () => {
    return dispatch(usersActions.getCurrentUser()).catch(() => {
      toast.error(intl.formatMessage({ id: 'profile.loadingUserErrorMessage' }), { autoClose: false });
    });
  };

  const isUsLocale = isEnLocale();

  const handleSubmitClick = () => {
    let batteryIncluded =
      Enumerable.from(repairItems).firstOrDefault(x => x.batteryIncluded !== '') ||
      Enumerable.from(repairItems).firstOrDefault(x => x.isBattery);

    if (validate(batteryIncluded)) {
      setShipping(getShippingInfo());
      history.push('/repairprocess/confirmation');
    } else {
      if (batteryIncluded && shippingAddressIsRestricted) {
        toggleShippingRestrictedModal();
      }

      toast.error(intl.formatMessage({ id: 'shipping.pleaseCorrectLabel' }), { autoClose: 5000 });
    }
  };

  const handleBackClick = () => {
    history.push('/repairprocess/addanother');
  };

  const handleEditAddressClick = address => {
    handleClearAddressValidation();
    setCurrentAddress(address);
    toggleAddressModal();
  };

  const handleAddAddressClick = () => {
    handleClearAddressValidation();
    setCurrentAddress({});
    toggleAddressModal();
  };

  const handleClearAddressValidation = () => {
    dispatch(usersActions.clearAddressValidation());
  };
  const handleConfirmEditClick = (
    id,
    defaultShippingAddress,
    addressLine1,
    addressLine2,
    city,
    state,
    countryCode,
    postalCode,
    name,
    isResidential,
    latitude,
    longitude
  ) => {
    dispatch(
      usersActions.upsertUserAddress(
        id,
        defaultShippingAddress,
        addressLine1,
        addressLine2,
        city,
        state,
        countryCode,
        postalCode,
        name,
        isResidential,
        latitude,
        longitude
      )
    )
      .then(payload => {
        getUser().then(() => {
          toast.success(intl.formatMessage({ id: 'address.addressUpdated' }), { autoClose: 5000 });
          handleClearAddressValidation();
          toggleAddressModal();

          if (payload.data.data) {
            setShippingAddress(payload.data.data);
          }
        });
      })
      .catch(error => {
        toast.error(intl.formatMessage({ id: 'address.saveChangesError' }), { autoClose: 5000 });
      });
  };

  const shippingTitle = (
    <Row>
      <Col>
        <h1>
          <FormattedMessage id='shipping.shippingTitle' />
        </h1>
      </Col>
    </Row>
  );

  const packagesMarkup = batteryRecycle ? isOnlyRecycle ? <> </> : <Packages /> : isUsLocale && <Packages />;

  const restrictionMarkup = isUsLocale ? (
    <Card>
      <UsShippingRestrictionWarning />
    </Card>
  ) : (
    <Card>
      <CaShippingRestrictionWarning />
    </Card>
  );

  const shippingRestrictedModalMarkup = shippingAddressIsRestricted && shippingAddressInvalid && (
    <Modal lg centered hidden={!showShippingRestrictedModal}>
      <ModalContent data-test-id='shipping-restricted-modal'>
        <ModalHeader>
          <FormattedMessage id='shipping.shippingRestrictedModal.title' />
        </ModalHeader>
        <ModalBody>
          <p>
            <FormattedMessage id='shipping.shippingRestrictedModal.shippingProhibited' />
          </p>
          <p>
            <FormattedMessage
              id='shipping.shippingRestrictedModal.serviceCenter'
              values={{
                link: (
                  <a
                    data-test-id='shippingRestrictionModal'
                    href='https://www.milwaukeetool.com/Support/Find-A-Service-Center'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    https://www.milwaukeetool.com/Support/Find-A-Service-Center
                  </a>
                ),
              }}
            />
          </p>
        </ModalBody>
        <ModalFooter>
          <Button data-test-id='toggleShippingRestrictionModal' primary onClick={toggleShippingRestrictedModal}>
            <FormattedMessage id='shipping.shippingRestrictedModal.understand' />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  return (
    <Fragment>
      <Grid>
        {shippingTitle}
        <Row>
          <Col xs={12} md={8}>
            {packagesMarkup}
            <ShippingInfo
              onEditAddressClick={handleEditAddressClick}
              onAddAddressClick={handleAddAddressClick}
              onEditProfileClick={toggleUserMetadataModal}
            />
          </Col>
          <Col xs={false} md={4}>
            <Card>
              <CardBody>
                <RepairItemsList />
              </CardBody>
            </Card>
            {restrictionMarkup}
          </Col>
        </Row>
        <Row>
          <Col end='xs'>
            <div className='mt-2'>
              <Button data-test-id='backButton' secondary onClick={() => handleBackClick()} className='mr-2'>
                <FormattedMessage id='general.back' />
              </Button>
              <Button
                primary
                onClick={() => handleSubmitClick()}
                data-test-id='submit-button'
                disabled={repairItems.length < 1}
              >
                <FormattedMessage id='general.continue' />
              </Button>
            </div>
          </Col>
        </Row>
      </Grid>
      <AddressModal
        hidden={!showAddressModal}
        toggle={toggleAddressModal}
        address={currentAddress}
        onCancelClick={toggleAddressModal}
        onSaveChangesClick={handleConfirmEditClick}
      />
      {shippingRestrictedModalMarkup}
      <Prompt
        message={location =>
          handleNavigateAway(
            location,
            repairItems.length > 0,
            intl.formatMessage({ id: 'general.unsavedChangesWarning' })
          )
        }
      />
      <UserMetadataModal hidden={!showUserMetadataModal} toggle={toggleUserMetadataModal} />
    </Fragment>
  );
};

export default Shipping;
