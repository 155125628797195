import { Loader } from '@met/react-components';
import { Breadcrumb, Card, Col, Row, Space, Typography } from 'antd';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import PermissionGatedComponent from '../../../../components/PermissionGatedComponent/PermissionGatedComponent';
import { Permissions } from '../../../../enums';
import { useUser } from '../../../../hooks';
import {
  lmrCategoryTypeActions,
  productIncludedActions,
  productProblemActions,
  productsActions,
  serviceCenterActions,
} from '../../../../store';
import {
  ProductIncludedMappings,
  ProductLmrMappings,
  ProductProblemMappings,
  ServiceCenterMappings,
} from '../../components';
import { ProductIncludedMappingProvider } from '../ProductIncludedMapping/ProductIncludedMappingContext';
import { ProductProblemMappingProvider } from '../ProductProblemMappings/ProductProblemMappingContext';
import { ServiceCenterMappingProvider } from '../ServiceCenterMappings/ServiceCenterMappingContext';
import { ProductImage, StyledBreadcrumb, StyledCol } from '../styled';

const { Text } = Typography;

const Product = () => {
  return (
    <ServiceCenterMappingProvider>
      <ProductContent />
    </ServiceCenterMappingProvider>
  );
};

const ProductContent = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const refreshDelay = 8000;

  const { sku } = useParams();

  const product = useSelector(state => state.products.getProduct.product);
  const loadingProduct = useSelector(state => state.products.getProduct.isLoading);

  const [refreshTriggered, setRefreshTriggered] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);

  const refreshInterval = useRef(null);

  const { permissions } = useUser();
  const userPermissions = permissions();

  useEffect(() => {
    return () => {
      clearInterval(refreshInterval.current);
    };
  }, []);

  useEffect(() => {
    if (sku) {
      getProduct();
      getServiceCenters();
      getProductProblems();
      getProductIncluded();
      getLmrCategoryTypes();
    }
  }, [sku]);

  useEffect(() => {
    if (product && product.sku === sku && product.lmrMappingsUpdatedAt) {
      if (product && lastUpdated && lastUpdated >= product.lmrMappingsUpdatedAt) {
        // LMR data unchanged
        toast.info(intl.formatMessage({ id: 'admin.product.refreshLmrMappingsUnchanged' }));
      } else if (product && lastUpdated && lastUpdated < product.lmrMappingsUpdatedAt) {
        // LMR data updated
        toast.success(intl.formatMessage({ id: 'admin.product.refreshLmrMappingsSuccess' }));
      }

      setLastUpdated(product.lmrMappingsUpdatedAt);
    }
  }, [product]);

  useEffect(() => {
    if (refreshTriggered) {
      // Fire and forget request to update LMR data
      dispatch(productsActions.loadSkuLmrMappings(sku))
        .then(() => {
          // Get updated LMR data after delay
          setTimeout(() => {
            setRefreshTriggered(false);

            dispatch(productsActions.getLmrMappingsBySku(sku)).catch(() => {
              toast.error(intl.formatMessage({ id: 'admin.product.refreshLmrMappingsError' }));
            });
          }, refreshDelay);
        })
        .catch(() => {
          setRefreshTriggered(false);

          toast.error(intl.formatMessage({ id: 'admin.product.refreshLmrMappingsError' }));
        });
    }
  }, [refreshTriggered]);

  const getProduct = () => {
    dispatch(productsActions.getProductForAdmin(sku)).catch(() => {
      toast.error(intl.formatMessage({ id: 'admin.product.loadingError' }));
    });
  };

  const getServiceCenters = () => {
    if (userPermissions[Permissions.PRODUCTS_STATES]) {
      dispatch(serviceCenterActions.getServiceCenters()).catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.product.loadingError' }));
      });
    }
  };

  const getProductIncluded = () => {
    if (userPermissions[Permissions.PRODUCTS_INCLUSIONS]) {
      dispatch(productIncludedActions.getProductInclusions()).catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.product.loadingError' }));
      });
    }
  };

  const handleAddAllServiceCenters = () => {
    if (userPermissions[Permissions.PRODUCTS_STATES]) {
      dispatch(productsActions.upsertAllServiceMappingsForSku(sku)).catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.product.loadingError' }));
      });
    }
  };

  const getLmrCategoryTypes = () => {
    dispatch(lmrCategoryTypeActions.getLmrCategoryTypes()).catch(() => {
      toast.error(intl.formatMessage({ id: 'admin.product.loadingError' }));
    });
  };

  const getProductProblems = () => {
    if (userPermissions[Permissions.PRODUCTS_PROBLEMS]) {
      dispatch(productProblemActions.getProductProblems()).catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.product.loadingError' }));
      });
    }
  };

  if (!product || loadingProduct) {
    return <Loader type='pulse' />;
  }

  const lmrMappingsMarkup = (
    <PermissionGatedComponent permissionRequired={Permissions.PRODUCTS_LMR}>
      <Col span={24}>
        <ProductLmrMappings
          product={product}
          lastUpdatedFormatted={moment(lastUpdated)
            .utc()
            .format('MM/DD/YYYY hh:mm:ss A')}
          refreshTriggered={refreshTriggered}
          handleReloadLmrClick={() => setRefreshTriggered(true)}
        />
      </Col>
    </PermissionGatedComponent>
  );

  const stateMappingMarkup = (
    <PermissionGatedComponent permissionRequired={Permissions.PRODUCTS_STATES}>
      <Col span={24}>
        <ServiceCenterMappingProvider>
          <ServiceCenterMappings sku={sku} handleAddAll={handleAddAllServiceCenters} />
        </ServiceCenterMappingProvider>
      </Col>
    </PermissionGatedComponent>
  );

  const problemMappingMarkup = (
    <PermissionGatedComponent permissionRequired={Permissions.PRODUCTS_PROBLEMS}>
      <Col span={24}>
        <ProductProblemMappingProvider>
          <ProductProblemMappings sku={sku} />
        </ProductProblemMappingProvider>
      </Col>
    </PermissionGatedComponent>
  );

  const inclusionMappingMarkup = (
    <PermissionGatedComponent permissionRequired={Permissions.PRODUCTS_INCLUSIONS}>
      <Col span={24}>
        <ProductIncludedMappingProvider>
          <ProductIncludedMappings sku={sku} />
        </ProductIncludedMappingProvider>
      </Col>
    </PermissionGatedComponent>
  );

  return (
    <Fragment>
      <Row>
        <Col>
          <h1>
            <FormattedMessage id='admin.heading' />
          </h1>
        </Col>
      </Row>
      <Row>
        <Space size='middle' direction='vertical' style={{ width: '100%' }}>
          <Col span={24}>
            <StyledBreadcrumb>
              <Breadcrumb.Item data-test-id='adminBreadcrumb'>
                <a data-test-id='adminProductsTabBreadcrumbText' style={{ color: 'rgb(220,1,28)' }} href={'/admin/products'}>
                  Products Admin
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{sku}</Breadcrumb.Item>
            </StyledBreadcrumb>
          </Col>
          <Col span={24}>
            <Card>
              <Row>
                <Col xs={12} md={2}>
                  <div>
                    <ProductImage src={product.image} alt={product.sku} />
                  </div>
                </Col>
                <StyledCol span={22}>
                  <Row>
                    <Col span={22}>
                      <Text>{product.skuAlias}</Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={22}>
                      <Text level={4} strong>
                        {product.description}
                      </Text>
                    </Col>
                  </Row>
                </StyledCol>
              </Row>
            </Card>
          </Col>
          {lmrMappingsMarkup}
          {stateMappingMarkup}
          {problemMappingMarkup}
          {inclusionMappingMarkup}
        </Space>
      </Row>
    </Fragment>
  );
};

export default Product;
