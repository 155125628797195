import Enumerable from 'linq';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generateGuid } from '../../../helpers/guid';
import { calculateWarrantyEligibility } from '../../../helpers/calculateWarrantyEligibility';

const RepairItemsContext = createContext({
  guid: '',
  sku: '',
  setSku: () => {},
  loadingProduct: false,
  product: {},
  isServiceEligible: null,
  loadingBatteries: false,
  batteries: [],
  uploadingProofOfPurchase: false,
  serialNumber: '',
  setSerialNumber: () => {},
  serialNumberInvalid: false,
  tagNumber: '',
  setTagNumber: () => {},
  serialNumberUnreadable: false,
  setSerialNumberUnreadable: () => {},
  manufactureDate: null,
  setManufactureDate: () => {},
  purchaseDate: null,
  setPurchaseDate: () => {},
  underWarranty: null,
  setUnderWarranty: () => {},
  underWarrantyInvalid: false,
  proofOfPurchase: null,
  setProofOfPurchase: () => {},
  proofOfPurchaseToBeUploaded: false,
  setProofOfPurchaseToBeUploaded: () => {},
  proofOfPurchaseIncluded: false,
  setProofOfPurchaseIncluded: () => {},
  proofOfPurchaseNotIncluded: false,
  setProofOfPurchaseNotIncluded: () => {},
  proofOfPurchaseInvalid: false,
  setProofOfPurchaseInvalid: () => {},
  proofOfPurchaseBlob: '',
  setProofOfPurchaseBlob: () => {},
  problems: [],
  setProblems: () => {},
  selectedProblemIds: [],
  setSelectedProblemIds: () => {},
  hasOtherProblem: false,
  setHasOtherProblem: () => {},
  otherProblem: '',
  setOtherProblem: () => {},
  otherProblemInvalid: false,
  setOtherProblemInvalid: () => {},
  problemsInvalid: false,
  included: [],
  setIncluded: () => {},
  selectedIncludedIds: [],
  setSelectedIncludedIds: () => {},
  hasOtherIncluded: false,
  setHasOtherIncluded: () => {},
  otherIncluded: '',
  setOtherIncluded: () => {},
  otherIncludedInvalid: false,
  setOtherIncludedInvalid: () => {},
  hasBatteryIncluded: false,
  setHasBatteryIncluded: () => {},
  batteryIncluded: false,
  setBatteryIncluded: () => {},
  batteryIncludedInvalid: false,
  setBatteryIncludedInvalid: () => {},
  includedInvalid: false,
  validate: () => {},
  getRepairItem: () => {},
  setRepairItem: () => {},
  userTriedSubmit: false,
  setUserTriedSubmit: () => {},
  shouldScroll: false,
  setShouldScroll: () => {},
  unsavedChanges: false,
  setUnsavedChanges: () => {},
  productBatteryDetails: {},
  warrantyEvaluation: null,
});

const useRepairItemsContext = () => useContext(RepairItemsContext);

const RepairItemsProvider = ({ children }) => {
  const proofOfPurchaseIncludedValue = 'Proof of Purchase';
  const oneOrMoreBatteriesValue = 'One or More Batteries';

  const loadingProduct = useSelector(state => state.products.getProduct.isLoading);
  const product = useSelector(state => state.products.getProduct.product);
  const isServiceEligible = useSelector(state => state.products.getProduct.isServiceEligible);
  const loadingBatteries = useSelector(state => state.batteries.getBatteries.isLoading);
  const batteries = useSelector(state => state.batteries.getBatteries.batteries);
  const productBatteryDetails = useSelector(state => state.batteries.selectedBattery.battery);
  const uploadingProofOfPurchase = useSelector(state => state.proofOfPurchase.uploadProofOfPurchase.isLoading);

  const [guid, setGuid] = useState('');
  const [sku, setSku] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [serialNumberInvalid, setSerialNumberInvalid] = useState(false);
  const [serialNumberUnreadable, setSerialNumberUnreadable] = useState(false);
  const [manufactureDate, setManufactureDate] = useState(null);
  const [purchaseDate, setPurchaseDate] = useState(null);
  const [tagNumber, setTagNumber] = useState('');
  const [proofOfPurchase, setProofOfPurchase] = useState(null);
  const [proofOfPurchaseToBeUploaded, setProofOfPurchaseToBeUploaded] = useState(false);
  const [proofOfPurchaseIncluded, setProofOfPurchaseIncluded] = useState(false);
  const [proofOfPurchaseNotIncluded, setProofOfPurchaseNotIncluded] = useState(false);
  const [proofOfPurchaseInvalid, setProofOfPurchaseInvalid] = useState(false);
  const [proofOfPurchaseBlob, setProofOfPurchaseBlob] = useState('');

  const [problems, setProblems] = useState([]);
  const [selectedProblemIds, setSelectedProblemIds] = useState([]);
  const [hasOtherProblem, setHasOtherProblem] = useState(false);
  const [isRecycled, setIsRecycled] = useState(false);
  const [otherProblem, setOtherProblem] = useState('');
  const [otherProblemInvalid, setOtherProblemInvalid] = useState(false);
  const [problemsInvalid, setProblemsInvalid] = useState(false);
  const [included, setIncluded] = useState([]);
  const [selectedIncludedIds, setSelectedIncludedIds] = useState([]);
  const [hasOtherIncluded, setHasOtherIncluded] = useState(false);
  const [otherIncluded, setOtherIncluded] = useState('');
  const [otherIncludedInvalid, setOtherIncludedInvalid] = useState(false);
  const [hasBatteryIncluded, setHasBatteryIncluded] = useState(false);
  const [batteryIncluded, setBatteryIncluded] = useState('');
  const [includedInvalid, setIncludedInvalid] = useState(false);
  const [userTriedSubmit, setUserTriedSubmit] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [batteryIncludedInvalid, setBatteryIncludedInvalid] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [warrantyEvaluation, setWarrantyEvaluation] = useState(null);

  useEffect(() => {
    if (userTriedSubmit) setSerialNumberInvalid(false);
  }, [serialNumber]);

  useEffect(() => {
    if (serialNumberUnreadable) {
      setSerialNumber('');
      setSerialNumberInvalid(false);
    }
  }, [serialNumberUnreadable]);

  useEffect(() => {
    if (proofOfPurchase && proofOfPurchase.name) {
      setProofOfPurchaseInvalid(false);
    }
  }, [proofOfPurchase]);

  useEffect(() => {
    if (proofOfPurchaseBlob !== '') {
      setProofOfPurchaseInvalid(false);
    }
  }, [proofOfPurchaseBlob]);

  useEffect(() => {
    if (proofOfPurchaseToBeUploaded) {
      setProofOfPurchaseInvalid(false);
      setProofOfPurchaseIncluded(false);
      setProofOfPurchaseNotIncluded(false);
    }
  }, [proofOfPurchaseToBeUploaded]);

  useEffect(() => {
    if (proofOfPurchaseIncluded) {
      setProofOfPurchaseInvalid(false);
      setProofOfPurchaseToBeUploaded(false);
      setProofOfPurchaseNotIncluded(false);
    }
  }, [proofOfPurchaseIncluded]);

  useEffect(() => {
    if (proofOfPurchaseNotIncluded) {
      setProofOfPurchaseInvalid(false);
      setProofOfPurchaseToBeUploaded(false);
      setProofOfPurchaseIncluded(false);
    }
  }, [proofOfPurchaseNotIncluded]);

  useEffect(() => {
    setProblemsInvalid(false);
  }, [problems]);

  useEffect(() => {
    if (userTriedSubmit && hasOtherProblem) {
      setOtherProblemInvalid(false);
    }
  }, [hasOtherProblem, otherProblem]);

  useEffect(() => {
    setIncludedInvalid(false);
  }, [included]);

  useEffect(() => {
    if (userTriedSubmit && hasBatteryIncluded) {
      setBatteryIncludedInvalid(false);
    }
  }, [hasBatteryIncluded, batteryIncluded]);

  useEffect(() => {
    if (userTriedSubmit && hasOtherIncluded) {
      setOtherIncludedInvalid(false);
    }
  }, [hasOtherIncluded, otherIncluded]);

  useEffect(() => {
    if (product && !loadingProduct) {
      setWarrantyEvaluation(calculateWarrantyEligibility(manufactureDate, purchaseDate, product.warrantyPeriod));
    }
  }, [manufactureDate, purchaseDate, product]);

  const validate = () => {
    let isValid = true;

    if (problems.length === 0 && otherProblem === '') {
      setProblemsInvalid(true);
      isValid = false;
    }
    if (hasOtherProblem && otherProblem === '') {
      setOtherProblemInvalid(true);
      isValid = false;
    }
    if (hasOtherIncluded && otherIncluded === '') {
      setOtherIncludedInvalid(true);
      isValid = false;
    }
    if (hasBatteryIncluded && batteryIncluded === '') {
      setBatteryIncludedInvalid(true);
      isValid = false;
    }

    return isValid;
  };

  const getRepairItem = () => {
    let repairItem = {
      guid: guid !== '' ? guid : generateGuid(),
      sku,
      description: product.description,
      image: product.image,
      shippingInstructions: product.shippingInstructions,
      serialNumber,
      serialNumberUnreadable,
      tagNumber,
      underWarranty: 'Warranty',
      proofOfPurchase,
      proofOfPurchaseToBeUploaded,
      proofOfPurchaseIncluded,
      proofOfPurchaseNotIncluded,
      proofOfPurchaseBlob,
      isBattery: product.isBattery,
      problems,
      otherProblem,
      isRecycled,
      mergedProblems: Enumerable.from(problems)
        .select(x => x.value)
        .toArray()
        .join(', '),
      included,
      otherIncluded,
      mergedIncluded: Enumerable.from(included)
        .select(x => x.description)
        .toArray()
        .join(', '),
      batteryIncluded,
      warrantyEvaluation,
      manufactureDate,
      purchaseDate,
      selectedIncludedIds: Enumerable.from(included)
        .select(x => x.includedId)
        .toArray(),
      selectedProblemIds: Enumerable.from(problems)
        .select(x => x.id)
        .toArray(),
      includedOther: '',
      problemDescriptionOther: '',
    };

    if (otherProblem) {
      if (problems.length > 0) {
        repairItem.mergedProblems += ', ';
      }

      repairItem.mergedProblems += otherProblem;
      repairItem.problemDescriptionOther += otherProblem;
    }

    if (otherIncluded) {
      if (included.length > 0) {
        repairItem.mergedIncluded += ', ';

        if (repairItem.includedOther && repairItem.includedOther.length > 0) {
          repairItem.includedOther += ', ';
        }
      }

      repairItem.mergedIncluded += otherIncluded;
      repairItem.includedOther += otherIncluded;
    }

    if (proofOfPurchaseIncluded) {
      if (included.length > 0) {
        repairItem.mergedIncluded += ', ';
        if (repairItem.includedOther && repairItem.includedOther.length > 0) {
          repairItem.includedOther += ', ';
        }
      }

      repairItem.mergedIncluded += proofOfPurchaseIncludedValue;
      repairItem.includedOther += proofOfPurchaseIncludedValue;
    }

    if (batteryIncluded || product.isBattery) {
      if (included.length > 0) {
        repairItem.mergedIncluded += ', ';
        if (repairItem.includedOther && repairItem.includedOther.length > 0) {
          repairItem.includedOther += ', ';
        }
      }
      repairItem.mergedIncluded += oneOrMoreBatteriesValue;
      repairItem.includedOther += oneOrMoreBatteriesValue;
    }
    return repairItem;
  };

  const setRepairItem = repairItem => {
    setGuid(repairItem.guid);
    setSku(repairItem.sku);
    setSerialNumber(repairItem.serialNumber);
    setSerialNumberUnreadable(repairItem.serialNumberUnreadable);
    setTagNumber(repairItem.tagNumber);
    setProofOfPurchase(repairItem.proofOfPurchase);
    setProofOfPurchaseToBeUploaded(repairItem.proofOfPurchaseToBeUploaded);
    setProofOfPurchaseIncluded(repairItem.proofOfPurchaseIncluded);
    setProofOfPurchaseNotIncluded(repairItem.proofOfPurchaseNotIncluded);
    setProofOfPurchaseBlob(repairItem.proofOfPurchaseBlob);
    setProblems(repairItem.problems);
    setOtherProblem(repairItem.otherProblem);
    setIncluded(repairItem.included);
    setOtherIncluded(repairItem.otherIncluded);
    setBatteryIncluded(repairItem.batteryIncluded);
    setIsRecycled(repairItem.isRecycled);
    setWarrantyEvaluation(repairItem.warrantyEvaluation);
    setManufactureDate(repairItem.manufactureDate);
    setPurchaseDate(repairItem.purchaseDate);
    setSelectedIncludedIds(repairItem.selectedIncludedIds);
  };

  return (
    <RepairItemsContext.Provider
      value={{
        sku,
        setSku,
        loadingProduct,
        product,
        isServiceEligible,
        loadingBatteries,
        batteries,
        uploadingProofOfPurchase,
        serialNumber,
        setSerialNumber,
        serialNumberInvalid,
        serialNumberUnreadable,
        setSerialNumberUnreadable,
        manufactureDate,
        setManufactureDate,
        purchaseDate,
        setPurchaseDate,
        tagNumber,
        setTagNumber,
        proofOfPurchase,
        setProofOfPurchase,
        proofOfPurchaseToBeUploaded,
        setProofOfPurchaseToBeUploaded,
        proofOfPurchaseIncluded,
        setProofOfPurchaseIncluded,
        proofOfPurchaseNotIncluded,
        setProofOfPurchaseNotIncluded,
        proofOfPurchaseInvalid,
        setProofOfPurchaseInvalid,
        proofOfPurchaseBlob,
        setProofOfPurchaseBlob,
        problems,
        selectedProblemIds,
        setSelectedProblemIds,
        hasOtherProblem,
        setHasOtherProblem,
        otherProblemInvalid,
        setOtherProblemInvalid,
        setProblems,
        otherProblem,
        setOtherProblem,
        problemsInvalid,
        included,
        setIncluded,
        selectedIncludedIds,
        setSelectedIncludedIds,
        hasOtherIncluded,
        setHasOtherIncluded,
        otherIncluded,
        setOtherIncluded,
        otherIncludedInvalid,
        setOtherIncludedInvalid,
        hasBatteryIncluded,
        setHasBatteryIncluded,
        batteryIncluded,
        setBatteryIncluded,
        batteryIncludedInvalid,
        setBatteryIncludedInvalid,
        includedInvalid,
        validate,
        getRepairItem,
        setRepairItem,
        userTriedSubmit,
        setUserTriedSubmit,
        shouldScroll,
        setShouldScroll,
        unsavedChanges,
        setUnsavedChanges,
        productBatteryDetails,
        setIsRecycled,
        isRecycled,
        warrantyEvaluation,
        setWarrantyEvaluation,
      }}
    >
      {children}
    </RepairItemsContext.Provider>
  );
};

export { RepairItemsContext, useRepairItemsContext, RepairItemsProvider };
