import React from 'react';
import { Collapsible, ListGroupItem, ListGroup } from '@met/react-components';
import { DashboardCollapsibleHeader, DashboardCollapsibleContentList } from './index';

const DashboardListGroup = ({ purchaseOrders }) => {
  let purchaseOrdersMarkup = purchaseOrders.map((purchaseOrder, index) => {
    let headerMarkup = (
      <DashboardCollapsibleHeader
        purchaseOrder={purchaseOrder}
        serviceOrderDate={purchaseOrder.purchaseOrderLines[0].serviceOrderDate}
      />
    );

    return (
      <ListGroupItem key={`purchase-order-${purchaseOrder.id}-${index}`}>
        <Collapsible trigger={headerMarkup}>
          <DashboardCollapsibleContentList purchaseOrderLines={purchaseOrder.purchaseOrderLines} />
        </Collapsible>
      </ListGroupItem>
    );
  });

  return <ListGroup>{purchaseOrdersMarkup}</ListGroup>;
};

export default DashboardListGroup;
