import styled from 'styled-components';
import { Row, Typography } from 'antd';
const { Text } = Typography;

export const SuggestionText = styled(Text)`
  margin-top: 0.25rem;
  color: rgb(23, 162, 184);
  cursor: pointer;

  :hover {
    color: rgb(17, 122, 139);
  }
`;

export const SuggestionRow = styled(Row)`
  margin-top: -25px;
`;
