import { css } from 'styled-components';

const responsive = (breakpoint, spacing) => {
  let multiplier = 0.25;
  let breakpointCss = css``;

  for (let i = 1; i <= 5; i++) {
    breakpointCss += `
      .p-${breakpoint}-${i} {
        padding: ${multiplier * spacing}rem !important;
      }
      .pt-${breakpoint}-${i} {
        padding-top: ${multiplier * spacing}rem !important;
      }
      .pr-${breakpoint}-${i} {
        padding-right: ${multiplier * spacing}rem !important;
      }
      .pb-${breakpoint}-${i} {
        padding-bottom: ${multiplier * spacing}rem !important;
      }
      .pl-${breakpoint}-${i} {
        padding-left: ${multiplier * spacing}rem !important;
      }
    `;

    // Zero out
    breakpointCss += `
    .p-${breakpoint}-0 {
      padding: 0 !important;
    }
    .pt-${breakpoint}-0 {
      padding-top: 0 !important;
    }
    .pr-${breakpoint}-0 {
      padding-right: 0 !important;
    }
    .pb-${breakpoint}-0 {
      padding-bottom: 0 !important;
    }
    .pl-${breakpoint}-0 {
      padding-left: 0 !important;
    }
  `;

    multiplier = multiplier * 2;
  }

  return breakpointCss;
};

export const padding = props => {
  let spacing = props.spacing || 1;
  let multiplier = 0.25;
  let paddingCss = css``;

  for (let i = 1; i <= 5; i++) {
    paddingCss += `
      .p-${i} {
        padding: ${multiplier * spacing}rem !important;
      }
      .pt-${i} {
        padding-top: ${multiplier * spacing}rem !important;
      }
      .pr-${i} {
        padding-right: ${multiplier * spacing}rem !important;
      }
      .pb-${i} {
        padding-bottom: ${multiplier * spacing}rem !important;
      }
      .pl-${i} {
        padding-left: ${multiplier * spacing}rem !important;
      }
    `;

    multiplier = multiplier * 2;
  }

  // Zero out
  paddingCss += `
    .p-0 {
      padding: 0 !important;
    }
    .pt-0 {
      padding-top: 0 !important;
    }
    .pr-0 {
      padding-right: 0 !important;
    }
    .pb-0 {
      padding-bottom: 0 !important;
    }
    .pl-0 {
      padding-left: 0 !important;
    }
  `;

  props.theme.grid.dimensions.map(d => {
    paddingCss += `
      @media screen and (min-width: ${props.theme.grid.breakpoints[d]}em) {
        ${responsive(d, spacing)}
      }
    `;
  });

  return paddingCss;
};

export default padding;