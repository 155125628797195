import createAxiosAction from '../createAxiosAction';
import { SYNC_PRODUCT_INCLUSIONS_TO_INTL, SYNC_PRODUCT_PROBLEMS_TO_INTL } from '../types';

export default {
  syncProductProblemsToIntl: () => {
    return createAxiosAction({
      url: `/api/v1/intSync/problems`,
      startAction: SYNC_PRODUCT_PROBLEMS_TO_INTL,
    });
  },
  syncProductInclusionsToIntl: () => {
    return createAxiosAction({
      url: `/api/v1/intSync/inclusions`,
      startAction: SYNC_PRODUCT_INCLUSIONS_TO_INTL,
    });
  },
};
