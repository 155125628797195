import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faUsers,
  faCalendarAlt,
  faCaretLeft,
  faCaretRight,
} from '@fortawesome/free-solid-svg-icons';

import { faEdit } from '@fortawesome/free-regular-svg-icons';

library.add(faEdit, faCalendarAlt, faCaretLeft, faCaretRight, faUsers);
