import React, { useEffect, useRef, useState } from 'react';
import { Fragment } from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { announcementsActions } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { AnnouncementsAddButtonWrapper, AnnouncementsButtonWrapper, StyledSpan } from '../styled';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../../hooks';
import { Text } from '@met/react-components';

const AnnouncementsTab = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [form] = Form.useForm();
  const formRef = useRef();
  const { Text } = Typography;
  const { TextArea } = Input;
  const { Option } = Select;

  const announcements = useSelector((state) => state.announcements.getAnnouncements.announcements);

  const [isVisible, toggleModal] = useToggle(false);
  const [isNew, setIsNew] = useState(true);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  const dateFormat = 'MM/DD/YYYY';

  useEffect(() => {
    if (announcements === undefined || announcements.length === 0) {
      grabAnnouncements();
    }
  }, []);

  const grabAnnouncements = () => {
    dispatch(announcementsActions.getAnnouncements()).catch(() => {
      toast.error(intl.formatMessage({ id: 'admin.announcements.loadingAnnouncementsErrorMessage' }), {
        autoClose: 5000,
      });
    });
  };

  const deleteAnnouncement = (id) => {
    dispatch(announcementsActions.deleteAnnouncement(id))
      .then(() => {
        grabAnnouncements();
        toast.success(intl.formatMessage({ id: 'admin.announcements.successDeleted' }), { autoClose: 5000 });
      })
      .catch((error) => {
        toast.error(intl.formatMessage({ id: 'admin.announcements.errorDeleted' }), { autoClose: 5000 });
      });
  };

  const handleSubmit = (form) => {
    let translationList = [];
    if (form?.englishTranslation) {
      let object = {
        Text: form?.englishTranslation,
        Lang: 'en-us',
      };
      translationList.push(object);
    }
    if (form?.frenchTranslation) {
      let object = {
        Text: form?.frenchTranslation,
        Lang: 'fr-ca',
      };
      translationList.push(object);
    }
    if (form?.spanishTranslation) {
      let object = {
        Text: form?.spanishTranslation,
        Lang: 'es-us',
      };
      translationList.push(object);
    }

    let newId = null;
    if (selectedAnnouncement) {
      newId = selectedAnnouncement.id;
    } else {
      newId = null;
    }

    let activeFrom = moment(form?.activeFrom).format('MM/DD/YYYY 00:00:00');
    let activeTo = moment(form?.activeTo).format('MM/DD/YYYY 23:59:59');

    dispatch(announcementsActions.upsertAnnouncement(newId, translationList, activeFrom, activeTo, form?.countryCode))
      .then((payload) => {
        grabAnnouncements();
        toast.success(intl.formatMessage({ id: 'admin.announcements.addAnnouncementSuccess' }), { autoClose: 5000 });
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.announcements.addAnnouncementError' }), { autoClose: 5000 });
      });
    toggleModal();
  };

  const handleEditClick = (record) => {
    setIsNew(false);
    setSelectedAnnouncement(record);
    fillForm(record);
    toggleModal();
  };

  const handleAddClick = () => {
    form.resetFields();
    setIsNew(true);
    setSelectedAnnouncement(null);
    toggleModal();
  };

  const findAnnouncement = (objectList) => {
    let obj = objectList.body.find((o) => o.lang === 'en-us');
    return <Text>{obj.text}</Text>;
  };

  const formatDate = (announcementDate) => {
    let date = moment(announcementDate).format('MM/DD/YYYY');
    return <Text>{date}</Text>;
  };

  const fillForm = (record) => {
    form.setFieldsValue({
      countryCode: record.countryCode,
      activeFrom: moment(record.activeFrom),
      activeTo: moment(record.activeTo),
      englishTranslation: record.body.find((item) => item.lang.toLowerCase() === 'en-us')?.text || '',
      frenchTranslation: record.body.find((item) => item.lang.toLowerCase() === 'fr-ca')?.text || '',
      spanishTranslation: record.body.find((item) => item.lang.toLowerCase() === 'es-us')?.text || '',
    });
  };

  const columns = [
    {
      title: 'Announcement',
      key: 'body',
      ellipsis: true,
      render: (record) => {
        return findAnnouncement(record);
      },
    },
    {
      title: 'Country',
      dataIndex: 'countryCode',
      key: 'countryCode',
      width: 100,
    },
    {
      title: 'Start Date',
      key: 'activeFrom',
      width: 150,
      render: (record) => {
        return formatDate(record.activeFrom);
      },
    },
    {
      title: 'End Date',
      key: 'activeTo',
      width: 150,
      render: (record) => {
        return formatDate(record.activeTo);
      },
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 200,
      render: (record) => (
        <Space direction='horizontal'>
          <AnnouncementsButtonWrapper
            data-test-id='adminAnnouncementsTabEditButton'
            onClick={() => handleEditClick(record)}
          >
            <FormattedMessage id='general.edit' />
          </AnnouncementsButtonWrapper>
          <Popconfirm
            data-test-id='announcementsPopConfirm'
            key='delete'
            title={intl.formatMessage({ id: 'general.areYouSure' })}
            onConfirm={() => deleteAnnouncement(record.id)}
            okText={intl.formatMessage({ id: 'general.yes' })}
            cancelText={intl.formatMessage({ id: 'general.no' })}
          >
            <AnnouncementsButtonWrapper data-test-id='adminAnnouncementsTabDeleteButton'>
              <FormattedMessage id='general.delete' />
            </AnnouncementsButtonWrapper>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const announcementsFormMarkup = (
    <Form
      name='addAnnouncementForm'
      form={form}
      ref={formRef}
      onFinish={handleSubmit}
      scrollToFirstError={true}
      layout='vertical'
    >
      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <Form.Item
            name='countryCode'
            label='Select Country'
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'general.required' }),
              },
            ]}
          >
            <Select placeholder={intl.formatMessage({ id: 'admin.announcements.selectACountry' })}>
              <Option value='US'>US</Option>
              <Option value='CA'>CA</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <Form.Item name='activeFrom' label='Start Date'>
            <DatePicker format={dateFormat} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item name='activeTo' label='End Date'>
            <DatePicker format={dateFormat} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <Form.Item
            name='englishTranslation'
            label='English'
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'general.required' }),
              },
              {
                min: 12,
                message: intl.formatMessage({ id: 'admin.announcements.minimumAnnouncementCharacters' }),
              },
            ]}
          >
            <TextArea placeholder={intl.formatMessage({ id: 'admin.announcements.enterAnnouncement' })} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <Form.Item
            name='frenchTranslation'
            label='French Translation'
            rules={[
              {
                min: 12,
                message: intl.formatMessage({ id: 'admin.announcements.minimumAnnouncementCharacters' }),
              },
            ]}
          >
            <TextArea placeholder={intl.formatMessage({ id: 'admin.announcements.enterTranslation' })} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name='spanishTranslation'
            label='Spanish Translation'
            rules={[
              {
                min: 12,
                message: intl.formatMessage({ id: 'admin.announcements.minimumAnnouncementCharacters' }),
              },
            ]}
          >
            <TextArea placeholder={intl.formatMessage({ id: 'admin.announcements.enterTranslation' })} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  const announcementsModal = (
    <Modal
      title={
        isNew ? (
          <FormattedMessage id='admin.announcements.addModalTitle' />
        ) : (
          <FormattedMessage id='admin.announcements.editModalTitle' />
        )
      }
      open={isVisible}
      closable={true}
      onCancel={toggleModal}
      width={800}
      destroyOnClose={true}
      footer={[
        <Button
          data-test-id='adminAnnouncementsTabCancelButton'
          key='cancel'
          onClick={() => {
            toggleModal();
          }}
          style={{ backgroundColor: '#CCCCCC', color: 'black' }}
        >
          Cancel
        </Button>,
        <AnnouncementsButtonWrapper
          data-test-id='adminAnnouncementsTabSubmitButton'
          key='submit'
          onClick={() => formRef.current.submit()}
        >
          Submit
        </AnnouncementsButtonWrapper>,
      ]}
    >
      {announcementsFormMarkup}
    </Modal>
  );

  return (
    <Fragment>
      <PageHeader
        title={intl.formatMessage({ id: 'admin.announcements.announcementsHeader' })}
        extra={[
          <Button
            data-test-id='adminAnnouncementsTabAddButton'
            key='add'
            type='primary'
            onClick={() => handleAddClick()}
            icon={<PlusOutlined />}
          >
            <span>
              <FormattedMessage id='general.add' />
            </span>
          </Button>,
        ]}
      >
        <Table rowKey='id' dataSource={announcements} columns={columns} pagination={false} />
      </PageHeader>
      {announcementsModal}
    </Fragment>
  );
};

export default AnnouncementsTab;
