import { useToggle } from '@met/react-components';
import { Button, Card, Col, Popconfirm, Row } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { ProductProblemMappingModal } from '../index';
import { useProductProblemMappingContext } from './ProductProblemMappingContext';
import { DeleteTextWrapper, ProblemMappingsTable } from '../styled';
import { toast } from 'react-toastify';
import { productsActions } from '../../../../store';
import ProductProblemBulkDeleteModal from './ProductProblemBulkDeleteModal';

const ProductProblemMappings = ({ sku }) => {
  const {
    addMode,
    deleteMode,
    setMappingMode,
    setCurrentMapping,
    productProblemMappings,
    selectedProductProblemMappings,
    setSelectedProductProblemMappings,
  } = useProductProblemMappingContext();

  const dispatch = useDispatch();
  const intl = useIntl();

  const [showModal, toggleModal] = useToggle(false);
  const [showDeleteModal, toggleDeleteModal] = useToggle(false);

  useEffect(() => {
    setCurrentMapping({ sku: sku });
  }, [sku]);

  const handleAddClick = () => {
    setMappingMode(addMode);
    toggleModal();
  };

  const handleDeleteClick = mappingToDelete => {
    // Removes from list of selected mappings
    if (
      selectedProductProblemMappings &&
      selectedProductProblemMappings.length > 0 &&
      selectedProductProblemMappings.find(x => x === mappingToDelete.id)
    ) {
      let mappings = selectedProductProblemMappings;
      mappings.splice(mappings.indexOf(mappingToDelete.id), 1);

      setSelectedProductProblemMappings(mappings);
    }

    dispatch(productsActions.deleteProductProblemMapping(mappingToDelete.id, mappingToDelete.sku))
      .then(() => {
        toast.success(
          intl.formatMessage({ id: 'admin.product.productProblemMappings.productProblemModal.deleteSuccess' })
        );
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.product.productProblemMappings.productProblemModal.deleteError' }));
      });
  };

  const handleDeleteBulkConfirm = () => {
    setSelectedProductProblemMappings([]);
  };

  const onSelectChange = selectedRowKeys => {
    setSelectedProductProblemMappings(selectedRowKeys);
  };

  const productProblemMappingMarkup = productProblemMappings && (
    <Col md={12}>
      <div>
        <ProblemMappingsTable
          data-test-id='problemMappingTable'
          rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
          rowSelection={{
            selectedProductProblemMappings,
            onChange: onSelectChange,
            preserveSelectedRowKeys: false,
          }}
          bordered={false}
          pagination={false}
          showHeader={true}
          columns={[
            {
              key: 'description',
              dataIndex: 'description',
            },
            {
              title: 'Actions',
              key: 'delete',
              render: productProblemMapping => (
                <Popconfirm
                  title={intl.formatMessage({ id: 'general.areYouSure' })}
                  onConfirm={() => handleDeleteClick(productProblemMapping)}
                  okText={intl.formatMessage({ id: 'general.yes' })}
                  cancelText={intl.formatMessage({ id: 'general.no' })}
                  cancelButtonProps={{ 'data-test-id': 'cancelBtn', style: { height: 'auto' } }}
                  okButtonProps={{ 'data-test-id': 'yesBtn', style: { height: 'auto' } }}
                >
                  <DeleteTextWrapper type='primary'>Delete</DeleteTextWrapper>
                </Popconfirm>
              ),
            },
          ]}
          dataSource={productProblemMappings}
          rowKey={item => item.id}
        />
      </div>
    </Col>
  );

  return (
    <Fragment>
      <Card
        title={<FormattedMessage id='admin.product.productProblemMappings.heading' />}
        extra={
          <React.Fragment>
            <Button
              data-test-id='deleteBtn'
              type='primary'
              style={{ marginRight: 10 }}
              icon={<DeleteOutlined />}
              onClick={toggleDeleteModal}
              disabled={selectedProductProblemMappings.length === 0}
            >
              <span>
                <FormattedMessage id='general.deleteSelected' />
              </span>
            </Button>
            <Button data-test-id='addBtn' type='primary' icon={<PlusOutlined />} onClick={() => handleAddClick()}>
              <span>
                <FormattedMessage id='general.add' />
              </span>
            </Button>
          </React.Fragment>
        }
      >
        <Row>{productProblemMappingMarkup}</Row>
      </Card>
      <ProductProblemMappingModal data-test-id='productProblemAddModal' hidden={!showModal} toggle={toggleModal} />
      <ProductProblemBulkDeleteModal
        data-test-id='productProblemDeleteModal'
        hidden={!showDeleteModal}
        toggle={() => toggleDeleteModal()}
        deleteConfirm={() => handleDeleteBulkConfirm()}
      />
    </Fragment>
  );
};

export default ProductProblemMappings;
