import { Checkbox, Dropdown, Placeholder, PlaceholderContent, Text, TextInput, useToggle } from '@met/react-components';
import Enumerable from 'linq';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import { useRepairItemsContext } from '../../../RepairItemsContext';
import { BatteryInputWrapper, CheckboxWrapper, OtherInputWrapper } from '../styled';
import { Button, Col, Modal, Row } from 'antd';
import { useLocale } from '../../../../../../hooks';
import BatteryEvaluationModal from '../../BatteryEveluationModal/BatteryEvaluationModal';

const Included = () => {
  const intl = useIntl();
  const history = useHistory();

  const { isCaLocale } = useLocale();

  const isCa = isCaLocale();

  const {
    sku,
    included,
    setIncluded,
    hasOtherIncluded,
    setHasOtherIncluded,
    otherIncluded,
    setOtherIncluded,
    otherIncludedInvalid,
    hasBatteryIncluded,
    setHasBatteryIncluded,
    batteryIncluded,
    setBatteryIncluded,
    batteryIncludedInvalid,
    includedInvalid,
    loadingProduct,
    product,
    loadingBatteries,
    batteries,
    setUnsavedChanges,
  } = useRepairItemsContext();

  const [batteryOptions, setBatteryOptions] = useState([]);
  const [selectedBattery, setSelectedBattery] = useState(null);
  const [batterySafetyLink, setBatterySafetyLink] = useState(null);

  const [showBatteryModal, toggleBatteryModal] = useToggle(false);
  const [showIneligibleBatteryModal, toggleIneligibleBatteryModal] = useToggle(false);

  useEffect(() => {
    if (!hasOtherIncluded) {
      setOtherIncluded('');
    }
  }, [hasOtherIncluded]);

  useEffect(() => {
    setHasOtherIncluded(otherIncluded.length > 0);
  }, [otherIncluded]);

  useEffect(() => {
    if (!hasBatteryIncluded) {
      setBatteryIncluded('');
    }
  }, [hasBatteryIncluded]);

  useEffect(() => {
    setHasBatteryIncluded(batteryIncluded.length > 0);

    let option = Enumerable.from(batteryOptions).firstOrDefault(x => x.value === batteryIncluded);
    if (option) {
      setSelectedBattery(option);
    }
  }, [batteryIncluded]);

  useEffect(() => {
    if (selectedBattery) {
      if (selectedBattery && selectedBattery.safetyLink) {
        setBatterySafetyLink(selectedBattery.safetyLink);
      }

      setBatteryIncluded(selectedBattery.value);
    }
  }, [selectedBattery]);

  useEffect(() => {
    if (batteries && batteries.length > 0) {
      setBatteryOptions(createBatteryOptions());
    }
  }, [batteries]);

  const handleBatteryCheck = batteryChecked => {
    setHasBatteryIncluded(batteryChecked);
  };

  const createBatteryOptions = () => {
    let batteryOptions = [];

    if (!loadingBatteries && batteries) {
      batteryOptions = batteries
        .filter(battery => battery.isActive)
        .map((battery, index) => {
          return {
            label: battery.skuAlias,
            value: battery.sku,
            description: battery.description,
            safetyLink: battery.safetyLink,
            isMXFuel: battery.isMXFuel,
          };
        });
    }

    return batteryOptions;
  };

  const handleCheckIncluded = includedItem => {
    let existingIncludedIndex = Enumerable.from(included).indexOf(x => x.description === includedItem.description);

    if (existingIncludedIndex > -1) {
      let updatedIncluded = included.filter((value, index) => {
        return index !== existingIncludedIndex;
      });

      if (included.length !== updatedIncluded.length) {
        setUnsavedChanges(true);
      }

      setIncluded(updatedIncluded);
    } else {
      let newIncluded = includedItem;

      setUnsavedChanges(true);
      setIncluded([...included, newIncluded]);
    }
  };

  const handleConfirmCancel = () => {
    setHasBatteryIncluded(false);
    setBatteryIncluded('');
  };

  const handleBatterySelect = selectedBattery => {
    setBatterySafetyLink(null);
    let battery = Enumerable.from(batteries).firstOrDefault(x => x.sku === selectedBattery.value);

    setSelectedBattery(selectedBattery);

    if (!battery.eligible) {
      toggleIneligibleBatteryModal();
    } else {
      toggleBatteryModal();
    }
  };

  const handleCloseIneligibleBatteryModal = () => {
    setHasBatteryIncluded(false);
    setBatteryIncluded('');
    setSelectedBattery(null);
    toggleIneligibleBatteryModal();
  };

  const includedTitle = (
    <Row>
      <Col>
        <h3>
          <FormattedMessage id='productDetail.included.includedTitle' />
        </h3>
      </Col>
    </Row>
  );

  const includedErrorMarkup = includedInvalid && (
    <Text error>
      <FormattedMessage id='productDetail.included.includedInvalid' />
    </Text>
  );

  const includedPlaceholderMarkup = loadingProduct && (
    <Placeholder>
      {includedTitle}
      <Row>
        <Col>
          <Row>
            <Col xs={10}>
              <PlaceholderContent />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <PlaceholderContent />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <PlaceholderContent />
            </Col>
          </Row>
          <Row>
            <Col xs={5}>
              <PlaceholderContent />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <PlaceholderContent />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <PlaceholderContent />
            </Col>
          </Row>
        </Col>
      </Row>
    </Placeholder>
  );

  const includedList =
    product &&
    product.productIncludedMappings.map((includedItem, index) => {
      let existingIncluded = Enumerable.from(included).firstOrDefault(x => x.description === includedItem.description);
      return (
        <Col xs={12} md={6} key={`included-${index}`}>
          <CheckboxWrapper>
            <Checkbox
              onCheck={() => handleCheckIncluded(includedItem)}
              label={intl.formatMessage({ id: 'productInclusions.' + includedItem.includedId, defaultMessage: includedItem.description })}
              checked={existingIncluded}
              data-test-id={`included-${index}`}
            />
          </CheckboxWrapper>
        </Col>
      );
    });

  const includedOther = (
    <Col xs={12} md={6}>
      <CheckboxWrapper>
        <Checkbox
          onCheck={() => setHasOtherIncluded(!hasOtherIncluded)}
          label={intl.formatMessage({ id: 'productDetail.included.includedOtherLabel' })}
          checked={hasOtherIncluded}
          data-test-id='included-other'
        />
        <OtherInputWrapper>
          <TextInput
            data-test-id='productDetailOtherIncludedProductInput'
            multiline
            rows={5}
            onChange={e => setOtherIncluded(e.target.value)}
            disabled={!hasOtherIncluded}
            value={otherIncluded}
            invalid={otherIncludedInvalid}
            errorMessage={intl.formatMessage({ id: 'productDetail.included.includedOtherInvalid' })}
            data-hj-allow
          />
        </OtherInputWrapper>
      </CheckboxWrapper>
    </Col>
  );

  const includedBattery = product && !product.isBattery && (
    <Col xs={12} md={6}>
      <CheckboxWrapper>
        <Checkbox
          onCheck={batteryChecked => handleBatteryCheck(batteryChecked.checked)}
          label={intl.formatMessage({ id: 'productDetail.included.includedBatteryLabel' })}
          checked={hasBatteryIncluded}
          data-test-id='included-battery'
        />
      </CheckboxWrapper>
    </Col>
  );

  const selectBatteryMarkup = hasBatteryIncluded && (
    <Row>
      <Col xs={24}>
        <h4>
          <FormattedMessage id='productDetail.included.selectBattery' />
        </h4>
        <BatteryInputWrapper>
          <Dropdown
            data-test-id='productDetailIncludedBatteryDropdown'
            options={batteryOptions}
            value={selectedBattery}
            getOptionValue={option => option.value}
            getOptionLabel={option => option.label}
            onChange={handleBatterySelect}
            disabled={!hasBatteryIncluded}
            windowMode
            windowMaxHeight={175}
            invalid={batteryIncludedInvalid}
          />
        </BatteryInputWrapper>
      </Col>
    </Row>
  );

  const ineligibleBatteryModalMarkup = selectedBattery && (
    <Modal
      visible={showIneligibleBatteryModal}
      title={<FormattedMessage id='productDetail.included.ineligibleBatteryModal.title' />}
      footer={[
        <Button data-test-id='ineligibleBatteryModalOkButton' primary onClick={() => handleCloseIneligibleBatteryModal()}>
          <FormattedMessage id='general.ok' />
        </Button>,
      ]}
    >
      <p>
        <FormattedMessage
          id='productDetail.included.ineligibleBatteryModal.notEligible'
          values={{
            description: selectedBattery.description,
            sku: selectedBattery.label,
          }}
        />
      </p>
      {selectedBattery.safetyLink && (
        <p>
          <FormattedMessage
            id='productDetail.included.ineligibleBatteryModal.expandedWarnings'
            values={{
              description: selectedBattery.description,
              sku: selectedBattery.label,
              link: isCa ? (
                <a data-test-id='batterySafetyNoticeLink' href={selectedBattery.safetyLink} target='_blank' rel='noopener noreferrer'>
                  https://www.milwaukeetool.ca/News/Safety-Notices
                </a>
              ) : (
                <a data-test-id='batterySafetyNoticeLink2' href={selectedBattery.safetyLink} target='_blank' rel='noopener noreferrer'>
                  https://milwaukeetool.com/safetynotices
                </a>
              ),
            }}
          />
        </p>
      )}
      <p>
        <FormattedMessage id='productDetail.included.ineligibleBatteryModal.warrantyInquiries' />
      </p>
    </Modal>
  );

  const includedMarkup = !loadingProduct && product && (
    <Fragment>
      {includedTitle}
      {includedErrorMarkup}
      <Row>
        {includedList}
        {includedBattery}
        {includedOther}
      </Row>
      {selectBatteryMarkup}
    </Fragment>
  );

  return (
    <Fragment>
      {includedPlaceholderMarkup}
      {includedMarkup}
      <BatteryEvaluationModal
        selectedProduct={selectedBattery}
        toggleBatteryModal={toggleBatteryModal}
        showBatteryModal={showBatteryModal}
        batterySafetyLink={batterySafetyLink}
        includedBatteryCancelClick={() => handleConfirmCancel()}
      />
      {ineligibleBatteryModalMarkup}
    </Fragment>
  );
};

export default Included;
