import React from 'react';
import { Collapse, List } from 'antd';
import styled from 'styled-components';

export const CollapsiblePanel = styled(Collapse.Panel)`
  .ant-collapse-header {
    display: flex;
    align-items: center;
  }
`;

export const UncollapsibleItem = styled(List.Item)`
  background-color: #ffffff;
`;
