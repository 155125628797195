import { setUserMetadata } from '../../helpers/sessionStorage';
import { UPDATE_USER_METADATA } from '../types';
import { error, success } from '../utils';

export let initialState = {
  updateUserMetadata: {
    isLoading: false,
    isError: false,
  },
};

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case UPDATE_USER_METADATA:
      return {
        ...state,
        updateUserMetadata: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPDATE_USER_METADATA):
      setUserMetadata(action.payload.data);

      return {
        ...state,
        updateUserMetadata: {
          isLoading: false,
          isError: false,
        },
      };
    case error(UPDATE_USER_METADATA):
      return {
        ...state,
        updateUserMetadata: {
          isLoading: false,
          isError: true,
        },
      };
    default:
      return state;
  }
};

export { reducer };
