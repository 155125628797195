import { useSelector } from 'react-redux';

const useFeatureToggles = () => {
  return {
    localesSupport: useSelector(
      state =>
        state.configuration.getActiveFeatures.activeFeatures &&
        state.configuration.getActiveFeatures.activeFeatures.includes('Locales')
    ),
    pdfProofofPurchase: useSelector(
      state =>
        state.configuration.getActiveFeatures.activeFeatures &&
        state.configuration.getActiveFeatures.activeFeatures.includes('POP-PDFUpload')
    ),
    batteryRecycle: useSelector(
      state =>
        state.configuration.getActiveFeatures.activeFeatures &&
        state.configuration.getActiveFeatures.activeFeatures.includes('9.0-Recycle')
    ),
    accountNumberToggle: useSelector(
      state =>
        state.configuration.getActiveFeatures.activeFeatures &&
        state.configuration.getActiveFeatures.activeFeatures.includes('AccountNumber')
    ),
    creditCardPayments: useSelector(
      state =>
        state.configuration.getActiveFeatures.activeFeatures &&
        state.configuration.getActiveFeatures.activeFeatures.includes('CreditCardPayments')
    ),
    largeProductSkuProgram: useSelector(
      state =>
        state.configuration.getActiveFeatures.activeFeatures &&
        state.configuration.getActiveFeatures.activeFeatures.includes('LargeProductSkuProgram')
    ),
    redirectToSupportCenter: useSelector(
      state =>
        state.configuration.getActiveFeatures.activeFeatures &&
        state.configuration.getActiveFeatures.activeFeatures.includes('RedirectToSupportCenter')
    ),
  };
};

export default useFeatureToggles;
