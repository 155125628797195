import { useToggle } from '@met/react-components';
import { Card, Col, Row, Typography } from 'antd';
import { CompassOutlined, PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { ServiceCenterMappingModal } from '../index';
import { useServiceCenterMappingContext } from './ServiceCenterMappingContext';
import { MappingsButtonWrapper, ServiceMappingsTable, DeleteTextWrapper, StyledSpan } from '../styled';

const Text = { Typography };

const ServiceCenterMappings = ({ sku, handleAddAll }) => {
  const {
    addMode,
    deleteMode,
    setMappingMode,
    setCurrentMapping,
    serviceSkuMappings,
    addingServiceSkuMapping,
  } = useServiceCenterMappingContext();

  const [showModal, toggleModal] = useToggle(false);

  const handleAddClick = () => {
    setMappingMode(addMode);
    setCurrentMapping({ sku: sku });
    toggleModal();
  };

  const handleDeleteClick = mappingToDelete => {
    setMappingMode(deleteMode);
    setCurrentMapping(mappingToDelete);
    toggleModal();
  };

  const serviceMappingsMarkup = serviceSkuMappings && (
    <Col md={12}>
      <div>
        <ServiceMappingsTable
          data-test-id='serviceMappingTable'
          rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
          bordered={false}
          showHeader={false}
          pagination={false}
          columns={[
            {
              key: 'compassIcon',
              render: () => <CompassOutlined />,
            },
            {
              key: 'description',
              dataIndex: 'description',
            },
            {
              key: 'axId',
              dataIndex: 'axId',
            },
            {
              key: 'delete',
              render: serviceMapping => (
                <DeleteTextWrapper
                  data-test-id='serviceDelete'
                  onClick={() => {
                    handleDeleteClick(serviceMapping);
                  }}
                >
                  Delete
                </DeleteTextWrapper>
              ),
            },
          ]}
          dataSource={serviceSkuMappings}
          rowKey={item => item.serviceCenterId}
        />
      </div>
    </Col>
  );

  return (
    <Fragment>
      <Card>
        <Row>
          <Col span={12}>
            <h3>
              <FormattedMessage id='admin.product.serviceCenterMappings.heading' />
            </h3>
          </Col>
          <Col span={12}>
            <Row justify={'end'} gutter={[8, 8]}>
              <Col>
                <MappingsButtonWrapper
                  data-test-id='serviceCenterAddAll'
                  type='primary'
                  icon={<PlusCircleOutlined />}
                  onClick={() => handleAddAll()}
                  loading={addingServiceSkuMapping}
                >
                  <StyledSpan>
                    <FormattedMessage id='admin.product.serviceCenterMappings.addAll' />
                  </StyledSpan>
                </MappingsButtonWrapper>
              </Col>
              <Col>
                <MappingsButtonWrapper
                  data-test-id='serviceCenterAdd'
                  type='primary'
                  icon={<PlusOutlined />}
                  onClick={() => handleAddClick()}
                >
                  <StyledSpan>
                    <FormattedMessage id='general.add' />
                  </StyledSpan>
                </MappingsButtonWrapper>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>{serviceMappingsMarkup}</Row>
      </Card>
      <ServiceCenterMappingModal hidden={!showModal} toggle={toggleModal} />
    </Fragment>
  );
};

export default ServiceCenterMappings;
