import { Icon } from '@met/react-components';
import { Space, Typography } from 'antd';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { ServiceCard } from '../../../../../../components';
import { useToggle } from '../../../../../../hooks';
import LmrDefinitionModal from './LmrDefinitionModal';

const { Paragraph, Text, Title } = Typography;

const ToolUnderWarranty = ({ warrantyLine }) => {
  const [showLmrModal, toggleLmrModal] = useToggle(false);

  const underWarrantyMarkup = (
    <Fragment>
      <ServiceCard>
        <Space direction='vertical' size='large'>
          <div>
            <Title strong>
              <FormattedMessage id='repairProcess.warrantyInformation.underWarranty.title' />
            </Title>
            <Paragraph>
              <FormattedMessage id='repairProcess.warrantyInformation.underWarranty.toolCondition' />
            </Paragraph>
            <Paragraph>
              <ul>
                <li>
                  <FormattedMessage id='repairProcess.warrantyInformation.warrantyCoverage.factor1' />
                </li>
                <li>
                  <FormattedMessage id='repairProcess.warrantyInformation.warrantyCoverage.factor2' />
                </li>
                <li>
                  <FormattedMessage id='repairProcess.warrantyInformation.warrantyCoverage.factor3' />
                </li>
              </ul>
            </Paragraph>
          </div>
          <div>
            <Title level={3} strong>
              <FormattedMessage id='repairProcess.warrantyInformation.noWarranty' />
            </Title>
            <Paragraph>
              <Text>
                <FormattedMessage id='productDetail.toolInfo.lmrTitle' />
                <Icon data-test-id='toggleLMRModal' className='ml-2' type='question-circle' xs onClick={toggleLmrModal} />
              </Text>
              <Paragraph>
                <Text style={{ color: '#db011c' }}>{warrantyLine}</Text>
              </Paragraph>
            </Paragraph>
          </div>
        </Space>
      </ServiceCard>
    </Fragment>
  );

  return (
    <Fragment>
      {underWarrantyMarkup}
      <LmrDefinitionModal isVisible={showLmrModal} toggle={() => toggleLmrModal()} />
    </Fragment>
  );
};

export default ToolUnderWarranty;
