import {
  Button,
  Col,
  Icon,
  InputGroup,
  InputGroupAppend,
  ListGroup,
  ListGroupItem,
  Loader,
  Row,
  Text,
  TextInput,
} from '@met/react-components';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { productsActions } from '../../../../store';
import { ProductImage } from '../styled';
import { Button as AntButton, PageHeader, Popconfirm } from 'antd';
import { RedoOutlined } from '@ant-design/icons';

const ProductsTab = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const searchTermRef = useRef(null);

  const [query, setQuery] = useQueryParams({
    q: withDefault(StringParam, undefined),
  });

  const searchingProducts = useSelector(state => state.products.searchProducts.isLoading);
  const products = useSelector(state => state.products.searchProducts.products);

  const [searchTerm, setSearchTerm] = useState(query.q || '');
  const [refreshAllLmrTriggered, setRefreshAllLmrTriggered] = useState(false);

  useEffect(() => {
    searchTermRef.current.focus();
  }, []);

  useEffect(() => {
    if (query.q !== undefined) {
      searchProducts();
    } else {
      clearSearchProducts();
    }
  }, [query]);

  useEffect(() => {
    if (refreshAllLmrTriggered) {
      dispatch(productsActions.loadAllLmrMappings())
        .then(() => {
          setTimeout(() => {
            setRefreshAllLmrTriggered(false);

            toast.success(intl.formatMessage({ id: 'admin.products.refreshLmrMappingsSuccess' }));
          }, 2000);
        })
        .catch(() => {
          setRefreshAllLmrTriggered(false);

          toast.error(intl.formatMessage({ id: 'admin.products.refreshLmrMappingsError' }));
        });
    }
  }, [refreshAllLmrTriggered]);

  const search = () => {
    setQuery({ q: searchTerm });

    searchProducts();
  };

  const handleEditClick = product => {
    history.push(`/admin/products/${product.sku}`);
  };

  const searchProducts = () => {
    dispatch(productsActions.searchProducts(searchTerm)).catch(() => {
      toast.error(intl.formatMessage({ id: 'admin.products.searchError' }));
    });
  };

  const clearSearchProducts = () => {
    dispatch(productsActions.clearSearchProducts());
  };

  const searchResultsList =
    products &&
    products.map((product, index) => {
      return (
        <ListGroupItem key={`search-result-${index}`}>
          <Row>
            <Col xs={2} middle='xs' center='xs'>
              <div>
                <ProductImage src={product.image} alt={product.sku} />
              </div>
            </Col>
            <Col middle='xs'>
              <Text sm>{product.sku}</Text>
              <Text sm bold>
                {product.description}
              </Text>
            </Col>
            <Col xs={12} sm='auto' middle='xs' center='xs'>
              <Button data-test-id='adminProductsTabEditProductButton' primary onClick={() => handleEditClick(product)}>
                <FormattedMessage id='general.edit' />
              </Button>
            </Col>
          </Row>
        </ListGroupItem>
      );
    });

  const loadingSearchResultsMarkup = searchingProducts && (
    <Row className='mt-3'>
      <Col center='xs'>
        <Loader type='tiles' />
      </Col>
    </Row>
  );

  const searchResultsMarkup = products && products.length > 0 && (
    <Fragment>
      <Row className='mt-3'>
        <Col>
          <h3>
            <FormattedMessage id='admin.products.searchResultsLabel' />
          </h3>
          <FormattedMessage
            id='admin.products.showingSearchResults'
            values={{ count: products.length, searchTerm: searchTerm }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup>{searchResultsList}</ListGroup>
        </Col>
      </Row>
    </Fragment>
  );

  const noSearchResultsMarkup = query.q && !searchingProducts && products && products.length === 0 && (
    <Row>
      <Col>
        <ListGroup>
          <ListGroupItem>
            <Row>
              <Col middle='xs' center='xs'>
                <FormattedMessage id='admin.products.noSearchResults' />
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Col>
    </Row>
  );

  return (
    <PageHeader
      title={intl.formatMessage({ id: 'admin.products.heading' })}
      extra={[
        <Popconfirm
          key='refreshAll'
          title={intl.formatMessage({ id: 'admin.products.reloadLmrAllConfirm' })}
          onConfirm={() => setRefreshAllLmrTriggered(true)}
        >
          <AntButton
            data-test-id='lmrRefreshAll'
            loading={refreshAllLmrTriggered}
            type='primary'
            icon={<RedoOutlined />}
          >
            <span>
              <FormattedMessage id='admin.products.reloadLmrAll' />
            </span>
          </AntButton>
        </Popconfirm>,
      ]}
    >
      <Row>
        <Col sm={6}>
          <Text className='mb-2'>
            <FormattedMessage id='admin.products.searchLabel' />
          </Text>
          <InputGroup>
            <TextInput
              data-test-id='adminProductsTabSearchLabelText' 
              onChange={e => setSearchTerm(e.target.value)}
              value={searchTerm}
              onSubmit={search}
              inputRef={searchTermRef}
            />
            <InputGroupAppend>
              <Button data-test-id='adminProductsTabSearchButton' onClick={search} loading={searchingProducts}>
                <Icon type='search' />
              </Button>
            </InputGroupAppend>
          </InputGroup>
        </Col>
      </Row>
      {loadingSearchResultsMarkup}
      {searchResultsMarkup}
      {noSearchResultsMarkup}
    </PageHeader>
  );
};

export default ProductsTab;
