import React from 'react';
import {LogoWrapperJr, InnerSpinnerJr, OuterSpinnerJr, PageLoaderWrapper, SpinContainerJr} from './styled';
import {ReactComponent as Logo} from "../../assets/logo-milwaukeetool.svg";

const PageLoaderJr = () => {
  return (
    <PageLoaderWrapper>
      <SpinContainerJr>
        <InnerSpinnerJr />
        <OuterSpinnerJr />
        <LogoWrapperJr>
          <Logo width={60}/>
        </LogoWrapperJr>
      </SpinContainerJr>
    </PageLoaderWrapper>
  );
};

export default PageLoaderJr;
