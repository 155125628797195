import { Alert, Button, Card, CardBody, Col, Grid, Row, useToggle } from '@met/react-components';
import Enumerable from 'linq';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { StringParam, useQueryParam } from 'use-query-params';
import { NotFound } from '../../../../../components/PageError';
import { ProductSearchModal } from '../../../../../components/ProductSearchModal';
import { handleNavigateAway } from '../../../../../helpers/navigation';
import { batteriesActions, productsActions } from '../../../../../store';
import { useRepairItemsContext } from '../../RepairItemsContext';
import RepairItemsList from '../../../components/RepairItemsList';
import { useRepairProcessContext } from '../../../RepairProcessContext';
import Included from './components/Included';
import Problem from './components/Problem';
import ToolInfo from './components/ToolInfo';
import TagReference from './components/TagReference';

export const ProductDetail = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const problemRef = useRef(null);

  const { repairItems, setRepairItems } = useRepairProcessContext();
  const {
    uploadingProofOfPurchase,
    product,
    sku,
    setSku,
    isServiceEligible,
    getRepairItem,
    setRepairItem,
    setUserTriedSubmit,
    validate,
    serialNumber,
    serialNumberUnreadable,
    shouldScroll,
    setShouldScroll,
    unsavedChanges,
    problemsInvalid,
  } = useRepairItemsContext();

  const [guidParam, setGuidParam] = useQueryParam('g', StringParam); // eslint-disable-line no-unused-vars

  const [usingExistingItem, setUsingExistingItem] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const [showSearchModal, toggleSearchModal] = useToggle(false);

  useEffect(() => {
    if (!serialNumber && !serialNumberUnreadable && !guidParam) {
      history.push(`/repairprocess/warrantyinformation/${sku}`);
    }
  }, []);

  useEffect(() => {
    if (shouldScroll) {
      if (problemsInvalid === true) {
        problemRef.current.scrollIntoView(false);
      }
      setShouldScroll(false);
    }
  }, [shouldScroll]);

  useEffect(() => {
    let pathParts = location.pathname.split('/');
    pathParts = pathParts.filter(part => {
      if (part && part !== '') {
        return part;
      }
    });

    if (pathParts[1] === 'productdetail') {
      setSku(pathParts[2]);

      if (guidParam) {
        let existingRepairItem = Enumerable.from(repairItems).firstOrDefault(x => x.guid === guidParam);

        if (existingRepairItem) {
          setUsingExistingItem(true);
          setRepairItem(existingRepairItem);
        } else {
          history.replace('/repairprocess/addanother');
        }
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    let existingRepairItem = Enumerable.from(repairItems).firstOrDefault(x => x.guid === guidParam);

    if (existingRepairItem) {
      setUsingExistingItem(true);
      setRepairItem(existingRepairItem);
    }
  }, [guidParam]);

  useEffect(() => {
    if (sku !== '') {
      dispatch(productsActions.getProduct(sku)).catch(payload => {
        if (payload.response.status === 400 || payload.response.status === 404) {
          setNotFound(true);
        }

        toast.error(intl.formatMessage({ id: 'productDetail.getProductError' }));
      });
      dispatch(batteriesActions.getBatteries()).catch(() => {
        toast.error(intl.formatMessage({ id: 'productDetail.getBatteriesError' }));
      });
    }
  }, [sku]);

  useEffect(() => {
    if (product && product.isBattery) {
      dispatch(batteriesActions.getBatteryBySku(product.sku));
    } else {
      dispatch(batteriesActions.clearSelectedBattery());
    }
  }, [product]);

  const handleSubmitClick = () => {
    setUserTriedSubmit(true);

    if (validate()) {
      if (usingExistingItem) {
        let otherRepairItems = repairItems.filter((r, i) => {
          return r.guid !== guidParam;
        });

        setRepairItems([...otherRepairItems, getRepairItem()]);
      } else {
        setRepairItems([...repairItems, getRepairItem()]);
      }

      if (usingExistingItem) {
        toast.success(intl.formatMessage({ id: 'productDetail.repairItemSaveSuccess' }), { autoClose: 5000 });
      } else {
        toast.success(intl.formatMessage({ id: 'productDetail.repairItemAddSuccess' }), { autoClose: 5000 });
      }

      history.push('/repairprocess/addanother');
    } else {
      toast.error(intl.formatMessage({ id: 'productDetail.pleaseCorrectLabel' }), { autoClose: 5000 });
    }

    setShouldScroll(true);
  };

  const handleCancelClick = () => {
    history.push('/repairprocess/addanother');
  };

  if (notFound) {
    return <NotFound />;
  }

  const toolNotEligibleMessage = !isServiceEligible && (
    <Alert danger>
      <FormattedMessage id='productDetail.toolNotEligible' />
    </Alert>
  );

  const toolInfoMarkup = isServiceEligible && (
    <Card>
      <CardBody>
        <ToolInfo />
      </CardBody>
    </Card>
  );

  const tagNumberMarkup = isServiceEligible && (
    <Card>
      <CardBody>
        <TagReference />
      </CardBody>
    </Card>
  );

  const problemMarkup = isServiceEligible && (
    <Card>
      <CardBody>
        <Problem problemRef={problemRef} />
      </CardBody>
    </Card>
  );

  const includedMarkup = isServiceEligible && (
    <Card>
      <CardBody>
        <Included />
      </CardBody>
    </Card>
  );

  const buttonMarkup = isServiceEligible ? (
    <Row>
      <Col end='xs'>
        <div className='mt-2'>
          <Button data-test-id='productDetailGeneralCancelButton' secondary onClick={handleCancelClick} className='mr-2'>
            <FormattedMessage id='general.cancel' />
          </Button>
          <Button primary onClick={handleSubmitClick} data-test-id='submitButton' loading={uploadingProofOfPurchase}>
            <FormattedMessage id={usingExistingItem ? 'general.saveChanges' : 'general.submit'} />
          </Button>
        </div>
      </Col>
    </Row>
  ) : (
    <Row>
      <Col end='xs'>
        <div className='mt-2'>
          <Button data-test-id='productDetailSearchAnotherProductGeneralCancelButton' secondary onClick={handleCancelClick} className='mr-2'>
            <FormattedMessage id='general.cancel' />
          </Button>
          <Button data-test-id='productDetailSearchAnotherProductButton' primary onClick={toggleSearchModal} disabled={product.sku === ''}>
            <FormattedMessage id='productDetail.searchAnotherProductLabel' />
          </Button>
        </div>
      </Col>
    </Row>
  );

  return (
    <Fragment>
      <Grid>
        {toolNotEligibleMessage}
        <Row>
          <Col xs={12} md={8}>
            {toolInfoMarkup}
            {tagNumberMarkup}
            {problemMarkup}
            {includedMarkup}
          </Col>
          <Col xs={false} md={4}>
            <Card>
              <CardBody>
                <RepairItemsList />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {buttonMarkup}
      </Grid>
      <ProductSearchModal hidden={!showSearchModal} toggle={toggleSearchModal} />
      <Prompt
        message={location =>
          handleNavigateAway(location, unsavedChanges, intl.formatMessage({ id: 'general.unsavedChangesWarning' }))
        }
      />
    </Fragment>
  );
};

export default ProductDetail;
