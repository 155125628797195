import React, { createContext, useContext, useState } from 'react';
import { useSelector } from 'react-redux';

const PreferencesContext = createContext({
  notificationTypes: [],
  loadingNotificationTypes: false,
  upsertingUserNotification: false,
  userNotifications: [],
  disableEmailNotifications: false,
});

const usePreferencesContext = () => useContext(PreferencesContext);

const PreferencesProvider = ({ children }) => {
  const notificationTypes = useSelector(state => state.notifications.getNotificationTypes.notificationTypes);
  const loadingNotificationTypes = useSelector(state => state.notifications.getNotificationTypes.isLoading);
  const upsertingUserNotification = useSelector(state => state.users.upsertUserNotifications.isLoading);

  const userNotifications = useSelector(state => state.users.user && state.users.user.userNotifications);
  const disableEmailNotifications = useSelector(
    state => state.users.user && state.users.user.disableEmailNotifications
  );

  return (
    <PreferencesContext.Provider
      value={{
        notificationTypes,
        loadingNotificationTypes,
        upsertingUserNotification,
        userNotifications,
        disableEmailNotifications,
      }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};

export { PreferencesProvider, usePreferencesContext, PreferencesContext };
