import { Col, Link, Text } from '@met/react-components';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Image, PurchaseOrderLine, WrapText } from './styled';

const DashboardCollapsibleContentLine = ({ purchaseOrderLine }) => {
  const intl = useIntl();

  return (
    <PurchaseOrderLine className='mt-2 mb-2'>
      <Col xs={12} md={1} center='xs' middle='xs' className='mb-2 mb-md-0'>
        <div>
          <Image src={`/api/v1/products/${purchaseOrderLine.sku}/image`} alt={purchaseOrderLine.sku} />
        </div>
      </Col>
      <Col xs={12} md={2} middle='xs' className='mb-2 mb-md-0'>
        <Text block sm bold className='mb-1'>
          {purchaseOrderLine.sku}
        </Text>
        <Text block sm>
          {purchaseOrderLine.description}
        </Text>
      </Col>
      <Col xs={7} md={2} start='xs' middle='md' className='mb-2 mb-md-0'>
        <Text block sm bold>
          <FormattedMessage id='dashboard.serialNumberLabel' />
        </Text>
        <WrapText block sm>
          {purchaseOrderLine.serialNumber || intl.formatMessage({ id: 'general.na' })}
        </WrapText>
      </Col>
      <Col xs={5} md={2} start='xs' middle='md' className='mb-2 mb-md-0'>
        <Text block sm bold>
          <FormattedMessage id='dashboard.orderNumberLabel' />
        </Text>
        <Text block sm>
          {purchaseOrderLine.serviceOrderNumber || <FormattedMessage id='dashboard.pending' />}
        </Text>
      </Col>
      <Col xs={7} md={2} start='xs' middle='md' className='mb-2 mb-md-0'>
        <Text block sm bold>
          <FormattedMessage id='dashboard.tagNumberLabel' />
        </Text>
        <Text block sm>
          {purchaseOrderLine.tagNumber || intl.formatMessage({ id: 'general.na' })}
        </Text>
      </Col>
      <Col xs={5} md='auto' middle='xs'>
        <Text block sm bold>
          <FormattedMessage id='dashboard.statusLabel' />
        </Text>
        <Text block sm>
          <FormattedMessage id={`dashboard.statuses.label${purchaseOrderLine.status}`} defaultMessage='-' />
        </Text>
      </Col>
      <Col xs={12} md middle='xs' center='xs' end='md' className='mt-3 mt-md-0 mb-2 mb-md-0'>
        <Link to={`/repairdetail/${purchaseOrderLine.hashedServiceOrderId}`} button primary>
          <FormattedMessage id='dashboard.viewOrder' />
        </Link>
      </Col>
    </PurchaseOrderLine>
  );
};

export default DashboardCollapsibleContentLine;
