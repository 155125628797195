import createAxiosAction from '../createAxiosAction';
import {
  GET_SERVICE_CENTERS,
  UPSERT_SERVICE_CENTER,
  GET_SERVICE_CENTER_MAPPINGS,
  INSERT_SERVICE_CENTER_MAPPING,
  UPDATE_SERVICE_CENTER_MAPPING,
  DELETE_SERVICE_CENTER_MAPPING,
  GET_DEFAULT_SERVICE_CENTER_MAPPINGS,
  UPDATE_DEFAULT_SERVICE_CENTER,
} from '../types';

export default {
  getServiceCenters: () => {
    return createAxiosAction({
      url: `/api/v1/serviceCenter`,
      startAction: GET_SERVICE_CENTERS,
    });
  },
  upsertServiceCenter: (
    id,
    axId,
    description,
    addressLine1,
    addressLine2,
    city,
    state,
    countryCode,
    postalCode,
    isActive
  ) => {
    return createAxiosAction({
      url: `/api/v1/servicecenter`,
      method: 'POST',
      body: {
        id: id,
        axId: axId,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        state: state,
        countryCode: countryCode,
        postalCode: postalCode,
        description: description,
        isActive: isActive,
      },
      startAction: UPSERT_SERVICE_CENTER,
    });
  },
  getServiceCenterMappings: () => {
    return createAxiosAction({
      method: 'GET',
      url: `/api/v1/serviceCenter/mappings`,
      startAction: GET_SERVICE_CENTER_MAPPINGS,
    });
  },
  insertServiceCenterMapping: mapping => {
    return createAxiosAction({
      method: 'POST',
      url: `/api/v1/serviceCenter/mappings`,
      body: { ServiceCenterStateMapping: mapping },
      startAction: INSERT_SERVICE_CENTER_MAPPING,
    });
  },
  updateServiceCenterMapping: mapping => {
    return createAxiosAction({
      method: 'PUT',
      url: `/api/v1/serviceCenter/mappings`,
      body: { ServiceCenterStateMapping: mapping },
      startAction: UPDATE_SERVICE_CENTER_MAPPING,
    });
  },
  deleteServiceCenterMapping: state => {
    return createAxiosAction({
      method: 'DELETE',
      url: `/api/v1/serviceCenter/mappings`,
      body: { state },
      startAction: DELETE_SERVICE_CENTER_MAPPING,
    });
  },
  getDefaultServiceCenterMappings: () => {
    return createAxiosAction({
      method: 'GET',
      url: `/api/v1/serviceCenter/defaultServiceCenterMappings`,
      startAction: GET_DEFAULT_SERVICE_CENTER_MAPPINGS,
    });
  },
  updateDefaultServiceCenter: mapping => {
    return createAxiosAction({
      method: 'PUT',
      url: `/api/v1/serviceCenter/defaultServiceCenterMappings`,
      body: { DefaultServiceCenterMapping: mapping },
      startAction: UPDATE_DEFAULT_SERVICE_CENTER,
    });
  },
};
