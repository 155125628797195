import createAxiosAction from '../createAxiosAction';
import { DOWNLOAD_PROOF_OF_PURCHASE, UPLOAD_PROOF_OF_PURCHASE } from '../types';

export default {
  uploadProofOfPurchase: formData => {
    return createAxiosAction({
      url: `/api/v1/proofofpurchase`,
      startAction: UPLOAD_PROOF_OF_PURCHASE,
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
      method: 'POST',
      body: formData,
    });
  },
  downloadProofOfPurchase: id => {
    return createAxiosAction({
      url: `/api/v1/proofofpurchase/${id}`,
      startAction: DOWNLOAD_PROOF_OF_PURCHASE,
      method: 'GET',
    });
  },
};
