import { Button, Col, Icon, Row, Text } from '@met/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const ServiceCenterLine = ({ serviceCenter, editServiceCenterClickOnClick, toggleServiceCenter }) => {
  const isActiveIcon = serviceCenter.isActive ? (
    <Text md bold success style={{ paddingLeft: '15px' }}>
      <CheckOutlined />
    </Text>
  ) : (
    <Text md bold danger style={{ paddingLeft: '15px' }}>
      <CloseOutlined />
    </Text>
  );
  return (
    <Row>
      <Col xs='auto' center='xs' middle='xs' className='mb-2 mb-md-0'>
        <Icon type='map-marker' info sm />
      </Col>
      <Col xs={3} left='xs' middle='xs' className='mb-2 mb-md-0'>
        <Text block md>
          {serviceCenter.description}
        </Text>
      </Col>
      <Col xs={2} left='xs' start='xs' className='mb-2 mb-md-0'>
        <Text block sm bold>
          <FormattedMessage id='admin.serviceCenter.axIdLabel' />
        </Text>
        <Text block sm>
          {serviceCenter.axId}
        </Text>
      </Col>
      <Col xs={2} left='xs' start='xs' className='mb-2 mb-md-0'>
        <Text block sm bold>
          <FormattedMessage id='admin.serviceCenter.countryCodeLabel' />
        </Text>
        <Text block sm>
          {serviceCenter.countryCode}
        </Text>
      </Col>
      <Col left='xs' start='xs' className='mb-2 mb-md-0'>
        <Text block sm bold>
          <FormattedMessage id='admin.serviceCenter.isActiveLabel' />
        </Text>
        {isActiveIcon}
      </Col>
      <Col xs='auto' middle='xs'>
        <Button data-test-id='adminServiceCentersEditButton' primary icon='pencil' onClick={() => editServiceCenterClickOnClick()}>
          <FormattedMessage id='general.edit' />
        </Button>
      </Col>
    </Row>
  );
};

export default ServiceCenterLine;
