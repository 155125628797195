import { useSelector } from 'react-redux';
import { serviceOrdersActions } from '../store';

const useServiceOrders = () => {
  const serviceOrdersByGroup = useSelector(state => state.serviceOrders.getServiceOrders.serviceOrders);
  const loadingServiceOrdersByGroup = useSelector(state => state.serviceOrders.getServiceOrders.isLoading);
  const generatingPaperwork = useSelector(state => state.serviceOrders.getServiceOrdersPdf.isLoading);

  const getServiceOrdersByGroup = groupId => {
    return serviceOrdersActions.getServiceOrders(groupId);
  };

  const getServiceOrdersPdf = groupId => {
    return serviceOrdersActions.getServiceOrdersPdf(groupId);
  };

  return {
    serviceOrdersByGroup,
    loadingServiceOrdersByGroup,
    getServiceOrdersByGroup,
    getServiceOrdersPdf,
    generatingPaperwork,
  };
};

export default useServiceOrders;
