import createAxiosAction from '../createAxiosAction';
import { GET_SKU_HUB_VOLUME_REPORT, 
  GET_SKU_STATE_VOLUME_REPORT, 
  GET_SKU_HUB_MAPPINGS_REPORT, 
  GET_WILL_CALL_SERVICE_ORDERS_REPORT,
  GET_DENIED_WARRANTY_SERVICE_ORDERS_REPORT
} from '../types';

export default {
  getSkuHubVolumeReport: (startDate, endDate) => {
    var params = `?startDate=${startDate}&endDate=${endDate}`;

    return createAxiosAction({
      url: `/api/v1/reports/getSkuHubVolume${params}`,
      responseType: 'blob',
      startAction: GET_SKU_HUB_VOLUME_REPORT,
    });
  },
  getSkuStateVolumeReport: (startDate, endDate) => {
    var params = `?startDate=${startDate}&endDate=${endDate}`;

    return createAxiosAction({
      url: `/api/v1/reports/getSkuStateVolume${params}`,
      responseType: 'blob',
      startAction: GET_SKU_STATE_VOLUME_REPORT,
    });
  },
  getSkuHubMappingsReport: () => {
    return createAxiosAction({
      url: '/api/v1/reports/getSkuHubMappings',
      responseType: 'blob',
      startAction: GET_SKU_HUB_MAPPINGS_REPORT,
    });
  },
  getWillCallServiceOrdersReport: (startDate, endDate) => {
    var params = `?startDate=${startDate}&endDate=${endDate}`;
    
    return createAxiosAction({
      url: `/api/v1/reports/getWillCallServiceOrdersForReport${params}`,
      responseType: 'blob',
      startAction: GET_WILL_CALL_SERVICE_ORDERS_REPORT,
    });
  },
  getDeniedWarrantyServiceOrdersReport: (startDate, endDate) => {
    var params = `?startDate=${startDate}&endDate=${endDate}`;

    return createAxiosAction({
      url: `/api/v1/reports/getDeniedWarrantyServiceOrdersForReport${params}`,
      responseType: 'blob',
      startAction: GET_DENIED_WARRANTY_SERVICE_ORDERS_REPORT,
    });
  },
};
