import React from 'react';
import { Card, Divider, Typography } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';

const CaShippingRestrictionWarning = () => {
  const intl = useIntl();

  const { Text } = Typography;
  const { Meta } = Card;

  const cardTitle = (
    <Meta
      data-test-id='caShippingWarningTitle'
      avatar={<WarningOutlined />}
      title={<FormattedMessage id='repairProcess.caRestrictionTitle' />}
    />
  );

  return (
    <Card>
      {cardTitle}
      <Divider />
      <Text data-test-id='caShippingWarningBody'>
        <FormattedMessage id='repairProcess.caRestrictionText' />
      </Text>
    </Card>
  );
};

export default CaShippingRestrictionWarning;
