/* eslint-disable test-selectors/button */
import { useToggle } from '@met/react-components';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { productIncludedActions, batteriesActions, intlSyncActions } from '../../../../store';
import ProductIncludedModal from './ProductIncludedModal';
import { Button, Col, Collapse, Empty, Input, List, PageHeader, Row, Spin, Switch, Typography } from 'antd';
import { EditOutlined, WalletTwoTone, RedoOutlined, PlusCircleOutlined, ThunderboltTwoTone } from '@ant-design/icons';
import {
  ButtonIconWrapper,
  MaxRow,
  IncludedResultsDiv,
  RowIconWrapper,
  FilterBarRow,
  IncludedFilterLabelWrapper,
  IncludedButtonWrapper,
} from '../styled';

const ProductIncludedTab = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { Panel } = Collapse;

  const loadingInclusions = useSelector(state => state.productIncluded.getProductInclusions.isLoading);
  const loadingBatteries = useSelector(state => state.batteries.getBatteries.isLoading);
  const updatingBattery = useSelector(state => state.batteries.upsertBattery.isLoading);
  const batteries = useSelector(state => state.batteries.getBatteries.batteries);
  const refreshingInclusions = useSelector(state => state.productIncluded.refreshProductInclusions.isLoading);
  const inclusions = useSelector(state => state.productIncluded.getProductInclusions.productInclusions);
  const upsertingInclusion = useSelector(state => state.productIncluded.upsertProductInclusions.isLoading);
  const isSyncingInclusionsToIntl = useSelector(state => state.intlSync.syncProductInclusionsToIntl.isLoading);

  const [filter, setFilter] = useState('');
  const [filteredInclusions, setFilteredInclusions] = useState([]);
  const [selectedInclusion, setSelectedInclusion] = useState({});
  const [showModal, setShowModal] = useToggle(false);

  useEffect(() => {
    dispatch(productIncludedActions.getProductInclusions()).catch(() => {
      toast.error(intl.formatMessage({ id: 'admin.productInclusionMappings.errorLoadingInclusion' }), {
        autoClose: false,
      });
    });
    dispatch(batteriesActions.getBatteries()).catch(() => {
      toast.error(intl.formatMessage({ id: 'admin.productInclusionMappings.errorLoadingBattery' }), {
        autoClose: false,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    let incs = inclusions.filter(inclusion => {
      return (
        !Boolean(filter) ||
        0 === filter.length ||
        inclusion.description.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
      );
    });
    setFilteredInclusions([...incs]);
  }, [filter]);

  useEffect(() => {
    setFilteredInclusions([...inclusions]);
  }, [inclusions]);

  const handleNewInclusionClick = () => {
    setSelectedInclusion({});
    setShowModal();
  };

  const refreshInclusionsClick = () => {
    dispatch(productIncludedActions.refreshProductInclusions())
      .then(payload => {
        toast.success(intl.formatMessage({ id: 'admin.included.includedUpdated' }), { autoClose: 5000 });
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.included.includedError' }), { autoClose: 5000 });
      });
  };

  const handleEditInclusionClick = inclusion => {
    setSelectedInclusion(inclusion);
    setShowModal();
  };

  const handleCancelClick = () => {
    setShowModal();
  };

  const handleSaveChangesClick = (id, description) => {
    dispatch(productIncludedActions.upsertProductInclusion(id, description))
      .then(payload => {
        setShowModal();
        toast.success(intl.formatMessage({ id: 'admin.included.includedUpdated' }), { autoClose: 5000 });
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.included.includedError' }), { autoClose: 5000 });
      });
  };

  const handleEligibleBatteryToggle = (value, sku) => {
    let battery = batteries.find(b => b.sku === sku);
    if (battery) {
      battery.eligible = value;
      dispatch(batteriesActions.upsertBattery(battery)).catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.included.errorUpdatingBattery' }), { autoClose: 5000 });
      });
    }
  };

  const handleActiveBatteryToggle = (value, sku) => {
    let battery = batteries.find(b => b.sku === sku);
    if (battery) {
      battery.isActive = value;
      dispatch(batteriesActions.upsertBattery(battery)).catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.included.errorUpdatingBattery' }), { autoClose: 5000 });
      });
    }
  };

  const handleSyncInclusionsToIntlClick = () => {
    dispatch(intlSyncActions.syncProductInclusionsToIntl()).then(res => {
      toast.success('Successfully Synced Inclusions to Intl', { autoClose: 5000 });
    });
  };

  const loadingSearchResultsMarkup = (loadingInclusions || refreshingInclusions) && <Spin />;

  const searchResultsMarkup = !refreshingInclusions && inclusions && inclusions.length > 0 && (
    <List
      bordered
      dataSource={filteredInclusions}
      renderItem={inclusion => (
        <List.Item key={`description-${inclusion.id}`}>
          <Col span={1}>
            <RowIconWrapper>
              <WalletTwoTone twoToneColor='#DB011C' />
            </RowIconWrapper>
          </Col>
          <Col span={21} data-test-id={`description-${inclusion.id}`}>
            <Typography.Text strong> {inclusion.description} </Typography.Text>
          </Col>
          <Col span={2} style={{ justifyContent: 'right' }}>
            <Button
              type='primary'
              size='medium'
              onClick={() => handleEditInclusionClick(inclusion)}
              data-test-id={`editButton${inclusion.description}`}
            >
              <Row>
                <ButtonIconWrapper>
                  <EditOutlined />
                </ButtonIconWrapper>
                <FormattedMessage id='general.edit' />
              </Row>
            </Button>
          </Col>
        </List.Item>
      )}
    ></List>
  );

  const productIncludedModal = (
    <ProductIncludedModal
      hidden={!showModal}
      toggle={setShowModal}
      inclusion={selectedInclusion}
      onCancelClick={handleCancelClick}
      onSaveChangesClick={handleSaveChangesClick}
      inclusions={inclusions}
      upsertingInclusion={upsertingInclusion}
    />
  );

  const filterBarMarkup = (
    <FilterBarRow>
      <Col sm={3} xs={24}>
        <IncludedFilterLabelWrapper data-test-id='includedFilterLabel'>
          <FormattedMessage id='admin.included.filterLabel' />
        </IncludedFilterLabelWrapper>
      </Col>
      <Col sm={12} xs={24}>
        <Input data-test-id='includedFilterTextBox' onChange={e => setFilter(e.target.value)} value={filter} />
      </Col>
      <Col sm={9} xs={24}>
        <IncludedButtonWrapper>
          <Button
            data-test-id='adminIncludedProductSyncInclusionsToIntlButton'
            type='primary'
            disabled={isSyncingInclusionsToIntl}
            loading={isSyncingInclusionsToIntl}
            onClick={() => handleSyncInclusionsToIntlClick()}
            className='mr-2'
          >
            <span>Sync Inclusions to Intl</span>
          </Button>
          <Button
            data-test-id='refreshInclusionsButton'
            type='primary'
            disabled={refreshingInclusions}
            loading={refreshingInclusions}
            onClick={() => refreshInclusionsClick()}
            className='mr-2'
          >
            <Row>
              <ButtonIconWrapper>
                <RedoOutlined />
              </ButtonIconWrapper>
              <FormattedMessage id='admin.included.refresh' />
            </Row>
          </Button>
          <Button
            data-test-id='addNewInclusionButton'
            type='primary'
            disabled={refreshingInclusions}
            loading={refreshingInclusions}
            onClick={() => handleNewInclusionClick()}
          >
            <Row>
              <ButtonIconWrapper>
                <PlusCircleOutlined />
              </ButtonIconWrapper>
              <FormattedMessage id='admin.included.add' />
            </Row>
          </Button>
        </IncludedButtonWrapper>
      </Col>
    </FilterBarRow>
  );

  const includedItemsHeader = <Typography.Text data-test-id='includedItemsHeader'>Inclusions</Typography.Text>;

  const batteryHeader = <Typography.Text data-test-id='batteryHeader'>Batteries</Typography.Text>;

  const includedItemsPanel = (
    <Panel header={includedItemsHeader} key='included'>
      {filterBarMarkup}
      <IncludedResultsDiv>
        {searchResultsMarkup}
        {loadingSearchResultsMarkup}
      </IncludedResultsDiv>
    </Panel>
  );

  const includedBatteryPanel = (
    <Panel header={batteryHeader} key='battery'>
      <Row>
        <Col xs={1}></Col>
        <Col xs={5}>
          <div style={{ paddingLeft: '20px' }}>
            <Typography.Text>Sku</Typography.Text>
          </div>
        </Col>
        <Col xs={0} sm={14}>
          <div style={{ paddingLeft: '5px' }}>
            <Typography.Text>Description</Typography.Text>
          </div>
        </Col>
        <Col xs={2}>
          <div style={{ paddingLeft: '20px' }}>
            <Typography.Text>Is Eligible</Typography.Text>
          </div>
        </Col>
        <Col xs={2}>
          <div style={{ paddingLeft: '10px' }}>
            <Typography.Text>Is Active</Typography.Text>
          </div>
        </Col>
      </Row>
      <IncludedResultsDiv>
        <List
          bordered
          dataSource={batteries}
          renderItem={battery => (
            <List.Item key={battery.sku}>
              <Col xs={1}>
                <RowIconWrapper>
                  <ThunderboltTwoTone twoToneColor='#DB011C' />
                </RowIconWrapper>
              </Col>
              <Col xs={5} sm={5} data-test-id={`sku${battery.sku}`}>
                <Typography.Text strong>{battery.skuAlias}</Typography.Text>
              </Col>
              <Col xs={0} sm={15} data-test-id={`description${battery.sku}`}>
                <Typography.Text strong>{battery.description}</Typography.Text>
              </Col>
              <Col xs={2} sm={2}>
                <Switch
                  checked={battery.eligible}
                  data-test-id={`batteryEligible${battery.sku}`}
                  onChange={(v, e) => handleEligibleBatteryToggle(v, battery.sku)}
                  disabled={updatingBattery}
                />
              </Col>
              <Col xs={2} sm={2}>
                <Switch
                  checked={battery.isActive}
                  data-test-id={`batteryActive${battery.sku}`}
                  onChange={(v, e) => handleActiveBatteryToggle(v, battery.sku)}
                  disabled={updatingBattery}
                />
              </Col>
            </List.Item>
          )}
        ></List>
        {loadingBatteries && (
          <div>
            <Spin />
          </div>
        )}
      </IncludedResultsDiv>
    </Panel>
  );

  return (
    <Fragment>
      <PageHeader title={intl.formatMessage({ id: 'admin.included.heading' })}>
        <Collapse defaultActiveKey={['included']}>
          {includedItemsPanel}
          {includedBatteryPanel}
        </Collapse>
      </PageHeader>
      {productIncludedModal}
    </Fragment>
  );
};

export default ProductIncludedTab;
