import React from 'react';
import { HeroBackdrop, HeroContent } from './styled';
import PropTypes from "prop-types";

const Hero = props => {
  return (
    <HeroBackdrop
      background={props.background}
      opacity={props.opacity}
      light={props.light}
      dark={props.dark}
      className={props.className}
    >
      <HeroContent {...props}>{props.children}</HeroContent>
    </HeroBackdrop>
  );
};

Hero.propTypes = {
  background: PropTypes.string,
  opacity: PropTypes.string,
  light: PropTypes.bool,
  dark: PropTypes.bool,
  maxWidth: PropTypes.string,
  className: PropTypes.string,
};

Hero.defaultProps = {
  opacity: '.5',
  light: false,
  dark: true,
  maxWidth: '1100px',
};

export default Hero;