/* eslint-disable no-template-curly-in-string */
export default {
  addAnother: {
    addAnotherTitle: 'Add Another Tool',
  },
  address: {
    deleteAddressModalTitle: 'Delete Address',
    confirmDeleteAddress: 'Are you sure you want to delete the following address?',
    defaultAddressModalTitle: 'Default Address',
    confirmDefaultAddress: 'Are you sure you want to set the following as your default shipping address?',
    setDefaultButton: 'Set Default',
    editAddressModalTitle: 'Edit Address',
    newAddressModalTitle: 'New Address',
    loadingCountriesErrorMessage: 'There was an error loading country data',
    loadingStatesErrorMessage: 'There was an error loading states data',
    countryLabel: 'Country',
    addressLine1Label: 'Address',
    addressLine2Label: 'Address 2',
    cityLabel: 'City',
    stateLabel: 'State/Province',
    postalCodeLabel: 'Postal Code',
    defaultShippingAddressLabel: 'Default Shipping Address',
    pleaseCorrectLabel: 'Please correct the highlighted errors and try again',
    saveChangesError: 'There was an error saving your changes',
    addressDeleted: 'Address deleted successfully!',
    addressUpdated: 'Address updated successfully!',
    deletedError: 'There was an error deleting the address',
    addressLine1Required: 'Address is required',
    cityRequired: 'City is required',
    postalCodeRequired: 'Postal Code is required',
    postalCodeInvalid: 'Invalid Postal Code',
    cannotDeleteDefault: 'Please set another address as default before deleting',
    didYouMean: 'Did you mean "{suggestion}"?',
    sanitizationError: 'There was an error verifying the address',
    validateAddress: 'Validate Address',
    addressIsValid: 'Address appears to be valid',
    addressNotFound: 'Address not found',
    addressIsInvalid: 'Address is invalid',
    clickSaveChanges: 'Click "Save Changes" to continue.',
    addressNameLabel: 'Your Name/Company Name',
    addressNameRequired: 'Company Name is required',
    residentialAddressLabel: 'Residential Address',
    addressNameCharLimit: "Address name must be 30 characters or less.",
    addressNameInvalidChar: "Address name cannot include special characters.",
    addressLineInvalidChar: "Address cannot include special characters.",
    poBoxesAreNotAllowed: "PO Boxes are not allowed",
  },
  navigation:{
      dashboard: 'Dashboard',
      admin: 'Admin',
      preferences: 'Preferences',
      loginSignup: 'Log In / Sign Up',
      signOut: 'Sign Out',
  },
  admin: {
    heading: 'Admin',
    productsTabLabel: 'Products',
    products: {
      heading: 'Products',
      searchLabel: 'Search Products',
      searchResultsLabel: 'Search Results',
      showingSearchResults: 'Showing top {count} results for "{searchTerm}"',
      searchError: 'There was an error searching for products',
      noSearchResults: 'No products found',
      reloadLmrAll: 'Refresh All LMR',
      reloadLmrAllConfirm: 'Are you sure you want to refresh LMR mappings for all products?',
      refreshLmrMappingsSuccess: 'LMR refresh was triggered successfully!',
      refreshLmrMappingsError: 'An error occurred with the LMR refresh',
    },
    product: {
      heading: 'Product Admin',
      loadingError: 'There was an error loading the product data',
      lmrMappings: 'LMR Mappings',
      productLmrMappings: 'Product LMR Mappings',
      lmrData: 'LMR Data',
      lastUpdated: 'Last Updated: ',
      lmrPrice: 'LMR Price',
      lmrPriceCa: 'LMR Price (CAD)',
      warrantyPeriod: 'Warranty Period',
      serviceEligible: 'Service Eligible',
      years: ' years',
      noLmrMappings: 'No LMR mappings set',
      reloadLmr: 'Refresh Product',
      addMapping: 'Add',
      deleteMapping: 'Delete',
      lmrModalHeader: ' LMR Mapping',
      lmrId: 'AX LMR ID',
      lmrMappingInvalid: 'Please enter a valid LMR ID',
      lmrMappingFormatInvalid: 'Format of LMR ID is invalid',
      areYouSure: 'Are you sure you want to delete this LMR mapping?',
      multipleMappings: 'NOTE: If multiple mappings are present, the most recent (top) will be used',
      lmrMappingAlreadyExists: 'The specified LMR mapping already exists',
      lmrMappingCreateSuccess: 'LMR mapping created successfully!',
      lmrMappingCreateError: 'An error occurred creating the LMR mapping',
      lmrMappingDeleteSuccess: 'LMR mapping deleted successfully!',
      lmrMappingDeleteError: 'An error occurred deleting the LMR mapping',
      refreshingLmrMappings: 'Refreshing LMR data',
      refreshLmrMappingsSuccess: 'LMR data refreshed and updated',
      refreshLmrMappingsUnchanged: 'LMR data refreshed but unchanged',
      refreshLmrMappingsError: 'An error occurred sending the refresh request',
      refreshLmrMappingsNoData: 'No LMR data found in AX for product',
      refreshTimeout: 'LMR data did not refresh before timing out',
      serviceCenterMappings: {
        heading: 'Service Center Mappings',
        addAll: 'Add All',
        noMappings: 'No Service Center mappings set yet',
        serviceCenterModal: {
          heading: ' Service Center Mapping',
          areYouSure: 'Are you sure you want to delete the {serviceCenter} mapping for {sku}?',
          serviceCenterLabel: 'Service Center',
          pleaseSelect: 'Please select a Service Center',
          alreadyExists: 'The specified Service Center mapping already exists',
          invalidSelection: 'Please select a valid Service Center',
          createSuccess: 'Service Center mapping created successfully!',
          createError: 'There was an error creating the Service Center mapping',
          deleteSuccess: 'Service Center mapping deleted successfully!',
          deleteError: 'There was an error deleting the Service Center mapping',
        },
      },
      productProblemMappings:{
        heading: 'Product Problem Mappings',
        noMappings: 'No Product Problem mappings set yet',
        productProblemModal: {
            heading: ' Product Problem Mappings',
            areYouSure: 'Are you sure you want to delete the "{problem}" problem mapping for {sku}?',
            productProblemLabel: 'Product Problem',
            pleaseSelect: 'Please select a Product Problem',
            alreadyExists: 'The specified Product Problem mapping already exists',
            invalidSelection: 'Please select a valid Product Problem',
            createSuccess: 'Product Problem mapping created successfully!',
            createError: 'There was an error creating the Product Problem mapping',
            deleteSuccess: 'Product Problem mapping deleted successfully!',
            deleteError: 'There was an error deleting the Product Problem mapping',
            oneOrMore: 'Please select 1 or more Product Problem',
            listTitle: 'The following problems will be deleted for {sku}:'
          },
      },
      productInclusionMappings:{
        heading: 'Product Inclusion Mappings',
        noMappings: 'No Product Inclusion mappings set yet',
        productInclusionModal: {
            heading: ' Product Inclusion Mapping',
            areYouSure: 'Are you sure you want to delete the "{inclusion}" mapping for {sku}?',
            productInclusionLabel: 'Product Inclusion',
            pleaseSelect: 'Please select a Product Inclusion',
            alreadyExists: 'The specified Product Inclusion mapping already exists',
            invalidSelection: 'Please select a valid Product Inclusion',
            createSuccess: 'Product Inclusion mapping created successfully!',
            createError: 'There was an error creating the Product Inclusion mapping',
            deleteSuccess: 'Product Inclusion mapping deleted successfully!',
            deleteError: 'There was an error deleting the Product Inclusion mapping',
            includedUpdated: 'Inclusions successfully synced',
            includedError: 'There was an error syncing inclusions'
          },
        errorLoadingBattery: 'There was an error loading batteries',
        errorUpdatingBattery: 'There was an error updating battery',
        errorLoadingInclusion: 'There was an error loading inclusions'
      }
    },
    serviceCentersTabLabel: 'Service Centers', 
    serviceCenters: {
      heading: 'Service Centers',
      defaultServiceCenters: 'Default Service Centers',
      serviceCenters: 'Service Centers',
      stateMappings: 'State Mappings',
    },
    serviceCenter: {
      axIdLabel: 'Ax Id',
      axIdRequired: 'Ax Id Required',
      isActiveLabel: 'Is Active',
      countryCodeLabel: 'Country',
      newServiceCenterModalTitle: "Add Service Center",
      editServiceCenterModalTitle: "Update Service Center",
      descriptionLabel: "Description",
      descriptionRequired: 'Description Required',
      deleteServiceCenterModalTitle: "Delete Service Center",
      confirmDeleteServiceCenter: "Are you sure you want to delete the following Service Center?",
      serviceCenterUpdateError: 'There was an error saving your changes',
      serviceCenterUpdated: 'Service Center successfully updated',
      fixAddress: 'Address is invalid please correct address.',
    },
    defaultServiceCenterMapping: {
      editModalTitle: 'Update Default Service Center',
      successUpdateMessage: 'Successfully updated {countryCode} default service center.',
      loadingDefaultServiceCenterMappingsErrorMessage: 'An error occurred loading default service centers'
    },
    stateMappings: {
      heading: 'State Mapping'
    },
    stateMapping: {
      addModalTitle: 'Add State Mapping',
      editModalTitle: 'Edit State Mapping',
      serviceCenterLabel: 'Service Center',
      confirmDeleteStateMapping: 'Are you sure you want to delete?',
      successDeleteMessage: 'Successfully deleted mapping.',
      successAddMessage: 'Successfully added mapping.',
      successUpdateMessage: 'Successfully updated mapping.',
      duplicateMapping: 'Mapping already exist for the selected state.'
      
    },
    serviceOrdersTabLabel: 'Service Orders',
    serviceOrders: {
      heading: 'Service Orders',
      searchLabel: 'Search Service Orders',
      searchResultsLabel: 'Search Results',
      searchError: 'There as an error searching for service orders',
      showingSearchResults: 'Showing top {count} results for "{searchTerm}"',
      noSearchResults: 'No service orders found',
      startDateInvalid: 'Please enter a valid start date',
      endDateInvalid: 'Please enter a valid end date',
      dateRangeInvalid: 'The end date must be greater than or equal to the start date',
      submittedLabel: 'Submitted',
      submittedByLabel: 'Submitted By',
      itemsLabel: '# Items',
      serviceOrderNumberLabel: 'Service Order #',
      serialNumberLabel: 'Serial Number',
      filterUnprocessed: 'Show Only Processed',
      serviceOrderStatusLabel: 'Status',
      statuses: {
        0: 'N/A',
        1: 'Submitted',
        2: 'In Transit',
        3: 'Received',
        4: 'In Repair',
        5: 'Shipped',
        6: 'Delivered to Customer',
        7: 'Delivered to Service Center',
        8: 'Recycled',
        9: 'In Repair - Call Us',
        10: 'Out of Warranty',
      },
      loadingServiceOrderStatusesErrorMessage: 'An error occurred loading statuses',
      filterByStatusPlaceholder: 'Filter by Status',
      showOnlyFailedOrders: 'Show Only Failed Orders',
      reprocessModal: {
        header: 'Reprocess Service Orders',
        reprocess: 'Reprocess',
        reprocessAmount: 'Are you sure you want to reprocess {totalCount} failed service orders from {dateRangeStart} to {dateRangeEnd}?',
        completedReprocess: 'Service orders successfully reprocessed!',
        noOrdersReprocess: 'There are no service orders to reprocess!',
        reprocessTime: 'Reprocessing may take several minutes...'
      },
      contact: {
        contactTitle: 'Contact',
      },
      deniedWarranty: {
        deniedWarrantyTitle: 'Denied Warranty',
        creditCard: 'Credit Card',
        purchaseOrder: 'Purchase Order',
        payLater: 'Pay Later',
        unknown: 'Unknown',
        pending: 'Pending',
        decisionDate: 'Decision Date',
        repairDecision: 'Repair Decision',
        estimatedScrapDate: 'Estimated Scrap Date',
        purchaseOrderNumber: 'Purchase Order Number',
        paymentMethod: 'Payment Method'
      }
    },
    serviceOrder: {
      loadingError: 'There was an error loading the service order',
      orderDetailsHeading: 'Order Details',
      serialNumberLabel: 'Serial Number',
      deniedWarrantyLabel: 'Denied Warranty',
      submittedAtLabel: 'Submitted',
      updatedAtLabel: 'Last Updated',
      serviceOrderNumberLabel: 'Service Order #',
      accountNumberLabel: 'Account #',
      shipmentInfoHeading: 'Shipment Details',
      shippingAddressLabel: 'Shipping Address',
      trackingNumberLabel: 'Tracking #',
      statusLabel: 'Status',
      toolProblemsLabel: 'Problems',
      includedItemsLabel: 'Included Items',
      primaryToolHeading: 'Primary Tool',
      otherItemsHeading: 'Other Items',
      toolInfoHeading: 'Tool Info',
      issuesAndWarningsLabel: 'Potential Issues',
      resubmitLabelSuccess: 'Shipping label was created successfully please refresh page for Tracking number',
      resubmitLabelFailure: 'Something went wrong creating the shipping label',
      resubmitLabelRefresh: 'Shipping label request sent please refresh the page to populate new data.',
      issues: {
        noServiceOrderNumber: 'No service order number was found',
        noTrackingNumber: 'No tracking number was found',
        noStatus: 'No status was found',
        tooManyPackages: 'Too many packages were specified',
        shippoStatusUnknown: 'Shippo status unknown',
        shippoStatusDocumentsFailed: 'Documents failed to process in Shippo',
        shippoStatusFailed: 'Shippo status indicates generic failure',
        shippoStatusTrackingFailed: 'Track shipment failed in Shippo',
      },
      warnings: {
        noAddressName: 'No name given for address',
        axOrLegacy: 'Was submitted via AX or legacy eService',
        shippoStatusRequested: 'Still processing in Shippo',
      },
      validateAddress: 'Validate Address',
      addressIsValid: 'Address appears to be valid',
      shippoStatusLabel: 'Shippo Status',
      shippoStatus: {
        0: 'Unknown',
        1: 'Requested',
        2: 'Processed',
        3: 'Received',
        4: 'Label Created',
        5: 'Failed',
        6: 'Tracking Failed'
      },
      numberOfPackagesLabel: 'Number of Packages',
      createLabel: 'Resubmit',
      communicationHistory: {
        title: 'Communication History',
        emailTemplate: 'Email Template',
        email: 'Email Address',
        dateSent: 'Date Sent',
        deliveryStatus: 'Delivery Status',
        eventStatus: 'Event Status',
        error: 'There was an error loading communication history'
      }
    },
    routingRules: {
      title: 'Service Order Routing',
      preamble1: 'Use the tool below to verify the state and SKU-based routing rules configured for a given set of tools.',
      preamble2: 'NOTE: Modifications to these rules can be made using the "Products" and/or "Service Centers" tabs above.',
      geoLocationInstructions: 'Select an address and products to see routing result. Addresses can be managed in preferences',
      stateOriginLabel: 'State of Origin',
      productsLabel: 'Products',
      searchProductPlaceholder: 'Search by SKU or description',
      pleaseSelectState: 'Please select a state of origin',
      pleaseAddProduct: 'Please add at least one product',
      verifyRoutingLabel: 'Verify Routing',
      searchProductNoResults: 'No results found',
      verifyRoutingError: 'An error occurred - please try again',
      thisOrderWouldBeRouted: 'This order would be routed to ',
      skuMappingLabel: 'Sku Mappings:',
      addressOriginLabel: 'Address of Origin',
      modeLabel: 'Select routing mode',
      distanceLabel: 'Distances:',
    },
    routingRulesTabLabel: 'Routing Rules',
    productProblemsTabLabel: 'Product Problems', 
    problems: {
      header: 'Product Problems',
      filterLabel: 'Filter Product Problems',
      editProblemModalTitle: 'Edit Product Problem',
      addProblemModalTitle: 'Add Product Problem',
      descriptionLabel: 'Description',
      descriptionRequired: 'Description Required',
      noSearchResults: 'No Problems found',
      problemUpdated: 'Problem saved successfully.',
      problemError: 'There was an issue saving your changes please try again.',
      add: 'Add New',
      noDuplicates: 'Problem already exists',
      noSpecialCharacters: 'Special characters are not allowed'
    },
    productIncludedTabLabel: 'Included', 
    included: {
      heading: 'Included',
      filterLabel: 'Filter Product Inclusions',
      editInclusionModalTitle: 'Edit Product Inclusion',
      addInclusionModalTitle: 'Add Product Inclusion',
      descriptionLabel: 'Description',
      descriptionRequired: 'Description Required',
      includedUpdated: 'Inclusion saved successfully.',
      includedError: 'There was an issue saving your changes please try again.',
      add: 'Add New',
      noSearchResults: 'No inclusions found',
      refresh: 'Sync Inclusions'
    },
    announcements: {
      announcementsHeader : 'Announcements',
      addAnnouncementSuccess: 'Successfully added announcement',
      addAnnouncementError: 'There was an error adding the announcement',
      loadingAnnouncementsErrorMessage: 'There was an error loading announcements',
      successDeleted: 'Announcement successfully deleted',
      errorDeleted: 'There was an error deleting the announcement',
      addModalTitle: 'Add Announcement',
      editModalTitle: 'Edit Announcement',
      errorUpdatingAnnouncement: 'There was an error updating the announcement',
      enterAnnouncement: 'Enter announcement',
      enterTranslation: 'Enter translation',
      selectACountry: 'Select a country',
      minimumAnnouncementCharacters: 'You need a minimum of 12 characters for an announcement',
    },
    reportsTabLabel: 'Reports',
    reports: {
      title: 'Reports',
      dateRange: 'Date Range',
      skuHubVolumesTitle: 'SKU/Hub Volumes',
      skuHubVolumesSubtitle: 'Frequency of SKUs sent to each branch/hub',
      skuStateFrequencyTitle: 'SKU/State Frequency',
      skuStateFrequencySubtitle: 'Frequency of SKUs submitted by state',
      skuHubMappingsTitle: 'SKU/Hub Mappings',
      skuHubMappingsSubtitle: 'SKUs eligible for service by each branch/hub',
      serviceOrderCreatedDateRangeTitle: 'Service Order Created Date Range',
      willCallServiceOrdersTitle: 'Will Call Service Orders',
      willCallServiceOrdersSubtitle: 'Service orders in will call stage requiring manual action',
      deniedWarrantyServiceOrdersTitle: 'Denied Warranty Service Orders',
      deniedWarrantyServiceOrdersSubtitle: 'Service orders in denied warranty stage requiring manual action',
    },
  },
  baseLayout: {
    unsupportedBrowser: 'Unsupported Web Browser',
    pleaseUseAnother: 'This web browser is no longer supported and this site may not function as intended.',
    exampleBrowsers: ' Please see the list below for a few examples of supported browsers.',
    googleChrome: 'Google Chrome',
    mozillaFirefox: 'Mozilla Firefox',
    microsoftEdge: 'Microsoft Edge',
  },
  collapsibleOrderList: {
    viewDetails: 'View Details',
    serialNumber: 'Serial Number',
    tagNumber: 'Tag ID/Customer Reference #',
    proofOfPurchase: 'Proof of Purchase',
    problem: 'Problem',
    included: 'Included',
    notIncluded: 'Not Included',
  },
  confirmation: {
    confirmationTitle: 'Confirmation',
    batteryInstructions: {
      title: 'Lithium-Ion Battery "Ground" Shipping Instructions',
      line1: 'Per DOT/PHMSA there are specific shipping requirements for "all" Lithium Ion battery products. "All" Power Tool manufacturers must comply with these requirements.',
      line2: 'Some of the batteries you could be shipping exceed the 100Whr limit and are considered Core 9 (DG9) hazardous material.',
      line3: 'Please refer to the special instructions that will print with the shipping label.',
      line4: 'We suggest you only send batteries if you believe they are related to the problem.',
      regulationsHeading: 'DOT General Awareness - Regulations',
      regulations1: 'Lithium Ion battery packs are regulated anytime they are in commerce (e.g., offered in carriers like UPS, FedEx). Some "exceptions" are available',
      regulations2: 'When the energy level (Whr) of a battery exceeds 100Whr it is considered fully regulated Class 9 (DG9) hazardous material for the purpose of transport',
      enforcementHeading: 'DOT General Awareness - Enforcement',
      enforcement1: 'Failure to comply with hazardous materials regulations can result in substantial civil and criminal fines and penalties',
      enforcement2: 'Each knowing violation is subject to a $75,000 civil penalty, or a $175,000 civil penalty for a knowing violation that results in death, serious illness or severe injury to any person, or substantial destruction of property',
      enforcement3: 'Criminal penalty of up to $500,000 per violation and up to 10 years in prison',
    },
    submitted: 'Service Order submitted!',
    submittedError: 'There was an error submitting your Service Order - please try again',
    repairItemsLabel: 'Repair Items',
    areYouSure: 'Are you sure you want to delete this tool?',
  },
  dashboard: {
    dashboardTitle: 'eService Dashboard',
    resultsToExcel: 'Results to Excel',
    datesError: 'The calendar start and end dates must be valid',
    exportErrorMessage: 'There was an error generating your file',
    startDateInvalid: 'Please enter a valid start date',
    endDateInvalid: 'Please enter a valid end date',
    dateRangeInvalid: 'The end date must be greater than or equal to the start date',
    showingLabel: 'Showing {resultsCount} of {totalCount} results',
    endOfData: 'End of Data',
    loading: 'Loading...',
    loadingErrorMessage: 'There was an error loading the page',
    noOrdersFound: 'No Orders Found',
    poNumberLabel: 'PO #',
    itemCountLabel: '# Items',
    orderNumberLabel: 'Order #',
    serialNumberLabel: 'Serial #',
    tagNumberLabel: 'Tag ID/Customer Ref #',
    statusLabel: 'Status',
    submittedLabel: 'Submitted',
    searchHelp: {
      rtxt1: '- Ex. ',
      rtxt2: ' will return service orders containing the terms ',
      txt01: 'By default, searches with spaces will treat separate words as AND searches ',
      txt02: 'The following modifiers can be used for advanced searching:',
      txt03: 'OR operator ',
      txt04: 'NOT operator ',
      txt05: ' will return service orders containing the term',
      txt06: ' but NOT ',
      txt07: 'Suffix operator ',
      txt08: ' will return service orders starting with the term ',
      txt09: 'Phrase operator ',
      txt10: ' will return service orders containing the WHOLE phrase ',
      txt11: ' exactly',
    },
    startRepair: 'Start a New Repair Order',
    statuses: {
      label1: 'Submitted',
      label2: 'In Transit',
      label3: 'Received',
      label4: 'In Repair',
      label5: 'Shipped',
      label6: 'Delivered',
      label7: 'Delivered',
      label8: 'Recycled',
      label9: 'In Repair',
      label10: 'In Repair',
    },
    waitingPeriodWarning: 'Please allow 24-48 hours for new order information to appear on your dashboard.',
    deniedWarning: 'You have an order(s) that are not under warranty. Please go to order(s) and review the options.',
    viewOrder: 'View Order',
    orderNumber: 'Order #',
    serialNumber: 'Serial #',
    status: 'Status',
    pending: 'Pending',
  },
  general: {
    add: 'Add',
    loginAndAdd: 'Login and Add',
    addNew: 'Add New',
    addTool: 'Add Tool',
    searchTool: 'Search for Tool',
    save: 'Save',
    saveChanges: 'Save Changes',
    submit: 'Submit',
    submitted: 'Submitted',
    ok: 'OK',
    cancel: 'Cancel',
    na: 'N/A',
    edit: 'Edit',
    delete: 'Delete',
    deleteSelected: 'Delete Selected',
    unsavedChangesWarning: 'You have unsaved changes - are you sure you want to leave?',
    continue: 'Continue',
    yes: 'Yes',
    no: 'No',
    chooseFile: 'Choose File',
    upload: 'Upload',
    uploaded: 'Uploaded',
    back: 'Back',
    close: 'Close',
    search: 'Search',
    confirm: 'Confirm',
    none: 'None',
    manage: 'Manage',
    pleaseSelect: 'Please Select',
    required: 'Required',
    any: 'Any',
    areYouSure: 'Are you sure?',
    notProvided: 'Not Provided',
    download: 'Download',
    processing: 'Processing',
    status: 'Status',
    processed: 'Processed',
    details: 'Details',
    reset: 'Reset',
    updatedBy: 'Updated By',
    updatedDate: 'Updated Date',
    pending: 'Pending',
    dismiss: 'Dismiss',
    activate: 'Activate',
    more: 'more',
    next: 'Next',
    error: 'Error',
    genericError: 'An error has occured. Please try again.'
  },
  home: {
    anonymousContent: {
      loginSignUp: 'Log In / Sign Up',
      howDoIKnow: 'How do I know if my tool is eligible?',
      searchPrompt: 'Search for Tools',
      checkIneligibleProducts: 'List of Ineligible Tools',
      solutionHeader: 'eService Is Your 24/7 Solution for Quick, Convenient Repairs',
      solutionLine1: 'Repairs completed in 7-10 days (warranty and non-warranty)',
      solutionLine2: 'Best for individual or smaller tool quantities',
      solutionLine3: 'Free {carrier} shipping to Milwaukee Tool repair facility',
      solutionLine4: 'Package your Milwaukee Tool and drop off at one of over {carrierLocationAmount} authorized {carrier} ship centers nationwide',
      solutionLine5: 'Milwaukee Factory Trained Technicians will repair your tool with genuine Milwaukee parts',
      solutionLine6: 'Your tool is returned directly to you - no need to pick it up',
      benefitsHeader: 'Account Benefits',
      benefitsLine1: 'Secure account information and management',
      benefitsLine2: 'Access to Milwaukee ONE-KEY and Heavy Duty News',
      benefitsLine3: 'Order status notifications',
      repairsCompletedAlt: 'Repairs completed in 7-10 business days',
      dropOffAlt: 'Drop off at over 8,000 FedEx locations',
      dropOffAltCa: 'Drop off at over 1,300 Purolator locations',
    },
    authenticatedContent: {
      startOrderHeading: 'Start your order by adding a tool',
      termsAndConditionsLabel: 'Terms & Conditions',
      acceptThe: 'I acknowledge that I have read and accept the ',
      termsAndConditionsRequired: 'You must accept the Terms & Conditions to place an eService order',
      termsAndConditionsNotSaved: 'There was an error saving your change to Terms & Conditions',
      addToolLabel: 'Add Tool',
      termsAndConditionsDownloadFailed: 'There was an error downloading the Terms & Conditions',
    },
    whatsNewModal: {
      title: 'Welcome to the New and Improved eService!',
      newSubmission: {
        title: 'New Submission Process',
        body: 'We\'ve made some changes, including a new, more intuitive submission process.',
        alt: 'A more intuitive submission process',
      },
      statusUpdates: {
        title: 'Get Status Updates, Increased Communication, and More Visibility With Our Repair Tracker',
        body: 'Wondering if your tool made it our repair hub? We now offer repair status updates, including tracking your tool shipment to and from our repair hub.',
        alt: 'Detailed repair status updates',
      },
      dashboard: {
        title: 'Organize Your Tool Submissions With Our Repair Submission Dashboard',
        body: 'Quickly reference information for each tool submission, respond to any information requests from our team, and export repair information from our dashboard.',
        alt: 'Quick reference the dashboard',
      },
    },
  },
  ineligibleTools: {
    ineligibleToolsTitle: 'What is Not Eligible for eService?',
    ineligibleToolsFooter: 'Find a Service Center',
    ineligibleTools: {
      tool01: 'Parts',
      tool02: 'M18TM HIGH DEMANDTM 9.0 Battery (48-11-1890)',
      tool03: 'Hand Tools (Including Empire Products)',
      tool04: 'AC Rotary Hammers 1 1/2" & Larger',
      tool05: 'Demolition Hammers',
      tool06: 'Storage',
      tool07: 'Corded Magnetic Drills',
      tool08: 'Miter Saws',
      tool09: 'Panel Saws',
      tool10: 'Work Gear',
      tool11: 'Abrasive and Dry Cut-Off Machines',
      tool12: 'Generators',
      tool13: 'Pressure Washers',
      tool14: 'Paint Sprayers',
      tool15: 'A/C Vacuum Cleaners (with the exception of the 8960)',
      tool16: 'Diamond Coring Equipment',
      tool17: 'Electric and Hand Hoists',
      tool18: 'HOLE DOZER Hole Saws',
      tool19: 'Accessories (ex: drill bits, LED bulbs)',
    },
  },
  moreInformation: {
    moreInformationTitle: 'More Information',
    viewTermsAndConditions: 'View Terms & Conditions',
    viewIneligible: 'View Tools Not Eligible for eService',
    additionalBody:
      'For additional help and/or information on other service options, please contact our Customer Service Team at 1-800-SAWDUST (1-800-729-3878)',
    additionalBodyCa:
      'For additional help and/or information on other service options, please contact our Customer Service Team at 1-877-948-2360',
  },
  pageError: {
    notFound: {
      title: 'We couldn\'t find the page you were looking for',
      subtitle: 'It either doesn\'t exist anymore or was moved',
    },
    serverError: {
      title: 'An error occurred loading the site',
      subtitle: 'Please refresh the page or try again later',
    },
    accessDenied: {
      title: 'Access denied',
      subtitle: 'You don\'t have permission to view this page',
    },
    authError: {
      title: 'There is an issue with your account',
      subtitle: 'Please contact support or {logout} and sign in with another account',
    },
  },
  productDetail: {
    getProductError: 'There was an error loading the product',
    getBatteriesError: 'There was an error loading battery data',
    toolNotEligible: 'This tool is not eligible for eService',
    toolInfo: {
      toolInfoTitle: 'Tool Info',
      serialNumberTitle: 'Serial Number',
      serialNumberLabel: 'Serial Number*',
      serialNumberRequired: 'Serial Number Required',
      proofOfPurchaseIncludedInShipment: 'Proof of purchase included in shipment',
      proofOfPurchaseNotIncluded: 'Proof of purchase not included',
      meetsRequirements: 'Your tool meets initial warranty requirements',
      warrantyPending: 'Your tools warranty status is pending review',
      moreInfoNeeded: 'We need more information to determine warranty eligibility',
      outOfWarranty: 'Your tool is out of warranty',
      manufactureDate: 'Manufacture Date',
      purchaseDate: 'Purchase Date',
      warrantyInfo: 'If your tool is determined to be out of warranty, one of the following prices may apply. You will contacted before further action is taken.',
      cannotReadSerialNumber: 'If you cannot read certain characters on your serial number plate, please put an asterisk (*) in their place',
      serialNumberUnreadableLabel: 'Serial Number Unreadable',
      tagNumberLabel: 'Tag ID/Customer Reference Number',
      tagNumberBlurb:
        'The Tag ID/Customer Reference Number is a number assigned by you to differentiate this tool from any others. For example, if you are sending in two of the same tool in a single submission, you might designate them as "Hammer Drill #1" and "Hammer Drill #2". It also allows us to identify your tools should you have any questions about your order.',
      tagNumberRequired: 'Tag ID/Customer Reference Number Required',
      warrantyHeader: 'Under Warranty*',
      warrantyModalHeader: "Milwaukee Tool's Limited Warranty",
      warrantyModalLimitedWarranty1:
        'In order to qualify for warranty, all claims must be within their stated warranty period and the failure of tool determined to be due to defects in material or workmanship. A certified factory technician will do a full evaluation of the tool and use the date code to determine if the product is within the warranty period.',
      warrantyModalLimitedWarranty2:
        "A proof of purchase will be required for warranty verification if the date code is over the warranty period. All non-qualifying tools are eligible for repair under Milwaukee's Lightning Max Repair program (see details under \"Lightning Max Repair\").",
      lmrModalHeader: 'Lightning Max Repair',
      lmrModalLightning1:
        'Milwaukee Tool offers Lightning Max Repair (LMR) for your non-qualifying warranty tools or tools outside of their warranty periods. Through Lightning Max Repair, you will pay for the cost of the repair and never pay more than the quoted price.',
      lmrModalLightning2: 
        'Due to the complexity of some products, you may see multiple LMR prices presented. This represents different types of repairs for the product. After examination by Milwaukee Tool, a determination will be made and a proper LMR price will be conveyed to you to ensure you never pay more than that amount.',
      lmrModalLightning3: {
        speed: 'SPEED: Repair of your tool in one week or less',
        payLess: 'PAY LESS NOT MORE: Guaranteed to never pay more than the quoted LMR price',
        reliability: 'RELIABILITY: All repairs are serviced by experienced, certified factory technicians',
        quality: 'QUALITY: Only genuine Milwaukee Tool parts are used',
      },
      proofOfPurchaseModalHeader: 'Proof of Purchase Not Included',
      proofOfPurchaseModalBody1: 'You have selected that your serial number is not readable, and that you are not including a proof of purchase.',
      proofOfPurchaseModalBody2: 'If you do not include a proof of purchase, we will be unable to use the date of purchase as a reference to assess warranty.',
      warrantyTitle: 'Tool Warranty',
      warrantyLabel: '{warrantyPeriod} Years',
      warrantyStatus: 'Warranty Status',
      lmrTitle: 'Lightning Max Repair Option(s)',
      lmrLabel: '{lmrLabel}: ${lmrPrice}',
      lmrLabelCa: '{lmrLabel} (CAD): ${lmrPrice}',
      lmrUnavailable: 'If the tool is not covered under warranty, our Customer Experience Team will reach out to you with LMR Price upon repair technician assessment. Please call 1-800-SAWDUST with any additional questions and concerns.',
      lmrUnavailableCa: 'If the tool is not covered under warranty, our Customer Experience Team will reach out to you with LMR Price upon repair technician assessment. Please call  (800) 268-4015 with any additional questions and concerns.',
      proofOfPurchaseHeader: 'Proof of Purchase*',
      proofOfPurchaseSubHeader: 'Upload a Picture or Scan (.jpg, .jpeg, .png, .pdf)',
      proofOfPurchaseSubHeaderNoPdf: 'Upload a Picture or Scan (.jpg, .jpeg, .png)',
      proofOfPurchaseTooLarge: 'File size too big',
      proofOfPurchaseInvalid: 'File type not allowed',
      proofOfPurchaseUploadLabel: 'Upload a picture or scan',
      proofOfPurchaseIncludedLabel: 'Proof of Purchase included in shipment',
      proofOfPurchaseNotIncludedLabel: 'Proof of Purchase NOT uploaded or included in shipment',
      proofOfPurchaseNotIncludedInfo:
        'If you do not include a Proof of Purchase, the serial number will be used to determine the warranty eligibility date',
      serialNumberUnreadableInfo:
        'If the serial number is unreadable, you must include a Proof of Purchase',
      proofOfPurchaseRequired: 'Proof of Purchase Required',
      proofOfPurchasePleaseSelect: 'Please select an option',
      proofOfPurchaseUploadCheck: 'Click "Upload" to finish uploading your Proof of Purchase',
      serialNumberModalHeader: 'Finding Your Serial Number',
      serialNumberModalBody1: 'Your serial number is an identifier for your tool and helps us to determine warranty coverage. You can find your serial number on the name plate that is adhered to the tool (pictured below).',
      serialNumberModalBody2: 'If you cannot read any part of your serial number, you may instead upload your proof of purchase.',
    },
    proofOfPurchaseUploadSuccess: 'Proof of Purchase uploaded successfully!',
    proofOfPurchaseUploadError: 'There was an error uploading your Proof of Purchase - please try again',
    problem: {
      problemTitle: 'Problem*',
      problemInvalid: 'Please select a problem',
      problemOtherLabel: 'Other',
      problemOtherInvalid: 'Please enter some details',
    },
    included: {
      includedTitle: 'I will be including the following items',
      includedInvalid: 'Please select included items',
      includedOtherLabel: 'Other',
      includedBatteryLabel: 'Battery',
      selectBattery: 'Select a Battery',
      includedOtherInvalid: 'Please enter some details',
      batteryModal: {
        title: 'Battery Shipping Notice',
        mail: 'Shipping batteries requires stricter shipping and packaging requirements as mandated by the Department of Transportation. Please pay close attention to the shipping instructions and additional labels that print upon submission of a battery to avoid any fines.',
        prohibited: 'PLEASE NOTE: Batteries CANNOT be shipped via eService from Alaska or Hawaii due to shipping regulations. Please use the link below to find an alternative service option.',
        understand: 'I Understand',
        batteryDamage: {
          title: 'Does your battery pack show any signs of major housing damage?',
          listTitle: 'Major Housing Damage',
          listPoint1: 'Major crack is visible on the battery pack',
          listPoint2: 'Battery core is visible due to damage',
          listPoint3: 'Signs of damage from heating or melting',
          contactUs: 'If you are uncertain, please call 1-800-SAWDUST to determine how to properly ship your battery to avoid any fines',
          contactUsCa: 'If you are uncertain, please call (800) 268-4015 to determine how to properly ship your battery to avoid any fines'
        },
        batteryDamageNotEligible: {
          line1: 'Due to the level of damage, this battery pack is not eService eligible per shipping regulation mandated by the Department of Transportation.',
          line2: 'Please call 1-800-SAWDUST to get instructions on how to correctly process your repair submission to avoid any fines.',
          line2Ca: 'Please call (800) 268-4015 to get instructions on how to correctly process your repair submission to avoid any fines.'
        },
        battery9: {
          title: 'Does your battery pack have a black dot, green dot, or checkmark on the serial label?'
        }
      },
      mxfModal: {
        title: 'MX FUEL Battery Shipping Notice',
        body1: 'Prior to shipment, ensure battery is double boxed. Place the battery into a box. Secure the box and place that box into another, larger box. Place packaging material between the two boxes as needed.',
        body2: 'If you are uncertain, please call {phoneNumber} for additional guidance',
        body3: 'Shipping batteries requires stricter shipping and packaging requirements as mandated by the Department of Transportation. Please pay close attention to the shipping instructions and additional labels that print upon submission of a battery to avoid any fines.',
        body4: 'PLEASE NOTE: Batteries CANNOT be shipped via eService from Alaska or Hawaii due to shipping regulations. Please use the link below to find an alternative service option.',
        understand: 'I Understand',
      },
      ineligibleBatteryModal: {
        title: 'Important Safety Notice',
        notEligible: '{description} ({sku}) is not eligible for eService.',
        expandedWarnings: 'Milwaukee Tool is expanding the warnings and instructions for {description}. For more information, please visit {link}.',
        warrantyInquiries: 'For warranty inquiries, please call 1-855-702-7152 for further information.',
      },
    },
    searchAnotherProductLabel: 'Search Another Product',
    pleaseCorrectLabel: 'Please correct the highlighted errors and try again',
    repairItemAddSuccess: 'Tool added successfully!',
    repairItemSaveSuccess: 'Tool updated successfully!',
  },
  productSearch: {
    productSearchTitle: 'Product Search',
    productSearchError: 'There was an error searching for products',
    productSearchNoResults: 'No results found',
    productSearchNoResultsSub: "We can't find any products matching your search",
    notEligible: 'Not eligible for eService',
    findServiceCenter: 'Find a Service Center',
    largeProductCall: 'Please call 1-800-SAWDUST',
    largeProductServiceSupport: 'for service support'
  },
  profile: {
    generalTabLabel: 'General',
    emailAddressLabel: 'Email Address',
    lastLoginLabel: 'Last Login',
    firstNameLabel: 'First Name',
    lastNameLabel: 'Last Name',
    phoneNumberLabel: 'Phone Number',
    preferredContactMethodLabel: 'Preferred Contact Method',
    preferredLangaugeLabel: 'Preferred Language',
    preferredContactMethods: {
      label1: 'Email',
      label2: 'Phone',
    },
    preferredLanguage: {
        english: 'English',
        french: 'French',
    },
    phoneNumberInvalid: 'Please enter a valid phone number',
    pleaseCorrectLabel: 'Please correct the highlighted errors and try again',
    profileSaveSuccess: 'Profile updated successfully!',
    profileSaveError: 'There was an error saving your changes',
    notificationsTabLabel: 'Notifications',
    loadingErrorMessage: 'There was an error loading the page',
    loadingUserErrorMessage: 'There was an error loading your account - please refresh the page to retry',
    saveChangesErrorMessage: 'There was an error saving your preferences',
    saveChangesSuccessMessage: 'Preferences updated successfully!',
    emailNotificationsHeader: 'Email Notifications',
    notifications: {
      label1: 'Submitted',
      label2: 'In Transit',
      label3: 'Received',
      label4: 'In Repair',
      label5: 'Shipped',
      label6: 'Delivered',
      label10: 'Denied Warranty'
    },
    disableAll: 'Disable All',
    loadingUserEmailsErrorMessage: 'There was an error loading your linked emails',
    linkedEmailsTabLabel: 'Linked Emails',
    linkedEmailsIntro:
      'Listed below are all of the email addresses associated with your account. If you\'ve recently changed your email address, or are not seeing service orders you submitted, click the "Add New" button below to link an old email address.',
    emailAddressHeader: 'Email Address',
    isVerifiedHeader: 'Verified*',
    linkedEmailsVerifiedFootnote: '* - In order to complete the verification process, please click the link in the email that is sent to the address you specified',
    addressesTabLabel: 'Addresses',
    defaultAddressLabel: 'Default Shipping Address',
    setDefaultAddress: 'Set Default',
    newAddressLabel: 'Add New Address',
    accountNumberLabel: 'Account Number',
    missingAccountNumberNotification: {
      missingAccountNumber: 'Have an Account Number with Milwaukee Tool?',
      missingAccountNumberBody: 'For the full eservice experience, please add your account number',
    },
    missingAccountNumberModal: {
      requestAccountNumber: 'Request Account Number',
      exceedsCharacterLimit: 'Exceeds character limit',
      successMessage: 'Successfully requested! Please allow 24-48 hours for your account number to be updated',
      errorMessage: 'There was an error submitting your request'
    },
    paymentMethodTab: {
      tabTitle: 'Payment Methods',
      paymentMethodIntro: 'Save time on future repair orders by saving a payment method to your account',
      usCustomerDisclaimer: '(U.S. Customers Only)'
    }
  },
  verifyEmail: {
    verifyErrorMessage: 'There was an error verifying your email',
    verifyErrorMessageBadRequest: 'Email token invalid',
    updateEmailSuccessMessage: 'Email updated successfully!',
  },
  recoverEmail: {
    introHeading: "Don't see your service orders? Changed your email recently?",
    introBody:
      'Use the recovery tool below to link to your old service orders to your new email address. You will receive a confirmation email with further instructions.',
    emailAddressLabel: 'Old Email Address',
    submissionSuccess: 'Request submitted successfully!',
    submissionError: 'There was an error submitting your request',
    invalidEmailMessage: 'Please enter a valid email address',
    introHeadingSubmitted: 'Your request has been submitted!',
    introBodySubmitted: 'Please check your email for a confirmation link to complete the recovery process.',
  },
  repairDetails: {
    repairDetailsTitle: 'Repair Details',
    newOrder: 'New Repair Order',
    caShippingRestrictionToast: 'Due to shipping restrictions, only one package is allowed per service order to Canadian Addresses',
    shippingInfoTitle: 'Shipping Info',
    orderInfoTitle: 'Order Info',
    deniedWarranty: {
      lmrDescription: 'The prices below reflect all repair options for the product. You will receive communication from our Customer Experience team regarding which repairs are required for your tool.',
      repairLabel: 'Repair',
      returnLabel: 'Return',
      recycleLabel: 'Recycle',
      repairDescription: 'Your tool will be repaired under our Lightning Max Repair Program at a maximum cost of ${cost}. Please choose from the payment options below.',
      repairDescriptionNoCost: 'Your tool will be repaired under our Lightning Max Repair Program. Please choose from the payment options below.',
      returnDescription: 'Your tool will be returned to you unrepaired. Shipping Charges of $10.95 may apply. Per Milwaukee Tool\'s Safety Policy, we will return the tool disassembled.',
      recycleDescription: 'Your tool will be recycled at our facility and will not be returned to you.',
      poLabel: 'Use a PO for payment',
      ccLabel: 'Use a Credit Card for payment',
      symptomCode: 'Symptom Code:',
      reason: 'Reason:',
      contactViaEmail: 'Per your preferences, you will be contacted by Customer Experience for payment information via email - {email}.',
      contactViaPhone: 'Per your preferences, you will be contacted by Customer Experience for payment information via phone - {phoneNumber}.',
      changePreferences: 'Change your preferred contact method',
      poNumberPlaceholder: 'Purchase Order Number',
      poNumberNotDescription: 'NOTE: A PO Number is not a Customer Reference Number.',
      deniedWarrantyAction1: 'Repair',
      deniedWarrantyAction1Details: 'Your tool will be repaired under our Lightning Max Repair Program at a maximum cost of ${lmrPrice}.',
      deniedWarrantyAction1_1Details: 'As you selected credit card as your payment method, you will be contacted by Customer Experience to process payment for this repair.',
      deniedWarrantyAction1_2Details: 'As you selected PO as your payment method, your account will be charged with the PO # {poNumber} to process payment for this repair.',
      deniedWarrantyAction2: 'Return',
      deniedWarrantyAction2Details: 'Your tool will be returned to you unrepaired. Shipping charges of $10.95 may apply. Per Milwaukee Tool\'s Safety Policy, the tool will be returned disassembled.',
      deniedWarrantyAction3: 'Recycle',
      deniedWarrantyAction3Details: 'Your tool will be recycled at our facilities and will not be returned to you.',
      repairOrderSummary: 'Repair Order Summary',
      recycleMarkupLine1: 'You have selected to have your tool be recycled.',
      recycleMarkupLine2: 'Once submitted, your tool will be recycled at our facilities and will not be returned to you.',
      returnMarkupLine1: 'You have selected to have your too be returned to you.',
      returnMarkupLine2: 'Once submitted, your tool will be returned to you unrepaired. Shipping charges of $10.95 may apply. Per Milwaukee Tool\'s Safety Policy, the tool will be returned disassembled.',
      repairMarkupLine1: 'Your tool is ready to be submitted for repair.',
      repairMarkupLine2: 'Our Customer Experience team will reach out with the required repairs for your tool after inspection.',
      billingInformation: 'Billing Information',
      cardEndingIn: 'Card ending in',
      purchaseOrderMarkupLine1: 'You have selected Purchase Order as your payment method.',
      purchaseOrderMarkupLine2: 'Once submitted, your account will be charged with the Purchase Order #{purchaseOrderNumber} to process payment for this repair.',
      payLaterMarkupLine1: 'As you selected pay later as your payment method, you will be contacted by Customer Experience to process payment for this repair.',
      submitRepairOrder: 'Submit Repair Order',
      payLater: 'Pay Later',
      purchaseOrder: 'Purchase Order',
      creditCard: 'Credit Card',
      authorizationNotice: 'After submission, an authorization charge of $1.00 will be reflected on your account. This charge will be canceled after the tool has been serviced and the actual repair cost will be transacted, not to exceed the estimated repair cost of',
      authorizationError: 'An error has occurred while authorizing your payment.',
      selectPaymentMethod: 'Select Payment Method'
    
    },
    actionPending: {
      repairLabel: 'Payment Required',
      repairDescriptionPayByCreditCard: 'Your tool was determined to be out of warranty, but you requested that it be repaired under the Lightning Max Repair program, which was ',
      repairDescriptionPayByCreditCardBold: 'paid via credit card.',
      repairDescriptionPayLater: 'Your tool was determined to be out of warranty, but you requested to ',
      repairDescriptionPayLater2: ' and repaired your tool under the Lightning Max Repair program.',
      repairDescriptionPayLater3: 'Customer Experience will be contacting you to process payment for your repair(s). In the meantime, please contact our Customer Experience team with any questions or concerns.',
      repairDescriptionPayLaterBold: 'pay later',
      repairDescriptionPayLaterBold2: 'If no payment is received after 35 days and three attempts to contact you, your tool will automatically be recycled. ',
      repairNoCostDescription: 'Your tool was determined to be out of warranty, but you requested that it be repaired under the Lightning Max Repair program.',
      repairPaymentDescription: 'If no payment is received after 35 days and three attempts to contact you, your tool will automatically be recycled.',
      repairProcessDescription: 'Customer Experience will be contacting you to process payment for your repair(s).',
      repairContactDescription: 'In the meantime, please contact our Customer Experience team with any questions or concerns.',
      returnLabel: 'Tool to be Returned',
      returnDescription: 'Your tool was determined to be out of warranty, and you requested that it be returned to you.',
      returnChargesDescription: 'Your tool will be returned to you unrepaired. Shipping Charges of $10.95 may apply. Per Milwaukee Tool\'s Safety Policy, we will return the tool disassembled.',
      recycleLabel: 'Tool to be Recycled',
      recycleDescription: 'Your tool was determined to be out of warranty, and you requested that it be recycled or we were unable to contact you after 35 days and three attempts.',
      recycleMistakeDescription: 'Please contact our Customer Experience team with any questions or concerns regarding your tool.',
    },
    getServiceOrderError: 'Error Loading Order',
    getServiceOrderErrorDetail: 'There was an error loading your order. Please refresh the page and try again.',
    pdfDownloading: 'Downloading PDF...',
    pdfDownloadError: 'There was an error downloading your documents. Please refresh the page and try again.',
    shippoStatusError: 'Something went wrong processing your order. Please contact customer support.',
    pdfDownloadTimeout: "Your label wasn't ready for download quite yet.  Please refresh the page and try again.",
    pending: 'Pending',
    orderInfo: {
      recycleStatus: {
        status0: 'Processing',
        status0Details: 'Your order was submitted and is still processing. You will be notified once processing has completed. Please allow up to 24 hours.',
        status1: 'Processing',
        status1Details: 'Your order was submitted and is still processing. You will be notified once processing has completed. Please allow up to 24 hours.',
        status2: 'Boxes In Transit',
        status2Details: 'These items from your order were determined to be discontinued, but are covered under warranty and eligible for replacement.',
        status2DetailsExt1: 'Pre-paid packaging is being sent to you in order to properly recycle these items. Please refer to "Tracking Info" below for details.',
        status2DetailsExt2: 'NOTE: Please do not ship these items in any other packaging.',
        status3: 'Boxes Delivered',
        status3Details: 'Pre-paid packaging for recycling your items has been delivered to you. Please ship your items according to the directions provided with the packaging.',
        status4: 'Items In Transit',
        status4Details: 'Your items are on the way to our partner for recycling. You will be notified once all items have been received. Please refer to "Tracking Info" below for details.',
        status5: 'Items Received',
        status5Details: 'Your items were received by our partner for recycling.',
        status5DetailsExt1: ' Once processing has been completed, your replacements will be sent out by our Customer Experience team. Please contact CX with any questions or concerns regarding your order.',
        status6: 'Order In Transit',
        status6Details: 'Replacements for your recycled items are on their way to you. Please refer to "Tracking Info" below for details.',
        status7: 'Order Delivered',
        status7Details: 'Replacements for your recycled items have been delivered to you. Please contact Customer Experience with any questions or concerns regarding your order.',
        status9: 'Processing Error',
        status9Details: 'An error occurred processing your order. Please contact Customer Experience for additional assistance.',
      },
      orderStatus: {
        status0: 'Processing',
        status0Details: 'Your order was submitted and is still processing.',
        status0DetailsExt1: 'This page will automatically update once complete. Please allow up to 5 minutes.',
        status1: 'Submitted',
        status1Details: 'Your order was submitted and is ready for shipping. Please use the "Print Label and Documents" and "Find a Drop-Off Location" buttons below.',
        status2: 'In Transit',
        status2Details: 'Your order is on its way to one of our service centers. Please refer to "Tracking Info" below for details.',
        status3: 'Received',
        status3Details: 'Your order was received at one of our service centers. It will be processed by a qualified repair technician soon.',
        status4: 'In Repair',
        status4Details: 'Your order is being evaluated by a qualified repair technician.',
        status5: 'Shipped',
        status5Details: 'Your order has shipped. Please refer to "Tracking Info" below for details.',
        status6: 'Delivered',
        status6Details: 'Your order was delivered. Please refer to "Tracking Info" below for details.',
        status7: 'Ready for Pickup',
        status7Details: 'Your order is ready for pickup at your nearest factory service center.',
        status8: 'Recycled',
        status8Details: 'Your tool has been recycled. This was per your request, or we were unable to contact you after 35 days and three attempts.',
        status9: 'In Repair - Contact Us',
        status9Details: 'We need additional information from you regarding your order. Please contact Customer Experience for further assistance.',
        status10: 'Out of Warranty',
        status10Details: 'After assessment by our repair technicians, we determined that your tool does not qualify for warranty coverage. Please select from the options below in order to proceed with your order.',
        status10DetailsExt1: 'After assessment by our repair technicians, we determined that your tool does not qualify for warranty coverage. Please select from the options below in order to proceed with your order.',
        status10DetailsExt2: 'NOTE: Once you have submitted a decision, it cannot be changed.',
        status10DetailsExt3: 'If no decision is made after 35 days and three attempts by Customer Experience to contact you, your tool will automatically be recycled.',
      },
      serviceOrderNumber: 'Service Order #',
      proofOfPurchase: 'Proof of Purchase',
      proofOfPurchaseIncluded: 'Included',
      proofOfPurchaseUploaded: 'Uploaded',
      proofOfPurchaseNotProvided: 'Not Provided',
      trackingInfo: 'Tracking Info',
      warrantyUpserted: 'Warranty Preference Submitted',
      warrantyUpsertedDetail: 'Your warranty preference was saved. Thank you for your submission!',
      warrantyUpsertError: 'Error Submitting Warranty Preference',
      warrantyUpsertErrorDetail: 'An error occurred submitting your warranty preference. Please refresh the page and try again.',
    },
    accountInfoTitle: 'Account Info',
    accountInfo: {
      accountName: 'Account Name',
      accountNumber: 'Account Number',
      phone: 'Phone',
      email: 'Email',      
    },
    orderItemsTitle: 'Items in This Order',
  },
  repairItems: {
    repairItemsTitle: 'Repair Order Items',
    noRepairItems: 'No items added yet!',
    continueLabel: 'Continue to Shipping',
  },
  repairProcess: {
    warrantyInformation: {
      toolInfoHeader: {
        yourToolsWarrantyStatus: 'Your Tools Warranty Status'
      },
      general: {
        manufactureDate: 'Manufacture Date',
        unknown: 'Unknown',
        notLegible: 'Not Legible',
        purchaseDate: 'Purchase Date',
        warrantyPeriod: 'Warranty Period',
        serialNumber: 'Serial Number',
        years: 'Years',
      },
      serialNumberView: {
        isMyToolCovered: 'How do I know if my tool is under warranty?',
        warrantyCoverageListTitle: 'Warranty coverage is based on a number of factors, including:',
        warrantyCoverageListItem1: 'Serial Number',
        warrantyCoverageListItem2: 'Proof of Purchase/Invoice/Receipt With Purchase Date',
        warrantyCoverageListItem3: 'Inspection of the tool for misuse',
        findSerialNumber: 'Find Your Serial Number',
        serialNumberExampleAlt: 'Example serial number on a tool',
        parseError: 'Could not resolve the manufacture date from the provided serial number',
        serialNumberExampleText: 'Your serial number can be found on your tool - location will vary depending on the type, but look for the following tag:',
        serialNumberNotFound: 'I can\'t find my serial number',
        serialNumberUnreadable: 'My serial number is unreadable',
        serialNumberSearchTitle: 'Enter Your Serial Number',
        serialNumberSearchPlaceholder: 'Ex. 12345678901234',
        serialLengthRequirementMessage: 'Serial numbers must contain 13-15 characters',
        lookup: 'Look Up',
        toolInfo: 'Tool Info',
        noSpecialCharacters: 'Only letters, numbers, and spaces allowed in this field'
      },
      underWarranty: {
        title: 'Your Tool Meets Initial Warranty Requirements',
        toolCondition: 'We still need to review your tool\'s condition and verify submitted details.',
      },
      pendingWarranty: {
        title: "Your Tool's Warranty Status is Pending",
        sentPOP: 'You indicated that you are sending in proof of purchase/invoice/receipt.',
        reviewAlert: 'We will alert you to the warranty status when we review the document.',
        reviewAlertBattery: 'We will alert you to the warranty status when we receive your item.'
      },
      outWarranty: {
        title: 'Your Tool is Out of Warranty',
        description: 'This tool has a {warranty} year warranty. Based on the serial number and proof of purchase/invoice/receipt you submitted the warranty has expired.',
        description2: 'You can still get your tool repaired through our Lightning Max Repair program.',
      },
      moreInformation: {
        title: 'We Need More Information',
        description: 'Without a serial number or proof of purchase to otherwise, your tool will need to be inspected to find the serial number to determine the start of the warranty.',
        description2: 'You can avoid this by submitting a proof of purchase, invoice, or receipt to show when the warranty began.',
        description3: 'If neither options are applicable, the tools warranty status will be evaluated during the submission process by a technician and you will be contacted at a later date.',
        enterSerialNumber: 'Enter Serial Number',
        uploadPOP: 'Upload Proof of Purchase/Invoice/Receipt',
      },
      enterRepairDetails: 'Enter Repair Details',
      noWarranty: 'If your tool is determined to be out of warranty:',
      warrantyCoverage: {
        title: 'Your warranty coverage is based on a number of factors:',
        factor1: 'Serial Number',
        factor2: 'Proof of Purchase/Invoice/Receipt With Purchase Date',
        factor3: 'Inspection of the tool for misuse',
      },
      cancelSubmission: 'Are you sure you want to cancel your submission? All progress will be lost.',
      uploadProofOfPurchase: 'Upload Proof of Purchase, Invoice, or Receipt',
      upload: 'Upload',
      whyUpload: 'Why upload a Proof of Purchase, invoice, or receipt?',
      proofOfPurchaseExplanation: 'A Proof of Purchase will help us verify the date of purchase and determine the start date of warranty coverage.',
      proofOfPurchaseManufactureDate: 'If you don\'t have a Proof of Purchase, warranty coverage will be determined based on the manufactured date of the tool.',
      dragToUpload: 'Drag and drop to upload your Proof of Purchase',
      dragOrDrop: 'OR',
      clickToUpload: 'Browse files',
      validFileTypes: 'Valid file types include: .jpg, .jpeg, .png, .pdf',
      maxFileSize: 'Maximum file size is 5 MB',
      proofOfPurchaseIncluded: 'Proof of Purchase included in shipment',
      proofOfPurchaseNotIncluded: 'Proof of Purchase NOT uploaded or included in shipment',
      noProofOfPurchaseModal: {
        title: 'Confirm Not Uploaded or Included',
        confirm: 'If you do not include a Proof of Purchase, the serial number will be used to determine the warranty eligibility date.',
      },
      uploadInProgress: 'Uploading file...',
      uploadSuccess: 'File uploaded successfully!',
      uploadError: 'An error occurred uploading your file - please try again',
      invalidFileType: 'The file type you selected is invalid - please try again with a different file',
      invalidFileSize: 'The file you selected is too big to upload - please try again with a smaller file',
      attemptParse: 'We attempted to automatically parse your uploaded file for a purchase date, but no dates were detected.',
      pleaseEnterDate: 'Please enter the purchase date manually below.',
      parseSuccessful: 'We were able to identify the purchase date based on your upload!',
      pleaseConfirm: 'Please confirm the purchase date below before proceeding.',
      detectedPurchaseDate: 'Detected Purchase Date',
      checkWarrantyStatus: 'Check Warranty Status',
      missingPurchaseDate: 'Please enter a purchase date',
    },
    packagesSummary: {
      poNumberLabel: 'PO Number',
      packagesLabel: 'Package(s)',
    },
    repairItemsSummary: {
      repairItemsLabel: 'Repair Items',
      serialNumberLabel1: 'Serial Number',
      serialNumberLabel2: 'Serial Number: ',
      serialNumberNotProvided: 'Not Provided',
      tagNumberLabel: 'Tag ID/Customer Reference #: ',
      proofOfPurchaseLabel: 'Proof of Purchase: ',
      proofOfPurchaseIncluded: 'Included in Shipment',
      proofOfPurchaseNotIncluded: 'Not Included',
      underWarrantyLabel: 'Under Warranty: ',
      problemLabel: 'Problem: ',
      otherProblemLabel: 'Other: ',
      includedLabel: 'Included: ',
      otherIncludedLabel: 'Other: ',
      batteryIncludedLabel: 'Battery: ',
    },
    shippingInfoSummary: {
      shippingInfoLabel: 'Shipping Info:',
      accountInfoLabel: 'Account Info:',
      nameLabel: 'Name:',
      phoneNumberLabel: 'Phone:',
      emailLabel: 'Email:',
      preferredContactMethodLabel: 'Preferred Contact Method:',
      accountNumberLabel: 'Account Number:',
    },
    caRestrictionTitle: 'Shipping Restrictions',
    caRestrictionText: 'Due to shipping restrictions, Canadian service orders are limited to one package per service order. If multiple packages are needed please submit multiple service orders.',
    usRestrictionText: 'Due to shipping restrictions, service orders are limited to a maximum of 8 packages per order. If more than 8 packages are required please split your order into multiple Service Orders',
    shippingInstructions: {
      title: 'Your Service Order Has Additional Shipping Instructions',
      acknowledgementCheckboxLabel: 'I understand',
      continueButton: 'Continue',
      cancelButton: 'Cancel',
      rotaryLaserInstructions: 'Please send Rotary Laser(s) in the hard plastic carrying case when sending in for calibration',
      acknowledgementRequiredMessage: 'Please acknowledge that you understand to continue'
    },
  },
  shipping: {
    shippingTitle: 'Shipping',
    numberOfPackages: 'Number of Packages',
    packagesCountRequired: 'Number of packages must be greater than 0',
    maximumBatteries1: 'PLEASE NOTE:',
    maximumBatteries2: 'If you are shipping batteries, a maximum of seven batteries per package is allowed',
    shippingInfo: 'Shipping Info*',
    loadingUserErrorMessage: 'There was an error loading your addresses',
    accountInfo: 'Account Info',
    pleaseCorrectLabel: 'Please correct the highlighted errors and try again',
    noDefaultAddress: 'No default shipping address set',
    useAnotherAddress: 'Use another address',
    useThisAddress: 'Use This Address',
    otherAddressesLabel: 'Other Addresses',
    addAnotherAddress: 'Add New',
    addAddress: 'Add Address',
    addAnAddress: 'Please add/select a valid shipping address',
    nameLabel: 'Name:',
    phoneNumberLabel: 'Phone:',
    emailLabel: 'Email:',
    preferredContactMethodLabel: 'Preferred Contact Method:',
    accountInfoRequired: 'Please complete your account info',
    accountNumberLabel: 'Account Number',
    shippingRestrictedModal: {
      title: 'Battery Shipping Restrictions',
      shippingProhibited: 'Customers are prohibited from shipping any and all batteries via eService from the selected state. Only tools can be included in eService shipments.',
      serviceCenter: 'If you require battery repair, please see your local service center. You can find the location of your nearest service center by clicking the following link: {link}',
      understand: 'I Understand'
    },
    shippingAddressLabel: 'Shipping Address',
    noShippingAddress: 'You don\'t have any addresses yet!',
  },
  orderSummary: {
    title: 'Order Summary',
    printPaperwork: 'Print Label and Documents',
    repairItemsLabel: 'Repair Items ({count})',
    repairItemsProcessing: 'These items are still processing. This page will automatically update once processing has completed. Please allow up to five minutes.',
    repairItemsProcessed: 'These items are ready for shipment. Please use the "Print Label and Documents" and "Find a Drop-Off Location" buttons below.',
    getServiceOrdersError: 'Error Retrieving Orders',
    getServiceOrdersErrorDetail: 'An error occurred getting your orders - please refresh the page to try again.',
    findProvider: 'Find a {provider} Drop-Off Location',
    newOrder: 'New Repair Order',
    viewDetails: 'View Details',
    otherItemsLabel: 'Other Items ({count})',
    otherItemsProcessing: 'Items in this order are still processing. You will be notified once processing has completed. Please allow up to 24 hours.',
    otherItemsProcessed: 'These items have been processed. Please click "View Details" below for next steps.',
    contactCustomerExperience: 'Contact Customer Experience',
  },
  userMetadataModal: {
    title: 'Edit Profile',
    completeTitle: 'Complete Profile',
    incomplete: 'We need a few extra details from you before you can submit repair requests - please fill out all required fields below.',
    emailAddressLabel: 'Email Address',
    firstNameLabel: 'First Name*',
    firstNameInvalid: 'First Name field is required',
    lastNameLabel: 'Last Name*',
    lastNameInvalid: 'Last Name field is required',
    phoneNumberLabel: 'Phone Number*',
    phoneNumberInvalid: 'Please enter a valid phone number',
    preferredContactMethodLabel: 'Preferred Contact Method',
    preferredLanguageLabel: 'Preferred Language',
    accountNumberLabel: 'Account Number',
  },
  footer: {
    legal: 'Legal',
    privacy: 'Privacy',
    oneTrust: 'Do Not Sell My Personal Information',
    en: 'EN',
    frCa: 'FR',
    language: 'Language'
  },
  serviceOrderDoc:{
    instructionHeader: 'Instructions',
    instructionsLine1: 'Include one copy of this sheet in each package',
    instructionsLine2: 'Keep one copy of this sheet for your records',
    instructionsLine3: 'Remove any blades, bits, or accessories and do not include the package',
    shippingInfoHeader: 'Shipping Information',
    packageCount: 'package(s) shipping from:',
    trackingLabel: 'Tracking Number',
    personalInformationHeader: 'Personal Information',
    phoneNumberLabel: 'Phone Number:',
    perferredContactLabel: 'Preferred Contact Method:',
    accountNumberLabel: 'Account Number:',
    itemsHeader: 'Repair Items',
    serialNumberLabel: 'Serial Number:',
    tagIdCustomerReferenceLabel: 'Tag ID/Customer Reference Number:',
    proofOfPurchaseLabel: 'Proof of Purchase:',
    problemsLabel: 'Problems:',
    includedLabel: 'Included:',
    popUploaded: 'Uploaded',
    popIncluded: 'Included',
    popNotIncluded: 'Not Included',
    includedBatteriesLabel: 'Included Batteries:'
  },
  orderDetails: {
    processingDetail: 'Processing your order - this may take a few minutes.',
    trackingInfoError: 'Processing Error',
    trackingInfoErrorDetail: 'We ran into an issue processing tracking for your order. Please contact Customer Experience for further assistance.',
    findProvider: 'Find a Drop-Off Location',
    printPaperwork: 'Print Label and Documents',
    pdfDownloading: 'Downloading PDF...',
    pdfDownloadingDetail: 'Printing your shipping labels and documents',
    pdfDownloadError: 'Error Downloading Documents',
    pdfDownloadErrorDetail: 'An error occurred printing your shipping documents. Please refresh the page and try again.',
    findNearestServiceCenter: 'Find Nearest Service Center',
  },
  lmrOptions: {
    lmrLabel: '{lmrLabel}: ${lmrPrice}',
    lmrLabelCa: '{lmrLabel} (CAD): ${lmrPrice}',
    lmrUnavailable: 'If the tool is not covered under warranty, our Customer Experience Team will reach out to you with LMR Price upon repair technician assessment. Please call 1-800-SAWDUST with any additional questions and concerns.',
    lmrUnavailableCa: 'If the tool is not covered under warranty, our Customer Experience Team will reach out to you with LMR Price upon repair technician assessment. Please call  (800) 268-4015 with any additional questions and concerns.',
  },
  square: {
    addCard: 'Add Card',
    addNewCard: 'Add New Card',
    paymentMethodAddedSuccess: 'Card added successfully',
    getPaymentMethodError: 'An error has occured while retrieving your payment methods',
    deletePaymentMethodSuccess: ' Card deleted successfully',
    cardholderNamePlaceholder: 'Cardholder Name',
    addCardDisclaimer: 'Credit card payments available for U.S. Customer only',
    addCreditCardError: 'An error has occured while saving your credit card. Please try again.',
    deletePaymentMethodError: 'An error has occured while deleting your payment method. Please try again.'
  }
}