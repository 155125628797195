import { GET_NOTIFICATION_TYPES, GET_USER_NOTIFICATION_HISTORY, GET_NOTIFICATION_HISTORY_BY_SERVICE_ORDER } from '../types';
import { error, success } from '../utils';

export let initialState = {
  getNotificationTypes: {
    isLoading: false,
    isError: false,
    notificationTypes: [],
  },
  getUserNotificationHistory: {
    data: [],
    isLoading: false,
    isError: false,
  },
  getNotificationHistoryByServiceOrder: {
    data: [],
    isLoading: false,
    isError: false,
  },
};

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_NOTIFICATION_TYPES:
      return {
        ...state,
        getNotificationTypes: {
          isLoading: true,
          isError: false,
          notificationTypes: [],
        },
      };
    case success(GET_NOTIFICATION_TYPES):
      return {
        ...state,
        getNotificationTypes: {
          isLoading: false,
          isError: false,
          notificationTypes: action.payload.data,
        },
      };
    case error(GET_NOTIFICATION_TYPES):
      return {
        ...state,
        getNotificationTypes: {
          isLoading: false,
          isError: true,
          notificationTypes: [],
        },
      };
    case GET_USER_NOTIFICATION_HISTORY:
      return {
        ...state,
        getUserNotificationHistory: {
          data: [],
          isLoading: true,
          isError: false,
        },
      };
    case success(GET_USER_NOTIFICATION_HISTORY):
      return {
        ...state,
        getUserNotificationHistory: {
          data: action.payload.data,
          isLoading: false,
          isError: false,
        },
      };
    case error(GET_USER_NOTIFICATION_HISTORY):
      return {
        ...state,
        getUserNotificationHistory: {
          data: [],
          isLoading: false,
          isError: false,
        },
      };    
      case GET_NOTIFICATION_HISTORY_BY_SERVICE_ORDER:
        return {
          ...state,
          getNotificationHistoryByServiceOrder: {
            data: [],
            isLoading: true,
            isError: false,
          },
        };
      case success(GET_NOTIFICATION_HISTORY_BY_SERVICE_ORDER):
        return {
          ...state,
          getNotificationHistoryByServiceOrder: {
            data: action.payload.data,
            isLoading: false,
            isError: false,
          },
        };
      case error(GET_NOTIFICATION_HISTORY_BY_SERVICE_ORDER):
        return {
          ...state,
          getNotificationHistoryByServiceOrder: {
            data: [],
            isLoading: false,
            isError: false,
          },
        };
    default:
      return state;
  }
};

export default reducer;
