import { Button, ListGroup, ListGroupItem, Loader, useToggle } from '@met/react-components';
import { Button as AntButton, Card, Col, List, PageHeader, Row, Space, Typography } from 'antd';

import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { countriesActions, serviceCenterActions, statesActions, usersActions } from '../../../../store';
import ServiceCenterLine from './ServiceCenterLine';
import ServiceCenterModal from './ServiceCenterModal';
import ServiceCenterMappingLine from './ServiceCenterMappingLine';
import StateMappingModal from './StateMappingModal';
import PermissionGatedComponent from '../../../../components/PermissionGatedComponent/PermissionGatedComponent';
import { Permissions } from '../../../../enums';
import { useUser } from '../../../../hooks';
import DefaultServiceCenterLine from './DefaultServiceCenterLine';
import DefaultServiceCenterModal from './DefaultServiceCenterModal';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;

const ServiceCentersTab = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const fetchingServiceCenters = useSelector(state => state.serviceCenters.getServiceCenters.isLoading);
  const serviceCenters = useSelector(state => state.serviceCenters.getServiceCenters.serviceCenters);
  const defaultServiceCenters = useSelector(
    state => state.serviceCenters.getDefaultServiceCenterMappings.defaultServiceCenterMappings
  );
  const fetchingServiceCenterMappings = useSelector(state => state.serviceCenters.getServiceCenterMappings.isLoading);
  const serviceCenterMappings = useSelector(
    state => state.serviceCenters.getServiceCenterMappings.serviceCenterMappings
  );

  const { permissions } = useUser();
  const userPermissions = permissions();
  const [currentServiceCenter, setCurrentServiceCenter] = useState({});
  const [currentStateMapping, setCurrentStateMapping] = useState({});
  const [currentDefaultServiceCenter, setCurrentDefaultServiceCenter] = useState({});
  const [isStateMappingNew, setIsStateMappingNew] = useState(false);

  const [showServiceCenterModal, toggleServiceCenterModal] = useToggle(false);
  const [showStateMappingModal, toggleStateMappingModal] = useToggle(false);
  const [showDefaultServiceCenterModal, toggleDefaultServiceCenterModal] = useToggle(false);

  useEffect(() => {
    if (userPermissions[Permissions.SERVICECENTERS_SERVICECENTERS]) {
      dispatch(serviceCenterActions.getServiceCenters()).catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.serviceOrders.loadingServiceOrderStatusesErrorMessage' }), {
          autoClose: false,
        });
      });

      dispatch(serviceCenterActions.getDefaultServiceCenterMappings()).catch(() => {
        toast.error(
          intl.formatMessage({
            id: 'admin.defaultServiceCenterMapping.loadingDefaultServiceCenterMappingsErrorMessage',
          }),
          {
            autoClose: false,
          }
        );
      });
    }
    if (userPermissions[Permissions.SERVICECENTERS_STATES]) {
      dispatch(serviceCenterActions.getServiceCenterMappings()).catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.serviceOrders.loadingServiceOrderStatusesErrorMessage' }), {
          autoClose: false,
        });
      });
    }
    dispatch(countriesActions.getCountries()).catch(() => {
      toast.error(intl.formatMessage({ id: 'address.loadingCountriesErrorMessage' }), { autoClose: false });
    });
    dispatch(statesActions.getStates()).catch(() => {
      toast.error(intl.formatMessage({ id: 'address.loadingStatesErrorMessage' }), { autoClose: false });
    });
  }, [dispatch]);

  const handleNewServiceCenterClick = () => {
    handleClearAddressValidation();
    setCurrentServiceCenter({ isActive: true });
    toggleServiceCenterModal();
  };

  const handleEditServiceCenterClick = center => {
    if (defaultServiceCenters && defaultServiceCenters.some(x => x.serviceCenterId == center.id)) {
      center.isDefault = true;
    } else {
      center.isDefault = false;
    }
    handleClearAddressValidation();
    setCurrentServiceCenter(center);
    toggleServiceCenterModal();
  };

  const handleCancelClick = () => {
    handleClearAddressValidation();
    toggleServiceCenterModal();
  };

  const handleClearAddressValidation = () => {
    dispatch(usersActions.clearAddressValidation());
  };

  const handleConfirmEditClick = (
    id,
    addressLine1,
    addressLine2,
    city,
    state,
    countryCode,
    postalCode,
    description,
    axId,
    isActive
  ) => {
    dispatch(
      serviceCenterActions.upsertServiceCenter(
        id,
        axId,
        description,
        addressLine1,
        addressLine2,
        city,
        state,
        countryCode,
        postalCode,
        isActive
      )
    )
      .then(payload => {
        toggleServiceCenterModal();
        toast.success(intl.formatMessage({ id: 'admin.serviceCenter.serviceCenterUpdated' }), { autoClose: 5000 });
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.serviceCenter.serviceCenterUpdateError' }), { autoClose: 5000 });
      });
  };

  const handleEditMappingClick = mapping => {
    setIsStateMappingNew(false);
    setCurrentStateMapping(mapping);
    toggleStateMappingModal();
  };
  const handleNewStateMappingClick = () => {
    setIsStateMappingNew(true);
    setCurrentStateMapping({});
    toggleStateMappingModal();
  };

  const handleStateMappingCancel = () => {
    setCurrentStateMapping({});
    toggleStateMappingModal();
  };

  const handleStateMappingDelete = state => {
    dispatch(serviceCenterActions.deleteServiceCenterMapping(state)).then(() => {
      toast.success(intl.formatMessage({ id: 'admin.stateMapping.successDeleteMessage' }), { autoClose: 5000 });
      handleStateMappingCancel();

      dispatch(serviceCenterActions.getServiceCenterMappings()).catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.serviceOrders.loadingServiceOrderStatusesErrorMessage' }), {
          autoClose: false,
        });
      });
    });
  };

  const handleEditDefaultServiceCenterClick = serviceCenter => {
    setCurrentDefaultServiceCenter(serviceCenter);
    toggleDefaultServiceCenterModal();
  };

  const handleDefaultServiceCenterCancel = () => {
    setCurrentDefaultServiceCenter({});
    toggleDefaultServiceCenterModal();
  };

  const handleDefaultServiceCenterSubmit = values => {
    if (values) {
      dispatch(serviceCenterActions.updateDefaultServiceCenter(values)).then(() => {
        toast.success(
          intl.formatMessage(
            { id: 'admin.defaultServiceCenterMapping.successUpdateMessage' },
            { countryCode: values.countryCode }
          ),
          { autoClose: 5000 }
        );
        handleDefaultServiceCenterCancel();

        dispatch(serviceCenterActions.getDefaultServiceCenterMappings());
      });
    }
  };

  const handleStateMappingSubmit = values => {
    if (isStateMappingNew) {
      //Insert

      // Check for duplicate state mapping
      if (!checkDuplicateStateMapping(values.state)) {
        // Run insert
        dispatch(serviceCenterActions.insertServiceCenterMapping(values)).then(() => {
          toast.success(intl.formatMessage({ id: 'admin.stateMapping.successAddMessage' }), { autoClose: 5000 });
          handleStateMappingCancel();

          dispatch(serviceCenterActions.getServiceCenterMappings()).catch(() => {
            toast.error(intl.formatMessage({ id: 'admin.serviceOrders.loadingServiceOrderStatusesErrorMessage' }), {
              autoClose: false,
            });
          });
        });
      } else {
        // Throw duplicate error
        toast.error(intl.formatMessage({ id: 'admin.stateMapping.duplicateMapping' }), { autoClose: 5000 });
      }
    } else {
      //Update

      // Run update
      dispatch(serviceCenterActions.updateServiceCenterMapping(values)).then(() => {
        toast.success(intl.formatMessage({ id: 'admin.stateMapping.successUpdateMessage' }), { autoClose: 5000 });
        handleStateMappingCancel();

        dispatch(serviceCenterActions.getServiceCenterMappings()).catch(() => {
          toast.error(intl.formatMessage({ id: 'admin.serviceOrders.loadingServiceOrderStatusesErrorMessage' }), {
            autoClose: false,
          });
        });
      });
    }
  };

  const checkDuplicateStateMapping = checkState => {
    //NOTE: This will only currently work for the United States as they will only have 1 service center per state
    //TODO: Change logic around to support Canadian state/provinces to support multiple service centers

    const res = serviceCenterMappings.filter(({ state }) => state.toLowerCase() === checkState.toLowerCase());
    // .map(({state}) => state);
    return res.length > 0;
  };

  const searchResultsList =
    serviceCenters &&
    serviceCenters.map((serviceCenter, soi) => {
      return (
        <ListGroupItem key={`search-result-${soi}`}>
          <ServiceCenterLine
            serviceCenter={serviceCenter}
            editServiceCenterClickOnClick={() => handleEditServiceCenterClick(serviceCenter)}
          />
        </ListGroupItem>
      );
    });

  const defaultServiceCenterMappingList = defaultServiceCenters && (
    <List
      data-test-id='defaultServiceCenterMappingList'
      itemLayout='horizontal'
      bordered={true}
      dataSource={defaultServiceCenters}
      renderItem={(item, index) => (
        <DefaultServiceCenterLine
          defaultServiceCenter={item}
          editDefaultServiceCenterClick={() => handleEditDefaultServiceCenterClick(item)}
          index={index}
        />
      )}
    />
  );

  const serviceCenterMappingsList =
    serviceCenterMappings &&
    serviceCenterMappings.map((mapping, soi) => {
      return (
        <ListGroupItem key={`mapping-search-result-${soi}`}>
          <ServiceCenterMappingLine
            serviceCenterMapping={mapping}
            editServiceCenterMappingOnClick={() => handleEditMappingClick(mapping)}
          />
        </ListGroupItem>
      );
    });

  const fetchingResultsMarkup = (fetchingServiceCenters || fetchingServiceCenterMappings) && (
    <Row className='mt-3'>
      <Col center='xs'>
        <Loader type='tiles' />
      </Col>
    </Row>
  );

  const serviceCenterTitleMarkup = (
    <Row className='mt-3'>
      <Col flex={7}>
        <h3>
          <FormattedMessage id='admin.serviceCenters.heading' />
        </h3>
      </Col>
      <Col flex={1} style={{ justifyContent: 'flex-end', display: 'flex' }}>
        <Button data-test-id='adminAddServiceCenterButton' primary icon='plus' onClick={() => handleNewServiceCenterClick()}>
          <FormattedMessage id='general.add' />
        </Button>
      </Col>
    </Row>
  );

  const serviceCentersMarkup = serviceCenters && (
    <PermissionGatedComponent permissionRequired={Permissions.SERVICECENTERS_SERVICECENTERS}>
      <PageHeader
        title={intl.formatMessage({ id: 'admin.serviceCenters.serviceCenters' })}
        extra={[
          <AntButton data-test-id='adminServiceCentersAddButton' key='add' type='primary' icon={<PlusOutlined />} onClick={() => handleNewServiceCenterClick()}>
            <span>
              <FormattedMessage id='general.add' />
            </span>
          </AntButton>,
        ]}
      >
        {searchResultsList}
      </PageHeader>
    </PermissionGatedComponent>
  );

  const defaultServiceCenterMarkup = defaultServiceCenters && (
    <PermissionGatedComponent permissionRequired={Permissions.SERVICECENTERS_SERVICECENTERS}>
      <PageHeader title={intl.formatMessage({ id: 'admin.serviceCenters.defaultServiceCenters' })}>
        {defaultServiceCenterMappingList}
      </PageHeader>
    </PermissionGatedComponent>
  );

  const stateMappingMarkup = (
    <PermissionGatedComponent permissionRequired={Permissions.SERVICECENTERS_STATES}>
      <PageHeader
        title={intl.formatMessage({ id: 'admin.stateMappings.heading' })}
        extra={[
          <AntButton data-test-id='adminAddStateMappingButton' key='add' type='primary' icon={<PlusOutlined />} onClick={() => handleNewStateMappingClick()}>
            <span>
              <FormattedMessage id='general.add' />
            </span>
          </AntButton>,
        ]}
      >
        <ListGroup>{serviceCenterMappingsList}</ListGroup>
      </PageHeader>
    </PermissionGatedComponent>
  );

  const resultsMarkup = !fetchingServiceCenters && !fetchingServiceCenterMappings && (
    <Space direction='vertical' style={{ width: '100%' }}>
      {defaultServiceCenterMarkup}
      {serviceCentersMarkup}
      {stateMappingMarkup}
    </Space>
  );

  const stateMappingModal = (
    <StateMappingModal
      hidden={showStateMappingModal}
      toggle={toggleStateMappingModal}
      stateMapping={currentStateMapping}
      isNew={isStateMappingNew}
      onCancel={handleStateMappingCancel}
      onFormSubmit={handleStateMappingSubmit}
      onDelete={handleStateMappingDelete}
    />
  );

  const serviceCenterModal = (
    <ServiceCenterModal
      hidden={!showServiceCenterModal}
      toggle={toggleServiceCenterModal}
      serviceCenter={currentServiceCenter}
      onCancelClick={handleCancelClick}
      onSaveChangesClick={handleConfirmEditClick}
    />
  );

  const defaultServiceCenterModal = (
    <DefaultServiceCenterModal
      hidden={showDefaultServiceCenterModal}
      toggle={toggleDefaultServiceCenterModal}
      defaultServiceCenter={currentDefaultServiceCenter}
      onCancel={handleDefaultServiceCenterCancel}
      onFormSubmit={handleDefaultServiceCenterSubmit}
    />
  );

  return (
    <Fragment>
      <PageHeader title={intl.formatMessage({ id: 'admin.serviceCenters.heading' })}>
        {fetchingResultsMarkup}
        {resultsMarkup}
      </PageHeader>
      {serviceCenterModal}
      {defaultServiceCenterModal}
      {stateMappingModal}
    </Fragment>
  );
};

export default ServiceCentersTab;
