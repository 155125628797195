import { Button, Card, Col, notification, Row, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { CollapsibleOrderList, NotFound, PageLoader, AccountInfo, ShippingInfo } from '../../components';
import { useContact, useOrderStatus, useRecycling, useServiceOrder, useUser } from '../../hooks';
import { useRepairProcessContext } from '../RepairProcess/RepairProcessContext';
import {
  OrderInfo,
  OrderProgress,
  OrderStatus,
  RecycleOrderInfo,
  RecycleOrderProgress,
  RecycleOrderStatus,
} from './components';

const { Title } = Typography;

const RepairDetail = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { setRepairItems, setShipping } = useRepairProcessContext();
  const { serviceOrder, loadingServiceOrder, getServiceOrder } = useServiceOrder();
  const { serviceOrderRecycling, loadingServiceOrderRecycling, getServiceOrderRecycling } = useRecycling();
  const { name, phoneNumber, email, accountNumber } = useUser();
  const { getEmailLink, getPhoneLink } = useContact();
  const {
    shippoTrackingFailure,
    callToRecycleFailure,
    startListeningForShippoTracking,
    stopListeningForShippoTracking,
    trackingNumber,
    setTrackingNumber,
  } = useOrderStatus();

  const [serviceOrderId, setServiceOrderId] = useState(null);
  const [orderItems, setOrderItems] = useState(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    return () => {
      stopTracking();
    };
  }, []);

  useEffect(() => {
    let pathParts = location.pathname.split('/');

    if (pathParts.length < 3) {
      history.replace('/dashboard');
    } else {
      setServiceOrderId(pathParts[2]);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (serviceOrderId) {
      dispatch(getServiceOrder(serviceOrderId)).catch(payload => {
        stopListeningForShippoTracking();

        notification.error({
          message: intl.formatMessage({ id: 'repairDetails.getServiceOrderError' }),
          description: intl.formatMessage({ id: 'repairDetails.getServiceOrderErrorDetail' }),
          duration: null,
        });

        if ([400, 403, 404].includes(payload.response.status)) {
          setNotFound(true);
        }
      });
    }
  }, [serviceOrderId]);

  useEffect(() => {
    if (serviceOrder) {
      const { serviceOrderRecyclingId, trackingNumber } = serviceOrder;

      if (serviceOrderRecyclingId) {
        // Is a recycling record
        dispatch(getServiceOrderRecycling(serviceOrderRecyclingId));
      } else {
        // Is not a recycling record
        setOrderItems(new Array(serviceOrder));
        setTrackingNumber(trackingNumber);
      }
    }
  }, [serviceOrder]);

  useEffect(() => {
    if (serviceOrderRecycling && serviceOrder && serviceOrderRecycling.id == serviceOrder.serviceOrderRecyclingId) {
      setOrderItems(serviceOrderRecycling.serviceOrders);
    }
  }, [serviceOrderRecycling]);

  useEffect(() => {
    if (serviceOrder && orderItems && orderItems.length > 0) {
      const { serviceOrderRecyclingId } = serviceOrder;

      if (!serviceOrderRecyclingId && !serviceOrder.trackingNumber && serviceOrder.shippoStatus !== 5) {
        // If tracking is not found and Shippo didn't fail, listen for tracking
        startTracking(serviceOrder.serviceOrderGroupId);
      }
    }
  }, [orderItems]);

  useEffect(() => {
    if (shippoTrackingFailure) {
      stopTracking();

      notification.error({
        message: intl.formatMessage({ id: 'orderDetails.trackingInfoError' }),
        description: intl.formatMessage({ id: 'orderDetails.trackingInfoErrorDetail' }),
        duration: null,
      });
    }
  }, [shippoTrackingFailure]);

  useEffect(() => {
    if (trackingNumber) {
      stopTracking();
    }
  }, [trackingNumber]);

  const startTracking = groupId => {
    startListeningForShippoTracking(groupId, trackingNumber => {
      for (let i = 0; i < orderItems.length; i++) {
        orderItems[i].trackingNumber = trackingNumber;
      }
    });

    notification.destroy();

    notification.info({
      message: intl.formatMessage({ id: 'general.processing' }),
      description: intl.formatMessage({ id: 'orderDetails.processingDetail' }),
      duration: null,
    });
  };

  const stopTracking = () => {
    notification.destroy();

    stopListeningForShippoTracking();
  };

  const handleNewOrderClick = () => {
    setRepairItems([]);
    setShipping({});

    history.push('/repairprocess/addanother');
  };

  if (notFound) {
    return <NotFound />;
  }

  if (!serviceOrder || loadingServiceOrder || loadingServiceOrderRecycling) {
    return <PageLoader />;
  }

  const progressMarkup = () => {
    if (serviceOrder.serviceOrderRecyclingId && serviceOrderRecycling) {
      if (callToRecycleFailure) {
        return null;
      } else if (serviceOrderRecycling.status === 1) {
        // Show user the normal order progress until we've processed their order
        return (
          <Card style={{ textAlign: 'center' }}>
            <OrderProgress trackingNumber={null} />
          </Card>
        );
      } else {
        return (
          <Card style={{ textAlign: 'center' }}>
            <RecycleOrderProgress />
          </Card>
        );
      }
    } else {
      return !shippoTrackingFailure ? (
        <Card style={{ textAlign: 'center' }}>
          <OrderProgress trackingNumber={trackingNumber} />
        </Card>
      ) : null;
    }
  };

  const orderItemsStatusMarkup =
    serviceOrder.serviceOrderRecyclingId && serviceOrderRecycling ? (
      <RecycleOrderStatus callToRecycleFailure={callToRecycleFailure} />
    ) : (
      <OrderStatus trackingNumber={trackingNumber} shippoTrackingFailure={shippoTrackingFailure} />
    );

  const orderInfoMarkup =
    serviceOrder.serviceOrderRecyclingId && serviceOrderRecycling ? <RecycleOrderInfo /> : <OrderInfo />;

  return (
    <Space direction='vertical' size='middle' style={{ width: '100%', padding: '0 1em' }}>
      <Row style={{ width: '100%' }}>
        <Col flex={1}>
          <Title level={2}>
            <FormattedMessage id='repairDetails.repairDetailsTitle' />
          </Title>
        </Col>
        <Col flex={0}>
          <Button type='primary' ghost onClick={() => handleNewOrderClick()} data-test-id='newOrderButton'>
            <FormattedMessage id='repairDetails.newOrder' />
          </Button>
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col flex={1}>{progressMarkup()}</Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col flex={1}>
          <Card>
            <Row style={{ width: '100%' }}>
              <Col xs={24} md={8}>
                {orderInfoMarkup}
              </Col>
              <Col xs={24} md={8}>
                <ShippingInfo
                  senderName={serviceOrder.recipient}
                  senderLine1={serviceOrder.addressLine1}
                  senderCity={serviceOrder.city}
                  senderState={serviceOrder.state}
                  senderPostalCode={serviceOrder.postalCode}
                  senderCountry={serviceOrder.countryCode}
                />
              </Col>
              <Col xs={24} md={8}>
                <AccountInfo accountName={name} accountNumber={accountNumber} phone={phoneNumber} email={email} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <div>
        <Title level={3}>
          <FormattedMessage id='repairDetails.orderItemsTitle' />
        </Title>
        {orderItemsStatusMarkup}
      </div>
      <CollapsibleOrderList orders={orderItems} />
      <Card>
        <Title level={4} id='contact'>
          <FormattedMessage id='orderSummary.contactCustomerExperience' />
        </Title>
        <Space size='large' wrap>
          {getPhoneLink()}
          {getEmailLink()}
        </Space>
      </Card>
    </Space>
  );
};

export default RepairDetail;
