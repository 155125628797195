import React, { Fragment, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Button, Checkbox, Form, Input, Modal, Popconfirm, Select } from 'antd';
import { useSelector } from 'react-redux';

const ProductLmrMappingModal = ({ hidden, toggle, isAdd, currentLmrMapping, handleSubmit, handleDelete }) => {
  const formRef = useRef();
  const intl = useIntl();

  const lmrCategoryTypes = useSelector(state => state.lmrCategoryTypes.getLmrCategoryTypes.lmrCategoryTypes);

  const lmrCategoryTypeOptions =
    lmrCategoryTypes &&
    lmrCategoryTypes.map((item, index) => {
      return (
        <Select.Option
          data-test-id={'lmrCategoryTypeOption-' + index}
          key={'lmrCategoryTypeOption-' + index}
          value={item.id}
        >
          {item.description}
        </Select.Option>
      );
    });

  return (
    <Modal
      title={isAdd ? 'Add' : 'Edit'}
      visible={hidden}
      onCancel={toggle}
      footer={[
        <Button data-test-id='adminProductLMRMappingCancelButton' key={'cancelBtn'} type='default' onClick={toggle}>
          Cancel
        </Button>,
        <Button data-test-id='adminProductLMRMappingSaveButton' onClick={() => formRef?.current?.submit()} type='primary'>
          Save
        </Button>,
        !isAdd && (
          <Popconfirm
            title={intl.formatMessage({ id: 'general.areYouSure' })}
            onConfirm={() => handleDelete(currentLmrMapping)}
            okText={intl.formatMessage({ id: 'general.yes' })}
            cancelText={intl.formatMessage({ id: 'general.no' })}
            cancelButtonProps={{ 'data-test-id': 'cancelBtn', style: { height: 'auto' } }}
            okButtonProps={{ 'data-test-id': 'yesBtn', style: { height: 'auto' } }}
          >
            <Button data-test-id='adminProductLMRMappingDeleteButton' type='primary'>Delete</Button>
          </Popconfirm>
        ),
      ]}
      destroyOnClose={true}
    >
      <Form
        name='lmrMappingsForm'
        ref={formRef}
        onFinish={handleSubmit}
        layout='vertical'
        initialValues={currentLmrMapping}
      >
        <Form.Item
          name='lmrId'
          label={'AX LMR ID'}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'general.required' }),
            },
            {
              pattern: new RegExp(/^(:?LMR|C)_.*$/i),
              message: intl.formatMessage({ id: 'admin.product.lmrMappingFormatInvalid' }),
            },
          ]}
        >
          <Input disabled={!isAdd} />
        </Form.Item>
        <Form.Item
          name={'lmrCategoryTypeId'}
          label={'Category'}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'general.required' }),
            },
          ]}
        >
          <Select showArrow={false} placeholder={'Please Select...'}>
            {lmrCategoryTypeOptions}
          </Select>
        </Form.Item>
        <Form.Item
          name={'lmrLabel'}
          label={'Label'}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'general.required' }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        {!isAdd && (
          <Fragment>
            <Form.Item name={'lmrPrice'} label={'Price (USD)'}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item name={'lmrPriceCa'} label={'Price (CAD)'}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item name={'warrantyPeriod'} label={'Warranty Period (Years)'}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item name={'serviceEligible'}>
              <Checkbox checked={currentLmrMapping?.serviceEligible} disabled={true}>
                Is Service Eligible
              </Checkbox>
            </Form.Item>
          </Fragment>
        )}
      </Form>
    </Modal>
  );
};

export default ProductLmrMappingModal;
