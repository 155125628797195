import React from 'react';
import { DashboardCollapsibleContentLine } from './index';
import { CollapsibleContent } from './styled';

const DashboardCollapsibleContentList = ({ purchaseOrderLines }) => {
  let purchaseOrderLinesMarkup =
    purchaseOrderLines &&
    purchaseOrderLines.map((purchaseOrderLine, index) => (
      <DashboardCollapsibleContentLine
        key={`purchase-order-line-${purchaseOrderLine.serviceOrderId}-${index}`}
        purchaseOrderLine={purchaseOrderLine}
      />
    ));

  return <CollapsibleContent>{purchaseOrderLinesMarkup}</CollapsibleContent>;
};

export default DashboardCollapsibleContentList;
