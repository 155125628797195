import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useLocale from './useLocale';

const useContact = () => {
  const usEmail = 'service.support@milwaukeetool.com';
  const caEmail = 'milwaukeetoolrepaircanada@ttigroupna.com';
  const usPhone = '18007293878';
  const usPhoneText = '1-800-SAWDUST';
  const caPhone = '18779482360';
  const caPhoneText = '1-877-948-2360';
  const usFindServiceCenterUrl = 'https://www.milwaukeetool.com/Support/Find-A-Service-Center';
  const caFindServiceCenterUrl = 'https://www.milwaukeetool.ca/Support/Find-A-Service-Center';

  const { getCurrentLocale } = useLocale();

  const currentLocale = getCurrentLocale();

  const getEmail = () => {
    switch (currentLocale) {
      default:
      case 'US':
        return usEmail;
      case 'CA':
        return caEmail;
    }
  };

  const getPhone = () => {
    switch (currentLocale) {
      default:
      case 'US':
        return usPhone;
      case 'CA':
        return caPhone;
    }
  };

  const getEmailLink = (includeIcon = true) => {
    let url = '';

    switch (currentLocale) {
      default:
      case 'US':
        url = `mailto:${usEmail}`;
        break;
      case 'CA':
        url = `mailto:${caEmail}`;
        break;
    }

    return (
      <a data-test-id='useEmailIcon' href={url} target='_blank' style={{ display: 'flex', alignItems: 'center' }}>
        {includeIcon && <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '8px' }} />}
        <span>{usEmail}</span>
      </a>
    );
  };

  const getPhoneLink = (includeIcon = true) => {
    let url = '';
    let text = '';

    switch (currentLocale) {
      default:
      case 'US':
        url = `tel:${usPhone}`;
        text = usPhoneText;
        break;
      case 'CA':
        url = `tel:${caPhone}`;
        text = caPhoneText;
        break;
    }

    return (
      <a data-test-id='usePhoneIcon' href={url} target='_blank' style={{ display: 'flex', alignItems: 'center' }}>
        {includeIcon && <FontAwesomeIcon icon={faPhone} style={{ marginRight: '8px' }} />}
        <span>{text}</span>
      </a>
    );
  };

  const getServiceCenterLink = (includeIcon = true) => {
    let url = '';

    switch (currentLocale) {
      default:
      case 'US':
        url = usFindServiceCenterUrl;
        break;
      case 'CA':
        url = caFindServiceCenterUrl;
        break;
    }

    return (
      <a data-test-id='findNearestServiceCenter' href={url} target='_blank' style={{ display: 'flex', alignItems: 'center' }}>
        {includeIcon && <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: '8px' }} />}
        <FormattedMessage id='orderDetails.findNearestServiceCenter' />
      </a>
    );
  };

  return {
    usEmail,
    caEmail,
    usPhone,
    usPhoneText,
    caPhone,
    caPhoneText,
    usFindServiceCenterUrl,
    caFindServiceCenterUrl,
    getEmail,
    getPhone,
    getEmailLink,
    getPhoneLink,
    getServiceCenterLink,
  };
};

export default useContact;
