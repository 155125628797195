import TagManager from 'react-gtm-module';
import oneTrust from './oneTrust';

let isInitialized = false;
let gtmId = window.location.host === 'service.milwaukeetool.ca' ? 'GTM-5GDBV9F' : 'GTM-WHMZXVB';

let init = function() {
  if (oneTrust.canLogGTM()) {
    TagManager.initialize({ gtmId: gtmId });
    isInitialized = true;
  } else {
    isInitialized = false;
  }
};

const googleTagManager = {
  pageView: function(path, trackerNames, title) {
    if (oneTrust.canLogGTM()) {
      if (!isInitialized) {
        init();
      }
      let pageData = {
        event: 'PageView',
        url: path,
        trackerNames: trackerNames,
        title: title,
      };
      TagManager.dataLayer(pageData);
    }
  },
  event: function(category, action, label) {
    if (oneTrust.canLogGTM()) {
      if (!isInitialized) {
        init();
      }
      let eventData = {
        event: category,
        action: action,
        label: label,
      };
      TagManager.dataLayer(eventData);
    }
  },
};

export default googleTagManager;
