const handleNavigateAway = (location, unsavedChanges, message) => {
  let isRepairProcessPage = location.pathname.startsWith('/repairprocess');
  if (!isRepairProcessPage) {
    return message;
  }

  return true;
};

export { handleNavigateAway };
