import React from 'react';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

const { Text } = Typography;

const LmrOptions = ({ lmrMappings, country, ...rest }) => {
  if ((!lmrMappings && country.toLowerCase() === 'us') || country.toLowerCase() === 'usa') {
    return (
      <Text {...rest}>
        <FormattedMessage id='lmrOptions.lmrUnavailable' />
      </Text>
    );
  } else if ((!lmrMappings && country.toLowerCase() === 'ca') || country.toLowerCase() === 'can') {
    return (
      <Text {...rest}>
        <FormattedMessage id='lmrOptions.lmrUnavailableCa' />
      </Text>
    );
  } else if (!lmrMappings) {
    return null;
  }

  const lmrMappingsList = lmrMappings.length > 0 ? lmrMappings : [lmrMappings];

  if (!country || country.toLowerCase() === 'us' || country.toLowerCase() === 'usa') {
    return lmrMappingsList.map((mapping, index) => {
      return lmrMappingsList.length >= 1 && mapping.lmrPrice > 0 ? (
        <div key={`lmr-mapping-us-${index}`}>
          <Text {...rest}>
            <FormattedMessage
              id='lmrOptions.lmrLabel'
              values={{
                lmrLabel: mapping.lmrLabel,
                lmrPrice: mapping.lmrPrice,
              }}
            />
          </Text>
        </div>
      ) : lmrMappingsList.length === 1 && mapping.lmrPrice === 0 ? (
        <FormattedMessage id='productDetail.toolInfo.lmrUnavailable' />
      ) : null;
    });
  } else if (country.toLowerCase() === 'ca' || country.toLowerCase() === 'can') {
    return lmrMappingsList.map((mapping, index) => {
      return lmrMappingsList.length >= 1 && mapping.lmrPriceCa > 0 ? (
        <div key={`lmr-mapping-ca-${index}`}>
          <Text {...rest}>
            <FormattedMessage
              id='lmrOptions.lmrLabelCa'
              values={{
                lmrLabel: mapping.lmrLabel,
                lmrPrice: mapping.lmrPriceCa,
              }}
            />
          </Text>
        </div>
      ) : lmrMappingsList.length === 1 && mapping.lmrPriceCa === 0 ? (
        <FormattedMessage id='productDetail.toolInfo.lmrUnavailable' />
      ) : null;
    });
  } else {
    return null;
  }
};

export default LmrOptions;
