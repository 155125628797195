import { Col, Link, ListGroupItem, Row, Text } from '@met/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const DashboardDeniedContentLine = ({ purchaseOrderLine }) => {
  return (
    <ListGroupItem>
      <Row>
        <Col xs={12} sm='auto' center='xs'>
          <div className='mb-3 mb-sm-0'>
            <Link data-test-id='dashboardDeniedWarrantyLineViewOrderButton' button primary href={`/repairdetail/${purchaseOrderLine.hashedServiceOrderId}`} className='mr-2'>
              <FormattedMessage id='dashboard.viewOrder' />
            </Link>
          </div>
        </Col>
        <Col xs={6} sm={2}>
          <Text sm bold>
            <FormattedMessage id='dashboard.serialNumber' />
          </Text>
          <Text sm>{purchaseOrderLine.serialNumber || '-'}</Text>
        </Col>
        <Col xs={6} sm={2}>
          <Text sm bold>
            <FormattedMessage id='dashboard.orderNumber' />
          </Text>
          <Text sm>{purchaseOrderLine.serviceOrderNumber || '-'}</Text>
        </Col>
      </Row>
    </ListGroupItem>
  );
};

export default DashboardDeniedContentLine;
