const responsive = breakpoint => {
  return `
    .hidden-${breakpoint} {
      display: none !important;
    }
    .visible-${breakpoint} {
      display: initial !important;
    }
  `;
};

export const display = props => {
  let displayCss = ``;

  props.theme.grid.dimensions.map(d => {
    displayCss += `
      @media screen and (min-width: ${props.theme.grid.breakpoints[d]}em) {
        ${responsive(d)}
      }
    `;
  });

  return displayCss;
};

export default display;
