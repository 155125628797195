import {FormattedMessage, useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {Card, Descriptions, Typography} from "antd";
import {NotificationTypes, PaymentMethodTypes, WarrantyOptionTypes} from "../../../../enums";
import dayjs from "dayjs";
import {StyledDescriptions} from "../styled";
import {useServiceOrder} from "../../../../hooks";
import {useDispatch} from "react-redux";

const { Text } = Typography;

const DeniedWarranty = ({serviceOrder}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  
  const {id, warrantyOption, warrantyOptionSubmittedDate, paymentMethodId, purchaseOrderNumber} = serviceOrder;
  
  const { getServiceOrderEstimatedScrapDate} = useServiceOrder();

  const [estimatedScrapDate, setEstimatedScrapDate] = useState(null);
  
  useEffect(() => {
    if(id) {
      getEstimatedScrapDate()
    }
  }, [id])
  
  const getEstimatedScrapDate = async () => {
    try {
      let response = await dispatch(getServiceOrderEstimatedScrapDate(id, NotificationTypes.DENIEDWARRANTY));
      
      if(response?.data?.data?.estimatedScrapDate) {
        setEstimatedScrapDate(response.data.data.estimatedScrapDate)
      }
    } catch (e) {
      message.error(intl.formatMessage({ id: 'general.genericError' }))
    }
  }

  const getPaymentTypeString = (paymentMethodTypeId) => {
    switch (paymentMethodTypeId) {
      case PaymentMethodTypes.CREDITCARD:
        return intl.formatMessage({ id: 'admin.serviceOrders.deniedWarranty.creditCard' })
      case PaymentMethodTypes.PURCHASEORDER:
        return intl.formatMessage({ id: 'admin.serviceOrders.deniedWarranty.purchaseOrder' })
      case PaymentMethodTypes.PAYLATER:
        return intl.formatMessage({ id: 'admin.serviceOrders.deniedWarranty.payLater' })
      default:
        return intl.formatMessage({ id: 'admin.serviceOrders.deniedWarranty.unknown' })
    }
  }
  
  const decisionDateMarkup = (
    <Descriptions.Item
      label= {intl.formatMessage({ id: 'admin.serviceOrders.deniedWarranty.decisionDate' })}
    >
      <Text>
        {
          warrantyOptionSubmittedDate ? 
            dayjs(warrantyOptionSubmittedDate).format('MM/DD/YYYY h:mm:ss A')
            :
            intl.formatMessage({ id: 'admin.serviceOrders.deniedWarranty.pending' })
        }
      </Text>
    </Descriptions.Item>
  );
  
  const repairDecisionMarkup = warrantyOption && (
    <Descriptions.Item
      label= {intl.formatMessage({ id: 'admin.serviceOrders.deniedWarranty.repairDecision' })}
    >
      <Text>{warrantyOption}</Text>
    </Descriptions.Item>
  );
  
  const estimatedScrapDateMarkup = (
    <Descriptions.Item
      label= {intl.formatMessage({ id: 'admin.serviceOrders.deniedWarranty.estimatedScrapDate' })}
    >
      {
        estimatedScrapDate ?
          <Text>{dayjs(estimatedScrapDate).format('MM/DD/YYYY')}</Text>
          :
          <Text>
            <FormattedMessage id={'admin.serviceOrders.deniedWarranty.unknown'}/>
          </Text>

      }
    </Descriptions.Item>
  );
  
  const purchaseOrderMarkup = purchaseOrderNumber && (
    <Descriptions.Item
      label= {intl.formatMessage({ id: 'admin.serviceOrders.deniedWarranty.purchaseOrderNumber' })}
    >
      <Text>{purchaseOrderNumber}</Text>
    </Descriptions.Item>
  );
  
  const paymentMethodTypeMarkup = warrantyOption === WarrantyOptionTypes.REPAIR && paymentMethodId && (
    <Descriptions.Item
      label= {intl.formatMessage({ id: 'admin.serviceOrders.deniedWarranty.paymentMethod' })}
    >
      <Text>{getPaymentTypeString(paymentMethodId)}</Text>
    </Descriptions.Item>
  );
  
  return (
    <Card>
      <StyledDescriptions
        title={intl.formatMessage({ id: 'admin.serviceOrders.deniedWarranty.deniedWarrantyTitle' })}
        layout='vertical'
      >
        {estimatedScrapDateMarkup}
        {decisionDateMarkup}
        {repairDecisionMarkup}
        {paymentMethodTypeMarkup}
        {purchaseOrderMarkup}
      </StyledDescriptions>
    </Card>
  );
};

export default DeniedWarranty;