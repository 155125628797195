import { Space, Typography } from 'antd';
import moment from 'moment';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InfoGrid, InfoGridItem } from '../../../../components';
import { useRecycling, useServiceOrder, useShipping } from '../../../../hooks';

const { Title } = Typography;

const RecycleOrderInfo = () => {
  const intl = useIntl();
  const { serviceOrder } = useServiceOrder();
  const { serviceOrderRecycling } = useRecycling();
  const { getTrackingMarkup } = useShipping();

  const outboundTrackingMarkup =
    [2, 3].includes(serviceOrderRecycling.status) &&
    serviceOrderRecycling.recyclingLines &&
    serviceOrderRecycling.recyclingLines.length > 0 &&
    serviceOrderRecycling.recyclingLines.map(recycleLine => {
      return getTrackingMarkup(
        recycleLine.id,
        serviceOrderRecycling.addressCountryCode,
        recycleLine.outboundTrackingNumber,
        'ups'
      );
    });

  const inboundTrackingMarkup =
    [4].includes(serviceOrderRecycling.status) &&
    serviceOrderRecycling.recyclingLines &&
    serviceOrderRecycling.recyclingLines.length > 0 &&
    serviceOrderRecycling.recyclingLines.map(recycleLine => {
      return getTrackingMarkup(
        recycleLine.id,
        serviceOrderRecycling.addressCountryCode,
        recycleLine.inboundTrackingNumber,
        'ups'
      );
    });

  const replacementTrackingMarkup =
    [6, 7].includes(serviceOrderRecycling.status) &&
    serviceOrderRecycling.replacementTrackingNumber &&
    getTrackingMarkup(0, serviceOrderRecycling.addressCountryCode, serviceOrderRecycling.replacementTrackingNumber);

  let trackingMarkup = (outboundTrackingMarkup || inboundTrackingMarkup || replacementTrackingMarkup) && (
    <InfoGridItem label={intl.formatMessage({ id: 'repairDetails.orderInfo.trackingInfo' })}>
      <Space direction='vertical' size={2}>
        {outboundTrackingMarkup}
        {inboundTrackingMarkup}
        {replacementTrackingMarkup}
      </Space>
    </InfoGridItem>
  );

  const proofOfPurchaseMarkup = serviceOrder.proofOfPurchaseIncluded ? (
    <FormattedMessage id='repairDetails.orderInfo.proofOfPurchaseIncluded' />
  ) : serviceOrder.proofOfPurchaseBlob ? (
    <FormattedMessage id='repairDetails.orderInfo.proofOfPurchaseUploaded' />
  ) : (
    <FormattedMessage id='repairDetails.orderInfo.proofOfPurchaseNotProvided' />
  );

  return (
    <Fragment>
      <Title level={4}>
        <FormattedMessage id='repairDetails.orderInfoTitle' />
      </Title>
      <InfoGrid column={1}>
        <InfoGridItem label={intl.formatMessage({ id: 'general.submitted' })}>
          {moment(serviceOrder.submitted)
            .local()
            .format('M/D/YYYY')}
        </InfoGridItem>
        <InfoGridItem label={intl.formatMessage({ id: 'general.status' })}>
          <FormattedMessage id={`repairDetails.orderInfo.recycleStatus.status${serviceOrderRecycling.status}`} />
        </InfoGridItem>
        <InfoGridItem label={intl.formatMessage({ id: 'repairDetails.orderInfo.proofOfPurchase' })}>
          {proofOfPurchaseMarkup}
        </InfoGridItem>
        {trackingMarkup}
      </InfoGrid>
    </Fragment>
  );
};

export default RecycleOrderInfo;
