import { InboxOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Modal, Row, Space, Typography } from 'antd';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { PageLoader, ServiceCard } from '../../../../../../components';
import { getFileExtension } from '../../../../../../helpers/formatter';
import { proofOfPurchaseActions } from '../../../../../../store';
import RepairItemsList from '../../../../components/RepairItemsList';
import { useRepairItemsContext } from '../../../RepairItemsContext';
import { StyledDatePicker, StyledDragger, WideButton } from './styled';

const { Paragraph, Text, Title } = Typography;

const ProofOfPurchase = ({ onBack, onCheckWarrantyStatus }) => {
  const validFileTypes = ['image/jpeg', 'application/pdf', 'image/png'];
  const maxFileSizeBytes = 5000000;
  const dateFormat = 'MM/DD/YYYY';

  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    proofOfPurchase,
    setProofOfPurchase,
    uploadingProofOfPurchase,
    setProofOfPurchaseBlob,
    setProofOfPurchaseIncluded,
    setProofOfPurchaseNotIncluded,
    serialNumberUnreadable,
    purchaseDate,
    setPurchaseDate,
  } = useRepairItemsContext();

  const uploadingToast = React.useRef(null);

  const [uploadingFile, setUploadingFile] = useState(false);
  const [showNotIncludedModal, setShowNotIncludedModal] = useState(false);
  const [detectedPurchaseDate, setDetectedPurchaseDate] = useState(null);
  const [specifiedPurchaseDate, setSpecifiedPurchaseDate] = useState(null);
  const [fileTypeIsInvalid, setFileTypeIsInvalid] = useState(false);
  const [fileSizeIsInvalid, setFileSizeIsInvalid] = useState(false);

  useEffect(() => {
    setSpecifiedPurchaseDate(purchaseDate);
  }, []);

  useEffect(() => {
    if (detectedPurchaseDate) {
      setSpecifiedPurchaseDate(detectedPurchaseDate);
    }
  }, [detectedPurchaseDate]);

  useEffect(() => {
    setPurchaseDate(specifiedPurchaseDate);
  }, [specifiedPurchaseDate]);

  const validateFile = (file) => {
    let isValidType = validFileTypes.indexOf(file.type.toLowerCase()) > -1;
    let isValidSize = file.size <= maxFileSizeBytes;

    if (!isValidType) {
      toast.error(intl.formatMessage({ id: 'repairProcess.warrantyInformation.invalidFileType' }));
    }
    if (!isValidSize) {
      toast.error(intl.formatMessage({ id: 'repairProcess.warrantyInformation.invalidFileSize' }));
    }

    setFileTypeIsInvalid(!isValidType);
    setFileSizeIsInvalid(!isValidSize);

    return isValidType && isValidSize;
  };

  const handleUploadChange = (upload) => {
    if (!upload.file) {
      return;
    }

    const { status } = upload.file;

    if (status === 'uploading' && !uploadingFile) {
      setUploadingFile(true);

      handleClearProofOfPurchase(); // Clear state since we're uploading a new file

      uploadingToast.current = toast.info(
        intl.formatMessage({ id: 'repairProcess.warrantyInformation.uploadInProgress' })
      );
    } else if (status === 'done') {
      setUploadingFile(false);

      toast.dismiss(uploadingToast.current);

      toast.success(intl.formatMessage({ id: 'repairProcess.warrantyInformation.uploadSuccess' }));
    } else if (status === 'error') {
      toast.dismiss(uploadingToast.current);

      toast.error(intl.formatMessage({ id: 'repairProcess.warrantyInformation.uploadError' }));
    }
  };

  const handleUploadProofOfPurchase = (upload) => {
    const { onSuccess, onError, file } = upload;

    try {
      setProofOfPurchase(file);

      let formData = new FormData();
      formData.append('proofOfPurchase', file);
      formData.append('fileExtension', getFileExtension(file.name));

      dispatch(proofOfPurchaseActions.uploadProofOfPurchase(formData))
        .then((response) => {
          const { blobInfo, possiblePurchaseDates } = response.data.data;

          setProofOfPurchaseBlob(blobInfo?.itemPath);

          if (possiblePurchaseDates && possiblePurchaseDates.length > 0) {
            setDetectedPurchaseDate(moment(possiblePurchaseDates[0]));
          }

          onSuccess();
        })
        .catch(() => {
          onError();
        });
    } catch (error) {
      onError();
    }
  };

  const handleIncludeProofOfPurchaseClick = () => {
    setProofOfPurchaseIncluded(true);
    onCheckWarrantyStatus();
  };

  const handleNotIncludeProofOfPurchaseClick = () => {
    setProofOfPurchaseNotIncluded(true);

    // If user specified serial number was not readable and they aren't including a POP, have them confirm
    if (serialNumberUnreadable) {
      setShowNotIncludedModal(true);
    } else {
      onCheckWarrantyStatus();
    }
  };

  const handleClearProofOfPurchase = () => {
    setProofOfPurchase(null);
    setProofOfPurchaseBlob(null);
    setDetectedPurchaseDate(null);
    setSpecifiedPurchaseDate(null);
    setFileTypeIsInvalid(false);
    setFileSizeIsInvalid(false);
  };

  const loadingMarkup = uploadingProofOfPurchase && <PageLoader />;

  const repairItemsListMarkup = (
    <ServiceCard>
      <RepairItemsList />
    </ServiceCard>
  );

  const invalidFileTypeMessage = !uploadingProofOfPurchase && fileTypeIsInvalid && (
    <div>
      <Text type='danger'>
        <FormattedMessage id='repairProcess.warrantyInformation.invalidFileType' />
      </Text>
    </div>
  );

  const invalidFileSizeMessage = !uploadingProofOfPurchase && fileSizeIsInvalid && (
    <div>
      <Text type='danger'>
        <FormattedMessage id='repairProcess.warrantyInformation.invalidFileSize' />
      </Text>
    </div>
  );

  const alternativeOptionsMarkup = !uploadingProofOfPurchase && !proofOfPurchase && (
    <Space direction='horizontal' wrap style={{ maxWidth: '100%' }}>
      <WideButton data-test-id='proofOfPurchaseIncludedButton' onClick={() => handleIncludeProofOfPurchaseClick()}>
        <FormattedMessage id='repairProcess.warrantyInformation.proofOfPurchaseIncluded' />
      </WideButton>
      <WideButton
        data-test-id='proofOfPurchaseNotIncludedInShipmentButton'
        onClick={() => handleNotIncludeProofOfPurchaseClick(true)}
      >
        <FormattedMessage id='repairProcess.warrantyInformation.proofOfPurchaseNotIncluded' />
      </WideButton>
    </Space>
  );

  const purchaseDateValidationMessage = !uploadingProofOfPurchase && proofOfPurchase && !specifiedPurchaseDate && (
    <Text type='danger'>
      <FormattedMessage id='repairProcess.warrantyInformation.missingPurchaseDate' />
    </Text>
  );

  const purchaseDateMarkup = !uploadingProofOfPurchase && proofOfPurchase && (
    <Space direction='vertical'>
      <div>
        <Title level={3} strong>
          <FormattedMessage id='repairProcess.warrantyInformation.detectedPurchaseDate' />
        </Title>
        {!detectedPurchaseDate && (
          <Fragment>
            <Paragraph>
              <FormattedMessage id='repairProcess.warrantyInformation.attemptParse' />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id='repairProcess.warrantyInformation.pleaseEnterDate' />
            </Paragraph>
          </Fragment>
        )}
        {detectedPurchaseDate && (
          <Fragment>
            <Paragraph>
              <FormattedMessage id='repairProcess.warrantyInformation.parseSuccessful' />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id='repairProcess.warrantyInformation.pleaseConfirm' />
            </Paragraph>
          </Fragment>
        )}
      </div>
      <StyledDatePicker
        data-test-id='proofOfPurchaseDatePicker'
        size='large'
        value={specifiedPurchaseDate}
        format={dateFormat}
        picker='day'
        allowClear={false}
        onChange={(date) => setSpecifiedPurchaseDate(date)}
        style={{ marginTop: '-1em' }}
      />
      {purchaseDateValidationMessage}
    </Space>
  );

  return (
    <Fragment>
      <Row gutter={[16, 0]}>
        <Col xs={24} lg={16}>
          <ServiceCard>
            <Title level={2} strong>
              <FormattedMessage id='repairProcess.warrantyInformation.uploadProofOfPurchase' />
            </Title>
            <Space direction='vertical' size='large' style={{ maxWidth: '100%' }}>
              <div>
                <Title level={3} strong>
                  <FormattedMessage id='repairProcess.warrantyInformation.whyUpload' />
                </Title>
                <Paragraph>
                  <FormattedMessage id='repairProcess.warrantyInformation.proofOfPurchaseExplanation' />
                </Paragraph>
                <Paragraph>
                  <FormattedMessage id='repairProcess.warrantyInformation.proofOfPurchaseManufactureDate' />
                </Paragraph>
              </div>
              <div>
                <Title level={3} strong>
                  <FormattedMessage id='repairProcess.warrantyInformation.upload' />
                </Title>
                <div style={{ marginBottom: '2em' }}>
                  <StyledDragger
                    data-test-id='proofOfPurchaseFileUpload'
                    accept={validFileTypes.join(',')}
                    multiple={false}
                    fileList={proofOfPurchase ? [proofOfPurchase] : []}
                    beforeUpload={validateFile}
                    customRequest={handleUploadProofOfPurchase}
                    onChange={handleUploadChange}
                    onRemove={handleClearProofOfPurchase}
                    listType='picture'
                  >
                    <Space direction='vertical'>
                      <InboxOutlined style={{ fontSize: '2em', color: '#db011c' }} />
                      <Text>
                        <FormattedMessage id='repairProcess.warrantyInformation.dragToUpload' />
                      </Text>
                      <Divider plain={true}>
                        <FormattedMessage id='repairProcess.warrantyInformation.dragOrDrop' />
                      </Divider>
                      <Button data-test-id='uploadProofOfPurchaseButton' type='primary' onClick={() => {}}>
                        <FormattedMessage id='repairProcess.warrantyInformation.clickToUpload' />
                      </Button>
                      <br />
                      <Text>
                        <FormattedMessage id='repairProcess.warrantyInformation.validFileTypes' />
                      </Text>
                      <Text>
                        <FormattedMessage id='repairProcess.warrantyInformation.maxFileSize' />
                      </Text>
                    </Space>
                  </StyledDragger>
                  {invalidFileTypeMessage}
                  {invalidFileSizeMessage}
                </div>
                {loadingMarkup}
                {purchaseDateMarkup}
                {alternativeOptionsMarkup}
              </div>
            </Space>
          </ServiceCard>
        </Col>
        <Col xs={24} lg={8}>
          {repairItemsListMarkup}
        </Col>
      </Row>
      <Row justify='end'>
        <Col>
          <Space direction='horizontal'>
            <Button data-test-id='proofOfPurchaseGeneralBackButton' type='secondary' onClick={() => onBack()}>
              <FormattedMessage id='general.back' />
            </Button>
            <Button
              data-test-id='checkWarranty'
              type='primary'
              disabled={!specifiedPurchaseDate}
              onClick={() => onCheckWarrantyStatus()}
            >
              <FormattedMessage id='repairProcess.warrantyInformation.checkWarrantyStatus' />
            </Button>
          </Space>
        </Col>
      </Row>
      <Modal
        title={intl.formatMessage({ id: 'repairProcess.warrantyInformation.noProofOfPurchaseModal.title' })}
        visible={showNotIncludedModal}
        okText={intl.formatMessage({ id: 'general.continue' })}
        onOk={() => onCheckWarrantyStatus()}
        onCancel={() => setShowNotIncludedModal(false)}
      >
        <FormattedMessage id='repairProcess.warrantyInformation.noProofOfPurchaseModal.confirm' />
      </Modal>
    </Fragment>
  );
};

export default ProofOfPurchase;
