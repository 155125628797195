import { adalFetch, AuthenticationContext, withAdalLogin } from 'react-adal';

const appId = '1f623664-c117-4337-82de-d7455344b25c';
export const adalConfig = {
  cacheLocation: 'localStorage',
  clientId: appId,
  redirectUri: 'https://' + window.location.host,
  endpoints: {
    api: appId,
  },
  postLogoutRedirectUri: window.location.origin,
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) => {
  // only use the azure ad wrapper if we are actually logged in
  if (authContext.getCachedUser() !== null) {
    return adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
  } else {
    return fetch(options);
  }
};

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
