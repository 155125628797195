import { runWithAdal } from 'react-adal';
import { authContext } from './helpers/azureAd';

const DO_NOT_LOGIN =
  window.location.host === 'service.milwaukeetool.com' || window.location.host === 'service.milwaukeetool.ca';

runWithAdal(
  authContext,
  () => {
    // eslint-disable-next-line
    require('./indexApp.js');
  },
  DO_NOT_LOGIN
);
