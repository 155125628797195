import { Alert, Button, Card, Col, notification, Row, Space, Typography } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { AccountInfo, CollapsibleOrderList, NotFound, PageLoader, ShippingInfo } from '../../../components';
import { useContact, useOrderStatus, useRecycling, useServiceOrders, useUser } from '../../../hooks';
import { useRepairProcessContext } from '../RepairProcessContext';
import { OrderStatus, RecycleOrderStatus } from './components';

const { Title } = Typography;

const OrderSummary = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const intl = useIntl();
  const { serviceOrdersByGroup, loadingServiceOrdersByGroup, getServiceOrdersByGroup } = useServiceOrders();
  const { setRepairItems: setContextRepairItems, setShipping } = useRepairProcessContext();
  const { getEmailLink, getPhoneLink } = useContact();
  const { name, phoneNumber, email, accountNumber } = useUser();
  const {
    shippoTrackingFailure,
    callToRecycleFailure,
    startListeningForShippoTracking,
    stopListeningForShippoTracking,
    trackingNumber,
    setTrackingNumber,
  } = useOrderStatus();
  const { serviceOrderRecycling, getServiceOrderRecycling } = useRecycling();

  const [notFound, setNotFound] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const [serviceOrderInfo, setServiceOrderInfo] = useState(null);
  const [serviceOrderIsCanadian, setServiceOrderIsCanadian] = useState(false);
  const [repairItems, setRepairItems] = useState([]);
  const [otherItems, setOtherItems] = useState([]);

  useEffect(() => {
    return () => {
      stopTracking();
    };
  }, []);

  useEffect(() => {
    let pathParts = location.pathname.split('/');

    if (pathParts.length < 4) {
      history.replace('/dashboard');
    } else {
      setGroupId(pathParts[3]);

      dispatch(getServiceOrdersByGroup(pathParts[3])).catch(payload => {
        stopListeningForShippoTracking();

        notification.error({
          message: intl.formatMessage({ id: 'orderSummary.getServiceOrdersError' }),
          description: intl.formatMessage({ id: 'orderSummary.getServiceOrdersErrorDetail' }),
          duration: null,
        });

        if ([400, 403, 404].includes(payload.response.status)) {
          setNotFound(true);
        }
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (serviceOrdersByGroup && serviceOrdersByGroup.length > 0) {
      let serviceOrder = serviceOrdersByGroup[0];

      setServiceOrderInfo(serviceOrder);
      setServiceOrderIsCanadian(['ca', 'can'].includes(serviceOrder.countryCode.toLowerCase()));
      setRepairItems(serviceOrdersByGroup.filter(x => !x.serviceOrderRecyclingId));
      setOtherItems(serviceOrdersByGroup.filter(x => x.serviceOrderRecyclingId));
    }
  }, [serviceOrdersByGroup]);

  useEffect(() => {
    if (repairItems && repairItems.length > 0) {
      let serviceOrder = repairItems[0];

      setTrackingNumber(serviceOrder.trackingNumber);

      if (!serviceOrder.trackingNumber && serviceOrder.shippoStatus !== 5) {
        // If tracking is not found and Shippo didn't fail, listen for tracking
        startTracking(groupId);
      }
    }
  }, [repairItems]);

  useEffect(() => {
    if (otherItems && otherItems.length > 0) {
      let serviceOrder = otherItems[0];

      if (serviceOrder.serviceOrderRecyclingId) {
        dispatch(getServiceOrderRecycling(serviceOrder.serviceOrderRecyclingId));
      }
    }
  }, [otherItems]);

  useEffect(() => {
    if (shippoTrackingFailure) {
      stopTracking();

      notification.error({
        message: intl.formatMessage({ id: 'orderDetails.trackingInfoError' }),
        description: intl.formatMessage({ id: 'orderDetails.trackingInfoErrorDetail' }),
        duration: null,
      });
    }
  }, [shippoTrackingFailure]);

  useEffect(() => {
    if (trackingNumber) {
      stopTracking();
    }
  }, [trackingNumber]);

  const startTracking = groupId => {
    startListeningForShippoTracking(groupId, trackingNumber => {
      for (let i = 0; i < repairItems.length; i++) {
        repairItems[i].trackingNumber = trackingNumber;
      }
    });

    notification.destroy();

    notification.info({
      message: intl.formatMessage({ id: 'general.processing' }),
      description: intl.formatMessage({ id: 'orderDetails.processingDetail' }),
      duration: null,
    });
  };

  const stopTracking = () => {
    notification.destroy();

    stopListeningForShippoTracking();
  };

  const handleNewOrderClick = () => {
    setContextRepairItems([]);
    setShipping({});

    history.push('/repairprocess/addanother');
  };

  if (notFound) {
    return <NotFound />;
  }

  if (loadingServiceOrdersByGroup || !serviceOrderInfo) {
    return <PageLoader />;
  }

  const repairItemsMarkup = repairItems && repairItems.length > 0 && (
    <Fragment>
      <Row style={{ width: '100%' }}>
        <Col xs={24}>
          <Title level={3}>
            <FormattedMessage id='orderSummary.repairItemsLabel' values={{ count: repairItems.length }} />
          </Title>
        </Col>
        <Col xs={24}>
          <OrderStatus serviceOrder={repairItems[0]} shippoTrackingFailure={shippoTrackingFailure} />
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col flex={1}>
          <CollapsibleOrderList orders={repairItems} showViewButton={true} />
        </Col>
      </Row>
    </Fragment>
  );

  const otherItemsMarkup = serviceOrderRecycling && otherItems && otherItems.length > 0 && (
    <Fragment>
      <Row style={{ width: '100%' }}>
        <Col xs={24}>
          <Title level={3}>
            <FormattedMessage id='orderSummary.otherItemsLabel' values={{ count: otherItems.length }} />
          </Title>
        </Col>
        <Col xs={24}>
          <RecycleOrderStatus
            serviceOrderId={otherItems[0].id}
            orderProcessed={serviceOrderRecycling.status > 1}
            callToRecycleFailure={callToRecycleFailure}
          />
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col xs={24}>
          <CollapsibleOrderList orders={otherItems} showViewButton={false} />
        </Col>
      </Row>
    </Fragment>
  );

  return (
    <Space direction='vertical' size='middle' style={{ width: '100%', padding: '0 1em' }}>
      <Row style={{ width: '100%' }}>
        <Col flex={1}>
          <Title level={2}>
            <FormattedMessage id='orderSummary.title' />
          </Title>
        </Col>
        <Col flex={0}>
          <Button type='primary' ghost onClick={() => handleNewOrderClick()} data-test-id='newOrderButton'>
            <FormattedMessage id='orderSummary.newOrder' />
          </Button>
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col flex={1}>
          <Card>
            <Row gutter={[24, 24]} style={{ width: '100%' }}>
              <Col xs={24} md={12}>
                <ShippingInfo
                  senderName={serviceOrderInfo.recipient}
                  senderLine1={serviceOrderInfo.addressLine1}
                  senderCity={serviceOrderInfo.city}
                  senderState={serviceOrderInfo.state}
                  senderPostalCode={serviceOrderInfo.postalCode}
                  senderCountry={serviceOrderInfo.countryCode}
                />
              </Col>
              <Col xs={24} md={12}>
                <AccountInfo accountName={name} accountNumber={accountNumber} phone={phoneNumber} email={email} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {repairItemsMarkup}
      {otherItemsMarkup}
      <Card>
        <Title level={4} id='contact'>
          <FormattedMessage id='orderSummary.contactCustomerExperience' />
        </Title>
        <Space size='large' wrap>
          {getPhoneLink()}
          {getEmailLink()}
        </Space>
      </Card>
    </Space>
  );
};

export default OrderSummary;
