import React, { createContext, useContext, useState } from 'react';
import { useSelector } from 'react-redux';

const BaseContext = createContext({
  isInternetExplorer: false,
  authenticationError: false,
  setAuthenticationError: () => {},
  announcements: [],
});

const useBaseContext = () => useContext(BaseContext);

const BaseProvider = ({ children }) => {
  const { announcements } = useSelector(state => state.announcements.getActiveAnnouncements);

  const [authenticationError, setAuthenticationError] = useState(false);

  const isInternetExplorer = false || !!document.documentMode;

  return (
    <BaseContext.Provider
      value={{
        isInternetExplorer,
        authenticationError,
        setAuthenticationError,
        announcements,
      }}
    >
      {children}
    </BaseContext.Provider>
  );
};

export { BaseContext, useBaseContext, BaseProvider };
