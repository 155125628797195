import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { Row, Col, Text } from '@met/react-components';

export const Image = styled.img`
  max-width: 100%;
  bomax-height: 130px;
`;

export const CollapsibleContent = styled.div`
  width: 100%;
  max-width: 100%;
  max-height: 500px;
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  overflow-y: auto;
  overflow-x: hidden;
`;

export const CollapseHeaderRow = styled(Row)`
  width: 100%;
`;

export const StyledInfiniteScroll = styled(InfiniteScroll)`
  > * {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }
`;

export const PurchaseOrderLine = styled(Row)`
  padding: 1rem;

  &:nth-child(even) {
    background-color: ${props => props.theme.colors.lightGray};
  }
`;

export const NoResultsCol = styled(Col)`
  margin: 3rem 0;
`;

export const WrapText = styled(Text)`
  width: 100%:
  max-width: 100%;
  word-wrap: break-word;
`;
