import { useAuth0 } from '@auth0/auth0-react';
import { Hero } from '../../components/Hero';
import { Button, Typography } from 'antd';
import React, { Fragment, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { ProductSearchModal } from '../../components';
import { downloadUrl } from '../../helpers/downloadBlob';
import { userHasOrders } from '../../helpers/sessionStorage';
import { useUser } from '../../hooks';
import { docsActions, usersActions } from '../../store';
import { AnonymousContent, AuthenticatedContent } from './components';
import { useToggle } from '../../hooks';

const { Title } = Typography;

const Home = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const intl = useIntl();
  const { acceptedTermsAndConditions } = useUser();
  const dispatch = useDispatch();
  const history = useHistory();

  const [showSearchModal, toggleSearchModal] = useToggle(false);

  useEffect(() => {
    if (isAuthenticated && acceptedTermsAndConditions && userHasOrders()) {
      history.replace('/dashboard');
    }
  }, [isAuthenticated]);

  const handleLoginClick = () => {
    loginWithRedirect();
  };

  const handleAddNewClick = () => {
    if (!acceptedTermsAndConditions) {
      toast.warn(intl.formatMessage({ id: 'home.authenticatedContent.termsAndConditionsRequired' }));
    } else {
      toggleSearchModal();
    }
  };

  const handleTermsConditionsCheck = accepted => {
    dispatch(usersActions.upsertUserTermsAndConditions(accepted)).catch(() => {
      toast.error(intl.formatMessage({ id: 'home.authenticatedContent.termsAndConditionsNotSaved' }), {
        autoClose: false,
      });
    });
  };

  const handleDownloadTermsConditions = () => {
    dispatch(docsActions.downloadTermsAndConditions())
      .then(response => {
        downloadUrl('termsconditions.pdf', response.data.data);
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'home.authenticatedContent.termsAndConditionsDownloadFailed' }), {
          autoClose: false,
        });
      });
  };

  const anonymousContent = !isAuthenticated && <AnonymousContent />;

  const authenticatedContent = isAuthenticated && (
    <AuthenticatedContent
      termsConditions={acceptedTermsAndConditions}
      handleTermsConditionsCheck={handleTermsConditionsCheck}
      handleAddNewClick={handleAddNewClick}
      handleDownloadTermsConditions={handleDownloadTermsConditions}
    />
  );

  const loginButton = !isAuthenticated && (
    <Button id='loginSignUpButton' data-test-id='loginSignUpButton' type='primary' onClick={handleLoginClick}>
      <FormattedMessage id='home.anonymousContent.loginSignUp' />
    </Button>
  );

  return (
    <Fragment>
      <Hero background='https://connect.milwaukeetool.com/static/media/banner_1.14a84250.jpg' opacity='.6' dark>
        <Title style={{ color: '#ffffff' }}>eService Tool Repair</Title>
        {loginButton}
      </Hero>
      {anonymousContent}
      {authenticatedContent}
      <ProductSearchModal hidden={!showSearchModal} toggle={toggleSearchModal} />
    </Fragment>
  );
};

export default Home;
