import { FormattedMessage, useIntl } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import PageError from '../PageError';
import { LogoutText } from "./styled";

const AuthError = () => {
  const intl = useIntl();
  const { logout } = useAuth0();

  let subtitle = (
    <FormattedMessage
      id='pageError.authError.subtitle'
      values={{
        logout: (
          <LogoutText data-test-id='authErrorLogoutText' onClick={() => logout()}>
            logout
          </LogoutText>
        ),
      }}
    />
  );

  return <PageError title={intl.formatMessage({ id: 'pageError.authError.title' })} subtitle={subtitle} />;
};

export default AuthError;
