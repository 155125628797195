import { Descriptions } from 'antd';
import styled from 'styled-components';

export const StyledDescriptions = styled(Descriptions)`
  .ant-descriptions-view {
    border: none;

    tr,
    th,
    td {
      padding: 0;
      border: none;
      background: none;
    }

    th.ant-descriptions-item-label {
      width: 1px;
      padding-right: 1em;
      vertical-align: top;
      white-space: nowrap;
      font-weight: 600;
    }
  }
`;

export const StyledDescriptionsItem = styled(Descriptions.Item)``;
