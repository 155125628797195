import { display } from './display';
import { margins } from './margins';
import { padding } from './padding';
import { rebootCss } from './rebootCss';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ${rebootCss()}
  ${props => padding(props)};
  ${props => margins(props)};
  ${props => display(props)};
  
  body {
    min-height: 100vh;
  }

  input::-ms-clear,
  input::-ms-reveal {  
    display: none; 
    width: 0; 
    height: 0; 
  }
`;

export { GlobalStyle };