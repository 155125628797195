import { Icon } from '@met/react-components';
import { Space, Typography } from 'antd';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { ServiceCard } from '../../../../../../components';
import { useToggle } from '../../../../../../hooks';
import LmrDefinitionModal from './LmrDefinitionModal';
import { useRepairItemsContext } from '../../../RepairItemsContext';

const { Paragraph, Text, Title } = Typography;

const ToolPendingWarranty = ({ warrantyLine }) => {
  const {
    product,
    serialNumber,
    warrantyEvaluation,
    proofOfPurchaseIncluded,
    purchaseDate,
    serialNumberUnreadable,
  } = useRepairItemsContext();

  const [showLmrModal, toggleLmrModal] = useToggle(false);

  //A battery with a sku can't need more info, instead, modify verbiage and show pending view
  const underWarrantyMarkup = (
    <Fragment>
      <ServiceCard>
        <Title strong>
          <FormattedMessage id='repairProcess.warrantyInformation.pendingWarranty.title' />
        </Title>
        <Space direction='vertical' size='large'>
          {warrantyEvaluation === null && serialNumber && !proofOfPurchaseIncluded && product.isBattery && (
            <Paragraph>
              <FormattedMessage id='repairProcess.warrantyInformation.pendingWarranty.reviewAlertBattery' />
            </Paragraph>
          )}
          {((!warrantyEvaluation && serialNumber && !purchaseDate && proofOfPurchaseIncluded) ||
            (warrantyEvaluation === null && serialNumberUnreadable && proofOfPurchaseIncluded)) && (
            <div>
              <Paragraph>
                <FormattedMessage id='repairProcess.warrantyInformation.pendingWarranty.sentPOP' />
              </Paragraph>
              <Paragraph>
                <FormattedMessage id='repairProcess.warrantyInformation.pendingWarranty.reviewAlert' />
              </Paragraph>
            </div>
          )}
          <div>
            <Title level={3} strong>
              <FormattedMessage id='repairProcess.warrantyInformation.warrantyCoverage.title' />
            </Title>
            <Paragraph>
              <ul>
                <li>
                  <FormattedMessage id='repairProcess.warrantyInformation.warrantyCoverage.factor1' />
                </li>
                <li>
                  <FormattedMessage id='repairProcess.warrantyInformation.warrantyCoverage.factor2' />
                </li>
                <li>
                  <FormattedMessage id='repairProcess.warrantyInformation.warrantyCoverage.factor3' />
                </li>
              </ul>
            </Paragraph>
          </div>
          <div>
            <Title level={3} strong>
              <FormattedMessage id='repairProcess.warrantyInformation.noWarranty' />
            </Title>
            <Paragraph>
              <Text>
                <FormattedMessage id='productDetail.toolInfo.lmrTitle' />
                <Icon data-test-id='productDetailToolInfoLMRTitle' className='ml-2' type='question-circle' xs onClick={toggleLmrModal} />
              </Text>
              <Paragraph>
                <Text style={{ color: '#db011c' }}>{warrantyLine}</Text>
              </Paragraph>
            </Paragraph>
          </div>
        </Space>
      </ServiceCard>
    </Fragment>
  );

  return (
    <Fragment>
      {underWarrantyMarkup}
      <LmrDefinitionModal isVisible={showLmrModal} toggle={() => toggleLmrModal()} />
    </Fragment>
  );
};

export default ToolPendingWarranty;
