import { useLocale } from '../hooks';

const { isCaLocale } = useLocale();
const isCa = isCaLocale();

const intlCom = {
  carrier: 'FedEx',
  carrierLocationAmount: '8,000',
};

const intlCa = {
  carrier: 'Purolator',
  carrierLocationAmount: '1 300',
};

export const getCountryIntlValues = () => {
  return isCa ? intlCa : intlCom;
};
