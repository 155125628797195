import { Button, Col, Form, Layout, List, Row, Select, Spin, Typography } from 'antd';
import { CheckCircleTwoTone, MinusCircleOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const { Content } = Layout;
const { Title, Paragraph } = Typography;
const RoutingResult = ({ result }) => {
  const intl = useIntl();

  const serviceCenterDisplay = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CheckCircleTwoTone twoToneColor='#52c41a' style={{ marginRight: '.5em' }} />
      <div>
        <FormattedMessage id='admin.routingRules.thisOrderWouldBeRouted' /> {result?.selectedServiceCenter?.city},{' '}
        {result?.selectedServiceCenter?.state}
      </div>
    </div>
  );

  const skuMappingDisplay =
    result.skuServiceCenterMappings &&
    result.skuServiceCenterMappings.length > 0 &&
    result.skuServiceCenterMappings.map(mapping => {
      return (
        <Col>
          <List
            style={{ paddingBottom: '10px' }}
            header={<div>Service Centers for {mapping.sku}</div>}
            bordered
            dataSource={mapping.availableServiceCenters}
            renderItem={item => (
              <List.Item>
                <Typography.Text>
                  {' '}
                  {item.city}, {item.state}{' '}
                </Typography.Text>
              </List.Item>
            )}
          />
        </Col>
      );
    });

  const stateMappingDisplay =
    result.stateServiceCenterMappings &&
    result.stateServiceCenterMappings.length > 0 &&
    result.stateServiceCenterMappings.map(mapping => {
      return (
        <Col>
          <List
            style={{ paddingBottom: '10px' }}
            header={<div>Service Center for {mapping.state}</div>}
            bordered
            dataSource={mapping.availableServiceCenters}
            renderItem={item => (
              <List.Item>
                <Typography.Text>
                  {' '}
                  {item.city}, {item.state}{' '}
                </Typography.Text>
              </List.Item>
            )}
          />
        </Col>
      );
    });

  const serviceCenterDistanceDisplay = result.serviceCenterDistances && result.serviceCenterDistances.length > 0 && (
    <Col>
      <List
        header={<div>Service Center Distance</div>}
        bordered
        dataSource={result.serviceCenterDistances}
        renderItem={item => (
          <List.Item>
            <Row>
              <Col>
                <Typography.Text>
                  {' '}
                  {item.serviceCenter?.city}, {item.serviceCenter?.state}{' '}
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text style={{ paddingLeft: '10px' }}> {item.distance.toFixed(1)} mi </Typography.Text>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </Col>
  );

  const rulingDisplay = (
    <Row>
      <Col>
        <div>{result.ruleUsed}</div>
      </Col>
    </Row>
  );

  return (
    <Content style={{ marginTop: '1rem' }}>
      <Row>{serviceCenterDisplay}</Row>
      <Row>{rulingDisplay}</Row>
      <Row justify='space-around'>
        {skuMappingDisplay}
        {stateMappingDisplay}
        {serviceCenterDistanceDisplay}
      </Row>
    </Content>
  );
};
export default RoutingResult;
