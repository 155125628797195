import {
  Card,
  CardBody,
  Col
} from '@met/react-components';
import { Table, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { notificationsActions } from '../../../../store'
import moment from 'moment';
import {NotificationTypes} from '../../../../enums';

const CommunicationHistory = (serviceOrder) => {
  const { Text } = Typography;
  const dispatch = useDispatch();
  const intl = useIntl();
  const [communicationTableData, setCommunicationTableData] = useState([]);

  useEffect(() => {
    if(serviceOrder?.serviceOrder?.id) {
      getCommunicationHistory();
    }
  }, [serviceOrder]);

  const getCommunicationHistory = async () => {
    try {
      const response = await dispatch(notificationsActions.getNotificationHistoryByServiceOrder(serviceOrder.serviceOrder.id))
      setCommunicationTableData(response.data.data)
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'admin.serviceOrder.communicationHistory.error' }));
    }
  };

  const getEmailType = (notificationType) => {
    switch(notificationType) {
      case NotificationTypes.WILLCALL:
        return 'Will Call';
      case NotificationTypes.DENIEDWARRANTY:
        return 'Denied Warranty';
      default:
        return notificationType;
  }}

  const columns = [
    {
      name: 'emailTemplate',
      id: 'notificationType',
      dataIndex: 'notificationType',
      title: intl.formatMessage({ id: 'admin.serviceOrder.communicationHistory.emailTemplate' }),
      render: record => {return getEmailType(record)}
    },
    {
      name: 'emailAddress',
      id: 'email',
      dataIndex: 'email',
      title: intl.formatMessage({ id: 'admin.serviceOrder.communicationHistory.email' }),
    },
    {
      name: 'dateTime',
      id: 'createdAt',
      dataIndex: 'createdAt',
      title: intl.formatMessage({ id: 'admin.serviceOrder.communicationHistory.dateSent' }),
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: date => {
        return <Text>{moment.utc(date).local().format('MM/DD/YYYY hh:mm A')}</Text>;
      },
    },
    {
      name: 'deliveryStatus',
      id: 'latestDeliveryEvent',
      dataIndex: 'latestDeliveryEvent',
      title: intl.formatMessage({ id: 'admin.serviceOrder.communicationHistory.deliveryStatus' }),
      render: record => {return record?.emailEventTypeId}
    },
    {
      name: 'eventStatus',
      id: 'latestEngagementEvent',
      dataIndex: 'latestEngagementEvent',
      title: intl.formatMessage({ id: 'admin.serviceOrder.communicationHistory.eventStatus' }),
      render: record => {return record?.emailEventTypeId}
    },
  ];
  
  return (
    <>
      {communicationTableData.length > 0 &&
        <Col>
          <Card>
            <CardBody>
              <h3 style={{paddingBottom:'1rem'}}>
                <FormattedMessage id='admin.serviceOrder.communicationHistory.title' />
              </h3>
              <Table 
                columns={columns}
                dataSource={communicationTableData}
                pagination={false}
                rowKey='id'></Table>
            </CardBody>
          </Card>
        </Col>
      }
    </>
  );
}

export default CommunicationHistory;