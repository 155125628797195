// Locales
export const UPDATE_LOCALE = 'UPDATE_LOCALE';

// Site Wide
export const NAVIGATION = 'NAVIGATION';

// Users
export const GET_MET_USER_TOKEN = 'GET_MET_USER_TOKEN';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const UPSERT_USER = 'UPSERT_USER';
export const UPSERT_USER_TERMS_AND_CONDITIONS = 'UPSERT_USER_TERMS_AND_CONDITIONS';
export const UPSERT_USER_NOTIFICATIONS = 'UPSERT_USER_NOTIFICATIONS';
export const GET_USER_EMAILS = 'GET_USER_EMAILS';
export const UPSERT_USER_EMAIL = 'UPSERT_USER_EMAIL';
export const SUBMIT_EMAIL_RECOVERY = 'SUBMIT_EMAIL_RECOVERY';
export const VERIFY_USER_EMAIL = 'VERIFY_USER_EMAIL';
export const VALIDATE_USER_ADDRESS = 'VALIDATE_USER_ADDRESS';
export const UPSERT_USER_ADDRESS = 'UPSERT_USER_ADDRESS';
export const DELETE_USER_ADDRESS = 'DELETE_USER_ADDRESS';
export const UPDATE_PREFERRED_CONTACT_METHOD = 'UPDATE_PREFERRED_CONTACT_METHOD';
export const GET_USER_ADDRESSES = 'GET_USER_ADDRESSES';
export const GET_USER_ADDRESSES_WITH_DEFAULT = 'GET_USER_ADDRESSES_WITH_DEFAULT';
export const CLEAR_ADDRESS_VALIDATION = 'CLEAR_ADDRESS_VALIDATION';
export const UPDATE_PREFERRED_LANGUAGE = 'UPDATE_PREFERRED_LANGUAGE';
export const UPDATE_PREFERENCE = 'UPDATE_PREFERENCE';
export const UPDATE_USER_DEFAULT_ADDRESS = 'UPDATE_USER_DEFAULT_ADDRESS';
export const CLEAR_ADDRESS_MODAL = 'CLEAR_ADDRESS_MODAL';
export const UPDATE_USER_REQUESTED_ACCOUNT_NUMBER = 'UPDATE_USER_REQUESTED_ACCOUNT_NUMBER';

// UserMetadata
export const UPDATE_USER_METADATA = 'UPDATE_USER_METADATA';

// Notifications
export const GET_NOTIFICATION_TYPES = 'GET_NOTIFICATION_TYPES';
export const GET_USER_NOTIFICATION_HISTORY = 'GET_USER_NOTIFICATION_HISTORY';
export const GET_NOTIFICATION_HISTORY_BY_SERVICE_ORDER = 'GET_NOTIFICATION_HISTORY_BY_SERVICE_ORDER';

// ServiceOrders
export const GET_SERVICE_ORDER = 'GET_SERVICE_ORDER';
export const SEARCH_SERVICE_ORDERS = 'SEARCH_SERVICE_ORDERS';
export const SEARCH_SERVICE_ORDERS_ADMIN = 'SEARCH_SERVICE_ORDERS_ADMIN';
export const GET_SERVICE_ORDERS = 'GET_SERVICE_ORDERS';
export const CLEAR_SERVICE_ORDERS = 'CLEAR_SERVICE_ORDERS';
export const CLEAR_SERVICE_ORDERS_ADMIN = 'CLEAR_SERVICE_ORDERS_ADMIN';
export const EXPORT_SERVICE_ORDERS = 'EXPORT_SERVICE_ORDERS';
export const SUBMIT_SERVICE_ORDER = 'SUBMIT_SERVICE_ORDER';
export const UPSERT_WARRANTY_OPTION = 'UPSERT_WARRANTY_OPTION';
export const GET_DENIED_SERVICE_ORDERS = 'GET_DENIED_SERVICE_ORDERS';
export const GET_SERVICE_ORDERS_PDF = 'GET_SERVICE_ORDERS_PDF';
export const GET_SERVICE_ORDER_STATUSES = 'GET_SERVICE_ORDER_STATUSES';
export const RESUBMIT_SERVICE_ORDER_LABEL = 'RESUBMIT_SERVICE_ORDER_LABEL';
export const RESUBMIT_BULK_SERVICE_ORDER_LABEL = 'RESUBMIT_BULK_SERVICE_ORDER_LABEL';
export const RESYNC_USER_ORDER_INDEX = 'RESYNC_USER_ORDER_INDEX';
export const UPDATE_SERVICE_ORDER = 'UPDATE_SERVICE_ORDER';
export const GET_SERVICE_ORDER_ROUTING = 'GET_SERVICE_ORDER_ROUTING';
export const GET_ESTIMATED_SCRAP_DATE = 'GET_ESTIMATED_SCRAP_DATE';

// Recycling
export const GET_SERVICE_ORDER_RECYCLING = 'GET_SERVICE_ORDER_RECYCLING';
export const GET_SERVICE_ORDER_RECYCLING_GROUPS = 'GET_SERVICE_ORDER_RECYCLING_GROUPS';
export const EXPORT_SERVICE_ORDER_RECYCLING = 'EXPORT_SERVICE_ORDER_RECYCLING';
export const UPDATE_SERVICE_ORDER_RECYCLING_REPLACEMENT = 'UPDATE_SERVICE_ORDER_RECYCLING_REPLACEMENT';

// Products
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const CLEAR_SEARCH_PRODUCTS = 'CLEAR_SEARCH_PRODUCTS';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_IMAGE = 'GET_PRODUCT_IMAGE';
export const INSERT_PRODUCT_LMR_CATEGORY = 'INSERT_PRODUCT_LMR_CATEGORY';
export const DELETE_PRODUCT_LMR_CATEGORY = 'DELETE_PRODUCT_LMR_CATEGORY';
export const UPSERT_SERVICE_SKU_MAPPING = 'UPSERT_SERVICE_SKU_MAPPING';
export const DELETE_SERVICE_SKU_MAPPING = 'DELETE_SERVICE_SKU_MAPPING';
export const LOAD_ALL_LMR_MAPPINGS = 'LOAD_ALL_LMR_MAPPINGS';
export const LOAD_SKU_LMR_MAPPINGS = 'LOAD_SKU_LMR_MAPPINGS';
export const GET_SERVICE_PRODUCT = 'GET_SERVICE_PRODUCT';
export const UPSERT_PRODUCT_PROBLEM_MAPPING = 'UPSERT_PRODUCT_PROBLEM_MAPPING';
export const DELETE_PRODUCT_PROBLEM_MAPPING = 'DELETE_PRODUCT_PROBLEM_MAPPING';
export const UPSERT_PRODUCT_INCLUDED_MAPPING = 'UPSERT_PRODUCT_INCLUDED_MAPPING';
export const DELETE_PRODUCT_INCLUDED_MAPPING = 'DELETE_PRODUCT_INCLUDED_MAPPING';
export const REFRESH_PRODUCT_INCLUDED_MAPPING = 'REFRESH_PRODUCT_INCLUDED_MAPPING';
export const INSERT_PRODUCT_PROBLEM_MAPPING_BULK = 'INSERT_PRODUCT_PROBLEM_MAPPING_BULK';
export const DELETE_PRODUCT_PROBLEM_MAPPING_BULK = 'DELETE_PRODUCT_PROBLEM_MAPPING_BULK';
export const UPSERT_LMR_MAPPING = 'UPSERT_LMR_MAPPING';
export const DELETE_LMR_MAPPING = 'DELETE_LMR_MAPPING';
export const ADD_ALL_SERVICE_MAPPINGS = 'ADD_ALL_SERVICE_MAPPINGS';

// Batteries
export const GET_BATTERIES = 'GET_BATTERIES';
export const GET_BATTERY_BY_SKU = 'GET_BATTERY_BY_SKU';
export const CLEAR_SELECTED_BATTERY = 'CLEAR_SELECTED_BATTERY';
export const UPSERT_BATTERY = 'UPSERT_BATTERY';

// Countries
export const GET_COUNTRIES = 'GET_COUNTRIES';

// States
export const GET_STATES = 'GET_STATES';

// Proof of Purchases
export const UPLOAD_PROOF_OF_PURCHASE = 'UPLOAD_PROOF_OF_PURCHASE';
export const DOWNLOAD_PROOF_OF_PURCHASE = 'DOWNLOAD_PROOF_OF_PURCHASE';

// Docs
export const DOWNLOAD_TERMS_AND_CONDITIONS = 'DOWNLOAD_TERMS_AND_CONDITIONS';

// ServiceCenters
export const GET_SERVICE_CENTERS = 'GET_SERVICE_CENTERS';
export const UPSERT_SERVICE_CENTER = 'UPSERT_SERVICE_CENTER';
export const GET_SERVICE_CENTER_MAPPINGS = 'GET_SERVICE_CENTER_MAPPINGS';
export const INSERT_SERVICE_CENTER_MAPPING = 'INSERT_SERVICE_CENTER_MAPPING';
export const UPDATE_SERVICE_CENTER_MAPPING = 'UPDATE_SERVICE_CENTER_MAPPING';
export const DELETE_SERVICE_CENTER_MAPPING = 'DELETE_SERVICE_CENTER_MAPPING';
export const GET_DEFAULT_SERVICE_CENTER_MAPPINGS = 'GET_DEFAULT_SERVICE_CENTER_MAPPINGS';
export const UPDATE_DEFAULT_SERVICE_CENTER = 'UPDATE_DEFAULT_SERVICE_CENTER';

// Problems
export const GET_PRODUCT_PROBLEMS = 'GET_PRODUCT_PROBLEMS';
export const UPSERT_PRODUCT_PROBLEM = 'UPSERT_PRODUCT_PROBLEM';

// Included
export const GET_PRODUCT_INCLUDED = 'GET_PRODUCT_INCLUDED';
export const UPSERT_PRODUCT_INCLUDED = 'UPSERT_PRODUCT_INCLUDED';
export const REFRESH_PRODUCT_INCLUDED = 'REFRESH_PRODUCT_INCLUDED';

// Announcements
export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
export const GET_ACTIVE_ANNOUNCEMENTS = 'GET_ACTIVE_ANNOUNCEMENTS';
export const UPSERT_ANNOUNCEMENT = 'UPSERT_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT';

// Configuration
export const GET_ACTIVE_FEATURES = 'GET_ACTIVE_FEATURES';

// SignalR
export const REGISTER_TRACKING_NUMBER_HUB = 'REGISTER_TRACKING_NUMBER_HUB';
export const GET_SIGNALR_ENDPOINT = 'GET_SIGNALR_ENDPOINT';

// LMR Mappings
export const GET_LMR_CATEGORY_TYPES = 'GET_LMR_CATEGORY_TYPES';
export const GET_LMR_MAPPINGS_BY_SKU = 'GET_LMR_MAPPINGS_BY_SKU';

// Serial Number
export const PARSE_SERIAL_NUMBER = 'PARSE_SERIAL_NUMBER';

// Reports
export const GET_SKU_HUB_VOLUME_REPORT = 'GET_SKU_HUB_VOLUME_REPORT';
export const GET_SKU_STATE_VOLUME_REPORT = 'GET_SKU_STATE_VOLUME_REPORT';
export const GET_SKU_HUB_MAPPINGS_REPORT = 'GET_SKU_HUB_MAPPINGS_REPORT';
export const GET_WILL_CALL_SERVICE_ORDERS_REPORT = 'GET_WILL_CALL_SERVICE_ORDERS_REPORT';
export const GET_DENIED_WARRANTY_SERVICE_ORDERS_REPORT = 'GET_DENIED_WARRANTY_SERVICE_ORDERS_REPORT';

//GenesysChatWidget
export const LOADING_CHATSCHEDULE = 'LOADING_CHATSCHEDULE';

// SYNC TO INTL
export const SYNC_PRODUCT_PROBLEMS_TO_INTL = 'SYNC_PRODUCT_PROBLEMS_TO_INTL';
export const SYNC_PRODUCT_INCLUSIONS_TO_INTL = 'SYNC_PRODUCT_INCLUSIONS_TO_INTL';

// Square/CreditCards
export const SET_SELECTED_CARD = 'SET_SELECTED_CARD';
export const GET_INVITE_TO_PAY = 'GET_INVITE_TO_PAY';
export const GET_CREDIT_CARDS = 'GET_CREDIT_CARDS';
export const ADD_CREDIT_CARD = 'ADD_CREDIT_CARD';
export const GET_SQUARE_CONFIG = 'GET_SQUARE_CONFIG';
export const DELETE_CREDIT_CARD = 'DELETE_CREDIT_CARD';
export const AUTHORIZE_CREDIT_CARD = 'AUTHORIZE_CREDIT_CARD';
