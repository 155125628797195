import { Col, Radio, Row, Typography } from 'antd';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Addresses from './Addresses';
import General from './General';
import LinkedEmails from './LinkedEmails';
import Notifications from './Notifications';
import { PreferencesProvider } from './PreferencesContext';
import PaymentMethod from './PaymentMethod';
import { useFeatureToggles } from '../../hooks';
import {
  BackButton,
  MobileButton,
  MobileButtonSpace,
  PreferencesTabs,
  StyledContainer,
  StyledBackIcon,
  StyledDiv,
} from './styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { StringParam, useQueryParam } from 'use-query-params';
import { PreferenceTabTypes } from '../../enums';

const Preferences = () => {
  return (
    <PreferencesProvider>
      <PreferencesContent />
    </PreferencesProvider>
  );
};

const PreferencesContent = () => {
  const intl = useIntl();
  const { Text } = Typography;
  const { creditCardPayments } = useFeatureToggles();
  const [currentTab, setCurrentTab] = useQueryParam('t', StringParam);

  const tabs = [
    {
      key: PreferenceTabTypes.GENERAL,
      label: (
        <Text data-test-id='generalTab' strong>
          <FormattedMessage id='profile.generalTabLabel' />
        </Text>
      ),
      children: <General />,
    },
    {
      key: PreferenceTabTypes.NOTIFICATIONS,
      label: (
        <Text data-test-id='notificationsTab' strong>
          <FormattedMessage id='profile.notificationsTabLabel' />
        </Text>
      ),
      children: <Notifications />,
    },
    {
      key: PreferenceTabTypes.LINKEDEMAILS,
      label: (
        <Text data-test-id='linkedEmailsTab' strong>
          <FormattedMessage id='profile.linkedEmailsTabLabel' />
        </Text>
      ),
      children: <LinkedEmails />,
    },
    {
      key: PreferenceTabTypes.ADDRESSES,
      label: (
        <Text data-test-id='addressesTab' strong>
          <FormattedMessage id='profile.addressesTabLabel' />
        </Text>
      ),
      children: <Addresses />,
    },
    creditCardPayments && {
      key: PreferenceTabTypes.PAYMENTMETHOD,
      label: (
        <Text data-test-id='paymentMethodTab' strong>
          <FormattedMessage id='profile.paymentMethodTab.tabTitle' />
        </Text>
      ),
      children: <PaymentMethod />,
    },
  ];

  const getTabContent = () => {
    switch (currentTab) {
      case PreferenceTabTypes.GENERAL:
        return <General />;

      case PreferenceTabTypes.NOTIFICATIONS:
        return <Notifications />;

      case PreferenceTabTypes.LINKEDEMAILS:
        return <LinkedEmails />;

      case PreferenceTabTypes.ADDRESSES:
        return <Addresses />;

      case PreferenceTabTypes.PAYMENTMETHOD:
        return creditCardPayments ? <PaymentMethod /> : null;

      default:
        return null;
    }
  };

  const handleNavigation = (e) => {
    setCurrentTab(e.target.value);
  };

  const mobileContent = <StyledDiv>{getTabContent()}</StyledDiv>;

  const tabsMarkup = (
    <PreferencesTabs data-test-id='preferencesTabs' items={tabs} />
  );

  const mobileMenuMarkup = !currentTab && (
    <Radio.Group
      data-test-id='currentTabRadioGroup'
      buttonStyle='solid'
      onChange={handleNavigation}
      style={{ width: '100%' }}
    >
      <MobileButtonSpace
        data-test-id='mobileButtonSpace'
        direction={'vertical'}
        style={{ width: '100%' }}
      >
        <MobileButton
          data-test-id='mobileButtonGeneral'
          value='general'
          className='preferencesMobileButton1'
        >
          <Row>
            <Col xs={23}>
              <FormattedMessage id='profile.generalTabLabel' />
            </Col>
            <Col xs={1}>
              <FontAwesomeIcon icon={faAngleRight} />
            </Col>
          </Row>
        </MobileButton>
        <MobileButton
          data-test-id='mobileButtonNotifications'
          value='notifications'
        >
          <Row>
            <Col xs={23}>
              <FormattedMessage id='profile.notificationsTabLabel' />
            </Col>
            <Col xs={1}>
              <FontAwesomeIcon icon={faAngleRight} />
            </Col>
          </Row>
        </MobileButton>
        <MobileButton
          data-test-id='mobileButtonLinkedEmails'
          value='linkedEmails'
        >
          <Row>
            <Col xs={23}>
              <FormattedMessage id='profile.linkedEmailsTabLabel' />
            </Col>
            <Col xs={1}>
              <FontAwesomeIcon icon={faAngleRight} />
            </Col>
          </Row>
        </MobileButton>
        <MobileButton data-test-id='mobileButtonAddresses' value='addresses'>
          <Row>
            <Col xs={23}>
              <FormattedMessage id='profile.addressesTabLabel' />
            </Col>
            <Col xs={1}>
              <FontAwesomeIcon icon={faAngleRight} />
            </Col>
          </Row>
        </MobileButton>
        {creditCardPayments && (
          <MobileButton
            data-test-id='mobileButtonPaymentMethod'
            value='paymentMethod'
          >
            <Row>
              <Col xs={23}>
                <FormattedMessage id='profile.paymentMethodTab.tabTitle' />
              </Col>
              <Col xs={1}>
                <FontAwesomeIcon icon={faAngleRight} />
              </Col>
            </Row>
          </MobileButton>
        )}
      </MobileButtonSpace>
    </Radio.Group>
  );

  const backButtonMarkup = currentTab && (
    <BackButton
      data-test-id='preferencesBackButton'
      onClick={() => {
        setCurrentTab(null);
      }}
      icon={<StyledBackIcon icon={faChevronLeft} />}
    >
      {intl.formatMessage({ id: 'general.back' })}
    </BackButton>
  );

  return (
    <Fragment>
      <h1 style={{ paddingLeft: '24px' }}>
        <FormattedMessage id='navigation.preferences' />
      </h1>
      <StyledContainer>
        {tabsMarkup}
        {backButtonMarkup}
        {mobileMenuMarkup}
        {mobileContent}
      </StyledContainer>
    </Fragment>
  );
};

export default Preferences;
