import styled from 'styled-components';
import { Menu, Affix, Drawer, Layout } from 'antd';

const { SubMenu } = Menu;
const { Header } = Layout;

export const MobileDrawerMenu = styled(Menu)`
  color: #db011c;
  margin-left: -15px;
  border-right: 0px;
`;

export const MobileNav = styled(Affix)`
  visibility: hidden;
  height: 0;
  @media (max-width: ${props => props.theme.responsive.md}) {
    visibility: visible;
    height: 84px;
  }
`;

export const DesktopNav = styled(Affix)`
  visibility: visible;
  @media (max-width: ${props => props.theme.responsive.md}) {
    visibility: hidden;
    height: 0;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 50px;
`;

export const MobileLabelWrapper = styled.div`
  color: #db011c;
  display: flex;
  align-items: center;
  margin-left: 5px;
  padding-top: 0px;
`;

export const Label = styled.div`
  flex: 1;
`;

export const UppercaseText = styled.div`
  text-transform: uppercase;
  font-weight: normal;
  font-size: 18px;
`;

export const LogoWrapper = styled.div`
  width: 150px;
  height: 100%;
  float: left;
  display: flex;
  cursor: pointer;

  img,
  svg {
    align-self: center;
  }
`;

export const TopNavMenu = styled(Menu)`
  background: #db011c !important;
  border-bottom: none !important;

  height: 100%;
  .ant-menu-submenu:hover,
  .ant-menu-submenu-active {
    .ant-menu-submenu-title {
      color: white;
    }
  }

  .ant-menu-item {
    color: white;
  }
  .ant-menu-item:hover,
  .ant-menu-item-selected {
    color: white !important;
  }

  .ant-menu-sub {
    min-width: 220px !important;
  }

  .ant-menu-submenu-arrow::before {
    width: 18px !important;
    height: 18px !important;
  }
`;

export const TopSubMenu = styled(SubMenu)`
  float: left;
`;

export const StyledDrawer = styled(Drawer)`
  margin-top: 84px;

  @media (max-width: ${props => props.theme.responsive.sm}) {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
`;

export const StyledHeader = styled(Header)`
  zindex: 1;
  width: 100%;
  background: #db011c;
  box-shadow: 0 0 15px 0 #6c757d;
  height: 84px;
  padding: 10px 25px;
`;

export const HeaderContainer = styled.div`
  z-index: 1;
  width: 100%;
  display: flex;
`;

export const DesktopAccountWrapper = styled.div`
  margin-left: auto;
  margin-bottom: 5px;
  display: flex;
  height: 100%;
  align-self: center;
`;
