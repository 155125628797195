import {
  CLEAR_SEARCH_PRODUCTS,
  DELETE_PRODUCT_INCLUDED_MAPPING,
  DELETE_PRODUCT_PROBLEM_MAPPING,
  DELETE_SERVICE_SKU_MAPPING,
  GET_PRODUCT,
  GET_PRODUCT_IMAGE,
  INSERT_PRODUCT_PROBLEM_MAPPING_BULK,
  DELETE_PRODUCT_PROBLEM_MAPPING_BULK,
  LOAD_ALL_LMR_MAPPINGS,
  LOAD_SKU_LMR_MAPPINGS,
  REFRESH_PRODUCT_INCLUDED_MAPPING,
  SEARCH_PRODUCTS,
  UPSERT_PRODUCT_INCLUDED_MAPPING,
  UPSERT_PRODUCT_PROBLEM_MAPPING,
  UPSERT_SERVICE_SKU_MAPPING,
  ADD_ALL_SERVICE_MAPPINGS,
  GET_LMR_MAPPINGS_BY_SKU,
} from '../types';
import { error, success } from '../utils';
import Enumerable from 'linq';

export let initialState = {
  searchProducts: {
    isLoading: false,
    isError: false,
    products: [],
  },
  getProduct: {
    isLoading: false,
    isError: false,
    product: null,
    isServiceEligible: true,
  },
  getProductImage: {
    isLoading: false,
    isError: false,
    url: null,
    sku: null,
  },
  upsertServiceSkuMapping: {
    isLoading: false,
    isError: false,
  },
  deleteServiceSkuMapping: {
    isLoading: false,
    isError: false,
  },
  upsertProductProblemMapping: {
    isLoading: false,
    isError: false,
  },
  deleteProductProblemMapping: {
    isLoading: false,
    isError: false,
  },
  insertProductProblemMappingBulk: {
    isLoading: false,
    isError: false,
  },
  deleteProductProblemMappingBulk: {
    isLoading: false,
    isError: false,
  },
  upsertProductIncludedMapping: {
    isLoading: false,
    isError: false,
  },
  deleteProductIncludedMapping: {
    isLoading: false,
    isError: false,
  },
  loadAllLmrMappings: {
    isLoading: false,
    isError: false,
  },
  getLmrMappingsBySku: {
    isLoading: false,
    isError: false,
  },
  refreshProductInclusions: {
    isLoading: false,
    isError: false,
  },
};

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case SEARCH_PRODUCTS:
      return {
        ...state,
        searchProducts: {
          isLoading: true,
          isError: false,
          products: [],
        },
      };
    case success(SEARCH_PRODUCTS):
      return {
        ...state,
        searchProducts: {
          isLoading: false,
          isError: false,
          products: action.payload.data || [],
        },
      };
    case error(SEARCH_PRODUCTS):
      return {
        ...state,
        searchProducts: {
          isLoading: false,
          isError: true,
          products: [],
        },
      };
    case CLEAR_SEARCH_PRODUCTS:
      return {
        ...state,
        searchProducts: {
          ...initialState.searchProducts,
        },
      };
    case GET_PRODUCT:
      return {
        ...state,
        getProduct: {
          isLoading: true,
          isError: false,
          product: null,
          isServiceEligible: true,
        },
      };
    case success(GET_PRODUCT):
      return {
        ...state,
        getProduct: {
          isLoading: false,
          isError: false,
          product: action.payload.data,
          isServiceEligible: action.payload.data.serviceEligible,
        },
      };
    case error(GET_PRODUCT):
      return {
        ...state,
        getProduct: {
          isLoading: false,
          isError: true,
          product: null,
          isServiceEligible: true,
        },
      };
    case GET_PRODUCT_IMAGE:
      return {
        ...state,
        getProductImage: {
          isLoading: true,
          isError: false,
          url: null,
          sku: null,
        },
      };
    case success(GET_PRODUCT_IMAGE):
      return {
        ...state,
        getProductImage: {
          isLoading: false,
          isError: false,
          url: action.payload.data,
          sku: action.sku,
        },
      };
    case error(GET_PRODUCT_IMAGE):
      return {
        ...state,
        getProductImage: {
          isLoading: false,
          isError: true,
          url: null,
          sku: null,
        },
      };
    case UPSERT_SERVICE_SKU_MAPPING:
      return {
        ...state,
        upsertServiceSkuMapping: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPSERT_SERVICE_SKU_MAPPING):
      if (!state.getProduct.product) {
        state.getProduct.product = {
          serviceSkuMappings: [],
        };
      }

      return {
        ...state,
        upsertServiceSkuMapping: {
          isLoading: false,
          isError: false,
        },
        getProduct: {
          ...state.getProduct,
          product: {
            ...state.getProduct.product,
            serviceSkuMappings: [...state.getProduct.product.serviceSkuMappings, action.payload.data],
          },
        },
      };
    case error(UPSERT_SERVICE_SKU_MAPPING):
      return {
        ...state,
        upsertServiceSkuMapping: {
          isLoading: false,
          isError: true,
        },
      };
    case ADD_ALL_SERVICE_MAPPINGS:
      return {
        ...state,
        upsertServiceSkuMapping: {
          isLoading: true,
          isError: false,
        },
      };
    case success(ADD_ALL_SERVICE_MAPPINGS):
      if (!state.getProduct.product) {
        state.getProduct.product = {
          serviceSkuMappings: [],
        };
      }
      return {
        ...state,
        upsertServiceSkuMapping: {
          isLoading: false,
          isError: false,
        },
        getProduct: {
          ...state.getProduct,
          product: {
            ...state.getProduct.product,
            serviceSkuMappings: [...action.payload.data],
          },
        },
      };
    case error(ADD_ALL_SERVICE_MAPPINGS):
      return {
        ...state,
        upsertServiceSkuMapping: {
          isLoading: false,
          isError: true,
        },
      };

    case DELETE_SERVICE_SKU_MAPPING:
      return {
        ...state,
        deleteServiceSkuMapping: {
          isLoading: true,
          isError: false,
        },
      };
    case success(DELETE_SERVICE_SKU_MAPPING):
      if (!state.getProduct.product) {
        state.getProduct.product = {
          serviceSkuMappings: [],
        };
      }

      return {
        ...state,
        deleteServiceSkuMapping: {
          isLoading: false,
          isError: false,
        },
        getProduct: {
          ...state.getProduct,
          product: {
            ...state.getProduct.product,
            serviceSkuMappings: [
              ...state.getProduct.product.serviceSkuMappings.filter((v, i) => {
                return v.serviceCenterId !== action.payload.data.serviceCenterId;
              }),
            ],
          },
        },
      };
    case error(DELETE_SERVICE_SKU_MAPPING):
      return {
        ...state,
        deleteServiceSkuMapping: {
          isLoading: false,
          isError: true,
        },
      };
    case UPSERT_PRODUCT_PROBLEM_MAPPING:
      return {
        ...state,
        upsertProductProblemMapping: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPSERT_PRODUCT_PROBLEM_MAPPING):
      if (!state.getProduct.product || !state.getProduct.product.productProblems) {
        state.getProduct.product = {
          productProblems: [],
        };
      }
      return {
        ...state,
        upsertProductProblemMapping: {
          isLoading: false,
          isError: false,
        },
        getProduct: {
          ...state.getProduct,
          product: {
            ...state.getProduct.product,
            productProblems: [...state.getProduct.product.productProblems, action.payload.data],
          },
        },
      };
    case error(UPSERT_PRODUCT_PROBLEM_MAPPING):
      return {
        ...state,
        upsertProductProblemMapping: {
          isLoading: false,
          isError: true,
        },
      };
    case INSERT_PRODUCT_PROBLEM_MAPPING_BULK:
      return {
        ...state,
        insertProductProblemMappingBulk: {
          isLoading: true,
          isError: false,
        },
      };
    case success(INSERT_PRODUCT_PROBLEM_MAPPING_BULK):
      if (!state.getProduct.product || !state.getProduct.product.productProblems) {
        state.getProduct.product = {
          productProblems: [],
        };
      }
      return {
        ...state,
        insertProductProblemMappingBulk: {
          isLoading: false,
          isError: false,
        },
        getProduct: {
          ...state.getProduct,
          product: {
            ...state.getProduct.product,
            productProblems: action.payload.data,
          },
        },
      };
    case error(INSERT_PRODUCT_PROBLEM_MAPPING_BULK):
      return {
        ...state,
        insertProductProblemMappingBulk: {
          isLoading: false,
          isError: true,
        },
      };
    case DELETE_PRODUCT_PROBLEM_MAPPING_BULK:
      return {
        ...state,
        deleteProductProblemMappingBulk: {
          isLoading: true,
          isError: false,
        },
      };
    case success(DELETE_PRODUCT_PROBLEM_MAPPING_BULK):
      if (!state.getProduct.product || !state.getProduct.product.productProblems) {
        state.getProduct.product = {
          productProblems: [],
        };
      }
      return {
        ...state,
        deleteProductProblemMappingBulk: {
          isLoading: false,
          isError: false,
        },
        getProduct: {
          ...state.getProduct,
          product: {
            ...state.getProduct.product,
            productProblems: action.payload.data && action.payload.data.length > 0 ? action.payload.data : [],
          },
        },
      };
    case error(DELETE_PRODUCT_PROBLEM_MAPPING_BULK):
      return {
        ...state,
        deleteProductProblemMappingBulk: {
          isLoading: false,
          isError: true,
        },
      };
    case DELETE_PRODUCT_PROBLEM_MAPPING:
      return {
        ...state,
        deleteProductProblemMapping: {
          isLoading: true,
          isError: false,
        },
      };
    case success(DELETE_PRODUCT_PROBLEM_MAPPING):
      if (!state.getProduct.product || !state.getProduct.product.productProblems) {
        state.getProduct.product = {
          productProblems: [],
        };
      }
      return {
        ...state,
        deleteProductProblemMapping: {
          isLoading: false,
          isError: false,
        },
        getProduct: {
          ...state.getProduct,
          product: {
            ...state.getProduct.product,
            productProblems: [
              ...state.getProduct.product.productProblems.filter((v, i) => {
                return v.id !== action.payload.data.problemId;
              }),
            ],
          },
        },
      };
    case error(DELETE_PRODUCT_PROBLEM_MAPPING):
      return {
        ...state,
        deleteProductProblemMapping: {
          isLoading: false,
          isError: true,
        },
      };
    case UPSERT_PRODUCT_INCLUDED_MAPPING:
      return {
        ...state,
        upsertProductIncludedMapping: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPSERT_PRODUCT_INCLUDED_MAPPING):
      if (!state.getProduct.product || !state.getProduct.product.productIncludedMappings) {
        state.getProduct.product = {
          productIncludedMappings: [],
        };
      }
      return {
        ...state,
        upsertProductIncludedMapping: {
          isLoading: false,
          isError: false,
        },
        getProduct: {
          ...state.getProduct,
          product: {
            ...state.getProduct.product,
            productIncludedMappings: [...state.getProduct.product.productIncludedMappings, action.payload.data],
          },
        },
      };
    case error(UPSERT_PRODUCT_INCLUDED_MAPPING):
      return {
        ...state,
        upsertProductIncludedMapping: {
          isLoading: false,
          isError: true,
        },
      };
    case DELETE_PRODUCT_INCLUDED_MAPPING:
      return {
        ...state,
        deleteProductIncludedMapping: {
          isLoading: true,
          isError: false,
        },
      };
    case success(DELETE_PRODUCT_INCLUDED_MAPPING):
      if (!state.getProduct.product || !state.getProduct.product.productIncludedMappings) {
        state.getProduct.product = {
          productIncludedMappings: [],
        };
      }
      return {
        ...state,
        deleteProductIncludedMapping: {
          isLoading: false,
          isError: false,
        },
        getProduct: {
          ...state.getProduct,
          product: {
            ...state.getProduct.product,
            productIncludedMappings: [
              ...state.getProduct.product.productIncludedMappings.filter((v, i) => {
                return v.includedId !== action.payload.data.includedId;
              }),
            ],
          },
        },
      };
    case error(DELETE_PRODUCT_INCLUDED_MAPPING):
      return {
        ...state,
        deleteProductIncludedMapping: {
          isLoading: false,
          isError: true,
        },
      };
    case LOAD_ALL_LMR_MAPPINGS:
      return {
        ...state,
        loadAllLmrMappings: {
          isLoading: true,
          isError: false,
        },
      };
    case success(LOAD_ALL_LMR_MAPPINGS):
      return {
        ...state,
        loadAllLmrMappings: {
          isLoading: false,
          isError: false,
        },
      };
    case error(LOAD_ALL_LMR_MAPPINGS):
      return {
        ...state,
        loadAllLmrMappings: {
          isLoading: false,
          isError: true,
        },
      };
    case LOAD_SKU_LMR_MAPPINGS:
      return {
        ...state,
        loadSkuLmrMappings: {
          isLoading: true,
          isError: false,
        },
      };
    case success(LOAD_SKU_LMR_MAPPINGS):
      return {
        ...state,
        loadSkuLmrMappings: {
          isLoading: false,
          isError: false,
        },
      };
    case error(LOAD_SKU_LMR_MAPPINGS):
      return {
        ...state,
        loadSkuLmrMappings: {
          isLoading: false,
          isError: true,
        },
      };
    case REFRESH_PRODUCT_INCLUDED_MAPPING:
      return {
        ...state,
        refreshProductInclusions: {
          isError: false,
          isLoading: true,
        },
      };
    case success(REFRESH_PRODUCT_INCLUDED_MAPPING):
      return {
        ...state,
        refreshProductInclusions: {
          isError: false,
          isLoading: false,
        },
        getProduct: {
          isLoading: false,
          isError: false,
          product: action.payload.data.updatedProduct,
        },
      };
    case error(REFRESH_PRODUCT_INCLUDED_MAPPING):
      return {
        ...state,
        refreshProductInclusions: {
          isError: true,
          isLoading: false,
        },
      };

    case GET_LMR_MAPPINGS_BY_SKU:
      return {
        ...state,
        getLmrMappingsBySku: {
          isLoading: true,
          isError: false,
        },
      };
    case success(GET_LMR_MAPPINGS_BY_SKU):
      let lastUpdated = Enumerable.from(action.payload.data)
        .orderByDescending(x => x.updatedAt)
        .select(x => x.updatedAt)
        .firstOrDefault();

      return {
        ...state,
        getLmrMappingsBySku: {
          isLoading: false,
          isError: false,
        },
        getProduct: {
          ...state.getProduct,
          product: {
            ...state.getProduct.product,
            productLmrMappings: action.payload.data,
            lmrMappingsUpdatedAt: lastUpdated,
          },
        },
      };
    case error(GET_LMR_MAPPINGS_BY_SKU):
      return {
        ...state,
        getLmrMappingsBySku: {
          isLoading: false,
          isError: true,
        },
      };
    default:
      return state;
  }
};

export default reducer;
