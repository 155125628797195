import {
  SEARCH_SERVICE_ORDERS,
  SEARCH_SERVICE_ORDERS_ADMIN,
  GET_SERVICE_ORDER,
  CLEAR_SERVICE_ORDERS,
  CLEAR_SERVICE_ORDERS_ADMIN,
  EXPORT_SERVICE_ORDERS,
  SUBMIT_SERVICE_ORDER,
  UPSERT_WARRANTY_OPTION,
  GET_SERVICE_ORDERS,
  GET_DENIED_SERVICE_ORDERS,
  GET_SERVICE_ORDERS_PDF,
  GET_SERVICE_ORDER_STATUSES,
  RESUBMIT_SERVICE_ORDER_LABEL,
  RESUBMIT_BULK_SERVICE_ORDER_LABEL,
  UPDATE_SERVICE_ORDER,
  GET_SERVICE_ORDER_ROUTING,
  RESYNC_USER_ORDER_INDEX,
} from '../types';
import { error, success } from '../utils';

export let initialState = {
  searchServiceOrders: {
    isLoading: false,
    isError: false,
    data: [],
    totalCount: null,
    count: null,
    totalPages: null,
  },
  searchServiceOrdersAdmin: {
    isLoading: false,
    isError: false,
    data: [],
    totalCount: null,
    count: null,
    totalPages: null,
  },
  getServiceOrder: {
    isLoading: false,
    isError: false,
    data: null,
  },
  getServiceOrders: {
    isLoading: false,
    isError: false,
    serviceOrders: [],
  },
  exportServiceOrders: {
    isLoading: false,
    isError: false,
  },
  submitServiceOrder: {
    isLoading: false,
    isError: false,
  },
  resubmitSubmitServiceOrderLabel: {
    isLoading: false,
    isError: false,
  },
  resubmitBulkServiceOrderLabel: {
    isLoading: false,
    isError: false,
  },
  resyncUserOrderIndex: {
    isLoading: false,
    isError: false,
  },
  upsertWarrantyOption: {
    isLoading: false,
    isError: false,
  },
  getDeniedServiceOrders: {
    isLoading: false,
    isError: false,
    deniedServiceOrders: [],
  },
  getServiceOrdersPdf: {
    isLoading: false,
    isError: false,
  },
  getServiceOrderStatuses: {
    isLoading: false,
    isError: false,
    serviceOrderStatuses: [],
  },
  getServiceOrderRouting: {
    isLoading: false,
    isError: false,
    routingResult: null,
  },
};

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case SEARCH_SERVICE_ORDERS:
      return {
        ...state,
        searchServiceOrders: {
          isLoading: true,
          isError: false,
          data: state.searchServiceOrders.data,
        },
      };
    case success(SEARCH_SERVICE_ORDERS):
      return {
        ...state,
        searchServiceOrders: {
          isLoading: false,
          isError: false,
          data: [...state.searchServiceOrders.data, ...action.payload.data],
          totalCount: action.payload.totalCount,
          count: action.payload.count,
          totalPages: action.payload.totalPages,
        },
      };
    case error(SEARCH_SERVICE_ORDERS):
      return {
        ...state,
        searchServiceOrders: {
          isLoading: false,
          isError: true,
          data: [],
        },
      };
    case SEARCH_SERVICE_ORDERS_ADMIN:
      return {
        ...state,
        searchServiceOrdersAdmin: {
          isLoading: true,
          isError: false,
          data: state.searchServiceOrdersAdmin.data,
        },
      };
    case success(SEARCH_SERVICE_ORDERS_ADMIN):
      return {
        ...state,
        searchServiceOrdersAdmin: {
          isLoading: false,
          isError: false,
          data: action.payload.data,
          totalCount: action.payload.totalCount,
          count: action.payload.count,
          totalPages: action.payload.totalPages,
        },
      };
    case error(SEARCH_SERVICE_ORDERS_ADMIN):
      return {
        ...state,
        searchServiceOrdersAdmin: {
          isLoading: false,
          isError: true,
          data: [],
        },
      };
    case GET_SERVICE_ORDER:
      return {
        ...state,
        getServiceOrder: {
          ...state.getServiceOrder,
          isLoading: true,
          isError: false,
        },
      };
    case success(GET_SERVICE_ORDER):
      return {
        ...state,
        getServiceOrder: {
          isLoading: false,
          isError: false,
          data: action.payload.data,
        },
      };
    case error(GET_SERVICE_ORDER):
      return {
        ...state,
        getServiceOrder: {
          isLoading: false,
          isError: true,
          data: null,
        },
      };
    case GET_SERVICE_ORDERS:
      return {
        ...state,
        getServiceOrders: {
          ...state.getServiceOrders,
          isLoading: true,
          isError: false,
        },
      };
    case success(GET_SERVICE_ORDERS):
      return {
        ...state,
        getServiceOrders: {
          isLoading: false,
          isError: false,
          serviceOrders: action.payload.data,
        },
      };
    case error(GET_SERVICE_ORDERS):
      return {
        ...state,
        getServiceOrders: {
          isLoading: false,
          isError: true,
          serviceOrders: [],
        },
      };
    case CLEAR_SERVICE_ORDERS:
      return {
        ...state,
        searchServiceOrders: {
          isLoading: false,
          isError: false,
          data: [],
          totalCount: 0,
          count: 0,
          totalPages: 0,
          additionalData: [],
        },
      };
    case CLEAR_SERVICE_ORDERS_ADMIN:
      return {
        ...state,
        searchServiceOrdersAdmin: {
          ...initialState.searchServiceOrdersAdmin,
        },
      };
    case EXPORT_SERVICE_ORDERS:
      return {
        ...state,
        exportServiceOrders: {
          isLoading: true,
          isError: false,
        },
      };
    case success(EXPORT_SERVICE_ORDERS):
      return {
        ...state,
        exportServiceOrders: {
          isLoading: false,
          isError: false,
        },
      };
    case error(EXPORT_SERVICE_ORDERS):
      return {
        ...state,
        exportServiceOrders: {
          isLoading: false,
          isError: true,
        },
      };
    case SUBMIT_SERVICE_ORDER:
      return {
        ...state,
        submitServiceOrder: {
          isLoading: true,
          isError: false,
        },
      };
    case success(SUBMIT_SERVICE_ORDER):
      return {
        ...state,
        submitServiceOrder: {
          isLoading: false,
          isError: false,
        },
      };
    case error(SUBMIT_SERVICE_ORDER):
      return {
        ...state,
        submitServiceOrder: {
          isLoading: false,
          isError: true,
        },
      };
    case UPDATE_SERVICE_ORDER:
      return {
        ...state,
        getServiceOrder: {
          ...state.getServiceOrder,
          isLoading: true,
          isError: false,
        },
      };
    case success(UPDATE_SERVICE_ORDER):
      return {
        ...state,
        getServiceOrder: {
          isLoading: false,
          isError: false,
          data: action.payload.data,
        },
      };
    case error(UPDATE_SERVICE_ORDER):
      return {
        ...state,
        getServiceOrder: {
          isLoading: false,
          isError: true,
          data: null,
        },
      };
    case RESUBMIT_SERVICE_ORDER_LABEL:
      return {
        ...state,
        resubmitSubmitServiceOrderLabel: {
          isLoading: true,
          isError: false,
        },
      };
    case success(RESUBMIT_SERVICE_ORDER_LABEL):
      return {
        ...state,
        resubmitSubmitServiceOrderLabel: {
          isLoading: false,
          isError: false,
        },
        getServiceOrder: {
          isLoading: false,
          isError: false,
          data: action.payload.data,
        },
      };
    case error(RESUBMIT_SERVICE_ORDER_LABEL):
      return {
        ...state,
        resubmitSubmitServiceOrderLabel: {
          isLoading: false,
          isError: true,
        },
      };
    case RESUBMIT_BULK_SERVICE_ORDER_LABEL:
      return {
        ...state,
        resubmitBulkServiceOrderLabel: {
          isLoading: true,
          isError: false,
        },
      };
    case success(RESUBMIT_BULK_SERVICE_ORDER_LABEL):
      return {
        ...state,
        resubmitBulkServiceOrderLabel: {
          isLoading: false,
          isError: false,
        },
        getServiceOrder: {
          isLoading: false,
          isError: false,
          data: null,
        },
      };
    case error(RESUBMIT_BULK_SERVICE_ORDER_LABEL):
      return {
        ...state,
        resubmitBulkServiceOrderLabel: {
          isLoading: false,
          isError: true,
        },
      };
    case RESYNC_USER_ORDER_INDEX:
      return {
        ...state,
        resyncUserOrderIndex: {
          isLoading: true,
          isError: false,
        },
      };
    case success(RESYNC_USER_ORDER_INDEX):
      return {
        ...state,
        resyncUserOrderIndex: {
          isLoading: false,
          isError: false,
        },
      };
    case error(RESYNC_USER_ORDER_INDEX):
      return {
        ...state,
        resyncUserOrderIndex: {
          isLoading: false,
          isError: true,
          ...action.payload,
        },
      };
    case UPSERT_WARRANTY_OPTION:
      return {
        ...state,
        upsertWarrantyOption: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPSERT_WARRANTY_OPTION):
      return {
        ...state,
        upsertWarrantyOption: {
          isLoading: false,
          isError: false,
        },
        getServiceOrder: {
          ...state.getServiceOrder,
          data: {
            ...state.getServiceOrder.data,
            warrantyOption: action.payload.data,
            paymentMethodId: action.paymentMethodId,
          },
        },
      };
    case error(UPSERT_WARRANTY_OPTION):
      return {
        ...state,
        upsertWarrantyOption: {
          isLoading: false,
          isError: true,
        },
      };
    case GET_DENIED_SERVICE_ORDERS:
      return {
        ...state,
        getDeniedServiceOrders: {
          isLoading: true,
          isError: false,
          deniedServiceOrders: [],
        },
      };
    case success(GET_DENIED_SERVICE_ORDERS):
      return {
        ...state,
        getDeniedServiceOrders: {
          isLoading: false,
          isError: false,
          deniedServiceOrders: action.payload.data,
        },
      };
    case error(GET_DENIED_SERVICE_ORDERS):
      return {
        ...state,
        getDeniedServiceOrders: {
          isLoading: false,
          isError: true,
          deniedServiceOrders: [],
        },
      };
    case GET_SERVICE_ORDERS_PDF:
      return {
        ...state,
        getServiceOrdersPdf: {
          isLoading: true,
          isError: false,
        },
      };
    case success(GET_SERVICE_ORDERS_PDF):
      return {
        ...state,
        getServiceOrdersPdf: {
          isLoading: false,
          isError: false,
        },
      };
    case error(GET_SERVICE_ORDERS_PDF):
      return {
        ...state,
        getServiceOrdersPdf: {
          isLoading: false,
          isError: true,
        },
      };
    case GET_SERVICE_ORDER_STATUSES:
      return {
        ...state,
        getServiceOrderStatuses: {
          isLoading: true,
          isError: false,
          serviceOrderStatuses: [],
        },
      };
    case success(GET_SERVICE_ORDER_STATUSES):
      return {
        ...state,
        getServiceOrderStatuses: {
          isLoading: false,
          isError: false,
          serviceOrderStatuses: action.payload.data,
        },
      };
    case error(GET_SERVICE_ORDER_STATUSES):
      return {
        ...state,
        getServiceOrderStatuses: {
          isLoading: false,
          isError: true,
          serviceOrderStatuses: [],
        },
      };
    case GET_SERVICE_ORDER_ROUTING:
      return {
        ...state,
        getServiceOrderRouting: {
          isLoading: true,
          isError: false,
          routingResult: null,
        },
      };
    case success(GET_SERVICE_ORDER_ROUTING):
      return {
        ...state,
        getServiceOrderRouting: {
          isLoading: false,
          isError: false,
          routingResult: action.payload.data,
        },
      };
    case error(GET_SERVICE_ORDER_ROUTING):
      return {
        ...state,
        getServiceOrderRouting: {
          isLoading: false,
          isError: true,
          routingResult: null,
        },
      };
    default:
      return state;
  }
};

export default reducer;
