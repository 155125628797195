import { ModalBody } from '@met/react-components';
import styled from 'styled-components';

export const StyledModalBody = styled(ModalBody)`
  overflow-x: hidden;

  h4 {
    color: ${props => props.theme.colors.red};
  }

  .slick-dots {
    position: static !important;
  }
`;

export const ImageWrapper = styled.div`
  text-align: center;

  img {
    max-width: 100%;
    max-height: 400px;
    display: inline-block;
  }

  @media (max-width: ${props => props.theme.screenSize.md}) {
    max-height: 200px;
  }
`;
