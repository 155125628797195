import createAxiosAction from '../createAxiosAction';
import { DOWNLOAD_TERMS_AND_CONDITIONS } from '../types';

export default {
  downloadTermsAndConditions: () => {
    return createAxiosAction({
      url: `/api/v1/docs/termsconditions`,
      startAction: DOWNLOAD_TERMS_AND_CONDITIONS,
      method: 'GET',
    });
  },
};
