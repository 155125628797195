import React from 'react';
import { Button, Col, DatePicker, Form, Input, Modal, notification, Row, Spin, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { serviceOrdersActions } from '../../../store';
import { QuestionCircleOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;

const ResyncOrderIndexModal = ({ hidden, toggle }) => {
  const dispatch = useDispatch();
  const resyncIndex = useSelector((state) => state.serviceOrders.resyncUserOrderIndex);
  const [form] = Form.useForm();

  const handleResync = () => {
    form
      .validateFields()
      .then((values) => {
        const startDate = values.dateRange[0].format('YYYY-MM-DD');
        const endDate = values.dateRange[1].format('YYYY-MM-DD');

        dispatch(serviceOrdersActions.resyncUserOrderIndex(values.userEmail, startDate, endDate))
          .then((res) => {
            if (!resyncIndex.isLoading) {
              toggle();
              completedNotification();
            }
          })
          .catch((e) => {
            if (e.response.status === 400) {
              errorNotification(e.response.data);
            } else {
              errorNotification('Something went wrong. Please try again later.');
            }

            toggle();
          });

        form.resetFields();
      })
      .catch((e) => {});
  };

  const errorNotification = (description) => {
    notification.error({
      placement: 'bottomRight',
      message: 'Oops! Something went wrong.',
      description: description,
    });
  };

  const completedNotification = () => {
    notification.success({
      placement: 'bottomRight',
      message: 'Orders Have Been Queued for Resync',
      description: 'Give it a few minutes to complete before you check for changes.',
    });
  };

  const bodyContent = (
    <React.Fragment>
      {resyncIndex.isLoading ? (
        <Row style={{ textAlign: 'center' }}>
          <Col xs={24}>Retrieving and queuing orders for search index resync. This may take a few minutes.</Col>
          <Col xs={24} style={{ paddingTop: '10px' }}>
            <Spin size='middle' />
          </Col>
        </Row>
      ) : (
        <>
          <Form
            name='resyncOrderIndexForm'
            form={form}
            onFinish={handleResync}
            scrollToFirstError={true}
            layout='vertical'
          >
            <Row>
              <Col xs={20}>
                <Form.Item
                  label='User Email'
                  name='userEmail'
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                    // put a rule here to validate email format
                    {
                      pattern: new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$'),
                      message: 'Please use a valid email address',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={20}>
                <Form.Item
                  name={'dateRange'}
                  label={
                    <div>
                      Created Date Range
                      <Tooltip
                        title='The created date range for this users service orders to target for index resync'
                        placement='bottom'
                      >
                        <QuestionCircleOutlined className='ml-2' />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <RangePicker />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </React.Fragment>
  );

  return (
    <Modal
      data-test-id='resyncUserOrdersModal'
      title=<fragment>
        Resync Order Search Index
        <Tooltip
          title='When orders seem out of date we can trigger a resync of specific orders here'
          placement='bottom'
        >
          <QuestionCircleOutlined className='ml-2' />
        </Tooltip>
      </fragment>
      open={!hidden}
      onCancel={toggle}
      closable={!resyncIndex.isLoading}
      maskClosable={!resyncIndex.isLoading}
      afterClose={form.resetFields()}
      footer={[
        <Button
          data-test-id='resyncUserOrdersModalCancel'
          key='cancelBtn'
          disabled={resyncIndex.isLoading}
          onClick={() => toggle()}
          className='mr-2'
        >
          Cancel
        </Button>,
        <Button
          data-test-id='resyncUserOrdersModalReprocess'
          key='reprocessBtn'
          type={'primary'}
          disabled={resyncIndex.isLoading}
          onClick={() => {
            form.submit();
          }}
        >
          Resync
        </Button>,
      ]}
      destroyOnClose
    >
      {bodyContent}
    </Modal>
  );
};

export default ResyncOrderIndexModal;
