import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Form, Input, message, Skeleton, Typography} from 'antd';
import {FormattedMessage, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {useDynamicScript, useSquarePaymentFormConfig} from '../../../hooks';
import {getSquareUrl, isSquareSandbox, squareProdUrl, squareSandboxUrl} from '../../../helpers/squareHelper';
import {squareActions} from '../../../store';
import {DisclaimerContainer} from "../styled";
import PageError from "../../PageError/PageError";

const { Text } = Typography;

const SquarePaymentForm = forwardRef(({ onCardNonceSuccess, showSaveCard, showDisclaimer }, ref) => {
  const intl = useIntl();
  const formRef = useRef();
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    handleFormSubmit: () => {
      formRef?.current?.submit();
    },
  }));

  const squareApplicationId = useSelector(state => state.square.squareApplicationId);

  const [sqCard, setSqCard] = useState(undefined);
  const [sqCardLoading, setSqCardLoading] = useState(true);
  const [squareUpJsConfig, setSquareUpJsConfig] = useState({
    async: true,
    container: 'body',
    src: getSquareUrl(squareApplicationId),
    clearCacheOnSrcChange: true,
    loadAutomatically: false,
  });
  const [loaded, loadError, loadTheScript] = useDynamicScript(squareUpJsConfig);
  const [configured, sqPaymentFormConfig] = useSquarePaymentFormConfig(squareApplicationId, null);

  useEffect(() => {
    if (!squareApplicationId) {
      dispatch(squareActions.getSquareConfig());
    }

    if (squareApplicationId && isSquareSandbox(squareApplicationId) && squareUpJsConfig.src !== squareSandboxUrl) {
      setSquareUpJsConfig({
        ...squareUpJsConfig,
        src: squareSandboxUrl,
      });
    } else if (squareApplicationId && !isSquareSandbox(squareApplicationId) && squareUpJsConfig.src !== squareProdUrl) {
      setSquareUpJsConfig({
        ...squareUpJsConfig,
        src: squareProdUrl,
      });
    }
  }, [squareApplicationId]);

  useEffect(() => {
    if (squareApplicationId && squareUpJsConfig) {
      loadTheScript();
    }
  }, [squareUpJsConfig, squareApplicationId]);

  useEffect(() => {
    if (loadError) {
      setSqCardLoading(false);
      message.error(intl.formatMessage({ id: 'general.genericError' }));
    }
  }, [loadError]);

  const getSquareCard = async () => {
    const payments = window.Square.payments(sqPaymentFormConfig.applicationId, sqPaymentFormConfig.locationId);
    const card = await payments.card({});

    await card.attach('#card-container');

    setSqCard(card);
    setSqCardLoading(false);
  };

  useEffect(() => {
    if (configured && loaded && !loadError) {
      setSqCardLoading(true);
      getSquareCard().then(r => {});
    }
  }, [configured, loaded]);

  const requestCardNonce = async formVal => {
    const result = await sqCard.tokenize();
    const saveCard = true;

    if (result.status === 'OK') {
      const cardData = {
        card_brand: result.details.card.brand,
        last_4: result.details.card.last4,
        billing_postal_code: result.details.billing ? result.details.billing.postalCode : undefined,
      };

      onCardNonceSuccess(cardData, result.token, formVal.cardholderName, saveCard);
    }

    if (result.status === 'INVALID') {
      if (result.errors && result.errors.length) {
        message.error(`${result.errors[0].field}: ${result.errors[0].message}`);
      }
    }
  };
  
  const formMarkup = !loadError && (
    <Form.Item
      name='cardholderName'
      rules={[
        {
          required: true,
          message: 'Required',
        },
      ]}
    >
      <Input id='cardholder-name' placeholder={intl.formatMessage({ id: 'square.cardholderNamePlaceholder' })} />
    </Form.Item>
  );
  
  const disclaimer = showDisclaimer && !loadError && (
    <DisclaimerContainer>
      <Text>
        <FormattedMessage id='square.addCardDisclaimer'/>
      </Text>
    </DisclaimerContainer>
  );
  
  const errorLoadingSquare = loadError && (
    <PageError
      title={intl.formatMessage({ id: 'general.genericError' })}
    />
  );

  return (
    <>
      <Form id='creditCardForm' ref={formRef} onFinish={values => requestCardNonce(values)}>
        <Skeleton loading={sqCardLoading} active>
          {formMarkup}
        </Skeleton>
        <span id='card-container' />
      </Form>
      { disclaimer }
      {errorLoadingSquare}
    </>
  );
});

export default SquarePaymentForm;
