import Enumerable from 'linq';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const RepairProcessContext = createContext({
  submittingServiceOrder: false,
  repairItems: [],
  setRepairItems: () => {},
  shipping: {},
  setShipping: () => {},
  getServiceOrder: () => {},
  batteryIncluded: false,
});

const useRepairProcessContext = () => useContext(RepairProcessContext);

const RepairProcessProvider = ({ children }) => {
  const submittingServiceOrder = useSelector(state => state.serviceOrders.submitServiceOrder.isLoading);

  const [repairItems, setRepairItems] = useState([]);
  const [shipping, setShipping] = useState({});
  const [batteryIncluded, setBatteryIncluded] = useState(false);

  useEffect(() => {
    if (repairItems && repairItems.length > 0) {
      let repairIncludesBattery = Enumerable.from(repairItems).any(x => x.batteryIncluded);

      setBatteryIncluded(repairIncludesBattery);
    }
  }, [repairItems]);

  const getServiceOrder = () => {
    let serviceOrder = {};
    serviceOrder.repairItems = repairItems;
    serviceOrder.shipping = shipping;

    return serviceOrder;
  };

  return (
    <RepairProcessContext.Provider
      value={{
        submittingServiceOrder,
        repairItems,
        setRepairItems,
        shipping,
        setShipping,
        getServiceOrder,
        batteryIncluded,
      }}
    >
      {children}
    </RepairProcessContext.Provider>
  );
};

export { RepairProcessContext, useRepairProcessContext, RepairProcessProvider };
