import { CLEAR_SELECTED_BATTERY, GET_BATTERIES, GET_BATTERY_BY_SKU, UPSERT_BATTERY } from '../types';
import { error, success } from '../utils';

export let initialState = {
  getBatteries: {
    isLoading: false,
    isError: false,
    batteries: [],
  },
  selectedBattery: {
    isLoading: false,
    isError: false,
    battery: null,
  },
  upsertBattery: {
    isLoading: false,
    isError: false,
  },
};

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_BATTERIES:
      return {
        ...state,
        getBatteries: {
          isLoading: true,
          isError: false,
          batteries: [],
        },
      };
    case success(GET_BATTERIES):
      return {
        ...state,
        getBatteries: {
          isLoading: false,
          isError: false,
          batteries: action.payload.data,
        },
      };
    case error(GET_BATTERIES):
      return {
        ...state,
        getBatteries: {
          isLoading: false,
          isError: true,
          batteries: [],
        },
      };
    case GET_BATTERY_BY_SKU:
      return {
        ...state,
        selectedBattery: {
          isLoading: true,
          isError: false,
          battery: null,
        },
      };
    case success(GET_BATTERY_BY_SKU):
      return {
        ...state,
        selectedBattery: {
          isLoading: false,
          isError: false,
          battery: action.payload.data,
        },
      };
    case error(GET_BATTERY_BY_SKU):
      return {
        ...state,
        selectedBattery: {
          isLoading: false,
          isError: true,
          battery: null,
        },
      };
    case CLEAR_SELECTED_BATTERY:
      return {
        ...state,
        selectedBattery: {
          isLoading: false,
          isError: false,
          battery: null,
        },
      };
    case UPSERT_BATTERY:
      return {
        ...state,
        upsertBattery: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPSERT_BATTERY):
      let batteries = state.getBatteries.batteries;
      return {
        ...state,
        getBatteries: {
          ...state.getBatteries,
          batteries: batteries.map(battery => {
            if (battery.sku === action.payload.sku) return action.payload;
            else {
              return battery;
            }
          }),
        },
        upsertBattery: {
          isLoading: false,
          isError: false,
        },
      };
    case error(UPSERT_BATTERY):
      return {
        ...state,
        upsertBattery: {
          isLoading: false,
          isError: true,
        },
      };
    default:
      return state;
  }
};

export default reducer;
