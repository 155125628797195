import React from 'react';
import { StyledBurger } from './styled';

export const Hamburger = ({ onClick, open }) => {
  return (
    <StyledBurger data-test-id='styledHamburger' onClick={onClick} open={open}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};
