import createAxiosAction from '../createAxiosAction';
import { GET_PRODUCT_INCLUDED, UPSERT_PRODUCT_INCLUDED, REFRESH_PRODUCT_INCLUDED } from '../types';

export default {
  getProductInclusions: () => {
    return createAxiosAction({
      url: `api/v1/productIncluded`,
      startAction: GET_PRODUCT_INCLUDED,
    });
  },
  upsertProductInclusion: (id, description) => {
    return createAxiosAction({
      url: `/api/v1/productIncluded`,
      method: 'POST',
      body: {
        id: id,
        description: description,
      },
      startAction: UPSERT_PRODUCT_INCLUDED,
    });
  },
  refreshProductInclusions: () => {
    return createAxiosAction({
      url: '/api/v1/productIncluded/refresh',
      method: 'GET',
      startAction: REFRESH_PRODUCT_INCLUDED,
    });
  },
};
