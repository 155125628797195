import { Typography } from 'antd';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

const { Text, Title } = Typography;

const ShippingInfo = ({ senderName, senderLine1, senderCity, senderState, senderPostalCode, senderCountry }) => {
  return (
    <Fragment>
      <Title level={4}>
        <FormattedMessage id='repairDetails.shippingInfoTitle' />
      </Title>
      <Text strong>{senderName}</Text>
      <br />
      <Text>{senderLine1}</Text>
      <br />
      <Text>{`${senderCity}, ${senderState} ${senderPostalCode} ${senderCountry}`}</Text>
    </Fragment>
  );
};

export default ShippingInfo;
