import React from 'react';
import { RepairProcessProvider } from '../../../containers/RepairProcess/RepairProcessContext';
import Layout from '../Layout';

const RepairProcessLayout = props => {
  return (
    <Layout>
      <RepairProcessProvider>
        {props.children}
      </RepairProcessProvider>
    </Layout>
  );
};

export default RepairProcessLayout;
