export default {
  isEnabled: category => {
    return isEnabled(category);
  },
  canLogGTM: () => {
    return isEnabled('C0002') || isEnabled('2');
  },
  canLogAI: () => {
    return isEnabled('C0002') || isEnabled('2');
  },
};

const isEnabled = category => {
  if (window.OnetrustActiveGroups) {
    let activeGroups = window.OnetrustActiveGroups.split(',').filter(Boolean);
    return activeGroups.includes(category);
  }
};
