import { Icon } from '@met/react-components';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Image,
  Modal,
  Row,
  Space,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { PageLoader, ServiceCard } from '../../../../../../components';
import { useToggle } from '../../../../../../hooks';
import { serialNumberActions } from '../../../../../../store';
import RepairItemsList from '../../../../components/RepairItemsList';
import { useRepairItemsContext } from '../../../RepairItemsContext';
import { FocusedServiceCard, SerialNumberSearch } from './styled';

const { Paragraph, Text, Title } = Typography;

const SerialNumber = ({ onSerialNumberUnreadableClick, onContinueClick }) => {
  const intl = useIntl();
  const formRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    product,
    serialNumber,
    setSerialNumberUnreadable,
    setSerialNumber,
    manufactureDate,
    setManufactureDate,
  } = useRepairItemsContext();

  const [parsingSerialNumber, setParsingSerialNumber] = useState(false);
  const [serialNumberInvalid, setSerialNumberInvalid] = useState(false);
  const [isM4Battery, setIsM4Battery] = useState(false);
  const [isM28Battery, setIsM28Battery] = useState(false);
  const [showModal, toggleModal] = useToggle(false);

  useEffect(() => {
    if (product.isBattery) {
      if (product.description.toLowerCase().includes('m4')) {
        setIsM4Battery(true);
      } else if (product.description.toLowerCase().includes('m28')) {
        setIsM28Battery(true);
      }
    }
  }, [product]);

  const onLookupClick = (form) => {
    if (form) {
      setParsingSerialNumber(true);

      // Use artificial load for better UX
      setTimeout(() => {
        setParsingSerialNumber(false);
        setSerialNumberInvalid(false);

        if (product?.isBattery && (isM4Battery || isM28Battery)) {
          setManufactureDate(null);
          setSerialNumber(form.serialNumber);
          setSerialNumberUnreadable(false);
        } else {
          dispatch(
            serialNumberActions.parseSerialNumber(
              form.serialNumber,
              product.isBattery
            )
          )
            .then((res) => {
              setManufactureDate(res.data.data);
              setSerialNumber(form.serialNumber);
              setSerialNumberUnreadable(false);
            })
            .catch(() => {
              toast.error(
                intl.formatMessage({
                  id: 'repairProcess.warrantyInformation.serialNumberView.parseError',
                })
              );
              setManufactureDate(null);
              setSerialNumber(form.serialNumber);
              setSerialNumberUnreadable(false);
              setSerialNumberInvalid(true);
            });
        }
      }, 1500);
    }
  };

  if (!product) {
    return <PageLoader />;
  }

  const repairItemsListMarkup = (
    <ServiceCard>
      <RepairItemsList />
    </ServiceCard>
  );

  const serialInvalidMarkup = serialNumberInvalid && !parsingSerialNumber && (
    <Fragment>
      <Paragraph type='danger'>
        <FormattedMessage id='repairProcess.warrantyInformation.serialNumberView.parseError' />
      </Paragraph>
    </Fragment>
  );

  const serialResultsMarkup = serialNumber && (
    <Card style={{ maxWidth: '750px' }}>
      <Descriptions
        title={intl.formatMessage({
          id: 'repairProcess.warrantyInformation.serialNumberView.toolInfo',
        })}
        colon={false}
        column={{ xs: 1, sm: 3 }}
        layout='vertical'
        bordered
        labelStyle={{ fontWeight: '600' }}
      >
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'repairProcess.warrantyInformation.general.serialNumber',
          })}
        >
          <Text data-hj-allow>{serialNumber}</Text>
        </Descriptions.Item>
        {!isM4Battery && !isM28Battery && (
          <Descriptions.Item
            label={intl.formatMessage({
              id: 'repairProcess.warrantyInformation.general.manufactureDate',
            })}
          >
            <Text data-hj-allow>
              {manufactureDate
                ? moment(manufactureDate).local().format('MM/DD/YYYY')
                : intl.formatMessage({
                    id: 'repairProcess.warrantyInformation.general.unknown',
                  })}
            </Text>
          </Descriptions.Item>
        )}
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'repairProcess.warrantyInformation.general.warrantyPeriod',
          })}
        >
          <Text data-hj-allow>
            {product?.warrantyPeriod}{' '}
            <FormattedMessage id='repairProcess.warrantyInformation.general.years' />
          </Text>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );

  const serialNumberInfoModal = (
    <Modal
      centered
      width={800}
      visible={showModal}
      footer={null}
      onCancel={toggleModal}
    >
      <div>
        <Title level={3} strong>
          <FormattedMessage id='repairProcess.warrantyInformation.serialNumberView.isMyToolCovered' />
        </Title>
        <Paragraph>
          <FormattedMessage id='repairProcess.warrantyInformation.serialNumberView.warrantyCoverageListTitle' />
        </Paragraph>
        <Paragraph>
          <ul style={{ marginBottom: 0 }}>
            <li>
              <FormattedMessage id='repairProcess.warrantyInformation.serialNumberView.warrantyCoverageListItem1' />
            </li>
            <li>
              <FormattedMessage id='repairProcess.warrantyInformation.serialNumberView.warrantyCoverageListItem2' />
            </li>
            <li>
              <FormattedMessage id='repairProcess.warrantyInformation.serialNumberView.warrantyCoverageListItem3' />
            </li>
          </ul>
        </Paragraph>
      </div>
      <div>
        <Title level={3} strong>
          <FormattedMessage id='repairProcess.warrantyInformation.serialNumberView.findSerialNumber' />
        </Title>
        <Paragraph>
          <FormattedMessage id='repairProcess.warrantyInformation.serialNumberView.serialNumberExampleText' />
        </Paragraph>
        <Image
          src={
            window.location.origin + '/images/tool_serial_number_example.png'
          }
          alt={intl.formatMessage({
            id: 'repairProcess.warrantyInformation.serialNumberView.serialNumberExampleAlt',
          })}
          style={{ maxWidth: '500px' }}
        />
      </div>
    </Modal>
  );

  return (
    <Fragment>
      <Row gutter={[16, 0]}>
        <Col xs={24} lg={16}>
          <FocusedServiceCard>
            <Title level={3} strong>
              <FormattedMessage id='repairProcess.warrantyInformation.serialNumberView.serialNumberSearchTitle' />
              <Icon
                data-test-id='serialNumberSearchTitle'
                className='ml-2'
                type='question-circle'
                xs
                onClick={toggleModal}
              />
            </Title>
            <Form
              name='serialLookupForm'
              ref={formRef}
              onFinish={onLookupClick}
              initialValues={{ serialNumber: serialNumber }}
            >
              <Form.Item
                name='serialNumber'
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'general.required' }),
                  },
                  {
                    pattern: new RegExp(/^[A-Za-z0-9 ]*$/g),
                    message: intl.formatMessage({
                      id: 'repairProcess.warrantyInformation.serialNumberView.noSpecialCharacters',
                    }),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && product.isBattery) {
                        return Promise.resolve();
                      } else if (
                        value &&
                        !product.isBattery &&
                        (value.length < 13 || value.length > 15)
                      ) {
                        return Promise.reject(
                          new Error(
                            intl.formatMessage({
                              id: 'repairProcess.warrantyInformation.serialNumberView.serialLengthRequirementMessage',
                            })
                          )
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <SerialNumberSearch
                  placeholder={intl.formatMessage({
                    id: 'repairProcess.warrantyInformation.serialNumberView.serialNumberSearchPlaceholder',
                  })}
                  onSearch={() => formRef?.current?.submit()}
                  enterButton={intl.formatMessage({
                    id: 'repairProcess.warrantyInformation.serialNumberView.lookup',
                  })}
                  size='large'
                  style={{ maxWidth: '750px' }}
                  data-test-id='serialNumberInput'
                  disabled={parsingSerialNumber}
                  loading={parsingSerialNumber}
                  data-hj-allow
                />
              </Form.Item>
            </Form>
            {serialInvalidMarkup}
            {!parsingSerialNumber && (!serialNumber || serialNumberInvalid) && (
              <Space
                wrap
                direction='horizontal'
                style={{ marginTop: '.5em', marginBottom: '.5em' }}
              >
                <Button
                  data-test-id='serialNumberNotFoundButton'
                  onClick={() => onSerialNumberUnreadableClick()}
                >
                  <FormattedMessage id='repairProcess.warrantyInformation.serialNumberView.serialNumberNotFound' />
                </Button>
                <Button
                  data-test-id='serialNumberUnreadableButton'
                  onClick={() => onSerialNumberUnreadableClick()}
                >
                  <FormattedMessage id='repairProcess.warrantyInformation.serialNumberView.serialNumberUnreadable' />
                </Button>
              </Space>
            )}
            {serialResultsMarkup}
          </FocusedServiceCard>
          {serialNumberInfoModal}
        </Col>
        <Col xs={24} lg={8}>
          {repairItemsListMarkup}
        </Col>
      </Row>
      <Row justify='end'>
        <Space>
          <Button
            data-test-id='repairProcessGeneralCancelButton'
            onClick={() => history.push(`/repairprocess/addanother`)}
          >
            <FormattedMessage id='general.cancel' />
          </Button>
          <Button
            data-test-id='repairProcessGeneralContinueButton'
            type='primary'
            onClick={() => onContinueClick()}
            disabled={!manufactureDate && !serialNumber}
          >
            <FormattedMessage id='general.continue' />
          </Button>
        </Space>
      </Row>
    </Fragment>
  );
};

export default SerialNumber;
