import { Col, Text, Row, Icon, Button } from '@met/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const ServiceCenterMappingLine = ({ serviceCenterMapping, editServiceCenterMappingOnClick }) => {
  return (
    <Row>
      <Col xs='auto' center='xs' middle='xs' className='mb-2 mb-md-0'>
        <Icon type='map-marker' info sm />
      </Col>
      <Col xs={3} left='xs' middle='xs' className='mb-2 mb-md-0'>
        <Text block md>
          {serviceCenterMapping.state}
        </Text>
      </Col>
      <Col left='xs' start='xs' middle='xs' className='mb-2 mb-md-0'>
        <Text block sm>
          {serviceCenterMapping.description}
        </Text>
      </Col>
      <Col xs='auto' middle='xs'>
        <Button data-test-id='adminEditServiceCenterStateMappingButton' primary icon='pencil' onClick={() => editServiceCenterMappingOnClick()}>
          <FormattedMessage id='general.edit' />
        </Button>
      </Col>
    </Row>
  );
};

export default ServiceCenterMappingLine;
