import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

export const LogoutText = styled(Text)`
  color: rgb(23, 162, 184);
  cursor: pointer;

  :hover {
    color: rgb(17, 122, 139);
  }
`;