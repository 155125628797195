import { DELETE_ANNOUNCEMENT, GET_ANNOUNCEMENTS, GET_ACTIVE_ANNOUNCEMENTS, UPSERT_ANNOUNCEMENT } from '../types';
import { error, success } from '../utils';

export let initialState = {
  getAnnouncements: {
    isLoading: false,
    isError: false,
    announcements: [],
  },
  getActiveAnnouncements: {
    isLoading: false,
    isError: false,
    announcements: [],
  },
  upsertAnnouncement: {
    isLoading: false,
    isError: false,
  },
  deleteAnnouncement: {
    isLoading: false,
    isError: false,
  },
};

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_ANNOUNCEMENTS:
      return {
        ...state,
        getAnnouncements: {
          isLoading: true,
          isError: false,
          announcements: [],
        },
      };
    case success(GET_ANNOUNCEMENTS):
      return {
        ...state,
        getAnnouncements: {
          isLoading: false,
          isError: false,
          announcements: action.payload.data,
        },
      };
    case error(GET_ANNOUNCEMENTS):
      return {
        ...state,
        getAnnouncements: {
          isLoading: false,
          isError: true,
          announcements: [],
        },
      };
    case GET_ACTIVE_ANNOUNCEMENTS:
      return {
        ...state,
        getActiveAnnouncements: {
          isLoading: true,
          isError: false,
          announcements: [],
        },
      };
    case success(GET_ACTIVE_ANNOUNCEMENTS):
      return {
        ...state,
        getActiveAnnouncements: {
          isLoading: false,
          isError: false,
          announcements: action.payload.data,
        },
      };
    case error(GET_ACTIVE_ANNOUNCEMENTS):
      return {
        ...state,
        getActiveAnnouncements: {
          isLoading: false,
          isError: true,
          announcements: [],
        },
      };
    case UPSERT_ANNOUNCEMENT:
      return {
        ...state,
        upsertAnnouncement: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPSERT_ANNOUNCEMENT):
      let announcements = state.getAnnouncements.announcements.filter(x => x.id !== action.payload.data.id);
      return {
        ...state,
        upsertAnnouncement: {
          isLoading: false,
          isError: false,
        },
        getAnnouncements: {
          ...state.getAnnouncements,
          announcements: [...announcements, action.payload.data],
        },
      };
    case error(UPSERT_ANNOUNCEMENT):
      return {
        ...state,
        upsertAnnouncement: {
          isLoading: false,
          isError: true,
        },
      };
    case DELETE_ANNOUNCEMENT:
      return {
        ...state,
        deleteAnnouncement: {
          isLoading: true,
          isError: false,
        },
      };
    case success(DELETE_ANNOUNCEMENT):
      return {
        ...state,
        deleteAnnouncement: {
          isLoading: false,
          isError: false,
        },
        getAnnouncements: {
          ...state.getAnnouncements,
          announcements: [
            ...state.getAnnouncements.announcements.filter((v, i) => {
              return v.id !== action.payload.data.Id;
            }),
          ],
        },
      };
    case error(DELETE_ANNOUNCEMENT):
      return {
        ...state,
        deleteAnnouncement: {
          isLoading: false,
          isError: true,
        },
      };
    default:
      return state;
  }
};

export default reducer;
