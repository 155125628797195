import React, { Fragment, useEffect, useState } from 'react';
import LmrOptions from '../../../../../../components/LmrOptions';
import { calculateWarrantyEligibility } from '../../../../../../helpers/calculateWarrantyEligibility';
import { useLocale } from '../../../../../../hooks';
import { useRepairItemsContext } from '../../../RepairItemsContext';
import MoreInformationNeeded from './MoreInformationNeeded';
import ToolNoWarranty from './ToolNoWarranty';
import ToolPendingWarranty from './ToolPendingWarranty';
import ToolUnderWarranty from './ToolUnderWarranty';
import RepairItemsList from '../../../../components/RepairItemsList';
import { Button, Col, Row, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { RepairDetailsButton } from './styled';
import { ServiceCard } from '../../../../../../components';
import { useHistory } from 'react-router-dom';

const WarrantyEvaluation = ({ onBack, onGoToSerialNumber }) => {
  const {
    sku,
    product,
    proofOfPurchase,
    proofOfPurchaseNotIncluded,
    proofOfPurchaseIncluded,
    manufactureDate,
    serialNumberUnreadable,
    serialNumber,
    purchaseDate,
    warrantyEvaluation,
    setWarrantyEvaluation,
  } = useRepairItemsContext();
  const { isCaLocale } = useLocale();
  const history = useHistory();

  useEffect(() => {
    setWarrantyEvaluation(
      calculateWarrantyEligibility(
        manufactureDate,
        purchaseDate,
        product.warrantyPeriod
      )
    );
  }, [manufactureDate, purchaseDate, product]);

  const canadaLmrLines = product && (
    <LmrOptions
      lmrMappings={product.productLmrMappings}
      country='ca'
      style={{ fontWeight: '600', color: '#db011c' }}
    />
  );

  const usLmrLines = product && (
    <LmrOptions
      lmrMappings={product.productLmrMappings}
      country='us'
      style={{ fontWeight: '600', color: '#db011c' }}
    />
  );

  const warrantyLines = isCaLocale() ? canadaLmrLines : usLmrLines;

  const repairItemsListMarkup = (
    <ServiceCard>
      <RepairItemsList />
    </ServiceCard>
  );

  //If warrantyEval comes back true show this
  const underWarranty = warrantyEvaluation && (
    <ToolUnderWarranty
      data-test-id='toolUnderWarranty'
      warrantyLine={warrantyLines}
      onBack={() => onBack()}
    />
  );

  //If warrantyEvaluation is false AND serial number exists AND purchase date doest NOT exist AND POP is included
  //OR warrantyEvaluation is NULL AND serial number is unreadable AND POP is included
  //OR warrantyEvaluation is NULL AND serial number exists AND is a battery
  const pendingWarranty = ((!warrantyEvaluation &&
    serialNumber &&
    !purchaseDate &&
    proofOfPurchaseIncluded) ||
    (warrantyEvaluation === null &&
      serialNumberUnreadable &&
      proofOfPurchaseIncluded) ||
    (warrantyEvaluation === null && serialNumber && product.isBattery)) && (
    <ToolPendingWarranty warrantyLine={warrantyLines} onBack={onBack} />
  );

  //If warrantyEvaluation is false
  //AND serial number AND POP exists
  //OR serial number is unreadable AND POP exists
  //OR serial number AND manufacture date exists AND POP is NOT included
  const outWarranty = !warrantyEvaluation &&
    ((serialNumber && proofOfPurchase) ||
      (serialNumberUnreadable && proofOfPurchase) ||
      (serialNumber && manufactureDate && proofOfPurchaseNotIncluded)) && (
      <ToolNoWarranty warrantyLine={warrantyLines} onBack={onBack} />
    );

  //If warrantyEvalution is NULL
  //AND(serial number is unreadable AND proof of purchase is not included)
  //OR (serial number exists AND NO manufacture date/purchase date exists AND POP NOT included)
  const moreInformation = warrantyEvaluation === null &&
    ((serialNumberUnreadable && proofOfPurchaseNotIncluded) ||
      (serialNumber &&
        !manufactureDate &&
        !purchaseDate &&
        proofOfPurchaseNotIncluded &&
        !product.isBattery)) && (
      <MoreInformationNeeded
        data-test-id='moreInformationNeeded'
        warrantyLine={warrantyLines}
        onBack={onBack}
        onGoToSerialNumber={onGoToSerialNumber}
      />
    );

  return (
    <Fragment>
      <Row gutter={[16, 0]}>
        <Col xs={24} lg={16}>
          {underWarranty}
          {pendingWarranty}
          {outWarranty}
          {moreInformation}
        </Col>
        <Col data-test-id='repairItemsList' xs={24} lg={8}>
          {repairItemsListMarkup}
        </Col>
      </Row>
      <Row justify='end'>
        <Space>
          <Col>
            <Button
              data-test-id='productDetailGeneralBack'
              onClick={() => onBack()}
            >
              <FormattedMessage id='general.back' />
            </Button>
          </Col>
          <Col>
            <RepairDetailsButton
              data-test-id='repairDetailsButton'
              block
              onClick={() => {
                history.push(`/repairprocess/productdetail/${sku}`);
              }}
            >
              <FormattedMessage id='repairProcess.warrantyInformation.enterRepairDetails' />
            </RepairDetailsButton>
          </Col>
        </Space>
      </Row>
    </Fragment>
  );
};

export default WarrantyEvaluation;
