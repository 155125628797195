import { Button, Col, Modal, notification, Row, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { serviceOrdersActions } from '../../../store';

const { Text } = Typography;

const ResubmitBulkServiceOrderModal = ({ datesQuery, handleSearch, hidden, serviceOrders, toggle }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const displayDateFormat = 'MM/DD/YYYY';

  const resubmitLabel = useSelector((state) => state.serviceOrders.resubmitBulkServiceOrderLabel);

  const [momentStartDate, setMomentStartDate] = useState(datesQuery.s);
  const [momentEndDate, setMomentEndDate] = useState(datesQuery.e);

  useEffect(() => {
    setMomentStartDate(moment(datesQuery.s).format(displayDateFormat));
    setMomentEndDate(moment(datesQuery.e).format(displayDateFormat));
  }, [datesQuery]);

  const handleResubmit = () => {
    if (serviceOrders.data.length === 0) {
      noOrdersNotification();
    } else {
      dispatch(serviceOrdersActions.resubmitFailedServiceOrderLabel(datesQuery.s, datesQuery.e)).then((res) => {
        if (!resubmitLabel.isLoading) {
          toggle();
          handleSearch();
          completedNotification();
        }
      });
    }
  };

  const noOrdersNotification = () => {
    notification.error({
      placement: 'bottomRight',
      message: intl.formatMessage({ id: 'admin.serviceOrders.reprocessModal.header' }),
      description: intl.formatMessage({ id: 'admin.serviceOrders.reprocessModal.noOrdersReprocess' }),
    });
  };

  const completedNotification = () => {
    notification.success({
      placement: 'bottomRight',
      message: intl.formatMessage({ id: 'admin.serviceOrders.reprocessModal.header' }),
      description: intl.formatMessage({ id: 'admin.serviceOrders.reprocessModal.completedReprocess' }),
    });
  };

  const bodyContent = (
    <React.Fragment>
      {resubmitLabel.isLoading ? (
        <Row style={{ textAlign: 'center' }}>
          <Col xs={24}>
            <FormattedMessage id='admin.serviceOrders.reprocessModal.reprocessTime' />
          </Col>
          <Col xs={24} style={{ paddingTop: '10px' }}>
            <Spin size='middle' />
          </Col>
        </Row>
      ) : (
        <Text strong>
          <FormattedMessage
            id='admin.serviceOrders.reprocessModal.reprocessAmount'
            values={{
              totalCount: serviceOrders.totalCount,
              dateRangeStart: momentStartDate,
              dateRangeEnd: momentEndDate,
            }}
          />
        </Text>
      )}
    </React.Fragment>
  );
  return (
    <Modal
      data-test-id='productProblemModal'
      title={intl.formatMessage({ id: 'admin.serviceOrders.reprocessModal.header' })}
      open={!hidden}
      onCancel={toggle}
      closable={!resubmitLabel.isLoading}
      maskClosable={!resubmitLabel.isLoading}
      footer={[
        <Button
          data-test-id='productProblemModalCancel'
          key='cancelBtn'
          disabled={resubmitLabel.isLoading}
          onClick={() => toggle()}
          className='mr-2'
        >
          <FormattedMessage id='general.cancel' />
        </Button>,
        <Button
          data-test-id='productProblemModalReprocess'
          key='reprocessBtn'
          type={'primary'}
          disabled={resubmitLabel.isLoading}
          onClick={() => {
            handleResubmit();
          }}
        >
          <FormattedMessage id='admin.serviceOrders.reprocessModal.reprocess' />
        </Button>,
      ]}
      destroyOnClose
    >
      {bodyContent}
    </Modal>
  );
};

export default ResubmitBulkServiceOrderModal;
