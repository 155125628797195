import { Button, Form, Input, Modal } from 'antd';
import React, { Fragment, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CloseOutlined } from '@ant-design/icons';

const MissingAccountNumberModal = ({ showModal, toggleModal, onSaveClick }) => {
  const intl = useIntl();
  const formRef = useRef();

  const handleSubmit = form => {
    onSaveClick(form);
  };

  const modalMarkup = showModal && (
    <Modal
      closeIcon={<CloseOutlined data-test-id='modalCloseButton' />}
      visible={showModal}
      title={intl.formatMessage({ id: 'profile.missingAccountNumberModal.requestAccountNumber' })}
      footer={[
        <Button data-test-id='cancelActivateAccountNumberModal' key={'cancelBtn'} onClick={() => toggleModal()}>
          <FormattedMessage id='general.cancel' />
        </Button>,
        <Button data-test-id='submitActivateAccountNumberModal' key={'saveBtn'} onClick={() => formRef?.current?.submit()} type='primary'>
          <FormattedMessage id='general.submit' />
        </Button>,
      ]}
      width={750}
      onCancel={() => toggleModal()}
      destroyOnClose={true}
    >
      <Form name='accountNumberRequestForm' onFinish={handleSubmit} layout='vertical' ref={formRef}>
        <Form.Item
          name='requestedAccountNumber'
          label={intl.formatMessage({ id: 'profile.accountNumberLabel' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'general.required' }),
            },
            {
              max: 50,
              message: intl.formatMessage({ id: 'profile.missingAccountNumberModal.exceedsCharacterLimit' }),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );

  return <Fragment>{modalMarkup}</Fragment>;
};

export default MissingAccountNumberModal;
