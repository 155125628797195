import { Space, Typography } from 'antd';
import moment from 'moment';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InfoGrid, InfoGridItem } from '../../../../components';
import { useServiceOrder, useShipping } from '../../../../hooks';

const { Title } = Typography;

const OrderInfo = () => {
  const intl = useIntl();
  const { serviceOrder } = useServiceOrder();
  const { getTrackingMarkup } = useShipping();

  const serviceOrderNumberMarkup = serviceOrder && serviceOrder.serviceOrderNumber && (
    <InfoGridItem label={intl.formatMessage({ id: 'repairDetails.orderInfo.serviceOrderNumber' })}>
      {serviceOrder.serviceOrderNumber}
    </InfoGridItem>
  );

  // If status is Submitted -> In Transit
  let inboundTrackingMarkup =
    serviceOrder &&
    serviceOrder.trackingNumber &&
    [1, 2].includes(serviceOrder.status) &&
    getTrackingMarkup(0, serviceOrder.countryCode, serviceOrder.trackingNumber);

  // If status is Shipped -> Delivered
  let returnTrackingMarkup =
    serviceOrder &&
    serviceOrder.serviceOrderShipments &&
    serviceOrder.serviceOrderShipments.length > 0 &&
    [5, 6].includes(serviceOrder.status) &&
    getTrackingMarkup(0, serviceOrder.countryCode, serviceOrder.serviceOrderShipments[0].trackingNumber);

  let trackingMarkup = (inboundTrackingMarkup || returnTrackingMarkup) && (
    <InfoGridItem label={intl.formatMessage({ id: 'repairDetails.orderInfo.trackingInfo' })}>
      <Space direction='vertical' size={2}>
        {inboundTrackingMarkup}
        {returnTrackingMarkup}
      </Space>
    </InfoGridItem>
  );

  const proofOfPurchaseMarkup = serviceOrder.proofOfPurchaseIncluded ? (
    <FormattedMessage id='repairDetails.orderInfo.proofOfPurchaseIncluded' />
  ) : serviceOrder.proofOfPurchaseBlob ? (
    <FormattedMessage id='repairDetails.orderInfo.proofOfPurchaseUploaded' />
  ) : (
    <FormattedMessage id='repairDetails.orderInfo.proofOfPurchaseNotProvided' />
  );

  return (
    <Fragment>
      <Title level={4}>
        <FormattedMessage id='repairDetails.orderInfoTitle' />
      </Title>
      <InfoGrid column={1}>
        <InfoGridItem label={intl.formatMessage({ id: 'general.submitted' })}>
          {moment(serviceOrder.serviceOrderDate)
            .local()
            .format('M/D/YYYY')}
        </InfoGridItem>
        {serviceOrderNumberMarkup}
        <InfoGridItem label={intl.formatMessage({ id: 'repairDetails.orderInfo.proofOfPurchase' })}>
          {proofOfPurchaseMarkup}
        </InfoGridItem>
        <InfoGridItem label={intl.formatMessage({ id: 'general.status' })}>
          <FormattedMessage id={`repairDetails.orderInfo.orderStatus.status${serviceOrder.status}`} />
        </InfoGridItem>
        {trackingMarkup}
      </InfoGrid>
    </Fragment>
  );
};

export default OrderInfo;
