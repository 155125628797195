import { ListGroup, ListGroupItem, Text } from '@met/react-components';
import styled from 'styled-components';

export const StyledImg = styled.img`
  max-width: 100%;
  max-height: 150px;
`;

export const StyledText = styled(Text)`
  margin-bottom: 0.25rem;
`;

export const ActionText = styled(Text)`
  color: #db011c;
  font-weight: bold;
`;

export const SearchResults = styled(ListGroup)`
  margin-top: 0.5rem;
`;

export const SearchResult = styled(ListGroupItem)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const ActionWrapper = styled.div`
  margin-left: 1rem;
  text-align: center;

  @media (max-width: ${props => props.theme.screenSize.md}) {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 0;
  }
`;
