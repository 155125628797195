import { Button, Col, Row, Space, Table, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUser } from '../../hooks';
import { usersActions } from '../../store';
import { DetailsContainer, DefaultButton } from './styled';
import { PageLoaderJr } from '../../components';

const LinkedEmails = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const { userEmails, loadingUserEmails } = useUser();
  const { Text } = Typography;

  useEffect(() => {
    dispatch(usersActions.getUserEmails()).catch(() => {
      toast.error(intl.formatMessage({ id: 'profile.loadingUserEmailsErrorMessage' }));
    });
  }, [dispatch]);

  if (loadingUserEmails) {
    return (
      <PageLoaderJr />
    );
  }

  const columns = [
    {
      title: intl.formatMessage({ id: 'profile.emailAddressHeader' }),
      key: 'userEmail',
      render: record => {
        return record.email;
      },
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: 'profile.isVerifiedHeader' }),
      key: 'isVerified',
      render: record => {
        return record.isVerified ? (
          <Space align='center' style={{ width: '100%', justifyContent: 'center' }}>
            <FontAwesomeIcon icon={faCheck} size='lg' />
          </Space>
        ) : (
          ''
        );
      },
      ellipsis: true,
    },
  ];

  return (
    <DetailsContainer>
      <Row>
        <Col>
          <FormattedMessage id='profile.linkedEmailsIntro' />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={userEmails}
        pagination={false}
        scroll={{ x: 'max-content' }}
        style={{ width: 'max-content', marginTop: '1rem', marginBottom: '0.5rem' }}
      />
      <Row justify={'end'}>
        <Col end='xs'>
          <DefaultButton 
            data-test-id='addNewRecoveryEmailButton'
            type='primary'
            onClick={() => history.push('/recoveremail?r=/preferences?t=linkedEmails')}
          >
            <FormattedMessage id='general.addNew' />
          </DefaultButton>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text italic='true' style={{ fontSize: 'small' }}>
            <FormattedMessage id='profile.linkedEmailsVerifiedFootnote' />
          </Text>
        </Col>
      </Row>
    </DetailsContainer>
  );
};

export default LinkedEmails;
