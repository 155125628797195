import { Image, Modal, Button, Checkbox, Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { ShippingInstructionAcknowledgementForm, ShippingInstructionContainer } from './styled';

const ShippingInstructionModal = ({ hidden, toggle }) => {
  const intl = useIntl();
  const history = useHistory();
  const [form] = Form.useForm();

  const submitAndContinue = () => {
    history.push('/repairprocess/shipping');
  }

  return (
    <Modal 
      title={intl.formatMessage({ id: 'repairProcess.shippingInstructions.title' })}
      open={!hidden} 
      width={900}
      onCancel={toggle}
      footer={[          
        <Button data-test-id='shippingInstructionsModalCancelButton' onClick={toggle}>
          {intl.formatMessage({ id: 'repairProcess.shippingInstructions.cancelButton' })}
        </Button>,
        <Button data-test-id='shippingInstructionsModalContinueButton' onClick={form.submit}>
          {intl.formatMessage({ id: 'repairProcess.shippingInstructions.continueButton' })}
        </Button>
        ]}
      >
        <ShippingInstructionContainer>
          <FormattedMessage id='repairProcess.shippingInstructions.rotaryLaserInstructions' />
          <Image 
            alt='Image of a rotary laser carrying case' 
            style={{marginTop:'1rem'}} 
            src={window.location.origin + '/images/rotary_laser_carrying_case.jpg'} 
          />
          </ShippingInstructionContainer>
          <ShippingInstructionAcknowledgementForm
              name="acknowledge-shipping-instructions"
              form={form}
              onFinish={submitAndContinue}
              >
              <Form.Item
                name='checkbox'
                valuePropName='checked'
                style={{textAlign:'center'}}
                rules={[{
                  required: true, 
                  transform: value => (value || undefined), 
                  type:'boolean', 
                  message: intl.formatMessage({ id: 'repairProcess.shippingInstructions.acknowledgementRequiredMessage'})
                }]}
              >
                <Checkbox>{intl.formatMessage({ id: 'repairProcess.shippingInstructions.acknowledgementCheckboxLabel'})}</Checkbox>
              </Form.Item>
            </ShippingInstructionAcknowledgementForm>
      </Modal>
  );
};

ShippingInstructionModal.propTypes = {
  hidden: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ShippingInstructionModal;
