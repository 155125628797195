import axios from 'axios';
import { auth0 } from '../helpers/auth0';
import { getMetSession, getMetSessionExpiry, setMetSessionExpiry, updateMetSession } from '../helpers/sessionStorage';
import { GET_MET_USER_TOKEN, UPSERT_USER, GET_ACTIVE_ANNOUNCEMENTS, GET_ACTIVE_FEATURES } from './types';
import { error, success } from './utils';

const createAxiosAction = ({
  actionPayload,
  startAction,
  method,
  url,
  body,
  responseType = 'json',
  headers,
}) => async dispatch => {
  dispatch({ ...actionPayload, type: startAction });

  let config = {
    method,
    data: body,
    url,
    responseType,
    headers: {
      ...headers,
    },
  };

  let auth0Token;
  let metSession;
  let metSessionExpiry;

  if (auth0.getAccessTokenSilently && auth0.getAccessTokenSilently()) {
    // Append Auth0 token as bearer token
    auth0Token = await auth0.getAccessTokenSilently()({});

    config.headers = {
      ...config.headers,
      authorization: 'Bearer ' + auth0Token,
    };

    metSessionExpiry = getMetSessionExpiry();

    if (
      startAction !== UPSERT_USER &&
      startAction !== GET_MET_USER_TOKEN &&
      startAction !== GET_ACTIVE_ANNOUNCEMENTS &&
      startAction !== GET_ACTIVE_FEATURES &&
      (!metSessionExpiry.length || metSessionExpiry < Math.floor(Date.now() / 1000))
    ) {
      // Check if MET session is expired and refresh if needed
      let refreshMetSession = axios({
        ...config,
        method: 'GET',
        url: '/api/v1/users/token',
      });

      refreshMetSession.then(refreshedMetSession => {
        metSession = refreshedMetSession.data.data;

        updateMetSession(refreshedMetSession.data.data);
        setMetSessionExpiry(Math.floor(new Date(refreshedMetSession.data.data.expires) / 1000));
      });
    } else {
      metSession = getMetSession();
    }
  }

  // Append MET token as header
  if (metSession) {
    config.headers = {
      ...config.headers,
      'met-user': metSession.token,
    };
  }

  let action = axios(config);

  return action
    .then(payload => {
      dispatch({
        ...actionPayload,
        type: success(startAction),
        payload: payload.data,
      });

      return payload;
    })
    .catch(payload => {
      dispatch({
        ...actionPayload,
        type: error(startAction),
      });

      return Promise.reject(payload);
    });
};

export default createAxiosAction;
