import { Button, Card, Col, Form, Input, Row, Typography } from 'antd';
import React, { Fragment, useState, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { StringParam, useQueryParam } from 'use-query-params';
import { usersActions } from '../../store';
import { OkButton, StyledContainer } from './styled';

const RecoverEmail = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { Text } = Typography;
  const formRef = useRef();

  const [returnUrl, setReturnUrl] = useQueryParam('r', StringParam); // eslint-disable-line no-unused-vars

  const [emailAddress, setEmailAddress] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const isLoading = useSelector(state => state.users.submitEmailRecovery.isLoading);

  const handleEmailAddressChange = emailAddress => {
    setEmailAddress(emailAddress);
  };

  const handleSubmitClick = () => {
    dispatch(usersActions.submitEmailRecovery(emailAddress))
      .then(() => {
        setSubmitted(true);
        toast.success(intl.formatMessage({ id: 'recoverEmail.submissionSuccess' }));
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'recoverEmail.submissionError' }));
      });
  };

  const handleReturnClick = () => {
    history.push(returnUrl ? returnUrl : '/dashboard');
  };

  const submitButtonText = submitted ? (
    <FormattedMessage id='general.submitted' />
  ) : (
    <FormattedMessage id='general.submit' />
  );

  const submitMarkup = !submitted && (
    <Fragment>
      <Row>
        <Col>
          <h3>
            <FormattedMessage id='recoverEmail.introHeading' />
          </h3>
          <Text>
            <FormattedMessage id='recoverEmail.introBody' />
          </Text>
        </Col>
      </Row>
      <Row style={{ display: 'unset' }}>
        <Col style={{ marginTop: '1rem' }}>
          <Form
            layout='vertical'
            ref={formRef}
            onFinish={() => {
              handleSubmitClick();
            }}
          >
            <Form.Item
              label={intl.formatMessage({ id: 'recoverEmail.emailAddressLabel' })}
              name='email'
              rules={[
                { 
                  required: true,
                  message: intl.formatMessage({ id: 'recoverEmail.invalidEmailMessage' }),
                },
                {
                  type: 'email',
                  message: intl.formatMessage({ id: 'recoverEmail.invalidEmailMessage' }),
                },
              ]}
            >
              <Input
                data-test-id='recoveryEmailTextInput'
                placeholder='your.name@email.com'
                onChange={e => handleEmailAddressChange(e.target.value)}
                style={{ width: 'max-content' }}
              />
            </Form.Item>
            <Form.Item>
              <Row justify={'end'}>
                <Col>
                  <OkButton
                    data-test-id='recoveryEmailSubmitButton'
                    type='primary'
                    onClick={() => formRef?.current?.submit()}
                    loading={isLoading}
                    disabled={submitted}
                  >
                    {submitButtonText}
                  </OkButton>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );

  const submittedMarkup = submitted && (
    <Fragment>
      <Row>
        <Col>
          <h3>
            <FormattedMessage id='recoverEmail.introHeadingSubmitted' />
          </h3>
          <Text>
            <FormattedMessage id='recoverEmail.introBodySubmitted' />
          </Text>
        </Col>
      </Row>
      <Row justify={'end'}>
        <Col end='xs'>
          <OkButton data-test-id='recoveryEmailSubmitOkButton' type='primary' onClick={() => handleReturnClick()}>
            <FormattedMessage id='general.ok' />
          </OkButton>
        </Col>
      </Row>
    </Fragment>
  );

  return (
    <Fragment>
      <h1>Email Recovery</h1>
      <StyledContainer>
        {submitMarkup}
        {submittedMarkup}
      </StyledContainer>
    </Fragment>
  );
};

export default RecoverEmail;
