import {Alert, Col, Row} from 'antd';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Content = styled.div`
  min-height: calc(100vh - 84px);
`;

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100%;
  animation: ${fadeIn} 300ms ease-in-out;
  transition: transform 0.3s;
  flex: 1 0 auto;

  * {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  }
`;

export const InnerContent = styled.div`
  max-width: 100%;
  flex: 1 0 auto;
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  overflow: hidden;
`;

export const PageLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const BannerAlert = styled(Alert)`
  padding: 1.5rem;
  margin: 0;
`;

export const FooterMenu = styled.div`
  color: white;
  width: 100%;
  background-color: #222;
  font-size: 14px;
  line-height: 14px;
`;

export const FooterExternalLink = styled.a`
  color: white;
  &:hover {
    text-decoration: underline;
    color: white;
  }
`;

export const LinkDivider = styled.span`
  margin-left: 3px;
  margin-right: 3px;
`;

export const FooterImage = styled.img`
  max-height: 1rem;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledRow = styled(Row)`
  margin: 32px auto 32px auto;
  font-size: 1rem !important;
`;

export const CenteredFooterCol = styled(Col)`
  @media screen and (max-width: 973px) {
    text-align: center !important;
    padding-bottom: 15px !important;
  }
`;
