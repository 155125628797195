import { useToggle } from '../../hooks';
import { Button, Typography } from 'antd';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { IneligibleToolModal } from '../IneligibleToolModal';
import { useLocale } from '../../hooks';

const { Title } = Typography;

const MoreInformation = ({ handleDownloadTermsConditions }) => {
  const [showIneligibleToolModal, toggleIneligibleToolModal] = useToggle(false);

  const { isCaLocale } = useLocale();

  const isCa = isCaLocale();

  const additionalBodyMarkup = isCa ? (
    <FormattedMessage id={'moreInformation.additionalBodyCa'} />
  ) : (
    <FormattedMessage id={'moreInformation.additionalBody'} />
  );

  return (
    <Fragment>
      <Title level={4}>
        <FormattedMessage id={'moreInformation.moreInformationTitle'} />
      </Title>
      <ul>
        <li>
          <Button data-test-id='viewTermsAndConditionsButton' type='link' onClick={() => handleDownloadTermsConditions()} style={{ padding: '0', margin: '0' }}>
            <FormattedMessage id={'moreInformation.viewTermsAndConditions'} />
          </Button>
        </li>
        <li>
          <Button data-test-id='viewIneligibleToolsButton' type='link' onClick={() => toggleIneligibleToolModal()} style={{ padding: '0', margin: '0' }}>
            <FormattedMessage id={'moreInformation.viewIneligible'} />
          </Button>
        </li>
      </ul>
      <p>{additionalBodyMarkup}</p>
      <IneligibleToolModal hidden={showIneligibleToolModal} toggle={toggleIneligibleToolModal} />
    </Fragment>
  );
};

export default MoreInformation;
