import { Modal, ModalContent, ModalHeader } from '@met/react-components';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ImageWrapper, StyledModalBody } from './styled';

const WhatsNewModal = ({ hidden, toggle }) => {
  const intl = useIntl();

  let settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Modal lg centered hidden={hidden} toggle={toggle}>
      <ModalContent data-test-id='whats-new-modal'>
        <ModalHeader toggle={toggle}>
          <FormattedMessage id='home.whatsNewModal.title' />
        </ModalHeader>
        <StyledModalBody>
          <Slider {...settings}>
            <div>
              <h4>
                <FormattedMessage id='home.whatsNewModal.newSubmission.title' />
              </h4>
              <p>
                <FormattedMessage id='home.whatsNewModal.newSubmission.body' />
              </p>
              <ImageWrapper>
                <img
                  src={window.location.origin + '/images/whats_new_1.jpg'}
                  alt={intl.formatMessage({ id: 'home.whatsNewModal.newSubmission.alt' })}
                  className='hidden-xs visible-md'
                />
                <img
                  src={window.location.origin + '/images/mobile_whats_new_1.png'}
                  alt={intl.formatMessage({ id: 'home.whatsNewModal.newSubmission.alt' })}
                  className='hidden-md'
                />
              </ImageWrapper>
            </div>
            <div>
              <h4>
                <FormattedMessage id='home.whatsNewModal.statusUpdates.title' />
              </h4>
              <p>
                <FormattedMessage id='home.whatsNewModal.statusUpdates.body' />
              </p>
              <ImageWrapper>
                <img
                  src={window.location.origin + '/images/whats_new_2.jpg'}
                  alt={intl.formatMessage({ id: 'home.whatsNewModal.statusUpdates.alt' })}
                  className='hidden-xs visible-md'
                />
                <img
                  src={window.location.origin + '/images/mobile_whats_new_2.png'}
                  alt={intl.formatMessage({ id: 'home.whatsNewModal.statusUpdates.alt' })}
                  className='hidden-md'
                />
              </ImageWrapper>
            </div>
            <div>
              <h4>
                <FormattedMessage id='home.whatsNewModal.dashboard.title' />
              </h4>
              <p>
                <FormattedMessage id='home.whatsNewModal.dashboard.body' />
              </p>
              <ImageWrapper>
                <img
                  src={window.location.origin + '/images/whats_new_3.jpg'}
                  alt={intl.formatMessage({ id: 'home.whatsNewModal.dashboard.alt' })}
                  className='hidden-xs visible-md'
                />
                <img
                  src={window.location.origin + '/images/mobile_whats_new_3.png'}
                  alt={intl.formatMessage({ id: 'home.whatsNewModal.dashboard.alt' })}
                  className='hidden-md'
                />
              </ImageWrapper>
            </div>
          </Slider>
        </StyledModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WhatsNewModal;
