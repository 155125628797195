import Enumerable from 'linq';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { useUser, useFeatureToggles } from '../../../hooks';
import useAddresses from '../../../hooks/useAddresses';
import { stripNonNumeric } from '../../../helpers/formatter';

const ShippingContext = createContext({
  packagesCount: 1,
  setPackagesCount: () => {},
  packagesCountInvalid: false,
  setPackagesCountInvalid: () => {},
  shippingAddress: {},
  setShippingAddress: () => {},
  shippingAddressInvalid: false,
  setShippingAddressInvalid: () => {},
  validate: () => {},
  getShippingInfo: () => {},
  setShippingInfo: () => {},
  batteryRestrictedStates: [],
  shippingAddressIsRestricted: false,
  accountInfoInvalid: false,
  setAccountInfoInvalid: () => {},
});

const useShippingContext = () => useContext(ShippingContext);

const ShippingProvider = ({ children }) => {
  const { batteryRecycle } = useFeatureToggles();
  const { email, lastName, firstName, phoneNumber, preferredContactMethod, userMetadataIncomplete } = useUser();
  const { states } = useAddresses();

  const [packagesCount, setPackagesCount] = useState(1);
  const [packagesCountInvalid, setPackagesCountInvalid] = useState(false);
  const [shippingAddress, setShippingAddress] = useState({});
  const [shippingAddressInvalid, setShippingAddressInvalid] = useState(false);
  const [shippingAddressIsRestricted, setShippingAddressIsRestricted] = useState(false);
  const [batteryRestrictedStates, setBatteryRestrictedStates] = useState(null);
  const [accountInfoInvalid, setAccountInfoInvalid] = useState(false);

  useEffect(() => {
    if (states && states.length > 0) {
      let restrictedStates = Enumerable.from(states)
        .where(x => x.hasBatteryRestrictions === true)
        .select(x => x.abbreviation)
        .toArray();

      setBatteryRestrictedStates(restrictedStates);
    }
  }, [states]);

  useEffect(() => {
    if (shippingAddress && batteryRestrictedStates) {
      if (batteryRestrictedStates.includes(shippingAddress.state)) {
        setShippingAddressIsRestricted(true);
      } else {
        setShippingAddressIsRestricted(false);
      }
    }
  }, [batteryRestrictedStates, shippingAddress]);

  useEffect(() => {
    if (shippingAddress && shippingAddress.addressLine1) {
      setShippingAddressInvalid(false);
    }
  }, [shippingAddress]);

  useEffect(() => {
    if (firstName && lastName && phoneNumber) {
      setAccountInfoInvalid(false);
    }
  }, [firstName, lastName, phoneNumber]);

  const validate = batteryIncluded => {
    let isValid = true;

    if (packagesCount === 0) {
      if (!batteryRecycle) {
        setPackagesCountInvalid(true);
        isValid = false;
      }
    }
    if (!shippingAddress.addressLine1) {
      setShippingAddressInvalid(true);
      isValid = false;
    }
    if (batteryIncluded && shippingAddressIsRestricted) {
      setShippingAddressInvalid(true);
      isValid = false;
    }
    if (userMetadataIncomplete()) {
      setAccountInfoInvalid(true);
      isValid = false;
    }

    return isValid;
  };

  const getShippingInfo = () => {
    return {
      email,
      lastName,
      firstName,
      phoneNumber: stripNonNumeric(phoneNumber),
      packagesCount,
      shippingAddress,
      preferredContactMethod,
    };
  };

  const setShippingInfo = shipping => {
    setPackagesCount(shipping.packagesCount);
    setShippingAddress(shipping.shippingAddress);
  };

  return (
    <ShippingContext.Provider
      value={{
        packagesCount,
        setPackagesCount,
        packagesCountInvalid,
        setPackagesCountInvalid,
        shippingAddress,
        setShippingAddress,
        shippingAddressInvalid,
        setShippingAddressInvalid,
        validate,
        getShippingInfo,
        setShippingInfo,
        batteryRestrictedStates,
        shippingAddressIsRestricted,
        accountInfoInvalid,
        setAccountInfoInvalid,
      }}
    >
      {children}
    </ShippingContext.Provider>
  );
};

export { ShippingContext, useShippingContext, ShippingProvider };
