import { REGISTER_TRACKING_NUMBER_HUB, GET_SIGNALR_ENDPOINT } from '../types';

import { error, success } from '../utils';

export let initialState = {
  signalRConnection: {
    trackingNumberConnection: {
      isRegistered: false,
      isRegistering: false,
      isError: false,
    },
  },
  signalRConfig: {
    isConfigured: false,
    endpoint: '',
    isError: false,
  },
};

const reducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case REGISTER_TRACKING_NUMBER_HUB:
      return {
        ...state,
        signalRConnection: {
          trackingNumberConnection: {
            isRegistered: false,
            isRegistering: true,
            isError: false,
          },
        },
      };
    case success(REGISTER_TRACKING_NUMBER_HUB):
      return {
        ...state,
        signalRConnection: {
          trackingNumberConnection: {
            isRegistered: true,
            isRegistering: false,
            isError: false,
          },
        },
      };
    case error(REGISTER_TRACKING_NUMBER_HUB):
      return {
        ...state,
        signalRConnection: {
          trackingNumberConnection: {
            isRegistered: false,
            isRegistering: false,
            isError: true,
          },
        },
      };
    case GET_SIGNALR_ENDPOINT:
      return {
        ...state,
        signalRConfig: {
          isConfigured: false,
          endpoint: '',
          isError: false,
        },
      };
    case success(GET_SIGNALR_ENDPOINT):
      return {
        ...state,
        signalRConfig: {
          isConfigured: true,
          endpoint: action.payload.data,
          isError: false,
        },
      };
    case error(GET_SIGNALR_ENDPOINT):
      return {
        ...state,
        signalRConfig: {
          isConfigured: false,
          endpoint: '',
          isError: true,
        },
      };
    default:
      return state;
  }
};

export default reducer;
