import {
  GET_DEFAULT_SERVICE_CENTER_MAPPINGS,
  GET_SERVICE_CENTER_MAPPINGS,
  GET_SERVICE_CENTERS,
  UPSERT_SERVICE_CENTER,
} from '../types';
import { success, error } from '../utils';

export let initialState = {
  getServiceCenters: {
    isLoading: false,
    isError: false,
    serviceCenters: [],
  },
  upsertServiceCenter: {
    isLoading: false,
    isError: false,
  },
  getServiceCenterMappings: {
    isLoading: false,
    isError: false,
    serviceCenterMappings: [],
  },
  getDefaultServiceCenterMappings: {
    isLoading: false,
    isError: false,
    defaultServiceCenterMappings: [],
  },
};

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_SERVICE_CENTERS:
      return {
        ...state,
        getServiceCenters: {
          isLoading: true,
          isError: false,
          serviceCenters: [],
        },
      };
    case success(GET_SERVICE_CENTERS):
      return {
        ...state,
        getServiceCenters: {
          isLoading: false,
          isError: false,
          serviceCenters: action.payload.data,
        },
      };
    case error(GET_SERVICE_CENTERS):
      return {
        ...state,
        getServiceCenters: {
          isLoading: false,
          isError: true,
          serviceCenters: [],
        },
      };
    case UPSERT_SERVICE_CENTER:
      return {
        ...state,
        upsertServiceCenter: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPSERT_SERVICE_CENTER):
      let serviceCenters = state.getServiceCenters.serviceCenters.filter(x => x.id !== action.payload.data.id);
      return {
        ...state,
        upsertServiceCenter: {
          isLoading: false,
          isError: false,
        },
        getServiceCenters: {
          ...state.getServiceCenters,
          serviceCenters: [...serviceCenters, action.payload.data],
        },
      };
    case error(UPSERT_SERVICE_CENTER):
      return {
        ...state,
        upsertServiceCenter: {
          isLoading: false,
          isError: true,
        },
      };
    case success(GET_SERVICE_CENTER_MAPPINGS):
      return {
        ...state,
        getServiceCenterMappings: {
          isLoading: false,
          isError: false,
          serviceCenterMappings: action.payload.data,
        },
      };
    case error(GET_SERVICE_CENTER_MAPPINGS):
      return {
        ...state,
        getServiceCenterMappings: {
          isLoading: false,
          isError: true,
          serviceCenterMappings: [],
        },
      };
    case GET_DEFAULT_SERVICE_CENTER_MAPPINGS:
      return {
        ...state,
        getDefaultServiceCenterMappings: {
          isLoading: true,
          isError: false,
          defaultServiceCenterMappings: [],
        },
      };
    case success(GET_DEFAULT_SERVICE_CENTER_MAPPINGS):
      return {
        ...state,
        getDefaultServiceCenterMappings: {
          isLoading: true,
          isError: false,
          defaultServiceCenterMappings: action.payload.data,
        },
      };
    case error(GET_DEFAULT_SERVICE_CENTER_MAPPINGS):
      return {
        ...state,
        getDefaultServiceCenterMappings: {
          isLoading: false,
          isError: true,
          defaultServiceCenterMappings: [],
        },
      };
    default:
      return state;
  }
};

export default reducer;
