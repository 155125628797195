import { Col, Form, Modal, Row, Typography} from 'antd';
import Enumerable from 'linq';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import {
  SelectTitle,
  UserModalInput,
  UserModalSelect,
  UserModalDataFormItem, 
  SubmitButton, 
  CancelButton,
  DisabledInput,
} from './styled'
import { stripNonNumeric } from '../../helpers/formatter';
import { MaskedInput } from '../../helpers/maskedInput'
import { useUser } from '../../hooks';
import { usersActions, localesActions } from '../../store';

const { Text } = Typography;

const UserMetadataModal = ({ hidden, toggle }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    email,
    accountNumber,
    firstName,
    lastName,
    phoneNumber,
    updatingUserMetadata,
    userMetadataIncomplete,
    preferredContactMethod,
    preferredLanguage,
  } = useUser();

  const [form] = Form.useForm();

  const [firstNameVal, setFirstNameVal] = useState(firstName);
  const [lastNameVal, setLastNameVal] = useState(lastName);
  const [phoneNumberVal, setPhoneNumberVal] = useState(phoneNumber);
  const [preferredContactMethodOptions, setPreferredContactMethodOptions] = useState([]);
  const [preferredLanguageOptions, setPreferredLanguageOptions] = useState([]);
  const [selectedPreferredContactMethod, setSelectedPreferredContactMethod] = useState(null);
  const [selectedPreferredLanguage, setselectedPreferredLanguage] = useState(null);

  useEffect(() => {
    setPreferredContactMethodOptions(createPreferredContactMethodOptions());
    setPreferredLanguageOptions(createPreferredLanguageOptions());
  }, []);

  useEffect(() => {
    if (preferredContactMethodOptions.length > 0) {
      let option = Enumerable.from(preferredContactMethodOptions).firstOrDefault(
        x => x.value === preferredContactMethod
      );
      if (option) {
        setSelectedPreferredContactMethod(option.value);
      }
    }
  }, [preferredContactMethod, preferredContactMethodOptions]);

  useEffect(() => {
    if (preferredLanguageOptions.length > 0) {
      let option = Enumerable.from(preferredLanguageOptions).firstOrDefault(x => x.value === preferredLanguage);
      if (option) {
        setselectedPreferredLanguage(option?.value);
      } else {
        setselectedPreferredLanguage(preferredLanguageOptions[0].value)
      }
    }
  }, [preferredLanguage, preferredLanguageOptions]);

  const handleSaveChanges = () => {
    let preferredContactMethodVal = selectedPreferredContactMethod;
    let preferredLanguageVal = selectedPreferredLanguage;
    dispatch(
      usersActions.updateUserPreferences(
        firstNameVal,
        lastNameVal,
        phoneNumberVal,
        preferredContactMethodVal,
        preferredLanguageVal
      )
    )
      .then(() => {
        toggle();
        if (preferredLanguageVal) {
          dispatch(localesActions.updateLocale(preferredLanguageVal));
        }
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'profile.profileSaveError' }), { autoClose: false });
      });
  };

  const createPreferredContactMethodOptions = () => {
    return [
      {
        label: intl.formatMessage({ id: 'profile.preferredContactMethods.label1' }),
        value: 1,
      },
      {
        label: intl.formatMessage({ id: 'profile.preferredContactMethods.label2' }),
        value: 2,
      },
    ];
  };

  const createPreferredLanguageOptions = () => {
    return [
      {
        label: intl.formatMessage({ id: 'profile.preferredLanguage.english' }),
        value: 'en-US',
      },
      {
        label: intl.formatMessage({ id: 'profile.preferredLanguage.french' }),
        value: 'fr-CA',
      },
    ];
  };

  const incompleteMetadataMarkup = userMetadataIncomplete() && (
    <Row style={{ marginBottom: '1rem' }}>
      <Col>
        <Text style={{ color: '#db011c' }}>
          <FormattedMessage id='userMetadataModal.incomplete' />
        </Text>
      </Col>
    </Row>
  );

  return (
    <Modal
      open={!hidden}
      width={800}
      centered
      title={intl.formatMessage({ id: 'userMetadataModal.title' })}
      bodyStyle={{ padding: '16px' }}
      closeIcon={<FontAwesomeIcon icon={faXmark} style={{ color: '#808080', strokeWidth: '30', stroke: '#808080' }} />}

      footer={[
        <CancelButton 
          data-test-id='userMetaDataModalCancelButton' 
          onClick={() => {
            toggle();
            form.resetFields();
          }}   
        className='mr-2'>
          <FormattedMessage id='general.cancel' />
        </CancelButton>,
        <SubmitButton data-test-id='userMetaDataModalSaveButton' onClick={() => form.submit()} loading={updatingUserMetadata}>
          <FormattedMessage id='general.saveChanges' />
        </SubmitButton>
      ]}
    >
      {incompleteMetadataMarkup}
      <Form
        name='userDataForm'
        form={form}
        initialValues={{
          firstName: firstName,
          lastName: lastName,
          email: email,
          accountNumber: accountNumber,
          phoneNumber: phoneNumber,
        }}
        onFinish={() => handleSaveChanges()}
        layout='vertical'
      >
        <Row>
          <Col span={12} style={{ paddingRight: '0.5rem' }}>
            <UserModalDataFormItem
              data-test-id='userMetaDataModalFirstNameLabel'
              name='firstName'
              label={intl.formatMessage({ id: 'userMetadataModal.firstNameLabel' })}
              onChange={e => setFirstNameVal(e.target.value)}
              rules={[
                {
                  required: 'true',
                  message: intl.formatMessage({ id: 'userMetadataModal.firstNameInvalid' }),
                  
                },
              ]}
            >
              <UserModalInput />
            </UserModalDataFormItem>
          </Col>
          <Col span={12} style={{ paddingLeft: '0.5rem' }}>
            <UserModalDataFormItem
              data-test-id='userMetaDataModalLastNameLabel'
              name='lastName'
              label={intl.formatMessage({ id: 'userMetadataModal.lastNameLabel' })}
              onChange={e => setLastNameVal(e.target.value)}
              rules={[
                {
                  required: 'true',
                  message: intl.formatMessage({ id: 'userMetadataModal.lastNameInvalid' }),
                },
              ]}
            >
              <UserModalInput />
            </UserModalDataFormItem>
          </Col>
        </Row>
        <Row>
          {email && (
            <Col sm={12} xs={accountNumber ? 12 : 24} style={{ marginTop: '0.85rem', paddingRight: '0.5rem' }}>
              <UserModalDataFormItem
                name='email'
                label={intl.formatMessage({ id: 'userMetadataModal.emailAddressLabel' })}
              >
                <DisabledInput disabled={true} />
              </UserModalDataFormItem>
            </Col>
          )}
          {accountNumber && (
            <Col sm={12} xs={12} style={{ marginTop: '0.85rem', paddingLeft: '0.5rem' }}>
              <UserModalDataFormItem
                name='accountNumber'
                label={intl.formatMessage({ id: 'userMetadataModal.accountNumberLabel' })}
              >
                <DisabledInput disabled={true} />
              </UserModalDataFormItem>
            </Col>
          )}
        </Row>
        <Row>
          <Col sm={8} xs={24} style={{ marginTop: '0.85rem', paddingRight: '0.5rem' }}>
            <UserModalDataFormItem
              data-test-id='userMetaDataModalPhoneNumberLabel'
              name='phoneNumber'
              label={intl.formatMessage({ id: 'userMetadataModal.phoneNumberLabel' })}
              onChange={e => setPhoneNumberVal(stripNonNumeric(e.target.value))}
              rules={[
                {
                  required: 'true',
                  pattern: new RegExp(/^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/),
                  message: intl.formatMessage({ id: 'userMetadataModal.phoneNumberInvalid' }),
                }
              ]}
            >
              <MaskedInput
                mask='(999) 999-9999'
                placeholder='(___) ___-____'
              />
            </UserModalDataFormItem>
          </Col>
        </Row>
        <Row>
          <Col sm={8} xs={24} style={{ marginTop: '0.85rem', paddingRight: '0.5rem' }}>
            <SelectTitle level={4}>
              {intl.formatMessage({ id: 'userMetadataModal.preferredContactMethodLabel' })}
            </SelectTitle>
            <UserModalSelect
              data-test-id='userMetaDataModalPreferredContactDropdown'  
              name='preferredContactMethod'
              options={preferredContactMethodOptions}
              defaultValue={preferredContactMethod}
              onChange={obj => setSelectedPreferredContactMethod(obj)}
              size='large'
            />
          </Col>
        </Row>
        <Row>
          <Col sm={8} xs={24} style={{ marginTop: '0.85rem', paddingRight: '0.5rem' }}>
            <SelectTitle level={4}>
              {intl.formatMessage({ id: 'userMetadataModal.preferredLanguageLabel' })}
            </SelectTitle>
            <UserModalSelect
              data-test-id='userMetaDataModalPreferredLanguageDropdown'
              name='preferredLanguage'
              options={preferredLanguageOptions}
              defaultValue={preferredLanguage || 'en-US'}
              onChange={obj => setselectedPreferredLanguage(obj)}
              placeholder={intl.formatMessage({ id: 'general.pleaseSelect' })}
              size='large'
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

UserMetadataModal.propTypes = {
  hidden: PropTypes.bool,
  toggle: PropTypes.func,
};

export default UserMetadataModal;
