import { FileUploadInput, PlaceholderContent, Row, Text } from '@met/react-components';
import styled from 'styled-components';

export const PlaceholderGapRow = styled(Row)`
  padding-top: 1.5rem;
`;

export const PlaceholderSquareContent = styled(PlaceholderContent)`
  height: 250px;

  @media (max-width: ${props => props.theme.screenSize.md}) {
    height: 125px;
  }
`;

export const SerialNumberCheckboxWrapper = styled.div`
  margin-top: 2.5rem;

  @media (max-width: ${props => props.theme.screenSize.md}) {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
`;

export const OtherInputWrapper = styled.div`
  max-width: 350px;
  margin-top: 0.5rem;
`;

export const BatteryInputWrapper = styled.div`
  max-width: 350px;
  margin-top: 0.5rem;
`;

export const ToolInfoImageWrapper = styled.div`
  height: 100%;
`;

export const ToolInfoImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledFileUploadInput = styled(FileUploadInput)`
  margin-bottom: 0;
`;

export const CheckboxWrapper = styled.div`
  margin-top: 0.5rem;
`;

export const UploadWrapper = styled.div`
  margin-top: 0.5rem;
`;

export const UploadErrorText = styled(Text)`
  margin-top: 0.25rem;
`;

export const NotIncludedText = styled(Text)`
  margin-top: 0.5rem;
`;

export const SerialNumberImg = styled.img`
  max-width: 300px;
`;

export const DamagedBatteryimg = styled.img`
  max-height: 200px;
  border: 1px solid gray;
`;

export const Battery9Img = styled.img`
  max-width: 325px;
  border: 1px solid gray;
`;

export const StyledFileName = styled.div`
  margin-top: 0.5rem;
  font-size: 0.9rem;
  font-weight: 300;
`;

export const ErrorMessage = styled.span`
  margin-top: 0.1rem;
  display: block;
  color: #db011c;
  font-size: 0.9rem;
`;
