import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import * as Locales from './Locales';
import * as Users from './Users';
import * as Notifications from './Notifications';
import * as ServiceOrders from './ServiceOrders';
import * as Products from './Products';
import * as Batteries from './Batteries';
import * as Countries from './Countries';
import * as States from './States';
import * as ProofOfPurchase from './ProofOfPurchase';
import * as ServiceCenters from './ServiceCenters';
import * as ProductProblems from './ProductProblems';
import * as ProductIncluded from './ProductIncluded';
import * as Announcements from './Announcements';
import * as Configuration from './Configuration';
import * as SignalR from './SignalR';
import * as Recycling from './Recycling';
import * as LmrCategoryTypes from './LmrCategoryTypes';
import * as SerialNumber from './SerialNumber';
import * as Reports from './Reports';
import * as GenesysChatWidget from './GenesysChatWidget';
import * as IntlSync from './IntlSync';
import * as Square from './Square';

export default function configureStore(history, initialState) {
  const reducers = {
    locales: Locales.reducer,
    users: Users.reducer,
    notifications: Notifications.reducer,
    serviceOrders: ServiceOrders.reducer,
    products: Products.reducer,
    batteries: Batteries.reducer,
    countries: Countries.reducer,
    states: States.reducer,
    userMetadata: Users.userMetadataReducer,
    proofOfPurchase: ProofOfPurchase.reducer,
    serviceCenters: ServiceCenters.reducer,
    productProblems: ProductProblems.reducer,
    productIncluded: ProductIncluded.reducer,
    announcements: Announcements.reducer,
    configuration: Configuration.reducer,
    signalR: SignalR.reducer,
    recycling: Recycling.reducer,
    lmrCategoryTypes: LmrCategoryTypes.reducer,
    serialNumber: SerialNumber.reducer,
    reports: Reports.reducer,
    chatSchedule: GenesysChatWidget.reducer,
    intlSync: IntlSync.reducer,
    square: Square.reducer,
  };

  const middleware = [thunk, routerMiddleware(history)];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
  });

  return createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));
}
