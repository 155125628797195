import {
  GET_LMR_CATEGORY_TYPES,
  GET_PRODUCT_INCLUDED,
  REFRESH_PRODUCT_INCLUDED,
  UPSERT_PRODUCT_INCLUDED,
} from '../types';

import { error, success } from '../utils';

export let initialState = {
  getLmrCategoryTypes: {
    isLoading: false,
    isError: false,
    lmrCategoryTypes: [],
  },
};

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_LMR_CATEGORY_TYPES:
      return {
        ...state,
        getLmrCategoryTypes: {
          isLoading: true,
          isError: false,
          lmrCategoryTypes: [],
        },
      };
    case success(GET_LMR_CATEGORY_TYPES):
      return {
        ...state,
        getLmrCategoryTypes: {
          isLoading: false,
          isError: false,
          lmrCategoryTypes: action.payload.data || [],
        },
      };
    case error(GET_LMR_CATEGORY_TYPES):
      return {
        ...state,
        getLmrCategoryTypes: {
          isLoading: false,
          isError: true,
          lmrCategoryTypes: [],
        },
      };
    default:
      return state;
  }
};

export default reducer;
