import { Alert, Button, notification, Space } from 'antd';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { downloadBlob } from '../../../../helpers/downloadBlob';
import { useContact, useServiceOrders, useShipping } from '../../../../hooks';

const OrderStatus = ({ serviceOrder, shippoTrackingFailure }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { generatingPaperwork, getServiceOrdersPdf } = useServiceOrders();
  const { getPhoneLink, getEmailLink } = useContact();
  const { getProviderLocationMarkup } = useShipping();

  const handlePrintPaperworkClick = () => {
    notification.success({
      message: intl.formatMessage({ id: 'orderDetails.pdfDownloading' }),
      description: intl.formatMessage({ id: 'orderDetails.pdfDownloadingDetail' }),
    });

    dispatch(getServiceOrdersPdf(serviceOrder.serviceOrderGroupId))
      .then(response => {
        downloadBlob(`${serviceOrder.serviceOrderGroupId}.pdf`, response.data);
      })
      .catch(() => {
        notification.error({
          message: intl.formatMessage({ id: 'orderDetails.pdfDownloadError' }),
          description: intl.formatMessage({ id: 'orderDetails.pdfDownloadErrorDetail' }),
        });
      })
      .finally(() => {
        notification.destroy();
      });
  };

  const messageType = shippoTrackingFailure ? 'error' : 'info';

  let message;
  if (shippoTrackingFailure) {
    message = <FormattedMessage id='orderDetails.trackingInfoError' />;
  } else if (serviceOrder.trackingNumber) {
    message = <FormattedMessage id='general.processed' />;
  } else {
    message = <FormattedMessage id='general.processing' />;
  }

  let description;
  if (shippoTrackingFailure) {
    description = <FormattedMessage id='orderDetails.trackingInfoErrorDetail' />;
  } else if (serviceOrder.trackingNumber) {
    description = <FormattedMessage id='orderSummary.repairItemsProcessed' />;
  } else {
    description = <FormattedMessage id='orderSummary.repairItemsProcessing' />;
  }

  let actions;
  if (shippoTrackingFailure) {
    actions = (
      <Space size='large' wrap>
        {getPhoneLink()}
        {getEmailLink()}
      </Space>
    );
  } else if (serviceOrder.trackingNumber) {
    actions = (
      <Space size='large' wrap>
        <Button data-test-id='orderConfirmationPrintPaperwork' type='primary' onClick={() => handlePrintPaperworkClick()} loading={generatingPaperwork}>
          <FormattedMessage id='orderDetails.printPaperwork' />
        </Button>
        {getProviderLocationMarkup(serviceOrder.countryCode)}
      </Space>
    );
  }

  const descriptionMarkup = (
    <Space direction='vertical' size='middle'>
      {description}
      {actions}
    </Space>
  );

  return <Alert type={messageType} message={message} description={descriptionMarkup} />;
};

export default OrderStatus;
