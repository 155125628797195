import React from 'react';
import { Card, Divider, Typography } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

const UsShippingRestrictionWarning = () => {
  const { Text } = Typography;
  const { Meta } = Card;

  const cardTitle = (
    <Meta
      data-test-id='usShippingWarningTitle'
      avatar={<WarningOutlined />}
      title={<FormattedMessage id='repairProcess.caRestrictionTitle' />}
    />
  );

  return (
    <Card>
      {cardTitle}
      <Divider />
      <Text data-test-id='usShippingWarningBody'>
        <FormattedMessage id='repairProcess.usRestrictionText' />
      </Text>
    </Card>
  );
};

export default UsShippingRestrictionWarning;
