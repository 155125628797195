import { useEffect, useRef, useState } from 'react';

let cachedScripts = [];
const maxRetryCount = 3;
const containerWhitelist = ['body', 'head'];
const defaults = { async: true, container: 'body' };

/**
 * custom hook: useDynamicScript
 *
 * Dynamically load a script and track if/when it is loaded successfully or errored.
 * Configure options controlling how script is loaded ({ src: string, async: boolean, container: string })
 * Has retry logic with exponential back-off (similar to how polly in c# handles errors) - retry count defined by maxRetryCount
 *
 * @param {Object} options - {src, async, container}
 * @returns {[Boolean, Boolean]} - [loaded, error]
 */
const useDynamicScript = ({ src, async, container, clearCacheOnSrcChange, loadAutomatically, debug }) => {
  const [retryCount, setRetryCount] = useState(0);
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });
  const [loadScript, setLoadScript] = useState(loadAutomatically);
  const previousSrc = useRef(src);
  const scriptRef = useRef(undefined);

  const debugMessage = message => {
    if (debug) {
      console.log('useDynamicScript: ' + message);
    }
  };

  const loadTheScript = () => {
    debugMessage('requested loading the script');
    setLoadScript(true);
  };

  useEffect(() => {
    if (clearCacheOnSrcChange && previousSrc.current !== src) {
      debugMessage(`src is different: ${previousSrc.current}   ${src} `);
      const scriptIndex = cachedScripts.indexOf(src);
      if (scriptIndex >= 0) {
        cachedScripts.splice(scriptIndex, 1);
      }
      if (scriptRef.current) {
        scriptRef.current.remove();
      }
      if (loadAutomatically) {
        setLoadScript(true);
      }
    }
    previousSrc.current = src;
  }, [src, clearCacheOnSrcChange]);

  useEffect(() => {
    debugMessage(`loading script effect: loadScript: ${loadScript} src: ${src}`);
    if (loadScript) {
      if (cachedScripts.includes(src)) {
        setState({
          loaded: true,
          error: false,
        });
        return;
      }
      debugMessage(`loading the script: not cached src: ${src}`);

      cachedScripts.push(src);

      const loadAsync = async ? async !== false : defaults.async;
      const containerElem = containerWhitelist.includes(container) ? container : defaults.container;

      let script = document.createElement('script');
      script.src = src;
      script.async = loadAsync;

      scriptRef.current = script;

      const onScriptLoad = () => {
        debugMessage(`script loaded: ${src}`);
        setState({
          loaded: true,
          error: false,
        });
        setLoadScript(false);
      };

      const onScriptError = () => {
        debugMessage(`Script load error: Retry Count: ${retryCount} src: ${src}`);

        const scriptIndex = cachedScripts.indexOf(src);
        if (scriptIndex >= 0) {
          cachedScripts.splice(scriptIndex, 1);
        }

        script.remove();

        if (retryCount < maxRetryCount) {
          const nextRetryCount = retryCount + 1;
          setTimeout(() => setRetryCount(nextRetryCount), 250 * nextRetryCount);
        } else {
          setState({
            loaded: true,
            error: true,
          });
        }
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      document.getElementsByTagName(containerElem)[0].appendChild(script);

      return () => {
        debugMessage(`removing event listeners: ${src}`);
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
      };
    }
  }, [src, async, container, retryCount, loadScript]);

  return [state.loaded, state.error, loadTheScript];
};

export default useDynamicScript;
