import { Space } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

export const PizzaSpace = styled(Space)`
  > .ant-space-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StepIconWrapper = styled.div`
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  font-size: 1rem;
  ${props => (props.isActive ? 'background-color: #17a2b8;' : completeColor)};
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.isActive && pulseAnimation};

  > svg {
    font-size: 1.25em;
  }
`;

const completeColor = css`
  background-color: ${props => (props.isComplete ? '#28a745' : '#cccccc')};
`;

const pulseAnimation = css`
  animation: pulse-animation 1.75s infinite;

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(23, 162, 184, 0.4);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(23, 162, 184, 0);
    }
  }
`;
