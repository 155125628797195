import { Col, Icon, LoadingDots, Row, Text } from '@met/react-components';
import React, { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAddresses } from '../../../../hooks';

const ShippingDetails = ({
  name,
  line1,
  city,
  state,
  countryCode,
  postalCode,
  trackingNumber,
  shippoStatus,
  numberOfPackages,
  loadingServiceOrder,
  resubmittingShippingLabel,
  toggleAddressModal,
  resubmitShippingLabel,
  isAx,
}) => {
  const intl = useIntl();
  const {
    validatingAddress,
    addressIsValid,
    addressValidationWarnings,
    addressValidationErrors,
    validateAddress,
  } = useAddresses();
  const [validatedAddress, setValidatedAddress] = useState(false);

  const handleValidateAddressClick = () => {
    setValidatedAddress(true);

    validateAddress(line1, '', city, state, countryCode, postalCode);
  };

  if (!line1) {
    return (
      <Col>
        <FormattedMessage id='general.na' />
      </Col>
    );
  }

  const validatingAddressMarkup = validatedAddress && validatingAddress && <LoadingDots className='mt-2' />;

  const validateAddressMarkup = !trackingNumber && !validatedAddress && !validatingAddress && (
    <Text data-test-id='validateAddressText' clickable primary onClick={() => handleValidateAddressClick()} className='mt-1 mb-3'>
      <FormattedMessage id='admin.serviceOrder.validateAddress' />
    </Text>
  );

  const warningsMarkup =
    validatedAddress &&
    !validatingAddress &&
    addressValidationWarnings &&
    addressValidationWarnings.map((warning, index) => {
      return (
        <Row key={`address-validation-warning-${index}`} className='mt-2'>
          <Col xs='auto' middle='xs'>
            <Icon type='exclamation-circle' warning sm />
          </Col>
          <Col middle='xs'>{warning.text}</Col>
        </Row>
      );
    });

  const errorsMarkup =
    validatedAddress &&
    !validatingAddress &&
    addressValidationErrors &&
    addressValidationErrors.map((error, index) => {
      return (
        <Row key={`address-validation-error-${index}`} className='mt-2'>
          <Col xs='auto' middle='xs'>
            <Icon type='times-circle' danger sm />
          </Col>
          <Col middle='xs'>{error.text}</Col>
        </Row>
      );
    });

  const addressIsValidMarkup = validatedAddress && !validatingAddress && addressIsValid && (
    <Row className='mt-2'>
      <Col xs='auto' middle='xs'>
        <Icon type='check' success sm />
      </Col>
      <Col middle='xs'>
        <FormattedMessage id='admin.serviceOrder.addressIsValid' />
      </Col>
    </Row>
  );

  const resubmitLabelButtonMarkup = !isAx && !trackingNumber && !(loadingServiceOrder || resubmittingShippingLabel) && (
    <Text data-test-id='resubmitShippingLabelText' clickable primary onClick={() => resubmitShippingLabel()} style={{ padding: '5px' }}>
      <Icon style={{ paddingBottom: '5px', paddingLeft: '5px' }} type='undo' danger sm />
    </Text>
  );
  const editAddressButtonMarkup = !(loadingServiceOrder || resubmittingShippingLabel) && !isAx && (
    <Text data-test-id='editShippingLabelText' clickable primary onClick={() => toggleAddressModal()} className='mt-2'>
      <Icon style={{ paddingBottom: '5px', paddingLeft: '5px' }} type='edit' danger sm />
    </Text>
  );

  const trackingNumberMarkup =
    loadingServiceOrder || resubmittingShippingLabel ? (
      <LoadingDots />
    ) : (
      trackingNumber || intl.formatMessage({ id: 'general.na' })
    );
  return (
    <Fragment>
      <Col sm={6}>
        <Text bold>
          <FormattedMessage id='admin.serviceOrder.shippingAddressLabel' />
          {editAddressButtonMarkup}
        </Text>
        <Text>{name}</Text>
        <Text>{line1}</Text>
        <Text>
          {city}, {state} {postalCode}
        </Text>
        {validatingAddressMarkup}
        {validateAddressMarkup}
      </Col>
      <Col sm={6}>
        <Text bold>
          <FormattedMessage id='admin.serviceOrder.trackingNumberLabel' />
          {resubmitLabelButtonMarkup}
        </Text>
        <Text>{trackingNumberMarkup}</Text>
      </Col>
      <Col sm={6}>
        <Text bold>
          <FormattedMessage id='admin.serviceOrder.shippoStatusLabel' />
        </Text>
        <Text>{intl.formatMessage({ id: `admin.serviceOrder.shippoStatus.${shippoStatus}` })}</Text>
      </Col>
      <Col sm={6}>
        <Text bold>
          <FormattedMessage id='admin.serviceOrder.numberOfPackagesLabel' />
        </Text>
        <Text>{numberOfPackages}</Text>
      </Col>
      <Col xs={12}>
        {addressIsValidMarkup}
        {warningsMarkup}
        {errorsMarkup}
      </Col>
    </Fragment>
  );
};

export default ShippingDetails;
