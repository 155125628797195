import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import { Permissions } from '../../enums';
import { useFeatureToggles, useUser } from '../../hooks';
import { Card, Col, Row, Tabs } from 'antd';
import {
  AnnouncementsTab,
  ProductIncludedTab,
  ProductProblemTab,
  ProductsTab,
  RoutingRulesTab,
  ServiceCentersTab,
  ServiceOrdersTab,
  ReportsTab,
} from './components';

const { TabPane } = Tabs;

const Admin = () => {
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();
  const { permissions } = useUser();
  const [currentTab, setCurrentTab] = useState('');
  const [tabOptions, setTabOptions] = useState([]);

  useEffect(() => {
    let tabs = [];
    if (permissions()[Permissions.ANNOUNCEMENTS]) {
      tabs.push(
        <TabPane key='announcements' tab={'Announcements'}>
          <AnnouncementsTab />
        </TabPane>
      );
    }
    if (permissions()[Permissions.PRODUCTINCLUSIONS]) {
      tabs.push(
        <TabPane key='productIncluded' tab={intl.formatMessage({ id: 'admin.productIncludedTabLabel' })}>
          <ProductIncludedTab />
        </TabPane>
      );
    }
    if (permissions()[Permissions.PRODUCTS]) {
      tabs.push(
        <TabPane key='products' tab={intl.formatMessage({ id: 'admin.productsTabLabel' })}>
          <ProductsTab />
        </TabPane>
      );
    }
    if (permissions()[Permissions.PRODUCTPROBLEMS]) {
      tabs.push(
        <TabPane key='productProblems' tab={intl.formatMessage({ id: 'admin.productProblemsTabLabel' })}>
          <ProductProblemTab />
        </TabPane>
      );
    }
    tabs.push(
      <TabPane key='routingRules' tab={intl.formatMessage({ id: 'admin.routingRulesTabLabel' })}>
        <RoutingRulesTab />
      </TabPane>
    );
    if (permissions()[Permissions.SERVICECENTERS]) {
      tabs.push(
        <TabPane key='serviceCenters' tab={intl.formatMessage({ id: 'admin.serviceCentersTabLabel' })}>
          <ServiceCentersTab />
        </TabPane>
      );
    }
    if(permissions()[Permissions.SERVICECENTERS] || permissions()[Permissions.SERVICEORDERS]) {
      tabs.push(
        <TabPane key='reports' tab={intl.formatMessage({ id: 'admin.reportsTabLabel' })}>
          <ReportsTab />
        </TabPane>
      );
    }
    if (permissions()[Permissions.SERVICEORDERS]) {
      tabs.push(
        <TabPane key='serviceorders' tab={intl.formatMessage({ id: 'admin.serviceOrdersTabLabel' })}>
          <ServiceOrdersTab />
        </TabPane>
      );
    }

    setTabOptions(tabs);
  }, []);

  useEffect(() => {
    let pathParts = location.pathname.split('/');
    if (pathParts.length < 3) {
      if (permissions()[Permissions.PRODUCTS]) {
        history.replace('/admin/products');
      } else if (permissions()[Permissions.SERVICEORDERS]) {
        history.replace('/admin/serviceorders');
      } else if (permissions()[Permissions.SERVICECENTERS]) {
        history.replace('/admin/serviceCenters');
      } else if (permissions()[Permissions.PRODUCTPROBLEMS]) {
        history.replace('/admin/productProblems');
      } else if (permissions()[Permissions.PRODUCTINCLUSIONS]) {
        history.replace('/admin/productIncluded');
      } else if (permissions()[Permissions.ANNOUNCEMENTS]) {
        history.replace('/admin/announcements');
      } else {
        history.replace('/admin/routingRules');
      }
    } else {
      setCurrentTab(pathParts[2]);
    }
  }, [location.pathname]);

  const handleTabChange = tabId => {
    history.push(`/admin/${tabId}`);
  };

  const tabsMarkup = currentTab && (
    <Tabs data-test-id='adminCurrentTab' activeKey={currentTab} onChange={tabId => handleTabChange(tabId)}>
      {tabOptions}
    </Tabs>
  );

  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <h1 style={{ paddingLeft: '24px' }}>
            <FormattedMessage id='admin.heading' />
          </h1>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card>{tabsMarkup}</Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Admin;
