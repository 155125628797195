import createAxiosAction from '../createAxiosAction';
import {
  ADD_CREDIT_CARD,
  AUTHORIZE_CREDIT_CARD,
  DELETE_CREDIT_CARD,
  GET_CREDIT_CARDS,
  GET_SQUARE_CONFIG,
} from '../types';

export default {
  getSquareConfig: locationId => {
    return createAxiosAction({
      url: `/api/v1/square/config?locationId=${locationId ? locationId : ''}`,
      startAction: GET_SQUARE_CONFIG,
    });
  },
  getCreditCards: () => {
    return createAxiosAction({
      method: 'GET',
      url: `/api/v1/square/creditcards`,
      startAction: GET_CREDIT_CARDS,
    });
  },

  addCreditCard: (billingPostalCode, cardholderName, squareCardNonce) => {
    return createAxiosAction({
      method: 'POST',
      url: `/api/v1/square/creditcards`,
      body: {
        billingPostalCode,
        cardholderName,
        squareCardNonce,
      },
      startAction: ADD_CREDIT_CARD,
    });
  },
  deleteCreditCard: creditCardId => {
    return createAxiosAction({
      method: 'POST',
      url: `/api/v1/square/creditcards/${creditCardId}`,
      startAction: DELETE_CREDIT_CARD,
    });
  },
  authorizeCreditCard: (creditCardId, serviceOrderNumber) => {
    return createAxiosAction({
      method: 'POST',
      url: `/api/v1/square/authorizeCard`,
      body: {
        ServiceOrderId: serviceOrderNumber,
        CardId: creditCardId,
      },
      startAction: AUTHORIZE_CREDIT_CARD,
    });
  },
};
