import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Col,
  Icon,
  InputGroup,
  InputGroupAppend,
  Loader,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Row,
  Text,
  TextInput,
} from '@met/react-components';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { productsActions } from '../../store';
import ProductSearchResult from './ProductSearchResult';
import { SearchResult, SearchResults } from './styled';

const ProductSearchModal = ({ hidden, toggle }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();

  const searchTermRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [searched, setSearched] = useState(false);

  let searchingProducts = useSelector(state => state.products.searchProducts.isLoading);
  let searchResults = useSelector(state => state.products.searchProducts.products);

  useEffect(() => {
    if (hidden === false) {
      setSearchTerm('');
      setSearched(false);
      searchTermRef.current.focus();
    }
  }, [hidden]);

  const handleSearchClick = () => {
    dispatch(productsActions.searchProducts(searchTerm))
      .then(() => {
        setSearched(true);
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'productSearch.productSearchError' }));
      });
  };

  const handleAddClick = sku => {
    toggle();

    history.push(`/repairprocess/warrantyinformation/${sku}`);
  };

  let searchResultsMarkup = '';

  if (searchingProducts) {
    searchResultsMarkup = (
      <SearchResult>
        <Row>
          <Col middle='xs' center='xs'>
            <Loader type='tiles' />
          </Col>
        </Row>
      </SearchResult>
    );
  }

  if (!searchingProducts && searched && searchResults.length === 0) {
    searchResultsMarkup = (
      <SearchResult>
        <Row>
          <Col middle='xs' center='xs'>
            <Icon type='search' md />
            <Text bold lg>
              <FormattedMessage id='productSearch.productSearchNoResults' />
            </Text>
            <Text md>
              <FormattedMessage id='productSearch.productSearchNoResultsSub' />
            </Text>
          </Col>
        </Row>
      </SearchResult>
    );
  }

  if (!searchingProducts && searched && searchResults.length > 0) {
    searchResultsMarkup =
      searchResults &&
      searchResults.map((searchResult, index) => {
        return (
          <ProductSearchResult
            key={`search-result-${index}`}
            sku={searchResult.sku}
            skuAlias={searchResult.skuAlias}
            description={searchResult.description}
            imageUrl={searchResult.image}
            serviceEligible={searchResult.serviceEligible}
            handleAddClick={() => handleAddClick(searchResult.sku)}
          />
        );
      });
  }

  return (
    <Modal lg centered hidden={hidden} toggle={toggle}>
      <ModalContent>
        <ModalHeader toggle={toggle}>
          <FormattedMessage id='productSearch.productSearchTitle' />
        </ModalHeader>
        <ModalBody>
          <InputGroup>
            <TextInput
              data-test-id='productSearchModalSearchInput'
              onChange={e => setSearchTerm(e.target.value)}
              value={searchTerm}
              onSubmit={handleSearchClick}
              inputRef={searchTermRef}
              data-hj-allow
            />
            <InputGroupAppend>
              <Button data-test-id='productSearchModalSearchButtonIcon' onClick={handleSearchClick} loading={searchingProducts}>
                <Icon type='search' />
              </Button>
            </InputGroupAppend>
          </InputGroup>
          <SearchResults flush>{searchResultsMarkup}</SearchResults>
        </ModalBody>
        <ModalFooter>
          <Button data-test-id='productSearchModalCancelButton' onClick={toggle}>Cancel</Button>
          <Button data-test-id='productSearchModalSearchButton' primary onClick={handleSearchClick}>
            Search
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ProductSearchModal.propTypes = {
  hidden: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ProductSearchModal;
