import { Modal, Typography } from 'antd';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUser } from '../../../../hooks';

const { Title, Paragraph } = Typography;

const DeniedWarrantyModal = ({ visible, onOk, onCancel, state }) => {
  const intl = useIntl();
  const { email, phoneNumber, preferredContactMethod } = useUser();

  let description;
  if (state.action === 1) {
    description = (
      <FormattedMessage
        id={`repairDetails.deniedWarranty.deniedWarrantyAction${state.action}_${state.paymentMethod}Details`}
        values={{ poNumber: state.poNumber }}
      />
    );
  } else if ([2, 3].includes(state.action)) {
    description = <FormattedMessage id={`repairDetails.deniedWarranty.deniedWarrantyAction${state.action}Details`} />;
  }

  const contactMethodDescription =
    state.action === 1 &&
    state.paymentMethod === 1 &&
    (preferredContactMethod === 1 ? (
      <FormattedMessage id='repairDetails.deniedWarranty.contactViaEmail' values={{ email: email }} />
    ) : (
      <FormattedMessage id='repairDetails.deniedWarranty.contactViaPhone' values={{ phoneNumber: phoneNumber }} />
    ));

  return (
    <Modal
      centered
      open={visible}
      onOk={onOk}
      okText={intl.formatMessage({ id: 'general.confirm' })}
      onCancel={onCancel}
    >
      <Title level={3}>
        <FormattedMessage id={`repairDetails.deniedWarranty.deniedWarrantyAction${state.action}`} />
      </Title>
      <Paragraph>{description}</Paragraph>
      <Paragraph>{contactMethodDescription}</Paragraph>
    </Modal>
  );
};

export default DeniedWarrantyModal;
