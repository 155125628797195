import {Button, Card, Form, Input, Radio, Row, Space, Tabs, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import theme from '../../styled-config'

const { Text } = Typography;

export const DetailsContainer = styled.div`
  margin-top: 1rem;
`;

export const NotificationTypesList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: block;
`;

export const AddressCard = styled(Card)`
  height: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);

  .ant-card-body {
    height: 100%;
  }
`;

export const NewAddressCard = styled(AddressCard)`
  text-align: center;
  cursor: pointer;
`;

export const FullHeightRow = styled(Row)`
  height: 100%;
  justify-content: center;

  .ant-col {
    align-self: center;
  }
`;

export const DefaultAddressLabel = styled(Text)`
  font-size: 0.8em;
  color: #db011a;
`;

export const ActionLinks = styled(Space)`
  cursor: pointer;

  .ant-typography {
    color: #db011a;
  }
`;

export const CreditCardContainer = styled(Card)`
  height: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);

  .ant-card-body {
    height: 100%;
  }
`;

export const DisclaimerText = styled(Text)`
  color: ${props => props.theme.colors.darkGray};
  margin-left: .25em;

  @media (max-width: ${theme.responsive.lg}) {
    margin-left: 0;
    margin-top: -1em;
  }
`;

export const PreferencesTabs = styled(Tabs)`
  display: unset;

  .ant-tabs-tab {
    padding: 16px 30px;
    margin: 0;
  }

  .ant-tabs-tab-btn {
    font-weight: 600;
    color: #a6a8a9;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #212529;
  }

  .ant-tabs-ink-bar {
    height: 3px !important;
  }
  
  @media only screen and (max-width: 964px) {
    display: none;
  }
  
  @media only screen and (min-width: 965px) {
    display: unset;
  }
`;

export const GeneralInput = styled(Input)`
  cursor: default !important;
  color: #4a4a4a !important;
  background-color: #f2f2f2 !important;
  padding: 0.375rem 0.75rem;
`;

export const UserFormItem = styled(Form.Item)`
  margin-bottom: 0.85rem;
`;

export const CancelButton = styled(Button)`
  border: none;
  box-shadow: none;
  text-shadow: none;
  background: #cccccc;
  color: black;
`;

export const DefaultButton = styled(Button)`
  border: none;
  box-shadow: none;
  text-shadow: none;
  background: #db011c;
`;

export const StyledContainer = styled(Card)`
  padding: '1.25rem',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  box-shadow: 'rgb(0 0 0 / 3%) 0px 0px 3px 0px',
  width: '100%';
`;

export const BackButton = styled(Button)`
  border: none;
  color: #db011c;
  box-shadow: none !important;
  padding: unset;

  @media only screen and (max-width: 964px) {
    display: block;
  }
  
  @media only screen and (min-width: 965px) {
    display: none;
  }
`;

export const MobileButton = styled(Radio.Button)`
  width: 100%;
  height: 52px;
  font-weight: bold;
  align-items: center; 
  padding: 8px 16px 8px 16px;
  
  @media only screen and (max-width: 964px) {
    display: block;
  }
  
  @media only screen and (min-width: 965px) {
    display: none;
  }
  
  span:nth-child(2) {
    width: 100%;
  }
`;

export const StyledDiv = styled.div`
  @media only screen and (min-width: 965px) {
    display: none;
  }
`;

export const MobileButtonSpace = styled(Space)`
  width: 100%;
  gap: 0px !important;
`;

export const StyledBackIcon = styled(FontAwesomeIcon)`
  vertical-align: middle !important;
  margin-bottom: 4.6px;
`;
