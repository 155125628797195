import Enumerable from 'linq';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useAddresses, useLocale} from '../../../../hooks';
import { usersActions } from '../../../../store';

import { SuggestionText, SuggestionRow } from '../styled';
import { formatPostalCode } from '../../../../helpers/formatter';
import { Button, Switch, Col, Form, Input, Modal, Row, Select, Typography } from 'antd';

const { isCaLocale } = useLocale();
const { Option } = Select;
const { Text } = Typography;

const ServiceCenterModal = ({ hidden, toggle, serviceCenter, onCancelClick, onSaveChangesClick }) => {
  //Refs
  const formRef = useRef();

  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    countries,
    states,
    validatingAddress,
    savingAddress,
    sanitizedAddress,
    addressValidationWarnings,
    addressValidationErrors,
  } = useAddresses();

  const [countriesOptions, setCountriesOptions] = useState([]);
  const [statesOptions, setStatesOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [addressLine1Suggestion, setAddressLine1Suggestion] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [citySuggestion, setCitySuggestion] = useState('');
  const [selectedState, setSelectedState] = useState(null);
  const [stateSuggestion, setStateSuggestion] = useState(null);
  const [postalCodeSuggestion, setPostalCodeSuggestion] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const [addressLine2Suggestion, setAddressLine2Suggestion] = useState(''); // eslint-disable-line no-unused-vars
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    if (serviceCenter) {
      setSelectedState(serviceCenter.state || '');
      setSelectedCountry(serviceCenter.country || '');
    }
  }, [serviceCenter]);

  useEffect(() => {
    if (countryList && countryList.length > 0) {
      setCountriesOptions(createCountriesOptions());
    }
  }, [countryList]);

  useEffect(() => {
    if (countries && countries.length > 0) {
      setCountryList(
        Enumerable.from(countries)
          .where(x => x.iso3 === 'USA' || x.iso3 === 'CAN')
          .toArray()
      ); // SVC-372: Only allow USA
    }
  }, [countries]);

  useEffect(() => {
    if (countryList.length > 0) {
      if (serviceCenter && serviceCenter.countryCode) {
        setSelectedCountry(serviceCenter.countryCode);
      } else {
        let defaultVal = 'USA';
        if (isCaLocale()) {
          defaultVal = 'CAN';
        }

        let defaultOption = countryList.find(x => x.iso3 === defaultVal).iso3;

        setSelectedCountry(defaultOption);
      }
    }
  }, [serviceCenter, countryList]);

  useEffect(() => {
    let list = [];
    if (states && states.length > 0) {
      list = states.filter(state => {
        if (selectedCountry) {
          return state.countryCode?.toLowerCase() == selectedCountry.toLowerCase();
        }
        return true;
      });

      setStateList(list);
    }
  }, [states, selectedCountry]);

  useEffect(() => {
    if (selectedCountry !== null) {
      setStatesOptions(createStatesOptions());
    }

    setIsDirty(true);
  }, [stateList]);

  useEffect(() => {
    if (statesOptions.length > 0 && serviceCenter && serviceCenter.state) {
      let option = Enumerable.from(statesOptions).firstOrDefault(x => x.value === serviceCenter.state);
      if (option) setSelectedState(option);
    } else {
      setSelectedState('');
    }
  }, [serviceCenter, statesOptions]);

  useEffect(() => {
    if (selectedState !== null) {
      setStateSuggestion(null);

      if (formRef.current) {
        formRef.current.setFieldsValue({
          state: selectedState,
        });
      }
    }
  }, [selectedState]);

  useEffect(() => {
    if (sanitizedAddress) {
      const { addressLine1, city, state, postalCode } = sanitizedAddress;

      if (addressLine1) {
        setAddressLine1Suggestion(addressLine1);
      }
      if (city) {
        setCitySuggestion(city);
      }
      if (state && statesOptions.length > 0) {
        let option = stateList.find(x => x.abbreviation === state).abbreviation;
        if (option) {
          setStateSuggestion(option);
        }
      }
      if (postalCode) {
        setPostalCodeSuggestion(postalCode);
      }
    }
  }, [sanitizedAddress]);

  const createCountriesOptions = () => {
    let countriesOptions = [];
    if (countryList.length > 0) {
      countriesOptions = countryList.map((country, index) => {
        return (
          <Option key={'country-' + index} value={country.iso3}>
            {country.name}
          </Option>
        );
      });
    }

    return countriesOptions;
  };

  const createStatesOptions = () => {
    let statesOptions = [];

    if (stateList && stateList.length > 0) {
      statesOptions = stateList.map((state, index) => {
        return (
          <Option key={'state-' + index} value={state.abbreviation}>
            {state.name}
          </Option>
        );
      });
    }

    return statesOptions;
  };

  const validate = formVal => {
    let isValid = true;
    if (formVal.postalCode && selectedCountry) {
      isValid = validatePostalCode(formVal.postalCode);
    }

    return isValid;
  };

  const onCountryChange = country => {
    setSelectedState('');
    formRef.current.setFieldsValue({
      state: '',
    });
    setSelectedCountry(country);
  };

  const onBlurPostalCode = postalCode => {
    let formatted = formatPostalCode(selectedCountry, postalCode);

    if (formRef.current) {
      formRef.current.setFieldsValue({
        postalCode: formatted,
      });
    }
  };

  const validatePostalCode = postalCode => {
    let isValid = true;

    let isUS = selectedCountry == 'USA' || selectedCountry == 'US';
    let isCA = selectedCountry == 'CAN' || selectedCountry == 'CA';
    let re = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/;
    if (isCA) {
      re = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    }
    if (!re.test(postalCode)) {
      isValid = false;
    }

    return isValid;
  };

  const clearSuggestions = () => {
    setPostalCodeSuggestion('');
    setStateSuggestion('');
    setCitySuggestion('');
    setAddressLine1Suggestion('');
    setAddressLine2Suggestion('');
  };

  const setFieldValue = (field, value) => {
    if (field) {
      setIsDirty(true);

      switch (field) {
        case 'addressLine1':
          formRef.current.setFieldsValue({
            addressLine1: value,
          });
          setAddressLine1Suggestion('');
          return;
        case 'city':
          formRef.current.setFieldsValue({
            city: value,
          });
          setCitySuggestion('');
          return;
        case 'state':
          formRef.current.setFieldsValue({
            state: value,
          });
          setStateSuggestion('');
          return;
        case 'postalCode':
          formRef.current.setFieldsValue({
            postalCode: value,
          });
          setPostalCodeSuggestion('');
          return;
      }
    }
  };

  const handleSaveChangesClick = formVal => {
    let isValid = validate(formVal);
    if (!isValid) {
      toast.error(intl.formatMessage({ id: 'address.pleaseCorrectLabel' }), { autoClose: 5000 });
    } else {
      if (isDirty) {
        dispatch(
          usersActions.validateUserAddress(
            formVal.addressLine1,
            addressLine2,
            formVal.city,
            formVal.state,
            formVal.country,
            formVal.postalCode
          )
        )
          .then(payload => {
            if (payload.data.data.isValid) {
              setIsDirty(false);
              toast.success(intl.formatMessage({ id: 'address.addressIsValid' }), { autoClose: 5000 });
            } else if (payload.data.data.errors) {
              setIsDirty(true);
              toast.error(intl.formatMessage({ id: 'address.addressIsInvalid' }), { autoClose: 5000 });
            } else if (payload.data.data.warnings) {
              setIsDirty(true);
              toast.error(intl.formatMessage({ id: 'address.addressNotFound' }), { autoClose: 5000 });
            }
          })
          .catch(() => {
            setIsDirty(true);
            toast.error(intl.formatMessage({ id: 'address.sanitizationError' }), { autoClose: 5000 });
          });
      } else {
        onSaveChangesClick(
          serviceCenter.id,
          formVal.addressLine1,
          addressLine2,
          formVal.city,
          formVal.state,
          formVal.country,
          formVal.postalCode,
          formVal.description,
          formVal.axId,
          formVal.isActive
        );
        setIsDirty(false);
      }
    }
  };

  const addressValidationWarningList =
    !isDirty &&
    !addressValidationErrors &&
    addressValidationWarnings &&
    addressValidationWarnings.map((warning, index) => {
      return (
        <Row key={`validation-warning-${index}`}>
          <Col>
            <Text bold error>
              {warning.text}
            </Text>
          </Col>
        </Row>
      );
    });

  const addressValidationWarningMarkup = addressValidationWarningList && (
    <Fragment>{addressValidationWarningList}</Fragment>
  );

  const addressValidationErrorList =
    isDirty &&
    addressValidationErrors &&
    addressValidationErrors.map((error, index) => {
      return (
        <Row key={`validation-error-${index}`}>
          <Col>
            <Text bold error>
              {error.text}
            </Text>
          </Col>
        </Row>
      );
    });

  const addressValidationErrorMarkup = addressValidationErrorList && <Fragment>{addressValidationErrorList}</Fragment>;

  const addressLine1SuggestionMarkup = addressLine1Suggestion && (
    <SuggestionRow>
      <Col xs='auto'>
        <SuggestionText data-test-id='adminAddServiceCenterAddressLine1Text' strong italic onClick={() => setFieldValue('addressLine1', addressLine1Suggestion)}>
          <FormattedMessage id='address.didYouMean' values={{ suggestion: addressLine1Suggestion }} />
        </SuggestionText>
      </Col>
    </SuggestionRow>
  );

  const citySuggestionMarkup = citySuggestion && (
    <SuggestionRow>
      <Col xs='auto'>
        <SuggestionText data-test-id='adminAddServiceCenterAddressCityText' strong italic onClick={() => setFieldValue('city', citySuggestion)}>
          <FormattedMessage id='address.didYouMean' values={{ suggestion: citySuggestion }} />
        </SuggestionText>
      </Col>
    </SuggestionRow>
  );

  const stateSuggestionMarkup = stateSuggestion && (
    <SuggestionRow>
      <Col xs='auto'>
        <SuggestionText data-test-id='adminAddServiceCenterAddressStateText' strong italic onClick={() => setFieldValue('state', stateSuggestion)}>
          <FormattedMessage
            id='address.didYouMean'
            values={{ suggestion: stateList.find(x => x.abbreviation === stateSuggestion).name }}
          />
        </SuggestionText>
      </Col>
    </SuggestionRow>
  );

  const postalCodeSuggestionMarkup = postalCodeSuggestion && (
    <SuggestionRow>
      <Col xs='auto'>
        <SuggestionText data-test-id='adminAddServiceCenterAddressPostalCodeText' strong italic onClick={() => setFieldValue('postalCode', postalCodeSuggestion)}>
          <FormattedMessage id='address.didYouMean' values={{ suggestion: postalCodeSuggestion }} />
        </SuggestionText>
      </Col>
    </SuggestionRow>
  );

  const editServiceCenterMarkup = serviceCenter && (
    <Form
      ref={formRef}
      name='addressModal'
      layout='vertical'
      onFinish={formVal => {
        handleSaveChangesClick(formVal);
      }}
      initialValues={{
        description: serviceCenter.description,
        axId: serviceCenter.axId,
        country: selectedCountry,
        addressLine1: serviceCenter.addressLine1,
        city: serviceCenter.city,
        state: selectedState,
        postalCode: serviceCenter.postalCode,
        isActive: serviceCenter.isActive,
      }}
      onValuesChange={(field, allValues) => {
        let key = Object.keys(field)[0];
        let value = field[key];

        setFieldValue(key, value);
      }}
      scrollToFirstError={true}
    >
      <Form.Item
        name='description'
        label={intl.formatMessage({ id: 'admin.serviceCenter.descriptionLabel' })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: 'admin.serviceCenter.descriptionRequired' }),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='axId'
        label={intl.formatMessage({ id: 'admin.serviceCenter.axIdLabel' })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: 'admin.serviceCenter.axIdRequired' }),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name='country' label={intl.formatMessage({ id: 'address.countryLabel' })}>
        <Select
          data-test-id='adminAddServiceCenterAddressCountryText'
          showSearch
          onChange={e => onCountryChange(e)}
          optionFilterProp='children'
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {countriesOptions}
        </Select>
      </Form.Item>
      <Form.Item
        name='addressLine1'
        label={intl.formatMessage({ id: 'address.addressLine1Label' })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: 'address.addressLine1Required' }),
          },
        ]}
      >
        <Input />
      </Form.Item>
      {addressLine1SuggestionMarkup}
      <Form.Item
        name='city'
        label={intl.formatMessage({ id: 'address.cityLabel' })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: 'address.cityRequired' }),
          },
        ]}
      >
        <Input />
      </Form.Item>
      {citySuggestionMarkup}
      <Row gutter={[6, 6]}>
        <Col xs={12}>
          <Form.Item
            name='state'
            label={intl.formatMessage({ id: 'address.stateLabel' })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'address.stateLabel' }),
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {statesOptions}
            </Select>
          </Form.Item>
          {stateSuggestionMarkup}
        </Col>
        <Col xs={12}>
          <Form.Item
            name='postalCode'
            label={intl.formatMessage({ id: 'address.postalCodeLabel' })}
            required={true}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && validatePostalCode(value)) {
                    return Promise.resolve();
                  }

                  if (!value) {
                    return Promise.reject(new Error(intl.formatMessage({ id: 'address.postalCodeRequired' })));
                  }
                  return Promise.reject(new Error(intl.formatMessage({ id: 'address.postalCodeInvalid' })));
                },
              }),
            ]}
          >
            <Input onBlur={e => onBlurPostalCode(e.target.value)} />
          </Form.Item>
          {postalCodeSuggestionMarkup}
        </Col>
      </Row>
      <Row gutter={[6, 6]}>
        <Col>
          <Form.Item
            data-test-id='isActiveCheckbox'
            name='isActive'
            valuePropName='checked'
            label={intl.formatMessage({ id: 'admin.serviceCenter.isActiveLabel' })}
          >
            <Switch disabled={serviceCenter.isDefault} />
          </Form.Item>
        </Col>
      </Row>
      {addressValidationWarningMarkup}
      {addressValidationErrorMarkup}
    </Form>
  );

  return (
    <Modal
      visible={!hidden}
      title={
        serviceCenter.id ? (
          <FormattedMessage id='admin.serviceCenter.editServiceCenterModalTitle' />
        ) : (
          <FormattedMessage id='admin.serviceCenter.newServiceCenterModalTitle' />
        )
      }
      footer={[
        <Button data-test-id='adminAddServiceCenterAddressCancelButton' key={'cancelBtn'} onClick={() => onCancelClick()}>
          <FormattedMessage id='general.cancel' />
        </Button>,
        <Button
          key={'submitBtn'}
          type={'primary'}
          onClick={() => formRef?.current?.submit()}
          loading={validatingAddress || savingAddress}
          data-test-id='save-changes-button'
        >
          {isDirty ? (
            <FormattedMessage id='address.validateAddress' />
          ) : serviceCenter.id ? (
            <FormattedMessage id='general.saveChanges' />
          ) : (
            <FormattedMessage id='general.submit' />
          )}
        </Button>,
      ]}
      onCancel={toggle}
      destroyOnClose={true}
      afterClose={clearSuggestions}
    >
      {editServiceCenterMarkup}
    </Modal>
  );
};

ServiceCenterModal.propTypes = {
  hidden: PropTypes.bool,
  toggle: PropTypes.func,
  serviceCenter: PropTypes.object,
  onCancelClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onSaveChangesClick: PropTypes.func,
};
export default ServiceCenterModal;
