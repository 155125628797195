import { useCallback, useState } from 'react';

/**
 * custom hook: useToggle
 * Manages show/open state flag for toggling functionality
 *
 * @param {Boolean} initialState
 * @returns {[Boolean, () => void]}
 */
const useToggle = (initialState = true) => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState(state => !state), []);

  return [state, toggle];
};

export default useToggle;
