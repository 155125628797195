import { Icon } from '@met/react-components';
import { Button, Space, Typography } from 'antd';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { ServiceCard } from '../../../../../../components';
import { useToggle } from '../../../../../../hooks';
import LmrDefinitionModal from './LmrDefinitionModal';

const { Text, Title, Paragraph } = Typography;

const MoreInformationNeeded = ({ warrantyLine, onBack, onGoToSerialNumber }) => {
  const [showLmrModal, toggleLmrModal] = useToggle(false);

  const underWarrantyMarkup = (
    <Fragment>
      <ServiceCard>
        <Title strong>
          <FormattedMessage id='repairProcess.warrantyInformation.moreInformation.title' />
        </Title>
        <Space direction='vertical' size='large'>
          <div>
            <Paragraph>
              <FormattedMessage id='repairProcess.warrantyInformation.moreInformation.description' />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id='repairProcess.warrantyInformation.moreInformation.description2' />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id='repairProcess.warrantyInformation.moreInformation.description3' />
            </Paragraph>
            <Space direction='horizontal'>
              <Button data-test-id='repairProcessEnterSerialNumberButton' type='primary' ghost onClick={() => onGoToSerialNumber()}>
                <FormattedMessage id='repairProcess.warrantyInformation.moreInformation.enterSerialNumber' />
              </Button>
              <Button data-test-id='repairProcessUploadPOPButton' type='primary' ghost onClick={() => onBack()}>
                <FormattedMessage id='repairProcess.warrantyInformation.moreInformation.uploadPOP' />
              </Button>
            </Space>
          </div>
          <div>
            <Title level={3} strong>
              <FormattedMessage id='repairProcess.warrantyInformation.warrantyCoverage.title' />
            </Title>
            <Paragraph>
              <ul>
                <li>
                  <FormattedMessage id='repairProcess.warrantyInformation.warrantyCoverage.factor1' />
                </li>
                <li>
                  <FormattedMessage id='repairProcess.warrantyInformation.warrantyCoverage.factor2' />
                </li>
                <li>
                  <FormattedMessage id='repairProcess.warrantyInformation.warrantyCoverage.factor3' />
                </li>
              </ul>
            </Paragraph>
          </div>
          <div>
            <Title level={3} strong>
              <FormattedMessage id='repairProcess.warrantyInformation.noWarranty' />
            </Title>
            <Paragraph>
              <Text>
                <FormattedMessage id='productDetail.toolInfo.lmrTitle' />
                <Icon data-test-id='productDetailLMRToggleIcon' className='ml-2' type='question-circle' xs onClick={toggleLmrModal} />
              </Text>
              <Paragraph>
                <Text style={{ color: '#db011c' }}>{warrantyLine}</Text>
              </Paragraph>
            </Paragraph>
          </div>
        </Space>
      </ServiceCard>
    </Fragment>
  );

  return (
    <Fragment>
      {underWarrantyMarkup}
      <LmrDefinitionModal isVisible={showLmrModal} toggle={() => toggleLmrModal()} />
    </Fragment>
  );
};

export default MoreInformationNeeded;
