import {
  Button,
  Card,
  CardBody,
  Col,
  Icon,
  Placeholder,
  PlaceholderContent,
  RadioButton,
  Row,
  Text,
} from '@met/react-components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPhoneNumber } from '../../../../helpers/formatter';

import { useAddresses, useUser } from '../../../../hooks';
import { useShippingContext } from '../ShippingContext';
import { AddressText } from '../styled';

const ShippingInfo = ({ onEditAddressClick, onAddAddressClick, onEditProfileClick }) => {
  const { user, loadingUser, name, email, phoneNumber, preferredContactMethod, accountNumber } = useUser();
  const { defaultUserAddress, userAddresses } = useAddresses();

  const { shippingAddress, setShippingAddress, shippingAddressInvalid, accountInfoInvalid } = useShippingContext();

  if (loadingUser) {
    return (
      <Placeholder>
        <Row>
          <Col>
            <Row>
              <Col xs={4}>
                <PlaceholderContent />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <PlaceholderContent />
              </Col>
            </Row>
            <Row>
              <Col xs={5}>
                <PlaceholderContent />
              </Col>
            </Row>
          </Col>
        </Row>
      </Placeholder>
    );
  }

  const handleShippingAddressChange = updatedShippingAddress => {
    if (shippingAddress.id !== updatedShippingAddress.id) {
      setShippingAddress(updatedShippingAddress);
    }
  };

  const generateAddressText = address => {
    return address.addressLine2 ? (
      <AddressText>
        {address.addressLine1} {address.addressLine2}, {address.city}, {address.state}, {address.postalCode}{' '}
        {address.countryCode}
      </AddressText>
    ) : (
      <AddressText>
        {address.addressLine1}, {address.city}, {address.state}, {address.postalCode} {address.countryCode}
      </AddressText>
    );
  };

  const editButtonMarkup = address => {
    if (shippingAddress && shippingAddress.id === address.id) {
      return (
        <Text data-test-id='editDefaultShippingAddressButton' primary clickable onClick={() => onEditAddressClick(address)}>
          <FormattedMessage id='general.edit' />
        </Text>
      );
    }
  };

  const defaultAddressMarkup = defaultUserAddress && (
    <Fragment>
      <Row>
        <Col>
          <Text bold>
            <FormattedMessage id='profile.defaultAddressLabel' />
          </Text>
        </Col>
      </Row>
      <Row>
        <Col xs='auto' middle='xs'>
          <div>
            <RadioButton
              id='address-default'
              className='pt-0'
              label={generateAddressText(defaultUserAddress)}
              value={0}
              checked={shippingAddress && shippingAddress.id === defaultUserAddress.id}
              onChange={() => handleShippingAddressChange(defaultUserAddress)}
              data-test-id='default-user-address'
            />
          </div>
        </Col>
        <Col xs={12} sm='auto' middle='xs' center='xs'>
          {editButtonMarkup(defaultUserAddress)}
        </Col>
      </Row>
    </Fragment>
  );

  const otherAddressesList =
    userAddresses &&
    userAddresses.map((userAddress, index) => {
      return (
        <Row key={`user-address-${index}`}>
          <Col xs='auto' middle='xs'>
            <RadioButton
              id={`address-${index}`}
              className='pt-0'
              label={generateAddressText(userAddress)}
              value={index}
              checked={shippingAddress && shippingAddress.id === userAddress.id}
              onChange={() => handleShippingAddressChange(userAddress)}
              data-test-id={`other-user-address-${index}`}
            />
          </Col>
          <Col xs={12} sm='auto' middle='xs' center='xs'>
            {editButtonMarkup(userAddress)}
          </Col>
        </Row>
      );
    });

  const otherAddressesMarkup = (defaultUserAddress || (userAddresses && userAddresses.length > 0)) && (
    <Fragment>
      <Row>
        <Col xs='auto' middle='xs'>
          <Text bold>
            <FormattedMessage id='shipping.otherAddressesLabel' />
          </Text>
        </Col>
      </Row>
      {otherAddressesList}
      <Row>
        <Col xs='auto'>
          <Button data-test-id='shippingAddAnotherAddress' primary icon='plus' onClick={() => onAddAddressClick()}>
            <FormattedMessage id='shipping.addAnotherAddress' />
          </Button>
        </Col>
      </Row>
    </Fragment>
  );

  const noAddressesMarkup = !defaultUserAddress && (!userAddresses || (userAddresses && userAddresses.length === 0)) && (
    <Fragment>
      <Row>
        <Col xs='auto'>
          <Text bold>
            <FormattedMessage id='shipping.shippingAddressLabel' />
          </Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text italic>
            <FormattedMessage id='shipping.noShippingAddress' />
          </Text>
        </Col>
      </Row>
      <Row>
        <Col xs='auto'>
          <Button data-test-id='shippingAddAnotherAddressPlus' primary icon='plus' onClick={() => onAddAddressClick()}>
            <FormattedMessage id='shipping.addAnotherAddress' />
          </Button>
        </Col>
      </Row>
    </Fragment>
  );

  const addressInvalidMarkup = shippingAddressInvalid && (
    <Row>
      <Col>
        <Text primary>
          <FormattedMessage id='shipping.addAnAddress' />
        </Text>
      </Col>
    </Row>
  );

  const accountInfoMarkup = user && (
    <Fragment>
      <Row>
        <Col>
          <h3>
            <FormattedMessage id='shipping.accountInfo' />
          </h3>
          <Text block className='mt-1'>
            <FormattedMessage id='shipping.nameLabel' /> {name}
          </Text>
          <Text block>
            <FormattedMessage id='shipping.phoneNumberLabel' /> {formatPhoneNumber(phoneNumber)}
          </Text>
          <Text block>
            <FormattedMessage id='shipping.emailLabel' /> {email}
          </Text>
          {accountNumber && (
            <Text block>
              <FormattedMessage id='shipping.accountNumberLabel' /> {accountNumber}
            </Text>
          )}
          <Text block>
            <FormattedMessage id='shipping.preferredContactMethodLabel' />{' '}
            <FormattedMessage id={`profile.preferredContactMethods.label${preferredContactMethod}`} />
          </Text>
        </Col>
      </Row>
      <Row>
        <Col xs='auto'>
          <Button data-test-id='shippingAccountInfoEditButton' primary onClick={() => onEditProfileClick()} icon='pencil'>
            <FormattedMessage id='general.edit' />
          </Button>
        </Col>
      </Row>
    </Fragment>
  );

  const accountInfoInvalidMarkup = accountInfoInvalid && (
    <Row>
      <Col>
        <Text primary>
          <FormattedMessage id='shipping.accountInfoRequired' />
        </Text>
      </Col>
    </Row>
  );

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Row className='mb-1'>
            <Col xs='auto' middle='xs' className='pr-0 mr-1'>
              <Icon type='shipping-fast' />
            </Col>
            <Col middle='xs'>
              <h3 className='mb-0'>
                <FormattedMessage id='shipping.shippingInfo' />
              </h3>
            </Col>
          </Row>
          {defaultAddressMarkup}
          {otherAddressesMarkup}
          {noAddressesMarkup}
          {addressInvalidMarkup}
          {accountInfoMarkup}
          {accountInfoInvalidMarkup}
        </CardBody>
      </Card>
    </Fragment>
  );
};

ShippingInfo.propTypes = {
  onEditAddressClick: PropTypes.func,
  onAddAddressClick: PropTypes.func,
  onEditProfileClick: PropTypes.func,
};

export default ShippingInfo;
