import { Button, Col, Dropdown, Modal, ModalContent, ModalFooter, ModalHeader, Row, Text } from '@met/react-components';
import Enumerable from 'linq';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { productsActions } from '../../../../store';
import { useServiceCenterMappingContext } from './ServiceCenterMappingContext';
import { ServiceCenterMappingModalBody } from '../styled';

const ServiceCenterMappingModal = ({ hidden, toggle }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    addMode,
    deleteMode,
    currentMapping,
    mappingMode,
    serviceCenters,
    loadingServiceCenters,
    serviceSkuMappings,
    deletingServiceSkuMapping,
    addingServiceSkuMapping,
  } = useServiceCenterMappingContext();

  const [serviceCentersOptions, setServiceCentersOptions] = useState([]);
  const [selectedServiceCenter, setSelectedServiceCenter] = useState(null);
  const [invalidServiceCenterMessage, setInvalidServiceCenterMessage] = useState('');

  useEffect(() => {
    if (serviceCenters && serviceCenters.length > 0) {
      setServiceCentersOptions(createServiceCentersOptions());
    }
  }, [serviceCenters]);

  useEffect(() => {
    if (selectedServiceCenter !== null) {
      setInvalidServiceCenterMessage('');
    }
  }, [selectedServiceCenter]);

  useEffect(() => {
    if (hidden) {
      setSelectedServiceCenter(null);
      setInvalidServiceCenterMessage('');
    }
  }, [hidden]);

  const createServiceCentersOptions = () => {
    let serviceCentersOptions = [];

    if (serviceCenters && serviceCenters.length > 0) {
      serviceCentersOptions = serviceCenters.map((serviceCenter, index) => {
        return {
          label: serviceCenter.description,
          value: serviceCenter.id,
        };
      });
    }

    return serviceCentersOptions;
  };

  const handleDeleteClick = () => {
    dispatch(productsActions.deleteServiceSkuMapping(currentMapping.serviceCenterId, currentMapping.sku))
      .then(() => {
        toggle();
        toast.success(
          intl.formatMessage({ id: 'admin.product.serviceCenterMappings.serviceCenterModal.deleteSuccess' })
        );
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.product.serviceCenterMappings.serviceCenterModal.deleteError' }));
      });
  };

  const handleAddClick = () => {
    if (selectedServiceCenter === null) {
      // No service center selected
      setInvalidServiceCenterMessage(
        intl.formatMessage({ id: 'admin.product.serviceCenterMappings.serviceCenterModal.pleaseSelect' })
      );
    } else {
      let mappedServiceCenterIds = Enumerable.from(serviceSkuMappings)
        .select(x => x.serviceCenterId)
        .toArray();
      if (mappedServiceCenterIds.includes(selectedServiceCenter.value)) {
        // Service center mapping already exists
        setInvalidServiceCenterMessage(
          intl.formatMessage({ id: 'admin.product.serviceCenterMappings.serviceCenterModal.alreadyExists' })
        );
      } else {
        // Service center mapping doesn't exist
        dispatch(productsActions.upsertServiceSkuMapping(selectedServiceCenter.value, currentMapping.sku))
          .then(() => {
            toggle();
            toast.success(
              intl.formatMessage({ id: 'admin.product.serviceCenterMappings.serviceCenterModal.createSuccess' })
            );
          })
          .catch(() => {
            toast.error(
              intl.formatMessage({ id: 'admin.product.serviceCenterMappings.serviceCenterModal.createError' })
            );
          });
      }
    }
  };

  const invalidMarkup = invalidServiceCenterMessage && (
    <Text error className='mt-2'>
      {invalidServiceCenterMessage}
    </Text>
  );

  const deleteMarkup = mappingMode === deleteMode && (
    <Row>
      <Col>
        <p>
          <FormattedMessage
            id='admin.product.serviceCenterMappings.serviceCenterModal.areYouSure'
            values={{ serviceCenter: currentMapping.description, sku: currentMapping.sku }}
          />
        </p>
      </Col>
    </Row>
  );

  const addMarkup = mappingMode === addMode && (
    <Row>
      <Col xs={12} md={6}>
        <Dropdown
          data-test-id='adminServiceCenterMappingModalDropdown'
          options={serviceCentersOptions}
          value={selectedServiceCenter}
          getOptionValue={option => option.value}
          getOptionLabel={option => option.label}
          onChange={obj => setSelectedServiceCenter(obj)}
          label={intl.formatMessage({
            id: 'admin.product.serviceCenterMappings.serviceCenterModal.serviceCenterLabel',
          })}
          loading={loadingServiceCenters}
        />
        {invalidMarkup}
      </Col>
    </Row>
  );

  const deleteButtonMarkup = mappingMode === deleteMode && (
    <Button data-test-id='adminServiceCenterDeleteMappingButton' primary onClick={() => handleDeleteClick()} loading={deletingServiceSkuMapping}>
      {mappingMode}
    </Button>
  );

  const addButtonMarkup = mappingMode === addMode && (
    <Button
      data-test-id='adminServiceCenterAddSKUMapping' 
      primary
      onClick={() => handleAddClick()}
      loading={addingServiceSkuMapping}
      disabled={!selectedServiceCenter}
    >
      {mappingMode}
    </Button>
  );

  return (
    <Modal lg centered hidden={hidden} toggle={toggle}>
      <ModalContent>
        <ModalHeader toggle={toggle}>
          {mappingMode}
          <FormattedMessage id='admin.product.serviceCenterMappings.serviceCenterModal.heading' />
        </ModalHeader>
        <ServiceCenterMappingModalBody>
          {deleteMarkup}
          {addMarkup}
        </ServiceCenterMappingModalBody>
        <ModalFooter>
          <Row>
            <Col end='xs'>
              <div>
                <Button data-test-id='adminServiceCenterSKUMappingCancelButton' secondary onClick={() => toggle()} className='mr-2'>
                  <FormattedMessage id='general.cancel' />
                </Button>
                {deleteButtonMarkup}
                {addButtonMarkup}
              </div>
            </Col>
          </Row>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ServiceCenterMappingModal;
