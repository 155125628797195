import React, { createContext, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const ProductProblemMappingContext = createContext({
  addMode: '',
  deleteMode: '',
  mappingMode: '',
  setMappingMode: () => {},
  currentMapping: null,
  setCurrentMapping: () => {},
  productProblems: [],
  loadingProductProblem: false,
  productProblemMappings: [],
  addingProductProblemMapping: false,
  deletingProductProblemMapping: false,
  selectedProductProblemMappings: [],
  setSelectedProductProblemMappings: () => {},
});

const useProductProblemMappingContext = () => useContext(ProductProblemMappingContext);

const ProductProblemMappingProvider = ({ children }) => {
  const intl = useIntl();

  const addMode = intl.formatMessage({ id: 'admin.product.addMapping' });
  const deleteMode = intl.formatMessage({ id: 'admin.product.deleteMapping' });

  const [mappingMode, setMappingMode] = useState('');
  const [currentMapping, setCurrentMapping] = useState(null);
  const [selectedProductProblemMappings, setSelectedProductProblemMappings] = useState([]);

  const productProblems = useSelector(state => state.productProblems.getProductProblems.productProblems);
  const loadingProductProblems = useSelector(state => state.productProblems.getProductProblems.isLoading);
  const productProblemMappings = useSelector(
    state =>
      state.products.getProduct &&
      state.products.getProduct.product &&
      state.products.getProduct.product.productProblems
  );
  const addingProductProblemMapping = useSelector(state => state.products.upsertProductProblemMapping.isLoading);
  const deletingProductProblemMapping = useSelector(state => state.products.deleteProductProblemMapping.isLoading);

  return (
    <ProductProblemMappingContext.Provider
      value={{
        addMode,
        deleteMode,
        mappingMode,
        setMappingMode,
        currentMapping,
        setCurrentMapping,
        productProblems,
        loadingProductProblems,
        productProblemMappings,
        addingProductProblemMapping,
        deletingProductProblemMapping,
        selectedProductProblemMappings,
        setSelectedProductProblemMappings,
      }}
    >
      {children}
    </ProductProblemMappingContext.Provider>
  );
};

export { ProductProblemMappingContext, useProductProblemMappingContext, ProductProblemMappingProvider };
