import styled from 'styled-components';
import { Card, Col, Row, Text } from '@met/react-components';

export const ImageWrapper = styled.div`
  width: 100%;
  max-width: 100px;
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const RepairItemImage = styled(Col)`
  padding: 0;
`;

export const RepairItemActions = styled(Col)`
  padding: 0;
`;

export const RepairItemAction = styled(Text)`
  margin: 0 1rem;
`;

export const PackagesSummaryCard = styled(Card)`
  height: 100%;
  background-color: ${props => props.theme.colors.gray};
`;

export const PackagesSummaryRow = styled(Row)`
  height: 100%;
`;

export const ShippingInfoSummaryCard = styled(Card)`
  height: 100%;
`;

export const RepairItemsRow = styled(Row)`
  width: 100%;
  margin: 1rem 0;
`;

export const RepairItemDetail = styled.div`
  margin-bottom: 0.5rem;
`;

export const StyledImg = styled.img`
  max-width: 50px;
`;
