import { Col, Text } from '@met/react-components';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CollapseHeaderRow } from './styled';

const DashboardCollapsibleHeader = ({ purchaseOrder, serviceOrderDate }) => {
  let formattedSubmittedDate = () => {
    return moment(serviceOrderDate)
      .local()
      .format('MM/DD/YYYY');
  };

  return (
    <CollapseHeaderRow className='ml-1 p-2'>
      <Col xs='auto'>
        <div className='mr-4'>
          <Text sm bold block>
            <FormattedMessage id='dashboard.submittedLabel' />
          </Text>
          <Text sm block>
            {formattedSubmittedDate()}
          </Text>
        </div>
      </Col>
      <Col>
        <Text sm bold block>
          <FormattedMessage id='dashboard.itemCountLabel' />
        </Text>
        <Text sm block>
          {purchaseOrder.purchaseOrderLines.length}
        </Text>
      </Col>
    </CollapseHeaderRow>
  );
};

export default DashboardCollapsibleHeader;
