export const squareProdUrl = 'https://web.squarecdn.com/v1/square.js';
export const squareSandboxUrl = 'https://sandbox.web.squarecdn.com/v1/square.js';

export const isSquareSandbox = applicationId => {
  return applicationId && applicationId.indexOf('sandbox') >= 0;
};

export const getSquareUrl = applicationId => {
  if (applicationId && isSquareSandbox(applicationId)) {
    // Is sandbox
    return squareSandboxUrl;
  } else if (applicationId && !isSquareSandbox(applicationId)) {
    // Is Prod
    return squareProdUrl;
  } else {
    return '';
  }
};
