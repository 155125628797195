import styled, {css} from "styled-components";
import theme from '../../styled-config'


const lightOrDark = props => {
  if (props.light)
    return css`
      background-color: #ffffff;
    `;
  else if (props.dark)
    return css`
      background-color: #000000;
    `;
}


const background = props => {
  if (props.background && props.background.toLowerCase().includes('http'))
    return css`
      background-image: url('${props.background}');
      background-size: cover;
    `;
  else if (props.background && props.background.startsWith('#'))
    return css`
      background-color: ${props.background};
    `;

  return css``;
};

export const HeroBackdrop = styled.div`
  padding: 2rem 1rem;
  position: relative;
  text-align: center;
  z-index: 1;
  ${props => lightOrDark(props)};

  &:before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    opacity: ${props => props.opacity};
    z-index: -1;
    ${props => background(props)};
  }
`;

export const HeroContent = styled.div`
  width: 100%;
  display: inline-block;
  background: transparent;
  text-align: center;

  @media (min-width: ${theme.responsive.md}) {
    max-width: ${props => props.maxWidth};
  }
`;