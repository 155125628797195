import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import history from '../history';
import { getAiInstrumentationKey } from './sessionStorage';

let reactPlugin = new ReactPlugin();
let appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: getAiInstrumentationKey(),
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {
        debug: false,
        history: history,
      },
    },
  },
});

appInsights.loadAppInsights();

// Per https://github.com/ant-design/ant-design/issues/26621
appInsights.addTelemetryInitializer(envelope => {
  if (envelope && envelope.data.message) {
    if (envelope.data.message.toLowerCase().includes('resizeobserver')) {
      return false;
    }
  }
  return true;
});

const withAppInsights = component => withAITracking(reactPlugin, component);

export { reactPlugin, withAppInsights };
