import { Col, Image, Row, Space, Typography } from 'antd';
import moment from 'moment';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ServiceCard } from '../../../../../../components';
import { useRepairItemsContext } from '../../../RepairItemsContext';
import { ProductImage } from './styled';

const { Text, Title } = Typography;

const ToolInfoHeader = ({ productInfo }) => {
  const {
    serialNumber,
    serialNumberUnreadable,
    manufactureDate,
    purchaseDate,
    proofOfPurchaseIncluded,
    proofOfPurchaseNotIncluded,
  } = useRepairItemsContext();

  const intl = useIntl();

  const serialMarkup = (serialNumber || serialNumberUnreadable) && (
    <Col>
      <div>
        <Title level={4} strong style={{ marginBottom: 0 }}>
          <FormattedMessage id='repairProcess.warrantyInformation.general.serialNumber' />
        </Title>
        <Text data-test-id='toolSerialNumber' data-hj-allow>
          {serialNumberUnreadable
            ? intl.formatMessage({
                id: 'repairProcess.warrantyInformation.general.notLegible',
              })
            : serialNumber}
        </Text>
      </div>
    </Col>
  );

  const manufactureDateMarkup = manufactureDate && (
    <Col>
      <div>
        <Title level={4} strong style={{ marginBottom: 0 }}>
          <FormattedMessage id='repairProcess.warrantyInformation.general.manufactureDate' />
        </Title>
        <Text data-test-id='toolManufacturingDate' data-hj-allow>
          {serialNumberUnreadable
            ? intl.formatMessage({
                id: 'repairProcess.warrantyInformation.general.unknown',
              })
            : manufactureDate
            ? moment(manufactureDate).local().format('MM/DD/YYYY')
            : intl.formatMessage({
                id: 'repairProcess.warrantyInformation.general.unknown',
              })}
        </Text>
      </div>
    </Col>
  );

  const purchaseDateMarkup = (purchaseDate ||
    proofOfPurchaseIncluded ||
    proofOfPurchaseNotIncluded) && (
    <Col>
      <div>
        <Title level={4} strong style={{ marginBottom: 0 }}>
          <FormattedMessage id='repairProcess.warrantyInformation.general.purchaseDate' />
        </Title>
        <Text data-test-id='toolPurchaseDate' data-hj-allow>
          {purchaseDate
            ? moment(purchaseDate).local().format('MM/DD/YYYY')
            : intl.formatMessage({
                id: 'repairProcess.warrantyInformation.general.unknown',
              })}
        </Text>
      </div>
    </Col>
  );

  const warrantyPeriodMarkup = (serialMarkup || purchaseDateMarkup) && (
    <Col>
      <div>
        <Title level={4} strong style={{ marginBottom: 0 }}>
          <FormattedMessage id='repairProcess.warrantyInformation.general.warrantyPeriod' />
        </Title>
        <Text data-test-id='toolWarrantyPeriod'>
          {productInfo?.warrantyPeriod}{' '}
          <FormattedMessage id='repairProcess.warrantyInformation.general.years' />
        </Text>
      </div>
    </Col>
  );

  const warrantyStatusMarkup = (serialMarkup ||
    manufactureDateMarkup ||
    purchaseDateMarkup ||
    warrantyPeriodMarkup) && (
    <Row gutter={[24, 16]}>
      {serialMarkup}
      {manufactureDateMarkup}
      {purchaseDateMarkup}
      {warrantyPeriodMarkup}
    </Row>
  );

  return (
    <Fragment>
      <Title data-test-id='toolWarrantyStatus' level={1} strong>
        <FormattedMessage id='repairProcess.warrantyInformation.toolInfoHeader.yourToolsWarrantyStatus' />
      </Title>
      <ServiceCard>
        <Row justify='center' align='middle' gutter={8}>
          <Col xs={24} md={4} style={{ textAlign: 'center' }}>
            <ProductImage
              src={productInfo?.image}
              alt={productInfo?.sku}
              preview={false}
            />
          </Col>
          <Col xs={24} md={20}>
            <Row>
              <Col>
                <Text>{productInfo?.skuAlias}</Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Title level={3} strong>
                  {productInfo?.description}
                </Title>
              </Col>
            </Row>
            {warrantyStatusMarkup}
          </Col>
        </Row>
      </ServiceCard>
    </Fragment>
  );
};

export default ToolInfoHeader;
