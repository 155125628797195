import { Layout as AntLayout } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { BaseLayout } from './BaseLayout';

const { Content } = AntLayout;

const ContentWrapper = styled(Content)`
  max-width: 1500px;
  height: 100%;
  padding: 1rem 0;
  margin: 2rem auto;
  box-sizing: border-box;
`;

const Layout = props => {
  return (
    <BaseLayout>
      <ContentWrapper>{props.children}</ContentWrapper>
    </BaseLayout>
  );
};

export default Layout;
