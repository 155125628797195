import { Alert, Space, Typography } from 'antd';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InfoGrid, InfoGridItem } from '../../../../components';
import { useContact, useRecycling, useShipping } from '../../../../hooks';

const { Paragraph } = Typography;

const RecycleOrderStatus = ({ callToRecycleFailure }) => {
  const intl = useIntl();
  const { serviceOrderRecycling } = useRecycling();
  const { getEmailLink, getPhoneLink } = useContact();
  const { getTrackingMarkup } = useShipping();
  const messageType = callToRecycleFailure ? 'error' : 'info';

  let message;
  if (callToRecycleFailure) {
    message = <FormattedMessage id='orderDetails.trackingInfoError' />;
  } else {
    message = <FormattedMessage id={`repairDetails.orderInfo.recycleStatus.status${serviceOrderRecycling.status}`} />;
  }

  let actions;
  if (callToRecycleFailure) {
    actions = (
      <Space size='large' wrap>
        {getPhoneLink()}
        {getEmailLink()}
      </Space>
    );
  } else if ([2, 3].includes(serviceOrderRecycling.status)) {
    // C2R boxes in transit or delivered to customer
    const trackingMarkup =
      serviceOrderRecycling.recyclingLines &&
      serviceOrderRecycling.recyclingLines.map(recycleLine => {
        return getTrackingMarkup(
          recycleLine.id,
          serviceOrderRecycling.addressCountryCode,
          recycleLine.outboundTrackingNumber,
          'ups'
        );
      });

    actions = (
      <InfoGrid>
        <InfoGridItem label={intl.formatMessage({ id: 'repairDetails.orderInfo.trackingInfo' })}>
          <Space direction='vertical' size={2}>
            {trackingMarkup}
          </Space>
        </InfoGridItem>
      </InfoGrid>
    );
  } else if ([4].includes(serviceOrderRecycling.status)) {
    // Recycled items in transit
    const trackingMarkup =
      serviceOrderRecycling.recyclingLines &&
      serviceOrderRecycling.recyclingLines.map(recycleLine => {
        return getTrackingMarkup(
          recycleLine.id,
          serviceOrderRecycling.addressCountryCode,
          recycleLine.inboundTrackingNumber,
          'ups'
        );
      });

    actions = (
      <InfoGrid>
        <InfoGridItem label={intl.formatMessage({ id: 'repairDetails.orderInfo.trackingInfo' })}>
          <Space direction='vertical' size={2}>
            {trackingMarkup}
          </Space>
        </InfoGridItem>
      </InfoGrid>
    );
  } else if ([5].includes(serviceOrderRecycling.status)) {
    // Recycled items delivered to C2R
    actions = (
      <Space size='large' wrap>
        {getPhoneLink()}
        {getEmailLink()}
      </Space>
    );
  } else if ([6].includes(serviceOrderRecycling.status)) {
    // Replacement items in transit to customer
    const trackingMarkup = getTrackingMarkup(
      serviceOrderRecycling.id,
      serviceOrderRecycling.addressCountryCode,
      serviceOrderRecycling.replacementTrackingNumber
    );

    actions = (
      <InfoGrid>
        <InfoGridItem label={intl.formatMessage({ id: 'repairDetails.orderInfo.trackingInfo' })}>
          <Space direction='vertical' size={2}>
            {trackingMarkup}
          </Space>
        </InfoGridItem>
      </InfoGrid>
    );
  } else if ([7].includes(serviceOrderRecycling.status)) {
    // Replacement items delivered to customer
    actions = (
      <Space size='large' wrap>
        {getPhoneLink()}
        {getEmailLink()}
      </Space>
    );
  }

  let description;
  if (callToRecycleFailure) {
    description = <FormattedMessage id='orderDetails.trackingInfoErrorDetail' />;
  } else if (serviceOrderRecycling.status === 2) {
    // Items determined to be recycled, packaging on its way
    description = (
      <div>
        <Paragraph>
          <FormattedMessage id='repairDetails.orderInfo.recycleStatus.status2Details' />
        </Paragraph>
        <Paragraph>
          <FormattedMessage id='repairDetails.orderInfo.recycleStatus.status2DetailsExt1' />
        </Paragraph>
        <Paragraph>
          <FormattedMessage id='repairDetails.orderInfo.recycleStatus.status2DetailsExt2' />
        </Paragraph>
      </div>
    );
  } else if (serviceOrderRecycling.status === 5) {
    // Items delivered to C2R
    description = (
      <div>
        <Paragraph>
          <FormattedMessage id='repairDetails.orderInfo.recycleStatus.status5Details' />
        </Paragraph>
        <Paragraph>
          <FormattedMessage id='repairDetails.orderInfo.recycleStatus.status5DetailsExt1' />
        </Paragraph>
      </div>
    );
  } else {
    description = (
      <FormattedMessage id={`repairDetails.orderInfo.recycleStatus.status${serviceOrderRecycling.status}Details`} />
    );
  }

  let descriptionMarkup = (
    <Space direction='vertical'>
      {description}
      {actions}
    </Space>
  );

  return <Alert type={messageType} message={message} description={descriptionMarkup} />;
};

export default RecycleOrderStatus;
