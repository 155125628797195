const colors = {
  blue: '#17A2B8',
  lightBlue: '#D1ECF1',
  green: '#28A745',
  lightGreen: '#D4EDDA',
  brown: '#A55E01',
  yellow: '#F5A623',
  lightYellow: '#FDEDD3',
  red: '#DB011C',
  lightRed: '#F8D7DA',
  white: '#FFF',
  gray: '#CCCCCC',
  lightGray: '#F2F2F2',
  darkGray: '#9B9B9B',
  darkerGray: '#4A4A4A',
  black: '#000',
  muted: '#6c757d',
};

export { colors };
