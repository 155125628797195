import { Button } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const useShipping = () => {
  const fedexLocationUrl = 'https://www.fedex.com/locate/index.html?locale=en_US#';
  const purolatorLocationUrl = 'https://www.purolator.com/en/shipping/find-shipping-centre';

  const getFedexTrackingUrl = (trackingNumber, countryCode) => {
    return `https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=${trackingNumber}&cntry_code=${countryCode}`;
  };

  const getPurolatorTrackingUrl = trackingNumber => {
    return `https://www.purolator.com/en/shipping/tracker?pins=332947004534${trackingNumber}`;
  };

  const getUpsTrackingUrl = trackingNumber => {
    return `https://www.ups.com/track?trackingNumber=${trackingNumber}`;
  };

  const getProviderLocationMarkup = (countryCode, showAsButton = false) => {
    return countryCode?.toLowerCase() === 'ca' || countryCode?.toLowerCase() === 'can' ? (
      <Button type='primary' href={purolatorLocationUrl} target='_blank' data-test-id='provider-location-link'>
        <FormattedMessage id='orderDetails.findProvider' />
      </Button>
    ) : (
      <Button type='primary' href={fedexLocationUrl} target='_blank' data-test-id='provider-location-link'>
        <FormattedMessage id='orderDetails.findProvider' />
      </Button>
    );
  };

  const getTrackingMarkup = (key, countryCode, trackingNumber, carrier = '') => {
    if (countryCode?.toLowerCase() !== 'ca' && countryCode?.toLowerCase() !== 'can' && carrier === 'ups') {
      return (
        <a href={getUpsTrackingUrl(trackingNumber)} target='_blank' key={key} data-test-id={`tracking-link-${key}`}>
          {trackingNumber}
        </a>
      );
    }

    return countryCode?.toLowerCase() === 'ca' || countryCode?.toLowerCase() === 'can' ? (
      <a href={getPurolatorTrackingUrl(trackingNumber)} target='_blank' key={key} data-test-id={`tracking-link-${key}`}>
        {trackingNumber}
      </a>
    ) : (
      <a
        href={getFedexTrackingUrl(trackingNumber, countryCode)}
        target='_blank'
        key={key}
        data-test-id={`tracking-link-${key}`}
      >
        {trackingNumber}
      </a>
    );
  };

  return {
    fedexLocationUrl,
    purolatorLocationUrl,
    getFedexTrackingUrl,
    getPurolatorTrackingUrl,
    getUpsTrackingUrl,
    getProviderLocationMarkup,
    getTrackingMarkup,
  };
};

export default useShipping;
