import { Col, Button, Form, Row } from 'antd';
import Enumerable from 'linq';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {UserMetadataModal, PageLoaderJr, PageLoader} from '../../components';
import { formatPhoneNumber } from '../../helpers/formatter';
import { useUser, useToggle } from '../../hooks';
import { DetailsContainer, GeneralInput, UserFormItem, CancelButton, DefaultButton } from './styled';
import { Alert, Space } from 'antd';
import {
  getHideMissingAccountNumberNotification,
  setHideMissingAccountNumberNotification,
} from '../../helpers/sessionStorage';
import MissingAccountNumberModal from './components/MissingAccountNumberModal/MissingAccountNumberModal';
import { usersActions } from '../../store';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

const General = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    loadingUser,
    email,
    firstName,
    lastName,
    phoneNumber,
    preferredContactMethod,
    accountNumber,
    preferredLanguage,
    requestedAccountNumber,
  } = useUser();

  const [showUserMetadataModal, toggleUserMetadataModal] = useToggle(false);
  const [preferredUserLanguage, setPreferredUserLanguage] = useState(
    intl.formatMessage({ id: 'profile.preferredLanguage.english' })
  );
  const [showMissingAccountNumberModal, toggleMissingAccountNumberModal] = useToggle(false);
  const [showMissingAccountNumberNotification, setShowMissingAccountNumberNotification] = useState(false);

  const languages = [
    {
      label: intl.formatMessage({ id: 'profile.preferredLanguage.english' }),
      value: 'en-US',
    },
    {
      label: intl.formatMessage({ id: 'profile.preferredLanguage.french' }),
      value: 'fr-CA',
    },
  ];

  useEffect(() => {
    if (!requestedAccountNumber && !getHideMissingAccountNumberNotification() && !accountNumber) {
      setShowMissingAccountNumberNotification(true);
    }
  }, [accountNumber, requestedAccountNumber]);

  useEffect(() => {
    if (preferredLanguage) {
      let lang = Enumerable.from(languages).firstOrDefault(x => x.value === preferredLanguage);
      if (lang) {
        setPreferredUserLanguage(lang.label);
      }
    }
  }, [preferredLanguage]);

  const [form] = Form.useForm();

  const initialValues = {
    email: email,
    firstName: firstName,
    lastName: lastName,
    accountNumber: accountNumber,
    phoneNumber: formatPhoneNumber(phoneNumber),
    preferredContactMethod:
      preferredContactMethod &&
      intl.formatMessage({ id: `profile.preferredContactMethods.label${preferredContactMethod}` }),
    preferredLanguage: preferredUserLanguage,
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  if (loadingUser) {
    return (
      <PageLoaderJr />
    );
  }

  const onDismissMissingAccountNumberNotificationClick = () => {
    setShowMissingAccountNumberNotification(false);
    setHideMissingAccountNumberNotification(true);
  };

  const updateRequestedAccountNumber = form => {
    dispatch(usersActions.updateUserRequestedAccountNumber(form.requestedAccountNumber))
      .then(() => {
        toast.success(intl.formatMessage({ id: 'profile.missingAccountNumberModal.successMessage' }));
        setShowMissingAccountNumberNotification(false);
        setHideMissingAccountNumberNotification(true);
        toggleMissingAccountNumberModal();
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'profile.missingAccountNumberModal.errorMessage' }));
      });
  };

  const missingAccountNumberMarkup = showMissingAccountNumberNotification && (
    <Alert
      message={intl.formatMessage({ id: 'profile.missingAccountNumberNotification.missingAccountNumber' })}
      description={intl.formatMessage({ id: 'profile.missingAccountNumberNotification.missingAccountNumberBody' })}
      type='info'
      action={
        <Space direction='horizontal'>
          <CancelButton
            data-test-id='dismissAccountNumberRequestAlert'
            type='primary'
            onClick={() => onDismissMissingAccountNumberNotificationClick()}
          >
            <FormattedMessage id='general.dismiss' />
          </CancelButton>
          <DefaultButton 
            data-test-id='activateAccountNumberRequestAlert'
            type='danger'
            onClick={() => toggleMissingAccountNumberModal()}
          >
            <FormattedMessage id='general.activate' />
          </DefaultButton>
        </Space>
      }
    />
  );

  return (
    <Fragment>
      {missingAccountNumberMarkup}
      <DetailsContainer>
        <Row>
          <Col flex='auto' sm={8}>
            <Form layout='vertical' form={form} disabled initialValues={initialValues}>
              <UserFormItem name='email' label={intl.formatMessage({ id: 'profile.emailAddressLabel' })}>
                <GeneralInput />
              </UserFormItem>
              <UserFormItem name='firstName' label={intl.formatMessage({ id: 'profile.firstNameLabel' })}>
                <GeneralInput />
              </UserFormItem>
              <UserFormItem name='lastName' label={intl.formatMessage({ id: 'profile.lastNameLabel' })}>
                <GeneralInput />
              </UserFormItem>
              {accountNumber && (
                <UserFormItem name='accountNumber' label={intl.formatMessage({ id: 'profile.accountNumberLabel' })}>
                  <GeneralInput />
                </UserFormItem>
              )}
              <UserFormItem name='phoneNumber' label={intl.formatMessage({ id: 'profile.phoneNumberLabel' })}>
                <GeneralInput />
              </UserFormItem>
              <UserFormItem
                name='preferredContactMethod'
                label={intl.formatMessage({ id: 'profile.preferredContactMethodLabel' })}
              >
                <GeneralInput />
              </UserFormItem>
              <UserFormItem
                name='preferredLanguage'
                label={intl.formatMessage({ id: 'profile.preferredLangaugeLabel' })}
              >
                <GeneralInput />
              </UserFormItem>
            </Form>
          </Col>
        </Row>
        <Row justify={'end'}>
          <Col end='xs'>
            <DefaultButton 
              data-test-id='preferencesGeneralEditButton'
              type='primary'
              onClick={() => toggleUserMetadataModal()}
            >
              <FormattedMessage id='general.edit' />
            </DefaultButton>
          </Col>
        </Row>
      </DetailsContainer>
      <UserMetadataModal
        hidden={!showUserMetadataModal}
        toggle={toggleUserMetadataModal}
        onSaveClick={() => form.resetFields()}
      />
      <MissingAccountNumberModal
        showModal={showMissingAccountNumberModal}
        toggleModal={toggleMissingAccountNumberModal}
        onSaveClick={form => updateRequestedAccountNumber(form)}
      />
    </Fragment>
  );
};

export default General;
