import {
  CLEAR_ADDRESS_VALIDATION,
  CLEAR_ADDRESS_MODAL,
  DELETE_USER_ADDRESS,
  GET_CURRENT_USER,
  GET_MET_USER_TOKEN,
  GET_USER_ADDRESSES,
  GET_USER_ADDRESSES_WITH_DEFAULT,
  GET_USER_EMAILS,
  NAVIGATION,
  SUBMIT_EMAIL_RECOVERY,
  UPDATE_PREFERENCE,
  UPDATE_PREFERRED_CONTACT_METHOD,
  UPDATE_PREFERRED_LANGUAGE,
  UPDATE_USER_DEFAULT_ADDRESS,
  UPSERT_USER,
  UPSERT_USER_ADDRESS,
  UPSERT_USER_EMAIL,
  UPSERT_USER_NOTIFICATIONS,
  UPSERT_USER_TERMS_AND_CONDITIONS,
  VALIDATE_USER_ADDRESS,
  VERIFY_USER_EMAIL,
} from '../types';
import { error, success } from '../utils';
import { setUserMetadata } from '../../helpers/sessionStorage';

export let initialState = {
  getMetUserToken: {
    isLoading: false,
    isError: false,
    token: null,
  },
  getCurrentUser: {
    isLoading: false,
    isError: false,
  },
  user: {},
  upsertUser: {
    isLoading: false,
    isError: false,
  },
  upsertUserTermsAndConditions: {
    isLoading: false,
    isError: false,
  },
  upsertUserNotifications: {
    isLoading: false,
    isError: false,
  },
  getUserEmails: {
    isLoading: false,
    isError: false,
  },
  getUserAddresses: {
    isLoading: true,
    isError: false,
  },
  userEmails: [],
  upsertUserEmail: {
    isLoading: false,
    isError: false,
  },
  userEmail: {},
  submitEmailRecovery: {
    isLoading: false,
    isError: false,
  },
  verifyUserEmail: {
    isLoading: false,
    isError: false,
  },
  validateUserAddress: {
    isLoading: false,
    isError: false,
    sanitizedAddress: {},
    isValidAddress: false,
    validationWarnings: [],
    validationErrors: [],
  },
  upsertUserAddress: {
    isLoading: false,
    isError: false,
  },
  deleteUserAddress: {
    isLoading: false,
    isError: false,
  },
  updatePreferredContactMethod: {
    isLoading: false,
    isError: false,
  },
  updatePreference: {
    isLoading: false,
    isError: false,
  },
  updateUserDefaultAddress: {
    isLoading: false,
    isError: false,
  },
};

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_MET_USER_TOKEN:
      return {
        ...state,
        getMetUserToken: {
          isLoading: false,
          isError: false,
        },
      };
    case success(GET_MET_USER_TOKEN):
      return {
        ...state,
        getMetUserToken: {
          isLoading: false,
          isError: false,
          token: action.payload.data,
        },
      };
    case error(GET_MET_USER_TOKEN):
      return {
        ...state,
        getToken: {
          isLoading: false,
          isError: true,
        },
      };
    case GET_CURRENT_USER:
      return {
        ...state,
        getCurrentUser: {
          isLoading: true,
          isError: false,
        },
      };
    case success(GET_CURRENT_USER):
      return {
        ...state,
        getCurrentUser: {
          isLoading: false,
          isError: false,
        },
        user: action.payload.data,
      };
    case error(GET_CURRENT_USER):
      return {
        ...state,
        getCurrentUser: {
          isLoading: false,
          isError: true,
        },
      };
    case GET_USER_ADDRESSES:
      return {
        ...state,
        getUserAddresses: {
          isLoading: true,
          isError: false,
        },
      };
    case success(GET_USER_ADDRESSES):
      return {
        ...state,
        getUserAddresses: {
          isLoading: false,
          isError: false,
        },
        user: { ...state.user, userAddresses: action.payload.data },
      };
    case error(GET_USER_ADDRESSES):
      return {
        ...state,
        getUserAddresses: {
          isLoading: false,
          isError: true,
        },
      };
    case GET_USER_ADDRESSES_WITH_DEFAULT: {
      return {
        ...state,
        getUserAddresses: {
          isLoading: true,
          isError: false,
        },
      };
    }
    case success(GET_USER_ADDRESSES_WITH_DEFAULT): {
      return {
        ...state,
        getUserAddresses: {
          isLoading: false,
          isError: false,
        },
        user: {
          ...state.user,
          userAddresses: action.payload.data.userAddresses,
          defaultUserAddress: action.payload.data.userDefaultAddress,
        },
      };
    }
    case error(GET_USER_ADDRESSES_WITH_DEFAULT): {
      return {
        ...state,
        getUserAddresses: {
          isLoading: false,
          isError: true,
        },
      };
    }
    case UPSERT_USER:
      return {
        ...state,
        upsertUser: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPSERT_USER):
      return {
        ...state,
        upsertUser: {
          isLoading: false,
          isError: false,
        },
        user: action.payload.data,
      };
    case error(UPSERT_USER):
      return {
        ...state,
        upsertUser: {
          isLoading: false,
          isError: true,
        },
      };
    case UPSERT_USER_TERMS_AND_CONDITIONS:
      return {
        ...state,
        upsertUserTermsAndConditions: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPSERT_USER_TERMS_AND_CONDITIONS):
      return {
        ...state,
        upsertUserTermsAndConditions: {
          isLoading: false,
          isError: false,
        },
        // retain state of user props and then update acceptedTermsAndCondition via action payload
        user: {
          ...state.user,
          acceptedTermsAndConditions: action.accepted,
        },
      };
    case error(UPSERT_USER_TERMS_AND_CONDITIONS):
      return {
        ...state,
        upsertUserTermsAndConditions: {
          isLoading: false,
          isError: true,
        },
      };
    case UPSERT_USER_NOTIFICATIONS:
      return {
        ...state,
        upsertUserNotifications: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPSERT_USER_NOTIFICATIONS):
      return {
        ...state,
        upsertUserNotifications: {
          isLoading: false,
          isError: false,
        },
        user: {
          ...state.user,
          userNotifications: action.userNotifications,
          disableEmailNotifications: action.disableEmail,
          disableSmsNotifications: action.disableSms,
        },
      };
    case error(UPSERT_USER_NOTIFICATIONS):
      return {
        ...state,
        upsertUserNotifications: {
          isLoading: false,
          isError: true,
        },
      };
    case GET_USER_EMAILS:
      return {
        ...state,
        getUserEmails: {
          isLoading: true,
          isError: false,
        },
      };
    case success(GET_USER_EMAILS):
      return {
        ...state,
        getUserEmails: {
          isLoading: false,
          isError: false,
        },
        userEmails: action.payload.data,
      };
    case error(GET_USER_EMAILS):
      return {
        ...state,
        getUserEmails: {
          isLoading: false,
          isError: true,
        },
      };
    case UPSERT_USER_EMAIL:
      return {
        ...state,
        upsertUserEmail: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPSERT_USER_EMAIL):
      return {
        ...state,
        upsertUserEmail: {
          isLoading: false,
          isError: false,
        },
        userEmail: action.payload.data,
      };
    case error(UPSERT_USER_EMAIL):
      return {
        ...state,
        upsertUserEmail: {
          isLoading: false,
          isError: true,
        },
      };
    case SUBMIT_EMAIL_RECOVERY:
      return {
        ...state,
        submitEmailRecovery: {
          isLoading: true,
          isError: false,
        },
      };
    case success(SUBMIT_EMAIL_RECOVERY):
      return {
        ...state,
        submitEmailRecovery: {
          isLoading: false,
          isError: false,
        },
      };
    case error(SUBMIT_EMAIL_RECOVERY):
      return {
        ...state,
        submitEmailRecovery: {
          isLoading: false,
          isError: true,
        },
      };
    case VERIFY_USER_EMAIL:
      return {
        ...state,
        verifyUserEmail: {
          isLoading: true,
          isError: false,
        },
      };
    case success(VERIFY_USER_EMAIL):
      return {
        ...state,
        verifyUserEmail: {
          isLoading: false,
          isError: false,
        },
      };
    case error(VERIFY_USER_EMAIL):
      return {
        ...state,
        verifyUserEmail: {
          isLoading: false,
          isError: true,
        },
      };
    case VALIDATE_USER_ADDRESS:
      return {
        ...state,
        validateUserAddress: {
          isLoading: true,
          isError: false,
        },
      };
    case success(VALIDATE_USER_ADDRESS):
      let validationResponse = action.payload.data;
      return {
        ...state,
        validateUserAddress: {
          isLoading: false,
          isError: false,
          sanitizedAddress: validationResponse.sanitizedAddress,
          isValidAddress: validationResponse.isValid,
          validationWarnings: validationResponse.warnings,
          validationErrors: validationResponse.errors,
        },
      };
    case error(VALIDATE_USER_ADDRESS):
      return {
        ...state,
        validateUserAddress: {
          isLoading: false,
          isError: true,
        },
      };
    case UPSERT_USER_ADDRESS:
      return {
        ...state,
        upsertUserAddress: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPSERT_USER_ADDRESS):
      return {
        ...state,
        upsertUserAddress: {
          isLoading: false,
          isError: false,
        },
      };
    case error(UPSERT_USER_ADDRESS):
      return {
        ...state,
        upsertUserAddress: {
          isLoading: false,
          isError: true,
        },
      };
    case DELETE_USER_ADDRESS:
      return {
        ...state,
        deleteUserAddress: {
          isLoading: true,
          isError: false,
        },
      };
    case success(DELETE_USER_ADDRESS):
      return {
        ...state,
        deleteUserAddress: {
          isLoading: false,
          isError: false,
        },
      };
    case error(DELETE_USER_ADDRESS):
      return {
        ...state,
        deleteUserAddress: {
          isLoading: false,
          isError: true,
        },
      };
    case UPDATE_USER_DEFAULT_ADDRESS:
      return {
        ...state,
        updateUserDefaultAddress: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPDATE_USER_DEFAULT_ADDRESS):
      return {
        ...state,
        updateUserDefaultAddress: {
          isLoading: false,
          isError: false,
        },
      };
    case error(UPDATE_USER_DEFAULT_ADDRESS):
      return {
        ...state,
        updateUserDefaultAddress: {
          isLoading: false,
          isError: true,
        },
      };
    case CLEAR_ADDRESS_MODAL:
      return {
        ...state,
        validateUserAddress: {
          isLoading: false,
          isError: false,
          sanitizedAddress: {},
          isValidAddress: false,
          validationWarnings: [],
          validationErrors: [],
        },
      };
    case UPDATE_PREFERRED_CONTACT_METHOD:
      return {
        ...state,
        updatePreferredContactMethod: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPDATE_PREFERRED_CONTACT_METHOD):
      return {
        ...state,
        updatePreferredContactMethod: {
          isLoading: false,
          isError: false,
        },
        user: {
          ...state.user,
          preferredContactMethod: action.preferredContactMethod,
        },
      };
    case error(UPDATE_PREFERRED_CONTACT_METHOD):
      return {
        ...state,
        updatePreferredContactMethod: {
          isLoading: false,
          isError: true,
        },
      };
    case UPDATE_PREFERRED_LANGUAGE:
      return {
        ...state,
        updatePreferredLanguage: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPDATE_PREFERRED_LANGUAGE):
      return {
        ...state,
        updatePreferredLanguage: {
          isLoading: false,
          isError: false,
        },
        user: {
          ...state.user,
          preferredLanguage: action.preferredLanguage,
        },
      };
    case error(UPDATE_PREFERRED_LANGUAGE):
      return {
        ...state,
        updatePreferredLanguage: {
          isLoading: false,
          isError: true,
        },
      };
    case UPDATE_PREFERENCE:
      return {
        ...state,
        updatePreference: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPDATE_PREFERENCE):
      let metadata = {
        firstName: action.payload.data.firstName,
        lastName: action.payload.data.lastName,
        phoneNumber: action.payload.data.phoneNumber,
      };
      setUserMetadata(metadata);
      return {
        ...state,
        updatePreference: {
          isLoading: false,
          isError: false,
        },
        user: {
          ...state.user,
          preferredContactMethod: action.payload.data.preferredContactMethod,
          preferredLanguage: action.payload.data.preferredLanguage,
        },
      };
    case error(UPDATE_PREFERENCE):
      return {
        ...state,
        updatePreference: {
          isLoading: false,
          isError: true,
        },
      };
    case CLEAR_ADDRESS_VALIDATION:
      return {
        ...state,
        validateUserAddress: {
          isLoading: false,
          isError: false,
          sanitizedAddress: {},
          isValidAddress: false,
          validationWarnings: [],
          validationErrors: [],
        },
      };
    case NAVIGATION:
      //NOTE: To maintain state of user in store, we have to return the state
      //      not the initial state as some reducers do.
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default reducer;
