import React from "react";
import {LogoWrapper, InnerSpinner, OuterSpinner, PageLoaderWrapper, SpinContainer} from './styled';
import {ReactComponent as Logo} from "../../assets/logo-milwaukeetool.svg";

const PageLoader = () => {
  return (
    <PageLoaderWrapper>
      <SpinContainer>
        <InnerSpinner/>
        <OuterSpinner/>
        <LogoWrapper>
          <Logo width={120}/>
        </LogoWrapper>
      </SpinContainer>
    </PageLoaderWrapper>
  );
};

export default PageLoader;
