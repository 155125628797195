import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { usersActions } from '../store';

const useAddresses = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const validateAddress = (line1, line2, city, state, countryCode, postalCode) => {
    dispatch(usersActions.validateUserAddress(line1, line2, city, state, countryCode, postalCode)).catch(() => {
      toast.error(intl.formatMessage({ id: 'address.sanitizationError' }));
    });
  };

  return {
    defaultUserAddress: useSelector(state => state.users.user.defaultUserAddress),
    userAddresses: useSelector(state => state.users.user.userAddresses),
    countries: useSelector(state => state.countries.getCountries.countries),
    loadingCountries: useSelector(state => state.countries.getCountries.isLoading),
    states: useSelector(state => state.states.getStates.states),
    loadingStates: useSelector(state => state.states.getStates.isLoading),
    validatingAddress: useSelector(state => state.users.validateUserAddress.isLoading),
    sanitizedAddress: useSelector(state => state.users.validateUserAddress.sanitizedAddress),
    addressIsValid: useSelector(state => state.users.validateUserAddress.isValidAddress),
    addressValidationWarnings: useSelector(state => state.users.validateUserAddress.validationWarnings),
    addressValidationErrors: useSelector(state => state.users.validateUserAddress.validationErrors),
    savingAddress: useSelector(state => state.users.upsertUserAddress.isLoading),
    validateAddress,
  };
};

export default useAddresses;
