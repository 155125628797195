import { Grid, Space, Typography } from 'antd';
import React from 'react';
import { PizzaSpace, StepIconWrapper } from './styled';

const { useBreakpoint } = Grid;
const { Text } = Typography;

const PizzaTracker = ({ steps, currentId }) => {
  const breakpoints = useBreakpoint();

  const stepsMarkup =
    steps &&
    steps.map(step => {
      if (step.isVisible === undefined) {
        step.isVisible = true;
      }

      if (step.isVisible !== false) {
        return (
          <PizzaSpace
            direction={!breakpoints.md ? 'horizontal' : 'vertical'}
            key={`pizza-step-${step.id}`}
            size='middle'
            align='center'
          >
            <StepIconWrapper isComplete={step.id <= currentId} isActive={step.id === currentId}>
              {step.icon}
            </StepIconWrapper>
            <Text>{step.label}</Text>
          </PizzaSpace>
        );
      }
    });

  return (
    <Space direction={!breakpoints.md ? 'vertical' : 'horizontal'} size='large'>
      {stepsMarkup}
    </Space>
  );
};

export default PizzaTracker;
