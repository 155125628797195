import { Button, Card, CardBody, Col, Grid, Row, useToggle } from '@met/react-components';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { MoreInformation, ProductSearchModal } from '../../../components';
import { useLocale } from '../../../hooks';
import { downloadUrl } from '../../../helpers/downloadBlob';
import { handleNavigateAway } from '../../../helpers/navigation';
import { docsActions } from '../../../store';
import RepairItemsList from '../components/RepairItemsList';
import { useRepairProcessContext } from '../RepairProcessContext';
import { AddAnotherRow } from './styled';
import UsShippingRestrictionWarning from '../components/UsShippingRestrictionWarning';
import CaShippingRestrictionWarning from '../components/CaShippingRestrictionWarning';
import { ShippingInstructionModal } from '../../../components/ShippingInstructionModal';
import Enumerable from 'linq';

export const AddAnother = () => {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { isEnLocale } = useLocale();

  const { repairItems } = useRepairProcessContext();

  const [showSearchModal, toggleSearchModal] = useToggle(false);
  const [showProductShippingInstruction, toggleShowProductShippingInstruction] = useToggle(false);

  const isUsLocale = isEnLocale();

  const handleContinueClick = () => {
    if(Enumerable.from(repairItems).any(x => x.shippingInstructions.length > 0)) {
      toggleShowProductShippingInstruction();
    } else {
      history.push('/repairprocess/shipping');
    }
  };

  const handleDownloadTermsConditions = () => {
    dispatch(docsActions.downloadTermsAndConditions())
      .then(response => {
        downloadUrl('termsconditions.pdf', response.data.data);
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'home.authenticatedContent.termsAndConditionsDownloadFailed' }), {
          autoClose: false,
        });
      });
  };

  const restrictionMarkup = isUsLocale ? (
    <Card>
      <UsShippingRestrictionWarning />
    </Card>
  ) : (
    <Card>
      <CaShippingRestrictionWarning />
    </Card>
  );

  return (
    <Fragment>
      <Grid>
        <Row>
          <Col xs={12} md={8}>
            <AddAnotherRow center='xs'>
              <Col center='xs'>
                <h2>
                  <FormattedMessage id='addAnother.addAnotherTitle' />
                </h2>
                <Button data-test-id='multiToolAddAnotherToolButton' lg primary onClick={toggleSearchModal} icon='plus'>
                  <FormattedMessage id='general.addTool' />
                </Button>
              </Col>
            </AddAnotherRow>
            <AddAnotherRow center='xs'>
              <Col md={6}>
                <MoreInformation handleDownloadTermsConditions={handleDownloadTermsConditions} />
              </Col>
            </AddAnotherRow>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody>
                <RepairItemsList handleContinueClick={handleContinueClick} showButtons />
                <ShippingInstructionModal hidden={!showProductShippingInstruction} toggle={toggleShowProductShippingInstruction}/>
              </CardBody>
            </Card>
            {restrictionMarkup}
          </Col>
        </Row>
      </Grid>
      <ProductSearchModal hidden={!showSearchModal} toggle={toggleSearchModal} />
      <Prompt
        message={location =>
          handleNavigateAway(
            location,
            repairItems.length > 0,
            intl.formatMessage({ id: 'general.unsavedChangesWarning' })
          )
        }
      />
    </Fragment>
  );
};

export default AddAnother;
