import React, { createContext, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const ProductIncludedMappingContext = createContext({
  addMode: '',
  deleteMode: '',
  mappingMode: '',
  setMappingMode: () => {},
  currentMapping: null,
  setCurrentMapping: () => {},
  productInclusions: [],
  loadingProductInclusions: false,
  productInclusionMappings: [],
  addingProductInclusionMapping: false,
  deletingProductInclusionMapping: false,
  refreshingProductInclusions: false,
});

const useProductIncludedMappingContext = () => useContext(ProductIncludedMappingContext);

const ProductIncludedMappingProvider = ({ children }) => {
  const intl = useIntl();

  const addMode = intl.formatMessage({ id: 'admin.product.addMapping' });
  const deleteMode = intl.formatMessage({ id: 'admin.product.deleteMapping' });

  const [mappingMode, setMappingMode] = useState('');
  const [currentMapping, setCurrentMapping] = useState(null);

  const productInclusions = useSelector(state => state.productIncluded.getProductInclusions.productInclusions);
  const loadingProductInclusions = useSelector(state => state.productIncluded.getProductInclusions.isLoading);
  const productInclusionMappings = useSelector(
    state =>
      state.products.getProduct &&
      state.products.getProduct.product &&
      state.products.getProduct.product.productIncludedMappings
  );
  const addingProductInclusionMapping = useSelector(state => state.products.upsertProductIncludedMapping.isLoading);
  const deletingProductInclusionMapping = useSelector(state => state.products.deleteProductIncludedMapping.isLoading);
  const refreshingProductInclusions = useSelector(state => state.products.refreshProductInclusions.isLoading);

  return (
    <ProductIncludedMappingContext.Provider
      value={{
        addMode,
        deleteMode,
        mappingMode,
        setMappingMode,
        currentMapping,
        setCurrentMapping,
        productInclusions,
        loadingProductInclusions,
        productInclusionMappings,
        addingProductInclusionMapping,
        deletingProductInclusionMapping,
        refreshingProductInclusions,
      }}
    >
      {children}
    </ProductIncludedMappingContext.Provider>
  );
};

export { ProductIncludedMappingContext, useProductIncludedMappingContext, ProductIncludedMappingProvider };
