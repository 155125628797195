import { PageLoader } from '@met/react-components';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { PageLoaderWrapper } from '../../components/Layout/BaseLayout/styled';
import { usersActions } from '../../store';

const VerifyEmail = props => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(usersActions.verifyUserEmail(props.match.params.token))
      .then(() => {
        history.push('/login');
        toast.success(intl.formatMessage({ id: 'verifyEmail.updateEmailSuccessMessage' }));
      })
      .catch(er => {
        let messageId = 'verifyEmail.verifyErrorMessage';
        if (er.response.status === 400) messageId = 'verifyEmail.verifyErrorMessageBadRequest';
        toast.error(intl.formatMessage({ id: messageId }), {
          autoClose: false,
        });
      });
  }, [dispatch, intl]);

  return (
    <PageLoaderWrapper>
      <PageLoader />
    </PageLoaderWrapper>
  );
};

export default VerifyEmail;
