import { GET_STATES } from '../types';
import { error, success } from '../utils';

export let initialState = {
  getStates: {
    isLoading: false,
    isError: false,
    states: [],
  },
};

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_STATES:
      return {
        ...state,
        getStates: {
          isLoading: true,
          isError: false,
          states: [],
        },
      };
    case success(GET_STATES):
      return {
        ...state,
        getStates: {
          isLoading: false,
          isError: false,
          states: action.payload.data,
        },
      };
    case error(GET_STATES):
      return {
        ...state,
        getStates: {
          isLoading: false,
          isError: true,
          states: [],
        },
      };
    default:
      return state;
  }
};

export default reducer;
