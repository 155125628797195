import { Button, Modal, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { productsActions } from '../../../../store';
import { useProductProblemMappingContext } from './ProductProblemMappingContext';

const { Text } = Typography;

const ProductProblemBulkDeleteModal = ({ hidden, toggle, deleteConfirm }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { currentMapping, productProblems, selectedProductProblemMappings } = useProductProblemMappingContext();

  const [deletionList, setDeletionList] = useState([]);

  useEffect(() => {
    if (!hidden) {
      createDeletionList();
    }
  }, [toggle]);

  const createDeletionList = () => {
    if (
      productProblems &&
      productProblems.length > 0 &&
      selectedProductProblemMappings &&
      selectedProductProblemMappings.length > 0
    ) {
      setDeletionList(
        selectedProductProblemMappings.map(
          (x, index) => productProblems.find((problem) => problem.id === x)?.description
        )
      );
    }
  };

  const handleDeleteClick = () => {
    if (selectedProductProblemMappings && selectedProductProblemMappings.length > 0 && currentMapping.sku) {
      dispatch(productsActions.deleteBulkProductProblems(selectedProductProblemMappings, currentMapping.sku))
        .then(() => {
          deleteConfirm();
          toggle();
          toast.success(
            intl.formatMessage({ id: 'admin.product.productProblemMappings.productProblemModal.deleteSuccess' })
          );
        })
        .catch(() => {
          toast.error(
            intl.formatMessage({ id: 'admin.product.productProblemMappings.productProblemModal.deleteError' })
          );
        });
    }
  };

  const bodyContent = (
    <React.Fragment>
      <Text strong>
        <FormattedMessage
          id='admin.product.productProblemMappings.productProblemModal.listTitle'
          values={{ sku: currentMapping?.sku }}
        />
      </Text>
      <ul style={{ marginTop: 10 }}>
        {deletionList.map((x, index) => {
          return <li key={'problem-' + index}>{x}</li>;
        })}
      </ul>
    </React.Fragment>
  );
  return (
    <Modal
      data-test-id='productProblemModal'
      title={
        intl.formatMessage({ id: 'general.delete' }) +
        intl.formatMessage({ id: 'admin.product.productProblemMappings.productProblemModal.heading' })
      }
      open={!hidden}
      onCancel={toggle}
      footer={[
        <Button
          data-test-id='adminProductProblemsDeleteModalCancelButton'
          key='cancelBtn'
          onClick={() => toggle()}
          className='mr-2'
        >
          <FormattedMessage id='general.cancel' />
        </Button>,
        <Button
          data-test-id='adminProductProblemsDeleteModalDeleteButton'
          key='deleteBtn'
          type={'primary'}
          onClick={() => handleDeleteClick()}
        >
          <FormattedMessage id='general.delete' />
        </Button>,
      ]}
      destroyOnClose
    >
      {bodyContent}
    </Modal>
  );
};

export default ProductProblemBulkDeleteModal;
