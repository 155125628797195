import { getSiteLocale } from '../helpers/locale';
import { getLocale } from '../helpers/sessionStorage';

const useLocale = () => {
  const isEnLocale = () => {
    return getSiteLocale().includes('com');
  };

  const isCaLocale = () => {
    return getSiteLocale().includes('ca');
  };

  const getCurrentLocale = () => {
    const currentLocale = getSiteLocale();

    switch (true) {
      case currentLocale.includes('com'):
        return 'US';
      case currentLocale.includes('ca'):
        return 'CA';
      case currentLocale.includes('au'):
        return 'AU';
      default:
        return 'US';
    }
  };
  const languages = [
    {
      id: 'profile.preferredLanguage.english',
      value: 'en-US',
    },
    {
      id: 'profile.preferredLanguage.french',
      value: 'fr-CA',
    },
  ];
  return {
    isEnLocale,
    isCaLocale,
    getCurrentLocale,
    languages,
    storedLocale: () => getLocale(),
  };
};

export default useLocale;
