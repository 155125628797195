import { Button, Form, Modal, Popconfirm, Select } from 'antd';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useAddresses } from '../../../../hooks';
import { serviceCenterActions } from '../../../../store';

const { Option } = Select;

const StateMappingModal = ({ hidden, stateMapping, onFormSubmit, onCancel, onDelete, isNew }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { states } = useAddresses();

  //Refs
  const formRef = useRef();

  const serviceCenters = useSelector((state) => state.serviceCenters.getServiceCenters.serviceCenters);

  useEffect(() => {
    if (!serviceCenters) {
      dispatch(serviceCenterActions.getServiceCenters()).catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.serviceOrders.loadingServiceOrderStatusesErrorMessage' }), {
          autoClose: false,
        });
      });
    }
  }, [serviceCenters]);

  useEffect(() => {
    if (formRef?.current) {
      formRef.current.setFieldsValue({
        state: stateMapping?.state,
        serviceCenterId: stateMapping?.serviceCenterId,
      });
    }
  }, [stateMapping, formRef]);

  const stateOptions =
    states &&
    states.map((item, index) => {
      return (
        <Option key={index} value={item.abbreviation}>
          {item.name}
        </Option>
      );
    });

  const serviceCenterOptions =
    serviceCenters &&
    serviceCenters.map((item, index) => {
      return (
        <Option key={index} value={item.id}>
          {item.description}
        </Option>
      );
    });

  return (
    <Modal
      title={
        isNew ? (
          <FormattedMessage id='admin.stateMapping.addModalTitle' />
        ) : (
          <FormattedMessage id='admin.stateMapping.editModalTitle' />
        )
      }
      open={hidden}
      closable={true}
      onOk={() => formRef?.current?.submit()}
      onCancel={onCancel}
      footer={[
        <Button data-test-id='editAdminStateMappingCancelButton' key='back' onClick={() => onCancel()}>
          Cancel
        </Button>,
        !isNew && (
          <Popconfirm
            key='delete'
            title='Are you sure?'
            onConfirm={() => onDelete(stateMapping.state)}
            okText={<FormattedMessage id='general.yes' />}
            cancelText={<FormattedMessage id='general.no' />}
          >
            <Button
              data-test-id='editAdminStateMappingDeleteButton'
              style={{ marginLeft: '3px' }}
              key={'deleteBtn'}
              type='danger'
            >
              <FormattedMessage id='general.delete' />
            </Button>
          </Popconfirm>
        ),
        <Button
          data-test-id='editAdminStateMappingSubmitButton'
          key='submit'
          type='primary'
          onClick={() => formRef.current.submit()}
        >
          <FormattedMessage id='general.submit' />
        </Button>,
      ]}
      width={800}
    >
      <Form
        name='stateMappingForm'
        onFinish={onFormSubmit}
        autoComplete='off'
        hideRequiredMark={false}
        layout={'vertical'}
        ref={formRef}
      >
        <Form.Item
          name='state'
          label={<FormattedMessage id='address.stateLabel' />}
          rules={[{ required: true, message: <FormattedMessage id='general.required' /> }]}
        >
          <Select optionFilterProp='children' showSearch disabled={!isNew}>
            <Option value=''>{<FormattedMessage id='general.pleaseSelect' />}</Option>
            {stateOptions}
          </Select>
        </Form.Item>
        <Form.Item
          name='serviceCenterId'
          label={<FormattedMessage id='admin.stateMapping.serviceCenterLabel' />}
          rules={[{ required: true, message: <FormattedMessage id='general.required' /> }]}
        >
          <Select optionFilterProp='children' showSearch>
            <Option value=''>{<FormattedMessage id='general.pleaseSelect' />}</Option>
            {serviceCenterOptions}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StateMappingModal;
