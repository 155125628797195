import { Button, Col, Dropdown, Modal, ModalContent, ModalFooter, ModalHeader, Row, Text } from '@met/react-components';
import Enumerable from 'linq';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { productsActions } from '../../../../store';
import { useProductIncludedMappingContext } from './ProductIncludedMappingContext';
import { ProductIncludedMappingModalBody } from '../styled';

const ProductIncludedMappingModal = ({ hidden, toggle }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    addMode,
    deleteMode,
    mappingMode,
    currentMapping,
    productInclusions,
    loadingProductInclusions,
    productInclusionMappings,
    addingProductInclusionMapping,
    deletingProductInclusionMapping,
  } = useProductIncludedMappingContext();

  const [productInclusionsOptions, setProductInclusionOptions] = useState([]);
  const [selectedProductInclusion, setSelectedProductInclusion] = useState(null);
  const [invalidProductInclusionMessage, setInvalidProductInclusionMessage] = useState('');

  useEffect(() => {
    if (productInclusions && productInclusions.length > 0) {
      setProductInclusionOptions(createProductInclusionOptions());
    }
  }, [productInclusions]);

  useEffect(() => {
    if (selectedProductInclusion !== null) {
      setInvalidProductInclusionMessage('');
    }
  }, [selectedProductInclusion]);

  useEffect(() => {
    if (hidden) {
      setSelectedProductInclusion(null);
      setInvalidProductInclusionMessage('');
    }
  }, [hidden]);

  const createProductInclusionOptions = () => {
    let productInclusionsOptions = [];

    if (productInclusions && productInclusions.length > 0) {
      productInclusionsOptions = productInclusions.map((productInclusions, index) => {
        return {
          label: productInclusions.description,
          value: productInclusions.id,
        };
      });
    }

    return productInclusionsOptions;
  };

  const handleDeleteClick = () => {
    dispatch(productsActions.deleteProductIncludedMapping(currentMapping.includedId, currentMapping.sku))
      .then(() => {
        toggle();
        toast.success(
          intl.formatMessage({ id: 'admin.product.productInclusionMappings.productInclusionModal.deleteSuccess' })
        );
      })
      .catch(() => {
        toast.error(
          intl.formatMessage({ id: 'admin.product.productInclusionMappings.productInclusionModal.deleteError' })
        );
      });
  };

  const handleAddClick = () => {
    if (selectedProductInclusion === null) {
      setInvalidProductInclusionMessage(
        intl.formatMessage({ id: 'admin.product.productInclusionMappings.productInclusionModal.pleaseSelect' })
      );
    } else {
      let mappedProductInclusionIds = Enumerable.from(productInclusionMappings)
        .select(x => x.includedId)
        .toArray();
      if (mappedProductInclusionIds.includes(selectedProductInclusion.value)) {
        setInvalidProductInclusionMessage(
          intl.formatMessage({ id: 'admin.product.productInclusionMappings.productInclusionModal.alreadyExists' })
        );
      } else {
        dispatch(productsActions.upsertProductIncludedMapping(selectedProductInclusion.value, currentMapping.sku))
          .then(() => {
            toggle();
            toast.success(
              intl.formatMessage({ id: 'admin.product.productInclusionMappings.productInclusionModal.createSuccess' })
            );
          })
          .catch(() => {
            toast.error(
              intl.formatMessage({ id: 'admin.product.productInclusionMappings.productInclusionModal.createError' })
            );
          });
      }
    }
  };

  const invalidMarkup = invalidProductInclusionMessage && (
    <Text error className='mt-2'>
      {invalidProductInclusionMessage}
    </Text>
  );

  const deleteMarkup = mappingMode === deleteMode && (
    <Row>
      <Col>
        <p>
          <FormattedMessage
            id='admin.product.productInclusionMappings.productInclusionModal.areYouSure'
            values={{ inclusion: currentMapping.description, sku: currentMapping.sku }}
          />
        </p>
      </Col>
    </Row>
  );

  const addMarkup = mappingMode === addMode && (
    <Row>
      <Col xs={12} md={6}>
        <Dropdown
          data-test-id='adminProductIncludedMappingModalDropdown'
          options={productInclusionsOptions}
          value={selectedProductInclusion}
          getOptionValue={option => option.value}
          getOptionLabel={option => option.label}
          onChange={obj => setSelectedProductInclusion(obj)}
          label={intl.formatMessage({
            id: 'admin.product.productInclusionMappings.productInclusionModal.productInclusionLabel',
          })}
          loading={loadingProductInclusions}
        />
        {invalidMarkup}
      </Col>
    </Row>
  );

  const deleteButtonMarkup = mappingMode === deleteMode && (
    <Button data-test-id='adminProductIncludedDeleteButton' primary onClick={() => handleDeleteClick()} loading={deletingProductInclusionMapping}>
      {mappingMode}
    </Button>
  );

  const addButtonMarkup = mappingMode === addMode && (
    <Button
      data-test-id='adminProductIncludedSyncingInclusionsButton' 
      primary
      onClick={() => handleAddClick()}
      loading={addingProductInclusionMapping}
      disabled={!selectedProductInclusion}
    >
      {mappingMode}
    </Button>
  );

  return (
    <Modal lg centered hidden={hidden} toggle={toggle}>
      <ModalContent>
        <ModalHeader toggle={toggle}>
          {mappingMode}
          <FormattedMessage id='admin.product.productInclusionMappings.productInclusionModal.heading' />
        </ModalHeader>
        <ProductIncludedMappingModalBody>
          {deleteMarkup}
          {addMarkup}
        </ProductIncludedMappingModalBody>
        <ModalFooter>
          <Row>
            <Col end='xs'>
              <div>
                <Button
                  data-test-id='adminProductIncludedMappingModalCancelButton' 
                  secondary
                  onClick={() => toggle()}
                  className='mr-2'
                  loading={addingProductInclusionMapping || deletingProductInclusionMapping}
                >
                  <FormattedMessage id='general.cancel' />
                </Button>
                {deleteButtonMarkup}
                {addButtonMarkup}
              </div>
            </Col>
          </Row>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProductIncludedMappingModal;
