import createAxiosAction from '../createAxiosAction';
import { GET_NOTIFICATION_HISTORY_BY_SERVICE_ORDER, GET_NOTIFICATION_TYPES, GET_USER_NOTIFICATION_HISTORY } from '../types';

export default {
  getNotificationTypes: () => {
    return createAxiosAction({
      url: `/api/v1/notifications/_actions/getNotificationTypes`,
      startAction: GET_NOTIFICATION_TYPES,
    });
  },
  getUserNotificationHistory: email => {
    return createAxiosAction({
      url: `/api/v1/notifications/history/?email=${email}`,
      method: 'GET',
      startAction: GET_USER_NOTIFICATION_HISTORY,
    });
  },
  getNotificationHistoryByServiceOrder: serviceOrderId => {
    return createAxiosAction({
      url: `/api/v1/notifications/historybyserviceorder/?id=${serviceOrderId}`,
      method: 'GET',
      startAction: GET_NOTIFICATION_HISTORY_BY_SERVICE_ORDER,
    });
  }
};
