import { ListGroup } from '@met/react-components';
import React from 'react';
import { DashboardDeniedContentLine } from './index';

const DashboardDeniedContentList = ({ purchaseOrderLines }) => {
  return (
    <ListGroup>
      {purchaseOrderLines.map(purchaseOrderLine => (
        <DashboardDeniedContentLine
          key={`DeniedPurchaseOrderLine-${purchaseOrderLine.sku}-${purchaseOrderLine.serviceOrderNumber}`}
          purchaseOrderLine={purchaseOrderLine}
          hashedServiceOrderId={purchaseOrderLine.serviceOrderId}
        />
      ))}
    </ListGroup>
  );
};

export default DashboardDeniedContentList;
