import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Icon,
  Link,
  Loader,
  Row,
  Text,
  useToggle,
} from '@met/react-components';
import Enumerable from 'linq';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { AddressModal } from '../../../../components/AddressModal';
import { NotFound } from '../../../../components/PageError';
import { downloadBlob } from '../../../../helpers/downloadBlob';
import { useServiceOrder } from '../../../../hooks';
import { countriesActions, serviceOrdersActions, statesActions } from '../../../../store';
import ShippingDetails from './ShippingDetails';
import OrderDetails from './OrderDetails';
import ServiceOrderLine from './ServiceOrderLine';
import CommunicationHistory from './CommunicationHistory';
import DeniedWarranty from "./DeniedWarranty";
import ContactInfo from "./ContactInfo";

const ServiceOrder = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { generateIssuesList, generateWarningsList } = useServiceOrder();

  const pdfDownloadingToast = useRef(null);

  const { id } = useParams();

  const loadingServiceOrder = useSelector(state => state.serviceOrders.getServiceOrder.isLoading);
  const resubmittingShippingLabel = useSelector(state => state.serviceOrders.resubmitSubmitServiceOrderLabel.isLoading);
  const serviceOrder = useSelector(state => state.serviceOrders.getServiceOrder.data);
  const generatingPaperwork = useSelector(state => state.serviceOrders.getServiceOrdersPdf.isLoading);

  const [showAddressModal, toggleAddressModal] = useToggle(false);

  const [notFound, setNotFound] = useState(false);
  const [primaryTool, setPrimaryTool] = useState({});
  const [otherTools, setOtherTools] = useState([]);
  const [issues, setIssues] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [pdfRequested, setPdfRequested] = useState(false);
  const [currentAddress, setCurrentAddress] = useState({});
  const [isAx, setIsAx] = useState(false);

  useEffect(() => {
    if (id) {
      getServiceOrder();
    }
  }, [id]);
  
  useEffect(() => {
    dispatch(countriesActions.getCountries()).catch(() => {
      toast.error(intl.formatMessage({ id: 'address.loadingCountriesErrorMessage' }), { autoClose: false });
    });
    dispatch(statesActions.getStates()).catch(() => {
      toast.error(intl.formatMessage({ id: 'address.loadingStatesErrorMessage' }), { autoClose: false });
    });
  }, [dispatch]);

  useEffect(() => {
    if (serviceOrder && serviceOrder.serviceOrderLines) {
      let lines = serviceOrder.serviceOrderLines;
      let explicitPrimaryTool = Enumerable.from(lines).firstOrDefault(x => x.isPrimaryTool);

      setPrimaryTool(explicitPrimaryTool || lines[0]);
      setOtherTools(
        explicitPrimaryTool
          ? Enumerable.from(lines)
              .where(x => !x.isPrimaryTool)
              .toArray()
          : lines.slice(1, lines.length)
      );

      setIssues(generateIssuesList(serviceOrder));
      setWarnings(generateWarningsList(serviceOrder));
    }

    if (serviceOrder) {
      if (serviceOrder.serviceOrderGroupId) {
        setIsAx(false);
      } else {
        setIsAx(true);
      }
      var address = {
        id: 1000,
        name: serviceOrder.addressName,
        addressLine1: serviceOrder.addressLine1,
        addressLine2: '',
        city: serviceOrder.city,
        state: serviceOrder.state,
        countryCode: serviceOrder.countryCode,
        postalCode: serviceOrder.postalCode,
        addressIsResidential: serviceOrder.addressIsResidential,
      };
      setCurrentAddress(address);
    }
  }, [serviceOrder]);

  useEffect(() => {
    if (serviceOrder?.trackingNumber && serviceOrder?.serviceOrderGroupId) {
      if (pdfRequested) {
        printPaperwork();
      }
    }
  }, [serviceOrder, pdfRequested]);

  const printPaperwork = () => {
    setPdfRequested(false);

    dispatch(serviceOrdersActions.getServiceOrdersPdf(serviceOrder.serviceOrderGroupId))
      .then(response => {
        downloadBlob(`${serviceOrder.serviceOrderGroupId}.pdf`, response.data);
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'orderSummary.pdfDownloadError' }));
      })
      .finally(() => {
        toast.dismiss(pdfDownloadingToast.current);
      });
  };

  const handlePrintPaperworkClick = () => {
    setPdfRequested(true);
    pdfDownloadingToast.current = toast.info(intl.formatMessage({ id: 'orderSummary.pdfDownloading' }), {
      autoClose: false,
    });
  };

  const getServiceOrder = () => {
    dispatch(serviceOrdersActions.getServiceOrder(id)).catch(payload => {
      if (payload.response.status === 404) {
        setNotFound(true);
      } else {
        toast.error(intl.formatMessage({ id: 'admin.serviceOrder.loadingError' }));
      }
    });
  };

  const resubmitShippingLabel = () => {
    dispatch(serviceOrdersActions.resubmitServiceOrderLabel(serviceOrder.id))
      .then(payload => {
        if (payload.data.data.trackingNumber) {
          toast.success(intl.formatMessage({ id: 'admin.serviceOrder.resubmitLabelSuccess' }), { autoClose: 5000 });
        } else if (payload.data.id) {
          toast.error(intl.formatMessage({ id: 'admin.serviceOrder.resubmitLabelRefresh' }), { autoClose: 5000 });
        } else {
          toast.error(intl.formatMessage({ id: 'admin.serviceOrder.resubmitLabelFailure' }), { autoClose: 5000 });
        }
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.serviceOrder.resubmitLabelFailure' }), { autoClose: 5000 });
      });
  };

  const handleCancelAddressClick = () => {
    toggleAddressModal();
  };

  const handleConfirmAddressEditClick = (
    id,
    defaultShippingAddress,
    addressLine1,
    addressLine2,
    city,
    state,
    countryCode,
    postalCode,
    addressName,
    addressIsResidential,
    latitude,
    longitude
  ) => {
    serviceOrder.addressLine1 = addressLine1;
    serviceOrder.AddressName = addressName;
    serviceOrder.city = city;
    serviceOrder.state = state;
    serviceOrder.PostalCode = postalCode;
    serviceOrder.countryCode = countryCode;
    dispatch(serviceOrdersActions.updateServiceOrderAddress(serviceOrder))
      .then(() => {
        toggleAddressModal();
        toast.success(intl.formatMessage({ id: 'address.addressUpdated' }), { autoClose: 5000 });
      })
      .catch(error => {
        toast.error(intl.formatMessage({ id: 'address.saveChangesError' }), { autoClose: 5000 });
      });
  };

  if (notFound) {
    return <NotFound />;
  }

  if (!serviceOrder || loadingServiceOrder) {
    return <Loader type='pulse' />;
  }

  const primaryToolMarkup = primaryTool && (
    <Card>
      <CardBody>
        <Row>
          <Col>
            <h3 className='mb-0'>
              <FormattedMessage id='admin.serviceOrder.primaryToolHeading' />
            </h3>
          </Col>
        </Row>
        <ServiceOrderLine
          sku={primaryTool.sku}
          description={primaryTool.description}
          serialNumber={primaryTool.serialNumber}
          isDeniedWarranty={primaryTool.isDeniedWarranty}
          updatedAt={primaryTool.updatedAt}
        />
      </CardBody>
    </Card>
  );

  const otherToolsList =
    otherTools.length > 0 &&
    otherTools.map((tool, index) => {
      return (
        <ServiceOrderLine
          sku={tool.sku}
          description={tool.description}
          serialNumber={tool.serialNumber}
          isDeniedWarranty={tool.isDeniedWarranty}
          updatedAt={tool.updatedAt}
          key={`service-order-line-${index}`}
        />
      );
    });

  const otherToolsMarkup = otherToolsList && (
    <Card>
      <CardBody>
        <Row>
          <Col>
            <h3 className='mb-0'>
              <FormattedMessage id='admin.serviceOrder.otherItemsHeading' />
            </h3>
          </Col>
        </Row>
        {otherToolsList}
      </CardBody>
    </Card>
  );

  const problemsListItems =
    serviceOrder && serviceOrder.problemDescriptionList && serviceOrder.problemDescriptionList.length > 0 ? (
      serviceOrder.problemDescriptionList.map((problem, index) => {
        return <li key={`problem-${index}`}>{problem}</li>;
      })
    ) : (
      <li>
        <FormattedMessage id='general.none' />
      </li>
    );

  let includedListItems =
    serviceOrder && serviceOrder.included && serviceOrder.included.length > 0 ? (
      serviceOrder.included.split(', ').map((included, index) => {
        return <li key={`included-${index}`}>{included}</li>;
      })
    ) : (
      <li>
        <FormattedMessage id='general.none' />
      </li>
    );

  const issuesList =
    issues &&
    issues.map((issue, index) => {
      return (
        <Row key={`issue-${index}`} className='mt-1'>
          <Col xs='auto' middle='xs'>
            <Icon type='times-circle' danger sm />
          </Col>
          <Col middle='xs'>{issue}</Col>
        </Row>
      );
    });

  const warningsList =
    warnings &&
    warnings.map((warning, index) => {
      return (
        <Row key={`warning-${index}`} className='mt-1'>
          <Col xs='auto' middle='xs'>
            <Icon type='exclamation-circle' warning sm />
          </Col>
          <Col middle='xs'>{warning}</Col>
        </Row>
      );
    });

  const issuesAndWarningsMarkup = (issuesList.length > 0 || warningsList.length > 0) && (
    <Card>
      <CardBody>
        <Row>
          <Col xs={12}>
            <h3>
              <FormattedMessage id='admin.serviceOrder.issuesAndWarningsLabel' />
            </h3>
          </Col>
          <Col>
            {issuesList}
            {warningsList}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );

  const downloadMarkup = serviceOrder && serviceOrder.trackingNumber && (
    <Row>
      <Col xs={12}>
        <div>
          <Button data-test-id='adminOrderPanelPrintPaperworkButton' primary onClick={() => handlePrintPaperworkClick()} loading={generatingPaperwork || pdfRequested}>
            <FormattedMessage id='orderSummary.printPaperwork' />
          </Button>
        </div>
      </Col>
    </Row>
  );
  
  const deniedWarrantyMarkup = serviceOrder && (serviceOrder.warrantyOption || serviceOrder.status === 10) && (
    <DeniedWarranty
      serviceOrder={serviceOrder}
    />
  );
  
  return (
    <Fragment>
      <Row>
        <Col>
          <h1>
            <FormattedMessage id='admin.heading' />
          </h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to='/admin/serviceorders'>Service Orders Admin</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{id}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
        <Col xs={12}>{issuesAndWarningsMarkup}</Col>
        <Col xs={12}>
          <ContactInfo
            name={serviceOrder.addressName}
            email={serviceOrder.emailAddress}
            phone={serviceOrder.phoneNumber}
          />
        </Col>
        <Col xs={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <Col xs={12}>
                      <h3>
                        <FormattedMessage id='admin.serviceOrder.orderDetailsHeading' />
                      </h3>
                    </Col>
                    <OrderDetails
                      submittedAt={serviceOrder.serviceOrderDate}
                      serviceOrderNumber={serviceOrder.serviceOrderNumber}
                      accountNumber={serviceOrder.accountNumber}
                      status={serviceOrder.status}
                    />
                  </Row>
                </Col>
                <Col xs={12} md={6} className='mt-3 mt-md-0'>
                  <Row>
                    <Col xs={12}>
                      <h3>
                        <FormattedMessage id='admin.serviceOrder.shipmentInfoHeading' />
                      </h3>
                    </Col>
                    <ShippingDetails
                      name={serviceOrder.addressName}
                      line1={serviceOrder.addressLine1}
                      city={serviceOrder.city}
                      state={serviceOrder.state}
                      countryCode={serviceOrder.countryCode}
                      postalCode={serviceOrder.postalCode}
                      trackingNumber={serviceOrder.trackingNumber}
                      shippoStatus={serviceOrder.shippoStatus}
                      numberOfPackages={serviceOrder.numberOfPackages}
                      resubmitShippingLabel={() => resubmitShippingLabel()}
                      toggleAddressModal={() => toggleAddressModal()}
                      loadingServiceOrder={loadingServiceOrder}
                      resubmittingShippingLabel={resubmittingShippingLabel}
                      isAx={isAx}
                    />
                  </Row>
                  {downloadMarkup}
                </Col>
                <Col xs={12} className='mt-3'>
                  <Row>
                    <Col xs={12}>
                      <h3>
                        <FormattedMessage id='admin.serviceOrder.toolInfoHeading' />
                      </h3>
                    </Col>
                    <Col xs={12} sm={4} md={3}>
                      <Text bold>
                        <FormattedMessage id='admin.serviceOrder.toolProblemsLabel' />
                      </Text>
                      <ul className='mb-0'>{problemsListItems}</ul>
                    </Col>
                    <Col xs={12} sm={4} md={3} className='mt-2 mt-sm-0'>
                      <Text bold>
                        <FormattedMessage id='admin.serviceOrder.includedItemsLabel' />
                      </Text>
                      <ul className='mb-0'>{includedListItems}</ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12}>
          {deniedWarrantyMarkup}
        </Col>
        <Col xs={12}>
          {primaryToolMarkup}
          {otherToolsMarkup}
        </Col>
        <CommunicationHistory serviceOrder={serviceOrder}/>
        <AddressModal
          hidden={!showAddressModal}
          toggle={toggleAddressModal}
          address={currentAddress}
          showDeleteAddressView={false}
          showDefaultAddressView={false}
          onCancelClick={handleCancelAddressClick}
          onDeleteClick={() => {}}
          onSetDefaultClick={() => {}}
          onSaveChangesClick={handleConfirmAddressEditClick}
        />
      </Row>
    </Fragment>
  );
};

export default ServiceOrder;
