import { Modal, ModalBody, ModalContent, ModalHeader, Grid } from '@met/react-components';
import React from 'react';
import { StyledCode } from './styled';
import { FormattedMessage } from 'react-intl';

const SearchHelpModal = props => {
  let searchHelpModalContent = (
    <div>
      <div>
        <FormattedMessage id='dashboard.searchHelp.txt01' />
        <FormattedMessage id='dashboard.searchHelp.rtxt1' />
        <StyledCode>hammer drill</StyledCode>
        <FormattedMessage id='dashboard.searchHelp.rtxt2' />
        <StyledCode>hammer</StyledCode> AND <StyledCode>drill</StyledCode>.
      </div>
      <div>
        <FormattedMessage id='dashboard.searchHelp.txt02' />
      </div>
      <ul>
        <li>
          <strong>
            <FormattedMessage id='dashboard.searchHelp.txt03' /> (<StyledCode>|</StyledCode>)
          </strong>{' '}
          <FormattedMessage id='dashboard.searchHelp.rtxt1' />
          <StyledCode>hammer|drill</StyledCode>
          <FormattedMessage id='dashboard.searchHelp.rtxt2' />
          <StyledCode>hammer</StyledCode> OR <StyledCode>drill</StyledCode>
        </li>
        <li>
          <strong>
            <FormattedMessage id='dashboard.searchHelp.txt04' /> (<StyledCode>-</StyledCode>)
          </strong>{' '}
          <FormattedMessage id='dashboard.searchHelp.rtxt1' /> <StyledCode>hammer -drill</StyledCode>
          <FormattedMessage id='dashboard.searchHelp.txt05' /> <StyledCode>hammer</StyledCode>
          <FormattedMessage id='dashboard.searchHelp.txt06' />
          <StyledCode>drill</StyledCode>
        </li>
        <li>
          <strong>
            <FormattedMessage id='dashboard.searchHelp.txt07' /> (<StyledCode>*</StyledCode>)
          </strong>{' '}
          <FormattedMessage id='dashboard.searchHelp.rtxt1' />
          <StyledCode>impact*</StyledCode>
          <FormattedMessage id='dashboard.searchHelp.txt08' />
          <StyledCode>impact</StyledCode>
        </li>
        <li>
          <strong>
            <FormattedMessage id='dashboard.searchHelp.txt09' /> (<StyledCode>""</StyledCode>)
          </strong>{' '}
          <FormattedMessage id='dashboard.searchHelp.rtxt1' />
          <StyledCode>"m12 impact"</StyledCode>
          <FormattedMessage id='dashboard.searchHelp.txt10' />
          <StyledCode>"m12 impact"</StyledCode>
          <FormattedMessage id='dashboard.searchHelp.txt11' />
        </li>
      </ul>
    </div>
  );

  return (
    <Grid>
      <Modal centered hidden={props.hidden} toggle={props.toggle}>
        <ModalContent>
          <ModalHeader toggle={props.toggle}>Search Tips</ModalHeader>
          <ModalBody>
            <div>{searchHelpModalContent}</div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Grid>
  );
};

export default SearchHelpModal;
