import { Button, Text } from '@met/react-components';
import styled from 'styled-components';

export const AddressText = styled(Text)`
  white-space: nowrap;

  @media (max-width: ${props => props.theme.screenSize.md}) {
    white-space: normal;
  }
`;

export const EditButton = styled(Button)`
  max-width: 20rem;
`;

export const AddAnotherWrapper = styled(Text)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${props => props.theme.colors.red};
`;
