import { useEffect, useState } from 'react';

const useSquarePaymentFormConfig = (applicationId, locationId) => {
  const [state, setState] = useState({
    configured: false,
    sqPaymentFormConfig: {},
  });

  useEffect(() => {
    if (!applicationId) {
      setState({
        configured: false,
        sqPaymentFormConfig: {},
      });
      return;
    }

    // Define base config
    const baseConfig = {
      autoBuild: false,
      inputClass: 'sq-input',
      inputStyles: [
        {
          fontSize: '16px',
          fontFamily: 'Segoe UI',
          placeholderColor: '#B5BBC1',
          color: '#212529',
          backgroundColor: 'transparent',
        },
      ],
      cardNumber: {
        elementId: 'sq-card-number',
        placeholder: 'Card Number',
      },
      cvv: {
        elementId: 'sq-cvv',
        placeholder: 'CVV',
      },
      expirationDate: {
        elementId: 'sq-expiration-date',
        placeholder: 'MM/YY',
      },
      postalCode: {
        elementId: 'sq-postal-code',
        placeholder: 'ZIP',
      },
    };

    // Add applicationId (required)
    baseConfig.applicationId = applicationId;

    // Add locationId (optional)
    if (locationId) {
      baseConfig.locationId = locationId;
    }

    setState({
      configured: true,
      sqPaymentFormConfig: baseConfig,
    });
  }, [applicationId, locationId]);

  return [state.configured, state.sqPaymentFormConfig];
};

export default useSquarePaymentFormConfig;
