import React from 'react';
import { useIntl } from 'react-intl';
import PageError from '../PageError';

const ServerError = () => {
  const intl = useIntl();

  return (
    <PageError
      title={intl.formatMessage({ id: 'pageError.serverError.title' })}
      subtitle={intl.formatMessage({ id: 'pageError.serverError.subtitle' })}
    />
  );
};

export default ServerError;
