import { Button, Form, Modal, Popconfirm, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useAddresses } from '../../../../hooks';
import { serviceCenterActions } from '../../../../store';

const { Option } = Select;

const DefaultServiceCenterModal = ({ hidden, defaultServiceCenter, onFormSubmit, onCancel }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { countries } = useAddresses();

  //Refs
  const formRef = useRef();

  const serviceCenters = useSelector((state) => state.serviceCenters.getServiceCenters.serviceCenters);

  const [serviceCenterFiltered, setServiceCenterFiltered] = useState([]);
  useEffect(() => {
    if (!serviceCenters) {
      dispatch(serviceCenterActions.getServiceCenters()).catch(() => {
        toast.error(intl.formatMessage({ id: 'admin.serviceOrders.loadingServiceOrderStatusesErrorMessage' }), {
          autoClose: false,
        });
      });
    }
  }, [serviceCenters]);

  useEffect(() => {
    if (formRef?.current) {
      formRef.current.setFieldsValue({
        countryCode: defaultServiceCenter?.countryCode,
        serviceCenterId: defaultServiceCenter?.serviceCenterId,
      });
    }

    if (defaultServiceCenter && serviceCenters) {
      let sc = serviceCenters.filter((x) => x.countryCode === defaultServiceCenter.countryCode && x.isActive);

      setServiceCenterFiltered(sc);
    }
  }, [defaultServiceCenter, formRef]);

  const countryOptions =
    countries &&
    countries.map((item, index) => {
      return (
        <Option data-test-id={'countryOption-' + index} key={'countryOption-' + index} value={item.iso3}>
          {item.name}
        </Option>
      );
    });

  const serviceCenterOptions =
    serviceCenterFiltered &&
    serviceCenterFiltered.map((item, index) => {
      return (
        <Option data-test-id={'serviceCenter-' + index} key={index} value={item.id}>
          {item.description}
        </Option>
      );
    });

  return (
    <Modal
      data-test-id='defaultServiceCenterModal'
      title={intl.formatMessage({ id: 'admin.defaultServiceCenterMapping.editModalTitle' })}
      open={hidden}
      closable={true}
      onOk={() => formRef?.current?.submit()}
      onCancel={onCancel}
      footer={[
        <Button key='back' onClick={() => onCancel()} data-test-id='defaultServiceCenterMappingCancelBtn'>
          Cancel
        </Button>,
        <Popconfirm
          data-test-id='defaultServiceCenterMappingPopConfirm'
          key='submitConfirm'
          title={intl.formatMessage({ id: 'general.areYouSure' })}
          onConfirm={() => formRef.current.submit()}
          okText={intl.formatMessage({ id: 'general.yes' })}
          cancelText={intl.formatMessage({ id: 'general.no' })}
          cancelButtonProps={{ 'data-test-id': 'cancelBtn', style: { height: 'auto' } }}
          okButtonProps={{ 'data-test-id': 'yesBtn', style: { height: 'auto' } }}
        >
          <Button data-test-id='adminDefaultServiceCenterSubmitButton' key='submit' type='primary'>
            <FormattedMessage id='general.submit' />
          </Button>
        </Popconfirm>,
      ]}
      width={800}
    >
      <Form
        name='stateMappingForm'
        onFinish={onFormSubmit}
        autoComplete='off'
        hideRequiredMark={false}
        layout={'vertical'}
        ref={formRef}
      >
        <Form.Item
          name='countryCode'
          label={intl.formatMessage({ id: 'admin.serviceCenter.countryCodeLabel' })}
          rules={[{ required: true, message: intl.formatMessage({ id: 'general.required' }) }]}
        >
          <Select data-test-id='countryCodes' optionFilterProp='children' showSearch disabled={true}>
            <Option value=''>{<FormattedMessage id='general.pleaseSelect' />}</Option>
            {countryOptions}
          </Select>
        </Form.Item>
        <Form.Item
          name='serviceCenterId'
          label={intl.formatMessage({ id: 'admin.stateMapping.serviceCenterLabel' })}
          rules={[{ required: true, message: intl.formatMessage({ id: 'general.required' }) }]}
        >
          <Select data-test-id='serviceCenters' optionFilterProp='children' showSearch>
            <Option value=''>{<FormattedMessage id='general.pleaseSelect' />}</Option>
            {serviceCenterOptions}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DefaultServiceCenterModal;
