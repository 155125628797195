import { GET_PRODUCT_INCLUDED, REFRESH_PRODUCT_INCLUDED, UPSERT_PRODUCT_INCLUDED } from '../types';

import { error, success } from '../utils';

export let initialState = {
  getProductInclusions: {
    isLoading: false,
    isError: false,
    productInclusions: [],
  },
  upsertProductInclusions: {
    isLoading: false,
    isError: false,
  },
  refreshProductInclusions: {
    isLoading: false,
    isError: false,
  },
};

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_PRODUCT_INCLUDED:
      return {
        ...state,
        getProductInclusions: {
          isLoading: true,
          isError: false,
          productInclusions: [],
        },
      };
    case success(GET_PRODUCT_INCLUDED):
      return {
        ...state,
        getProductInclusions: {
          isLoading: false,
          isError: false,
          productInclusions: action.payload.data || [],
        },
      };
    case error(GET_PRODUCT_INCLUDED):
      return {
        ...state,
        getProductInclusions: {
          isLoading: false,
          isError: true,
          productInclusions: [],
        },
      };
    case UPSERT_PRODUCT_INCLUDED:
      return {
        ...state,
        upsertProductInclusions: {
          isLoading: true,
          isError: false,
        },
      };
    case success(UPSERT_PRODUCT_INCLUDED):
      let productInclusions = state.getProductInclusions.productInclusions.filter(x => x.id !== action.payload.data.id);
      return {
        ...state,
        upsertProductInclusions: {
          isLoading: false,
          isError: false,
        },
        getProductInclusions: {
          ...state.getProductInclusions,
          productInclusions: [...productInclusions, action.payload.data],
        },
      };
    case error(UPSERT_PRODUCT_INCLUDED):
      return {
        ...state,
        upsertProductInclusions: {
          isLoading: false,
          isError: true,
        },
      };
    case REFRESH_PRODUCT_INCLUDED:
      return {
        ...state,
        refreshProductInclusions: {
          isLoading: true,
          isError: false,
        },
      };
    case success(REFRESH_PRODUCT_INCLUDED):
      return {
        ...state,
        refreshProductInclusions: {
          isLoading: false,
          isError: false,
        },
        getProductInclusions: {
          isLoading: false,
          isError: false,
          productInclusions: action.payload.data.updatedInclusions || [],
        },
      };
    case error(REFRESH_PRODUCT_INCLUDED):
      return {
        ...state,
        refreshProductInclusions: {
          isLoading: false,
          isError: true,
        },
      };
    default:
      return state;
  }
};

export default reducer;
