import { useAuth0 } from '@auth0/auth0-react';
import { Checkbox, Col, message, Row, Switch } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useUser } from '../../hooks';
import { notificationsActions, usersActions } from '../../store';
import { usePreferencesContext } from './PreferencesContext';
import { DetailsContainer, NotificationTypesList } from './styled';
import { PageLoaderJr } from '../../components';

const Notifications = () => {
  const intl = useIntl();
  const { loadingUser, user } = useUser();
  const dispatch = useDispatch();

  const {
    notificationTypes,
    loadingNotificationTypes,
    upsertingUserNotification,
    userNotifications,
    disableEmailNotifications,
  } = usePreferencesContext();
  const { isAuthenticated } = useAuth0();

  const [notifications, setNotifications] = useState([]);
  const [disableEmail, setDisableEmail] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(notificationsActions.getNotificationTypes()).catch(() => {
        toast.error(intl.formatMessage({ id: 'profile.loadingErrorMessage' }), {
          autoClose: false,
        });
      });
      if (!user.userNotifications) {
        dispatch(usersActions.getCurrentUser());
      }
    }
  }, [dispatch, intl]);

  useEffect(() => {
    if (userNotifications) {
      setNotifications(userNotifications);
    }

    if (disableEmailNotifications) {
      setDisableEmail(disableEmailNotifications);
    }
  }, [userNotifications, disableEmailNotifications]);

  if (loadingUser || loadingNotificationTypes) {
    return (
      <PageLoaderJr />
    );
  }

  const handleDisableEmail = disabled => {
    setDisableEmail(disabled);

    dispatch(usersActions.upsertUserNotifications(userNotifications, disabled, null))
      .then(() => {
        message.success(intl.formatMessage({ id: 'profile.saveChangesSuccessMessage' }));
      })
      .catch(() => {
        message.error(intl.formatMessage({ id: 'profile.saveChangesErrorMessage' }));
      });
  };

  const handleEmailNotificationClick = (id, isActive) => {
    let index = userNotifications?.findIndex(x => x.notificationType === id);
    let updatedUserNotifications;

    if (index > -1) {
      updatedUserNotifications = [
        ...userNotifications.filter(x => x.notificationType !== id),
        {
          notificationType: id,
          receiveEmail: isActive,
        },
      ];
    } else {
      updatedUserNotifications = [
        ...userNotifications,
        {
          notificationType: id,
          receiveEmail: isActive,
        },
      ];
    }

    setNotifications(updatedUserNotifications);

    dispatch(usersActions.upsertUserNotifications(updatedUserNotifications, disableEmail, null))
      .then(() => {
        message.success(intl.formatMessage({ id: 'profile.saveChangesSuccessMessage' }));
      })
      .catch(() => {
        message.error(intl.formatMessage({ id: 'profile.saveChangesErrorMessage' }));
      });
  };

  const emailNotificationsList =
    notificationTypes &&
    notificationTypes.map((notificationType, index) => {
      let notification = notifications?.filter(x => x.notificationType === notificationType.key);
      let isActive = notification.length > 0 ? notification[0].receiveEmail : true;

      return (
        <li key={`email-notifications-${index}`} style={{ margin: '.4em 0', display: 'flex' }}>
          <Switch
            data-test-id={`emailNotificationsSwitch-${index}`}
            checked={!disableEmail && isActive}
            onChange={(checked, event) => handleEmailNotificationClick(notificationType.key, checked)}
            disabled={disableEmail || upsertingUserNotification}
          />
          <div style={{ marginLeft: '.5em' }}>
            <FormattedMessage id={`profile.notifications.label${notificationType.value}`} />
          </div>
        </li>
      );
    });

  return (
    <DetailsContainer>
      <Row>
        <Col>
          <div className='mb-2'>
            <FormattedMessage id='profile.emailNotificationsHeader' />
          </div>
          <NotificationTypesList>{emailNotificationsList}</NotificationTypesList>
          <Checkbox
            data-test-id='disableAllNotificationsCheckbox'
            checked={disableEmail}
            onChange={event => handleDisableEmail(event.target.checked)}
            style={{ marginTop: '.5em' }}
          >
            <FormattedMessage id='profile.disableAll' />
          </Checkbox>
        </Col>
      </Row>
    </DetailsContainer>
  );
};

Notifications.propTypes = {
  saveChanges: PropTypes.func,
};

export default Notifications;
