import createAxiosAction from '../createAxiosAction';
import { PARSE_SERIAL_NUMBER } from '../types';

export default {
  parseSerialNumber: (serialNumber, isBattery) => {
    return createAxiosAction({
      url: `/api/v1/serialNumber/parse/?serialNumber=${serialNumber}&isBattery=${isBattery}`,
      startAction: PARSE_SERIAL_NUMBER,
      method: 'GET',
    });
  },
};
