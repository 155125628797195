import {
  Col,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Row,
  Text,
  TextInput,
} from '@met/react-components';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ButtonWrapper, StyledButton } from '../styled';

const ProductIncludedModal = ({
  hidden,
  toggle,
  inclusion,
  onCancelClick,
  onSaveChangesClick,
  inclusions,
  upsertingInclusion,
}) => {
  const intl = useIntl();
  const [description, setDescription] = useState('');
  const [descriptionInvalid, setDescriptionInvalid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [inclusionValidationErrors, setInclusionValidationErrors] = useState([]);

  const isValid = () => {
    let isValid = true;
    setIsDirty(false);
    setDescriptionInvalid(false);
    setInclusionValidationErrors([]);
    if (!description || description.length <= 0) {
      setDescriptionInvalid(true);
      isValid = false;
    }
    if (checkForDuplicateInclusion(inclusion, description, inclusions)) {
      isValid = false;
      setInclusionValidationErrors(['Inclusion description already exists']);
    }
    return isValid;
  };

  const checkForDuplicateInclusion = (currentInclusion, currentDescription, allInclusions) => {
    const regex = /(\s|[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/])/gm; // eslint-disable-line no-useless-escape
    let result = allInclusions.some(x => {
      if (x.id === currentInclusion.id) return false;
      else if (x.description.replace(regex, '').toLowerCase() === currentDescription.replace(regex, '').toLowerCase())
        return true;
      else return false;
    });
    return result;
  };

  const handleSaveChangesClick = () => {
    if (isValid()) {
      onSaveChangesClick(inclusion.id, description);
    }
  };

  useEffect(() => {
    if (inclusion && inclusion.description && inclusion.description.length > 0) setDescription(inclusion.description);
    else setDescription('');
    setIsDirty(false);
  }, [inclusion]);

  useEffect(() => {
    setDescriptionInvalid(false);
    setIsDirty(true);
  }, [description]);

  const inclusionValidationErrorList =
    !isDirty &&
    inclusionValidationErrors &&
    inclusionValidationErrors.length > 0 &&
    inclusionValidationErrors.map((error, index) => {
      return (
        <Row key={`validation-error-${index}`}>
          <Col>
            <Text bold error>
              {error}
            </Text>
          </Col>
        </Row>
      );
    });

  const inclusionValidationErrorMarkup = inclusionValidationErrorList && (
    <Fragment>{inclusionValidationErrorList}</Fragment>
  );

  const editProductIncludedModal = inclusion && (
    <Fragment>
      <ModalHeader toggle={toggle}>
        {inclusion.id ? (
          <FormattedMessage id='admin.included.editInclusionModalTitle' />
        ) : (
          <FormattedMessage id='admin.included.addInclusionModalTitle' />
        )}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <TextInput
              data-test-id='adminProductIncludedModalDescriptionRequiredText'
              label={intl.formatMessage({ id: 'admin.included.descriptionLabel' })}
              value={description}
              onChange={e => setDescription(e.target.value)}
              invalid={descriptionInvalid}
              errorMessage={intl.formatMessage({ id: 'admin.included.descriptionRequired' })}
            />
          </Col>
        </Row>
        {inclusionValidationErrorMarkup}
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col end='xs'>
            {/* eslint-disable-next-line test-selectors/button */}
            <ButtonWrapper>
              <StyledButton data-test-id='adminProductIncludedModalCancelButton' type='secondary' onClick={() => onCancelClick()} loading={upsertingInclusion}>
                <FormattedMessage id='general.cancel' />
              </StyledButton>
              <StyledButton
                type='primary'
                onClick={handleSaveChangesClick}
                data-test-id='save-changes-button'
                loading={upsertingInclusion}
              >
                <FormattedMessage id='general.submit' />
              </StyledButton>
            </ButtonWrapper>
          </Col>
        </Row>
      </ModalFooter>
    </Fragment>
  );

  return (
    <Modal md centered hidden={hidden} toggle={toggle}>
      <ModalContent data-test-id='productIncluded-modal-content'>{editProductIncludedModal}</ModalContent>
    </Modal>
  );
};
ProductIncludedModal.propTypes = {
  hidden: PropTypes.bool,
  toggle: PropTypes.func,
  inclusion: PropTypes.object,
  onCancelClick: PropTypes.func,
  onSaveChangesClick: PropTypes.func,
  inclusions: PropTypes.array,
};
export default ProductIncludedModal;
