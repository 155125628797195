import createAxiosAction from '../createAxiosAction';
import { GET_LMR_CATEGORY_TYPES } from '../types';

export default {
  getLmrCategoryTypes: () => {
    return createAxiosAction({
      method: 'GET',
      url: `api/v1/lmrCategoryTypes`,
      startAction: GET_LMR_CATEGORY_TYPES,
    });
  },
};
