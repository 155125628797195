import { Button, Col, Modal, Row, Typography } from 'antd';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { CloseOutlined } from '@ant-design/icons';
import { Text, useToggle } from '@met/react-components';
import { Battery9Img, DamagedBatteryimg } from '../ProductDetail/styled';
import { useFeatureToggles, useLocale } from '../../../../../hooks';

const { Paragraph } = Typography;

const BatteryEvaluationModal = ({
  selectedProduct,
  showBatteryModal,
  toggleBatteryModal,
  setIsRecycled,
  batterySafetyLink,
  includedBatteryCancelClick,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { isCaLocale } = useLocale();

  const isCa = isCaLocale();

  const [showBatteryDamageMarkup, toggleBatteryDamageMarkup] = useToggle(true);
  const [showBatteryDamageNotEligibleMarkup, toggleBatteryDamageNotEligibleMarkup] = useToggle(false);
  const [showBattery9Markup, toggleBattery9Markup] = useToggle(false);
  const [showIneligibleBatteryMarkup, toggleIneligibleBatteryMarkup] = useToggle(false);
  const [showBatteryShippingNoticeMarkup, toggleBatteryShippingNoticeMarkup] = useToggle(false);
  const [showMxFBatteryShippingNoticeMarkup, toggleMxFBatteryShippingNoticeMarkup] = useToggle(false);
  const { batteryRecycle } = useFeatureToggles();

  const findServiceCenterLink = isCa ? (
    <a
      data-test-id='findAServiceCenterLinkCA'
      href='https://www.milwaukeetool.ca/Support/Find-A-Service-Center'
      target='_blank'
      rel='noopener noreferrer'
    >
      <FormattedMessage id='productSearch.findServiceCenter' />
    </a>
  ) : (
    <a
      data-test-id='findAServiceCenterLinkUSA'
      href='https://www.milwaukeetool.com/Support/Find-A-Service-Center'
      target='_blank'
      rel='noopener noreferrer'
    >
      <FormattedMessage id='productSearch.findServiceCenter' />
    </a>
  );

  const handleConfirmCancel = () => {
    if (selectedProduct && (selectedProduct.isBattery || selectedProduct.isMXFuel)) {
      history.push('/repairprocess/addanother');
    } else {
      resetBatteryMarkupState();
      includedBatteryCancelClick();
      toggleBatteryModal();
    }
  };

  const handleIUnderstandClick = () => {
    resetBatteryMarkupState();
    toggleBatteryModal();
  };

  const handleBattery9Yes = () => {
    toggleBattery9Markup();
    if (batteryRecycle) {
      setIsRecycled(false);
    }
    toggleBatteryShippingNoticeMarkup();
  };

  const handleBattery9No = () => {
    toggleBattery9Markup();
    if (batteryRecycle) {
      setIsRecycled(true);
      toggleBatteryShippingNoticeMarkup();
    } else {
      toggleIneligibleBatteryMarkup();
    }
  };

  const handleBatteryDamageYes = () => {
    toggleBatteryDamageMarkup();
    toggleBatteryDamageNotEligibleMarkup();
  };

  const handleBatteryDamageNo = () => {
    if (selectedProduct.sku === '48-11-1890' || selectedProduct.sku === '48111890') {
      // Check if 9.0 battery
      toggleBatteryDamageMarkup();
      toggleBattery9Markup();
    } else if (selectedProduct.isMXFuel) {
      // Check for MX Fuel battery
      toggleBatteryDamageMarkup();
      toggleMxFBatteryShippingNoticeMarkup();
    } else {
      toggleBatteryDamageMarkup();
      toggleBatteryShippingNoticeMarkup();
    }
  };

  const resetBatteryMarkupState = () => {
    if (!showBatteryDamageMarkup) {
      toggleBatteryDamageMarkup();
    }
    if (showBattery9Markup) {
      toggleBattery9Markup();
    }
    if (showBatteryDamageNotEligibleMarkup) {
      toggleBatteryDamageNotEligibleMarkup();
    }
    if (showIneligibleBatteryMarkup) {
      toggleIneligibleBatteryMarkup();
    }
    if (showBatteryShippingNoticeMarkup) {
      toggleBatteryShippingNoticeMarkup();
    }
    if (showMxFBatteryShippingNoticeMarkup) {
      toggleMxFBatteryShippingNoticeMarkup();
    }
  };

  const batteryDamageMarkup = showBatteryDamageMarkup && (
    <React.Fragment>
      <Text bold>
        <FormattedMessage id='productDetail.included.batteryModal.batteryDamage.listTitle' />
      </Text>
      <Paragraph>
        <ul>
          <li>
            <FormattedMessage id='productDetail.included.batteryModal.batteryDamage.listPoint1' />
          </li>
          <li>
            <FormattedMessage id='productDetail.included.batteryModal.batteryDamage.listPoint2' />
          </li>
          <li>
            <FormattedMessage id='productDetail.included.batteryModal.batteryDamage.listPoint3' />
          </li>
        </ul>
      </Paragraph>
      <Row style={{ marginBottom: 5 }} gutter={[50, 24]}>
        <Col xs={24} md={12}>
          <DamagedBatteryimg
            data-test-id='batteryModalDamageOne'
            src={window.location.origin + '/images/battery_cracked_1.jpg'}
          />
        </Col>
        <Col xs={24} md={12}>
          <DamagedBatteryimg
            data-test-id='batteryModalDamageTwo'
            src={window.location.origin + '/images/battery_cracked_2.jpg'}
          />
        </Col>
      </Row>

      <Text>
        {isCa ? (
          <FormattedMessage id='productDetail.included.batteryModal.batteryDamage.contactUsCa' />
        ) : (
          <FormattedMessage id='productDetail.included.batteryModal.batteryDamage.contactUs' />
        )}
      </Text>
    </React.Fragment>
  );

  const batteryDamageNotEligible = showBatteryDamageNotEligibleMarkup && (
    <React.Fragment>
      <Paragraph>
        <FormattedMessage id='productDetail.included.batteryModal.batteryDamageNotEligible.line1' />
      </Paragraph>
      <Paragraph>
        {isCa ? (
          <FormattedMessage id='productDetail.included.batteryModal.batteryDamageNotEligible.line2Ca' />
        ) : (
          <FormattedMessage id='productDetail.included.batteryModal.batteryDamageNotEligible.line2' />
        )}
      </Paragraph>
    </React.Fragment>
  );

  const battery9Markup = showBattery9Markup && (
    <React.Fragment>
      <Row gutter={[6, 6]}>
        <Col xs={24} lg={12} style={{ textAlign: 'center' }}>
          <Battery9Img
            data-test-id='batteryModalBlackDot'
            src={window.location.origin + '/images/battery_9_blackdot.jpg'}
          />
        </Col>
        <Col xs={24} lg={12} style={{ textAlign: 'center' }}>
          <Battery9Img
            data-test-id='batteryModalGreenDot'
            src={window.location.origin + '/images/battery_9_greendot.jpg'}
          />
        </Col>
        <Col xs={24} lg={12} style={{ textAlign: 'center' }}>
          <Battery9Img
            data-test-id='batteryModalCheckPicture'
            src={window.location.origin + '/images/battery_9_check.jpg'}
          />
        </Col>
      </Row>
    </React.Fragment>
  );

  const ineligibleBatteryMarkup = showIneligibleBatteryMarkup && (
    <React.Fragment>
      <Paragraph>
        <FormattedMessage
          id='productDetail.included.ineligibleBatteryModal.notEligible'
          values={{
            description: selectedProduct.description,
            sku: selectedProduct.skuAlias,
          }}
        />
      </Paragraph>
      {batterySafetyLink && (
        <Paragraph>
          <FormattedMessage
            id='productDetail.included.ineligibleBatteryModal.expandedWarnings'
            values={{
              description: selectedProduct.description,
              sku: selectedProduct.skuAlias,
              link: isCa ? (
                <a
                  data-test-id='safetyNoticesLinkCA'
                  href={batterySafetyLink}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  https://www.milwaukeetool.ca/News/Safety-Notices
                </a>
              ) : (
                <a
                  data-test-id='safetyNoticesLinkCA2'
                  href={batterySafetyLink}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  https://milwaukeetool.com/safetynotices
                </a>
              ),
            }}
          />
        </Paragraph>
      )}
      <Paragraph>
        <FormattedMessage id='productDetail.included.ineligibleBatteryModal.warrantyInquiries' />
      </Paragraph>
    </React.Fragment>
  );

  const batteryShippingNoticeMarkup = showBatteryShippingNoticeMarkup && (
    <React.Fragment>
      <Paragraph>
        <FormattedMessage id='productDetail.included.batteryModal.mail' />
      </Paragraph>
      <Paragraph>
        <FormattedMessage id='productDetail.included.batteryModal.prohibited' />
      </Paragraph>
      <Paragraph>{findServiceCenterLink}</Paragraph>
    </React.Fragment>
  );

  const mxfModalLinkMarkup = isCa ? (
    <Paragraph>
      <a
        data-test-id='mxfModalFindAServiceCenterCA'
        href='https://www.milwaukeetool.ca/Support/Find-a-Service-Centre'
        target='_blank'
        rel='noopener noreferrer'
      >
        <FormattedMessage id='productSearch.findServiceCenter' />
      </a>
    </Paragraph>
  ) : (
    <Paragraph>
      <a
        data-test-id='mxfModalFindAServiceCenterUSA'
        href='https://www.milwaukeetool.com/Support/Find-A-Service-Center'
        target='_blank'
        rel='noopener noreferrer'
      >
        <FormattedMessage id='productSearch.findServiceCenter' />
      </a>
    </Paragraph>
  );

  const mxfBody2Markup = isCa ? (
    <Paragraph>
      <FormattedMessage id='productDetail.included.mxfModal.body2' values={{ phoneNumber: '(800) 268-4015' }} />
    </Paragraph>
  ) : (
    <Paragraph>
      <FormattedMessage id='productDetail.included.mxfModal.body2' values={{ phoneNumber: '1-800-SAWDUST' }} />
    </Paragraph>
  );

  const mxFuelBatteryShippingNoticeMarkup = showMxFBatteryShippingNoticeMarkup && (
    <React.Fragment>
      <Paragraph>
        <FormattedMessage id='productDetail.included.mxfModal.body1' />
      </Paragraph>
      {mxfBody2Markup}
      <Paragraph>
        <FormattedMessage id='productDetail.included.mxfModal.body3' />
      </Paragraph>
      <Paragraph>
        <FormattedMessage id='productDetail.included.mxfModal.body4' />
      </Paragraph>
      {mxfModalLinkMarkup}
    </React.Fragment>
  );

  const batteryModalMarkup = showBatteryModal && (
    <Modal
      closeIcon={<CloseOutlined data-test-id='batteryModalXButton' />}
      open={showBatteryModal}
      title={
        showBatteryDamageMarkup ? (
          <FormattedMessage id='productDetail.included.batteryModal.batteryDamage.title' />
        ) : showBatteryDamageNotEligibleMarkup ? (
          <FormattedMessage id='productSearch.notEligible' />
        ) : showIneligibleBatteryMarkup ? (
          <FormattedMessage id='productSearch.notEligible' />
        ) : showBattery9Markup ? (
          <FormattedMessage id='productDetail.included.batteryModal.battery9.title' />
        ) : showBatteryShippingNoticeMarkup ? (
          <FormattedMessage id='productDetail.included.batteryModal.title' />
        ) : showMxFBatteryShippingNoticeMarkup ? (
          <FormattedMessage id='productDetail.included.mxfModal.title' />
        ) : (
          ''
        )
      }
      footer={
        showBatteryDamageMarkup
          ? [
              <Button data-test-id='batteryModalCancelButton' key='cancelBtn' onClick={handleConfirmCancel}>
                <FormattedMessage id='general.cancel' />
              </Button>,
              <Button
                data-test-id='batteryModalYesButton'
                key='yesBtn'
                type={'primary'}
                onClick={handleBatteryDamageYes}
              >
                <FormattedMessage id='general.yes' />
              </Button>,
              <Button data-test-id='batteryModalNoButton' key='noBtn' type={'primary'} onClick={handleBatteryDamageNo}>
                <FormattedMessage id='general.no' />
              </Button>,
            ]
          : showBatteryDamageNotEligibleMarkup || showIneligibleBatteryMarkup
          ? [
              <Button
                data-test-id='batteryModalCloseButton'
                key='closeBtn'
                type={'primary'}
                onClick={handleConfirmCancel}
              >
                <FormattedMessage id='general.close' />
              </Button>,
            ]
          : showBattery9Markup
          ? [
              <Button data-test-id='batteryModalCancelButton' key='cancelBtn' onClick={handleConfirmCancel}>
                <FormattedMessage id='general.cancel' />
              </Button>,
              <Button data-test-id='batteryModalYesButton' key='yesBtn' type={'primary'} onClick={handleBattery9Yes}>
                <FormattedMessage id='general.yes' />
              </Button>,
              <Button data-test-id='batteryModalNoButton' key='noBtn' type={'primary'} onClick={handleBattery9No}>
                <FormattedMessage id='general.no' />
              </Button>,
            ]
          : showBatteryShippingNoticeMarkup || showMxFBatteryShippingNoticeMarkup
          ? [
              <Button data-test-id='batteryModalCancelButton' key='cancelBtn' onClick={handleConfirmCancel}>
                <FormattedMessage id='general.cancel' />
              </Button>,
              <Button
                data-test-id='batteryModalUnderstandButton'
                key='iUnderstandBtn'
                type='primary'
                onClick={handleIUnderstandClick}
              >
                <FormattedMessage id='productDetail.included.batteryModal.understand' />
              </Button>,
            ]
          : []
      }
      width={750}
      onCancel={handleConfirmCancel}
      destroyOnClose={true}
    >
      {batteryDamageMarkup}
      {batteryDamageNotEligible}
      {ineligibleBatteryMarkup}
      {battery9Markup}
      {batteryShippingNoticeMarkup}
      {mxFuelBatteryShippingNoticeMarkup}
    </Modal>
  );

  return <Fragment>{batteryModalMarkup}</Fragment>;
};

export default BatteryEvaluationModal;
