import createAxiosAction from '../createAxiosAction';
import {
  CLEAR_ADDRESS_MODAL,
  DELETE_USER_ADDRESS,
  GET_CURRENT_USER,
  GET_MET_USER_TOKEN,
  GET_USER_EMAILS,
  SUBMIT_EMAIL_RECOVERY,
  UPDATE_PREFERRED_CONTACT_METHOD,
  UPSERT_USER,
  UPSERT_USER_ADDRESS,
  UPSERT_USER_EMAIL,
  UPSERT_USER_NOTIFICATIONS,
  UPSERT_USER_TERMS_AND_CONDITIONS,
  VALIDATE_USER_ADDRESS,
  VERIFY_USER_EMAIL,
  GET_USER_ADDRESSES,
  CLEAR_ADDRESS_VALIDATION,
  UPDATE_PREFERRED_LANGUAGE,
  UPDATE_PREFERENCE,
  GET_USER_ADDRESSES_WITH_DEFAULT,
  UPDATE_USER_DEFAULT_ADDRESS,
  UPDATE_USER_REQUESTED_ACCOUNT_NUMBER,
} from '../types';
import { setUserMetadata } from '../../helpers/sessionStorage';

export default {
  getMetUserToken: () => {
    return createAxiosAction({
      url: `/api/v1/users/token`,
      startAction: GET_MET_USER_TOKEN,
      method: 'GET',
    });
  },
  getCurrentUser: () => {
    return createAxiosAction({
      url: `/api/v1/users/_actions/getactiveuser`,
      startAction: GET_CURRENT_USER,
    });
  },
  upsertUser: () => {
    return createAxiosAction({
      url: `/api/v1/users`,
      method: 'POST',
      startAction: UPSERT_USER,
    });
  },
  upsertUserTermsAndConditions: accepted => {
    return createAxiosAction({
      url: `/api/v1/users/termsandconditions`,
      method: 'POST',
      body: {
        accepted: accepted,
      },
      //preserves accepted value on payload to be used on the reducer to persist
      actionPayload: {
        accepted,
      },
      startAction: UPSERT_USER_TERMS_AND_CONDITIONS,
    });
  },
  upsertUserNotifications: (userNotifications, disableEmail, disableSms) => {
    return createAxiosAction({
      url: `/api/v1/users/notifications`,
      method: 'POST',
      body: {
        userNotifications,
        disableEmail,
        disableSms,
      },
      actionPayload: {
        userNotifications,
        disableEmail,
        disableSms,
      },
      startAction: UPSERT_USER_NOTIFICATIONS,
    });
  },
  getUserEmails: () => {
    return createAxiosAction({
      url: `/api/v1/users/useremails`,
      startAction: GET_USER_EMAILS,
    });
  },
  upsertUserEmail: isVerified => {
    return createAxiosAction({
      url: `/api/v1/users/useremails`,
      method: 'POST',
      body: {
        isVerified: isVerified,
      },
      startAction: UPSERT_USER_EMAIL,
    });
  },
  submitEmailRecovery: email => {
    return createAxiosAction({
      url: `/api/v1/users/emailrecovery`,
      method: 'POST',
      body: {
        email: email,
      },
      startAction: SUBMIT_EMAIL_RECOVERY,
    });
  },
  verifyUserEmail: token => {
    return createAxiosAction({
      url: `/api/v1/users/verify?token=${token}`,
      method: 'GET',
      startAction: VERIFY_USER_EMAIL,
    });
  },
  validateUserAddress: (addressLine1, addressLine2, city, state, countryCode, postalCode) => {
    return createAxiosAction({
      url: `/api/v1/users/useraddresses/validate`,
      method: 'POST',
      body: {
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        state: state,
        countryCode: countryCode,
        postalCode: postalCode,
      },
      startAction: VALIDATE_USER_ADDRESS,
    });
  },
  getUserAddresses: () => {
    return createAxiosAction({
      url: `/api/v1/users/useraddresses`,
      method: 'GET',
      startAction: GET_USER_ADDRESSES,
    });
  },
  getUserAddressesWithDefault: () => {
    return createAxiosAction({
      url: `/api/v1/users/userAddressesWithDefault`,
      method: 'GET',
      startAction: GET_USER_ADDRESSES_WITH_DEFAULT,
    });
  },
  upsertUserAddress: (
    id,
    defaultShippingAddress,
    addressLine1,
    addressLine2,
    city,
    state,
    countryCode,
    postalCode,
    name,
    isResidential,
    latitude,
    longitude
  ) => {
    return createAxiosAction({
      url: `/api/v1/users/useraddresses`,
      method: 'POST',
      body: {
        id: id,
        defaultShippingAddress: defaultShippingAddress,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        state: state,
        countryCode: countryCode,
        postalCode: postalCode,
        name: name,
        isResidential: isResidential,
        latitude: latitude,
        longitude: longitude,
      },
      startAction: UPSERT_USER_ADDRESS,
    });
  },
  deleteUserAddress: (id, defaultShippingAddress) => {
    return createAxiosAction({
      url: `/api/v1/users/useraddresses/delete`,
      method: 'POST',
      body: {
        id: id,
        defaultShippingAddress: defaultShippingAddress,
      },
      startAction: DELETE_USER_ADDRESS,
    });
  },
  updatePreferredContactMethod: preferredContactMethod => {
    return createAxiosAction({
      url: `/api/v1/users/preferredcontactmethod`,
      method: 'POST',
      body: {
        preferredContactMethod,
      },
      actionPayload: {
        preferredContactMethod,
      },
      startAction: UPDATE_PREFERRED_CONTACT_METHOD,
    });
  },

  clearAddressValidation: () => dispatch => {
    dispatch({ type: CLEAR_ADDRESS_VALIDATION });
  },

  clearAddressModal: () => dispatch => {
    dispatch({ type: CLEAR_ADDRESS_MODAL });
  },

  updateUserPreferredLanguage: preferredLanguage => {
    return createAxiosAction({
      url: `/api/v1/users/preferredlanguage`,
      method: 'POST',
      body: {
        preferredLanguage,
      },
      actionPayload: {
        preferredLanguage,
      },
      startAction: UPDATE_PREFERRED_LANGUAGE,
    });
  },
  updateUserPreferences: (firstName, lastName, phoneNumber, preferredContactMethod, preferredLanguage) => {
    return createAxiosAction({
      url: `/api/v1/users/preferences`,
      method: 'POST',
      body: {
        firstName,
        lastName,
        phoneNumber,
        preferredContactMethod,
        preferredLanguage,
      },
      startAction: UPDATE_PREFERENCE,
    });
  },
  updateUserDefaultAddress: id => {
    return createAxiosAction({
      url: `/api/v1/users/defaultaddress/update`,
      method: 'POST',
      body: {
        id,
      },
      startAction: UPDATE_USER_DEFAULT_ADDRESS,
    });
  },
  updateUserRequestedAccountNumber: requestedAccountNumber => {
    return createAxiosAction({
      url: `/api/v1/users/requestedaccountnumber`,
      method: 'POST',
      body: {
        requestedAccountNumber,
      },
      startAction: UPDATE_USER_REQUESTED_ACCOUNT_NUMBER,
    });
  },
};
