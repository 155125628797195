import { Button, Col, Row, Space, Typography } from 'antd';
import React, { Fragment } from 'react';
import { useServiceOrder, useUser } from '../../../../hooks';
import {
  InfoCardContainer,
  InnerInfoCardContainer,
  Space100,
} from '../../styled';
import { Icon, Text } from '@met/react-components';
import { FormattedMessage } from 'react-intl';
import LmrOptions from '../../../../components/LmrOptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import {
  DeniedWarrantyTypes,
  PaymentMethodTypes,
  PreferredContactMethodTypes,
} from '../../../../enums';

const { Title } = Typography;

const RepairOrderSummary = ({
  deniedWarrantyState,
  onBackClick,
  onSubmitClick,
  toggleLmrModal,
}) => {
  const { serviceOrder, upsertingWarrantyOption } = useServiceOrder();
  const { email, phoneNumber, preferredContactMethod } = useUser();
  const { action, creditCard, paymentMethod, poNumber } = deniedWarrantyState;
  const isAuthorizingCard = useSelector(
    (state) => state.square.authorizeCreditCard.isLoading
  );

  const usLmrLines = serviceOrder && (
    <LmrOptions
      lmrMappings={serviceOrder.productLmrMappings}
      country='us'
      style={{ fontWeight: '600', color: '#db011c' }}
    />
  );

  const lmrMarkup = (
    <Fragment>
      <Text>
        <FormattedMessage id='productDetail.toolInfo.lmrTitle' />
        <Icon
          data-test-id='toggleLMRModalIcon'
          className='ml-2'
          type='question-circle'
          xs
          onClick={toggleLmrModal}
        />
      </Text>
      {usLmrLines}
    </Fragment>
  );

  const authorizationNotice = paymentMethod ===
    PaymentMethodTypes.CREDITCARD && (
    <Space direction='vertical'>
      <Text>
        <FormattedMessage id='repairDetails.deniedWarranty.authorizationNotice' />{' '}
        {usLmrLines}
      </Text>
    </Space>
  );

  const contactMethodDescription =
    preferredContactMethod === PreferredContactMethodTypes.EMAIL ? (
      <FormattedMessage
        id='repairDetails.deniedWarranty.contactViaEmail'
        values={{ email: email }}
      />
    ) : (
      <FormattedMessage
        id='repairDetails.deniedWarranty.contactViaPhone'
        values={{ phoneNumber: phoneNumber }}
      />
    );

  const getPaymentMethodType = () => {
    switch (paymentMethod) {
      case PaymentMethodTypes.PAYLATER:
        return <FormattedMessage id='repairDetails.deniedWarranty.payLater' />;
      case PaymentMethodTypes.PURCHASEORDER:
        return (
          <FormattedMessage id='repairDetails.deniedWarranty.purchaseOrder' />
        );
      case PaymentMethodTypes.CREDITCARD:
        return (
          <FormattedMessage id='repairDetails.deniedWarranty.creditCard' />
        );
    }
  };

  const buttonMarkup = (
    <Row justify={'end'} gutter={[16, 16]} style={{ marginTop: '10px' }}>
      <Col>
        <Space direction='horizontal'>
          <Button
            data-test-id='backBtn'
            onClick={onBackClick}
            disabled={isAuthorizingCard || upsertingWarrantyOption}
          >
            <FormattedMessage id='general.back' />
          </Button>
          <Button
            data-test-id='submitRepairOrderBtn'
            type='primary'
            onClick={onSubmitClick}
            loading={isAuthorizingCard || upsertingWarrantyOption}
          >
            <FormattedMessage id='repairDetails.deniedWarranty.submitRepairOrder' />
          </Button>
        </Space>
      </Col>
    </Row>
  );

  const payLaterMarkup = paymentMethod === PaymentMethodTypes.PAYLATER && (
    <Space100 direction='vertical'>
      <Text>
        <FormattedMessage id='repairDetails.deniedWarranty.payLaterMarkupLine1' />
      </Text>
      <Text>{contactMethodDescription}</Text>
    </Space100>
  );
  const purchaseOrderMarkup = paymentMethod ===
    PaymentMethodTypes.PURCHASEORDER && (
    <Space100 direction='vertical'>
      <Text>
        <FormattedMessage id='repairDetails.deniedWarranty.purchaseOrderMarkupLine1' />
      </Text>
      <Text>
        <FormattedMessage
          id='repairDetails.deniedWarranty.purchaseOrderMarkupLine2'
          values={{
            purchaseOrderNumber: poNumber,
          }}
        />
      </Text>
    </Space100>
  );

  const creditCardMarkup = paymentMethod === PaymentMethodTypes.CREDITCARD && (
    <Fragment>
      <Text strong>
        <FormattedMessage id='repairDetails.deniedWarranty.cardEndingIn' />{' '}
        {creditCard?.cardLast4}{' '}
        <FontAwesomeIcon icon={faCreditCard} size='lg' />
      </Text>
      <Text>{creditCard?.cardholderName}</Text>
    </Fragment>
  );

  const repairMarkup = action === DeniedWarrantyTypes.REPAIR && (
    <Space100 direction='vertical'>
      <Text>
        <FormattedMessage id='repairDetails.deniedWarranty.repairMarkupLine1' />
      </Text>
      <Text>
        <FormattedMessage id='repairDetails.deniedWarranty.repairMarkupLine2' />
      </Text>
      {lmrMarkup}
      <Title level={4}>
        <FormattedMessage id='repairDetails.deniedWarranty.billingInformation' />{' '}
        - {getPaymentMethodType()}
      </Title>
      {payLaterMarkup}
      {purchaseOrderMarkup}
      {creditCardMarkup}
    </Space100>
  );

  const returnMarkup = action === DeniedWarrantyTypes.RETURN && (
    <Space100 direction='vertical'>
      <Text>
        <FormattedMessage id='repairDetails.deniedWarranty.returnMarkupLine1' />
      </Text>
      <Text>
        <FormattedMessage id='repairDetails.deniedWarranty.returnMarkupLine2' />
      </Text>
    </Space100>
  );

  const recycleMarkup = action === DeniedWarrantyTypes.RECYCLE && (
    <Space100 direction='vertical'>
      <Text>
        <FormattedMessage id='repairDetails.deniedWarranty.recycleMarkupLine1' />
      </Text>
      <Text>
        <FormattedMessage id='repairDetails.deniedWarranty.recycleMarkupLine2' />
      </Text>
    </Space100>
  );

  return (
    <InfoCardContainer>
      <InnerInfoCardContainer>
        <Title level={3}>
          <FormattedMessage id='repairDetails.deniedWarranty.repairOrderSummary' />
        </Title>
        {repairMarkup}
        {recycleMarkup}
        {returnMarkup}
        {buttonMarkup}
      </InnerInfoCardContainer>
      {authorizationNotice}
    </InfoCardContainer>
  );
};

export default RepairOrderSummary;
