import createAxiosAction from '../createAxiosAction';
import { GET_PRODUCT_PROBLEMS, UPSERT_PRODUCT_PROBLEM } from '../types';

export default {
  getProductProblems: () => {
    return createAxiosAction({
      url: `api/v1/productProblems`,
      startAction: GET_PRODUCT_PROBLEMS,
    });
  },
  upsertProductProblem: (id, description) => {
    return createAxiosAction({
      url: `/api/v1/productProblems`,
      method: 'POST',
      body: {
        id: id,
        description: description,
      },
      startAction: UPSERT_PRODUCT_PROBLEM,
    });
  },
};
