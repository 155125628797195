import { Typography } from 'antd';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InfoGrid, InfoGridItem } from '../InfoGrid';

const { Title } = Typography;

const AccountInfo = ({ accountName, accountNumber, phone, email }) => {
  const intl = useIntl();

  return (
    <Fragment>
      <Title level={4}>
        <FormattedMessage id='repairDetails.accountInfoTitle' />
      </Title>
      <InfoGrid column={1}>
        <InfoGridItem label={intl.formatMessage({ id: 'repairDetails.accountInfo.accountName' })}>
          {accountName}
        </InfoGridItem>
        <InfoGridItem label={intl.formatMessage({ id: 'repairDetails.accountInfo.accountNumber' })}>
          {accountNumber}
        </InfoGridItem>
        <InfoGridItem label={intl.formatMessage({ id: 'repairDetails.accountInfo.phone' })}>{phone}</InfoGridItem>
        <InfoGridItem label={intl.formatMessage({ id: 'repairDetails.accountInfo.email' })}>{email}</InfoGridItem>
      </InfoGrid>
    </Fragment>
  );
};

export default AccountInfo;
