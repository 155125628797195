import createAxiosAction from '../createAxiosAction';
import {
  SEARCH_SERVICE_ORDERS,
  GET_SERVICE_ORDER,
  GET_SERVICE_ORDERS,
  CLEAR_SERVICE_ORDERS,
  EXPORT_SERVICE_ORDERS,
  SUBMIT_SERVICE_ORDER,
  UPSERT_WARRANTY_OPTION,
  GET_DENIED_SERVICE_ORDERS,
  GET_SERVICE_ORDERS_PDF,
  SEARCH_SERVICE_ORDERS_ADMIN,
  CLEAR_SERVICE_ORDERS_ADMIN,
  GET_SERVICE_ORDER_STATUSES,
  RESUBMIT_SERVICE_ORDER_LABEL,
  RESUBMIT_BULK_SERVICE_ORDER_LABEL,
  UPDATE_SERVICE_ORDER,
  GET_SERVICE_ORDER_ROUTING,
  GET_ESTIMATED_SCRAP_DATE,
  RESYNC_USER_ORDER_INDEX,
} from '../types';

export default {
  searchServiceOrders: (page, searchParameters) => {
    return createAxiosAction({
      url: `/api/v1/serviceorders/${searchParameters}`,
      method: 'GET',
      startAction: SEARCH_SERVICE_ORDERS,
      actionPayload: {
        page,
      },
    });
  },
  searchServiceOrdersAdmin: (page, searchParameters) => {
    return createAxiosAction({
      url: `/api/v1/serviceorders/admin/${searchParameters}`,
      method: 'GET',
      startAction: SEARCH_SERVICE_ORDERS_ADMIN,
      actionPayload: {
        page,
      },
    });
  },
  getServiceOrder: (id) => {
    return createAxiosAction({
      url: `/api/v1/serviceorders/${id}`,
      method: 'GET',
      startAction: GET_SERVICE_ORDER,
    });
  },
  getServiceOrders: (groupId) => {
    return createAxiosAction({
      url: `/api/v1/serviceorders/group/${groupId}`,
      method: 'GET',
      startAction: GET_SERVICE_ORDERS,
    });
  },
  clearServiceOrders: () => {
    return {
      type: CLEAR_SERVICE_ORDERS,
    };
  },
  clearServiceOrdersAdmin: () => {
    return {
      type: CLEAR_SERVICE_ORDERS_ADMIN,
    };
  },
  exportServiceOrders: (searchParameters) => {
    return createAxiosAction({
      url: `/api/v1/serviceorders/_actions/export/${searchParameters}`,
      startAction: EXPORT_SERVICE_ORDERS,
      responseType: 'blob',
    });
  },
  submitServiceOrder: (serviceOrder) => {
    return createAxiosAction({
      url: `/api/v1/serviceorders`,
      method: 'POST',
      body: serviceOrder,
      startAction: SUBMIT_SERVICE_ORDER,
    });
  },
  upsertWarrantyOption: (serviceOrderNumber, purchaseOrderNumber, warrantyOption, paymentMethodId) => {
    return createAxiosAction({
      url: `/api/v1/serviceorders/_actions/upsertwarrantyoption`,
      method: 'POST',
      body: {
        serviceOrderNumber,
        purchaseOrderNumber,
        warrantyOption,
        paymentMethodId,
      },
      actionPayload: {
        warrantyOption,
        paymentMethodId,
      },
      startAction: UPSERT_WARRANTY_OPTION,
    });
  },
  getDeniedServiceOrders: () => {
    return createAxiosAction({
      url: `/api/v1/serviceorders/denied`,
      method: 'GET',
      startAction: GET_DENIED_SERVICE_ORDERS,
    });
  },
  getServiceOrdersPdf: (groupId) => {
    return createAxiosAction({
      url: `/api/v1/serviceorders/${groupId}/pdf`,
      method: 'GET',
      startAction: GET_SERVICE_ORDERS_PDF,
      responseType: 'blob',
    });
  },
  getServiceOrderStatuses: () => {
    return createAxiosAction({
      url: `/api/v1/serviceorders/statuses`,
      method: 'GET',
      startAction: GET_SERVICE_ORDER_STATUSES,
    });
  },
  resubmitServiceOrderLabel: (id) => {
    return createAxiosAction({
      url: `/api/v1/serviceorders/resubmitLabel/${id}`,
      method: 'GET',
      startAction: RESUBMIT_SERVICE_ORDER_LABEL,
    });
  },
  resubmitFailedServiceOrderLabel: (startDate, endDate) => {
    return createAxiosAction({
      url: `/api/v1/serviceorders/resubmitLabel/failed`,
      method: 'POST',
      body: {
        startDate: startDate,
        endDate: endDate,
      },
      startAction: RESUBMIT_BULK_SERVICE_ORDER_LABEL,
    });
  },
  updateServiceOrderAddress: (serviceOrder) => {
    return createAxiosAction({
      url: `/api/v1/serviceorders/update`,
      method: 'POST',
      body: serviceOrder,
      startAction: UPDATE_SERVICE_ORDER,
    });
  },
  resyncUserOrderIndex: (email, startDate, endDate) => {
    return createAxiosAction({
      url: `/api/v1/serviceorders/_actions/resyncOrderIndex`,
      method: 'POST',
      body: {
        email,
        startDate,
        endDate,
      },
      startAction: RESYNC_USER_ORDER_INDEX,
    });
  },
  getServiceOrderRouting: (state, skus, countryCode) => {
    return createAxiosAction({
      url: `/api/v1/serviceOrders/admin/routing`,
      method: 'POST',
      body: {
        state: state,
        skus: skus,
        countryCode: countryCode,
      },
      startAction: GET_SERVICE_ORDER_ROUTING,
    });
  },
  getServiceOrderRoutingGeolocation: (address, skus) => {
    return createAxiosAction({
      url: `/api/v1/serviceOrders/admin/routing`,
      method: 'POST',
      body: {
        userAddress: address,
        skus: skus,
      },
      startAction: GET_SERVICE_ORDER_ROUTING,
    });
  },
  getServiceOrderEstimatedScrapDate: (serviceOrderId, notificationTypeId) => {
    return createAxiosAction({
      url: `/api/v1/serviceOrders/estimatedScrapDate`,
      method: 'POST',
      body: {
        serviceOrderId,
        notificationTypeId,
      },
      startAction: GET_ESTIMATED_SCRAP_DATE,
    });
  },
};
