import moment from 'moment';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ProductImage, ServiceOrderLineRow } from '../styled';
import { Col, Space, Typography } from 'antd';

const { Text } = Typography;

const ServiceOrderLine = ({ sku, description, serialNumber, isDeniedWarranty, updatedAt }) => {
  const intl = useIntl();

  let formattedUpdatedAt = () => {
    return moment(updatedAt)
      .local()
      .format('MM/DD/YYYY h:mm:ss A');
  };

  return (
    <ServiceOrderLineRow align={'middle'}>
      <Col xs={24} lg={2}>
        <div style={{ textAlign: 'center' }}>
          <ProductImage src={`/api/v1/products/${sku}/image`} alt={sku} />
        </div>
      </Col>
      <Col xs={12} lg={8} middle='xs' className='mb-2 mb-md-0'>
        <Space direction={'vertical'}>
          <Text strong>{sku}</Text>
          <Text>{description}</Text>
        </Space>
      </Col>
      <Col xs={12} lg={6}>
        <Space direction={'vertical'}>
          <Text strong>
            <FormattedMessage id='admin.serviceOrder.serialNumberLabel' />
          </Text>
          <Text>{serialNumber || intl.formatMessage({ id: 'general.na' })}</Text>
        </Space>
      </Col>
      <Col xs={12} lg={4}>
        <Space direction={'vertical'}>
          <Text strong>
            <FormattedMessage id='admin.serviceOrder.deniedWarrantyLabel' />
          </Text>
          <Text>
            {isDeniedWarranty ? intl.formatMessage({ id: 'general.yes' }) : intl.formatMessage({ id: 'general.no' })}
          </Text>
        </Space>
      </Col>
      <Col xs={12} lg={4}>
        <Space direction={'vertical'}>
          <Text strong>
            <FormattedMessage id='admin.serviceOrder.updatedAtLabel' />
          </Text>
          <Text>{formattedUpdatedAt()}</Text>
        </Space>
      </Col>
    </ServiceOrderLineRow>
  );
};

export default ServiceOrderLine;
